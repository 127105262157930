import { ConfigProvider, Modal, Spin, Tag, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  useDeleteProposalShipmentItemMutation,
  useGetPropsalByIdQuery,
  useLazyGetProposalsByShipmentQuery,
  useRevokeSuggestedProposalByIdMutation,
  useUpdateAcceptedPropsalMutation,
  useUpdatePropsalMutation,
} from 'api/proposal';
import { useLazyGetShipmentsBySlugQuery } from 'api/shipment';
import ProfileImage from 'app/components/ProfilePicture';
import ProposalForm from 'app/components/Proposal/ProposalForm';
import References from 'app/components/Proposal/Refrences';
import DescriptionTitle from 'app/design-system/DescriptionTitle';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IProposalEditPorps } from 'types/feature/proposal.types';
import { IReferenceForm } from 'types/feature/proposals.types';

import { isAdminCO } from 'utils/shipment-helper';

import './proposal-edit-modal.scss';

const ProposalEditModal: FC<IProposalEditPorps> = ({
  selectedProposal,
  isOpen,
  onClose,
  shipment,
  isFieldEditDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [refetchProposalsByShipment] = useLazyGetProposalsByShipmentQuery();
  const [refetchShipment] = useLazyGetShipmentsBySlugQuery();
  const [referenceForm] = useForm<IReferenceForm>();
  const currentUser = useSelector((state: RootState) => state.user.user);

  const { data: proposalData, isLoading } = useGetPropsalByIdQuery(
    selectedProposal.id,
    {
      skip: !selectedProposal,
    }
  );

  const assigneeName = `${proposalData?.assignee?.fullname}`;
  const assigneePhoto = proposalData?.assignee?.photo;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    try {
      const { id: shipmentId, slug: shipmentSlug } = shipment;
      await refetchProposalsByShipment({ shipmentId });
      if (proposalData && proposalData.status === IQuoteStatus.ACCEPTED)
        await refetchShipment(shipmentSlug);
      setIsModalOpen(false);
      onClose();
    } catch (error) {
      message.warning('Something went wrong while refreshing shioment !');
    }
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            controlWidth: 120,
          },
        },
      }}
    >
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'70vw'}
        footer={null}
        title="Update RFQ"
        destroyOnClose
      >
        {isLoading ? (
          <div className="proposal-modal-loader-wrapper">
            <Spin size="large" />
          </div>
        ) : (
          proposalData && (
            <div className="proposal-edit-wrapper">
              <div className="header">
                <div className="info">
                  <Tooltip title={assigneeName}>
                    <ProfileImage
                      name={assigneeName}
                      photoUrl={assigneePhoto}
                      size={40}
                      wrapperClassName="user-gravatar"
                    />
                  </Tooltip>

                  <DescriptionTitle
                    title={`RFQ-${proposalData.slug}`}
                    description={proposalData?.company?.name || ' '}
                  />
                </div>
                <div className="status">
                  <Tag color="green" className="proposal-tag">
                    {proposalData.status}
                  </Tag>
                </div>
              </div>
              <References
                proposal={{
                  ...proposalData,
                }}
                form={referenceForm}
                shipment={{
                  shipmentReference: shipment.shipmentReference,
                  shipmentSlug: shipment.slug,
                }}
              />
              <ProposalForm
                shipment={{
                  ...shipment,
                  shipmentItems: shipment.cargoUnits,
                  acceptedCargoUnits: [],
                  acceptedShipmentItems: '',
                  acceptedShipmentType: null,
                }}
                showProposalDiffViewer={false}
                proposal={proposalData}
                onPostSubmit={handleCancel}
                isExternalForwarder={false}
                referenceForm={referenceForm}
                isAdmin={isAdminCO(
                  currentUser?.userRole,
                  shipment.currentUserRole
                )}
                isFieldEditDisabled={isFieldEditDisabled}
                updateProposalMutation={useUpdatePropsalMutation}
                updateAcceptedProposalMutation={
                  useUpdateAcceptedPropsalMutation
                }
                deleteProposalCargoUnitMutation={
                  useDeleteProposalShipmentItemMutation
                }
                // Waiting for internal API
                revokeSuggestedProposalMutation={
                  useRevokeSuggestedProposalByIdMutation
                }
              />
            </div>
          )
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default ProposalEditModal;
