import { Button, Col, Modal, Row } from 'antd';
import { useAmendAcceptedProposalMutation } from 'api/proposal';
import React, { FC } from 'react';
import { ProposalAmendStatusEnum } from 'types/entities/quote.entity';
import { IBaseProposalActionsProps } from 'types/feature/proposal.types';

import { displayErrors } from 'utils/error-notification';

const { confirm } = Modal;

const AcceptedProposalActions: FC<IBaseProposalActionsProps> = ({
  proposal,
  actionButton,
}) => {
  const [amendAcceptedProposal, { isLoading }] =
    useAmendAcceptedProposalMutation();

  const onAmendAcceptedProposal = async (status: ProposalAmendStatusEnum) => {
    try {
      await amendAcceptedProposal({
        proposalId: proposal.id,
        status,
      }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on Amend Proposal' });
    }
  };

  const onAcceptClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Accept Changes',
      okType: 'primary',
      icon: null,
      content: 'Are you sure you want to accept the changes for this proposal?',
      onOk: async () => {
        await onAmendAcceptedProposal(ProposalAmendStatusEnum.ACCEPT);
        return;
      },
    });
  };

  const onRejectClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Reject Changes',
      okType: 'danger',
      icon: null,
      content: 'Are you sure you want to accept the changes for this proposal?',
      onOk: async () => {
        await onAmendAcceptedProposal(ProposalAmendStatusEnum.DECLINE);
        return;
      },
    });
  };

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col>
          <p>
            <strong>Note:</strong> If you have any concerns about the changes in
            the quote, please mention in the comments to communicate with
            forwarder before you accept the changes.
          </p>
        </Col>
      </Row>
      <Row className="accepted-proposal-actions" gutter={[12, 12]}>
        <Col span={12}>
          <Button
            danger
            size={actionButton.size}
            block={actionButton.isBlock}
            onClick={onRejectClick}
            loading={isLoading}
          >
            Reject
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            size={actionButton.size}
            block={actionButton.isBlock}
            onClick={onAcceptClick}
            loading={isLoading}
          >
            Accept
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AcceptedProposalActions;
