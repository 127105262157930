import Icon from '@ant-design/icons';
import { Progress, Row, Typography } from 'antd';
import { renderServiceMode } from 'app/components/ContractRates/CostHeadCollapse/cost-head.helper';
import { calculateShipmentProgress } from 'app/components/ShipmentPreview/ShipmentOverview/shipment-overview.utility';
import React from 'react';
import { ModalityEnum } from 'types/entities/shipment.entity';
import { ShipmentProgressProps } from 'types/feature/shipments.types';

import { formatShipmentState } from 'utils/format-fields';

import { ReactComponent as BoatIcon } from '../../../../assets/icons/boat-filled-icon.svg';
import { ReactComponent as PlaneIcon } from '../../../../assets/icons/plane-icon.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/icons/truck-icon.svg';

const { Text } = Typography;

const ShipmentProgress: React.FC<ShipmentProgressProps> = ({
  shipment,
  isTracking,
}) => {
  return (
    <>
      <Row justify="space-between">
        {isTracking ? (
          formatShipmentState(shipment.status)
        ) : (
          <Text className="shipment-tab__title">Shipment Overview</Text>
        )}
        <div>
          {renderServiceMode(
            `${shipment.origin.serviceMode}/${shipment.destination.serviceMode}`
          )}
        </div>
      </Row>
      <div className="shipment-progress-wrapper">
        <Progress
          percent={calculateShipmentProgress(shipment.status)}
          type="line"
          status="success"
          showInfo={false}
        />
        <Icon
          component={
            shipment.modality === ModalityEnum.AIR
              ? PlaneIcon
              : shipment.modality === ModalityEnum.ROAD
              ? TruckIcon
              : BoatIcon
          }
          className="tick-icon"
          style={{
            left: `${calculateShipmentProgress(shipment.status)}%`,
          }}
        />
      </div>
    </>
  );
};

export default ShipmentProgress;
