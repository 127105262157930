import { Checkbox, Form } from 'antd';
import React, { FC, useEffect } from 'react';
import { ShipmentTypeEnum } from 'types/entities/shipment.entity';
import {
  IShipmentTypeFilterForm,
  IShipmentTypeFilterProps,
} from 'types/feature/shipments.types';

import { splitState } from 'utils/format-fields';

import '../shipment-filter.scss';

const ShipmentTypeFilter: FC<IShipmentTypeFilterProps> = ({
  onChange,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IShipmentTypeFilterForm>();

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        shipmentTypeFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ shipmentTypeFilter }: IShipmentTypeFilterForm) => {
    onChange(shipmentTypeFilter);
  };

  return (
    <Form
      form={form}
      onValuesChange={onFormChange}
      initialValues={{ statusFilter: defaultValue }}
      className="filters-form-wrapper"
    >
      <Form.Item name="shipmentTypeFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {Object.values(ShipmentTypeEnum).map(
              (modality: ShipmentTypeEnum) => (
                <div className="input-space-wrapper" key={modality}>
                  <Checkbox value={modality}>{splitState(modality)}</Checkbox>
                </div>
              )
            )}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default ShipmentTypeFilter;
