import { Checkbox, Form } from 'antd';
import React, { FC, useEffect } from 'react';
import {
  IStatusFilterForm,
  IStatusFilterProps,
} from 'types/feature/invoice-list';
import { InvoiceStatusEnum } from 'types/feature/invoice.types';

import { computeInvoiceStatusLabel } from '../../InvoiceStatusSwitch/helpers/helper';

const InvoiceStatusFilter: FC<IStatusFilterProps> = ({
  onChange,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IStatusFilterForm>();

  const onFormChange = ({ statusFilter }: IStatusFilterForm) => {
    onChange(statusFilter);
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        statusFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Form
      className="filters-form-wrapper"
      form={form}
      initialValues={{ statusFilter: defaultValue }}
      onValuesChange={onFormChange}
    >
      <Form.Item name="statusFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {Object.values(InvoiceStatusEnum).map(
              (status: InvoiceStatusEnum, index: number) => (
                <div className="input-space-wrapper" key={status}>
                  <Checkbox value={status}>
                    {computeInvoiceStatusLabel(status)}
                  </Checkbox>
                </div>
              )
            )}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default InvoiceStatusFilter;
