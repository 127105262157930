import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ICarrierEntity } from 'types/entities/carrier.entity';
import { IFeeItemDescriptionEntity } from 'types/entities/fee.entity';
import {
  AddCommentProposolRequest,
  IExternalProposalEntity,
  IExternalProposalParams,
  INewProposalEntity,
  INewProposalRequestBody,
  IProposalCommentEntity,
  IProposalEntity,
  IUpdateAcceptedProposalRequestBody,
  IUpdateProposalRequestBody,
} from 'types/entities/proposal.entity';
import {
  IProposalShipmentEntity,
  IShipmentTypeEntity,
} from 'types/entities/shipment.entity';

import { API_URL } from './common/config';
import { ApiResponse } from './common/response.type';

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/proposal-email`,
});

export const externalProposalApi = createApi({
  reducerPath: 'external-proposals',

  baseQuery: baseQuery,

  tagTypes: ['ProposalForForwarder'],

  endpoints: (build) => ({
    getExternalProposalShipment: build.query<
      IProposalShipmentEntity,
      IExternalProposalParams
    >({
      query: ({ proposalId, email }) => `/${proposalId}/${email}/shipment`,
      transformResponse: (resp: ApiResponse<IProposalShipmentEntity>) =>
        resp.data,
    }),
    getExternalPropsalById: build.query<
      IProposalEntity,
      IExternalProposalParams
    >({
      query: ({ proposalId, email }) => `/${proposalId}/${email}`,
      transformResponse: (resp: ApiResponse<IProposalEntity>) => resp.data,

      providesTags: ['ProposalForForwarder'],
    }),

    getExternalShipmentsTypes: build.query<IShipmentTypeEntity, void>({
      query: () => '/shipment-types',
      transformResponse: (resp: ApiResponse<IShipmentTypeEntity>) => resp.data,
    }),

    getExternalProposalItemDescription: build.query<
      IFeeItemDescriptionEntity,
      void
    >({
      query: () => '/description',
      transformResponse: (resp: ApiResponse<IFeeItemDescriptionEntity>) =>
        resp.data,
    }),

    updateExternalPropsalById: build.mutation<
      IExternalProposalEntity,
      IUpdateProposalRequestBody
    >({
      query: (body) => ({
        url: `/${body.proposal.id}/${body.proposal.assignee.email}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: ['ProposalForForwarder'],
    }),
    updateExternalAcceptedPropsal: build.mutation<
      IExternalProposalEntity,
      IUpdateAcceptedProposalRequestBody
    >({
      query: (payload) => ({
        url: `additional/${payload.params.id}/${payload.params.email}`,
        method: 'PATCH',
        body: payload.proposal,
      }),
      invalidatesTags: ['ProposalForForwarder'],
    }),

    createNewProposalById: build.mutation<
      ApiResponse<INewProposalEntity>,
      INewProposalRequestBody
    >({
      query: (newProposal) => ({
        url: `/new-proposal`,
        method: 'POST',
        params: newProposal,
      }),
    }),

    deleteExternalProposalItem: build.mutation<
      ApiResponse<string>,
      { id: string; params: IExternalProposalParams }
    >({
      query(body) {
        const { proposalId, email } = body.params;

        return {
          url: `/${proposalId}/${email}/items/${body.id}`,
          method: 'DELETE',
        };
      },
    }),

    deleteExternalProposalShipmentItem: build.mutation<
      ApiResponse<string>,
      { id: string; params: IExternalProposalParams }
    >({
      query(body) {
        const { proposalId, email } = body.params;

        return {
          url: `/${proposalId}/${email}/shipment-items/${body.id}`,
          method: 'DELETE',
        };
      },
    }),

    addCommentExternalPropsal: build.mutation<
      IProposalCommentEntity,
      AddCommentProposolRequest
    >({
      query: (proposal: AddCommentProposolRequest) => ({
        url: `/comment/${proposal.proposalId}/${proposal.email}`,
        method: 'POST',
        body: proposal,
      }),
    }),
    uploadFilesExternalPropsal: build.mutation<
      string,
      {
        files: FormData;
        params: IExternalProposalParams;
      }
    >({
      query: (body) => {
        const { proposalId, email } = body.params;
        return {
          url: `/files/${proposalId}/${email}`,
          method: 'POST',
          body: body.files,
          formData: true,
        };
      },
    }),
    deleteFilesExternalPropsal: build.mutation<
      string,
      {
        url: string;
        params: IExternalProposalParams;
      }
    >({
      query: (body) => {
        const { proposalId, email } = body.params;
        return {
          url: `/files/${proposalId}/${email}`,
          method: 'DELETE',
          body: body,
        };
      },
    }), ///
    activateNotificationForProposal: build.mutation<
      string,
      {
        rankNotificationEnabled: boolean;
        params: IExternalProposalParams;
      }
    >({
      query: (body) => {
        const { proposalId, email } = body.params;
        return {
          url: `/rank-notification/${proposalId}/${email}`,
          method: 'PATCH',
          body: body,
        };
      },
    }),
    getCarriersForProposal: build.query<ICarrierEntity[], void>({
      query: () => `/carriers`,
      transformResponse: (
        resp: ApiResponse<{ [key: number]: ICarrierEntity }>
      ) => {
        const shippingLinesArray: ICarrierEntity[] = [];
        for (const key in resp.data) {
          shippingLinesArray.push(resp.data[key as unknown as number]);
        }
        return shippingLinesArray;
      },
    }),

    createSuggestedProposalById: build.mutation<
      ApiResponse<INewProposalEntity>,
      INewProposalRequestBody
    >({
      query: (newProposal) => ({
        url: `/suggested-proposal`,
        method: 'POST',
        params: newProposal,
      }),
      invalidatesTags: ['ProposalForForwarder'],
    }),

    revokeSuggestedProposalById: build.mutation<
      ApiResponse<INewProposalEntity>,
      INewProposalRequestBody
    >({
      query: ({ proposalId, email }) => ({
        url: `/suggested-proposal/${proposalId}/${email}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProposalForForwarder'],
    }),
  }),
});

export const {
  useGetExternalProposalShipmentQuery,
  useGetExternalPropsalByIdQuery,
  useGetExternalShipmentsTypesQuery,
  useGetCarriersForProposalQuery,
  useUpdateExternalPropsalByIdMutation,
  useUploadFilesExternalPropsalMutation,
  useAddCommentExternalPropsalMutation,
  useCreateNewProposalByIdMutation,
  useDeleteExternalProposalShipmentItemMutation,
  useDeleteExternalProposalItemMutation,
  useDeleteFilesExternalPropsalMutation,
  useActivateNotificationForProposalMutation,
  useGetExternalProposalItemDescriptionQuery,
  useUpdateExternalAcceptedPropsalMutation,
  useCreateSuggestedProposalByIdMutation,
  useRevokeSuggestedProposalByIdMutation,
} = externalProposalApi;
