import { ThemeConfig } from 'antd';

const colors = {
  white: '#ffffff',
  black: '#000000',
  gray: '#6B7280',
  lightBlue: '#F7FAFF',
  blackHover: '#323232',
  inputStorke: '#CCD0D3',
};

// Global theme config for the app , in order to add or edit a value first refer to https://ant.design/docs/react/customize-theme#api
export const appTheme: ThemeConfig = {
  token: {
    fontSize: 13,
  },
  components: {
    Button: {
      colorPrimary: colors.black,
      colorTextLightSolid: colors.white,
      colorPrimaryHover: colors.blackHover,
      colorPrimaryActive: colors.black,
    },
    Layout: {
      colorBgHeader: colors.white,
    },
    Form: {
      margin: 0,
      colorTextLabel: colors.gray,
      colorTextHeading: colors.gray,
    },

    Input: {
      colorBorder: colors.inputStorke,
      colorPrimaryBorderHover: colors.black,
    },
    Table: {
      colorBgBase: colors.lightBlue,
    },

    InputNumber: {
      controlWidth: 180,
    },
    Typography: {
      fontSize: 12,
      fontSizeSM: 12,
    },
    Modal: {
      titleFontSize: 18,
      titleLineHeight: 1.5,
      borderRadius: 32,
      borderRadiusOuter: 32,
    },
  },
};

export const APP_THEME_CONST = {
  token: {
    // wireframe: false,
    colorPrimary: '#6d28d9',
    colorInfo: '#6d28d9',
    colorTextBase: '#111827',
    colorError: '#ef4444',
    colorWarning: '#f59e0b',
    colorSuccess: '#10b981',
    colorTextSecondary: '#6b7280',
    colorBorder: '#d1d5db',
    colorBorderSecondary: '#e5e7eb',
    fontSize: 16,
    fontSizeXL: 20,
    fontSizeHeading4: 24,
    fontSizeHeading3: 30,
    fontSizeHeading2: 32,
    fontSizeHeading1: 32,
    fontSizeHeading5: 20,
  },
  components: {
    Button: {
      defaultBg: 'rgb(245, 243, 255)',
      defaultBorderColor: 'rgba(109, 40, 217, 0)',
      defaultColor: 'rgb(109, 40, 217)',
      controlHeight: 36,
      // TODO: Already defined as global token
      fontSize: 14,
      colorPrimary: '#6d28d9',
      colorPrimaryHover: '#8D4EE6',
      colorPrimaryActive: '#8D4EE6',
    },
    Typography: {
      // TODO: Already defined as global token
      fontSize: 16,
      fontSizeSM: 14,
      fontSizeLG: 18,
      fontSizeXL: 20,
    },
    Form: {
      labelColor: 'rgb(107, 114, 128)',
    },
    Input: {
      controlHeight: 40,
      // TODO: Already defined as global token
      colorBorder: '#d1d5db',
    },

    InputNumber: {
      controlHeight: 40,
    },
    Mentions: {
      controlHeight: 40,
    },
    Radio: {
      controlHeight: 40,
    },
    Select: {
      controlHeight: 40,
      optionSelectedColor: 'rgb(17, 24, 39)',
      optionSelectedBg: 'rgba(255, 255, 255, 0)',
      optionActiveBg: 'rgb(245, 243, 255)',
    },
    Slider: {
      controlHeight: 40,
    },
    Divider: {
      colorSplit: 'rgb(229, 231, 235)',
    },
    DatePicker: {
      controlHeight: 40,
    },
  },
};
