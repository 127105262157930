import { ICreateCommentBasePayload } from './comment.entity';

export enum IBookingPartyEnum {
  SHIPPER = 'shipper',
  CONSIGNEE = 'consignee',
  NOTIFY_PARTY = 'notify_party',
}
export interface IBookingPartyEntity {
  id: string;
  companyName: string;
  contactPerson: string;
  country: string;
  email: string;
  phoneNumber: string;
  address: string;
  note: string;
  role: IBookingPartyEnum;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface ICreateBookinPartyRequestBody {
  shipmentId?: string;
  companyName?: string;
  email?: string;
  role?: string;
  contactPerson?: string;
  phoneNumber?: string;
  country?: string;
  address?: string;
  note?: string;
}

export interface IUpdateBookingPartyRequestBody {
  id: string;
  data: IBookingPartyEntity;
}

export interface IGetBookingCommentPayload {
  bookingId: string;
}

export interface ICreateBookingCommentPayload
  extends ICreateCommentBasePayload {
  bookingId: string;
}
