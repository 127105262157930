import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import PurchaseOrderStatusSwitch from 'app/design-system/PurchaseOrderStatus';
import dayjs from 'dayjs';
import React from 'react';
import { IPurchaseOrderEntity } from 'types/entities/purchase-order.entity';

import { displayCountry } from 'utils/format-fields';
import { SlugPrefixEnum, convertSlug } from 'utils/slug-formattor';

export const purchaseOrdersColumnsConst: ColumnsType<IPurchaseOrderEntity> = [
  {
    title: 'Order',
    key: 'order',
    dataIndex: 'order',
    render: (value, record, index) => {
      return (
        <Typography.Text>
          {convertSlug(record.slug, SlugPrefixEnum.PO)}
        </Typography.Text>
      );
    },
  },
  {
    title: 'From',
    key: 'from',
    render: (value, record, index) => {
      return <Typography.Text>{displayCountry(record.origin)}</Typography.Text>;
    },
  },

  {
    title: 'To',
    key: 'to',
    render: (value, record, index) => {
      return (
        <Typography.Text>{displayCountry(record.destination)}</Typography.Text>
      );
    },
  },
  {
    title: 'Suplier',
    key: 'supplier',
    render: (value, record, index) => {
      return record.supplier.company.name;
    },
  },
  {
    title: 'CRD',
    key: 'crd',
    render: (value, record, index) => {
      return dayjs(record.createdAt).format('DD-MM-YYYY');
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (value, record, index) => {
      return (
        <PurchaseOrderStatusSwitch
          disabled
          id={record.id}
          status={record.status}
          toUpdate={false}
        />
      );
    },
  },
];
