import { INotificationRequest } from 'types/entities/notification.entity';
import {
  INotificationSetting,
  IUpdateNotificationSettingsReqBody,
} from 'types/entities/setting.entity';

import { api } from './base-api';

export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllNotifications: build.query<INotificationSetting[], void>({
      query: () => ({ url: `/notification-settings` }),
    }),
    getNotifications: build.query<any, INotificationRequest>({
      query: ({ page, limit, read }) => ({
        url: '/app-notifications',
        params: { page, limit, read },
      }),
      providesTags: ['AppNotifications'],
    }),
    updateNotification: build.mutation<any, string>({
      query: (id) => ({
        url: `/app-notifications/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['AppNotifications'],
    }),
    updateAllNotifications: build.mutation<void, void>({
      query: () => ({
        url: `/app-notifications/bulk`,
        method: 'PATCH',
      }),
      invalidatesTags: ['AppNotifications'],
    }),

    updateNotificationSettings: build.mutation<
      INotificationSetting[],
      IUpdateNotificationSettingsReqBody
    >({
      query: (body) => ({
        url: `/notification-settings`,
        method: 'PATCH',
        body: body,
      }),
    }),
  }),
});

export const {
  useUpdateNotificationMutation,
  useUpdateAllNotificationsMutation,
  useGetAllNotificationsQuery,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useUpdateNotificationSettingsMutation,
} = notificationsApi;
