import { NotificationOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { useActivateNotificationForProposalMutation } from 'api/proposal-external.api';
import React, { FC } from 'react';
import { INotificationActionProps } from 'types/feature/proposal.types';

import { displayErrors } from 'utils/error-notification';

const { confirm } = Modal;

const ProposalNotificationAction: FC<INotificationActionProps> = ({
  isProposalNotifEnabled,
  proposalParams,
  onProposalNotifChange,
}) => {
  const [activateNotificationSetting, { isLoading }] =
    useActivateNotificationForProposalMutation();

  const onActivateNotificationClick = (isActivated: boolean) => {
    confirm({
      title: 'Confirmation',
      okText: 'Accept',
      okType: 'primary',
      icon: null,
      content: `Do you really want to ${
        isActivated ? 'enable' : 'disable'
      } notification for this proposal ?`,
      onOk: async () => {
        activateNotificationSetting({
          rankNotificationEnabled: isActivated,
          params: {
            proposalId: proposalParams.id,
            email: proposalParams.email,
          },
        })
          .unwrap()
          .then(() => {
            message.success(
              `Notifications ${
                isActivated ? 'enabled' : 'disabled'
              } for this proposal !`
            );
            onProposalNotifChange(isActivated);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            displayErrors(error, { title: 'Error' });
          });
        return;
      },
    });
  };

  return (
    <Button
      loading={isLoading}
      type={isProposalNotifEnabled ? 'primary' : 'default'}
      icon={<NotificationOutlined />}
      onClick={() => onActivateNotificationClick(!isProposalNotifEnabled)}
    />
  );
};

export default ProposalNotificationAction;
