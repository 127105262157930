import { Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { OriginDestinationPreviewProps } from 'types/feature/shipments.types';

import { displayCountry } from 'utils/format-fields';

const DATE_FORMAT = 'DD/MM/YYYY';

const OriginDestinationPreview: React.FC<OriginDestinationPreviewProps> = ({
  shipment,
}) => {
  return (
    <Row justify="space-between">
      <Space direction="vertical" size={[1, 1]}>
        <Typography.Text strong>Origin</Typography.Text>
        <Typography.Text>{displayCountry(shipment.origin)}</Typography.Text>
        <Typography.Text type="secondary">
          {dayjs(shipment.cargoReadyDate).format(DATE_FORMAT)}
        </Typography.Text>
      </Space>
      <Space direction="vertical" size={[1, 1]}>
        <Typography.Text strong>Destination</Typography.Text>
        <Typography.Text>
          {displayCountry(shipment.destination)}
        </Typography.Text>
        <Typography.Text type="secondary">
          {shipment.eta ? dayjs(shipment.eta).format(DATE_FORMAT) : 'N/A'}
        </Typography.Text>
      </Space>
    </Row>
  );
};

export default OriginDestinationPreview;
