import { Row, Spin, Typography } from 'antd';
import { useGetBookingCollaboratorsByIdQuery } from 'api/Booking-collaborators.ts';
import {
  useCreateBookingCommentMutation,
  useGetBookingCommentsByIdQuery,
} from 'api/booking-comments';
import { getMentionPoUserList } from 'app/components/ShipmentMessages/shipment-messages-util';
import CommentBox from 'app/design-system/CommentBox';
import React, { FC, useEffect, useState } from 'react';
import { ICommentEntity } from 'types/entities/comment.entity';
import { IBookingCommentsProps } from 'types/feature/bookings.types';
import { IMentionInput, TMentionUserList } from 'types/feature/messages.type';

import { displayErrors } from 'utils/error-notification';

const BookingComments: FC<IBookingCommentsProps> = ({ bookingId }) => {
  const [commentState, setCommentState] = useState<ICommentEntity[]>([]);
  const [collaborators, setCollaborators] = useState<TMentionUserList | []>([]);

  const {
    data: bookingCommentsData,
    isLoading: fetchingComments,
    refetch: refetchBookingComments,
  } = useGetBookingCommentsByIdQuery({ bookingId });

  const [addBookingComment, { isLoading: isAddingComment }] =
    useCreateBookingCommentMutation();
  const { data: bookingCollabdata } = useGetBookingCollaboratorsByIdQuery({
    bookingId,
    search: '',
    withCollaborators: true,
  });

  useEffect(() => {
    if (bookingCollabdata) {
      setCollaborators(
        getMentionPoUserList(bookingCollabdata.data.items, true)
      );
    }
  }, [bookingCollabdata]);

  const onAddComment = async (comment: IMentionInput) => {
    if (!comment) return;

    try {
      await addBookingComment({
        bookingId,
        originalText: comment.originalText,
        markupText: comment.markupText,
        mentions: comment.mentions,
      }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Comment Error' });
    }
  };

  useEffect(() => {
    refetchBookingComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    setCommentState(bookingCommentsData?.data?.comments || []);
  }, [bookingCommentsData]);

  return (
    <>
      {fetchingComments ? (
        <Row className="py-3" justify="center">
          <Spin />
        </Row>
      ) : (
        <div className="booking-comments-wrapper">
          <Typography.Title level={4}>Comments</Typography.Title>
          <CommentBox
            topSuggestions
            comments={commentState}
            onAddComment={onAddComment}
            isLoading={isAddingComment}
            isExternal={false}
            userList={collaborators}
          />
        </div>
      )}
    </>
  );
};

export default BookingComments;
