import {
  IAddInvoiceItemByIdRequestBody,
  IInvoiceEntity,
} from 'types/entities/invoice.entity';

import { api } from './base-api';

export const invoiceItemApi = api.injectEndpoints({
  endpoints: (build) => ({
    putInvoiceItemById: build.mutation<
      IInvoiceEntity,
      IAddInvoiceItemByIdRequestBody
    >({
      query: (body) => ({
        url: `/invoice-item/${body.invoiceId}`,
        method: 'PUT',
        body: body.invoiceItems,
        invalidatesTags: ['InvoiceById'],
      }),
    }),

    deleteInvoiceItemById: build.mutation<IInvoiceEntity, string>({
      query: (invoiceId) => ({
        url: `/invoice-item/${invoiceId}`,
        method: 'DELETE',
        invalidatesTags: ['InvoiceById'],
      }),
    }),
  }),
});

export const {
  usePutInvoiceItemByIdMutation,
  useDeleteInvoiceItemByIdMutation,
} = invoiceItemApi;
