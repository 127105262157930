import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isFullLoad } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import React, { FC } from 'react';
import { IChargeHeadEntity } from 'types/entities/contract.entity';
import { UnitMeasurementEnum } from 'types/entities/fee.entity';
import { IChargesTableProps } from 'types/feature/contracts.type';

import { formatContainerName, splitState } from 'utils/format-fields';

import '../contracts.style.scss';

const columns: (isFullLoad: boolean) => ColumnsType<IChargeHeadEntity> = (
  isFullLoad
) => [
  { key: 'title', title: 'Description', dataIndex: 'description' },
  ...[
    isFullLoad
      ? {
          key: 'containerType',
          title: 'Container Type',
          render: (_: string, record: IChargeHeadEntity, index: number) =>
            record.containerType
              ? formatContainerName(record.containerType)
              : '--',
          width: '15%',
        }
      : {},
  ],
  {
    key: 'cost',
    title: 'Cost',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      record.measurementUnit === UnitMeasurementEnum.CHWT
        ? `${record.cost} / Kg`
        : record.cost,
    width: '10%',
  },
  {
    key: 'currency',
    title: 'Currency',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      record.currency.toUpperCase(),
    width: '5%',
  },

  {
    key: 'unit',
    title: 'Unit',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      splitState(record.measurementUnit),
    width: '10%',
    align: 'right',
  },
];

const ChargesTable: FC<IChargesTableProps> = ({
  charges,
  tableKey,
  shipmentType,
}) => {
  return (
    <Table
      key={tableKey}
      className="charges-table"
      rowKey={(record: IChargeHeadEntity) => record?.id ?? record?.description}
      columns={columns(isFullLoad(shipmentType))}
      dataSource={charges}
      size="small"
      pagination={false}
      showHeader={false}
    />
  );
};

export default ChargesTable;
