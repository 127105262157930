import { Card, Table, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useGetPendingReviewsInvoicesQuery } from 'api/invoice-analytics';
import React, { FC, useState } from 'react';
import { IPendingReviewInvoice } from 'types/entities/invoice.entity';
import { SorterParams } from 'types/feature/pagination.types';

import '../invoice-analytics.scss';
import { columns } from './pending-review-table.const';

const defaultSorting: SorterParams = { sortBy: 'assignee', sortOrder: 'DESC' };
const PendingReviewTable: FC = () => {
  const [sortingParams, setSortingParams] =
    useState<SorterParams>(defaultSorting);
  const { data, isFetching } = useGetPendingReviewsInvoicesQuery(sortingParams);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<IPendingReviewInvoice>
      | SorterResult<IPendingReviewInvoice>[]
  ) => {
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    setSortingParams({
      ...sortingParams,
      sortBy: singleSorter.field?.toString() ?? 'assignee',
      sortOrder: singleSorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };
  return (
    <Card className="invoice-table-card-content">
      <div>
        <Typography.Title className="invoice-card-title" level={5}>
          Pending Review
        </Typography.Title>
        <Table
          loading={isFetching}
          onChange={handleTableChange}
          pagination={false}
          className="invoice-card-table"
          rowClassName="invoice-card-table-row"
          size="small"
          columns={columns}
          dataSource={data?.data || []}
        />
      </div>
    </Card>
  );
};

export default PendingReviewTable;
