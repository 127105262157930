import AppTabs from 'app/design-system/AppTabs';
import DashboardCard from 'app/design-system/DashboardCard';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'state/reducer';

import { contractPageViewEvent } from 'utils/analytics-events';

import ContractSearchAndBook from './ContractSearchAndBook';
import { ContractsTabs } from './ContractsTabs';

const Contracts: FC = () => {
  const location = useLocation();
  const activeTab = location.state ? location.state.activeTab : 'search';
  const campaign = useUTMState();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (user) {
      contractPageViewEvent(
        {
          user_id: user?.id || '',
          email: user?.email || '',
          company: user?.company?.name || '',
          company_id: user?.companyId || '',
        },
        { campaign }
      );
    }
  }, [user, campaign]);

  return (
    <DashboardCard noMargin fitContent>
      <AppTabs
        defaultActiveKey={activeTab}
        items={[
          {
            label: 'Search & Book',
            key: 'search',
            children: <ContractSearchAndBook />,
          },
          {
            label: 'Contracts',
            key: 'contracts',
            children: <ContractsTabs />,
          },
        ]}
      />
    </DashboardCard>
  );
};

export default Contracts;
