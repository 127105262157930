import { DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Form,
  InputNumber,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { bookingApi, useDeleteBookingItemsMutation } from 'api/bookings';
import React, { FC } from 'react';
import {
  addAllBookingItems,
  removeAllBookingItems,
  removeBookingItem,
} from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IDraftBookingEntity } from 'types/entities/booking.entity';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';

import { ReactComponent as EmptyOrders } from '../../../../../assets/orders.svg';
import { draftBookingColumns } from '../draft-booking.const';
import '../draft-booking.scss';

const { Text } = Typography;
const DraftBookingTable: FC<{ booking: IDraftBookingEntity }> = ({
  booking,
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [deleteBookingItems, { isLoading: isDeleting }] =
    useDeleteBookingItemsMutation();

  const onItemsUpdated = () => {
    if (booking.bookingId)
      bookingApi.util.invalidateTags([
        { type: 'Bookings', id: booking.bookingId },
      ]);
  };

  const onEditBookedValue = (item: IPurchaseOrderItemEntity) =>
    dispatch(
      addAllBookingItems({ order: { id: booking.order }, items: [item] })
    );

  const onDeleteItemsFromBooking = async (ids: string[]) => {
    try {
      if (booking.bookingId)
        await deleteBookingItems({
          ids,
          bookingId: booking.bookingId,
        });
      onItemsUpdated();
      notification.success({
        message: 'Chosen item deleted successfully !',
      });
    } catch (error) {
      notification.error({
        message: 'Delete Error',
        description:
          'Something went wrong while deleting booking items! please retry ...',
      });
    }
  };
  const onRemoveItem = async (item: IPurchaseOrderItemEntity) => {
    if (item.bookingItemId) {
      await onDeleteItemsFromBooking([item.bookingItemId]);
    }
    dispatch(removeBookingItem({ order: booking.order, id: item.id }));
  };

  const onRemoveAllItems = async () => {
    const ids = booking.items
      .filter((item) => !!item.bookingItemId)
      .map((item) => item.bookingItemId ?? '');
    if (ids.length) {
      await onDeleteItemsFromBooking(ids);
    }
    dispatch(removeAllBookingItems({ order: booking.order }));
  };

  const editColumn: (ColumnType<IPurchaseOrderItemEntity> & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: 'Booked',
      dataIndex: 'toBook',

      render: (_, record: IPurchaseOrderItemEntity, index: number) => {
        return (
          <InputNumber
            className="book-input"
            max={(record.requestedQuantity ?? 0) - (record.bookedQuantity ?? 0)}
            min={0}
            value={record.toBook}
            onChange={(value: number | null) =>
              onEditBookedValue({ ...record, toBook: value ?? 0 })
            }
          />
        );
      },

      width: '20%',
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: <Button onClick={onRemoveAllItems}> Remove all </Button>,
      render: (_, record: IPurchaseOrderItemEntity) => {
        return (
          <Space>
            <Button
              size="small"
              danger
              icon={<DeleteFilled />}
              onClick={() => onRemoveItem(record)}
              disabled={isDeleting}
            ></Button>
          </Space>
        );
      },
      align: 'right',
      width: 100,
    },
  ];

  const defaultColumns = [...draftBookingColumns, ...editColumn];

  return (
    <div className="draft-booking-wrapper">
      <Text strong className="order-title">
        PO {booking.refernce}
      </Text>
      <Form form={form}>
        <Table
          columns={defaultColumns}
          dataSource={booking.items}
          rowKey="id"
          size="small"
          className="items-table"
          pagination={false}
          bordered
          locale={{
            emptyText: (
              <Space direction="vertical" className="empty-table-wrapper">
                <EmptyOrders />
                <Text strong>Purchase Orders</Text>
                <Text type="secondary">Create new orders to view items</Text>
              </Space>
            ),
          }}
        />
      </Form>
    </div>
  );
};

export default DraftBookingTable;
