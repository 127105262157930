import { Typography } from 'antd';
import ProfileImage from 'app/components/ProfilePicture';
import React, { FC } from 'react';
import { ICommentItemProps } from 'types/feature/comment.types';

import './comment-item.scss';

const { Text } = Typography;

const CommentItem: FC<ICommentItemProps> = ({ comment }) => {
  return (
    <div className="comment-wrapper">
      <div className="comment-item">
        <ProfileImage
          wrapperClassName="user-gravatar"
          name={comment.userName}
          photoUrl={comment.userPhoto}
          size={30}
        />
      </div>
      <div className="comment-content">
        <div className="info-wrapper">
          <Text className="user-name">{comment.userName}</Text>
          <Text className="message-date">{comment.messageDate}</Text>
        </div>
        <Text className="user-message">{comment.message}</Text>
      </div>
    </div>
  );
};

export default CommentItem;
