import { Space, Button } from 'antd';
import { useGetInvoiceVendorsQuery } from 'api/invoice';
import { useGetTeamMembersQuery, useGetUserConnectionsQuery } from 'api/user';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import { IInvoiceFiltersProps } from 'types/feature/invoice-list';

import { computeAllFiltersCount } from 'utils/shipment-helper';

import AssigneeFilter from './InvoiceAssigneeFilter';
import InvoiceFilterDrawer from './InvoiceFilterDrawer';
import InvoiceStatusFilter from './InvoiceStatusFilter';
import VendorsFilter from './InvoiceVendorsFilter';

const InvoiceFilters: FC<IInvoiceFiltersProps> = ({
  onFilterChange,
  selectedFilters,
  onFilterDrawerChange,
  onReset,
  isStatusFiltered,
}) => {
  const [allContacts, setAllContacts] = useState<IUserPartnerEntity[]>([]);
  const { data: vendorsData } = useGetInvoiceVendorsQuery();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const { data: teamData } = useGetTeamMembersQuery();
  const { data: connectionsData } = useGetUserConnectionsQuery(null);

  useEffect(() => {
    if (connectionsData && teamData && currentUser) {
      const newConnections = connectionsData?.map(({ partner }) => partner);
      const combinedContacts = [currentUser, ...teamData, ...newConnections];
      setAllContacts(combinedContacts);
    }
  }, [connectionsData, teamData, currentUser]);
  const filterCount = isStatusFiltered
    ? computeAllFiltersCount({ ...selectedFilters, status: [] })
    : computeAllFiltersCount(selectedFilters); //if status is filtered by changing tabs, don't count it as a filter
  return (
    <div>
      <Space>
        <FilterDropdown
          isDisabled={isStatusFiltered}
          filters={selectedFilters}
          renderFilterComponent={
            <InvoiceStatusFilter
              layout="vertical"
              defaultValue={selectedFilters.status}
              onChange={(values) =>
                onFilterChange(values as string[], 'status')
              }
            />
          }
          buttonText="Status"
          itemKey="status"
        />
        {selectedFilters && vendorsData && (
          <VendorsFilter
            vendorsData={vendorsData.data || []}
            onFilterChange={onFilterChange}
            selectedFilters={selectedFilters}
          />
        )}
        <AssigneeFilter
          onFilterChange={onFilterChange}
          selectedFilters={selectedFilters}
          allContacts={allContacts}
        />
        <InvoiceFilterDrawer
          vendors={vendorsData?.data || []}
          defaultValue={selectedFilters}
          onChange={onFilterDrawerChange}
          onReset={onReset}
          isStatusFiltered={isStatusFiltered}
          filterCount={filterCount}
        />
        {filterCount > 0 && (
          <Button type="link" onClick={onReset}>
            Clear All
          </Button>
        )}
      </Space>
    </div>
  );
};

export default InvoiceFilters;
