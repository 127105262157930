import {
  Button,
  DatePicker,
  Form,
  Row,
  Space,
  message,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import ShipmentDetailsForm from 'app/components/ShipmentCreation/ShipmentDetailsForm';
import ProposalDiffViewer from 'app/components/ShipmentQuotes/ProposalDiffViewer';
import DashboardCard from 'app/design-system/DashboardCard';
import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IFeeEntity } from 'types/entities/fee.entity';
import {
  IProposalEntity,
  IUpdateProposalRequestBody,
} from 'types/entities/proposal.entity';
import { IShipmentDetailsForm } from 'types/feature/create-shipment.types';
import {
  AddProposalFormProps,
  IReferenceForm,
  IProposalItemsForm,
  IProposalImpDetailsForm,
} from 'types/feature/proposals.types';

import { submitProposalEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import {
  isProposalRejected,
  isSuggestedProposalExist,
} from 'utils/proposal-helper';
import { isForwarder } from 'utils/shipment-helper';

import FeesDetails from '../Fees';
import ImportantDetails from '../ImportantDetails';
import OtherDetails from '../OtherDetails';
import { formItemLayout } from '../OtherDetails/other-details.const';
import ProposalCommentBox from '../ProposalCommentBox';
import {
  computeDefaultFeeItem,
  computeDefaultImportantDetails,
  computeProposalDefaultShipmentDetails,
} from './proposal-form.utils';

// style
export interface RefObject {
  onValidate: () => Promise<boolean>;
}

const ProposalForm: FC<AddProposalFormProps> = ({
  shipment,
  proposal,
  onPostSubmit,
  email,
  isExternalForwarder,
  referenceForm,
  isFieldEditDisabled,
  isAdmin = false,
  showProposalDiffViewer,
  updateProposalMutation,
  updateAcceptedProposalMutation,
  deleteProposalCargoUnitMutation,
  revokeSuggestedProposalMutation,
}) => {
  const [proposalForm] = useForm<IProposalEntity>();
  const [shipmentDetailsForm] = useForm<IShipmentDetailsForm>();
  const [proposalFeeItemsForm] = useForm<IProposalItemsForm>();
  const [proposalImpDetailsFrom] = useForm<IProposalImpDetailsForm>();

  const isForwarderRole = isExternalForwarder
    ? true
    : isForwarder(shipment.currentUserRole);

  const currentUser = useSelector((state: RootState) => state.user.user);
  // delete shipment item
  const [deleteProposalCargoUnit] = deleteProposalCargoUnitMutation();

  // for internal updates i.e. by cargo owner
  const [updateProposal, { data, isLoading, isSuccess, isError, error }] =
    updateProposalMutation();

  const [revokeSuggestedProposalByI, { isLoading: isDeleteSuggestedLoading }] =
    revokeSuggestedProposalMutation();

  // for accepted internal updates i.e. by cargo owner
  const [
    updateAcceptedProposal,
    {
      data: updateAcceptedProposalData,
      isLoading: isUpdateAcceptedProposalLoading,
      isSuccess: isUpdateAcceptedProposalSuccess,
      isError: isUpdateAcceptedProposalError,
      error: updateAcceptedProposalError,
    },
  ] = updateAcceptedProposalMutation();

  const { data: allShipmentTypesData } = useGetExternalShipmentsTypesQuery();

  useEffect(() => {
    if (isSuccess || isUpdateAcceptedProposalSuccess) {
      notification.success({
        message: 'RFQ Submitted successfully',
        description: 'Your proposal for this shipment has been submited !',
      });
      if (data || updateAcceptedProposalData) onPostSubmit();
    }
    // eslint-disable-next-line
  }, [
    isSuccess,
    data,
    updateAcceptedProposalData,
    isUpdateAcceptedProposalSuccess,
  ]);

  useEffect(() => {
    if (isError || isUpdateAcceptedProposalError) {
      displayErrors(error || updateAcceptedProposalError);
    }
  }, [
    error,
    isError,
    updateAcceptedProposalError,
    isUpdateAcceptedProposalError,
  ]);

  // methods
  const onSubmitProposal = async () => {
    try {
      const proposalFormValues: IProposalEntity =
        await proposalForm.validateFields();
      const referenceFormValues: IReferenceForm | undefined =
        await referenceForm?.validateFields();
      const shipmentDetailsFormValues: IShipmentDetailsForm | undefined =
        await shipmentDetailsForm?.validateFields();
      const proposalFeeItemsFormValues: IProposalItemsForm | undefined =
        await proposalFeeItemsForm?.validateFields();
      const proposalImpDetailsFormValues: IProposalImpDetailsForm | undefined =
        await proposalImpDetailsFrom?.validateFields();

      if (!proposalFeeItemsFormValues?.proposalItems.length) {
        message.warning(
          'Please make sure to add at least one proposal item under fees section !'
        );
        return;
      }

      const mappedProposalItems: IFeeEntity[] =
        proposalFeeItemsFormValues.proposalItems.map((item: IFeeEntity) => {
          return {
            id: item.id ?? undefined,
            description: item.description,
            currency: item.currency,
            rate: item.rate,
            proposalId: proposal.id,
            quantity: item.quantity,
            measurementUnit: item.measurementUnit,
            totalCost: item.totalCost,
          };
        });
      const proposalWithShipmentItems: IUpdateProposalRequestBody = {
        proposal: {
          ...proposalFormValues,
          ...proposalImpDetailsFormValues,
          id: proposal.id,
          proposalItems: mappedProposalItems,
          proposalReference: referenceFormValues?.proposalReference || null,
          shipmentTypeId: shipmentDetailsFormValues.shipmentTypeId || null,
          assignee: {
            ...proposal.assignee,
            email: email ?? '',
          },
        },
        shipmentItems: shipmentDetailsFormValues.cargoUnits,
      };

      if (isFieldEditDisabled) {
        const acceptedProposalPayload = {
          proposal: {
            note: proposalFormValues.note,
            proposalReference: referenceFormValues?.proposalReference || null,
          },
          params: {
            id: proposal.id,
            email: proposal.assignee.email,
          },
        };

        await updateAcceptedProposal(acceptedProposalPayload);
      } else {
        await updateProposal(proposalWithShipmentItems);
      }

      if (allShipmentTypesData) {
        submitProposalEvent({
          user_id: currentUser?.id,
          email: currentUser?.email,
          company: currentUser?.company?.name || '',
          shipment_id: proposal.shipmentId,
          proposal_id: proposal.id,
          company_id: currentUser?.companyId,
          submitted_by: isExternalForwarder ? 'Forwarder' : 'Cargo Owner',
          shipment_type: shipment.shipmentType.title,
          shipment_modality: shipment.shipmentType.modality,
          proposal_type: allShipmentTypesData[
            shipmentDetailsFormValues.modality
          ].find((s) => s.id === shipmentDetailsFormValues.shipmentTypeId)
            ?.title,
          proposal_modality: shipmentDetailsFormValues.modality,
        });
      }
    } catch (error) {
      notification.warning({
        message: 'Make sure that you filled all required fields!',
      });
    }
  };

  const onRevokeChanges = () => {
    revokeSuggestedProposalByI({
      proposalId: proposal.id,
      email: proposal.assignee.email,
    });
  };

  useEffect(() => {
    if (proposal) {
      shipmentDetailsForm.resetFields();
    }
    // eslint-disable-next-line
  }, [proposal]);

  return (
    <>
      {showProposalDiffViewer && isSuggestedProposalExist(proposal) ? (
        <DashboardCard headerTitle="" large fitContent>
          <ProposalDiffViewer
            proposal={proposal}
            isActionsEnabled={false}
            proposalCurrency={shipment.createdBy.company.currency}
          />
        </DashboardCard>
      ) : (
        <>
          <ShipmentDetailsForm
            form={shipmentDetailsForm}
            defaultValues={computeProposalDefaultShipmentDetails(
              proposal,
              shipment,
              isForwarderRole
            )}
            isEditView={false}
            defaultShipmentType={
              isForwarderRole && proposal.suggestedProposal
                ? proposal.suggestedProposal.shipmentType
                : proposal.shipmentType || shipment?.shipmentType
            }
            isFieldEditDisabled={isFieldEditDisabled}
            proposalParams={{
              email: email ?? '',
              proposalId: proposal.id,
            }}
            deleteCargoUnitMutation={deleteProposalCargoUnit}
            viewShipmentTypeAndCargoDetails={true}
          />
          <FeesDetails
            isFieldEditDisabled={isFieldEditDisabled}
            isExternalForwarder={isExternalForwarder}
            form={proposalFeeItemsForm}
            defaultValues={computeDefaultFeeItem(proposal, isForwarderRole)}
            proposalParams={{
              email: email ?? '',
              proposalId: proposal.id,
            }}
          />

          <ImportantDetails
            isFieldEditDisabled={isFieldEditDisabled}
            form={proposalImpDetailsFrom}
            defaultValues={computeDefaultImportantDetails(
              proposal,
              isForwarderRole
            )}
          />
        </>
      )}
      <Form
        {...formItemLayout}
        form={proposalForm}
        scrollToFirstError
        name="other-details"
        initialValues={{
          expirationDate: dayjs(proposal.expirationDate ?? Date()),
        }}
      >
        <OtherDetails
          proposalId={proposal?.id}
          files={proposal.files}
          email={email ?? ''}
          isExternalForwarder={isExternalForwarder}
        />
        <ProposalCommentBox
          proposal={proposal}
          isExternalForwarder={isExternalForwarder}
        />
        <Row
          justify={'space-between'}
          align={'middle'}
          className="proposal-page__footer"
        >
          <Form.Item
            label="Expiry Date"
            name="expirationDate"
            rules={[
              {
                required: true,
                message: 'Please insert expiry date!',
              },
            ]}
          >
            <DatePicker
              disabledDate={(date: dayjs.Dayjs) => date < dayjs()}
              disabled={isFieldEditDisabled}
            />
          </Form.Item>
          <Space>
            {isForwarderRole && proposal.suggestedProposal && (
              <Button
                danger
                type="default"
                onClick={onRevokeChanges}
                disabled={
                  isLoading ||
                  isUpdateAcceptedProposalLoading ||
                  (!isAdmin && isProposalRejected(proposal.status))
                }
                loading={isDeleteSuggestedLoading}
              >
                Revoke Changes
              </Button>
            )}
            <Button
              type="primary"
              onClick={onSubmitProposal}
              disabled={
                isLoading ||
                isUpdateAcceptedProposalLoading ||
                (!isAdmin && isProposalRejected(proposal.status))
              }
              loading={isLoading || isUpdateAcceptedProposalLoading}
            >
              (Re)submit Proposal
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
export default ProposalForm;
