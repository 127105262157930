import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';
import { IUserEntity } from 'types/entities/user.entity';
import { IForwarderContactsTableProps } from 'types/feature/forwarders.types';

import { forwarderColumnsConst } from '../request-quote.const';
import './recent-forwarders.scss';

const ForwarderContactsTable: FC<IForwarderContactsTableProps> = ({
  companyId,
  forwarders,
  contactsRowSelection,
  onInviteForwarders,
}) => {
  const allColumns: ColumnsType<IUserEntity> = [
    ...forwarderColumnsConst,
    {
      key: 'action',
      width: '2%',
      render: (_: unknown, record: IUserEntity, index: number) => (
        <Button
          type="default"
          onClick={() =>
            onInviteForwarders([
              {
                companyId,
                forwarders: [record.id],
              },
            ])
          }
        >
          {record.invited ? 'Resend' : 'Invite'}
        </Button>
      ),
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
    },
  ];

  return (
    <Table
      className="forwarders-contact-table table-wrapper--no-border"
      dataSource={forwarders ?? []}
      columns={allColumns}
      rowSelection={contactsRowSelection}
      locale={{ emptyText: 'No recent forwarders added' }}
      rowKey={(item: IUserEntity) => item.id}
      pagination={false}
    />
  );
};

export default ForwarderContactsTable;
