import {
  ICollaboratorsEntity,
  IInviteCollaboratorBaseRequestBody,
  IInviteCollaboratorRequestBody,
  IInviteExisitingCollaboratorRequestBody,
  IInviteRecentCollaboratorRequestBody,
  IRemoveCollaboratorReq,
} from 'types/entities/collaborator.entity';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';

import { api } from './base-api';

export const collaboratorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCollaboratorByShipment: build.query<ICollaboratorsEntity[], string>({
      query: (shipmentId) => `/shipment-collaborator/${shipmentId}`,
      providesTags: ['ShipmentCollaborators'],
    }),

    getRecentCollaboratorByShipment: build.query<
      IRecentCollaboratorDef[],
      { shipmentId?: string; search?: string; isCommunication?: boolean }
    >({
      query: ({ shipmentId, search, isCommunication }) => ({
        url: `/shipment-collaborator/recent/${shipmentId}`,
        params: { search, isCommunication },
      }),
      providesTags: ['Collaborators'],
    }),

    inviteCollaborator: build.mutation<string, IInviteCollaboratorRequestBody>({
      query: (payload) => ({
        url: `/shipment-collaborator/invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Collaborators', 'Team'],
    }),

    inviteRecentCollaborator: build.mutation<
      string,
      IInviteRecentCollaboratorRequestBody
    >({
      query: (payload) => ({
        url: `/shipment-collaborator/invite/recent`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Collaborators', 'Team'],
    }),

    updateCollaborator: build.mutation<
      string,
      { payload: IInviteCollaboratorBaseRequestBody; collaboratorId: string }
    >({
      query: ({ payload, collaboratorId }) => ({
        url: `/shipment-collaborator/${collaboratorId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Collaborators', 'Team'],
    }),

    removeCollaborator: build.mutation<string, IRemoveCollaboratorReq>({
      query: ({ collaboratorId, shipmentId, isLastForwarder }) => ({
        url: `/shipment-collaborator/${shipmentId}/${collaboratorId}`,
        method: 'DELETE',
        params: { isLastForwarder },
      }),
      invalidatesTags: ['Collaborators', 'Team'],
    }),

    inviteForwarderExistingColleagues: build.mutation<
      string,
      IInviteExisitingCollaboratorRequestBody
    >({
      query: (payload) => ({
        url: `/shipment-collaborator/invite-colleagues`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Collaborators'],
    }),

    inviteExistingCollaborator: build.mutation<
      string,
      IInviteExisitingCollaboratorRequestBody
    >({
      query: (payload) => ({
        url: `/shipment-collaborator/invite-existing-connections`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'Collaborators',
        'ShipmentsById',
        'ShipmentBookingCollaborators',
      ],
    }),
  }),
});

export const {
  useGetCollaboratorByShipmentQuery,
  useLazyGetCollaboratorByShipmentQuery,
  useGetRecentCollaboratorByShipmentQuery,
  useInviteCollaboratorMutation,
  useInviteExistingCollaboratorMutation,
  useInviteForwarderExistingColleaguesMutation,
  useInviteRecentCollaboratorMutation,
  useUpdateCollaboratorMutation,
  useRemoveCollaboratorMutation,
} = collaboratorApi;
