import { FileAddOutlined } from '@ant-design/icons';
import { Upload, UploadProps, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useUploadOrderFileMutation } from 'api/purchase-order';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IAdditionalFormProps } from 'types/feature/purchase-orders.types';

const { Dragger } = Upload;
const MAX_FILE_NUMBER = 12;
export type AdditionalInfoFormRef = {
  getUrls: () => void;
};

const getFileName = (url: string) =>
  url?.includes('documentParsed')
    ? 'Highlighted PO document'
    : 'Original PO document';

const AdditionalInfoForm = forwardRef<
  AdditionalInfoFormRef,
  IAdditionalFormProps
>(({ footer, uploadedPurchaseOrderfile }, ref) => {
  const [urls, setAttachments] = useState<string[]>(
    uploadedPurchaseOrderfile ?? []
  );

  const [uploadDocument] = useUploadOrderFileMutation();

  useImperativeHandle(
    ref,
    function () {
      return {
        getUrls: () => {
          return urls;
        },
      };
    },
    [urls]
  );

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const uploadOrderFile = async (options: any) => {
    if (urls.length >= MAX_FILE_NUMBER) {
      message.info(`You can't upload more than ${MAX_FILE_NUMBER} documents`);
      return;
    }

    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('file', file);

    await uploadDocument(formdata)
      .unwrap()
      .then((response) => {
        onSuccess();
        setAttachments((value) => [...value, response[0]]);
      })
      .catch((error) => onError());
  };
  const fileDraggerProps: UploadProps = {
    defaultFileList: urls.map((item, index) => ({
      uid: `-${index}`,
      name: getFileName(item),
      status: 'done',
      url: item,
    })),
    maxCount: MAX_FILE_NUMBER,
    multiple: false,
    customRequest: uploadOrderFile,
    onChange(info: UploadChangeParam<UploadFile<string>>) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(
          `${info.file.name} file upload failed, please try again !`
        );
      }
    },
  };

  return (
    <DashboardCard
      large
      headerTitle="Additional Information"
      footer={footer}
      fitContent
    >
      <Dragger {...fileDraggerProps}>
        <p className="ant-upload-drag-icon">
          <FileAddOutlined />
        </p>
        <p className="ant-upload-text">Attach Documents (Optional)</p>
        <p className="ant-upload-hint">
          Click or Drag files here to upload (12 files maximum)
        </p>
      </Dragger>
    </DashboardCard>
  );
});

AdditionalInfoForm.displayName = 'AdditionalInfoForm';

export default AdditionalInfoForm;
