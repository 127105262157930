import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Form, Button, Table, message, Space, Tooltip, Popconfirm } from 'antd';
import { useDeleteChargeHeadByIdMutation } from 'api/contract';
import React, { FC, useEffect, useState } from 'react';
import { IChargeHeadEntity } from 'types/entities/contract.entity';
import { CurrencyEnum, UnitMeasurementEnum } from 'types/entities/fee.entity';
import { IChargeTableFormProps } from 'types/feature/contracts.type';

import { displayErrors } from 'utils/error-notification';

import '../contract-creation.scss';
import { ChargesEditableCell, columns } from './ChargeTableCells';

const ChargeTableForm: FC<IChargeTableFormProps> = ({
  defaultValues,
  onValuesChange,
  tableKey,
  contractId,
}) => {
  const [form] = Form.useForm<IChargeHeadEntity>();
  const [data, setData] = useState<IChargeHeadEntity[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: IChargeHeadEntity) => {
    return record.id === editingKey;
  };
  const edit = (record: IChargeHeadEntity) => {
    form.setFieldsValue({ ...record });

    setEditingKey(record.id ?? '');
  };

  const cancelEdit = () => {
    setEditingKey('');
  };

  const saveEdit = async () => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      const index = newData.findIndex((item) => editingKey === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
      onValuesChange(newData);
      form.resetFields();
    } catch (errInfo) {
      message.warning('Please enter all required fields');
    }
  };
  const [deleteChargeHead, { isLoading }] = useDeleteChargeHeadByIdMutation();

  const onDeleteChargeHead = async (id: string) => {
    try {
      if (contractId) {
        await deleteChargeHead({ contractId, id }).unwrap();
        message.success('Cost Head deleted succesfully !');
      }
      //eslint-disable-next-line
    } catch (err: any) {
      displayErrors(err, { title: 'Delete Costhead Error' });
    }
  };
  const removeCharge = (record: IChargeHeadEntity) => {
    try {
      if (record.id && !record.id.includes('chargeHead'))
        onDeleteChargeHead(record.id);
      const newData = data.filter((item) => record.id !== item.id);
      setData(newData);
      onValuesChange(newData);
    } catch (errInfo) {
      message.warning('Something went wrong while deleting this charge head !');
    }
  };

  const addChargeHead = () => {
    try {
      const newData = [...data];
      const key = `chargeHead-${data.length}`;
      const newItem: IChargeHeadEntity = {
        id: key,
        description: '',
        cost: 0,
        currency: CurrencyEnum.USD,
        measurementUnit: UnitMeasurementEnum.PERCONTAINER,
      };
      newData.push(newItem);
      setData(newData);
      onValuesChange(newData);
      setEditingKey(key);
    } catch (errInfo) {
      message.warning('Something went wrong while deleting this charge head !');
    }
  };

  useEffect(() => {
    if (defaultValues) {
      setData(
        defaultValues.map((charge, index) => ({
          ...charge,
          id: charge.id ?? `chargeHead-${index}`,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues.length]);

  const allColumns = [
    ...columns,
    {
      title: 'Actions',
      dataIndex: 'operation',
      editable: false,
      width: '8%',
      render: (_: string, record: IChargeHeadEntity) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              icon={<CheckOutlined />}
              onClick={saveEdit}
              type="default"
              size="small"
              className="accept-btn"
            />
            <Button
              icon={<CloseOutlined />}
              onClick={cancelEdit}
              type="dashed"
              size="small"
              danger
            />
          </Space>
        ) : (
          <Space>
            <Tooltip title="Edit this charge head !">
              <Button
                disabled={editingKey !== ''}
                icon={<EditFilled />}
                onClick={() => edit(record)}
                type="text"
                size="small"
              />
            </Tooltip>
            <Popconfirm
              title="Delete Charges"
              description="Are you sure to delete this charge head?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => removeCharge(record)}
            >
              <Button
                icon={<DeleteFilled />}
                type="text"
                size="small"
                danger
                loading={isLoading}
                disabled={isLoading}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    // TODO : this is not enough in case user switched UnitMeasurementEnum while editing
    // const isContainerDisabled =
    //   form.getFieldValue('measurementUnit') === UnitMeasurementEnum.CHWT &&
    //   col.dataIndex === 'containerType';

    return {
      ...col,
      onCell: (record: IChargeHeadEntity) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const renderChargesTable = (tableKey: React.Key) => (
    <Table
      key={tableKey}
      components={{
        body: {
          cell: ChargesEditableCell,
        },
      }}
      dataSource={data}
      columns={mergedColumns}
      rowClassName="editable-row"
      pagination={false}
      className="table-wrapper"
      size="small"
      rowKey="id"
      footer={() => (
        <Button
          type="link"
          onClick={addChargeHead}
          icon={<PlusCircleOutlined />}
          disabled={!!editingKey}
        >
          Add Charge Head
        </Button>
      )}
    />
  );

  return (
    <Form form={form} component={false} key={tableKey}>
      {renderChargesTable(tableKey)}
    </Form>
  );
};

export default ChargeTableForm;
