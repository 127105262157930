import { FirebaseError } from 'firebase/app';
import { User } from 'firebase/auth';

export enum AuthProviderEnum {
  GOOGLE = 'Google',
  MS = 'Microsoft',
}

export enum AuthTypeEnum {
  LOGIN = 'Login',
  SIGNUP = 'Sign up',
  RESET = 'Reset',
  SEND_EMAIL = 'SEND',
}

export interface IAuthToken {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export interface ISignUpFormProps {
  login: () => void;
  navigateToMainPage: () => void;
}

export interface ILoginFormProps {
  signup: () => void;
  navigateToMainPage: () => void;
  onForgotPassword: () => void;
}

export interface SocialAuthButtonsProps {
  navigateToMainPage: () => void;
  mode: AuthTypeEnum;
}

// parent component props
export interface IAuthProps {
  type: AuthTypeEnum;
  external?: boolean;
}

export type LoginState = {
  loading: boolean;
  error: FirebaseError | string | null;
  isSuccess?: boolean;
};

export interface IForgotPasswordProps {
  navigateToMainPage: () => void;
  onPasswordEmailSent: () => void;
}

export interface IResetPasswordProps {
  navigateToMainPage: () => void;
  resendVerificationCode: () => void;
}

export type AuthUserType = Partial<User> | null | undefined;
export type AuthContextType =
  | {
      user: AuthUserType;
    }
  | undefined;

export interface IAuthContextProviderProps {
  children: JSX.Element;
}
