import { Button, Typography } from 'antd';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC } from 'react';
import {
  IOverviewScreenProps,
  SignupOnboardingScreenEnum,
} from 'types/feature/user-onboarding';

import { ReactComponent as MapIcon } from '../../../../assets/iconsNew/map-icon.svg';
import { ReactComponent as POIcon } from '../../../../assets/iconsNew/po-icon.svg';
import { ReactComponent as ShipmentQuotesIcon } from '../../../../assets/iconsNew/shipment-quotes-icon.svg';

const OverviewScreen: FC<IOverviewScreenProps> = ({
  onScreenChange,
  onClose,
}) => {
  const items = [
    // TODO: Implementation in progress
    {
      key: SignupOnboardingScreenEnum.PO_SCREEN,
      title: 'Create a Purchase Order',
      icon: <POIcon />,
      onClick: onScreenChange,
    },
    {
      key: SignupOnboardingScreenEnum.CREATE_SHIPMENT_SCREEN,
      title: 'Request Shipping Quotes',
      icon: <ShipmentQuotesIcon />,
      onClick: onScreenChange,
    },
    {
      key: SignupOnboardingScreenEnum.IMPORT_SHIPMENT_SCREEN,
      title: 'Track My Shipments',
      icon: <MapIcon />,
      onClick: onScreenChange,
    },
  ];

  return (
    <>
      <ModalHeader
        title="Where can we help?"
        subTitle="We’re building Beebolt to be the ultimate supply-chain side kick. Let us help you take something off your plate."
      />

      <div className="signup-onboarding-content">
        {items.map((item) => (
          <div
            key={item.key}
            className="icon-box"
            onClick={() => onScreenChange(item.key)}
          >
            <div className="icon-box__icon">{item.icon}</div>
            <div className="icon-box__title">
              <Typography.Text strong>{item.title}</Typography.Text>
            </div>
          </div>
        ))}
      </div>

      <Button type="link" className="skip-btn" onClick={onClose}>
        Skip, I’ll explore myself
      </Button>
    </>
  );
};

export default OverviewScreen;
