export enum UserTablesEnum {
  PurchaseOrderTable = 'purchase_order',
  PurchaseOrderItemsTable = 'purchase_order_items',
  BookingTable = 'bookings',
  shipmentsTable = 'shipments',
  contractsTable = 'contracts',
  proposalTable = 'proposals',
  invoicesTable = 'invoices',
  containersTable = 'containers',
}

export interface IGetUserTableSetting {
  tableName: UserTablesEnum;
}
export interface ISetUserTableSetting {
  tableName: UserTablesEnum;
  setting: string;
}

export interface IUserTableSetting {
  setting: string;
}
