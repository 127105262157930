import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Alert, Form, Input, Button, notification } from 'antd';
import { checkExternalUser } from 'api/auth.firebase';
import React, { FC, useState } from 'react';
import { AuthRequestBody } from 'types/entities/auth.entity';
import { LoginState } from 'types/feature/auth.types';

import { ReactComponent as BeeboltLinedLogo } from '../../../assets/appIcons/beebolt-icon.svg';
import './admin.scss';

//https://freight-staging.firebaseapp.com/__/auth/action?mode=signIn&oobCode=ItUyEoC39VIn4ZHvL[%E2%80%A6]l=http%3A%2F%2Flocalhost%3A4000%2Fadmin-login&lang=en

const { Text, Title } = Typography;
const AdminLogin: FC = () => {
  const [form] = Form.useForm();
  const [loginState, setLoginState] = useState<LoginState>({
    loading: false,
    error: null,
    isSuccess: false,
  });

  const goToDashboard = () => (document.location.href = '/dashboard');

  const onSubmit = async () => {
    setLoginState({
      loading: true,
      error: null,
      isSuccess: false,
    });

    try {
      const values: AuthRequestBody = await form.validateFields();
      const resp = await checkExternalUser(values.email);

      if (!resp.isSuccess) {
        throw new Error(resp.errorMessage);
      }
      setLoginState({
        loading: false,
        error: null,
        isSuccess: resp.isSuccess,
      });
      goToDashboard();
      form.resetFields();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notification.error({
        message: 'Authentication failed',
        description: err.message,
      });
      setLoginState({
        loading: false,
        error: err,
        isSuccess: false,
      });
    }
  };
  return (
    <div className="login-container">
      <div className="auth-form">
        <BeeboltLinedLogo />
        <Title level={2}>Run as Admin!</Title>
        <Text type="secondary" className="notas">
          <Text strong type="secondary">
            Note :
          </Text>
          For security reasons, this login link will be used only once to allow
          you access the customer account !
        </Text>
        {loginState.error && (
          <Alert
            message={
              loginState.error.toString() ??
              'Unknown error has occured, please try again !'
            }
            type="error"
            showIcon
            className="alert-wrapper"
          />
        )}

        <Form layout="vertical" form={form} className="form-wrapper">
          <Form.Item
            label="Customer's Email"
            name="email"
            key="email"
            rules={[
              {
                required: true,
                message: 'Please enter the customer email!',
              },
              {
                type: 'email',
                warningOnly: true,
                message: 'Please enter a valid email !',
              },
            ]}
          >
            <Input placeholder="Enter the customer email" />
          </Form.Item>

          <Button
            type="primary"
            className="auth-form__login-btn"
            onClick={onSubmit}
            disabled={loginState.loading}
            icon={loginState.loading && <LoadingOutlined />}
          >
            Log In
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default AdminLogin;
