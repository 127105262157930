import { Button, Form, Modal, message } from 'antd';
import { useUpdateUserByIdMutation } from 'api/user';
import React, { useEffect } from 'react';
import { UserConnectionTypes } from 'types/entities/user.entity';
import {
  IConnectionForm,
  IEditConnectionModalProps,
} from 'types/feature/connections.type';

import { displayErrors } from 'utils/error-notification';

import ConnectionForm from '../AddConnectionModal/connection-form';
import './add-supplier.scss';

const EditConnectionModal: React.FC<IEditConnectionModalProps> = ({
  isRoleHidden = false,
  isModalOpen,
  closeModal,
  userToEdit,
}) => {
  const [updateUser, { isLoading, isSuccess, isError, error }] =
    useUpdateUserByIdMutation();

  const [form] = Form.useForm<IConnectionForm>();

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Update User Connection Error' });
    }
  }, [isError, error]);

  // methods

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true, ({ touched }) => touched);
      updateUser({ ...values, userId: userToEdit?.partner.id });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message.error(error.message ?? 'Make sure to fill all required fields !');
    }
  };
  return (
    <Modal
      title={'Update User Connection'}
      open={isModalOpen}
      onCancel={closeModal}
      footer={[
        <Button
          loading={isLoading}
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={onFinish}
        >
          Save changes
        </Button>,
      ]}
    >
      <ConnectionForm
        initialValues={{
          userConnectionType:
            userToEdit?.partnerType ?? UserConnectionTypes.OTHER,
          companyId: userToEdit.partner.company.id,
          companyName: userToEdit.partner.company.name,
          country: userToEdit.partner.company.country,
          address: userToEdit.partner.company.address,
          userInviteFor: '',
          ...userToEdit.partner,
        }}
        form={form}
        isRoleHidden={isRoleHidden}
        isEditMode={true}
      />
    </Modal>
  );
};

export default EditConnectionModal;
