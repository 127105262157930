import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Space, Input, Typography } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

import './editable-text.scss';

const { Text } = Typography;
interface IEditableTextProps {
  defaultValue: string;
  onSave: (value: string) => void;
  isLoading: boolean;
}

const EditableText: FC<IEditableTextProps> = ({
  defaultValue,
  onSave,
  isLoading,
}) => {
  const [textValue, setTextValue] = useState<string>(defaultValue);
  const [editText, setEditText] = useState<boolean>(false);
  const ref = useRef({
    textValue: defaultValue,
  });

  useEffect(() => {
    setTextValue(defaultValue);
  }, [defaultValue]);

  const onSaveText = () => {
    onSave(textValue);
    setEditText(false);
  };

  const onCancelText = () => {
    setTextValue(ref.current.textValue);
    setEditText(false);
  };

  return (
    <>
      {!editText ? (
        <Text>
          {textValue}
          <Button
            type="link"
            onClick={() => setEditText(true)}
            icon={<EditOutlined />}
          />
        </Text>
      ) : (
        <Space.Compact className="input-wrapper">
          <Input
            defaultValue={defaultValue}
            allowClear={true}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
          <Button
            type="default"
            onClick={() => onCancelText()}
            icon={<CloseOutlined />}
          />
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => onSaveText()}
            icon={<CheckOutlined />}
          />
        </Space.Compact>
      )}
    </>
  );
};

export default EditableText;
