import { Col, Form, Input, Radio, Row, Upload } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';
import {
  IAdditionalInfoFormProps,
  ShipmentInviteTypeEnum,
} from 'types/feature/create-shipment.types';

import { useFileDragger } from '../useFileDragger';
import { ReactComponent as UploadIcon } from './../../../../assets/iconsNew/upload-icon-outlined.svg';

const { TextArea } = Input;
const { Dragger } = Upload;
export type AdditionalInfoFormRef = {
  getAttahments: () => void;
};

const AdditionalInfoForm = forwardRef<
  AdditionalInfoFormRef,
  IAdditionalInfoFormProps
>(
  (
    {
      form,
      isEditView,
      defaultValues,
      isInviteTypeDisabled,
      isCompact = false,
    },
    ref
  ) => {
    const { props: fileDraggerProps, attachemnts } = useFileDragger();

    useImperativeHandle(
      ref,
      function () {
        return {
          getAttahments: () => {
            return attachemnts;
          },
        };
      },
      [attachemnts]
    );

    return (
      <Form
        initialValues={defaultValues}
        layout="vertical"
        form={form}
        scrollToFirstError
      >
        <Row gutter={[12, 12]}>
          <Col span={isCompact ? 24 : 6}>
            <Form.Item label="Shipment Reference" name="shipmentReference">
              <Input placeholder="Enter Shipment Reference" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Additional Notes" name="additionalInfo">
          <TextArea
            rows={5}
            placeholder="Enter any additional informations..."
          />
        </Form.Item>

        {!isEditView && (
          <>
            <Form.Item>
              <Dragger {...fileDraggerProps}>
                <p className="ant-upload-drag-icon mb-0">
                  <UploadIcon />
                </p>
                <p className="ant-upload-hint">
                  Select a File, or Drag and Drop <br /> (12 files maximum)
                </p>
              </Dragger>
            </Form.Item>

            {!isInviteTypeDisabled && (
              <Form.Item name="inviteType">
                <Radio.Group>
                  <Radio value={ShipmentInviteTypeEnum.FORWARDER}>
                    Request quote from forwarders
                  </Radio>
                  <Radio value={ShipmentInviteTypeEnum.COLLABORATOR}>
                    I have nominated a forwarder for this shipment
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    );
  }
);

AdditionalInfoForm.displayName = 'AdditionalInfoForm';

export default AdditionalInfoForm;
