import { Checkbox, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  IContactFilterForm,
  IContactFilterProps,
} from 'types/feature/shipments.types';

import { searchString } from 'utils/common';
import { debounce } from 'utils/debounce';
import { computeFullName } from 'utils/user-utils';

import '../shipment-filter.scss';

const ContactFilter: FC<IContactFilterProps> = ({
  onChange,
  contacts,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IContactFilterForm>();
  const [contactState, setContactState] = useState(contacts);

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        contactFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ contactFilter }: IContactFilterForm) => {
    onChange(contactFilter);
  };

  const onSearch = (value: string) => {
    //array.filter expects a return
    return contacts.filter((contact) =>
      searchString(computeFullName(contact)).includes(searchString(value))
    );
  };

  const debouncedHandler = debounce((value: string) => {
    if (value === '') return;
    setContactState(onSearch(value));
  }, 200);

  const onContactSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setContactState(contacts);
    debouncedHandler(e.target.value);
  };

  return (
    <div>
      <Form
        form={form}
        onValuesChange={onFormChange}
        layout="vertical"
        initialValues={{ contactFilter: defaultValue }}
        className="filters-form-wrapper"
      >
        <Input
          className="location-search-feild"
          placeholder="Search Contact"
          onChange={onContactSearch}
          allowClear
        />

        <Form.Item name="contactFilter">
          <Checkbox.Group>
            <div className={`filter-input-wrapper ${layout}`}>
              {contactState.map((contact) => (
                <div className="input-space-wrapper" key={contact.id}>
                  <Checkbox value={contact.id}>
                    {computeFullName(contact)}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactFilter;
