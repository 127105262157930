import { Alert, Button, Skeleton, Table } from 'antd';
import { useGetDashboardPurchaseOrdersQuery } from 'api/dashboard';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { purchaseOrdersColumnsConst } from './purchase-orders-card-const.tsx';
import './purchase-orders-card-styles.scss';

const PurchaseOrdersCard: FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetDashboardPurchaseOrdersQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const onViewAll = () => {
    navigate('/purchase-orders');
  };
  return (
    <DashboardCard
      headerTitle="Purchase Orders"
      fitContent
      rightAccessory={
        <Button type="text" onClick={onViewAll}>
          View All
        </Button>
      }
    >
      {error && (
        <Alert
          showIcon
          message="Unexpected error while fetching purchase orders"
          type="error"
        />
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          className="dashboard-card-table"
          columns={purchaseOrdersColumnsConst}
          dataSource={data?.data.slice(0, 4) || []}
          showSorterTooltip
          pagination={{ hideOnSinglePage: true }}
          size="middle"
          loading={isLoading}
          rowKey="id"
        />
      )}
    </DashboardCard>
  );
};

export default PurchaseOrdersCard;
