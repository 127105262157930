import { Alert, Modal, Row, Skeleton, Space, Switch, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  useCreateNewProposalByIdMutation,
  useCreateSuggestedProposalByIdMutation,
  useDeleteExternalProposalShipmentItemMutation,
  useRevokeSuggestedProposalByIdMutation,
  useGetExternalPropsalByIdQuery,
  useUpdateExternalAcceptedPropsalMutation,
  useUpdateExternalPropsalByIdMutation,
  useGetExternalProposalShipmentQuery,
} from 'api/proposal-external.api';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IReferenceForm } from 'types/feature/proposals.types';

import { getSessionEmail } from 'utils/auth-storage';
import { displayErrors } from 'utils/error-notification';
import {
  isSuggestedProposalExist,
  isProposalAccepted,
  isProposalEditDisabled,
  canCresteSuggestedProposal,
} from 'utils/proposal-helper';

import { ReactComponent as NoneFound } from '../../../assets/404.svg';
import CreateNewQuoteCard from '../MultipleProposals/CreateNewProposalCard';
import CreateNewProposalModal from '../MultipleProposals/CreateNewProposalModal';
import ProposalForm from './ProposalForm';
import ProposalSuccessModal from './ProposalSuccessModal';
import QuotationProgress from './QuotationProgress';
import References from './Refrences';
import ShipmentDetails from './ShipmentDetails';
// style
import './proposal.scss';

const { confirm } = Modal;

const Proposals: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [param] = useSearchParams();
  const proposalFromRef = useRef<HTMLDivElement>(null);

  const [showProposalDiffViewer, setShowProposalDiffViewer] =
    useState<boolean>(false);

  const [isFieldEditDisabled, setIsFieldEditDisabled] = useState<boolean>(true);

  const currentUser = useSelector((state: RootState) => state.user.user);

  const email =
    param.get('email')?.replaceAll(' ', '+') ?? getSessionEmail() ?? '';
  const [referenceForm] = useForm<IReferenceForm>();

  // state
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const [proposalNotification, setProposalNotification] =
    useState<boolean>(false);

  const [isCreateNewQuoteModalOpen, setIsCreateNewQuoteModalOpen] =
    useState<boolean>(false);

  const [createNewProposalById] = useCreateNewProposalByIdMutation();

  const [createSuggestedProposal] = useCreateSuggestedProposalByIdMutation();

  // methods
  const onGoToDashboard = () => {
    navigate(`/dashboard`);
    setIsSuccessModalOpen(false);
  };

  const onGoToProposalNotFound = () => {
    navigate(`/not-found`);
  };

  const onSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const onCreateNewQuoteModalClose = () => {
    setIsCreateNewQuoteModalOpen(false);
  };

  // queries;
  const {
    data: shipmentData,
    isError: isShipmentError,
    error: shipmentError,
  } = useGetExternalProposalShipmentQuery({ proposalId: id, email });
  const {
    data: proposalData,
    isLoading: isFetching,
    isError,
    isSuccess,
    error,
  } = useGetExternalPropsalByIdQuery({ proposalId: id, email });

  useEffect(() => {
    if (shipmentError && isShipmentError) {
      displayErrors(shipmentError, { title: 'Failed To Fetch Shipment' });
    }
  }, [isShipmentError, shipmentError]);

  useEffect(() => {
    if (isSuccess) {
      if (!proposalData) {
        onGoToProposalNotFound();
        return;
      }
      setProposalNotification(proposalData.rankNotificationEnabled);

      if (isSuggestedProposalExist(proposalData)) {
        if (isFieldEditDisabled) {
          setShowProposalDiffViewer(true);
        }
      } else {
        const isEditDisabled = isProposalEditDisabled(
          false,
          proposalData.status,
          !!(currentUser && proposalData?.createdBy === currentUser?.id)
        );
        setIsFieldEditDisabled(isEditDisabled);
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, proposalData]);

  const onPostSubmit = () => {
    setIsCreateNewQuoteModalOpen(true);
  };

  const onProposalNotifChange = (isActivated: boolean) => {
    setProposalNotification(isActivated);
  };

  const onCreateNewProposalClick = () => {
    confirm({
      className: 'create-new-proposal-modal',
      title: 'Confirmation',
      okText: ' Create new proposal',
      cancelText: 'Modify current proposal',
      okType: 'primary',
      icon: null,
      width: 600,
      content:
        'Are you sure you to to create a new proposal for the same shipment?',
      onOk: async () => {
        createNewProposalById({
          proposalId: id,
          email,
        })
          .unwrap()
          .then((resp) => {
            window.open(
              `/proposals/${resp.data.proposalId}?email=${resp.data.email}`
            );
            setIsCreateNewQuoteModalOpen(false);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            displayErrors(error, { title: 'Error' });
          });
        return;
      },
    });
  };

  const scrollToBottom = useCallback(() => {
    const proposalFormElement = proposalFromRef.current?.getElementsByClassName(
      'proposal-form-wrapper'
    );

    if (proposalFormElement && proposalFormElement.length > 0) {
      proposalFormElement[0].scrollIntoView({ behavior: 'smooth' });

      proposalFormElement[0].classList.add('hightligh-animation');
      setTimeout(() => {
        proposalFormElement[0].classList.remove('hightligh-animation');
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalData]);

  const onCreateSuggestedProposal = async (proposal: IProposalEntity) => {
    try {
      if (email) {
        await createSuggestedProposal({
          proposalId: proposal.id,
          email,
        }).unwrap();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on creating suggested proposal' });
    }
  };
  const displayErrorResponseByStatus = () => {
    return isError ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).status === 403 ? (
        <Row justify="center">
          <Space direction="vertical" align="center">
            {/* SHOULD BE REPLACED LATER WITH 403 IMAGE */}
            <NoneFound />
            <Typography.Text strong>
              You are not authorized to view this proposal
            </Typography.Text>
            <Typography.Text>
              it is either deleted by Cargo Owner or You were removed from
              proposal collaborators list.
            </Typography.Text>
            <Typography.Text type="secondary" strong>
              Please contact the cargo owner to get access to this proposal.
            </Typography.Text>
          </Space>
        </Row>
      ) : (
        <Alert
          showIcon
          type="error"
          message="Unable to load proposal details..."
          description="Please refresh this page in order to edit the proposal or submit one!"
        />
      )
    ) : (
      <Skeleton active />
    );
  };

  const onEditProposal = () => {
    setIsCreateNewQuoteModalOpen(false);
    setIsFieldEditDisabled(false);
    setShowProposalDiffViewer(false);
    scrollToBottom();
  };

  const onEditProposalConfirm = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Edit Proposal',
      okType: 'default',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
      },
      icon: null,
      content:
        proposalData && proposalData.suggestedProposal
          ? 'This proposal changes are already under review. Are you sure you want to edit this proposal?'
          : 'This proposal is accepted by Cargo owner. Are you sure you want to edit this proposal?',
      onOk: async () => {
        if (proposalData && canCresteSuggestedProposal(proposalData)) {
          await onCreateSuggestedProposal(proposalData);
        }

        onEditProposal();
      },
    });
  };

  return (
    <div className="proposal-page" data-testid="proposal-page">
      <div className="proposal-page__fixed-header">
        <Typography.Paragraph
          strong
          className="proposal-page__fixed-header__title"
        >
          Please provide your best quote for shipment below
        </Typography.Paragraph>
      </div>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <div className="proposal-page__container">
          {proposalData && (
            <CreateNewQuoteCard
              status={proposalData.status}
              proposal={{
                bigDeadline: shipmentData?.dueDate || '',
                bidRank: proposalData.rank,
                shipmentReference: shipmentData?.shipmentReference || '',
                showBiddingRank: proposalData.showBiddingRank,
              }}
              onCreateNewProposalClick={onCreateNewProposalClick}
              isProposalNotifEnabled={proposalNotification}
              onProposalNotifChange={onProposalNotifChange}
              proposalParams={{ id, email }}
              onEditProposal={
                isProposalAccepted(proposalData.status)
                  ? onEditProposalConfirm
                  : onEditProposal
              }
            />
          )}

          <QuotationProgress
            status={proposalData?.status ?? IQuoteStatus.REQUESTED}
          />

          {/* TODO  not available on bubble yet <CargoDetails /> */}
          {proposalData && shipmentData ? (
            <div ref={proposalFromRef}>
              <References
                proposal={proposalData}
                form={referenceForm}
                shipment={{
                  shipmentReference: shipmentData.shipmentReference,
                  shipmentSlug: shipmentData.slug,
                }}
              />

              <ShipmentDetails shipment={shipmentData} />
              {isSuggestedProposalExist(proposalData) && (
                <div className="proposal-page__diff-viewer">
                  <span>
                    {showProposalDiffViewer
                      ? 'Proposal Form View'
                      : 'Compare the proposal which was accepted with the changes you have requested'}
                  </span>
                  <Switch
                    checked={showProposalDiffViewer}
                    onChange={(value) => setShowProposalDiffViewer(value)}
                  />
                </div>
              )}
              <div className="proposal-form-wrapper">
                <ProposalForm
                  shipment={shipmentData}
                  proposal={{ ...proposalData, id: id }}
                  onPostSubmit={onPostSubmit}
                  email={email}
                  isExternalForwarder
                  referenceForm={referenceForm}
                  isAdmin={false}
                  isFieldEditDisabled={isFieldEditDisabled}
                  showProposalDiffViewer={showProposalDiffViewer}
                  updateProposalMutation={useUpdateExternalPropsalByIdMutation}
                  updateAcceptedProposalMutation={
                    useUpdateExternalAcceptedPropsalMutation
                  }
                  deleteProposalCargoUnitMutation={
                    useDeleteExternalProposalShipmentItemMutation
                  }
                  revokeSuggestedProposalMutation={
                    useRevokeSuggestedProposalByIdMutation
                  }
                />
              </div>
            </div>
          ) : (
            displayErrorResponseByStatus()
          )}
        </div>
      )}

      {proposalData && (
        <CreateNewProposalModal
          proposal={{
            bigDeadline: shipmentData?.dueDate || '',
            bidRank: proposalData.rank,
            shipmentReference: shipmentData?.shipmentReference ?? '',
            showBiddingRank: proposalData.showBiddingRank,
          }}
          isOpen={isCreateNewQuoteModalOpen}
          onGoToDashboard={onGoToDashboard}
          onClose={onCreateNewQuoteModalClose}
          onCreateNewProposalClick={onCreateNewProposalClick}
          onProposalNotifChange={onProposalNotifChange}
          isProposalNotifEnabled={proposalNotification}
          proposalParams={{ id, email }}
          status={proposalData.status}
          onEditProposal={
            isProposalAccepted(proposalData.status)
              ? onEditProposalConfirm
              : onEditProposal
          }
        />
      )}
      <ProposalSuccessModal
        isOpen={isSuccessModalOpen}
        onGoToDashboard={onGoToDashboard}
        onCloseModal={onSuccessModalClose}
      />
    </div>
  );
};
export default Proposals;
