import dayjs from 'dayjs';
import dayJs from 'dayjs';

export const computeToday = (format: string) => {
  const formatedDate = dayJs().startOf('day').format(format);

  return {
    fromDate: formatedDate,
    toDate: dayJs().format(format),
  };
};

export const computeYesterday = (format: string) => {
  const yesterday = dayJs().subtract(1, 'day').startOf('day').format(format);

  return {
    fromDate: yesterday,
    toDate: computeToday('YYYY-MM-DD HH:mm:ss').fromDate,
  };
};

export const computeMessageDate = (dateStr: string) => {
  const date = dayjs(dateStr);
  const diffInDays = dayjs()
    .startOf('day')
    .diff(dayjs(date).startOf('day'), 'day');

  return diffInDays === 0
    ? date.format('HH:mm')
    : diffInDays === 1
    ? 'Yesterday'
    : date.format(diffInDays < 7 ? 'dddd' : 'MMM DD');
};
