import Icon from '@ant-design/icons';
import { Row, Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React, { FC } from 'react';
import { IDocumentExtractLoaderProps } from 'types/feature/document-extractor.types';

import docsParsingGif from '../../../../assets/docs-parsing.gif';
import { ReactComponent as MinmizeIcon } from '../../../../assets/icons/minimize-icon.svg';
import './document-extract-loader.scss';

const DocumentExtractLoader: FC<IDocumentExtractLoaderProps> = ({
  isOpen,
  onClose,
}) => {
  const renderExtractingTitle = (
    <>
      <Typography.Title level={3} className="mt-0">
        Document Parsing
      </Typography.Title>
      <Typography.Paragraph className="modal-sub-title">
        It will take few seconds to parse this document...
      </Typography.Paragraph>
    </>
  );

  const customMinimizeIcon = <Icon component={MinmizeIcon} />;

  return (
    <>
      <Modal
        title={renderExtractingTitle}
        open={isOpen}
        onCancel={onClose}
        closeIcon={customMinimizeIcon}
        footer={null}
        destroyOnClose
        width="500px"
      >
        <Row className="py-3" justify="center">
          <img width="200px" src={docsParsingGif} alt="parsing-icon" />
        </Row>
      </Modal>
    </>
  );
};

export default DocumentExtractLoader;
