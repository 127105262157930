import { Drawer, Button, Typography, Badge, Space, Divider } from 'antd';
import { useGetTeamMembersQuery, useGetUserConnectionsQuery } from 'api/user';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import {
  IInvoiceFilterDrawerProps,
  IInvoicesFiltersState,
} from 'types/feature/invoice-list';

import DateFilter from '../../ShipmentFilters/DateFilter';
import AssigneeFilter from './InvoiceAssigneeFilter';
import InvoiceStatusFilter from './InvoiceStatusFilter';
import VarianceFilter from './InvoiceVarianceFilter';
import VendorsFilter from './InvoiceVendorsFilter';
import SubmittedByFilter from './SubmittedByFilter';

const InvoiceFilterDrawer: FC<IInvoiceFilterDrawerProps> = ({
  defaultValue,
  onChange,
  onReset,
  vendors,
  isStatusFiltered,
  filterCount,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [open, setOpen] = useState(false);
  const [allContacts, setAllContacts] = useState<IUserPartnerEntity[]>([]);

  const [invoiceFilters, setInvoicefilters] = useState<IInvoicesFiltersState>(
    {}
  );

  const { data: teamData } = useGetTeamMembersQuery(); // get team members

  const { data: connectionsData } = useGetUserConnectionsQuery(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setInvoicefilters({});
    onReset();
  };

  const onApplyFilter = () => {
    onChange(invoiceFilters);
    setOpen(false);
  };

  const renderFilterCount = () => {
    return filterCount > 0 && <Badge count={filterCount} color="#6F6F6F" />;
  };

  useEffect(() => {
    setInvoicefilters(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (connectionsData && teamData && currentUser) {
      const newConnections = connectionsData?.map(({ partner }) => partner);
      const combinedContacts = [currentUser, ...teamData, ...newConnections];
      setAllContacts(combinedContacts);
    }
  }, [connectionsData, teamData, currentUser]);

  return (
    <>
      <Button onClick={showDrawer}>
        <Space>All Filters {renderFilterCount()}</Space>
      </Button>
      <Drawer
        title="Invoice Filters"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        footer={
          <div className="filter-footer-actions">
            <Button onClick={onClose} className="mx-1">
              Cancel
            </Button>
            <Button type="primary" onClick={onApplyFilter}>
              <Space>Apply Filters {renderFilterCount()}</Space>
            </Button>
          </div>
        }
      >
        {!isStatusFiltered && (
          <>
            <div>
              <Typography.Title level={5}>Invoice Status</Typography.Title>
              <InvoiceStatusFilter
                layout={'horizontal'}
                defaultValue={undefined}
                onChange={(value) =>
                  setInvoicefilters({ ...invoiceFilters, status: value })
                }
              />
            </div>
            <Divider />
          </>
        )}
        <div>
          <Typography.Title level={5}>Variance</Typography.Title>
          <VarianceFilter
            onFilterChange={(values) => {
              setInvoicefilters({ ...invoiceFilters, variance: values });
            }}
            selectedFilters={invoiceFilters}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Date created</Typography.Title>
          <DateFilter
            layout="horizontal"
            defaultValue={invoiceFilters?.createdDates}
            onChange={(values) =>
              setInvoicefilters({
                ...invoiceFilters,
                createdDates: values,
              })
            }
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Date Invoiced</Typography.Title>
          <DateFilter
            layout="horizontal"
            defaultValue={invoiceFilters?.issueDates}
            onChange={(values) =>
              setInvoicefilters({
                ...invoiceFilters,
                issueDates: values,
              })
            }
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Submitted By</Typography.Title>
          <SubmittedByFilter
            onFilterChange={(values) => {
              setInvoicefilters({ ...invoiceFilters, submittedBy: values });
            }}
            selectedFilters={invoiceFilters}
            allContacts={allContacts}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Assigned To</Typography.Title>
          <AssigneeFilter
            onFilterChange={(values) => {
              setInvoicefilters({ ...invoiceFilters, assignee: values });
            }}
            selectedFilters={invoiceFilters}
            allContacts={allContacts}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Vendors</Typography.Title>
          <VendorsFilter
            onFilterChange={(values) => {
              setInvoicefilters({ ...invoiceFilters, vendors: values });
            }}
            selectedFilters={invoiceFilters}
            vendorsData={vendors}
          />
        </div>
      </Drawer>
    </>
  );
};

export default InvoiceFilterDrawer;
