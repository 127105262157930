import { Skeleton, Spin, message, notification } from 'antd';
import {
  useAssignBookingsToShipmentMutation,
  useLazyGetCratedBookingsQuery,
} from 'api/bookings';
import {
  useCreateProxyShipmentMutation,
  useCreateShipmentMutation,
} from 'api/shipment';
import ShipmentCreationForm from 'app/components/ShipmentCreation/ShipmentCreationForm';
import ShipmentsTable from 'app/components/Shipments/ShipmentsTable';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { resetBookings } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { IAssignBookingsShipmentProps } from 'types/feature/bookings.types';
import { TOnCreateShipmentParams } from 'types/feature/create-shipment.types';

import { purchaseOrderAssignToShipmentEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { createDefaultShipmentFromBookings } from './helper/map-booking-to-shipment.helper';

const AssignBookingsShipment: FC<IAssignBookingsShipmentProps> = ({
  existingShipment,
  ids,
  shipmentTypes,
}) => {
  const [shipmentToBook, setShipmentToBook] = useState<IShipmentEntity | null>(
    null
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  let bookingIds = useSelector((state: RootState) => state.booking.ids);

  bookingIds = ids ?? bookingIds;

  const [getBookings] = useLazyGetCratedBookingsQuery();

  const [assignBookingsToShipment, { isLoading, isSuccess, isError, error }] =
    useAssignBookingsToShipmentMutation();

  const [createShipment, { isLoading: isCreating, isSuccess: isCreated }] =
    useCreateShipmentMutation();
  const [
    createProxyShipment,
    { isSuccess: isProxyShipmentSuccess, isLoading: isProxyShipmentLoading },
  ] = useCreateProxyShipmentMutation();

  const createDefaultShipment = async (bookingIds: string[]) => {
    try {
      const { data } = await getBookings({
        bookingIds,
      }).unwrap();

      if (shipmentTypes) {
        const shipment = createDefaultShipmentFromBookings(
          data.items,
          shipmentTypes
        );
        setShipmentToBook(shipment as IShipmentEntity);
      }
    } catch (error) {
      message.warning('Unable to auto-fill shipment form from booking data !');
    }
  };

  const onNavigateToShipmentDetails = (shipment: {
    id: string;
    slug: string;
  }) => {
    dispatch(resetBookings({}));
    navigate(`/shipments/${shipment?.slug}`, {
      state: { shipmentId: shipment?.id },
    });
  };
  const onAssignToShipment = async (
    shipmentId: string,
    slug: string,
    isCreateMode: boolean
  ) => {
    if (!bookingIds) {
      return;
    }
    try {
      await assignBookingsToShipment({ shipmentId, bookingIds, isCreateMode });
      onNavigateToShipmentDetails({ id: shipmentId, slug });

      purchaseOrderAssignToShipmentEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        booking_id: bookingIds,
        shipment_id: shipmentId,
        company_id: user?.companyId,
      });
    } catch (error: unknown) {
      notification.warning({ message: 'Unknown Error' });
    }
  };

  const onCreateShipment: TOnCreateShipmentParams = async (
    shipment,
    inviteType,
    hasExternalConnections
  ) => {
    try {
      let resp;
      if (hasExternalConnections) {
        resp = await createProxyShipment(shipment).unwrap();
      } else {
        resp = await createShipment({
          body: shipment,
          params: undefined,
        }).unwrap();
      }
      if (resp) await onAssignToShipment(resp.id, `SH-${resp?.slug}`, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Creation Error' });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Bookings assigned successfully !',
        description: 'Check booking tab under shipment for more details ! ',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isCreated || isProxyShipmentSuccess) {
      notification.success({
        message: 'Shipment created successfully !',
      });
    }
  }, [isCreated, isProxyShipmentSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Assign booking error !' });
    }
  }, [isError, error]);

  useEffect(() => {
    if (bookingIds?.length) createDefaultShipment(bookingIds);
    // eslint-disable-next-line
  }, [bookingIds]);

  return (
    <div style={{ marginTop: 15 }}>
      <Spin spinning={isLoading}>
        {existingShipment ? (
          <ShipmentsTable
            isBookingAssignment={true}
            onAssignToShipment={onAssignToShipment}
          />
        ) : shipmentToBook ? (
          <ShipmentCreationForm
            defaultShipment={shipmentToBook}
            isLoading={isCreating || isLoading || isProxyShipmentLoading}
            onCreateShipment={onCreateShipment}
            openPreviewTab={null}
          />
        ) : (
          <Skeleton active />
        )}
      </Spin>
    </div>
  );
};

export default AssignBookingsShipment;
