import React, { FC } from 'react';

import './invoice-timeline.scss';

interface IInvoiceTimelineItems {
  step: number;
  title: string;
  subTitle: string;
}
interface IInvoiceTimelineProps {
  activeStep: number;
  items: IInvoiceTimelineItems[];
}

const computeTimelineState = (activeStep, currentStep) => {
  if (activeStep === currentStep) {
    return 'active';
  } else if (activeStep > currentStep) {
    return 'completed';
  } else if (activeStep < currentStep) {
    return 'pending';
  }
};

const InvoiceTimeline: FC<IInvoiceTimelineProps> = ({ activeStep, items }) => {
  return (
    <div className="invoice-timeline-wrapper">
      {items.map((item) => {
        return (
          <div
            className={`timeline-item ${computeTimelineState(
              activeStep,
              item.step
            )}`}
            key={`item-${item.step}`}
          >
            <div className="timeline-item-icon">
              <span className="text">{item.step}</span>
            </div>
            <div className="timeline-item-content">
              <div className="item-heading">
                <h3>{item.title}</h3>
                <div className="line" />
              </div>
              <p>{item.subTitle}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default InvoiceTimeline;
