import { UserAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCreateBookingPartyMutation } from 'api/booking-parties';
import { useGetTeamMembersQuery, useGetUserConnectionsQuery } from 'api/user';
import AddConnectionSelect from 'app/components/Connections/AddConnectionSelect';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IBookingPartyEnum as IBookingPartyRoleEnum,
  ICreateBookinPartyRequestBody,
} from 'types/entities/booking-party';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import { IAddTrackingModalProps } from 'types/feature/tracking.types';

import { addBookingPartyEvent } from 'utils/analytics-events';
import { splitState } from 'utils/format-fields';
import { isPhoneNumberValid } from 'utils/inputs-validators';
import {
  computePartnersSelectOptions,
  computeTeamSelectOptions,
} from 'utils/user-helper';

import './add-booking-party-modal.scss';

const { Option } = Select;

const AddBookingPartyModal: React.FC<IAddTrackingModalProps> = ({
  actionButton,
  shipmentId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    addBookingPartyOnboarding,
    hideAddBookingPartyModal,
    shareBookingPartyModal,
  } = useBookingPartyState();

  const user = useSelector((state: RootState) => state.user.user);
  const [createBookingParty, { isLoading, isSuccess, isError }] =
    useCreateBookingPartyMutation();

  const { data: userConnectionsData, isLoading: isConnectionsLoading } =
    useGetUserConnectionsQuery(null);
  const { data: teamData, isLoading: isTeamLoading } = useGetTeamMembersQuery(); // get team members

  const [form] = Form.useForm<ICreateBookinPartyRequestBody>();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to add booking party',
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);

      if (user)
        addBookingPartyEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          shipment_id: shipmentId,
          company_id: user?.companyId,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) errorNotification();
  }, [isError]);

  const onFinish = async (values: ICreateBookinPartyRequestBody) => {
    try {
      await createBookingParty(values);
      form.resetFields();
    } catch (e) {
      errorNotification();
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    if (hideAddBookingPartyModal) {
      hideAddBookingPartyModal();
    }
  };

  useEffect(() => {
    if (addBookingPartyOnboarding) {
      showModal();
    }
  }, [addBookingPartyOnboarding]);

  const setDefaulContact = (user: IUserPartnerEntity) => {
    form.setFieldsValue({
      companyName: user?.company?.name || '',
      email: user.email,
      contactPerson: `${user.fullname}`,
      phoneNumber: user.mobileNumber || '',
      country: user.company?.country || '',
      address: user.company?.address || '',
    });
  };
  const onSelectChange = (value: string) => {
    const filteredValue = userConnectionsData?.find(
      ({ partner }) => partner.id === value
    );
    if (filteredValue) {
      setDefaulContact(filteredValue.partner);
    } else {
      const filteredTeamMember = teamData?.find(({ id }) => id === value);

      if (filteredTeamMember) {
        setDefaulContact(filteredTeamMember);
      }
    }
  };

  const allContacts = [
    ...computeTeamSelectOptions(teamData ?? []),
    ...computePartnersSelectOptions(userConnectionsData ?? []),
  ];

  return (
    <div className="add-booking-party-wrapper">
      <Button type="default" onClick={showModal} {...actionButton}>
        {actionButton?.text ? actionButton?.text : 'Add Party'}
        {shareBookingPartyModal}
      </Button>
      <Modal
        title="Add Booking Party"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            htmlType="submit"
            form="booking-party-form"
          >
            Save
          </Button>,
        ]}
      >
        <div className="booking-party-form-wrapper">
          <Form
            id="booking-party-form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            layout="horizontal"
            initialValues={{
              shipmentId: shipmentId,
              role: IBookingPartyRoleEnum.CONSIGNEE,
            }}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              hidden
              label="Shipment ID"
              name="shipmentId"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="contactPerson"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              {AddConnectionSelect({
                isLoading: isConnectionsLoading || isTeamLoading,
                selectOptions: allContacts,
                isRoleHidden: false,
                showAddNewUser: true,
                btnProps: {
                  icon: <UserAddOutlined />,
                  text: 'Add Contact Person',
                },
                onSelectChange,
              })}
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  max: 14,
                  min: 10,
                  message: 'Phone number should be at least 10 digits !',
                },
                () => ({
                  validator(rule, value) {
                    if (!value || (value && isPhoneNumberValid(value))) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'The inserted phone number  is not valid !'
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Select placeholder="Select Carrier">
                {Object.values(IBookingPartyRoleEnum).map((role) => (
                  <Option value={role} key={role}>
                    {splitState(role)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Notes" name="note">
              <TextArea />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AddBookingPartyModal;
