import {
  CloudDownloadOutlined,
  DeleteFilled,
  EditFilled,
  FilterFilled,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useDeleteContractByIdMutation,
  useGetContractsQuery,
} from 'api/contract';
import ContractRatesUpdate from 'app/components/ContractRatesEdit';
import CheckboxList from 'app/design-system/CheckList';
import { useAdjustColumns } from 'app/hooks/adjustColumns.hook';
import React, { FC, Key, useState } from 'react';
import { IContractEntity } from 'types/entities/contract.entity';
import { ShipmentTypeEnum } from 'types/entities/shipment.entity';
import { PaginatorParams } from 'types/feature/pagination.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';

import { ReactComponent as EmptyContracts } from '../../../../assets/empty-contracts.svg';
import CoastHeadCollapse from '../CostHeadCollapse';
import UploadContractModal from '../UploadContractModal';
import '../contracts.style.scss';
import { mainColumnConst, options } from './contracts.const';

const defaultPagination: PaginatorParams = {
  page: 1,
  limit: 10,
};

const { confirm } = Modal;
const { Search } = Input;

const ContractsTable: FC<{
  shipmentType: ShipmentTypeEnum;
}> = ({ shipmentType }) => {
  const [contractToEdit, setContractToEdit] = useState<
    IContractEntity | undefined
  >(undefined);
  const [expandedContractsRows, setExpandedContractsRows] = useState<Key[]>([]);
  const { selectedColumns, selectColumns } = useAdjustColumns(
    options,
    UserTablesEnum.contractsTable
  );
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [paginationState, setPaginationState] =
    useState<PaginatorParams>(defaultPagination);

  // queries
  const mapFilters = {
    ...paginationState,
    search,
    shipmentType,
  };

  const {
    data: contractsData,
    isLoading,
    refetch: refetchContracts,
  } = useGetContractsQuery({
    params: mapFilters,
  });

  const [deleteOneContract, { isLoading: isDeleting }] =
    useDeleteContractByIdMutation();
  const { items: contracts = [], meta } = contractsData?.data ?? {};

  const onViewClick = (id: string) => {
    setExpandedContractsRows((currentKeys) => [...currentKeys, id]);
  };

  const onClose = () => {
    setContractToEdit(undefined);
    refetchContracts();
  };

  const onCollapseClick = (id: string) => {
    setExpandedContractsRows((currentKeys) => [
      ...currentKeys.filter((key) => key !== id),
    ]);
  };

  const isCollapsed = (id: string) => !expandedContractsRows.includes(id);
  const onEditClick = (record: IContractEntity) => {
    setContractToEdit(record);
  };

  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this contract?',
      onOk: async () => {
        await deleteOneContract({ contractId: id })
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Contract Deleted',
              description: `The selected contract has been deleted successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete Contract Error' });
          });
      },
    });
  };

  // Table Columns

  const columns: ColumnsType<IContractEntity> = [
    ...filterColumns(mainColumnConst, selectedColumns),
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: 200,
      render: (_: string, record: IContractEntity, index: number) => {
        const isRowCollapsed = isCollapsed(`${record.id}`);

        return (
          <Space>
            <Button
              key={`view-${index}`}
              type="link"
              onClick={
                isRowCollapsed
                  ? () => onViewClick(`${record.id}`)
                  : () => onCollapseClick(`${record.id}`)
              }
            >
              {isRowCollapsed ? 'View' : 'Collapse'}
            </Button>
            <Button
              key={`edit-${index}`}
              type="text"
              icon={<EditFilled />}
              onClick={() => onEditClick(record)}
              loading={isDeleting}
            />
            <Button
              key={`delete-${index}`}
              type="text"
              icon={<DeleteFilled />}
              onClick={() => onDeleteClick(record.id)}
              loading={isDeleting}
              danger
            />
          </Space>
        );
      },
    },
  ];

  const handleInputOnChange = (value: string) => {
    if (!value) {
      setSearch(undefined);
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    if (value) setSearch(value);
  };

  return (
    <>
      <Row justify="space-between" className="contract-table--toolbox">
        <div className="search-wrapper">
          <Search
            placeholder="search"
            allowClear
            onChange={(event) => handleInputOnChange(event.target.value)}
            onSearch={handleOnSearch}
          />
        </div>
        <Space>
          <CheckboxList
            options={options}
            selectedItems={selectedColumns}
            onChange={selectColumns}
            tableName={UserTablesEnum.contractsTable}
          >
            <Button icon={<FilterFilled />}>Adjust Table</Button>
          </CheckboxList>
          <Button
            type="dashed"
            icon={<CloudDownloadOutlined />}
            href="https://storage.googleapis.com/freight-bucket-production/contract_rate/template/contract-template.xlsx"
          >
            Download Template
          </Button>
          <UploadContractModal />
        </Space>
      </Row>
      <Table
        loading={isLoading}
        className="table-wrapper--no-border contract-table"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        dataSource={contracts}
        expandable={{
          expandedRowRender: (record: IContractEntity) => (
            <CoastHeadCollapse
              costHeads={record.costHeads}
              shipmentType={record.shipmentType}
            />
          ),
          expandedRowKeys: expandedContractsRows,
          onExpandedRowsChange: (expandedKeys) => {
            setExpandedContractsRows(expandedKeys as React.Key[]);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={<EmptyContracts />}
              description={
                <Space direction="vertical">
                  <Typography.Text strong>Contracts List</Typography.Text>
                  <Typography.Text type="secondary">
                    Upload new contracts or change filters to consult your
                    contracts !
                  </Typography.Text>
                </Space>
              }
            />
          ),
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: paginationState.limit,
          pageSizeOptions: ['5', '10', '50'],
          total: meta?.totalItems ?? 0,
          current: meta?.currentPage ?? 1,
          onChange: (page, pageSize) => {
            setPaginationState({ page: page, limit: pageSize });
          },
        }}
      />
      <ContractRatesUpdate
        contractToEdit={contractToEdit}
        isOpen={!!contractToEdit}
        onClose={onClose}
      />
    </>
  );
};

export default ContractsTable;
