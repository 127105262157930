import { Row, Col, Form, Input, Button } from 'antd';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import CompanyField from 'app/design-system/CompanyField';
import React from 'react';
import { TRenderForwarderFieldListProps } from 'types/feature/create-shipment.types';

import { ReactComponent as BinOutlinedIcon } from '../../../../assets/iconsNew/bin-icon-outlined.svg';

export const renderForwarderFieldList: TRenderForwarderFieldListProps = ({
  field,
  isDeleteActionEnabled,
  onRemoveField,
  handleSelectAcompany,
}) => (
  <div key={field.key}>
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Name"
          name={[field.name, 'fullname']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input placeholder="Full name" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name={[field.name, 'companyId']}
          hidden
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Company Name"
          name={[field.name, 'company']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The company is required, if it is new make sure to add it first!'
                );
              },
            }),
          ]}
        >
          <CompanyField
            userRole="forwarder"
            handleSelectAcompany={handleSelectAcompany([field.name, 'company'])}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[12, 12]} align="middle">
      <Col span={isDeleteActionEnabled ? 22 : 24}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Email Address"
          name={[field.name, 'email']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: "Please input the forwarder's email.",
            },
            {
              type: 'email',
              message: 'Please input a correct email address.',
            },
          ]}
        >
          <Input placeholder="Enter email address" />
        </Form.Item>
      </Col>
      {onRemoveField && isDeleteActionEnabled && (
        <Col span={2}>
          <Button
            className="delete-btn-fix"
            icon={<BinOutlinedIcon />}
            onClick={() => onRemoveField(field.name)}
            type="default"
            danger
          />
        </Col>
      )}
    </Row>
  </div>
);
