import {
  ApartmentOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Col, Row, Skeleton } from 'antd';
import { useGetProfileQuery } from 'api/user';
import AppTabs from 'app/design-system/AppTabs';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCurrentUser } from 'state/slices/user.slice';

import BasicInfos from './BasicInfos';
import CompanyInfos from './CompanyInfos';
import Connections from './Connections';
import Password from './Password';
import SideCard from './SideCard';
import TeamInfos from './TeamInfos';
import './profile.scss';

const Profile: FC = () => {
  const dispatch = useDispatch();

  const {
    data: profile,
    isLoading,

    refetch: refetchProfile,
  } = useGetProfileQuery();
  const [currentTab, setCurrentTab] = useState('basics');
  const [addTeamMember, setAddTeamMember] = useState(false);

  const currentRoute = useLocation();
  useEffect(() => {
    if (currentRoute.pathname === '/profile/team') {
      setCurrentTab('team');
      setAddTeamMember(true);
    }
  }, [currentRoute]);

  const isAdmin = profile?.data?.userRole === 'admin';

  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey);
  };

  useEffect(() => {
    if (profile?.data) {
      dispatch(
        setCurrentUser({
          ...profile.data,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return isLoading ? (
    <Skeleton active />
  ) : profile ? (
    <Row>
      <Col span={7} xs={24} md={6}>
        <SideCard profile={profile.data} refetchProfile={refetchProfile} />
      </Col>
      <Col span={17} xs={24} md={18}>
        <DashboardCard fitContent>
          <AppTabs
            activeKey={currentTab}
            onTabClick={(activeKey) => setCurrentTab(activeKey)}
            type="card"
            onChange={onTabChange}
            items={[
              {
                label: (
                  <>
                    <UserOutlined /> My profile
                  </>
                ),
                key: 'basics',

                children: (
                  <>
                    <BasicInfos user={profile?.data} />
                    <CompanyInfos
                      company={profile.data.company}
                      isAdmin={isAdmin}
                    />
                    <Password />
                  </>
                ),
              },
              {
                label: (
                  <>
                    <ApartmentOutlined /> Team
                  </>
                ),
                key: 'team',
                children: <TeamInfos addTeamMember={addTeamMember} />,
              },
              {
                label: (
                  <>
                    <DeploymentUnitOutlined /> Connections
                  </>
                ),
                key: 'connections',
                children: <Connections />,
              },
            ]}
          />
        </DashboardCard>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default Profile;
