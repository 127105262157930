/* eslint-disable */
import Icon, { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Modal,
  Spin,
  Typography,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { useUploadContractFileMutation } from 'api/contract';
import { formatUnitMeasurement } from 'app/components/Proposal/Fees/fees.const';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnitMeasurementEnum } from 'types/entities/fee.entity';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import { MeasurementUnitEnum } from 'types/entities/purchase-order.entity';

import { displayErrors } from 'utils/error-notification';
import { formatContainerName } from 'utils/format-fields';

import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera-icon-filled.svg';

const { Text } = Typography;
const { Dragger } = Upload;
const UploadContractModal: FC = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    uploadDocument,
    { data: extractedData, isLoading: isExtracting, isSuccess, isError, error },
  ] = useUploadContractFileMutation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const uploadOrderFile = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('file', file);

    uploadDocument(formdata)
      .unwrap()
      .then((payload) => {
        onSuccess();
      })
      .catch((error) => onError());
  };

  const onConfirm = async () => {
    if (extractedData) {
      navigate('/contracts/create', {
        state: {
          contract: extractedData,
        },
      });
    }
  };

  useEffect(() => {
    if (isError) {
      displayErrors(error, {
        title: 'Error while extracting data from PO file !',
      });
    }
  }, [isError]);

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    customRequest: uploadOrderFile,
    onChange(info: any) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Button onClick={showModal} type="primary">
        Upload Contract File
      </Button>
      <Modal
        title="Upload Document"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            onClick={onConfirm}
            disabled={isExtracting || !extractedData}
            loading={isExtracting}
          >
            Create New Contract
          </Button>,
        ]}
        width={450}
        destroyOnClose
      >
        <Collapse
          size="small"
          expandIcon={() => <InfoCircleOutlined />}
          items={[
            {
              key: '1',
              label: 'How to fill contract rate sheet ?',
              children: (
                <Typography.Paragraph>
                  <Typography.Text>
                    1. All columns must be filled.
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    2. Container type possible values are :
                    <Typography.Text strong>
                      {Object.values(ContainerTypeEnum)
                        .map((value) => formatContainerName(value))
                        .join(', ')}
                    </Typography.Text>
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    3. Charge Unit possible values are :
                    <Typography.Text strong>
                      {' '}
                      {Object.values(UnitMeasurementEnum)
                        .map((value) => formatUnitMeasurement(value))
                        .join(', ')}
                    </Typography.Text>
                  </Typography.Text>
                </Typography.Paragraph>
              ),
            },
          ]}
        />
        <div className="upload-document-wrapper">
          <Dragger {...props}>
            <div className="upload-file-wrapper">
              <Icon className="camera-icon" component={CameraIcon} />
              <Text className="upload-lebel">Attach Contract</Text>
            </div>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default UploadContractModal;
