import Icon from '@ant-design/icons';
import { Form, Row, Col, Select, Button, Checkbox, Typography } from 'antd';
import ControlCard from 'app/design-system/ControlCard';
import React, { FC } from 'react';
import {
  LengthMeasurementUnitEnum,
  PackageTypeEnum,
} from 'types/entities/shipment.entity';
import {
  IShipmentDetailsFormProps,
  IShipmentTypeState,
} from 'types/feature/create-shipment.types';

import { ReactComponent as PlusOutlinedIcon } from '../../../../assets/iconsNew/plus-icon-outlined.svg';
import { CARGO_UNITS_INIT_STATE } from '../ShipmentCreationForm/shipment.utils';
import ContainerForm from './ContainerForm';
import DimensionForm from './DimensionForm';
import UnitDimensionForm from './UnitDimensionForm';
import useShipmentDetailsForm from './useShipmentDetailsForm';

const { Text } = Typography;

const ShipmentDetailsFormCompact: FC<IShipmentDetailsFormProps> = ({
  form,
  defaultValues,
  defaultShipmentType,
  isEditView,
  footer,
  isFieldEditDisabled: isFieldActionsDisabled = false,
  proposalParams,
  deleteCargoUnitMutation,
  viewShipmentTypeAndCargoDetails,
}) => {
  const {
    cargoUnitsType,
    shipmentTypeOptions,
    allShipmentTypesData,
    isLoadingTypes,
    total,
    onShipmentFormValueChange,
    onCargoUnitTypeChange,
    onDeleteCargoUnit,
  } = useShipmentDetailsForm(
    form,
    defaultValues,
    defaultShipmentType,
    viewShipmentTypeAndCargoDetails,
    proposalParams,
    deleteCargoUnitMutation
  );

  const renderShipmentType = () =>
    allShipmentTypesData && (
      <Form.Item
        label="Shipment Type"
        name="shipmentTypeId"
        rules={[
          {
            required: true,
            message: 'Make sure to select the shipment type !',
          },
        ]}
      >
        <Select
          loading={isLoadingTypes}
          disabled={isEditView || isFieldActionsDisabled}
          options={shipmentTypeOptions?.map((value: IShipmentTypeState) => ({
            value: value.id,
            label: value.title.toUpperCase(),
          }))}
        />
      </Form.Item>
    );

  const renderCargoByContainer = () => (
    <>
      <Form.List
        name="cargoUnits"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error('Insert at least 1 cargo details')
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <ControlCard
                key={field.key}
                renderMain={
                  <ContainerForm
                    field={field}
                    isDeleteActionEnabled={fields.length > 1}
                    onRemoveField={remove}
                    isFieldActionsDisabled={isFieldActionsDisabled}
                  />
                }
              />
            ))}

            <Button
              className="my-2 float-right"
              type="default"
              onClick={() => add(CARGO_UNITS_INIT_STATE[0])}
              icon={<Icon component={PlusOutlinedIcon} size={25} />}
              disabled={isFieldActionsDisabled}
            >
              Add more
            </Button>
          </>
        )}
      </Form.List>
    </>
  );

  const renderCargoByUnits = () =>
    cargoUnitsType && (
      <>
        <div className="unit-dimensions-checkbox">
          <Checkbox
            defaultChecked={cargoUnitsType.byUnit}
            checked={cargoUnitsType.byUnit}
            onChange={onCargoUnitTypeChange}
          >
            By Units Dimensions
          </Checkbox>
        </div>

        {cargoUnitsType.byUnit ? (
          <>
            <Form.List
              name="cargoUnits"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Insert at least 1 cargo details')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <ControlCard
                      key={field.key}
                      renderMain={
                        <UnitDimensionForm
                          field={field}
                          isDeleteActionEnabled={fields.length > 1}
                          onRemoveField={remove}
                          isFieldActionsDisabled={isFieldActionsDisabled}
                        />
                      }
                    />
                  ))}

                  <Form.Item>
                    <Button
                      className="my-2 float-right"
                      onClick={() =>
                        add({
                          ...CARGO_UNITS_INIT_STATE[0],
                          packageType: PackageTypeEnum.PALLETS,
                          lengthMeasurement: LengthMeasurementUnitEnum.CM,
                        })
                      }
                      icon={<Icon component={PlusOutlinedIcon} size={25} />}
                      disabled={isFieldActionsDisabled}
                    >
                      Add more
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            {viewShipmentTypeAndCargoDetails && (
              <Row justify={'end'} align={'middle'}>
                <div className="units-footer__wrapper transparent">
                  <Text className="units-footer__title light">
                    Total Weight
                  </Text>
                  <div className="units-footer__amount-wrapper transparent">
                    <Text className="units-footer__amount">
                      {total.weight} Kg
                    </Text>
                  </div>
                </div>
                <div className="units-footer__wrapper transparent">
                  <Text className="units-footer__title light">
                    Chargeable Weight
                  </Text>
                  <div className="units-footer__amount-wrapper transparent">
                    <Text className="units-footer__amount">
                      {total.charges} Kg
                    </Text>
                  </div>
                </div>
                <div className="units-footer__wrapper transparent">
                  <Text className="units-footer__title light">
                    Total Volume
                  </Text>
                  <div className="units-footer__amount-wrapper transparent">
                    <Text className="units-footer__amount transparent">
                      {total.volume} cbm
                    </Text>
                  </div>
                </div>
              </Row>
            )}
          </>
        ) : (
          <Form.List
            name="cargoUnits"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error('Insert at least 1 cargo details')
                    );
                  }
                },
              },
            ]}
          >
            {(fields) =>
              fields.map((field) => (
                <ControlCard
                  key={field.key}
                  renderMain={
                    <DimensionForm
                      field={field}
                      isFieldActionsDisabled={isFieldActionsDisabled}
                    />
                  }
                  renderFooter={<></>}
                />
              ))
            }
          </Form.List>
        )}
      </>
    );

  return (
    <Form
      {...(defaultValues && { initialValues: defaultValues })}
      layout="vertical"
      form={form}
      scrollToFirstError
      onValuesChange={onShipmentFormValueChange}
      name="shipment-items-detailts"
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Freight Method" name="modality">
            <Select
              loading={isLoadingTypes}
              disabled={isEditView || isFieldActionsDisabled}
              options={Object.keys(allShipmentTypesData ?? {})?.map(
                (method: string) => ({
                  value: method,
                  label: method.toUpperCase(),
                })
              )}
            />
          </Form.Item>
        </Col>
        <Col span={12}>{renderShipmentType()}</Col>
      </Row>

      {!cargoUnitsType ? (
        <></>
      ) : cargoUnitsType.byContainer ? (
        renderCargoByContainer()
      ) : (
        renderCargoByUnits()
      )}
    </Form>
  );
};

export default ShipmentDetailsFormCompact;
