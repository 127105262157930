import { DeleteFilled, DownOutlined, EditFilled } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Button, Input, Modal, Row, Space, notification } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ColumnsType } from 'antd/es/table';
import {
  purchaseOrdersApi,
  useDeletePurchaseOrderByIdMutation,
} from 'api/purchase-order';
import { useGetUserTableSettingQuery } from 'api/user-table-columns';
import CreatePurchaseOrderModal from 'app/components/CreatePurchaseOrderModal';
import PurchaseOrderEdit from 'app/components/PurchaseOrderEdit';
import AppTabs from 'app/design-system/AppTabs';
import CheckboxList from 'app/design-system/CheckList';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IPurchaseOrderEntity,
  SplitScreenMode,
} from 'types/entities/purchase-order.entity';
import { IPurchaseOrderFilters } from 'types/feature/purchase-orders.types';
import {
  AccessLevelEnum,
  IPurchaseOrdersTabProps,
} from 'types/feature/purchase-orders.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { isManager, isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';
import { isCargoOwner } from 'utils/shipment-helper';

import { ReactComponent as AddUserIcon } from '../../../../assets/icons/add-user-icon.svg';
import { ReactComponent as PdfDocFilled } from '../../../../assets/pdf-filled.svg';
import PurchaseOrderFiles from '../PurchaseOrderFiles';
import PurchaseOrderFilters from './PurchaseOrdersFilters';
import PurchaseOrderTable from './PurchaseOrdersTable';
import {
  POsColumnConst,
  columns,
  filteredColumns,
} from './purchase-orders-table.const';
import './purchase-orders.scss';
import PurchaseOrderCollaborators from './purchaseOrdersCollaborators';

const { Search } = Input;
const { confirm } = Modal;

const PurchaseOrdersTabs: FC<IPurchaseOrdersTabProps> = ({
  mode,
  onCreateBooking,
  shipmentId,
  currentUserRole,
}) => {
  const isShipmentTab = !!shipmentId;
  const isBookingOngoing = mode === SplitScreenMode.BOTH;
  const isBookingAvailable = useSelector(
    (state: RootState) => state.booking.isBookingAvailable
  );
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [selectedFilters, setSelectedFilters] = useState<IPurchaseOrderFilters>(
    {}
  );
  const [defaultKey, setTabKey] = useState<string>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    isBookingOngoing ? filteredColumns : []
  );
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [currentUserAccessLevel, setCurrentUserAccessLevel] =
    useState<AccessLevelEnum>(AccessLevelEnum.VIEW);
  const [isEditMode, setIsEditMode] = useState<{
    open: false | 'EDIT' | 'FILES';
    order: IPurchaseOrderEntity | null;
  }>({ open: false, order: null });
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState(false);

  // queries
  const { data: setting, isSuccess: isSettingRetrieved } =
    useGetUserTableSettingQuery({
      tableName: UserTablesEnum.PurchaseOrderTable,
    });
  const [deleteOrder, { isLoading }] = useDeletePurchaseOrderByIdMutation();

  const selectColumns = (selected: CheckboxValueType[]) => {
    setSelectedColumns(selected as unknown as string[]);
  };
  const dispatch = useDispatch();

  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this purchase order?',
      onOk: async () => {
        await deleteOrder(id)
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Order Deleted',
              description: `The selected purchase order has been deleted successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete PO Error' });
          });
      },
    });
  };

  const onResetFilters = () => {
    setSelectedFilters({});
  };

  const hideCollaboratorModal = () => {
    setIsCollaboratorOpen(false);
    dispatch(
      purchaseOrdersApi.util.invalidateTags([{ type: 'PurchaseOrders' }])
    );
  };
  const showCollaboratorModal = (id: string, accessLevel: AccessLevelEnum) => {
    setIsCollaboratorOpen(true);
    setSelectedRow(id);
    setCurrentUserAccessLevel(accessLevel);
  };

  const renderCollaboratorModal = (
    id: string,
    accessLevel: AccessLevelEnum
  ) => (
    <PurchaseOrderCollaborators
      hideCollaboratorModal={hideCollaboratorModal}
      showCollaboratorModal={showCollaboratorModal}
      purchaseOrderId={id}
      currentUserAccessLevel={currentUserAccessLevel}
      isCollaboratorOpen={isCollaboratorOpen}
    />
  );

  const attachmentColumn: ColumnsType<IPurchaseOrderEntity> = [
    {
      title: 'Attachments',
      key: 'attachments',
      render: (_, record: IPurchaseOrderEntity) => {
        return (
          <Button
            type="text"
            icon={<Icon component={PdfDocFilled} size={100} />}
            onClick={() => setIsEditMode({ open: 'FILES', order: record })}
          />
        );
      },
      align: 'center',
      width: '6%',
    },
  ];
  const actionColumn: ColumnsType<IPurchaseOrderEntity> = [
    {
      key: 'actions',
      title: 'Actions',
      render: (_, record: IPurchaseOrderEntity) => {
        const isViewerAccess = isViewer(
          record.currentUserAccessLevel.accessLevel
        );
        const isManagerAccess = isManager(
          record.currentUserAccessLevel.accessLevel
        );
        return (
          <Space>
            <Button
              className="add-user-button"
              icon={<AddUserIcon />}
              size="small"
              type="text"
              onClick={() =>
                showCollaboratorModal(
                  record.id,
                  record.currentUserAccessLevel.accessLevel
                )
              }
            />
            <Button
              disabled={isViewerAccess}
              icon={<EditFilled />}
              size="small"
              type="text"
              onClick={() => setIsEditMode({ open: 'EDIT', order: record })}
            />

            <Button
              icon={<DeleteFilled />}
              danger
              size="small"
              type="text"
              disabled={isLoading || !isManagerAccess}
              onClick={() => onDeleteClick(record.id)}
            />
          </Space>
        );
      },
      align: 'center',
    },
  ];

  const renderTableByState = (isDraft: boolean | undefined) => (
    <PurchaseOrderTable
      selectedFilters={selectedFilters}
      isBookingOngoing={isBookingOngoing}
      shipmentId={shipmentId}
      columns={[
        ...filterColumns(
          [...POsColumnConst, ...attachmentColumn],
          selectedColumns
        ),
        ...(isCargoOwner(currentUserRole)
          ? actionColumn
          : isShipmentTab || isBookingOngoing
          ? []
          : actionColumn),
      ]}
      isDraft={isDraft}
      search={search}
    />
  );

  const changeDisplay = useCallback(() => {
    setSelectedColumns(isBookingOngoing ? filteredColumns : setting ?? []);
  }, [isBookingOngoing, setting]);

  const handleInputOnChange = (value: string) => {
    if (value === '') {
      setSearch(undefined);
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    changeDisplay();
  }, [mode, changeDisplay]);

  useEffect(() => {
    if (isSettingRetrieved) {
      if (setting.length) {
        setSelectedColumns(setting);
      } else {
        setSelectedColumns(columns.map((col) => col.value));
      }
    }
  }, [setting, isSettingRetrieved]);
  const onFilterChange = (value: string[], name: string) => {
    if (!value || value.length === 0) {
      // If value is empty, create a new object without the key
      const newFilters = { ...selectedFilters };
      delete newFilters[name];
      setSelectedFilters(newFilters);
    } else {
      setSelectedFilters({ ...selectedFilters, [name]: value });
    }
  };

  const onFilterDrawerChange = (value) => {
    setSelectedFilters(value);
  };

  return (
    <DashboardCard headerTitle="Purchase Orders" noMargin fitContent>
      <Row justify="space-between" className="po-actions-bar">
        <Space className="search-wrapper">
          <Search
            placeholder="search"
            allowClear
            onChange={(event) => handleInputOnChange(event.target.value)}
            onSearch={handleOnSearch}
          />
          {selectedFilters && (
            <PurchaseOrderFilters
              onResetFilters={onResetFilters}
              onFilterChange={onFilterChange}
              selectedFilters={selectedFilters}
              onFilterDrawerChange={onFilterDrawerChange}
            />
          )}
        </Space>
        <Space>
          {!isBookingOngoing && (
            <CheckboxList
              options={columns}
              selectedItems={selectedColumns}
              onChange={selectColumns}
              tableName={UserTablesEnum.PurchaseOrderTable}
            >
              <Button icon={<DownOutlined />}> Adjust Columns</Button>
            </CheckboxList>
          )}
          <Button
            onClick={onCreateBooking}
            disabled={isBookingOngoing || !isBookingAvailable}
          >
            Create New Booking
          </Button>
          <CreatePurchaseOrderModal buttonProps={{ type: 'primary' }} />
        </Space>
      </Row>
      <div className="purchase-orders-wrapper">
        <AppTabs
          size="small"
          defaultActiveKey={defaultKey}
          activeKey={defaultKey}
          onTabClick={(key: string) => {
            setTabKey(key);
          }}
          items={[
            {
              label: 'All Orders',
              key: 'active',
              children: renderTableByState(undefined),
            },
            {
              label: 'In Progress',
              key: 'progress',
              children: renderTableByState(false),
            },
            {
              label: 'Drafts',
              key: 'drafts',
              children: renderTableByState(true),
            },
          ]}
        />
      </div>

      <PurchaseOrderEdit
        order={isEditMode.order ?? null}
        isOpen={isEditMode.open === 'EDIT'}
        onCloseEditModal={() => setIsEditMode({ open: false, order: null })}
      />
      <Modal
        open={isEditMode.open === 'FILES'}
        footer={null}
        onCancel={() => setIsEditMode({ open: false, order: null })}
      >
        <PurchaseOrderFiles orderId={isEditMode.order?.id ?? null} />
      </Modal>
      {selectedRow &&
        renderCollaboratorModal(selectedRow, currentUserAccessLevel)}
    </DashboardCard>
  );
};

export default PurchaseOrdersTabs;
