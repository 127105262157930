import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import LocationFilter from 'app/components/ShipmentFilters/LocationFilter';
import React, { FC } from 'react';
import { IPurchaseOrderLocationFilterProps } from 'types/feature/purchase-orders.types';

const POOriginFilter: FC<IPurchaseOrderLocationFilterProps> = ({
  onFilterChange,
  selectedFilters,
  allShipmentsLocation,
}) => {
  return (
    <div>
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <LocationFilter
            layout="horizontal"
            defaultValue={selectedFilters?.origins}
            locations={allShipmentsLocation?.origins}
            onChange={(values) => onFilterChange(values as string[], 'origins')}
          />
        }
        buttonText={'Origins'}
        itemKey={'origins'}
      />
    </div>
  );
};

export default POOriginFilter;
