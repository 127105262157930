import Icon from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { Collapse, Row, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { ICostHeadCollapseProps } from 'types/feature/contracts.type';

import { formatOriginToDest } from 'utils/format-fields';

import ChargesTable from '../ChargesTable';
import '../contracts.style.scss';
import { renderCoastHeadIcon } from './cost-head.helper';

const { Text } = Typography;

const CoastHeadCollapse: FC<ICostHeadCollapseProps> = ({
  costHeads,
  shipmentType,
}) => {
  const renderItems: CollapseProps['items'] = costHeads.map(
    (costHead, index) => {
      return {
        key: costHead.id,
        label: (
          <Row justify="space-between">
            <Space>
              <Icon
                component={renderCoastHeadIcon(costHead)}
                className="coast-head-collapse--icon"
              />
              <Text strong>{costHead.title}</Text>
              <Text>
                ( {formatOriginToDest(costHead.destination, costHead.origin)} )
              </Text>
            </Space>
            {costHead.rate && <Text strong>{costHead.rate} $</Text>}
          </Row>
        ),
        children: (
          <ChargesTable
            charges={costHead.charges}
            tableKey={`charges-${costHead.id}-${index}`}
            shipmentType={shipmentType}
          />
        ),
      };
    }
  );

  return (
    <Collapse
      items={renderItems}
      expandIconPosition="end"
      bordered={false}
      className="coast-head-collapse"
    />
  );
};

export default CoastHeadCollapse;
