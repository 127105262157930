import { Button, Form, Input, Row, Spin, notification } from 'antd';
import { useCreateApiKeyMutation, useGetApiKeyQuery } from 'api/api-key';
import React, { FC, useEffect, useState } from 'react';

import { displayErrors } from 'utils/error-notification';

import './developer-settings.scss';

const DeveloperSettings: FC = () => {
  const {
    data: apiKeyData,
    isLoading: isApiKeyLoading,
    isError: isApiKeyError,
    error: apiKeyError,
  } = useGetApiKeyQuery();

  const [generateApiKey, { isLoading: isUpdating }] = useCreateApiKeyMutation();

  const [apiKeyVisible, setApiKeyVisible] = useState(false);

  useEffect(() => {
    if (isApiKeyError && apiKeyError) {
      displayErrors(apiKeyError, { title: 'Developer Settings Error' });
    }
  }, [isApiKeyError, apiKeyError]);

  const handleGenerateApiKey = async () => {
    try {
      await generateApiKey().unwrap();
      notification.success({
        message: 'API Key has been generated successfully!',
      });
    } catch (error: any) {
      displayErrors(error, { title: 'Developer Settings Error' });
    }
  };

  const toggleApiKeyVisibility = () => {
    setApiKeyVisible(!apiKeyVisible);
  };

  return (
    <div className="api-key-container">
      {isApiKeyLoading ? (
        <Row className="full-width py-3" justify="center">
          <Spin />
        </Row>
      ) : (
        <>
          {apiKeyData?.data && (
            <div className="api-key-input">
              <Form.Item label="Your API Key:" labelCol={{ span: 24 }}>
                <Input
                  type={apiKeyVisible ? 'text' : 'password'}
                  value={apiKeyData.data.key}
                  readOnly
                />
              </Form.Item>
            </div>
          )}

          <Button
            type="primary"
            onClick={
              apiKeyData?.data ? toggleApiKeyVisibility : handleGenerateApiKey
            }
            loading={isApiKeyLoading || isUpdating}
            className={apiKeyData?.data ? 'api-key-btn' : ''}
          >
            {apiKeyData?.data
              ? apiKeyVisible
                ? 'Hide API Key'
                : 'Show API Key'
              : 'Generate API Key'}
          </Button>
        </>
      )}
    </div>
  );
};

export default DeveloperSettings;
