import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Space, Typography, notification } from 'antd';
import {
  useInviteExistingCollaboratorMutation,
  useLazyGetCollaboratorByShipmentQuery,
} from 'api/collaborators';
import ProfileImage from 'app/components/ProfilePicture';
import MultipleDocsUploadModal from 'app/components/ShipmentDocuments/UploadDocumentModal/MultipleUploadModal';
import FilePreview from 'app/design-system/FilePreview';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  MentionsInput,
  Mention,
  MentionItem,
  SuggestionDataItem,
} from 'react-mentions';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IMessageEntity } from 'types/entities/message.entity';
import {
  CustomSuggestionDataItem,
  IMentionInput,
  IMessagesProps,
} from 'types/feature/messages.type';

import { fileAddEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import MessageItem from '../MessageItem';
import { getMentionData } from '../shipment-messages-util';
import './messages.scss';

dayjs.extend(relativeTime);

const { Title, Text } = Typography;

const messageInitialStateConst = {
  mentions: [],
  originalText: '',
  markupText: '',
};

const Messages: FC<IMessagesProps> = ({
  messages,
  onAddMessage,
  isLoading,
  currentUser,
  userList,
  shipmentId,
  shipmentSlug,
  fitContent,
  firstUnreadMessageId,
}) => {
  const { bookingParty, resetShareBookingParty } = useBookingPartyState();
  const [mentionInput, setMentionInput] = useState<IMentionInput>(
    messageInitialStateConst
  );
  const [fileList, setFileList] = useState<string[]>([]);
  const isSendDisabled = !mentionInput.originalText;
  const user = useSelector((state: RootState) => state.user.user);

  const [refetchCollaboratorsByShipment] =
    useLazyGetCollaboratorByShipmentQuery();

  const isTagEmpty =
    !!mentionInput.originalText && mentionInput.mentions.length < 1;

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    const lastItem = messageContainerRef.current?.getElementsByClassName(
      `message-item-${messages.length - 1}`
    );
    if (lastItem && lastItem.length > 0) {
      lastItem[0].scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const [
    addExistingCollaborators,
    {
      isError: isInvitationError,
      error: invitationError,
      isSuccess: invitationSuccess,
    },
  ] = useInviteExistingCollaboratorMutation();

  const onConfirmAddUser = (newCollaborators: string[]) => {
    Modal.confirm({
      icon: null,
      okText: 'Yes',
      content:
        'This person is not a collaborator in this shipment. Are you sure you want to add them in this shipment?',
      onOk: async () => {
        try {
          await addExistingCollaborators({
            shipmentId,
            userIds: newCollaborators,
          }).unwrap();
          notification.success({
            message: 'Collaborators added',
            description: `collaborators were added to the shipment successfully`,
          });
          onAddMessage(mentionInput, fileList);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'add collaborator error' });
        }
      },
    });
  };

  useEffect(() => {
    if (bookingParty) {
      const message = `
        ${bookingParty?.companyName}
        Hi,
        Here is the booking party details.

        Role: ${bookingParty?.role}
        Name: ${bookingParty?.contactPerson}
        Email: ${bookingParty?.email}
        Phone Number: ${bookingParty?.phoneNumber}
        Address: ${bookingParty?.address}
        Country: ${bookingParty?.country}

        TAG PERSON: 
      `;

      setMentionInput({
        mentions: [],
        originalText: message,
        markupText: message,
      });
    } else {
      setMentionInput(messageInitialStateConst);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingParty]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, messageContainerRef]);

  useEffect(() => {
    if (isInvitationError && invitationError) {
      displayErrors(invitationError);
    }
  }, [isInvitationError, invitationError]);

  useEffect(() => {
    if (invitationSuccess) {
      notification.success({ message: 'Invitation sent to collaborators !' });
    }
  }, [invitationSuccess]);

  const onSendClick = async () => {
    setMentionInput(messageInitialStateConst);
    setFileList([]);
    if (bookingParty && resetShareBookingParty) {
      resetShareBookingParty();
    }
    try {
      const updatedCollabData = await refetchCollaboratorsByShipment(
        shipmentId
      ).unwrap();
      const newCollaborators = mentionInput.mentions.filter(
        (userId) =>
          !updatedCollabData?.some(
            (collab) => collab.collaborator.id === userId
          )
      );

      if (newCollaborators.length > 0) {
        onConfirmAddUser(newCollaborators);
      } else {
        onAddMessage(mentionInput, fileList);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, {
        title: 'failed to get updated list of collaborators',
      });
    }
  };

  const handleChange = (
    _: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setMentionInput({
      mentions: mentions.map((m) => m.id),
      originalText: newPlainTextValue,
      markupText: newValue,
    });
  };

  const onUploadComplete = (files: string[]) => {
    setFileList([...fileList, ...files]);
    fileAddEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
    });
  };

  const onRemoveAttachment = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  //send messages by pressing enter (to go a new line you need to press shift + enter)
  const handleOnKeyPress = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (isTagEmpty) {
      return;
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSendClick();
    }
  };
  const renderDivider = (message: IMessageEntity) => {
    if (!firstUnreadMessageId) {
      return;
    }
    if (firstUnreadMessageId === message.id) {
      return (
        <div className="divider-container">
          <span className="message-timestamp">
            Sent at: {dayjs(message.createdAt).format('YYYY-MM-DD HH:mm')}
          </span>
          <Divider orientation="right">New</Divider>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`messages-wrapper${fitContent ? '__fit-content' : ''}`}>
      <div ref={messageContainerRef} className="messages-list-wrapper">
        {messages.map((message, index) => (
          <div key={message.id} className={`message-item-${index}`}>
            {renderDivider(message)}
            <MessageItem
              message={{
                userName: `${message.createdBy.fullname}`,
                messageDate: dayjs(message.createdAt).fromNow(),
                message: message.originalMessage ?? message.message,
                userPhoto: null,
                files: message.files,
              }}
              userList={getMentionData(userList)}
              isActive={!!(currentUser.id === message.createdBy.id)}
            />
          </div>
        ))}
      </div>
      <div className="send-message-wrapper">
        <ProfileImage
          name={`${currentUser.fullname}`}
          photoUrl={currentUser.photo}
          size={40}
          wrapperClassName="user-gravatar"
        />
        <div className="input-wrapper">
          <MentionsInput
            value={mentionInput?.markupText}
            onChange={handleChange}
            placeholder="Type message here, use the @ symbol to tag other users."
            className="mentions-input"
            onKeyDown={(event) => handleOnKeyPress(event)}
          >
            <Mention
              markup="@{{__id__||__display__}}"
              trigger="@"
              displayTransform={(id, username) => `@${username}`}
              data={getMentionData(userList)}
              className="mentions__mention"
              renderSuggestion={(suggestion: SuggestionDataItem) => {
                const customSuggestion = suggestion as CustomSuggestionDataItem;

                return (
                  <div className="suggestion-item">
                    <Space>
                      <ProfileImage
                        key={customSuggestion.id}
                        name={customSuggestion.display || ''}
                        photoUrl={customSuggestion.photo}
                        className="user-gravatar"
                      />
                      {customSuggestion.display}
                    </Space>
                    {!customSuggestion.isAlreadyInvited && (
                      <span>Not in this shipment</span>
                    )}
                  </div>
                );
              }}
            />
          </MentionsInput>

          <MultipleDocsUploadModal
            shipmentSlug={shipmentSlug}
            shipmentId={shipmentId}
            actionButton={{ text: '', icon: <PaperClipOutlined /> }}
            onUploadComplete={onUploadComplete}
          />

          <Button
            className="add-message-btn"
            type="primary"
            onClick={onSendClick}
            loading={isLoading}
            disabled={isSendDisabled || isTagEmpty}
          >
            Send
          </Button>
        </div>
      </div>
      {isTagEmpty && (
        <Text type="danger" className="message-error-text">
          Use the @ symbol to tag at least one user.
        </Text>
      )}
      {fileList && fileList.length > 0 && (
        <div className="file-preview-list">
          <Title level={5} className="m-0">
            Attachments
          </Title>
          <Space>
            {fileList.map((file, index) => (
              <FilePreview
                key={index}
                imageUrl={file}
                size="small"
                onRemove={() => onRemoveAttachment(index)}
              />
            ))}
          </Space>
        </div>
      )}
    </div>
  );
};

export default Messages;
