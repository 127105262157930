import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  InputNumber,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { addAllBookingItems } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';
import { IPurchaseOrderItemsProps } from 'types/feature/purchase-orders.types';

import { purchaseOrderBookingCreationStartEvent } from 'utils/analytics-events';
import { isViewer } from 'utils/collaborators';
import { filterColumns } from 'utils/filter-table-columns';

import { ReactComponent as EmptyOrders } from '../../../../assets/orders.svg';
import { POsColumnConst } from './po-items-table.const';
import './purchase-order-items.scss';

const { Text } = Typography;
const PurchaseOrderItems: FC<IPurchaseOrderItemsProps> = ({
  accessLevel,
  purchaseOrderId,
  purchaseOrderRef,
  items,
  selectedColumns,
  isBookingOngoing,
  isShipmentTab,
  isDraft,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const addItemToBooking = (item: IPurchaseOrderItemEntity) => {
    dispatch(
      addAllBookingItems({
        order: { id: purchaseOrderId, reference: purchaseOrderRef },
        items: [item],
      })
    );

    purchaseOrderBookingCreationStartEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      source: 'addItemToBooking',
      company_id: user?.companyId,
    });
  };

  const addAllItemToBooking = () => {
    const mappedItems = items
      .map((item, index: number) => {
        const qteToBook =
          form.getFieldValue([item.id ?? index, 'booking']) ??
          (item.requestedQuantity ?? 0) - (item.bookedQuantity ?? 0);
        return {
          ...item,
          toBook: qteToBook,
        };
      })
      .filter(
        (item) =>
          item.toBook > 0 && item.id && selectedRowKeys.includes(item.id)
      );

    if (!mappedItems.length) {
      message.warning('Make sure to insert valid quantities !');
      return;
    }

    dispatch(
      addAllBookingItems({
        order: { id: purchaseOrderId, reference: purchaseOrderRef },
        items: mappedItems,
      })
    );
  };
  const editColumn: (ColumnType<IPurchaseOrderItemEntity> & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: 'actions',
      dataIndex: 'action',
      editable: true,
      title: (
        <Button
          onClick={addAllItemToBooking}
          disabled={!selectedRowKeys.length || isDraft || isViewer(accessLevel)}
        >
          Add Selected Items
        </Button>
      ),
      render: (_, record: IPurchaseOrderItemEntity, index: number) => {
        return (
          <Space>
            <Form.Item
              name={[record.id ?? index, 'booking']}
              noStyle
              initialValue={record.toBook}
            >
              <InputNumber
                disabled={isViewer(accessLevel)}
                className="book-input"
                max={
                  (record.requestedQuantity ?? 0) - (record.bookedQuantity ?? 0)
                }
                min={0}
              />
            </Form.Item>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                const qte = form.getFieldValue([record.id ?? index, 'booking']);
                if (qte) {
                  addItemToBooking({
                    ...record,
                    toBook: qte,
                  });
                } else {
                  message.warning('Enter a valid quantity please !');
                }
              }}
              disabled={
                record.requestedQuantity === record.bookedQuantity ||
                isDraft ||
                isViewer(accessLevel)
              }
            >
              {!isBookingOngoing ? 'Add To Booking' : ''}
            </Button>
          </Space>
        );
      },
      align: 'right',
      width: 250,
    },
  ];

  const defaultColumns = [
    ...filterColumns(POsColumnConst, selectedColumns),
    ...(isShipmentTab ? [] : editColumn),
  ];

  return (
    <Form form={form}>
      <Table
        columns={defaultColumns}
        dataSource={items}
        rowKey="id"
        size="small"
        className="items-table"
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          onChange: onSelectChange,
          getCheckboxProps: (record: IPurchaseOrderItemEntity) => ({
            disabled: record.bookedQuantity === record.requestedQuantity,
          }),
        }}
        locale={{
          emptyText: (
            <Space direction="vertical" className="empty-table-wrapper">
              <EmptyOrders />
              <Text strong>Purchase Orders</Text>
              <Text type="secondary">Create new orders to view items</Text>
            </Space>
          ),
        }}
      />
    </Form>
  );
};

export default PurchaseOrderItems;
