import {
  IGetUserTableSetting,
  ISetUserTableSetting,
  IUserTableSetting,
} from 'types/feature/user-table-setting.type';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const userTableSettingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserTableSetting: build.query<string[], IGetUserTableSetting>({
      query: (payload) => ({
        url: '/table-settings',
        params: payload,
      }),
      transformResponse: (res: ApiResponse<IUserTableSetting>, meta, arg) => {
        const columns: string[] = [];
        if (res.data?.setting) {
          const jsonRes = JSON.parse(res.data.setting);
          columns.push(...Object.keys(jsonRes));
        }
        return columns;
      },
      providesTags: (res: string[] = [], meta, arg) => [
        { type: 'UserTableSetting', id: arg.tableName },
      ],
    }),
    setUserTableSetting: build.mutation<
      ApiResponse<IUserTableSetting>,
      ISetUserTableSetting
    >({
      query: (payload) => ({
        url: '/table-settings',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (res, meta, arg) => [
        { type: 'UserTableSetting', id: arg.tableName },
      ],
    }),
  }),
});

export const { useGetUserTableSettingQuery, useSetUserTableSettingMutation } =
  userTableSettingApi;
