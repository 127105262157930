import { Alert, Button, Skeleton, Table } from 'antd';
import { useGetInvoicesForReviewQuery } from 'api/dashboard';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IInvoiceForReviewEntity } from 'types/entities/invoice.entity.js';

import { reviewInvoicesColumnsConst } from './review-invoices-card-const.tsx';

const ReviewInvoicesCard: FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetInvoicesForReviewQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const onViewAll = () => {
    navigate('/invoices');
  };
  return (
    <DashboardCard
      headerTitle="Review Invoices"
      rightAccessory={
        <Button type="text" onClick={onViewAll}>
          View All
        </Button>
      }
      fitContent
    >
      {error && (
        <Alert
          showIcon
          message="Unexpected error while fetching invoices"
          type="error"
        />
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          className="dashboard-card-table"
          columns={[
            ...reviewInvoicesColumnsConst,
            {
              key: 'action',
              render: (_, record: IInvoiceForReviewEntity) => (
                <Link
                  to={`/shipments/SH-${record.shipment.slug}/invoice/${record.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Review
                </Link>
              ),
            },
          ]}
          dataSource={data?.data.slice(0, 4) || []}
          showSorterTooltip
          pagination={{
            hideOnSinglePage: true,
          }}
          size="middle"
          loading={isLoading}
          rowKey="id"
        />
      )}
    </DashboardCard>
  );
};

export default ReviewInvoicesCard;
