import { Drawer, Button, Typography, Badge, Space, Divider } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { IPurchaseOrderFilters } from 'types/feature/purchase-orders.types';
import { IPurchaseOrderFilterDrawerProps } from 'types/feature/purchase-orders.types';

import { computeAllFiltersCount } from 'utils/shipment-helper';

import CreatedDatesFilter from '../POCreatedDateFilter';
import POCreatorFilter from '../POCreatorFilter';
import PODestinationFilter from '../PODestinationFilter';
import POModalitiesFilter from '../POModalitiesFilter';
import POOriginFilter from '../POOriginFilter';
import POStatusesFilter from '../POStatusesFilter';
import POSupplierFilter from '../POSupplierFilter';

const PurchaseOrderFilterDrawer: FC<IPurchaseOrderFilterDrawerProps> = ({
  defaultValue,
  onChange,
  onReset,
  allContacts,
  allPurchaseOrderLocations,
}) => {
  const [open, setOpen] = useState(false);

  const [purchaseOrderFilters, setPurchaseOrderFilters] =
    useState<IPurchaseOrderFilters>({});

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setPurchaseOrderFilters({});
    onReset();
  };

  const onApplyFilter = () => {
    onChange(purchaseOrderFilters);
    setOpen(false);
  };

  const renderFilterCount = () => {
    const filterCount = computeAllFiltersCount(purchaseOrderFilters);
    return filterCount > 0 && <Badge count={filterCount} color="#6F6F6F" />;
  };

  useEffect(() => {
    setPurchaseOrderFilters(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Button onClick={showDrawer}>
        <Space>All Filters {renderFilterCount()}</Space>
      </Button>
      <Drawer
        title="Invoice Filters"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        footer={
          <div className="filter-footer-actions">
            <Button onClick={onClose} className="mx-1">
              Cancel
            </Button>
            <Button type="primary" onClick={onApplyFilter}>
              <Space>Apply Filters {renderFilterCount()}</Space>
            </Button>
          </div>
        }
      >
        <div>
          <Typography.Title level={5}>Purchase Order Status</Typography.Title>
          <POStatusesFilter
            layout={'horizontal'}
            defaultValue={purchaseOrderFilters.poStatuses}
            onChange={(value) =>
              setPurchaseOrderFilters({
                ...purchaseOrderFilters,
                poStatuses: value,
              })
            }
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Modality</Typography.Title>
          <POModalitiesFilter
            layout={'horizontal'}
            defaultValue={purchaseOrderFilters.modalities}
            onChange={(value) =>
              setPurchaseOrderFilters({
                ...purchaseOrderFilters,
                modalities: value,
              })
            }
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Creators</Typography.Title>
          <POCreatorFilter
            onFilterChange={(values) => {
              setPurchaseOrderFilters({
                ...purchaseOrderFilters,
                creators: values,
              });
            }}
            selectedFilters={purchaseOrderFilters}
            allContacts={allContacts}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Suppliers</Typography.Title>
          <POSupplierFilter
            onFilterChange={(values) => {
              setPurchaseOrderFilters({
                ...purchaseOrderFilters,
                suppliers: values,
              });
            }}
            selectedFilters={purchaseOrderFilters}
            allContacts={allContacts}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Origins</Typography.Title>
          {allPurchaseOrderLocations && (
            <POOriginFilter
              onFilterChange={(values) => {
                setPurchaseOrderFilters({
                  ...purchaseOrderFilters,
                  origins: values,
                });
              }}
              selectedFilters={purchaseOrderFilters}
              allShipmentsLocation={allPurchaseOrderLocations}
            />
          )}
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Destinations</Typography.Title>
          {allPurchaseOrderLocations && (
            <PODestinationFilter
              onFilterChange={(values) => {
                setPurchaseOrderFilters({
                  ...purchaseOrderFilters,
                  destinations: values,
                });
              }}
              selectedFilters={purchaseOrderFilters}
              allShipmentsLocation={allPurchaseOrderLocations}
            />
          )}
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Created Dates</Typography.Title>
          <CreatedDatesFilter
            onFilterChange={(values) => {
              setPurchaseOrderFilters({
                ...purchaseOrderFilters,
                createdDate: values,
              });
            }}
            selectedFilters={purchaseOrderFilters}
          />
        </div>
      </Drawer>
    </>
  );
};

export default PurchaseOrderFilterDrawer;
