import { Button, Modal, Typography } from 'antd';
import React, { FC } from 'react';
import { ShipmentInviteTypeEnum } from 'types/feature/create-shipment.types';
import { SuccessModalProps } from 'types/feature/success-modal.types';

import './success-modal.scss';

const { Text, Title } = Typography;
const SuccessModal: FC<SuccessModalProps> = ({
  isOpen,
  shipmentId,
  shipmentInviteType,
  hasExternalConnections,
  onRequestQuote,
  onViewShipment,
}) => {
  const onInviteForwarderClick = () => {
    onRequestQuote(shipmentId.id, shipmentId.slug);
  };

  const onViewShipmentClick = () => {
    onViewShipment(shipmentId.id, shipmentId.slug);
  };

  const renderInviteSection = (
    <Text className="modal-content">
      Your shipment has successfully created. And notified to the contact
      person.
    </Text>
  );

  const renderForwarderInviteSection = (
    <>
      <Text className="modal-content">
        Your shipment has successfully created. Invite forwarders to get
        quotation
      </Text>

      <Button type="primary" onClick={onInviteForwarderClick}>
        Invite a forwarder
      </Button>
    </>
  );

  const renderCollaboratorInviteSection = (
    <>
      <Text className="modal-content">
        Your shipment has successfully created. Invite collaborator to the
        shipment.
      </Text>

      <Button type="primary" onClick={onInviteForwarderClick}>
        Invite a collaborator
      </Button>
    </>
  );

  return (
    <Modal
      open={isOpen}
      footer={null}
      destroyOnClose
      onCancel={onViewShipmentClick}
    >
      <div className="modal-container">
        <Title level={3}>Congratulations 🎉</Title>
        {hasExternalConnections
          ? renderInviteSection
          : shipmentInviteType === ShipmentInviteTypeEnum.FORWARDER
          ? renderForwarderInviteSection
          : renderCollaboratorInviteSection}
        <Button type="text" onClick={onViewShipmentClick}>
          View Shipment
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
