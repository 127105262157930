import { Button, Modal, message } from 'antd';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IProposalActionsProps } from 'types/feature/proposal.types';

import { mapProposalToContract } from './proposal-contract.utility';

const { confirm } = Modal;

const ProposalContractButton: FC<IProposalActionsProps> = ({
  proposal,
  shipment,
  actionButton,
}) => {
  const navigate = useNavigate();

  const onCreateContractClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Confirm',
      icon: null,
      content: 'Are you sure you want to turn this proposal into contract?',
      onOk: async () => {
        try {
          const extractedData = mapProposalToContract(proposal, shipment);
          navigate('/contracts/create', {
            state: {
              contract: extractedData,
              proposalId: proposal.id,
            },
          });
        } catch {
          message.error('Unable to create contract from this proposal !');
        }
        return;
      },
    });
  };
  return (
    <Button
      size={actionButton.size}
      onClick={onCreateContractClick}
      disabled={proposal.status === IQuoteStatus.REQUESTED}
    >
      Add as new Contract
    </Button>
  );
};

export default ProposalContractButton;
