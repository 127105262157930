import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch, Table, Tooltip, Typography, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useGetCompanySettingsQuery,
  useUpdateCompanySettingsMutation,
} from 'api/company-settings';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ICompanySetting } from 'types/entities/setting.entity';

import { companySettingsChange } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { splitState } from 'utils/format-fields';

import './company-settings.scss';

const { Text } = Typography;
const CompanySettings: FC = () => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const { data, isLoading, isError, error } = useGetCompanySettingsQuery();
  const [updateSettings, { isLoading: isUpdating, isSuccess }] =
    useUpdateCompanySettingsMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Company settings have been updated successfully !',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error)
      displayErrors(error, { title: 'Company Settings Error' });
  }, [isError, error]);

  const onChangeSettingsCta = (mode: 'on' | 'off') => {
    if (currentUser) {
      companySettingsChange({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
        mode,
      });
    }
  };

  const onSettingChange = async (settingId: string, isEnabled: boolean) => {
    try {
      await updateSettings({ settingId, isEnabled }).unwrap();
      onChangeSettingsCta(isEnabled ? 'on' : 'off');
      //eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Company Settings Error' });
    }
  };

  const settingsColumns: ColumnsType<ICompanySetting> = [
    {
      title: 'Actions',
      render: (_, record: ICompanySetting) => (
        <Space direction="vertical" size={[1, 1]}>
          <Text strong>{splitState(record.title)}</Text>
          <Text type="secondary">{record.description}</Text>
        </Space>
      ),
      align: 'left',
    },
    {
      title: 'Enabled',
      render: (_, record: ICompanySetting) => (
        <Tooltip
          title={
            record.isEnabled ? 'Disable this setting!' : 'Enable this setting !'
          }
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.isEnabled}
            onChange={(checked) => onSettingChange(record.id, checked)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={settingsColumns}
        dataSource={data ?? []}
        showSorterTooltip
        pagination={{ hideOnSinglePage: true }}
        size="small"
        loading={isLoading || isUpdating}
        rowKey="id"
        className="settings-table"
        showHeader={false}
      />
    </>
  );
};

export default CompanySettings;
