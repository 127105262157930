import { Empty, Steps } from 'antd';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { SplitScreenMode } from 'types/entities/purchase-order.entity';
import { IPurchaseOrdersBookingProps } from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import AssignBookingsShipment from './AssignBookingsShipment';
import CreatedBookings from './CreatedBooking';
import DraftBooking from './DraftBooking';
import './order-booking.scss';

const PurchaseOrderBooking: FC<IPurchaseOrdersBookingProps> = ({
  onCreateBooking,
  onCloseBookingTab,
  mode,
}) => {
  const [step, setStep] = useState<number>(0);
  const [isNewShipment, setNewShipment] = useState<boolean>(true);
  const { bookings, ids } = useSelector((state: RootState) => state.booking);
  const {
    data: shipmentTypes,
    isError,
    error,
  } = useGetExternalShipmentsTypesQuery();

  const goToNextStep = useCallback(() => {
    setStep((step) => step + 1);
  }, [setStep]);

  const goToPreviousStep = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Shipment Types Error' });
    }
  }, [isError, error]);

  const renderByStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <DraftBooking
            goToNextStep={goToNextStep}
            onCancel={onCloseBookingTab}
          />
        );
      case 1:
        return (
          <CreatedBookings
            isNewShipment={isNewShipment}
            goToNextStep={goToNextStep}
            onChangeOption={() => setNewShipment(!isNewShipment)}
            goToPreviousStep={goToPreviousStep}
            onCancel={onCloseBookingTab}
          />
        );
      case 2:
        return (
          <AssignBookingsShipment
            existingShipment={!isNewShipment}
            shipmentTypes={shipmentTypes}
          />
        );
      default:
        return <Empty />;
    }
  };

  const steps = [
    {
      key: 1,
      title: 'Select POs',
    },
    {
      key: 2,
      title: 'Create Booking',
      disabled: !ids?.length,
    },
    {
      key: 3,
      title: 'Assign to Shipment',
      disabled: !ids?.length,
    },
  ];

  useEffect(() => {
    if (mode === SplitScreenMode.ONLY_PO && bookings.length) {
      onCreateBooking();
    }
  }, [mode, bookings, onCreateBooking]);

  return (
    <>
      <div className="booking-tabs-wrapper">
        <Steps
          items={steps}
          size="default"
          current={step}
          onChange={(step) => setStep(step)}
        />
        {renderByStep(step)}
      </div>
    </>
  );
};

export default PurchaseOrderBooking;
