import { IProposalEntity } from 'types/entities/proposal.entity';

import {
  isProposalSubmitted,
  isProposalRequested,
} from 'utils/proposal-helper';

export const forwarderRequestedAndSubmittedQuoteCount = (
  proposals: IProposalEntity[],
  companyId: string
) => {
  let submittedQuotes = 0;
  let requestedQuotes = 0;

  proposals.forEach((proposal) => {
    if (proposal.companyId === companyId) {
      if (isProposalSubmitted(proposal.status)) {
        submittedQuotes += 1;
        requestedQuotes += 1;
      }
      if (isProposalRequested(proposal.status)) {
        requestedQuotes += 1;
      }
    }
  });

  return { requestedQuotes, submittedQuotes };
};
