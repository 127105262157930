import { Button, Form, Input, Modal, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { IDocumentExtensionEnum } from 'types/entities/document.entity';
import {
  ISaveExtractedDocumentForm,
  ISaveExtractedDocumentModalProps,
} from 'types/feature/document-extractor.types';

const options = [
  {
    label: 'Excel',
    value: IDocumentExtensionEnum.EXCEL,
  },
];

const SaveDocumentModal: React.FC<ISaveExtractedDocumentModalProps> = ({
  shipmentId,
  file,
  isOpen,
  onClose: closeModal,
  onSave,
  isLoading,
}) => {
  const [form] = useForm<ISaveExtractedDocumentForm>();

  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();

      onSave(values);
    } catch (error) {
      message.warning('Make sure to fill all fields !');
    }
  };

  return (
    <div>
      <Modal
        title="Save Document"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="save"
            onClick={handleUpdate}
            loading={isLoading}
          >
            Save File
          </Button>,
        ]}
        width={450}
        destroyOnClose
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          initialValues={{
            name: file?.name,
            extension: file?.extension,
          }}
        >
          <Form.Item label="Document Name" name="name">
            <Input placeholder="Document Name" />
          </Form.Item>

          <Form.Item label="Document Type" name="extension">
            <Select
              size="middle"
              placeholder="Document type"
              options={options}
              className="documents-type-selector"
              data-testid="document-save-extension-btn"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SaveDocumentModal;
