import { Button, Drawer, Empty, notification } from 'antd';
import { useCreateShipmentMutation } from 'api/shipment';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISingleShipmentResponse,
  ITrackingPreviewProps,
} from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';
import { formatShipmentState } from 'utils/format-fields';

import OriginDestinationPreview from '../ShipmentDetails/OriginDestinationPreview';
import ShipmentProgress from '../ShipmentDetails/ShipmentProgress';

const TrackingPreview: FC<ITrackingPreviewProps> = ({
  isOpen,
  onClose,
  shipment,
}) => {
  const navigate = useNavigate();
  //Queries
  const [createShipments, { isLoading, isSuccess }] =
    useCreateShipmentMutation();

  //Methods
  const handleCreateClick = async (
    shipment: ISingleShipmentResponse | undefined
  ) => {
    if (!shipment) {
      return;
    }
    try {
      const createdShipments = await createShipments({
        body: shipment,
        params: undefined,
      }).unwrap();
      navigate(`shipments/SH-${createdShipments.slug}`);
      onClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to create shipment' });
    }
  };

  //Hooks
  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Shipment Created Successfully' });
    }
  }, [isSuccess]);
  return (
    <Drawer
      destroyOnClose
      width={'70vw'}
      open={isOpen}
      onClose={onClose}
      headerStyle={{ background: '#DAE6EF' }}
      title={shipment?.trackingNumber}
      extra={
        <Button
          loading={isLoading}
          type="primary"
          onClick={() => handleCreateClick(shipment)}
          className="footer-action-btn"
        >
          Create Shipment
        </Button>
      }
    >
      {shipment ? (
        <div>
          <ShipmentProgress shipment={shipment} isTracking />
          <OriginDestinationPreview shipment={shipment} />
        </div>
      ) : (
        <Empty description="Unble to fetch tracking details" />
      )}
    </Drawer>
  );
};

export default TrackingPreview;
