import { capitalize } from './format-fields';

const initialsUrl = 'https://avatars.dicebear.com/api/initials';
export const getEmoji = (name: string) => `${initialsUrl}/:${name}.svg`;

export const scrollIntoView = (
  ref: React.RefObject<HTMLDivElement>,
  targetClass: string
) => {
  const container = ref.current?.getElementsByClassName(targetClass);

  if (container && container.length > 0) {
    container[0].scrollIntoView({ behavior: 'smooth' });
  }
};

// Removes Z from ISO format to avoid time zone conversion.
export const removeZFromISO = (date: string) => date.replace('Z', '');

export const flattenedEnumValue = (value: string) =>
  capitalize(value.replace(/_/g, ' '));

export const searchString = (str: string) =>
  str.toLowerCase().replace(/\s/g, '');

export const selectOptionFilter = (input, option) =>
  searchString(option.label ?? '').indexOf(searchString(input)) !== -1;

export const roundToPrecision = (num: number, precision?: number) =>
  isNaN(num) ? 0 : parseFloat(num.toFixed(precision ?? 2));
