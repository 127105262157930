import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Typography, Divider, Tooltip } from 'antd';
import Upload from 'antd/es/upload';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'state/slices/user.slice';
import { ISideCardProps } from 'types/feature/profile.types';

import { splitState } from 'utils/format-fields';

import { useUploadProfilePicture } from './useUploadProdilePicture';

const { Text, Title } = Typography;

const SideCard: FC<ISideCardProps> = ({ profile, refetchProfile }) => {
  const dispatch = useDispatch();
  const refetch = async () => {
    const res = await refetchProfile();
    if (res.data && res.isSuccess) {
      dispatch(
        setCurrentUser({
          ...res.data,
        })
      );
    }
  };
  const { props, profileImgUrl } = useUploadProfilePicture({
    refetch,
  });
  return (
    <DashboardCard>
      <div className="profile-wrapper">
        <Tooltip title="Upload or Edit your profile picture">
          <Upload {...props}>
            {profileImgUrl ? (
              <Avatar size={100} src={profileImgUrl} />
            ) : profile.photo ? (
              <Avatar size={100} src={profile.photo ?? profileImgUrl} />
            ) : (
              <div>
                {<PlusOutlined />}
                <div className="upload-btn">Add Picture</div>
              </div>
            )}
          </Upload>
        </Tooltip>

        <Title level={5}>{profile.fullname}</Title>
        <Text type="secondary">{splitState(profile.userRole)}</Text>
        <Divider />
        <Text strong type="secondary">
          {profile.email}
        </Text>
      </div>
    </DashboardCard>
  );
};

export default SideCard;
