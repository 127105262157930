import { Tooltip, Typography } from 'antd';
import React, { FC } from 'react';

import './style.scss';

interface IShipmentReferenceProps {
  reference: string;
  className: string;
  isSecondary?: boolean;
}
const ShipmentReference: FC<IShipmentReferenceProps> = ({
  reference,
  className,
  isSecondary,
}) => {
  return (
    <Tooltip title={reference}>
      <Typography.Text
        strong={!isSecondary}
        className={`reference-text ${className}`}
      >
        {reference}
      </Typography.Text>
    </Tooltip>
  );
};

export default ShipmentReference;
