import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TrackingByAiState {
  isStartTracking: boolean;
  shipmentId: string | undefined;
  shipmentSlug: string | undefined;
  fileUrl: string | undefined;
}

const initialState: TrackingByAiState = {
  isStartTracking: false,
  shipmentId: undefined,
  shipmentSlug: undefined,
  fileUrl: undefined,
};

export const trackingByAiSlice = createSlice({
  name: 'trackingByAi',
  initialState,
  reducers: {
    setIsStartTracking: (state, action: PayloadAction<boolean>) => {
      state.isStartTracking = action.payload;
    },
    setShipmentId: (state, action: PayloadAction<string>) => {
      state.shipmentId = action.payload;
    },
    setShipmentSlug: (state, action: PayloadAction<string>) => {
      state.shipmentSlug = action.payload;
    },
    setFileUrl: (state, action: PayloadAction<string>) => {
      state.fileUrl = action.payload;
    },
  },
});

export const {
  setIsStartTracking,
  setShipmentId,
  setShipmentSlug,
  setFileUrl,
} = trackingByAiSlice.actions;

export default trackingByAiSlice.reducer;
