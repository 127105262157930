export enum TaskStatusEnum {
  TODO = 'to_do',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export interface TaskAssignee {
  id: string;
  fullname: string;

  photo: string;
}

export interface ITaskEntity {
  createdDate?: number;
  createdBy?: string;
  modifiedDate?: number;
  name: string;
  dueDate: string;
  status: TaskStatusEnum;
  assignee: TaskAssignee;
  id: string;
  shipment: string;
  shipmentId: string;
  isPastDeadline: boolean | null;
}

export interface ICreateTaskRequestBody {
  shipmentId: string;
  data: {
    name?: string;
    dueDate?: string;
    status?: string;
    assignee?: string;
  };
}

export interface IUpdateTaskRequestBody {
  taskId: string;
  data: {
    name?: string;
    dueDate?: string;
    status?: string;
    assignee?: string | null;
  };
}
