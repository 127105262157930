import { IAuthToken } from 'types/feature/auth.types';

const TOKEN_KEY = 'user_auth_token';
const REFRESH_KEY = 'user_refresh_token';
const EXPIRY_TIME_KEY = 'expiry_time';
const EXTERNAL_EMAIL = 'invitee_email_address';
const USER_TIMEZONE = 'user_timezone';

const REMEMBER_ME = 'remember_me';

// fetch tokens
export const getRefreshToken: () => string = () =>
  localStorage.getItem(REFRESH_KEY) ?? '';

export const getAuthToken: () => string | null = () =>
  localStorage.getItem(TOKEN_KEY);

export const getSessionEmail: () => string | null = () =>
  localStorage.getItem(EXTERNAL_EMAIL);

export const setSessionEmail = (email: string) =>
  localStorage.setItem(EXTERNAL_EMAIL, email);

export const getSessionTimezone: () => string | null = () =>
  localStorage.getItem(USER_TIMEZONE);

export const setSessionTimezone = (email: string) =>
  localStorage.setItem(USER_TIMEZONE, email);

export const getExpiryDate: () => number | null = () => {
  const date = localStorage.getItem(EXPIRY_TIME_KEY);
  if (date) return Number(date);
  return null;
};

export const getRememberMe: () => boolean | null = () =>
  localStorage.getItem(REMEMBER_ME) === 'YES';

export const setRememberMe = (remeberMe: boolean) =>
  localStorage.setItem(REMEMBER_ME, remeberMe ? 'YES' : 'NO');
// save tokens
export const saveAuthTokens = (
  tokens: IAuthToken,
  externalEmail: string | null
) => {
  const { accessToken, expiresIn, refreshToken } = tokens;
  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_KEY, refreshToken);
  localStorage.setItem(EXPIRY_TIME_KEY, (expiresIn * 1000).toString()); // need to transform it to miliseconds
  if (externalEmail) localStorage.setItem(EXTERNAL_EMAIL, externalEmail);
};

// delete
export const clearAuthTokens = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRY_TIME_KEY);
  localStorage.removeItem(EXTERNAL_EMAIL);
  localStorage.removeItem(USER_TIMEZONE);
  localStorage.removeItem(REFRESH_KEY);
};
