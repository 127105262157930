import {
  ICarrierEntity,
  IGetCarriersRequest,
} from 'types/entities/carrier.entity';

import { api } from './base-api';

export const carrierApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCarriers: build.query<ICarrierEntity[], IGetCarriersRequest>({
      query: (params: IGetCarriersRequest) => ({
        params,
        url: `/carrier`,
      }),
    }),
    getCarriersOfShipment: build.query<
      ICarrierEntity[],
      { shipmentId: string }
    >({
      query: (params) => ({
        url: `/carrier/${params.shipmentId}`,
      }),
      providesTags: ['CarriersByProposals'],
    }),
  }),
});

export const { useGetAllCarriersQuery, useGetCarriersOfShipmentQuery } =
  carrierApi;
