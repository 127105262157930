import { Button, Typography } from 'antd';
import 'providers/booking-parties-provider';
import React, { FC } from 'react';
import { ShareBookingPartyOnboardingScreenProps } from 'types/feature/user-onboarding';

import '../userOnboarding.scss';
import './share-booking-party-onboarding-modal.scss';

const ShareBookingPartyOnboardingScreen: FC<
  ShareBookingPartyOnboardingScreenProps
> = ({ onShareBookingPartyClick, onScreenReset }) => {
  return (
    <div className="booking-party-wrapper">
      <div className="share-bp-illustration" />
      {/* TODO: Move to design system */}
      <div className="user-story-time-line-wrapper">
        <div className="time-line-label">Step 2 of 2</div>
        <div className="time-line-items-wrapper">
          <div className="story-item active"></div>
          <div className="story-item active"></div>
        </div>
      </div>
      <Typography.Title level={3}>Booking Party</Typography.Title>
      <Typography.Paragraph className="booking-party-content">
        Share booking parties information with forwarders
      </Typography.Paragraph>
      <Button type="primary" onClick={onShareBookingPartyClick}>
        Share Booking Party
      </Button>
      <Button type="link" onClick={onScreenReset} block>
        Skip
      </Button>
    </div>
  );
};

export default ShareBookingPartyOnboardingScreen;
