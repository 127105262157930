import { configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { api } from 'api/base-api';
import { freightAIConvertionApi } from 'api/freight-ai';
import { externalProposalApi } from 'api/proposal-external.api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authApi } from '../../api/auth';
import { userApi } from '../../api/user';
import rootReducer, { RootState } from '../reducer';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat(
        userApi.middleware,
        authApi.middleware,
        freightAIConvertionApi.middleware,
        api.middleware,
        externalProposalApi.middleware
      ),
    preloadedState,
  });
};

export const store = setupStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
