import { Button, Modal, notification } from 'antd';
import { useGetCollaboratorByShipmentQuery } from 'api/collaborators';
import {
  useAcceptProposalMutation,
  useGetProposalsByShipmentQuery,
  useRejectProposalMutation,
} from 'api/proposal';
import { useLazyGetShipmentsBySlugQuery } from 'api/shipment';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IProposalActionsProps } from 'types/feature/proposal.types';

import { qoutesReviewEvent } from 'utils/analytics-events';

import './proposal-action.scss';

const { confirm } = Modal;

const isAcceptProposalExist = (proposals: IProposalEntity[] = []) => {
  return proposals.some((p) => p.status === IQuoteStatus.ACCEPTED);
};

const ProposalActions: FC<IProposalActionsProps> = ({
  proposal,
  shipment,
  sendRejectionEmail = true, // this value should awlways be true
  actionButton,
}) => {
  const { slug = '' } = shipment;
  const { showShareBookingPartyModal } = useBookingPartyState();
  const { data: proposalData } = useGetProposalsByShipmentQuery({
    shipmentId: shipment.id,
  });
  const [refetchShipmentBySlug] = useLazyGetShipmentsBySlugQuery();
  const user = useSelector((state: RootState) => state.user.user);

  const { refetch: refetchShipmentCollaborator } =
    useGetCollaboratorByShipmentQuery(shipment.id);

  const [
    acceptProposal,
    {
      data: acceptRespData,
      isSuccess: isAcceptSuccess,
      isError: isAcceptError,
    },
  ] = useAcceptProposalMutation();

  const [
    rejectProposal,
    {
      data: rejectRespData,
      isSuccess: isRejectSuccess,
      isError: isDeclineError,
    },
  ] = useRejectProposalMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Oops!, Unable to update proposal',
    });
  };

  useEffect(() => {
    if (isRejectSuccess) {
      refetchShipmentBySlug(slug);
      refetchShipmentCollaborator();
    }
    // eslint-disable-next-line
  }, [rejectRespData, isRejectSuccess]);

  useEffect(() => {
    if (isAcceptSuccess) {
      refetchShipmentBySlug(slug);
      refetchShipmentCollaborator();
    }

    // eslint-disable-next-line
  }, [acceptRespData, isAcceptSuccess]);

  useEffect(() => {
    if (isAcceptError || isDeclineError) {
      errorNotification();
    }

    // eslint-disable-next-line
  }, [isDeclineError, isAcceptError]);

  const onAcceptClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Accept',
      okType: 'primary',
      icon: null,
      content: (
        <div>
          <p>Are you sure you want to accept this proposal? </p>
          <p>
            <strong>Note:</strong> On accepting this proposal current shipment
            details will be replaced with proposal shipment details.
          </p>
        </div>
      ),
      onOk: async () => {
        try {
          await acceptProposal({
            id: proposal.id,
            params: { sendRejectionEmail },
          });
          qoutesReviewEvent({
            user_id: user?.id,
            email: user?.email,
            company: user?.company?.name || '',
            shipment_id: shipment.id,
            review_result: 'accepted',
            company_id: user?.companyId,
            shipment_type: shipment.shipmentType.title,
            shipment_modality: shipment.shipmentType.modality,
            proposal_type: proposal.shipmentType.title,
            proposal_modality: proposal.shipmentType.modality,
          });
          if (showShareBookingPartyModal) {
            showShareBookingPartyModal();
          }
        } catch {
          errorNotification();
        }
        return;
      },
    });
  };

  const onRejectClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Reject',
      okType: 'danger',
      icon: null,
      content: 'Are you sure you want to reject this proposal?',
      onOk: async () => {
        try {
          await rejectProposal({
            id: proposal.id,
            params: { sendRejectionEmail },
          });
          qoutesReviewEvent({
            user_id: user?.id,
            email: user?.email,
            company: user?.company?.name || '',
            shipment_id: shipment.id,
            review_result: 'rejected',
            company_id: user?.companyId,
            shipment_type: shipment.shipmentType.title,
            shipment_modality: shipment.shipmentType.modality,
            proposal_type: proposal.shipmentType.title,
            proposal_modality: proposal.shipmentType.modality,
          });
        } catch {
          errorNotification();
        }
        return;
      },
    });
  };
  return (
    <div className="proposal-actions">
      <Button
        danger
        size={actionButton.size}
        block={actionButton.isBlock}
        onClick={onRejectClick}
        disabled={proposal.status === IQuoteStatus.DECLINED}
      >
        Reject
      </Button>
      <Button
        type="primary"
        size={actionButton.size}
        block={actionButton.isBlock}
        onClick={onAcceptClick}
        disabled={
          proposal.status === IQuoteStatus.ACCEPTED ||
          isAcceptProposalExist(proposalData)
        }
      >
        Accept
      </Button>
    </div>
  );
};

export default ProposalActions;
