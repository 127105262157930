import dayjs from 'dayjs';
import {
  IChargeHeadEntity,
  IContractCreateEntity,
} from 'types/entities/contract.entity';
import { IFeeEntity } from 'types/entities/fee.entity';
import {
  ContainerTypeEnum,
  IProposalEntity,
} from 'types/entities/proposal.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';

import { isAirTransportation } from 'utils/shipment-type';

const mapProposalItemsToContractCharges = (
  items: IFeeEntity[],
  containerType?: ContainerTypeEnum
) => {
  return items.map((item: IFeeEntity) => {
    return {
      description: item.description,
      cost: item.rate,
      currency: item.currency,
      containerType: containerType,
      measurementUnit: item.measurementUnit,
    } as IChargeHeadEntity;
  });
};
export const mapProposalToContract: (
  proposal: IProposalEntity,
  shipment: IShipmentEntity
) => Partial<IContractCreateEntity> = (proposal, shipment) => {
  return {
    carrierId: proposal.carrier?.id,
    contactId: proposal.assigneeId,
    startDate: dayjs().toISOString(),
    endDate: proposal.expirationDate,
    forwarder: proposal.company,
    reference: '',
    paymentTerms: proposal.paymentTerms,
    shipmentType: shipment.shipmentType,
    serviceMode: shipment.serviceMode,
    containerType: shipment.cargoUnits[0].containerType ?? undefined,
    costHeads: [
      {
        serviceMode: shipment.serviceMode ?? '',
        transitDays: proposal.transitTime,
        title: isAirTransportation(shipment.shipmentType.title)
          ? 'Air Freight'
          : 'Sea Freight',
        origin: {
          ...shipment.origin,
          id: undefined,
        },
        destination: {
          ...shipment.destination,
          id: undefined,
        },
        charges: mapProposalItemsToContractCharges(
          proposal.proposalItems,
          shipment.cargoUnits[0].containerType ?? undefined
        ),
      },
    ],
  };
};
