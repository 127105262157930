import { LockFilled } from '@ant-design/icons/lib/icons';
import { Space, message } from 'antd';
import { useAddCommentMutation } from 'api/proposal';
import { useAddCommentExternalPropsalMutation } from 'api/proposal-external.api';
import CommentBox from 'app/design-system/CommentBox';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IProposalCommentEntity } from 'types/entities/proposal.entity';
import { IMentionInput } from 'types/feature/messages.type';
import { IProposalCommentBoxProps } from 'types/feature/proposals.types';

import {
  commentSendEvent,
  sendProposalCommentEvent,
} from 'utils/analytics-events';
import { isProposalPrivate } from 'utils/proposal-helper';
import { isCargoOwner } from 'utils/shipment-helper';

const ProposalCommentBox: FC<IProposalCommentBoxProps> = ({
  proposal,
  isExternalForwarder,
  fitContent,
  currentUserRole,
}) => {
  const [commentState, setCommentState] = useState<IProposalCommentEntity[]>(
    proposal.proposalComments
  );
  const user = useSelector((state: RootState) => state.user.user);

  const [
    addComment,
    { data: respData, isLoading: isAddingComment, isError, isSuccess },
  ] = useAddCommentMutation();

  const [
    addExternalComment,
    { data: respExternalData, isSuccess: isExternalCommentSubmitted },
  ] = useAddCommentExternalPropsalMutation();

  const updateProposalCommentState = (comment: IProposalCommentEntity) => {
    setCommentState([
      ...commentState,
      {
        ...comment,
        createdBy: {
          ...comment.createdBy,
          ...(isExternalForwarder && {
            fullname: proposal?.assignee?.fullname,
          }),
        },
      },
    ]);
  };

  useEffect(() => {
    if (isSuccess && respData) {
      updateProposalCommentState(respData);

      sendProposalCommentEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        shipment_id: proposal.shipmentId,
        proposal_id: proposal.id,
        company_id: user?.companyId,
      });
    }
    // eslint-disable-next-line
  }, [respData, isSuccess]);

  useEffect(() => {
    setCommentState(proposal.proposalComments);
  }, [proposal]);

  useEffect(() => {
    if (isExternalCommentSubmitted && respExternalData) {
      updateProposalCommentState(respExternalData);
    }
    // eslint-disable-next-line
  }, [respExternalData, isExternalCommentSubmitted]);

  useEffect(() => {
    if (isError) message.warning("Your last comment hasn't been submitted !");
  }, [isError]);

  const onAddComment = async (message: IMentionInput) => {
    if (!message) return;
    commentSendEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
    });
    if (isExternalForwarder) {
      addExternalComment({
        comment: message.originalText,
        proposalId: proposal.id,
        email: proposal.assignee.email,
      });
    } else {
      addComment({
        comment: message.originalText,
        proposalId: proposal.id,
      });
    }
  };
  const renderWithCard = () => {
    return (
      <DashboardCard headerTitle="Comments" large className="clear-background">
        <CommentBox
          comments={commentState}
          onAddComment={onAddComment}
          isLoading={isAddingComment}
          isExternal={isExternalForwarder}
          userList={[]}
        />
      </DashboardCard>
    );
  };
  const renderWithoutCard = () => {
    return (
      <>
        {isProposalPrivate(proposal.status, currentUserRole) && (
          <div className="proposal-comments-header">
            <Space>
              <LockFilled />
              Chats before quote acceptance are private and are only visible to
              you and
              {isCargoOwner(currentUserRole) ? 'forwarder.' : 'cargo owner.'}
            </Space>
          </div>
        )}

        <CommentBox
          comments={commentState}
          fitContent={true}
          onAddComment={onAddComment}
          isLoading={isAddingComment}
          isExternal={isExternalForwarder}
          userList={[]}
        />
      </>
    );
  };
  return fitContent ? renderWithoutCard() : renderWithCard();
};

export default ProposalCommentBox;
