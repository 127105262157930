export enum SlugPrefixEnum {
  BK = 'BK',
  SH = 'SH',
  PO = 'PO',
  QUOTE = 'RFQ',
}

export const convertSlug = (slug: number, prefix: SlugPrefixEnum) => {
  const paddedValue = slug?.toString().padStart(4, '0');
  return `${prefix}-${paddedValue}`;
};

export const convertSlugToShipmentId = (slug?: number | string) => {
  if (!slug) return '';
  const paddedValue = slug.toString().padStart(5, '0');
  return `SH-${paddedValue}`;
};
