import { Spin } from 'antd';
import { useGetPropsalByIdQuery } from 'api/proposal';
import ProposalCommentBox from 'app/components/Proposal/ProposalCommentBox';
import React, { FC, useEffect } from 'react';
import { IProposalCommentsProps } from 'types/entities/message.entity';

import { displayErrors } from 'utils/error-notification';

const ProposalComments: FC<IProposalCommentsProps> = ({
  proposalId,
  currentUserRole,
}) => {
  const {
    data: proposal,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetPropsalByIdQuery(proposalId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error fetching proposals' });
    }
  }, [isError, error]);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="loading">
          <Spin />
        </div>
      ) : (
        proposal && (
          <ProposalCommentBox
            currentUserRole={currentUserRole}
            fitContent={true}
            proposal={proposal}
            isExternalForwarder={false}
          />
        )
      )}
    </>
  );
};

export default ProposalComments;
