import { Spin, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import './style.scss';

const ShipmentCreationMessages = [
  'Checking shipment info...',
  'Adding cargo details...',
  'Adding shipment types and dimensions...',
  'Setting origin and destination ...',
  'Adding additional infos ...',
];

const TypingMessage: FC<{
  message: string;
  typingSpeed: number;
  onDone: () => void;
}> = ({ message, typingSpeed, onDone }) => {
  const [typedMessage, setTypedMessage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      if (currentIndex < message.length) {
        setTypedMessage((prevTyped) => prevTyped + message[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        onDone();
        setCurrentIndex(0);
        setTypedMessage('');
      }
    }, typingSpeed);

    return () => clearTimeout(typingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, message, typingSpeed]);

  return (
    <div className="loading-streamer">
      <Spin spinning />
      <Typography.Text style={{ paddingTop: 20 }}>
        {typedMessage}
      </Typography.Text>
    </div>
  );
};
const ShipmentCreationPrompt: FC = () => {
  const [text, setText] = useState('Extracting data from text ...');
  const [idx, setIndex] = useState(0);

  const onDone = () => {
    setIndex((prevIdx) => (prevIdx + 1) % 5);
    setText(ShipmentCreationMessages[idx]);
  };
  return <TypingMessage message={text} typingSpeed={100} onDone={onDone} />;
};

export default ShipmentCreationPrompt;
