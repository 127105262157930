export enum ChatUserEnum {
  AI = 'ai',
  HUMAN = 'human',
}

export interface ChatHistory {
  text: string;
  user: ChatUserEnum;
  name?: string;
}

export interface ChatRequest {
  question: string;
  chatHistory?: ChatHistory[];
  companyID: string;
  userID: string;
}
