import { Button, Form, Input, Row, Typography, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useSendResetPasswordRequestMutation } from 'api/auth';
import React, { FC, useEffect } from 'react';
import { IForgotPasswordProps } from 'types/feature/auth.types';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as BeeboltLinedLogo } from '../../../../assets/appIcons/beebolt-icon.svg';
// style
import '../LoginForm/login-form.style.scss';

const ForgotPassword: FC<IForgotPasswordProps> = ({
  navigateToMainPage,
  onPasswordEmailSent,
}) => {
  const [form] = useForm<{ email: string }>();

  // through server
  const [sendEmailRequest, { isSuccess, isError, error, isLoading }] =
    useSendResetPasswordRequestMutation();

  const onSendEmailExternal = async () => {
    try {
      const values = await form.validateFields();
      await sendEmailRequest(values);
    } catch (error: unknown) {
      notification.warning({
        message: 'Error',
        description: 'make sure to fill all fields correctly !',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Reset Password Email Sent !',
        description:
          'A confirmation email has been sent to your inbox , please check and follow the steps to reset your password !',
      });
      onPasswordEmailSent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Reset Password Email Error !' });
    }
  }, [isError, error]);

  // component
  return (
    <div className="auth-form">
      <BeeboltLinedLogo />
      <Typography.Title level={2}>Reset My Password!</Typography.Title>
      <div className="items-wrapper">
        <Typography.Text>
          Please enter your email to receive a reset password code.
        </Typography.Text>
      </div>

      <Form layout="vertical" form={form}>
        <Form.Item
          label="Email"
          name="email"
          key="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email!',
            },
            {
              type: 'email',
              warningOnly: true,
              message: 'Please enter a valid email !',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <FormItem>
          <Button
            type="primary"
            className="auth-form__login-btn"
            onClick={onSendEmailExternal}
            disabled={isLoading}
            loading={isLoading}
          >
            Send confirmation email
          </Button>
        </FormItem>
      </Form>

      <Row align="middle" justify="center" className="auth-form__signup">
        <Typography.Text>Go back to login page ?</Typography.Text>
        <Button type="link" onClick={navigateToMainPage}>
          Log In
        </Button>
      </Row>
    </div>
  );
};

export default ForgotPassword;
