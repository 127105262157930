import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
} from 'react';
import {
  AuthContextType,
  IAuthContextProviderProps,
  AuthUserType,
} from 'types/feature/auth.types';

import { isAuthenticated } from './user-helper';

export const AuthContext = createContext<AuthContextType>(undefined);

export const AuthContextProvider: FC<IAuthContextProviderProps> = (props) => {
  const [user, setUser] = useState<AuthUserType>();

  // check if user is authenticated
  useEffect(() => {
    const checkAuthentication = async () => {
      const result = await isAuthenticated();
      setUser(result);
    };

    checkAuthentication();
  }, []);

  return <AuthContext.Provider value={{ user }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);

  return { ...auth, isAuthenticated: auth?.user != null };
};
