import { IShipmentEntity } from 'types/entities/shipment.entity';

export enum ShipmentCreationScreenEnum {
  OVERVIEW_SCREEN = 'overview-screen',
  AI_FORM_SCREEN = 'ai-form-screen',
  SHIPMENT_FORM_SCREEN = 'shipment-form-screen',
  INVITE_FORWARDER_SCREEN = 'invite-forwarder-screen',
  BIDDING_FORM_SCREEN = 'bidding-form-screen',
  CLOSE_MODAL = 'close-modal',
}

export enum ShipmentCreationFormTabEnum {
  CARGO_DETAILS_FORM = 'cargoDetailsForm',
  SHIPMENT_DETAILS_FORM = 'shipmentDetailsForm',
  ADDITIONAL_INFO = 'additionalInfoForm',
}

export interface ShipmentCreationOnboardingModalProps {
  onClose: () => void;
}

export interface IOverviewScreenProps {
  onScreenChange: (
    newScreen: ShipmentCreationScreenEnum,
    screenData?: Record<string, any>
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAIFormScreenProps extends IOverviewScreenProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IShipmentIFormScreenProps extends IOverviewScreenProps {
  defaultShipment: IShipmentEntity | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InviteForwarderScreenProps extends IOverviewScreenProps {}

export type THandleConfirmMethod = (props: {
  currentKey: string;
  nextKey?: string;
}) => void;
