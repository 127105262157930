import { Button, Space, Tooltip } from 'antd';
import TableDescriptionList from 'app/design-system/TableDescriptionList';
import React from 'react';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IInvoiceItemEntity } from 'types/entities/invoice.entity';
import { IRenderInvoiceMatchingTableColPropos } from 'types/feature/invoice.types';

import { exchangeRates } from 'utils/money-conversion';

import { ReactComponent as DoubleArrowIcon } from '../../../../assets/icons/double-arrow-icon.svg';
import {
  canRenderInvoiceComparisonIcon,
  computeCellBorder,
  computeEmptyCellBorder,
  computeEmptyCellNumberBorder,
  computeMoney,
  isInvoicelMatching,
  isProposalMatching,
} from './InvoiceMatchingTable.utils';

const renderComparisonIcon = (
  parsedConvertedCost: number | null,
  convertedCost: number | null
) => (
  <div>
    {parsedConvertedCost === convertedCost ? <>&#9989;</> : <>&#10060;</>}
  </div>
);

export const renderInvoiceMatchingTableCol: IRenderInvoiceMatchingTableColPropos =
  ({
    isItemDetailEditabale = false,
    onCopyActualCost,
    tableView,
    isTaxEnabled,
  }) => [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      editable: isItemDetailEditabale,
      fixed: 'left',
      render: (_, record) => (
        <div
          className={`${computeEmptyCellBorder(
            record.description
          )} description`}
        >
          {record.description}
        </div>
      ),
    },
    ...(isProposalMatching(tableView)
      ? []
      : [
          {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            editable: isItemDetailEditabale,
            width: '100px',
            render: (_, record) => (
              <div
                className={`${computeEmptyCellNumberBorder(
                  record.quantity
                )} quantity`}
              >
                {record.quantity}
              </div>
            ),
          },
          {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            editable: isItemDetailEditabale,
            width: '100px',
            render: (_, record) => (
              <div
                className={`${computeEmptyCellNumberBorder(record.rate)} rate`}
              >
                {record.rate}
              </div>
            ),
          },
          ...(isTaxEnabled
            ? [
                {
                  title: 'Tax',
                  dataIndex: 'tax',
                  key: 'tax',
                  editable: isItemDetailEditabale,
                  width: '100px',
                  render: (_, record) => (
                    <div
                      className={`${computeEmptyCellNumberBorder(
                        record.tax
                      )} tax`}
                    >
                      {record.tax}
                    </div>
                  ),
                },
              ]
            : []),
          {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            editable: isItemDetailEditabale,
            width: '80px',
            render: (_, record) => (
              <div
                className={`${computeEmptyCellBorder(
                  record.currency
                )} currency`}
              >
                {record.currency?.toUpperCase()}
              </div>
            ),
          },
        ]),
    {
      title: 'Invoice Amount',
      dataIndex: 'parsedTotalCost',
      key: 'parsedTotalCost',
      editable: true,
      render: (
        _,
        {
          costAndCurrency: {
            convertedCost,
            conversionRate,
            invoiceCurrency,
            invoiceItemCurrency,
            proposalItemCurrency,
            convertedParsedCost,
            parsedCost,
          },
          parsedTotalCost,
          totalCost,
        }
      ) => {
        const borderClass =
          parsedTotalCost === null || parsedTotalCost === undefined
            ? 'cell-red-border'
            : '';
        const actualValue = parsedTotalCost || 0;

        const { money, convertedMoney } = computeMoney(
          actualValue,
          convertedParsedCost,
          invoiceCurrency,
          invoiceItemCurrency
        );

        const canRenderComparisonIcon = canRenderInvoiceComparisonIcon(
          tableView,
          proposalItemCurrency,
          invoiceItemCurrency
        );

        return (
          <div className="actual-cost-wrapper">
            <TableDescriptionList
              label={<div className={`${borderClass}`}>{money}</div>}
              description={
                <Space>
                  {convertedMoney}
                  {canRenderComparisonIcon &&
                    convertedMoney &&
                    renderComparisonIcon(convertedParsedCost, convertedCost)}
                </Space>
              }
              isVertical={true}
              className="m-0"
            />
          </div>
        );
      },
    },
    ...(!isInvoicelMatching(tableView) && onCopyActualCost
      ? [
          {
            title: '',
            dataIndex: 'arrow',
            key: 'arrow',
            editable: false,
            render: (_, record, rowIndex) =>
              onCopyActualCost && (
                <div className="icon-wrapper">
                  <Tooltip title="Copy to right">
                    <Button
                      type="link"
                      icon={
                        <DoubleArrowIcon
                          className="arrow-icon"
                          onClick={() => onCopyActualCost(rowIndex)}
                        />
                      }
                    />
                  </Tooltip>
                </div>
              ),
          },
        ]
      : []),
    ...(!isInvoicelMatching(tableView)
      ? [
          {
            title: 'Quoted Amount',
            dataIndex: 'totalCost',
            key: 'totalCost',
            editable: true,
            render: (
              _,
              {
                costAndCurrency: {
                  convertedCost,
                  conversionRate,
                  invoiceCurrency,
                  invoiceItemCurrency,
                  proposalItemCurrency,
                  convertedParsedCost,
                  parsedCost,
                },
                parsedTotalCost,
                totalCost,
              }: IInvoiceItemEntity
            ) => {
              const actualValue = parsedTotalCost || 0;
              const expectedValue = totalCost || 0;
              let computedConvertedCost = convertedCost;

              // Conversion rate priority for Qouted Amount. proposalItemCurrency rate > AI parsed rate > FE constant
              if (parsedCost && convertedCost === null) {
                const computedConversionRate = proposalItemCurrency
                  ? exchangeRates[proposalItemCurrency]
                  : conversionRate ||
                    (invoiceCurrency && exchangeRates[invoiceCurrency]) ||
                    0;

                computedConvertedCost = expectedValue * computedConversionRate;
              }

              const { money, convertedMoney } = computeMoney(
                expectedValue,
                computedConvertedCost,
                invoiceCurrency,
                proposalItemCurrency ?? invoiceItemCurrency
              );

              const canRenderComparisonIcon = canRenderInvoiceComparisonIcon(
                tableView,
                proposalItemCurrency,
                invoiceItemCurrency
              );

              return (
                <TableDescriptionList
                  label={
                    <div
                      className={computeCellBorder(actualValue, expectedValue)}
                    >
                      {money}
                    </div>
                  }
                  description={
                    <Space>
                      {convertedMoney}
                      {canRenderComparisonIcon &&
                        convertedMoney &&
                        renderComparisonIcon(
                          convertedParsedCost,
                          computedConvertedCost
                        )}
                    </Space>
                  }
                  isVertical={true}
                  className="m-0"
                />
              );
            },
          },
        ]
      : []),
  ];

export const renderTotalAmount = (
  computedTotalAmount: number,
  invoiceParsedTotal: number,
  currency: CurrencyEnum | null
) => {
  const { money } = computeMoney(computedTotalAmount, 0, currency, null);

  return (
    <TableDescriptionList
      label={
        <span
          className={
            computedTotalAmount !== invoiceParsedTotal
              ? 'cell-red-filled'
              : 'cell-gray-filled'
          }
        >
          {money}
        </span>
      }
      description=""
      isVertical={true}
    />
  );
};
