import { Checkbox, ConfigProvider, Row } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { IQuoteStatus } from 'types/entities/quote.entity';

// style
import './progress.scss';

const computeProposalStatus = (currentStatus: string) => {
  const quoteStatus =
    currentStatus === IQuoteStatus.REVOKED
      ? Object.values(IQuoteStatus)
      : Object.values(IQuoteStatus).filter((status) => status !== 'revoked');

  const currentStatusIndex = quoteStatus.findIndex((s) => s === currentStatus);

  return quoteStatus.map((value: IQuoteStatus, index: number) => {
    const isAcceptedOrPending =
      index <= currentStatusIndex && !(currentStatusIndex === 3 && index === 2);
    return (
      <div
        key={value}
        className={`progress-indicator ${
          isAcceptedOrPending ? 'progress-indicator--activated' : ''
        } ${
          index <= currentStatusIndex && index === 3
            ? 'progress-indicator--declined'
            : ''
        }`}
      >
        <Checkbox key={value} checked={isAcceptedOrPending}>
          {value}
        </Checkbox>
      </div>
    );
  });
};

const QuotationProgress: FC<{ status: IQuoteStatus }> = ({ status }) => {
  return (
    <DashboardCard headerTitle="Quotation Progress" large>
      <Row className="progress-wrapper">
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: '#46B96B',
                colorPrimaryHover: '#059669',
              },
            },
          }}
        >
          {computeProposalStatus(status)}
        </ConfigProvider>
      </Row>
    </DashboardCard>
  );
};

export default QuotationProgress;
