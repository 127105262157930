import { useChecklistItem } from '@dopt/react-checklist/hooks';
import { Button, Select, SelectProps, Space, Tag, Typography } from 'antd';
import { useCreatShipmentsFromTrackingMutation } from 'api/container';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICheckTrackingNumbersProps } from 'types/entities/container.entity';
import { v4 as uuidv4 } from 'uuid';

import { displayErrors } from 'utils/error-notification';

const CheckTrackingNumbers: FC<ICheckTrackingNumbersProps> = ({
  onNextStep,
  onChangeInvalidTrackingNumbers,
  onShowExistingShipments,
  onShowCreatedShipments,
  onCloseModal,
  onInputText,
  textAreaValue,
}) => {
  const [showBanner, setShowBanner] = useState(0);
  const [inputValues, setInputValues] = useState<SelectProps['value']>([]);
  const navigate = useNavigate();
  const [createShipments, { data: shipmentsData, isLoading }] =
    useCreatShipmentsFromTrackingMutation();

  const trackShipmentChecklistItem = useChecklistItem(
    'announcement.import_shipment_screen'
  );

  const onSubmit = async () => {
    const trackingNumbers = inputValues
      .map((value) => value.label)
      .concat(textAreaValue ? [textAreaValue] : [])
      .reduce((acc, label) => {
        return acc.includes(label) ? acc : [...acc, label];
      }, []);
    if (textAreaValue) {
      setInputValues([
        ...inputValues,
        { label: textAreaValue, value: uuidv4(), color: 'blue' },
      ]);
    }

    onInputText('');

    try {
      await createShipments({
        trackingNumbers: trackingNumbers,
      }).unwrap();

      if (!trackShipmentChecklistItem.done) {
        trackShipmentChecklistItem.complete();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Validation Error' });
    }
  };

  const handleShipmentsData = () => {
    if (shipmentsData) {
      const createdShipments = shipmentsData.data.newShipments;
      const existingShipments = shipmentsData.data.existingShipments;
      const totalNumberOfShipments =
        createdShipments.length + existingShipments.length;
      if (
        totalNumberOfShipments === 1 &&
        shipmentsData.data.invalidTrackingNumbers.length < 1
      ) {
        // bypass second screen if only one tracking number is entered
        if (createdShipments.length > 0) {
          navigate(`/shipments/SH-${createdShipments[0].slug}`);
        } else {
          navigate(`/shipments/SH-${existingShipments[0].slug}`);
        }
        setInputValues([]);
        onInputText('');
        onCloseModal();
      } else if (totalNumberOfShipments > 0) {
        onChangeInvalidTrackingNumbers(
          shipmentsData.data.invalidTrackingNumbers
        );
        onNextStep();
        onShowExistingShipments(existingShipments);
        onShowCreatedShipments(createdShipments);
      } else {
        setShowBanner(inputValues.length);
        setInputValues((prevInputValues) =>
          prevInputValues.map((item) => ({ ...item, color: 'red' }))
        );
      }
    }
  };
  useEffect(() => {
    handleShipmentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentsData]);
  const tagRender: SelectProps['tagRender'] = (props) => {
    const { label, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const handleClose = () => {
      const index = inputValues.findIndex((item) => item.value === value);
      if (index !== -1) {
        const newInputValues = [
          ...inputValues.slice(0, index),
          ...inputValues.slice(index + 1),
        ];
        setInputValues(newInputValues);
      }
    };
    const item = inputValues.find((item) => item.value === value);
    const tagColor = item ? item.color : 'blue';
    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose();
        }}
        className="tracking-tag"
      >
        {label}
      </Tag>
    );
  };
  const handleSearch = (val: string) => {
    onInputText(val);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (textAreaValue) {
      if (event.key === 'Enter') {
        setInputValues([
          ...inputValues,
          {
            label: textAreaValue,
            value: uuidv4(),
            color: 'blue',
          },
        ]);
        onInputText('');
      }
    } else {
      if (event.key === 'Backspace') {
        setInputValues(inputValues?.slice(0, -1));
      }
    }
  };

  return (
    <>
      <ModalHeader
        title="Create a Shipment"
        subTitle="Enter your tracking numbers, and Beebolt will create shipments for each and allow you to track them all from one place."
      />
      <div className="tracking-input-content">
        <Space size="large" direction="vertical">
          <Select
            placeholder='Copy and paste tracking numbers, press "Enter" between each one.'
            autoFocus
            mode="multiple"
            tagRender={tagRender}
            className="select-input"
            value={inputValues}
            notFoundContent={null}
            searchValue={textAreaValue}
            onSearch={handleSearch}
            onKeyDown={handleKeyDown}
          />
        </Space>
        {showBanner > 0 && (
          <div className="banner-wrapper-invalid">
            <Typography.Text strong>
              {`We couldn't identify ${
                showBanner > 1
                  ? 'those tracking numbers.'
                  : 'that tracking number.'
              }  Kindly verify and try again`}
            </Typography.Text>
          </div>
        )}
        <Button
          disabled={inputValues.length < 1 && !textAreaValue}
          loading={isLoading}
          onClick={onSubmit}
          type="primary"
          className="my-2"
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default CheckTrackingNumbers;
