import { HTMLAttributes, ReactNode } from 'react';
import {
  CurrencyEnum,
  IFeeEntity,
  UnitMeasurementEnum,
} from 'types/entities/fee.entity';

import { capitalize, splitState } from 'utils/format-fields';

export const defaultColumns = [
  {
    title: 'Description',
    dataIndex: 'description',
    editable: true,
  },
  {
    title: 'Unit Measurement',
    dataIndex: 'unit',
    editable: true,
    render: (value: unknown, record: IFeeEntity, index: number) =>
      record.measurementUnit?.toUpperCase(),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    editable: true,
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    editable: true,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    editable: true,
    render: (value: unknown, record: IFeeEntity, index: number) =>
      record.currency?.toUpperCase(),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    editable: false,
  },
];

export interface IEditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string | undefined;
  inputType: 'number' | 'text';
  record: IFeeEntity;
  index: number;
  children: ReactNode;
}

export const currencyOptions = Object.values(CurrencyEnum).map(
  (curr: CurrencyEnum, index: number) => {
    return { value: curr, label: curr.toUpperCase() };
  }
);

export const formatUnitMeasurement = (state: UnitMeasurementEnum) => {
  switch (state) {
    case UnitMeasurementEnum.PERBL:
    case UnitMeasurementEnum.PERCONTAINER:
      return splitState(state);
    default:
      return state.toUpperCase();
  }
};

export const units = Object.values(UnitMeasurementEnum).map(
  (curr: UnitMeasurementEnum, index: number) => {
    return { value: curr, label: formatUnitMeasurement(curr) };
  }
);

export const computeDescriptionOptions = (descriptionData: string[]) => {
  return descriptionData.map((description) => ({
    value: description,
    label: capitalize(description),
  }));
};

export const computeTotalFees = (items: IFeeEntity[]) => {
  const groupedProposalItems: Map<string, number> = new Map();

  items.forEach((item) => {
    if (item.currency && item.quantity) {
      const currencyType = item.currency;
      if (!groupedProposalItems.has(currencyType)) {
        groupedProposalItems.set(currencyType, 0);
      }

      const pre = groupedProposalItems.get(currencyType);
      groupedProposalItems.set(
        currencyType,
        (pre ?? 0) + (item.quantity ?? 0) * (item.rate ?? 0)
      );
    }
  });

  let total = '';
  groupedProposalItems.forEach(
    (value, key) =>
      (total = total.concat(`${value?.toFixed(2)} ${key?.toUpperCase()} + `))
  );

  return total.slice(0, -3);
};
