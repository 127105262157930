import { IShipmentEntity } from 'types/entities/shipment.entity';

const useTrackingMap = () => {
  const groupByLocation = (shipments: IShipmentEntity[]) => {
    const grouped: { [key: string]: IShipmentEntity[] } = {};

    shipments.forEach((shipment) => {
      const { currentLocation } = shipment;
      const locationKey = `${currentLocation?.latitude},${currentLocation?.longitude}`;

      if (Object.prototype.hasOwnProperty.call(grouped, locationKey)) {
        grouped[locationKey].push(shipment);
      } else {
        grouped[locationKey] = [shipment];
      }
    });

    return grouped;
  };

  return {
    groupByLocation,
  };
};

export default useTrackingMap;
