import { Button, Typography } from 'antd';
import React, { FC } from 'react';
import {
  IPOOverviewScreenProps,
  PurchaseOrderCreationScreenEnum,
} from 'types/feature/purchase-orders-onboarding.types';

import { ReactComponent as POIcon } from '../../../../assets/iconsNew/po-icon.svg';

const OverviewScreen: FC<IPOOverviewScreenProps> = ({ onScreenChange }) => {
  const navigateToAiFormScreen = () =>
    onScreenChange(PurchaseOrderCreationScreenEnum.UPLOAD_SCREEN);

  return (
    <div className="sh-onboarding-overview-screen">
      <POIcon />
      <Typography.Title level={4} className="font-medium mt-0">
        Collaborative Purchase Orders
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        Beebolt allows you to collaborate directly with suppliers – say goodbye
        to email ping-pong, and keep one source of truth for your team.
      </Typography.Paragraph>
      <Button onClick={navigateToAiFormScreen} type="primary">
        Continue
      </Button>
    </div>
  );
};

export default OverviewScreen;
