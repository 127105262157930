import { Typography } from 'antd';
import DescriptionList from 'app/design-system/DescriptionList';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IProposalImportantDetailsProps } from 'types/feature/proposal.types';

import { dateFormat2Const } from 'utils/shipment-type';

const { Title } = Typography;

const ProposalImportantDetails: FC<IProposalImportantDetailsProps> = ({
  proposal,
  diffKeys,
}) => {
  return (
    <>
      <Title level={5} className="mx-0 my-1">
        Important Details
      </Title>
      <div className="proposal-important-details-wrapper slim-card-wrapper">
        <DescriptionList
          label="Carrier"
          description={proposal?.carrier?.name || ''}
          className={diffKeys && `${diffKeys['carrier']} text-value`}
        />
        <DescriptionList
          label="Payment Term"
          description={proposal.paymentTerms}
          className={diffKeys && `${diffKeys['paymentTerms']} text-value`}
        />
        <DescriptionList
          label="Free Days"
          description={proposal.freeDays}
          className={diffKeys && `${diffKeys['freeDays']} text-value`}
        />
        <DescriptionList
          label="Transit Time (Days)"
          description={proposal.transitTime}
          className={diffKeys && `${diffKeys['transitTime']} text-value`}
        />
        <DescriptionList
          label="Expiry Date"
          description={dayjs(proposal.expirationDate).format(dateFormat2Const)}
          className={diffKeys && `${diffKeys['expirationDate']} text-value`}
        />
        {proposal.proposalReference && (
          <DescriptionList
            label="Forwarder Reference"
            description={proposal.proposalReference}
            className={
              diffKeys && `${diffKeys['proposalReference']} text-value`
            }
          />
        )}
        {proposal.etd && (
          <DescriptionList
            label="Estimated Departure"
            description={dayjs(proposal.etd).format(dateFormat2Const)}
            className={diffKeys && `${diffKeys['etd']} text-value`}
          />
        )}
        {proposal.eta && (
          <DescriptionList
            label="Estimated Arrival"
            description={dayjs(proposal.eta).format(dateFormat2Const)}
            className={diffKeys && `${diffKeys['eta']} text-value`}
          />
        )}
        {proposal.vesselName && (
          <DescriptionList
            label="Vessel Name"
            description={proposal.vesselName}
            className={diffKeys && `${diffKeys['vesselName']} text-value`}
          />
        )}
        {proposal.routing && (
          <DescriptionList
            label="Routing"
            description={proposal.routing}
            className={diffKeys && `${diffKeys['routing']} text-value`}
          />
        )}
      </div>
    </>
  );
};

export default ProposalImportantDetails;
