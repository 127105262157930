import { BookFilled, ClockCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Skeleton, Table, Tag } from 'antd';
import {
  useGetActiveShipmentsQuery,
  useGetBookmarkedShipmentsQuery,
} from 'api/dashboard';
import DashboardCard from 'app/design-system/DashboardCard';
import ShipmentsMap from 'app/design-system/map';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IBookmarkedShipment } from 'types/entities/shipment.entity';
import { IBaseAnalyticsEvents } from 'types/feature/analytics-events.type';
import { ShipmentsCardProps } from 'types/feature/dashboard.types';

import {
  bookmarkedShipmentsClickEvent,
  mapClickEvent,
  viewAllShipmentsClickEvent,
} from 'utils/analytics-events';

import { shipmentColumnsConst } from './shipments-card.const';
import './shipments-card.scss';

const ShipmentsCard: FC<ShipmentsCardProps> = ({ navigateToShipmentsPage }) => {
  const {
    data: shipments,
    isLoading,
    error,
  } = useGetBookmarkedShipmentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const user = useSelector((state: RootState) => state.user.user);

  const { data: activeShipments } = useGetActiveShipmentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const changeDetails: IBaseAnalyticsEvents = {
    user_id: user?.id || '',
    email: user?.email || '',
    company: user?.company?.name || '',
    company_id: user?.companyId || '',
  };
  const onMapClick = () => {
    mapClickEvent(changeDetails);
  };

  const onViewAllShipments = () => {
    viewAllShipmentsClickEvent(changeDetails);
    navigateToShipmentsPage({});
  };

  const onRow = (record: IBookmarkedShipment, index: number | undefined) => {
    return {
      onClick: () => {
        bookmarkedShipmentsClickEvent(changeDetails);
        navigateToShipmentsPage({
          id: record.shipment.id,
          slug: record.shipment.slug,
          redirct: {
            tab: 'overview',
          },
        });
      },
    };
  };

  return (
    <>
      <div onClick={onMapClick}>
        <ShipmentsMap
          shipments={activeShipments ?? []}
          type="GLOBAL"
          polyline={null}
        />
      </div>

      <DashboardCard
        fitContent
        headerTitle="Shipments to watch"
        rightAccessory={
          <Button type="text" onClick={onViewAllShipments}>
            View all shipments
          </Button>
        }
      >
        {error && (
          <Alert showIcon message="Unexpected error while fetching shipments" />
        )}
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={[
              {
                key: 'bookmark',
                render: (_, record: IBookmarkedShipment, index: number) => {
                  return record.isBookmarked ? (
                    <Tag icon={<BookFilled />} color="gold" bordered={false}>
                      Bookmarked
                    </Tag>
                  ) : (
                    <Tag
                      icon={<ClockCircleOutlined />}
                      color="blue"
                      bordered={false}
                    >
                      Expected Soon
                    </Tag>
                  );
                },
              },
              ...shipmentColumnsConst,
              {
                key: 'action',
                render: (_, record: IBookmarkedShipment, index: number) => (
                  <Link
                    to={{
                      pathname: `/shipments/${record.shipment.slug}`,
                    }}
                    state={{ shipmentId: record.shipment.id }}
                  >
                    View Shipment
                  </Link>
                ),
              },
            ]}
            showHeader={false}
            dataSource={shipments ?? []}
            showSorterTooltip
            pagination={{ hideOnSinglePage: true }}
            size="middle"
            loading={isLoading}
            rowKey="id"
            onRow={onRow}
          />
        )}
      </DashboardCard>
    </>
  );
};

export default ShipmentsCard;
