import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Row,
  Space,
  Typography,
} from 'antd';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import { computeChargebaleWeight } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import DescriptionList from 'app/design-system/DescriptionList';
import React, { FC } from 'react';
import { IMatchContractsProps } from 'types/feature/contracts.type';

import { getTotalCost } from 'utils/contract';
import { formatContainerName, formatOriginToDest } from 'utils/format-fields';

import CoastHeadCollapse from '../CostHeadCollapse';
import { renderServiceMode } from '../CostHeadCollapse/cost-head.helper';
import '../contracts.style.scss';

const { Text } = Typography;

const MatchedContractsRate: FC<IMatchContractsProps> = ({
  contractsData,
  containerType,
  onBookClick,
  weight,
  volume,
}) => {
  const { items: contracts = [] } = contractsData ?? {};

  const renderItems: CollapseProps['items'] = contracts.map((record, index) => {
    const { costHeads, shipmentType } = record;
    return {
      key: record.id,
      label: (
        <Row align="middle">
          <Col span={14}>
            <Row justify="space-between">
              <Space direction="vertical">
                <Text className="carrier-text">{record.carrier?.name}</Text>
                <Text type="secondary">
                  Forwarder Company <Text strong>{record.forwarder.name}</Text>
                </Text>
              </Space>
              <Space>
                {displayFreighMethod(record.shipmentType.modality, true)}
              </Space>
            </Row>
            <Divider className="horiz-line" />
            <Row justify="space-between">
              <Text>
                {formatOriginToDest(
                  costHeads[0].destination,
                  costHeads[0].origin
                )}
              </Text>
              {record.serviceMode
                ? renderServiceMode(record.serviceMode)
                : '--'}
            </Row>
          </Col>
          <Divider type="vertical" className="vert-line" />
          <Col span={8}>
            <DescriptionList
              label="Transit Days"
              description={`${costHeads[0].transitDays} days`}
              isVertical={false}
            />
            {containerType && (
              <DescriptionList
                label="Container Type"
                description={formatContainerName(containerType)}
                isVertical={false}
              />
            )}
            {weight && volume && (
              <DescriptionList
                label="Chargeable Weight"
                description={`${computeChargebaleWeight(
                  weight,
                  volume,
                  record.shipmentType.title
                )} kg`}
                isVertical={false}
              />
            )}
          </Col>
          <Button
            type="primary"
            className="book-btn"
            onClick={() => onBookClick(record)}
          >
            <Text className="book-btn__text">$ {getTotalCost(costHeads)} </Text>
            <br />
            <Text className="book-btn__text">Book</Text>
          </Button>
        </Row>
      ),
      children: (
        <CoastHeadCollapse costHeads={costHeads} shipmentType={shipmentType} />
      ),
    };
  });

  return (
    <Collapse
      items={renderItems}
      expandIconPosition="end"
      bordered={false}
      className="contract-collapse"
    />
  );
};

export default MatchedContractsRate;
