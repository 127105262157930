import Icon from '@ant-design/icons';
import { Badge, Col, Row, Space, Tooltip } from 'antd';
import DeleteButton from 'app/components/ShipmentDetails/DeleteButton';
import ShipmentEditModal from 'app/components/ShipmentEdit';
import DescriptionList from 'app/design-system/DescriptionList';
import DescriptionTitle from 'app/design-system/DescriptionTitle';
import { disaplayForwarders } from 'app/design-system/DisplayConnections';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { UserRoleEnum, userProfileRoleEnum } from 'types/entities/user.entity';
import { IShipmentHeaderProps } from 'types/feature/shipments.types';

import { isViewer } from 'utils/collaborators';
import { removeZFromISO } from 'utils/common';
import { displayCountry } from 'utils/format-fields';
import {
  dateFormat2Const,
  dateFormatAirConst,
  isAirTransportation,
} from 'utils/shipment-type';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-icon.svg';
import { ReactComponent as BinIcon } from '../../../../assets/icons/bin-icon.svg';
import BookmarkButton from '../../BookmarkButton';
import DuplicateButton from '../DuplicateButton';
import ShipmentCollaborators from '../ShipmentCollaboratorsModal';
import ShipmentTags from '../ShipmentTags';
import TrackingByAi from '../TrackingByAi';
import './shipment-header.scss';

const ShipmentHeader: FC<IShipmentHeaderProps> = ({ shipment }) => {
  const shipmentType = shipment?.acceptedShipmentType ?? shipment?.shipmentType;

  const isCargoOwner = !!(
    shipment.currentUserRole === UserRoleEnum.CARGO_OWNER
  );

  const dateFormat =
    shipmentType && isAirTransportation(shipmentType.title)
      ? dateFormatAirConst
      : dateFormat2Const;

  const renderShipmentInfos = () => {
    return (
      <div className="shipment-info-wrapper">
        <div className="left-wrapper">
          <DescriptionTitle
            title={
              <>
                <Icon className="bin-icon" component={BinIcon} />
                {shipmentType?.title.toUpperCase()}
              </>
            }
            description={shipmentType?.modality.toUpperCase()}
          />
          <div className="shipment-location-wrapper">
            <DescriptionTitle
              title={
                <Tooltip title={displayCountry(shipment.origin)}>
                  {displayCountry(shipment.origin)}
                </Tooltip>
              }
              description={
                shipment.etd
                  ? dayjs(removeZFromISO(shipment.etd)).format(dateFormat)
                  : ''
              }
            />
            <Icon className="arrow-icon" component={ArrowIcon} />
            <DescriptionTitle
              title={
                <Tooltip title={displayCountry(shipment.destination)}>
                  {displayCountry(shipment.destination)}
                </Tooltip>
              }
              description={
                shipment.eta
                  ? dayjs(removeZFromISO(shipment.eta)).format(dateFormat)
                  : ''
              }
            />
          </div>
        </div>
        <div className="right-wrapper">
          {shipment?.forwarders?.length > 0 && (
            <DescriptionList
              label="Forwarders:"
              description={
                <div className="user-gravatar-wrapper">
                  {disaplayForwarders({
                    teamMemebers: shipment.forwarders,
                  })}
                </div>
              }
            />
          )}
          {shipment?.teams?.length > 0 && (
            <DescriptionList
              label="Team:"
              description={
                <div className="user-gravatar-wrapper">
                  {disaplayForwarders({ teamMemebers: shipment.teams })}
                </div>
              }
            />
          )}

          <div className="action-wrapper">
            <Space>
              <BookmarkButton
                shipmentId={shipment.id}
                isBookmarked={shipment.isBookmarked}
              />
              <ShipmentCollaborators
                shipmentId={shipment.id}
                currentUser={shipment.currentCollaborator}
              />

              <ShipmentEditModal shipment={shipment} />
              {isCargoOwner &&
                !isViewer(shipment.currentCollaborator.accessLevel) && (
                  <>
                    <DuplicateButton shipmentId={shipment.id} />
                    <DeleteButton
                      shipmentId={shipment.id}
                      isShipmentArchived={shipment.archived}
                    />
                  </>
                )}
            </Space>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Row className="shipment-header-wrapper">
        <Col span={24}>
          {shipment.archived ? (
            <Badge.Ribbon text="Archived" color="red">
              {renderShipmentInfos()}
            </Badge.Ribbon>
          ) : (
            renderShipmentInfos()
          )}
          <div className="shipment-tag-wrapper">
            <Col span={22}>
              <ShipmentTags shipment={shipment} />
            </Col>
            <Col span={2}>
              <TrackingByAi />
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ShipmentHeader;
