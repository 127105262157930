import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import LocationFilter from 'app/components/ShipmentFilters/LocationFilter';
import React, { FC } from 'react';
import { IPurchaseOrderLocationFilterProps } from 'types/feature/purchase-orders.types';

const PODestinationFilter: FC<IPurchaseOrderLocationFilterProps> = ({
  onFilterChange,
  selectedFilters,
  allShipmentsLocation,
}) => {
  return (
    <div>
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <LocationFilter
            layout="horizontal"
            defaultValue={selectedFilters?.destinations}
            locations={allShipmentsLocation?.destinations}
            onChange={(values) =>
              onFilterChange(values as string[], 'destinations')
            }
          />
        }
        buttonText={'Destinations'}
        itemKey={'destinations'}
      />
    </div>
  );
};

export default PODestinationFilter;
