import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from 'firebase/auth';
import { IUserEntity } from 'types/entities/user.entity';
import { IAuthToken } from 'types/feature/auth.types';

import { getRefreshToken } from 'utils/auth-storage';

import {
  AuthRequestBody,
  ResetPasswordRequest,
} from '../types/entities/auth.entity';
import { API_URL } from './common/config';
import { ApiResponse } from './common/response.type';
import { refreshTokens } from './helper/auth.helper';

// refresh tockens after social auth
export const onCredsUpdated = async () => {
  await refreshTokens();
};

// API
export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    loginSecure: builder.mutation<
      ApiResponse<{ user: IUserEntity; tokens: IAuthToken }>,
      AuthRequestBody
    >({
      query: (creds: AuthRequestBody) => ({
        method: 'POST',
        url: '/user/login',
        body: creds,
      }),
    }),
    refreshClientTokens: builder.query<
      ApiResponse<{ user: IUserEntity; tokens: IAuthToken }>,
      void
    >({
      query: () => ({
        method: 'GET',
        url: `/user/refresh-token?refreshToken=${getRefreshToken()}`,
      }),
    }),
    signup: builder.mutation<ApiResponse<IUserEntity>, AuthRequestBody>({
      query: (creds: AuthRequestBody) => ({
        method: 'POST',
        url: '/user/sign-up',
        body: creds,
      }),
    }),
    setUserOnLogin: builder.mutation<ApiResponse<IUserEntity>, User>({
      query: (creds: User) => ({
        method: 'POST',
        url: '/user/social-sign-in',
        body: creds,
      }),
    }),
    resetPassword: builder.mutation<ApiResponse<string>, ResetPasswordRequest>({
      query: (payload: ResetPasswordRequest) => ({
        url: '/user/reset-password',
        method: 'PATCH',
        body: payload,
      }),
    }),
    sendResetPasswordRequest: builder.mutation<
      ApiResponse<string>,
      { email: string }
    >({
      query: (payload) => ({
        url: '/user/reset-password-email',
        method: 'PATCH',
        body: payload,
      }),
    }),
    removeTempPassword: builder.mutation<
      ApiResponse<IUserEntity>,
      { email: string }
    >({
      query: (creds: { email: string }) => ({
        method: 'PATCH',
        url: '/user/reset-temporary-password',
        body: creds,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useSetUserOnLoginMutation,
  useRemoveTempPasswordMutation,
  useLoginSecureMutation,
  useResetPasswordMutation,
  useSendResetPasswordRequestMutation,
} = authApi;
