import {
  IMessageEntity,
  ISendMessageRequestBody,
  IGetMessages,
  IShipmentsMessageResponseBody,
} from 'types/entities/message.entity';

import { api } from './base-api';

export const messagesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllMessages: build.query<IMessageEntity[], void>({
      query: () => ({ url: `/shipment-message` }),
    }),

    getAllShipmentsMessages: build.query<
      IShipmentsMessageResponseBody,
      IGetMessages | null
    >({
      query: (params: IGetMessages) => ({
        url: '/shipment-message/list',
        params: params,
      }),
      providesTags: ['AllShipmentMessages'],
    }),

    UpdateMessage: build.mutation<IMessageEntity, string>({
      query: (shipmentId) => ({
        url: `/shipment-message/view/${shipmentId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['AllShipmentMessages'],
    }),

    getMessages: build.query<IMessageEntity[], string>({
      query: (shipmentId) => ({ url: `/shipment-message/${shipmentId}` }),
    }),

    sendMessage: build.mutation<IMessageEntity, ISendMessageRequestBody>({
      query: (body) => ({
        url: `/shipment-message`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetAllMessagesQuery,
  useGetMessagesQuery,
  useSendMessageMutation,
  useLazyGetAllShipmentsMessagesQuery,
  useUpdateMessageMutation,
} = messagesApi;
