import { Col, Row, Skeleton, Typography } from 'antd';
import DescriptionList from 'app/design-system/DescriptionList';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import {
  EditableDescriptiontInputEnum,
  IInvoiceDetailsProps,
} from 'types/feature/invoice.types';

import EditableDescription from './EditableDescription';

const { Title } = Typography;

const InvoiceDetails: FC<IInvoiceDetailsProps> = ({
  invoiceDetails,
  isEditabale,
  isLoading,
  onChange,
}) => {
  const onInvoiceDetailsChange = async (value, key) => {
    if (onChange) {
      onChange({
        [key]: value,
      });
    }
  };

  const renderSkeleton = () => (
    <Row
      align="middle"
      justify="space-between"
      className="invoice-details--row"
    >
      <Skeleton.Input active size="small" />

      <Skeleton.Input active size="small" />

      <Skeleton.Input active size="small" />

      <Skeleton.Input active size="small" />
    </Row>
  );

  return (
    <div className="invoice-details">
      <Title level={4}>Important Details</Title>
      {isLoading ? (
        renderSkeleton()
      ) : (
        <>
          <Row align="middle" className="invoice-details--row">
            <Col span={5}>
              <DescriptionList
                label="BL No:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.mbl}
                      inputType={EditableDescriptiontInputEnum.TEXT_INPUT}
                      onChange={(value) => onInvoiceDetailsChange(value, 'mbl')}
                    />
                  ) : (
                    invoiceDetails.mbl || 'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
            <Col span={7}>
              <DescriptionList
                label="Invoice Date:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.issueDate}
                      inputType={EditableDescriptiontInputEnum.DATE_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'issueDate')
                      }
                    />
                  ) : invoiceDetails.issueDate ? (
                    dayjs(invoiceDetails.issueDate).format('DD/MM/YYYY')
                  ) : (
                    'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
            <Col span={7}>
              <DescriptionList
                label="Invoice Due Date:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.dueDate}
                      inputType={EditableDescriptiontInputEnum.DATE_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'dueDate')
                      }
                    />
                  ) : invoiceDetails.dueDate ? (
                    dayjs(invoiceDetails.dueDate).format('DD/MM/YYYY')
                  ) : (
                    'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
            <Col span={5}>
              <DescriptionList
                label="Invoice Currency:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.currency as string}
                      inputType={EditableDescriptiontInputEnum.CURRENCY_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'currency')
                      }
                    />
                  ) : (
                    invoiceDetails.currency?.toUpperCase() || 'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
          </Row>
          <Row align="middle" className="invoice-details--row">
            <Col span={5}>
              <DescriptionList
                label="Invoice No:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.invoiceNo ?? ''}
                      inputType={EditableDescriptiontInputEnum.TEXT_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'invoiceNo')
                      }
                    />
                  ) : (
                    invoiceDetails.invoiceNo ?? 'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
            <Col span={7}>
              <DescriptionList
                label="Supplier VAT:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.supplierVatNo ?? ''}
                      inputType={EditableDescriptiontInputEnum.TEXT_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'supplierVatNo')
                      }
                    />
                  ) : (
                    invoiceDetails.supplierVatNo ?? 'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
            <Col span={7}>
              <DescriptionList
                label="Buyer VAT:"
                description={
                  isEditabale ? (
                    <EditableDescription
                      value={invoiceDetails.buyerVatNo ?? ''}
                      inputType={EditableDescriptiontInputEnum.TEXT_INPUT}
                      onChange={(value) =>
                        onInvoiceDetailsChange(value, 'buyerVatNo')
                      }
                    />
                  ) : (
                    invoiceDetails.buyerVatNo ?? 'N/A'
                  )
                }
                isVertical={true}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default InvoiceDetails;
