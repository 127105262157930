import { DatePicker, Input, Select, Typography } from 'antd';
import { currencyOptions } from 'app/components/Proposal/Fees/fees.const';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import {
  EditableDescriptiontInputEnum,
  IEditableDescriptiontProps,
} from 'types/feature/invoice.types';

const { Text } = Typography;

const EditableDescription: FC<IEditableDescriptiontProps> = ({
  value,
  inputType,
  onChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    onChange(inputValue);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDateChange = (newDate) => {
    setInputValue(newDate ? dayjs(newDate).toISOString() : newDate);
  };

  const handleCurrencyChange = (newCurrency) => {
    setInputValue(newCurrency);
  };

  const renderInputElements = () => {
    switch (inputType) {
      case EditableDescriptiontInputEnum.DATE_INPUT:
        return (
          <DatePicker
            defaultValue={dayjs(inputValue ?? new Date())}
            format="DD/MM/YYYY"
            onChange={handleDateChange}
            onBlur={handleInputBlur}
            autoFocus
            allowClear={true}
          />
        );

      case EditableDescriptiontInputEnum.TEXT_INPUT:
        return (
          <Input
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
          />
        );

      case EditableDescriptiontInputEnum.CURRENCY_INPUT:
        return (
          <Select
            value={inputValue}
            options={currencyOptions}
            onChange={handleCurrencyChange}
            onBlur={handleInputBlur}
            autoFocus
          />
        );
    }
  };

  const computeInputValue = () => {
    switch (inputType) {
      case EditableDescriptiontInputEnum.DATE_INPUT:
        return inputValue ? dayjs(inputValue).format('DD/MM/YYYY') : 'N/A';

      case EditableDescriptiontInputEnum.CURRENCY_INPUT:
      case EditableDescriptiontInputEnum.TEXT_INPUT:
        return inputValue?.toUpperCase() || 'N/A';

      default:
        return 'N/A';
    }
  };

  return (
    <div>
      {isEditing ? (
        renderInputElements()
      ) : (
        <Text onClick={handleTextClick}>{computeInputValue()}</Text>
      )}
    </div>
  );
};

export default EditableDescription;
