import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ProfileImage from 'app/components/ProfilePicture';
import React from 'react';
import { IInvoiceCollaboratorTableDef } from 'types/feature/invoice.types';

export const invoiceCollaboratorColumnsConst: ColumnsType<IInvoiceCollaboratorTableDef> =
  [
    {
      key: 'avatar',
      render: (value, record, index) => {
        return <ProfileImage name={record.fullname} photoUrl={record.photo} />;
      },
    },
    {
      key: 'name',
      render: (value, record, index) => {
        return <Typography.Text>{record.fullname}</Typography.Text>;
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      render: (value, record, index) => {
        return (
          <Typography.Text type="secondary">{record.email}</Typography.Text>
        );
      },
    },
  ];
