import {
  Row,
  Col,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import { currencyOptions } from 'app/components/Proposal/Fees/fees.const';
import React from 'react';
import { FC } from 'react';
import { IOrderLinesFormFieldsProps } from 'types/feature/purchase-orders.types';

import { ReactComponent as DeleteBinIcon } from '../../../../assets/iconsNew/bin-icon-outlined.svg';

const OrderLinesFormFields: FC<IOrderLinesFormFieldsProps> = ({
  title,
  field,
  fields,
  onRemoveField,
}) => (
  <div key={field.key}>
    <Row gutter={[12, 1]} align="middle" justify="space-between">
      <Col span={20}>
        <Typography.Paragraph strong>{title}</Typography.Paragraph>
      </Col>
      <Col span={1}>
        {fields.length > 1 ? (
          <Button
            icon={<DeleteBinIcon />}
            onClick={onRemoveField}
            danger
            type="default"
          />
        ) : null}
      </Col>
    </Row>
    <Row gutter={[12, 1]}>
      <Col span={6}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Ref n°"
          name={[field.name, 'reference']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: "Please input the order item's reference.",
            },
          ]}
        >
          <Input placeholder="ex. 00-23AZ" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="HS Code"
          name={[field.name, 'hsCode']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="ex. 45000xxx" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={[field.name, 'id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Description"
          name={[field.name, 'description']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
              message: "Please input the order item's description.",
            },
          ]}
        >
          <TextArea placeholder="ex. Shoes, Red, Lether" rows={1} />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Country of Origin"
          name={[field.name, 'coo']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="ex. India" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Boxes"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'boxes']}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputNumber
            placeholder="Enter boxes"
            className="custom-input"
            min={0}
            precision={0}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Quantity"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'requestedQuantity']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'Please input the quantity or delete this line.',
            },
            ({ getFieldValue, getFieldsValue }) => ({
              validator(rule, value) {
                if (
                  !value ||
                  (getFieldValue(['items', field.name, 'bookedQuantity']) ??
                    0) <= value
                ) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The booked quantity should be greater or equal to requested quantity ! Input a valid quantity ..'
                );
              },
            }),
          ]}
        >
          <InputNumber
            placeholder="Enter quantity"
            min={1}
            precision={0}
            type="number"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'bookedQuantity']}
          hidden
        >
          <InputNumber
            placeholder="Enter quantity"
            min={1}
            precision={0}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Price"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'unitPrice']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
              message: 'Please input the unit price or delete this line.',
            },
          ]}
        >
          <InputNumber
            placeholder="Enter price"
            className="custom-input"
            min={0.01}
            precision={2}
            type="number"
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          name={[field.name, 'currencyCode']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: false,
              whitespace: true,
              message: 'Please select currency or delete this line.',
            },
          ]}
          label="Currency"
        >
          <Select placeholder="Currency" options={currencyOptions}></Select>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="Total Volume"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'totalVolume']}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputNumber
            placeholder="Total Volume"
            min={0}
            precision={2}
            type="number"
            contentEditable={false}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Total Weight"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'totalWeight']}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputNumber
            placeholder="Total Weight"
            min={0}
            precision={2}
            type="number"
            contentEditable={false}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Total Price"
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'totalAmount']}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputNumber
            placeholder="Total price"
            min={0}
            precision={2}
            type="number"
            contentEditable={false}
          />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default OrderLinesFormFields;
