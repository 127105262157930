import DateFilter from 'app/components/ShipmentFilters/DateFilter';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React, { FC } from 'react';
import { IPurchaseOrderCreatedAtFilterProps } from 'types/feature/purchase-orders.types';

const CreatedDatesFilter: FC<IPurchaseOrderCreatedAtFilterProps> = ({
  onFilterChange,
  selectedFilters,
}) => {
  return (
    <div>
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <DateFilter
            layout="horizontal"
            defaultValue={selectedFilters?.createdDate}
            onChange={(values) => {
              onFilterChange(values as string[], 'createdDate');
            }}
          />
        }
        buttonText={'Created Date'}
        itemKey={'createdDate'}
      />
    </div>
  );
};

export default CreatedDatesFilter;
