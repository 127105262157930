import {
  IShipmentEntity,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';

export const dateFormatConst = 'DD-MM-YY';
export const dateFormat2Const = 'MMM DD, YYYY';
export const dateFormatAirConst = 'MMM DD, YYYY HH:mm';

export const isAirTransportation = (type: ShipmentTypeEnum) =>
  [ShipmentTypeEnum.AIR_CARGO, ShipmentTypeEnum.COURIER].includes(type);

export const isRoadTransportation = (type: ShipmentTypeEnum) =>
  [ShipmentTypeEnum.FTL, ShipmentTypeEnum.LTL].includes(type);

export const isSeaTransportation = (type: ShipmentTypeEnum) =>
  [ShipmentTypeEnum.FCL, ShipmentTypeEnum.LCL].includes(type);

export const isCourier = (type?: ShipmentTypeEnum) =>
  type !== undefined && ShipmentTypeEnum.COURIER === type;

export const isLTL = (type?: ShipmentTypeEnum) =>
  type !== undefined && ShipmentTypeEnum.LTL === type;

export const isTrackingActive = (shipment: IShipmentEntity) =>
  shipment.awb || shipment.mbl || shipment.trackingNumber;

export const computeShipmentTypeClassName = (type: ShipmentTypeEnum) =>
  type.replace(' ', '-');
