import { Button, DatePicker, Empty, Input, Typography } from 'antd';
import {
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
} from 'api/tasks';
import TeamSelect from 'app/components/TeamSelect';
import DashboardCard from 'app/design-system/DashboardCard';
import TaskStatusSwitch from 'app/design-system/TaskSwitch';
import dayjs from 'dayjs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ITaskEntity } from 'types/entities/task.entity';
import {
  IShipmentTasksItemProps,
  TShipmentTasksProps,
} from 'types/feature/shipments.types';

import {
  addtaskEvent,
  assignTaskEvent,
  editTaskDateEvent,
  editTaskStatusEvent,
} from 'utils/analytics-events';
import { flattenedEnumValue } from 'utils/common';
import { displayErrors } from 'utils/error-notification';

import './shipment-tasks.scss';

const { Text } = Typography;
const dateFormat = 'MMM DD';
const ShipmentTasksItem: FC<IShipmentTasksItemProps> = ({
  task,
  shipmentId,
}) => {
  const user = useSelector((state: RootState) => state.user.user);

  const [
    updateTask,
    { isLoading: isUpdating, isError: isUpdatingError, error: updateError },
  ] = useUpdateTaskMutation();

  useEffect(() => {
    if (isUpdatingError && updateError) {
      displayErrors(updateError, { title: 'Update Tasks Error' });
    }
  }, [isUpdatingError, updateError]);

  const onAssigneeChange = (assignee: string | null) => {
    updateTask({
      taskId: task.id,
      data: { assignee },
    });

    assignTaskEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      shipment_id: shipmentId,
      company_id: user?.companyId,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDateChange = (dueDate: any) => {
    updateTask({
      taskId: task.id,
      data: { dueDate },
    });

    editTaskDateEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      shipment_id: shipmentId,
      company_id: user?.companyId,
    });
  };

  const onStatusChange = (status: string) => {
    updateTask({
      taskId: task.id,
      data: { status },
    });

    editTaskStatusEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      shipment_id: shipmentId,
      previous_status: task.status,
      new_status: status,
      company_id: user?.companyId,
    });
  };

  return (
    <div className="task-item-wrapper">
      <div className="task-item-action-wrapper">
        <Text className="name-text m-0" ellipsis>
          {flattenedEnumValue(task?.name)}
        </Text>

        <div className="task-actions">
          <TaskStatusSwitch
            status={task?.status}
            updateStatus={onStatusChange}
            toUpdate
          />
          <DatePicker
            className="task-date-picker"
            disabled={isUpdating}
            onChange={onDateChange}
            suffixIcon={null}
            defaultValue={task.dueDate ? dayjs(task.dueDate) : undefined}
            clearIcon={false}
            format={dateFormat}
          />

          <TeamSelect
            onChange={onAssigneeChange}
            defaultValue={task?.assignee?.id}
            shipmentId={shipmentId}
            disabled={isUpdating}
          />
        </div>
      </div>
    </div>
  );
};

const ShipmentTasks: FC<TShipmentTasksProps> = ({ shipment, cardOverlay }) => {
  const user = useSelector((state: RootState) => state.user.user);
  const taskContainerRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const {
    data: taskData,
    isError: isGetTaskError,
    error: getTaskError,
  } = useGetTaskByIdQuery(shipment.id);
  const [
    createTask,
    { isLoading: isUpdating, isError: isCreateError, error: createTaskError },
  ] = useCreateTaskMutation();

  const [taskText, setTaskText] = useState<string>('');

  const scrollToBottom = () => {
    if (taskData && taskData.length > 0) {
      const lastItem = taskContainerRef.current?.getElementsByClassName(
        `task-item-${taskData.length - 1}`
      );
      if (lastItem && lastItem.length > 0) {
        lastItem[0].scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const onAddTask = async () => {
    const date = new Date();

    await createTask({
      shipmentId: shipment.id,
      data: {
        name: taskText,
        dueDate: date.toISOString(),
      },
    });

    setTaskText('');
    addtaskEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      shipment_id: shipment.id,
      company_id: user?.companyId,
    });
  };

  useEffect(() => {
    if (taskData && initialRender.current) {
      initialRender.current = false;
      return;
    }
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData]);

  useEffect(() => {
    if (isGetTaskError) {
      displayErrors(getTaskError, { title: 'Get Tasks Error' });
    }
  }, [isGetTaskError, getTaskError]);

  useEffect(() => {
    if (isCreateError) {
      displayErrors(createTaskError, { title: 'Create Task Error' });
    }
  }, [isCreateError, createTaskError]);

  const renderShipmentTask = () => (
    <>
      <div className="task-wrapper" ref={taskContainerRef}>
        {taskData && taskData.length > 0 ? (
          taskData.map((task: ITaskEntity, index: number) => (
            <div key={task.id} className={`task-item-${index}`}>
              <ShipmentTasksItem task={task} shipmentId={shipment.id} />
            </div>
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Tasks found for this shipment!"
          />
        )}
      </div>
      <div className="task-footer">
        <Input
          value={taskText}
          className="task-input"
          placeholder="Add New Task"
          onChange={(e) => setTaskText(e.target.value)}
        />
        <Button
          disabled={!taskText}
          type="default"
          onClick={onAddTask}
          loading={isUpdating}
        >
          Add
        </Button>
      </div>
    </>
  );

  return cardOverlay ? (
    <DashboardCard
      headerTitle="Tasks"
      fitContent
      noMargin
      className="overview-card-wrapper-2"
    >
      {renderShipmentTask()}
    </DashboardCard>
  ) : (
    renderShipmentTask()
  );
};

export default ShipmentTasks;
