import { Button, Empty, Row, Spin, message, notification } from 'antd';
import {
  useCreateBookingsMutation,
  useUpdateBookingItemsMutation,
  useUpdateBookingsMutation,
} from 'api/bookings';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { setCreatedBookingIds } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IDraftBookingEntity } from 'types/entities/booking.entity';
import { IDraftBookingsProps } from 'types/feature/purchase-orders.types';

import { purchaseOrderBookingCreateEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as EmptyOrders } from '../../../../assets/orders.svg';
import DraftBookingTable from './DraftBookingTable';
import {
  mapDraftBookings,
  mapDraftBookingsItems,
} from './draft-booking.helper';
import './draft-booking.scss';

const DraftBooking: FC<IDraftBookingsProps> = ({ goToNextStep, onCancel }) => {
  const { bookings, ids } = useSelector((state: RootState) => state.booking);
  const user = useSelector((state: RootState) => state.user.user);

  const [createBookings, { isLoading, isSuccess, data, isError, error }] =
    useCreateBookingsMutation();
  const [
    updateBookings,
    {
      isLoading: isUpdating,
      isSuccess: isUpdated,
      data: updatedData,
      isError: isUpdateError,
    },
  ] = useUpdateBookingsMutation();
  const [
    updateBookingItems,
    {
      isLoading: isUpdatingItems,
      isSuccess: isItemsUpdated,
      isError: isItemsUpdateError,
    },
  ] = useUpdateBookingItemsMutation();
  const dispatch = useAppDispatch();

  const onCreateClick = async (bookings: IDraftBookingEntity[]) => {
    if (!bookings || !bookings.length) {
      message.warning('You should add one purchse item at least !');
    }

    // map to request body
    const payload = mapDraftBookings(bookings);

    // request
    try {
      await createBookings(payload);

      purchaseOrderBookingCreateEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    } catch (error) {
      notification.error({
        message: 'Creation Error',
        description:
          'Something went wrong while creating bookings ! please retry ...',
      });
    }
  };

  const onUpdateClick = async (bookings: IDraftBookingEntity[]) => {
    if (!bookings || !bookings.length) {
      message.warning('You should add one purchse item at least !');
      return;
    }

    // map to request body
    const payload = { ...mapDraftBookings(bookings), bookingIds: ids ?? [] };
    const updatedItems = mapDraftBookingsItems(bookings);

    // request
    try {
      if (updatedItems.length)
        await updateBookingItems({ purchaseOrderItems: updatedItems });
      if (payload.purchaseOrders.length) await updateBookings(payload);
      else {
        goToNextStep();
      }
    } catch (error) {
      notification.error({
        message: 'Update Error',
        description:
          'Something went wrong while updating bookings ! please retry ...',
      });
    }
  };

  const onSuccess = useCallback(
    (ids: string[]) => {
      // set created ids
      dispatch(setCreatedBookingIds({ ids }));
      goToNextStep();
    },
    [goToNextStep, dispatch]
  );

  useEffect(() => {
    if (isSuccess) onSuccess(data?.data.bookingIds ?? []);
  }, [isSuccess, data, onSuccess]);

  useEffect(() => {
    if (isUpdated) onSuccess(updatedData?.data.bookingIds ?? []);
  }, [isUpdated, isItemsUpdated, updatedData, onSuccess]);

  useEffect(() => {
    if (isError && error)
      displayErrors(error, { title: 'Create Bookings Error' });
  }, [isError, error]);

  useEffect(() => {
    if (isUpdateError || isItemsUpdateError)
      notification.error({
        message: 'Update Error',
        description:
          'Something went wrong while updating bookings ! Make sure to insert valid values !',
      });
  }, [isItemsUpdateError, isUpdateError]);

  return (
    <div>
      <div className="main-section">
        {!bookings.length ? (
          <Empty
            style={{ marginTop: 50 }}
            image={<EmptyOrders />}
            description="Add items from purchase orders on left tab to book !"
          />
        ) : (
          bookings.map((booking) => (
            <DraftBookingTable key={booking.order} booking={booking} />
          ))
        )}
      </div>

      <Row justify="end" className="action-footer">
        <Button onClick={onCancel}>Cancel</Button>
        {ids?.length ? (
          <Button
            type="primary"
            onClick={() => onUpdateClick(bookings)}
            disabled={isUpdating || !bookings.length || isUpdatingItems}
            icon={isItemsUpdated || isUpdating ? <Spin size="small" /> : <></>}
          >
            Update Bookings
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => onCreateClick(bookings)}
            disabled={isLoading || !bookings.length}
            icon={isLoading ? <Spin size="small" /> : <></>}
          >
            Create Bookings
          </Button>
        )}
      </Row>
    </div>
  );
};

export default DraftBooking;
