import { Card, Empty, Space } from 'antd';
import FilePreview from 'app/design-system/FilePreview';
import React, { FunctionComponent } from 'react';
import { IFileEntity } from 'types/entities/proposal.entity';

export const displayShipmentFiles: FunctionComponent<{
  filesData: IFileEntity[];
  isLoading: boolean;
}> = ({ isLoading, filesData }) => {
  return (
    <div className="shipment-document-wrapper">
      <Card loading={isLoading}>
        <div>
          {filesData && filesData.length > 0 ? (
            <Space className="files-preview-wrapper">
              {filesData.map((file) => (
                <FilePreview
                  key={file.id}
                  imageUrl={file.file}
                  size="middle"
                  file={file}
                />
              ))}
            </Space>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Card>
    </div>
  );
};
