import { Input, Space, Table } from 'antd';
import { useGetUserCompanyQuery } from 'api/user';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useState } from 'react';
import { ITeamInfosProps } from 'types/entities/team.entity';
import { IUserEntity } from 'types/entities/user.entity';
import { PaginatorParams } from 'types/feature/pagination.types';

import AddTeamMember from './AddTeamMember';
import { columns } from './team.const';

const { Search } = Input;

const TeamInfos: FC<ITeamInfosProps> = ({ addTeamMember }) => {
  const [params, setParams] = useState<PaginatorParams>({
    page: 1,
    limit: 10,
  });
  const { data: users, isLoading } = useGetUserCompanyQuery(params);

  //methods

  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setParams({ ...params, keyword: undefined });
    }
  };
  return (
    <DashboardCard
      headerTitle="Team Information"
      rightAccessory={
        <Space>
          <Search
            placeholder="search"
            onSearch={(value: string) =>
              setParams({ ...params, keyword: value })
            }
            onChange={handleEmptySearch}
            allowClear
          />

          <AddTeamMember addTeamMember={addTeamMember} />
        </Space>
      }
    >
      <Table
        dataSource={users?.data.items}
        loading={isLoading}
        columns={columns}
        rowKey={(user: IUserEntity) => user.id}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: params.limit,
          pageSizeOptions: ['5', '10', '50'],
          total: users?.data?.meta?.totalItems,
          onChange: (page, pageSize) => {
            setParams({ page: page, limit: pageSize });
          },
        }}
      />
    </DashboardCard>
  );
};

export default TeamInfos;
