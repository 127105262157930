import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';
import { ITotalItemsState } from 'types/feature/purchase-orders.types';

export const computeTotalPrice = (items: IPurchaseOrderItemEntity[]) => {
  const groupedProposalItems: Map<string, number> = new Map();

  items.forEach((item) => {
    if (item.currencyCode && item.requestedQuantity) {
      const currencyType = item.currencyCode;
      if (!groupedProposalItems.has(currencyType)) {
        groupedProposalItems.set(currencyType, 0);
      }

      const pre = groupedProposalItems.get(currencyType);
      groupedProposalItems.set(
        currencyType,
        (pre ?? 0) + item.requestedQuantity * (item.unitPrice ?? 0)
      );
    }
  });

  let total = '';

  groupedProposalItems.forEach(
    (value, key) =>
      (total = total.concat(`${value?.toFixed(2)} ${key?.toUpperCase()} + `))
  );

  return total ? total.slice(0, -3) : '0';
};
export const computeTotals: (
  items: IPurchaseOrderItemEntity[]
) => ITotalItemsState = (items: IPurchaseOrderItemEntity[]) => {
  const total = items.reduce(
    (prev, curr) => {
      prev.weight = (prev.weight ?? 0) + (curr.totalWeight ?? 0);
      prev.volume = (prev.volume ?? 0) + (curr.totalVolume ?? 0);
      prev.requestedQuantity =
        prev.requestedQuantity + (curr.requestedQuantity ?? 0);
      prev.boxes = prev.boxes + (curr.boxes ?? 0);
      return prev;
    },
    { price: 0, weight: 0, volume: 0, requestedQuantity: 0, boxes: 0 }
  );

  const price = computeTotalPrice(items);

  return {
    ...total,
    price: price,
    quantity: total.requestedQuantity,
    weight: Number(total.weight?.toFixed(2)),
    volume: Number(total.volume?.toFixed(2)),
  };
};
