import {
  IGetInvoiceCount,
  IGetInvoiceDashBoardResponse,
  IGetOnHoldInvoicesResponse,
  IGetPendingInvoicesResponse,
} from 'types/entities/invoice.entity';
import { SorterParams } from 'types/feature/pagination.types';

import { api } from './base-api';

export const invoiceAnalyticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceCounts: build.query<IGetInvoiceCount, void>({
      query: () => ({
        url: `/invoice-analytics/invoice-counts`,
        method: 'GET',
      }),

      providesTags: ['InvoiceCount'],
    }),
    getPendingReviewsInvoices: build.query<
      IGetPendingInvoicesResponse,
      SorterParams
    >({
      query: (params) => ({
        url: `/invoice-analytics/pending-review`,
        method: 'GET',
        params: { ...params },
      }),

      providesTags: ['InvoicePendingReview'],
    }),
    getOnHoldInvoices: build.query<IGetOnHoldInvoicesResponse, SorterParams>({
      query: (params) => ({
        url: `/invoice-analytics/on-hold`,
        method: 'GET',
        params: { ...params },
      }),

      providesTags: ['invoiceOnHold'],
    }),
    getPendingApprovalInvoices: build.query<
      IGetOnHoldInvoicesResponse,
      SorterParams
    >({
      query: (params) => ({
        url: `/invoice-analytics/pending-approval`,
        method: 'GET',
        params: { ...params },
      }),

      providesTags: ['InvoicePendingApproval'],
    }),
    getInvoiceDashboard: build.query<IGetInvoiceDashBoardResponse, void>({
      query: () => ({
        url: `/invoice-analytics/dashboard`,
        method: 'GET',
      }),

      providesTags: ['InvoiceDashboard'],
    }),
  }),
});

export const {
  useGetInvoiceDashboardQuery,
  useGetInvoiceCountsQuery,
  useGetOnHoldInvoicesQuery,
  useGetPendingApprovalInvoicesQuery,
  useGetPendingReviewsInvoicesQuery,
} = invoiceAnalyticsApi;
