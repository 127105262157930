import { DatePicker, DatePickerProps, Form } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayJs from 'dayjs';
import React, { FC, useEffect } from 'react';
import {
  IDateFilterForm,
  IDateFilterProps,
} from 'types/feature/shipments.types';

import '../shipment-filter.scss';

const { RangePicker } = DatePicker;
const dateFormatString = 'YYYY-MM-DD';

const DateFilter: FC<IDateFilterProps> = ({
  onChange,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IDateFilterForm>();

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        dateFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onRangePickerChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string]
  ) => {
    if (dateString[0]) {
      onChange(dateString);
    } else {
      onChange([]);
    }
  };

  return (
    <div className="date-filter-form-wrapper">
      <RangePicker
        className="date-range-input"
        showTime={false}
        format={(date) => date.format(dateFormatString)}
        onChange={onRangePickerChange}
        allowClear={true}
        {...(defaultValue && {
          defaultValue: [
            dayJs(defaultValue[0], dateFormatString),
            dayJs(defaultValue[1], dateFormatString),
          ],
        })}
      />
    </div>
  );
};

export default DateFilter;
