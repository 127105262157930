import { Row, Skeleton } from 'antd';
import { useGetResumeShipmentsQuery } from 'api/dashboard';
import IndicatorWrapper from 'app/design-system/Indicator';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IIndicatorEntity } from 'types/entities/indicator.entity';
import { ShipmentsCardProps } from 'types/feature/dashboard.types';

import { shipmentStatusClickEvent } from 'utils/analytics-events';
import { convertToStatusValue } from 'utils/shipment-helper';

import './indicators.scss';

const Indicators: FC<ShipmentsCardProps> = ({ navigateToShipmentsPage }) => {
  const user = useSelector((state: RootState) => state.user.user);
  const { data: indicators, isLoading } = useGetResumeShipmentsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const onStatusClick = (status) => {
    shipmentStatusClickEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company.name,
      company_id: user?.companyId,
      status,
    });
    navigateToShipmentsPage({
      status: convertToStatusValue(status),
    });
  };
  return (
    <Row className="dashboard-indicators">
      {isLoading ? (
        <Skeleton active paragraph />
      ) : (
        indicators?.map((value: IIndicatorEntity) => (
          <IndicatorWrapper
            key={value.status}
            data={value}
            onClick={() => onStatusClick(value.status)}
          />
        ))
      )}
    </Row>
  );
};

export default Indicators;
