import Icon from '@ant-design/icons';
import { Button, notification } from 'antd';
import { onCredsUpdated, useSetUserOnLoginMutation } from 'api/auth';
import { loginWithSocialProviders } from 'api/auth.firebase';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect, useState } from 'react';
import { IBaseAnalyticsContext } from 'types/feature/analytics-events.type';
import {
  AuthProviderEnum,
  AuthTypeEnum,
  SocialAuthButtonsProps,
} from 'types/feature/auth.types';

import { signupCompleteEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as GoogleLogo } from '../../../../assets/google-logo.svg';
import { ReactComponent as MSLogo } from '../../../../assets/ms-logo.svg';
// style
import '../LoginForm/login-form.style.scss';

const SocialAuthButtons: FC<SocialAuthButtonsProps> = ({
  navigateToMainPage,
  mode,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [setAuths, { error, isError }] = useSetUserOnLoginMutation();
  const campaign = useUTMState();

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Authentication Error' });
    }
  }, [isError, error]);

  const onLoginWithGoogle = async (provider: AuthProviderEnum) => {
    setIsLoading(true);

    try {
      const response = await loginWithSocialProviders(provider);

      if (!response.isSuccess) {
        throw new Error(response.errorMessage, {
          cause: response.errorMessage,
        });
      }

      if (response.user) {
        // TODO later only refresh token if it is newely created user
        const resp = await setAuths(response.user).unwrap();
        if (resp && resp?.data && mode === AuthTypeEnum.SIGNUP) {
          const {
            userId = '',
            company,
            email = '',
            fullname = '',
            mobileNumber = '',
          } = resp.data;
          const context: IBaseAnalyticsContext = {
            campaign,
          };

          signupCompleteEvent(
            {
              user_id: userId,
              email: email,
              company: company?.name || '',
              company_id: company?.id || '',
              first_name: fullname,
              last_name: '',
              phone_number: mobileNumber || '',
              type: provider,
            },
            context
          );
        }
        await onCredsUpdated();
        if (mode === AuthTypeEnum.SIGNUP)
          notification.success({
            message: 'Authentication succeded !',
            description:
              'You account is created, you can now navigate in the app !',
          });
        navigateToMainPage();
      }
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setIsLoading(false);

      if (err.message !== 'canceled') {
        notification.error({
          message: 'Authentication failed',
          description:
            err.message ?? 'Unknown issue has occured, Please try again !',
        });
      } else {
        notification.info({
          message: 'Authentication cancled by th user',
        });
      }
    }
  };

  // component
  return (
    <div>
      <Button
        icon={<Icon component={GoogleLogo} />}
        className="auth-form__login-btn-external"
        onClick={() => onLoginWithGoogle(AuthProviderEnum.GOOGLE)}
        disabled={isLoading}
      >
        {mode} with Google
      </Button>
      <Button
        icon={<Icon component={MSLogo} />}
        className="auth-form__login-btn-external auth-form__sign-btn"
        onClick={() => onLoginWithGoogle(AuthProviderEnum.MS)}
        disabled={isLoading}
      >
        {mode} with Microsoft
      </Button>
    </div>
  );
};

export default SocialAuthButtons;
