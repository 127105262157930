import {
  Button,
  Divider,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
  Input,
  Tooltip,
} from 'antd';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from 'antd/es/table/interface';
import {
  useGetAllShipmentsLocationQuery,
  useLazyGetShipmentProposalsQuery,
} from 'api/shipment';
import BiddingCounter from 'app/components/Bidding/BiddingCounter';
import { forwarderRequestedAndSubmittedQuoteCount } from 'app/components/Proposal/helpers/helper';
import DateFilter from 'app/components/ShipmentFilters/DateFilter';
import FilterDrawer from 'app/components/ShipmentFilters/FilterDrawer';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import LocationFilter from 'app/components/ShipmentFilters/LocationFilter';
import ShipmentTypeFilter from 'app/components/ShipmentFilters/ShipmentTypeFilter';
import StatusFilter from 'app/components/ShipmentFilters/StatusFilter';
import ShipmentPreview from 'app/components/ShipmentPreview';
import ProposalDetailsModal from 'app/components/ShipmentQuotes/ProposalDetailsModal';
import {
  defaultPaginationAndSorting,
  removeEmptyArrays,
  selectLowestCostQuoteOfShipment,
} from 'app/components/Shipments/helpers/helper';
import ShipmentTagsDisplay from 'app/design-system/ShipmentTags';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { SearchAndSortParams } from 'types/feature/pagination.types';
import { IShipmentsFiltersState } from 'types/feature/shipments.types';

import { previewShipmentCtaClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import {
  formatBids,
  formatOriginToDestDashBoard,
  formatShipmentState,
} from 'utils/format-fields';
import { checkObjectNotEmpty } from 'utils/object.helpers';
import { isCargoOwner, isForwarder } from 'utils/shipment-helper';

import './pending-quotes.scss';

const { Search } = Input;

const ShipmentPendingQuotes: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state: RootState) => state.user.user);
  const company = useSelector((state: RootState) => state.user.user?.company);

  const [showProposalDetailsModal, setShowProposalDetailsModal] =
    useState(false);
  const [selectedProposal, setSelectedProposal] = useState<{
    proposal: IProposalEntity | null;
    shipment: IShipmentEntity;
  } | null>();

  const [paginationAndSortingState, setPaginationAndSortingState] =
    useState<SearchAndSortParams>(defaultPaginationAndSorting);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [shipmentSearch, setShipmentSearch] = useState<IShipmentsFiltersState>({
    ...(location.state?.status && { statuses: [location.state?.status] }),
  });

  const [shipmentInPreview, setShipmentInPreview] = useState<string | null>(
    null
  );

  const [getShipmentProposals, { data: shipmentProposals, isLoading }] =
    useLazyGetShipmentProposalsQuery();
  const { data: allShipmentsLocation } = useGetAllShipmentsLocationQuery();

  const handleOnSearch = (value: string) => {
    if (value) setSearchTerm(value);
  };

  const onShipmentProposalSearch = async () => {
    try {
      await getShipmentProposals({
        page: paginationAndSortingState.page,
        limit: paginationAndSortingState.limit,
        sortOrder: paginationAndSortingState.sortOrder?.toLocaleUpperCase(),
        keyword: searchTerm,
        ...removeEmptyArrays(shipmentSearch),
      }).unwrap();
      //eslint-disable-next-line
    } catch (e: any) {
      displayErrors(e);
    }
  };

  const onShipmentOverview = ({
    slug,
    currentUserRole,
    proposalId,
  }: IShipmentEntity) => {
    if (!isForwarder(currentUserRole)) {
      window.open(`/shipments/${slug}`, '_blank');
    } else {
      navigate(`/proposals/${proposalId}?email=${user?.email}`);
    }
  };
  const onDetailProposalClose = async () => {
    setSelectedProposal(null);
    setShowProposalDetailsModal(false);
    // refetch proposals on accept or reject
    await onShipmentProposalSearch();
  };

  const onShipmentPreviewClose = async () => {
    setShipmentInPreview(null);

    await onShipmentProposalSearch();
  };

  const onPreviewShipment = (shipment: IShipmentEntity) => {
    if (user) {
      previewShipmentCtaClickEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }

    setShipmentInPreview(shipment.slug);
  };

  const shipmentProposalRoute = (id: string, email: string) => {
    navigate(`/proposals/${id}?email=${email}`);
  };

  useEffect(() => {
    onShipmentProposalSearch();
  }, [shipmentSearch, searchTerm]);

  useEffect(() => {
    if (shipmentProposals) {
      onShipmentProposalSearch();
    }
  }, [paginationAndSortingState]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IShipmentEntity> | SorterResult<IShipmentEntity>[]
  ) => {
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;

    setPaginationAndSortingState({
      page: pagination.current ?? 1,
      limit: pagination.pageSize ?? 10,
      sortOrder: singleSorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onRow = (record: IShipmentEntity, index: number | undefined) => {
    return {
      onClick: () => {
        if (onShipmentOverview) onShipmentOverview(record);
      },
    };
  };

  const handleOpenModal = (record: IShipmentEntity) => {
    setSelectedProposal(selectLowestCostQuoteOfShipment(record));
    setShowProposalDetailsModal(true);
  };

  const handleDisableViewQuotationButton = (record: IShipmentEntity) => {
    const shipment = selectLowestCostQuoteOfShipment(record);
    const isProposalReturned = !!shipment?.proposal;

    return !(
      !!record.highestCost &&
      !!record.lowestCost &&
      isCargoOwner(record.currentUserRole) &&
      isProposalReturned
    );
  };

  const columns: ColumnsType<IShipmentEntity> = [
    {
      title: 'Shipment ID',
      dataIndex: 'slug',
      key: 'slug',
      sorter: true,
      className: 'shipment-id-cell',
      fixed: 'left',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, record: IShipmentEntity) => {
        return (
          <Space direction="vertical" align="center">
            <>
              <Space>
                {!isForwarder(record.currentUserRole) ? (
                  <Link
                    to={`/shipments/${record?.slug}`}
                    state={{ shipmentId: record?.shipmentAccess?.targetId }}
                  >
                    {record?.slug}
                  </Link>
                ) : (
                  <Typography.Link
                    onClick={() => {
                      shipmentProposalRoute(
                        record?.proposalId as string,
                        user?.email as string
                      );
                    }}
                  >
                    {record?.slug}
                  </Typography.Link>
                )}
                <Button
                  className="preview-btn"
                  onClick={() => {
                    if (onPreviewShipment) onPreviewShipment(record);
                  }}
                  size="small"
                >
                  Preview
                </Button>
              </Space>
              <Typography.Text strong>
                {record?.shipmentReference}
              </Typography.Text>
            </>

            <ShipmentTagsDisplay tags={record.shipmentMetadata} maxNumber={3} />
          </Space>
        );
      },
      align: 'center',
    },
    {
      title: 'Origin / Destination',
      key: 'fromTo',
      render: (value, record, index) => {
        return formatOriginToDestDashBoard(record);
      },
      align: 'center',
    },
    {
      title: 'Amount',
      key: 'bids',
      render: (value, record, index) => {
        const isNotForwarder = !isForwarder(record.currentUserRole);
        return isNotForwarder ? formatBids(record) : '';
      },
      align: 'center',
    },
    {
      title: 'Bidding Deadlines',
      key: 'deadline',
      render: (value, record, index) => {
        const isUserForwarder = isForwarder(record.currentUserRole);

        const forwarderQuotes = isUserForwarder
          ? forwarderRequestedAndSubmittedQuoteCount(
              record.proposals as IProposalEntity[],
              user?.companyId as string
            )
          : null;

        return (
          <div className="pending-quotes-bidding-count">
            {record.dueDate && (
              <BiddingCounter
                shipmentParams={{
                  id: record.id,
                  slug: record.slug,
                }}
                deadline={record.dueDate}
                showDropDownActions={!isUserForwarder}
                trigger="click"
              />
            )}
            {isUserForwarder ? (
              <Typography.Paragraph className="quoted-text">{`${forwarderQuotes?.submittedQuotes} / ${forwarderQuotes?.requestedQuotes} quoted`}</Typography.Paragraph>
            ) : (
              <Typography.Paragraph className="quoted-text">{`${record.submittedQuotes} / ${record.totalQuotes} quoted`}</Typography.Paragraph>
            )}
          </div>
        );
      },
      align: 'center',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (value, record, index) => {
        const text = record.shipmentReference
          ? `${record.slug}`
          : `${record.slug}`;
        return (
          <Space
            className="shipment-details"
            direction="vertical"
            align="center"
            size={2}
          >
            <div className="status">{formatShipmentState(record.status)}</div>
            <div className="shipment-text">
              <Typography.Paragraph className="shipment-text--slug">
                {text}
              </Typography.Paragraph>
              <Tooltip
                placement="top"
                title={record.shipmentReference ? record.shipmentReference : ''}
              >
                <Typography.Paragraph className="shipment-text--slug">
                  {record.shipmentReference ? record.shipmentReference : ''}
                </Typography.Paragraph>
              </Tooltip>
            </div>
          </Space>
        );
      },
      align: 'center',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '200px',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
      render: (_: string, record: IShipmentEntity, index: number) => {
        return (
          <Space>
            {!isForwarder(record.currentUserRole) ? (
              <>
                <Button
                  key={`view-${index}`}
                  onClick={() => {
                    if (onPreviewShipment) onPreviewShipment(record);
                  }}
                >
                  View Quotations
                </Button>

                <Button
                  type="primary"
                  key={`accept-${index}`}
                  disabled={handleDisableViewQuotationButton(record)}
                  onClick={() => handleOpenModal(record)}
                >
                  Accept Lowest
                </Button>
              </>
            ) : (
              <Button
                key={`view-${index}`}
                onClick={() => {
                  shipmentProposalRoute(
                    record?.proposalId as string,
                    user?.email as string
                  );
                }}
              >
                View Quotation
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const handleInputOnChange = (value: string) => {
    if (!value) {
      setSearchTerm(undefined);
      return;
    }
  };

  const onFilterDrawerChange = (value) => {
    setShipmentSearch(value);
  };

  const onResetClick = () => {
    setShipmentSearch({
      destinations: [],
      origins: [],
      date: [],
      statuses: [],
      modalities: [],
      shipmentType: [],
    });
  };

  return (
    <>
      <div className="filter-wrapper">
        {/* Can we move filters into separate component */}
        <div>
          <Typography.Text>
            Filters <Divider type="vertical" />
          </Typography.Text>
          <Space>
            <FilterDropdown
              filters={shipmentSearch}
              renderFilterComponent={
                <StatusFilter
                  layout="vertical"
                  defaultValue={shipmentSearch?.statuses}
                  fromPendingQuotes
                  onChange={(statuses) =>
                    setShipmentSearch({
                      ...shipmentSearch,
                      statuses,
                    })
                  }
                />
              }
              buttonText="Status"
              itemKey="statuses"
            />
            <FilterDropdown
              filters={shipmentSearch}
              renderFilterComponent={
                <DateFilter
                  layout="vertical"
                  defaultValue={shipmentSearch?.date}
                  onChange={(dateFilter) =>
                    setShipmentSearch({
                      ...shipmentSearch,
                      date: dateFilter,
                    })
                  }
                />
              }
              buttonText="Date"
              itemKey="date"
            />
            <FilterDropdown
              filters={shipmentSearch}
              renderFilterComponent={
                <ShipmentTypeFilter
                  layout="vertical"
                  defaultValue={shipmentSearch?.shipmentType}
                  onChange={(shipmentType) =>
                    setShipmentSearch({
                      ...shipmentSearch,
                      shipmentType,
                    })
                  }
                />
              }
              buttonText="Freight Type"
              itemKey="shipmentType"
            />
            {allShipmentsLocation && (
              <FilterDropdown
                filters={shipmentSearch}
                renderFilterComponent={
                  <LocationFilter
                    layout="horizontal"
                    defaultValue={shipmentSearch?.origins}
                    locations={allShipmentsLocation?.origins}
                    onChange={(origins) =>
                      setShipmentSearch({
                        ...shipmentSearch,
                        origins,
                      })
                    }
                  />
                }
                buttonText="Origins"
                itemKey="origins"
              />
            )}
            {allShipmentsLocation && (
              <FilterDropdown
                filters={shipmentSearch}
                renderFilterComponent={
                  <LocationFilter
                    layout="vertical"
                    defaultValue={shipmentSearch?.destinations}
                    locations={allShipmentsLocation?.destinations}
                    onChange={(destinations) =>
                      setShipmentSearch({
                        ...shipmentSearch,
                        destinations,
                      })
                    }
                  />
                }
                buttonText="Destination"
                itemKey="destinations"
              />
            )}
            <FilterDrawer
              defaultValue={shipmentSearch}
              onChange={onFilterDrawerChange}
              onReset={onResetClick}
              fromPendingQuotes
            />
            {checkObjectNotEmpty(removeEmptyArrays(shipmentSearch)) && (
              <Button type="link" block onClick={onResetClick}>
                Clear All
              </Button>
            )}
          </Space>
        </div>
        <Space>
          <Search
            placeholder="search"
            allowClear
            onChange={(event) => handleInputOnChange(event.target.value)}
            onSearch={handleOnSearch}
          />
        </Space>
      </div>

      <Table
        loading={isLoading}
        className="table-wrapper"
        rowKey={(record: IShipmentEntity) => record?.id}
        columns={columns}
        dataSource={shipmentProposals?.items}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: paginationAndSortingState.limit,
          pageSizeOptions: ['5', '10', '50'],
          total: shipmentProposals?.meta.totalItems ?? 0,
          current: shipmentProposals?.meta.currentPage ?? 1,
        }}
        onChange={handleTableChange}
        showHeader
        onRow={onRow}
      />
      {selectedProposal?.proposal && (
        <ProposalDetailsModal
          isOpen={showProposalDetailsModal}
          onClose={onDetailProposalClose}
          shipment={selectedProposal.shipment}
          selectedProposal={selectedProposal?.proposal}
          currency={company?.currency}
        />
      )}
      {shipmentInPreview && (
        <ShipmentPreview
          shipmentSlug={shipmentInPreview}
          onClose={onShipmentPreviewClose}
          onNavigateToShipment={onShipmentOverview}
        />
      )}
    </>
  );
};

export default ShipmentPendingQuotes;
