import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { UserRoleEnum } from 'types/entities/user.entity';

import { isViewer } from './collaborators';
import {
  hasShipmentStarted,
  isCargoOwner,
  isForwarder,
} from './shipment-helper';

export const isProposalAccepted = (currentStatus: IQuoteStatus) =>
  currentStatus === IQuoteStatus.ACCEPTED;

export const isProposalSubmitted = (currentStatus: IQuoteStatus) =>
  currentStatus === IQuoteStatus.SUBMITTED;

export const isProposalRequested = (currentStatus: IQuoteStatus) =>
  currentStatus === IQuoteStatus.REQUESTED;

export const isProposalRejected = (currentStatus: IQuoteStatus) =>
  currentStatus === IQuoteStatus.DECLINED;

export const isProposalRevoked = (currentStatus: IQuoteStatus) =>
  currentStatus === IQuoteStatus.REVOKED;

export const isProposalTreated = (status: string) =>
  [IQuoteStatus.ACCEPTED, IQuoteStatus.DECLINED].includes(
    status as IQuoteStatus
  );

export const isProposalEditDisabled = (
  isAdmin: boolean,
  proposalStatus: IQuoteStatus,
  isOwner: boolean
): boolean =>
  isProposalTreated(proposalStatus) ? !(isAdmin || isOwner) : false;

export const isProposalPrivate = (
  proposalStatus: IQuoteStatus,
  currentUserRole: UserRoleEnum | undefined
) => {
  return (
    (isCargoOwner(currentUserRole) || isForwarder(currentUserRole)) &&
    !isProposalAccepted(proposalStatus)
  );
};

export const isSuggestedProposalExist = (proposalData: IProposalEntity) =>
  !!(
    proposalData?.suggestedProposal && proposalData?.suggestedProposal !== null
  );

export const canCresteSuggestedProposal = (proposalData: IProposalEntity) =>
  !!(
    isProposalAccepted(proposalData.status) &&
    !isSuggestedProposalExist(proposalData)
  );

export const isProposalActionEnabled = (
  proposal: IProposalEntity,
  shipment: IShipmentEntity,
  defaultStatus: IQuoteStatus[] = [
    IQuoteStatus.REQUESTED,
    IQuoteStatus.DECLINED,
    IQuoteStatus.ACCEPTED,
  ]
) => {
  return (
    !defaultStatus.includes(proposal.status) &&
    !isViewer(shipment.currentCollaborator.accessLevel) &&
    !isForwarder(shipment.currentUserRole) &&
    !hasShipmentStarted(shipment.status)
  );
};
