import { UserConnectionTypes } from 'types/entities/user.entity';

import {
  ICompanyConnectionEntity,
  ICompanyEntity,
  ICreateCompanyRequest,
  ICreateCompanyResponse,
  IUpdateCompanyConnectionRequest,
} from '../types/entities/company.entity';
import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const companiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<
      ApiResponse<IEntityList<ICompanyConnectionEntity>>,
      { role: UserConnectionTypes }
    >({
      query: (params) => ({ url: `/company-connection`, params }),
      providesTags: ['Companies'],
    }),

    searchCompany: build.query<ICompanyEntity[], string>({
      query: (name: string) => ({ url: `/company/search/${name}` }),
      providesTags: (result, error, arg) => [{ type: 'Companies', id: arg }],
    }),

    addCompany: build.mutation<ICreateCompanyResponse, ICreateCompanyRequest>({
      query: (payload: ICreateCompanyRequest) => ({
        url: `/company-connection`,
        method: 'POST',
        body: payload.company,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Companies', id: arg.company.companyName },
      ],
    }),

    pinCompany: build.mutation<
      ICreateCompanyResponse,
      IUpdateCompanyConnectionRequest
    >({
      query: (payload: IUpdateCompanyConnectionRequest) => ({
        url: `/company-connection/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const {
  useSearchCompanyQuery,
  useGetCompaniesQuery,
  useAddCompanyMutation,
  usePinCompanyMutation,
} = companiesApi;
