import { IMentionInput } from 'types/feature/messages.type';

import { IProposalEntity } from './proposal.entity';
import { IShipmentEntity } from './shipment.entity';
import { UserRoleEnum } from './user.entity';

export enum MessagesTabKeyEnum {
  SHIPMENT_MESSAGES = 'shipment-messages',
  PROPOSAL_COMMENTS = 'proposal-comments',
}
export interface ICreatedByEntity {
  id: string;
  fullname: string;

  email: string;
  photo: string | null;
}

export interface IFileEntity {
  id: string;
  file: string;
  name: string;
  type: string;
}
export interface IGetMessages {
  page: number;
  limit: number;
  keyword: string;
}

export interface IMessageEntity {
  createdBy: ICreatedByEntity;
  id: string;
  createdAt: string;
  updatedAt: string;
  message: string;
  originalMessage: string;
  messageViews: any[];
  mentions: string[];
  files: IFileEntity[];
}
export interface IShipmentsMessageEntity extends IShipmentEntity {
  hasProposals: boolean;
  latestMessage: {
    createdAt: string;
    id: string;
    message: string;
    messageViews: [];
    originalMessage: string;
  };
  unreadCommentCount: number;
  unreadMessageCount: number;
  firstUnreadMessageId: string;
}
export interface IShipmentsMessageResponseBody {
  items: IShipmentsMessageEntity[];
  meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
export interface IShipmentMessagesListProps {
  shipmentsMessages: IShipmentsMessageEntity[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  getAllMessages: (params: IGetMessages) => void;
  setSelectedMessage: React.Dispatch<
    React.SetStateAction<IShipmentsMessageEntity | undefined>
  >;
  selectedMessage: IShipmentsMessageEntity | undefined;
}
export interface IProposalsListProps {
  shipmentId: string;
  selectedProposal: IProposalEntity | undefined;
  setSelectedProposal: React.Dispatch<
    React.SetStateAction<IProposalEntity | undefined>
  >;
  switchToShipmentMessagesTab: () => void;
}
export interface IProposalCommentsProps {
  proposalId: string;
  currentUserRole: UserRoleEnum;
}
export interface IMessagesHeaderProps {
  selectedMessage: IShipmentsMessageEntity;
  handleShowMembers: () => void;
}

export interface ISendMessageRequestBody extends IMentionInput {
  shipmentId: string;
  attachments: string[];
}

export interface IMembersListProps {
  shipmentId: string;
  handleHideMembers: () => void;
}
