import { Button, Col, Form, Input, Modal, Row, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useAddCompanyUserMutation } from 'api/user';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import React, { FC, useEffect, useState } from 'react';
import { IAddTeamMember } from 'types/entities/team.entity';

import { displayErrors } from 'utils/error-notification';

const AddTeamMember: FC<IAddTeamMember> = ({ addTeamMember }) => {
  const [open, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  // queries
  const [inviteTeamMmeber, { isLoading, isError, error, isSuccess }] =
    useAddCompanyUserMutation();

  // methods
  const onInviteTeamMember = async () => {
    try {
      const { fullname, email } = await form.validateFields();

      await inviteTeamMmeber({
        fullname,
        email,
      });
    } catch (error) {
      notification.error({
        message: 'Failed to invite new member, Try again !',
      });
    }
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (isError && error) displayErrors(error);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: `Invitation sent to ${form.getFieldValue('fullname')}!`,
      });
      form.resetFields();
      closeModal();
    }
  }, [isSuccess, form]);

  useEffect(() => {
    addTeamMember && setIsOpen(true);
  }, [addTeamMember]);

  return (
    <>
      <Button type="primary" onClick={openModal}>
        Add Team Member
      </Button>
      <Modal
        width={'50vw'}
        open={open}
        title="Invite new company member"
        onCancel={closeModal}
        footer={[
          <Button
            key="invite"
            type="primary"
            onClick={onInviteTeamMember}
            disabled={isLoading}
            loading={isLoading}
          >
            Invite
          </Button>,
          <Button
            key="cancel"
            onClick={closeModal}
            disabled={isLoading}
            loading={isLoading}
          >
            Cancel
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            TeamMembers: [{ fullname: '', email: '' }],
          }}
        >
          <Row gutter={[12, 12]}>
            <Col span={10}>
              <Form.Item
                label="Full Name"
                name="fullname"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Field is required',
                  },
                ]}
              >
                <Input placeholder="Full name" />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                label="Email"
                {...formItemLayoutWithOutLabel}
                name="email"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Field is required',
                  },
                  {
                    type: 'email',
                    message: 'Enter correct email.',
                  },
                ]}
              >
                <Input className="custom-input" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddTeamMember;
