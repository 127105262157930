import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { SearchAndSortParams } from 'types/feature/pagination.types';
import { IShipmentsFiltersState } from 'types/feature/shipments.types';

import { checkObjectNotEmpty } from 'utils/object.helpers';

export const defaultPaginationAndSorting: SearchAndSortParams = {
  page: 1,
  limit: 10,
  sortBy: 'createdAt',
  sortOrder: 'desc',
};

export const removeEmptyArrays = (shipmentFilters: IShipmentsFiltersState) => {
  return checkObjectNotEmpty(
    shipmentFilters as Record<string, string | string[]>
  )
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(shipmentFilters).reduce((acc: any, cv: string) => {
        if (shipmentFilters[cv].length > 0) {
          return {
            ...acc,
            [cv]: shipmentFilters[cv],
          };
        }
        return acc;
      }, {})
    : shipmentFilters;
};

export const selectLowestCostQuoteOfShipment = (shipment: IShipmentEntity) => {
  let lowestCostProposal: IProposalEntity | null = null;

  shipment?.proposals?.forEach((proposal) => {
    if (
      proposal.proposalCost &&
      proposal.proposalCost !== 0 &&
      proposal.status === IQuoteStatus.SUBMITTED
    ) {
      if (
        !lowestCostProposal ||
        proposal.proposalCost < lowestCostProposal.proposalCost
      ) {
        lowestCostProposal = proposal;
      }
    }
  });

  return { shipment, proposal: lowestCostProposal };
};
