import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { IUserConnectionEntity } from 'types/entities/company.entity';

export const columns: ColumnsType<IUserConnectionEntity> = [
  {
    title: 'Company',
    dataIndex: 'number',
    key: 'number',
    render: (_, record: IUserConnectionEntity) => (
      <Typography.Text type="secondary">
        {record.partner.company.name}
      </Typography.Text>
    ),
    sorter: (recordA, recordB) =>
      recordA.partner.company.name.localeCompare(recordB.partner.company.name),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, record: IUserConnectionEntity) => (
      <Typography.Text strong>{record?.partner.fullname}</Typography.Text>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (_, record: IUserConnectionEntity) => record.partner.email,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (_, record: IUserConnectionEntity) =>
      record.partner.company.address,
  },

  {
    title: 'Connection',
    dataIndex: 'email',
    key: 'partnerType',
    render: (_, record: IUserConnectionEntity) => record.partnerType,
  },
];
