import { message } from 'antd';
import { FirebaseError } from 'firebase/app';
import {
  isSignInWithEmailLink,
  User,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';
import { SignWithEmailResponse } from 'types/entities/auth.entity';
import { AuthProviderEnum } from 'types/feature/auth.types';

import { saveAuthTokens } from 'utils/auth-storage';
import { waitForAuthStateChange } from 'utils/user-helper';

import { HOST } from './common/config';
import {
  firebaseAuth,
  googleAuthProvider,
  msAuthProvider,
} from './common/firebase';
import {
  isUserEmailVerified,
  setPersistenceForUser,
} from './helper/auth.helper';

/** BASIC AUTH : Login with creds
 *
 *
 * @param email - The user's email address.
 * @param password - The user's password
 * @param rememberMe - To decide type of persistence SESSION vs LOCAL
 *
 * @public
 */

export const loginWithCreds = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  try {
    // set persistence
    setPersistenceForUser(rememberMe);

    const loggedInUser = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    const { user } = loggedInUser;

    if (!isUserEmailVerified(user)) {
      return {
        isSuccess: false,
        showResend: true,
        errorMessage: `Email not verified. Please check your inbox and confirm your email before login !`,
      } as SignWithEmailResponse;
    }

    const { token, claims } = await user.getIdTokenResult(true);
    saveAuthTokens(
      {
        accessToken: token,
        expiresIn: Number(claims.exp),
        refreshToken: '',
      },
      email
    );

    return {
      isSuccess: true,
      showResend: false,
      additional: {
        companyId: claims['companyId'] as string,
        userId: claims['userId'] as string,
      },
    } as SignWithEmailResponse;
  } catch (err) {
    if (err instanceof FirebaseError) {
      return {
        isSuccess: false,
        showResend: true,
        errorMessage: `Wrong credentials, Invalid email or password`,
      } as SignWithEmailResponse;
    } else {
      return {
        isSuccess: false,
        showResend: true,
        errorMessage: `Authentication failed: Unknown issue has occured, Please try again !`,
      } as SignWithEmailResponse;
    }
  }
};

/** BASIC AUTH : Login with social provider (GOOGLE or Microsoft)
 *
 *
 * @param provider : AuthProviderEnum
 *
 * @public
 */
export const loginWithSocialProviders = async (provider: AuthProviderEnum) => {
  try {
    const authProvider =
      provider === AuthProviderEnum.GOOGLE
        ? googleAuthProvider
        : msAuthProvider;

    const result = await signInWithPopup(firebaseAuth, authProvider);

    // not needed for now
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(result);

    // The signed-in user info.
    const user = result.user;
    const refreshedTokens = await user?.getIdTokenResult(true);

    //save tokens and session's email for future uses
    saveAuthTokens(
      {
        accessToken: refreshedTokens.token as string,
        expiresIn: Number(refreshedTokens?.claims.exp),
        refreshToken: '',
      },
      ''
    );

    // sign in successful , user can proceed other actions
    return {
      isSuccess: true,
      showResend: false,
      user: user,
    } as SignWithEmailResponse;
  } catch (error: FirebaseError | unknown) {
    // Handle Errors here.
    if (error instanceof FirebaseError) {
      const fbError = error as FirebaseError;

      switch (fbError.code) {
        case 'auth/cancelled-popup-request':
          message.error('The request has been canceled');
          return {
            isSuccess: false,
            showResend: false,
            errorMessage: 'The login request has been cancelled, try again !',
          } as SignWithEmailResponse;
        case 'auth/user-cancelled':
          message.error('The permissions are not granted !');
          return {
            isSuccess: false,
            showResend: false,
            errorMessage:
              "The permission haven't been granted for this account !",
          } as SignWithEmailResponse;
        case 'auth/popup-closed-by-user':
          return {
            isSuccess: false,
            showResend: false,
            errorMessage: 'cancled',
          } as SignWithEmailResponse;
        default:
          return {
            isSuccess: false,
            showResend: false,
            errorMessage: 'Unexpected error, please try again !',
          } as SignWithEmailResponse;
      }
    }

    message.error(
      'Something went wrong during authentification, please try again !'
    );

    return {
      isSuccess: false,
      showResend: false,
      errorMessage:
        'Something went wrong during authentification, please try again !',
    } as SignWithEmailResponse;
  }
};

/** AUTH with Email LINK : Login with confirmation link received through email
 *
 *
 * @param email : The user's email ( either stored one or typed)
 *
 * @public
 */

export const checkExternalUser: (
  storedEmail: string
) => Promise<SignWithEmailResponse> = async (storedEmail: string) => {
  const emailLink = window.location.href; // For PROD

  if (isSignInWithEmailLink(firebaseAuth, emailLink)) {
    let current: User | null;

    // remeber user
    setPersistenceForUser(true);

    if (storedEmail) {
      current = await waitForAuthStateChange(firebaseAuth);
      // if we have a stored email + current valid user and the emails matches
      // then there is no need for verification
      if (current && current.email === storedEmail.toLocaleLowerCase()) {
        return {
          isSuccess: true,
          showResend: false,
        } as SignWithEmailResponse;
      }
    }

    try {
      // else we need to authenticate in order to get the tokens
      const userCredential = await signInWithEmailLink(
        firebaseAuth,
        storedEmail as string,
        emailLink
      );
      const user = userCredential.user;

      const refreshedTokens = await user?.getIdTokenResult(true);
      // save tokens and session's email for future uses
      saveAuthTokens(
        {
          accessToken: refreshedTokens?.token as string,
          refreshToken: '',
          expiresIn: Number(refreshedTokens?.claims.exp),
        },
        storedEmail
      );

      // sign in successful , user can proceed other actions
      return { isSuccess: true, showResend: false } as SignWithEmailResponse;
    } catch (error) {
      // if catched error is related to firebase auth
      if (error instanceof FirebaseError) {
        // link expired
        if ((error as FirebaseError).code.includes('invalid-action-code')) {
          message.error('The confirmation code has expired');
          return {
            isSuccess: false,
            showResend: true,
            errorMessage: 'The confirmation code has expired',
          } as SignWithEmailResponse;
        } else {
          // wrong email
          // P.S. we should be able to avoid this step unless it is the first time
          return {
            isSuccess: false,
            showResend: false,
            errorMessage: 'The email you entered is not valid !',
          } as SignWithEmailResponse;
        }
      }
      message.error(
        'Something went wrong during authentification, please try again !'
      );

      return {
        isSuccess: false,
        showResend: false,
        errorMessage:
          'Something went wrong during authentification, please try again !',
      } as SignWithEmailResponse;
    }
  } else {
    if (storedEmail) {
      const current = await waitForAuthStateChange(firebaseAuth);
      // if we have a stored email + current valid user and the emails matches
      // then there is no need for verification
      if (current && current.email === storedEmail.toLocaleLowerCase()) {
        return {
          isSuccess: true,
          showResend: false,
        } as SignWithEmailResponse;
      }
    }
    return {
      isSuccess: false,
      showResend: false,
      errorMessage: 'The link you clicked is not a valid authentication link !',
    };
  }
};

export const resetPasswordRequest = async (email: string) => {
  try {
    await sendPasswordResetEmail(firebaseAuth, email, {
      url: `https://${HOST}/login?action=resetPassword&email=${email}`,
      handleCodeInApp: true,
    });
    return { isSuccess: true, showResend: false } as SignWithEmailResponse;
  } catch (error: FirebaseError | unknown) {
    if (error instanceof FirebaseError) {
      // link expired
      if ((error as FirebaseError).code.includes('auth/user-not-found')) {
        return {
          isSuccess: false,
          showResend: true,
          errorMessage:
            'The email not found ! please enter a valid email or sign up.',
        } as SignWithEmailResponse;
      } else {
        return {
          isSuccess: false,
          showResend: false,
          errorMessage: 'Something went wrong, Please try again !',
        } as SignWithEmailResponse;
      }
    }
    return {
      isSuccess: false,
      showResend: false,
      errorMessage:
        'Something went wrong during reseting password, please try again !',
    } as SignWithEmailResponse;
  }
};

export const handleResetPassword = async (
  actionCode: string,
  newPassword: string
) => {
  try {
    await confirmPasswordReset(firebaseAuth, actionCode, newPassword);
    return { isSuccess: true, showResend: false } as SignWithEmailResponse;
  } catch (error) {
    // Invalid or expired action code. Ask user to try to reset the password
    // again.
    if (error instanceof FirebaseError) {
      // link expired
      if ((error as FirebaseError).code.includes('invalid-action-code')) {
        return {
          isSuccess: false,
          showResend: true,
          errorMessage:
            'The confirmation code has expired , please resend another Password Reset request !',
        } as SignWithEmailResponse;
      }
    }

    return {
      isSuccess: false,
      showResend: false,
      errorMessage:
        'Something went wrong during authentification, please try again !',
    } as SignWithEmailResponse;
  }
};
