import { PaperClipOutlined } from '@ant-design/icons';
import { Space, Typography, Image as AntImage } from 'antd';
//import Image as AntImage to differentiate with Image Object used in the getValidImageSources method
import ProfileImage from 'app/components/ProfilePicture';
import React, { FC, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { IMessageItemProps } from 'types/feature/messages.type';

import './message-item.scss';

const { Text } = Typography;

const MessageItem: FC<IMessageItemProps> = ({
  message,
  isActive,
  isWriting,
  isChatBot,
  isMarkdown,
}) => {
  const [validImageUrls, setValidImageUrls] = useState<string[]>([]);

  //methods

  const getValidImageSources = async (files: { file: string }[]) => {
    const urls = await Promise.all(
      files.map(async (file) => {
        const isValid = await new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(true);
          img.onerror = () => resolve(false);
          img.src = file.file;
        });

        return isValid ? file.file : ''; //returning only the files that have valid img src which is {file.file}
      })
    );

    return urls;
  };

  //hooks

  useEffect(() => {
    getValidImageSources(message.files).then(setValidImageUrls);
  }, [message.files]);
  return (
    <div className={`message-wrapper ${isActive ? 'active' : ''}`}>
      <div className="message-item">
        <ProfileImage
          name={message.userName}
          photoUrl={message.userPhoto}
          wrapperClassName="user-gravatar"
        />
      </div>
      <div
        className={`message-content ${isActive ? 'active' : ''}  ${
          isChatBot ? 'chatbot' : ''
        }`}
      >
        <div className="info-wrapper">
          <Text className="user-name">{message.userName}</Text>
          <Text className="message-date">{message.messageDate}</Text>
        </div>
        {isWriting ? (
          <Space align="center">
            <Text className="loader-text">Looking for an answer </Text>
            <span className="loader"></span>
          </Space>
        ) : (
          <Text className="user-message">
            {isMarkdown ? (
              <Markdown>{message.message}</Markdown>
            ) : (
              message.message
            )}
          </Text>
        )}

        {message.files.map((file, index) => (
          <Text key={index} className="user-message-link" ellipsis>
            {validImageUrls.includes(file.file) ? (
              <AntImage width={200} src={file.file} />
            ) : (
              <a key={index} href={file.file} target="_blank" rel="noreferrer">
                <PaperClipOutlined className="pin-icon" />
                {file.name}
              </a>
            )}
          </Text>
        ))}
      </div>
    </div>
  );
};

export default MessageItem;
