import { PaperClipOutlined } from '@ant-design/icons';
import { Space, Typography, Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { IBookingAssignButtonProps } from 'types/feature/bookings.types';

import { convertSlugToShipmentId } from 'utils/slug-formattor';

import { canAssignBooking } from './helpers/assign-booking.helper';

export const BookingAssignButton: FunctionComponent<
  IBookingAssignButtonProps
> = ({ booking, isDisabled, openAssignDrawer }) => {
  const isBookingAssignment = canAssignBooking(booking);
  return booking.shipment?.id ? (
    <Space direction="vertical">
      <Link
        to={`/shipments/${convertSlugToShipmentId(
          booking.shipment.slug
        )}/bookings`}
        state={{ shipmentId: booking.shipment?.id }}
      >
        {convertSlugToShipmentId(booking.shipment.slug)}
      </Link>
      <Typography.Text strong ellipsis style={{ width: 150 }}>
        {booking.shipment.shipmentReference
          ? `${booking.shipment.shipmentReference}`
          : ''}
      </Typography.Text>
    </Space>
  ) : (
    <Button
      icon={<PaperClipOutlined />}
      size="small"
      type="primary"
      disabled={isDisabled || !isBookingAssignment}
      onClick={openAssignDrawer}
    >
      Assign Shipment
    </Button>
  );
};
