import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography, notification } from 'antd';
import {
  useCreateProxyShipmentMutation,
  useCreateShipmentMutation,
} from 'api/shipment';
import ShipmentCreationForm from 'app/components/ShipmentCreation/ShipmentCreationForm';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  IContractEntity,
  IMatchContractRatesReq,
} from 'types/entities/contract.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { TOnCreateShipmentParams } from 'types/feature/create-shipment.types';

import { bookContractClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import ContractsSearch from './ContractSearch';
import './contract-search.style.scss';
import { createDefaultShipmentFromContract } from './helper';

const ContractSearchAndBook: FC = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [contractId, setContractId] = useState<string | undefined>(undefined);
  const [shipmentToBook, setShipmentToBook] = useState<IShipmentEntity | null>(
    null
  );

  const [createShipment, { isLoading: isCreating }] =
    useCreateShipmentMutation();

  const [createProxyShipment, { isLoading: isProxyShipmentLoading }] =
    useCreateProxyShipmentMutation();

  const isBookingOn = !!shipmentToBook;
  const onBookClick = (
    contract: IContractEntity,
    searchValues: IMatchContractRatesReq
  ) => {
    const [shipment, contractId] = createDefaultShipmentFromContract(
      contract,
      searchValues
    );
    setContractId(contractId);
    setShipmentToBook(shipment as IShipmentEntity);
  };

  const onCloseShipmentTab = () => {
    setShipmentToBook(null);
    setContractId(undefined);
  };
  const onNavigateToShipmentDetails = (shipment: {
    id: string;
    slug: string;
  }) => {
    navigate(`/shipments/${shipment?.slug}`, {
      state: { shipmentId: shipment?.id },
    });
  };

  const onCreateShipment: TOnCreateShipmentParams = async (
    shipment,
    inviteType,
    hasExternalConnections
  ) => {
    try {
      let resp;
      if (hasExternalConnections) {
        resp = await createProxyShipment(shipment).unwrap();
      } else {
        resp = await createShipment({
          body: shipment,
          params: { contractId },
        }).unwrap();
      }
      onNavigateToShipmentDetails({ id: resp.id, slug: `SH-${resp?.slug}` });

      bookContractClickEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
      });

      notification.success({
        message: 'Shipment booked successfully !',
        description:
          'The shipment has been created ! Check quote tab to consult attached proposals !',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Booking Error' });
    }
  };

  return (
    <Row>
      <Col span={isBookingOn ? 10 : 24}>
        <ContractsSearch isBookingOn={isBookingOn} onBookClick={onBookClick} />
      </Col>

      <Col span={isBookingOn ? 14 : 0}>
        <div className="book-shipment-wrapper">
          <Row justify="space-between">
            <Typography.Title level={3}>Create Shipment</Typography.Title>
            <Button
              type="text"
              size="large"
              icon={<CloseOutlined />}
              onClick={onCloseShipmentTab}
            />
          </Row>
          <ShipmentCreationForm
            isLoading={isCreating || isProxyShipmentLoading}
            defaultShipment={shipmentToBook}
            openPreviewTab={null}
            onCreateShipment={onCreateShipment}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ContractSearchAndBook;
