import { Button, Form, Input, Modal, notification } from 'antd';
import { useGetForwarderColleaguesQuery } from 'api/forwarders';
import { useInviteUserMutation } from 'api/user';
import React, { useEffect, useState } from 'react';
import {
  ICreateUserEntity,
  UserConnectionTypes,
} from 'types/entities/user.entity';
import { AddForwarderColleagueModalProps } from 'types/feature/connections.type';

import { displayErrors } from 'utils/error-notification';

const AddForwarderColleagueModal: React.FC<AddForwarderColleagueModalProps> = ({
  btnProps,
  company,
  shipmentId,
  onAddNewUser,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { refetch: refetchForwarderColleagues } =
    useGetForwarderColleaguesQuery({
      shipmentId,
      companyId: company.id,
    });

  const [inviteForwarder, { isLoading, isSuccess }] = useInviteUserMutation();

  const [form] = Form.useForm<Partial<ICreateUserEntity>>();

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = async () => {
    const { fullname, email, companyId, userConnectionType } =
      await form.validateFields();

    inviteForwarder({
      fullname,
      email,
      companyId,
      userConnectionType,
    })
      .unwrap()
      .then(async (resp) => {
        notification.success({ message: 'Forwarder Added Successfully!' });
        await refetchForwarderColleagues();
        await form.resetFields();
        hideModal();
        onAddNewUser(resp.data.id);
      })
      .catch((error) => {
        displayErrors(error, { title: 'Error' });
      });
  };

  return (
    <div className="add-supplier-wrapper">
      <div className="btn-wrapper">
        <Button type="text" onClick={showModal} {...btnProps}>
          Add New Forwarder
        </Button>
      </div>

      <Modal
        title="Add New Forwarder"
        open={isModalOpen}
        onCancel={hideModal}
        footer={[
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            htmlType="submit"
            form="supplier-form"
          >
            Add Forwarder
          </Button>,
        ]}
      >
        <div className="supplier-form-wrapper">
          <Form
            id="supplier-form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            form={form}
            initialValues={{
              userConnectionType: UserConnectionTypes.FORWARDER,
              companyName: company.name,
              companyId: company.id,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="userConnectionType"
              label="Role"
              hidden={true}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Full Name"
              name="fullname"
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Field is required',
                },
              ]}
            >
              <Input placeholder="Full name" />
            </Form.Item>
            {/* <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input placeholder="Doe" />
            </Form.Item> */}

            <Form.Item name="companyId" key="companyId" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="Company"
              name="companyName"
              key="companyName"
              required
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input type="email" placeholder="john.doe@email.com" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AddForwarderColleagueModal;
