import Checklist, { useChecklist } from '@dopt/react-checklist';
import { Button } from 'antd';
import SignupOnboardingModal from 'app/components/UserOnboarding/SignupOnboardingModal';
import DashboardCard from 'app/design-system/DashboardCard';
import { useFeatureFlags } from 'providers/feature-flag-provider';
import React, { useState } from 'react';
import { SignupOnboardingScreenEnum } from 'types/feature/user-onboarding';

import './onboarding-checklist-card.scss';

const OnboardingChecklistCard = () => {
  const checklist = useChecklist('announcement.signup-user-onboarding');

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [defaultOnboardingScreen, setDefaultOnboardingScreen] =
    useState<SignupOnboardingScreenEnum | null>(
      SignupOnboardingScreenEnum.DEFAULT
    );

  const { featureFlags } = useFeatureFlags();
  const isSignupOnboardingEnabled = featureFlags
    ? featureFlags['FEATURE_SIGNUP_ONBOARDING_ENABLED']
    : false;

  const onOboardingModalClose = async () => {
    setShowOnboardingModal(false);
  };

  const onCompleteClick = (selectedScreen: SignupOnboardingScreenEnum) => {
    setShowOnboardingModal(true);
    setDefaultOnboardingScreen(selectedScreen);
  };

  return (
    <DashboardCard
      headerTitle="Onboarding Journey"
      rightAccessory={
        <Button type="text" onClick={checklist.dismiss}>
          Dismiss All
        </Button>
      }
    >
      {isSignupOnboardingEnabled &&
        showOnboardingModal &&
        defaultOnboardingScreen && (
          <SignupOnboardingModal
            defaultScreen={defaultOnboardingScreen}
            isOpen={showOnboardingModal}
            onClose={onOboardingModalClose}
          />
        )}
      <Checklist.Root className="checklist-wrapper">
        <Checklist.Body>{checklist.body}</Checklist.Body>
        <Checklist.Items>
          {checklist.items.map((item, i) => (
            <Checklist.Item index={i} key={i}>
              <Checklist.ItemIcon>
                {item.completed ? (
                  <Checklist.IconCompleted />
                ) : item.skipped ? (
                  <Checklist.IconSkipped />
                ) : (
                  <Checklist.IconActive />
                )}
              </Checklist.ItemIcon>
              <Checklist.ItemContent>
                <Checklist.ItemTitle
                  disabled={item.done}
                  {...(!item.completed && {
                    onClick: () =>
                      onCompleteClick(
                        item.id.split('.')[1] as SignupOnboardingScreenEnum
                      ),
                  })}
                >
                  {item.title}
                </Checklist.ItemTitle>

                <Checklist.ItemBody disabled={item.done}>
                  {item.body}
                </Checklist.ItemBody>
              </Checklist.ItemContent>
              {!item.done && <Checklist.ItemSkipIcon onClick={item.skip} />}
            </Checklist.Item>
          ))}
        </Checklist.Items>
      </Checklist.Root>
    </DashboardCard>
  );
};

export default OnboardingChecklistCard;
