import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { UserRoleEnum } from 'types/entities/user.entity';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';
import { IPurchaseOrderCollaborators } from 'types/feature/purchase-orders.types';
import { ShipmentStatusEnum } from 'types/feature/shipments.types';

import { hasShipmentStarted } from 'utils/shipment-helper';

export const getMentionUserList = (users: ICollaboratorsEntity[]) => {
  return users.map((user) => ({
    id: user.collaborator.id,
    display: `${user.collaborator.fullname} `,
    photo: user.collaborator.photo,
  }));
};

export const getMentionPoUserList = (
  collaborators: IPurchaseOrderCollaborators[],
  showOnlyCollabs: boolean
) => {
  return collaborators
    .filter((collaborator) => !showOnlyCollabs || collaborator.isCollaborator)
    .map((collaborator) => ({
      id: collaborator.id,
      display: `${collaborator.fullname}`,
      photo: collaborator.photo,
    }));
};

export const getMentionData = (collabData: IRecentCollaboratorDef[]) => {
  return collabData.map((collaborator) => ({
    id: collaborator.id,
    display: `${collaborator.fullname}`,
    isAlreadyInvited: collaborator.isAlreadyInvited,
    photo: collaborator.photo,
  }));
};

export const excludeForwarderRole = (users: ICollaboratorsEntity[]) => {
  return users.filter((user) => user.role !== UserRoleEnum.FORWARDER);
};

export const computeUserList = (
  users: ICollaboratorsEntity[],
  shipmentStatus: ShipmentStatusEnum
) => {
  if (users.length === 0) return [];

  if (hasShipmentStarted(shipmentStatus)) {
    return getMentionUserList(users);
  } else {
    return getMentionUserList(excludeForwarderRole(users));
  }
};
