import { Space } from 'antd';
import Papa from 'papaparse';
import React from 'react';
import { TDcoumentExtractorTableTypes } from 'types/feature/document-extractor.types';
import * as XLSX from 'xlsx';

// Generate a random id
export const generateRandonId = () => Math.random().toString(36).substr(2, 9);

export const addRandomIds = (data) => {
  return data.map((item) => ({
    ...item,
    __id: generateRandonId(),
  }));
};

export const removeRandomIds = (dataWithIds) => {
  return dataWithIds.map(({ __id, ...rest }) => rest);
};

const hiddenColumns = [
  'si',
  'hscodesdescription',
  'freightcurrency',
  'freightcharge',
  'insurancecurrency',
  'insurancecharge',
  '__id',
];

export const precisionInputColumns = ['WEIGHT', 'QUANTITY', 'BOXES', 'VALUE'];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const computeCsvColumns = (csvData: any[], renderTitleDropdown: any) => {
  if (csvData?.length === 0) {
    return [];
  }

  const headerColumns = Object.keys(csvData[0]);
  const csvColumns: TDcoumentExtractorTableTypes = headerColumns
    .filter((column) => !hiddenColumns.includes(column.toLowerCase()))
    .map((column) => ({
      title: (
        <Space>
          {column} {renderTitleDropdown(column, csvData)}
        </Space>
      ),
      dataIndex: column,
      editable: true,
      key: column,
      ...(precisionInputColumns.includes(column) && {
        render: (text) => (text ? parseFloat(text).toFixed(2) : text),
      }),
    }));

  return csvColumns;
};

export const computeCsvData = (csvString: string) => {
  let csvData;

  Papa.parse(csvString, {
    header: true,
    complete: function (result) {
      if (result.data.length > 0) {
        // Add random id to each item in result.data
        csvData = addRandomIds(result.data);
      }
    },
  });

  return csvData;
};

export const computeCsvToExcelLink = (jsonData) => {
  // Convert JSON to CSV
  const csvData = Papa.unparse(jsonData);

  // Convert CSV to Excel format (XLSX)
  const workbook = XLSX.read(csvData, { type: 'binary' });
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Convert array buffer to Blob
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return window.URL.createObjectURL(blob);
};
