import { UploadProps, message } from 'antd';
import { API_URL } from 'api/common/config';
import { useState } from 'react';

import { getAuthToken } from 'utils/auth-storage';
import { displayErrors } from 'utils/error-notification';

export const useFileDragger = (shipmentId?: string) => {
  const [attachemnts, setAttachments] = useState<string[]>([]);
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    method: 'POST',
    maxCount: 12,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },

    action: `${API_URL}/shipment/upload`,

    onChange(info) {
      const { status, response, name } = info.file;

      if (status === 'done') {
        message.success(`${name} file uploaded successfully.`);
        setAttachments((value) => [...value, response[0].url]);
      } else if (status === 'error') {
        displayErrors(
          { status: response.statusCode, data: response },
          { title: 'Delete File Error' }
        );
      }
    },
  };

  return { props, attachemnts };
};
