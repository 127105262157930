import React, { ReactNode } from 'react';

import './control-card.scss';

interface IControlCardProps {
  renderMain: ReactNode;
  renderFooter?: ReactNode;
}

const ControlCard: React.FC<IControlCardProps> = ({
  renderMain,
  renderFooter,
}) => (
  <div className="control-card">
    <div className="control-card__main">{renderMain}</div>
    {renderFooter && <div className="control-card__footer">{renderFooter}</div>}
  </div>
);

export default ControlCard;
