import { Divider, Empty, Typography } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import StatusTimeline from 'app/design-system/StatusTimeline';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import {
  IShipmentEntity,
  IShipmentHistoryDef,
} from 'types/entities/shipment.entity';

import { removeZFromISO } from 'utils/common';
import {
  dateFormat2Const,
  dateFormatAirConst,
  isAirTransportation,
} from 'utils/shipment-type';

import ShipmentStatusSwitch from '../ShipmentStatusSwitch';
import './shipment-status.scss';

const { Text, Paragraph } = Typography;

const computeStatusTimeline = (
  currentStatus: string,
  shipmentHistory: IShipmentHistoryDef[] = []
) => {
  const currentStatusIndex = shipmentHistory.findIndex(
    (s) => s.key === currentStatus
  );

  return shipmentHistory.map((status, index) =>
    currentStatusIndex >= index
      ? { ...status, isActive: true }
      : { ...status, isActive: false }
  );
};

const ShipmentStatus: FC<{ shipment: IShipmentEntity }> = ({ shipment }) => {
  const dateFormat =
    shipment?.shipmentType && isAirTransportation(shipment?.shipmentType.title)
      ? dateFormatAirConst
      : dateFormat2Const;

  return (
    <DashboardCard
      headerTitle="Current Status"
      fitContent
      noMargin
      className="overview-card-wrapper-1 shipment-status-wrapper"
    >
      {shipment ? (
        <>
          <div className="footer">
            <div className="footer-status">
              <ShipmentStatusSwitch shipment={shipment} />
            </div>
            <div className="footer-date">
              <Paragraph className="m-0">Expected Delivery</Paragraph>
              <Text>
                {shipment.eta
                  ? dayjs(removeZFromISO(shipment.eta)).format(dateFormat)
                  : ''}
              </Text>
            </div>
          </div>
          <Divider />

          <StatusTimeline
            items={computeStatusTimeline(
              shipment.status,
              shipment.shipmentHistory
            )}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </DashboardCard>
  );
};

export default ShipmentStatus;
