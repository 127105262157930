import { Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import CompanyField from 'app/design-system/CompanyField';
import React, { FC, useState } from 'react';
import { UserConnectionTypes } from 'types/entities/user.entity';
import {
  IConnectionForm,
  IConnectionFormProps,
} from 'types/feature/connections.type';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

import { getAccessLevels, renderAccessLevelOptions } from 'utils/collaborators';
import { capitalize } from 'utils/format-fields';
import { isPhoneNumberValid } from 'utils/inputs-validators';

const userConnectionsRoleOptions = Object.values(UserConnectionTypes);

const renderRoleOptions = (roleOptions: string[]) =>
  roleOptions.map((role) => {
    return {
      label: capitalize(role.replace('_', ' ')),
      value: role,
    };
  });

const ConnectionForm: FC<IConnectionFormProps> = ({
  initialValues,
  form,
  isRoleHidden,
  isEditMode = false,
  isPoCollaborator,
  currentUserAccessLevel,
}) => {
  const [selectedRole, setSelectedRole] = useState<UserConnectionTypes>(
    initialValues?.userConnectionType
  );
  const handleSelectAcompany =
    (name: string | number) => (value: string, id: string | undefined) => {
      if (value && id) {
        form.setFieldValue('companyName', value);
        form.setFieldValue('companyId', id);
      }
    };

  const onValuesChange = (
    changedValues: Partial<IConnectionForm>,
    values: IConnectionForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === 'userConnectionType') {
      setSelectedRole(values.userConnectionType);
    }
  };

  return (
    <div className="supplier-form-wrapper">
      <Form
        id="supplier-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="userConnectionType"
          label="Role"
          hidden={isRoleHidden}
          {...(!isRoleHidden && {
            rules: [{ required: true, message: 'Field is required' }],
          })}
        >
          <Select
            placeholder="Select Role"
            options={renderRoleOptions(userConnectionsRoleOptions)}
            disabled={isEditMode}
          />
        </Form.Item>
        <Form.Item
          label="Full Name"
          name="fullname"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Field is required',
            },
          ]}
        >
          <Input placeholder="Full name" />
        </Form.Item>

        <Form.Item name="companyId" key="companyId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Company"
          name="companyName"
          key="companyName"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The company is required, if it is new make sure to add it first !'
                );
              },
            }),
          ]}
          required
        >
          {isEditMode ? (
            <Input disabled={isEditMode} />
          ) : (
            <CompanyField
              userRole={selectedRole}
              handleSelectAcompany={handleSelectAcompany('company')}
            />
          )}
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Field is required' }]}
        >
          <Input
            type="email"
            placeholder="john.doe@email.com"
            disabled={isEditMode}
          />
        </Form.Item>
        {isPoCollaborator && currentUserAccessLevel ? (
          <Form.Item name="accessLevel" label="Access Level" key="access_level">
            <Select
              placeholder="Select Role"
              options={renderAccessLevelOptions(currentUserAccessLevel)}
              disabled={isEditMode}
            />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label="Phone Number"
              name="mobileNumber"
              rules={[
                {
                  max: 14,
                  min: 10,
                  message: 'Phone number should be at least 10 digits !',
                },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (value && isPhoneNumberValid(value)) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'The inserted phone number  is not valid !'
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="+96000000000" />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Input placeholder="ex: UAE" />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <TextArea />
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

export default ConnectionForm;
