import {
  Alert,
  Button,
  Checkbox,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  proposalsApi,
  useAddCommentMutation,
  useGetPropsalByIdQuery,
  useRevokeProposalMutation,
} from 'api/proposal';
import DescriptionList from 'app/design-system/DescriptionList';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { useAppDispatch } from 'state/store';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IProposalCommentEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IMentionInput } from 'types/feature/messages.type';
import {
  IDescriptionContentProps,
  IProposalDetailsPorps,
} from 'types/feature/proposal.types';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import {
  isProposalActionEnabled,
  isSuggestedProposalExist,
} from 'utils/proposal-helper';
import { isForwarder, isShipmentCreatorOrCO } from 'utils/shipment-helper';
import { dateFormat2Const } from 'utils/shipment-type';
import { computeFullName } from 'utils/user-utils';

import ProposalActions from '../ProposalActions';
import ProposalContractButton from '../ProposalContractButton';
import ProposalDiffViewer from '../ProposalDiffViewer';
import ShipmentDetailsComparison from '../ShipmentDetailsComparison';
import {
  CommentSection,
  DocumentSection,
  HeaderSection,
  FeeDetailSection,
  TotalFeeSection,
} from './ProposalDetailsComponent';
import './proposal-modal.scss';

const { Text, Title } = Typography;
const { confirm } = Modal;

const DescriptionContent: FC<IDescriptionContentProps> = ({
  title,
  content,
}) => {
  return (
    <div className="desctiption-content-wrapper">
      <Text>{title}</Text>
      <div className="desctiption-content-box">
        <Text>{content}</Text>
      </div>
    </div>
  );
};

const ProposalDetailsModal: FC<IProposalDetailsPorps> = ({
  shipment,
  selectedProposal,
  isOpen,
  onEditClick,
  onClose,
  onReawardClick,
  currency = CurrencyEnum.USD, // default is always set to USD
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);

  const [sendRejectionEmail, setSendRejectionEmail] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { data: proposalData, isLoading } = useGetPropsalByIdQuery(
    selectedProposal.id,
    {
      skip: !selectedProposal,
    }
  );

  const isActionEnabled =
    !!proposalData && isProposalActionEnabled(proposalData, shipment);

  const isRevokeEnabled =
    !!proposalData &&
    !isViewer(shipment.currentCollaborator.accessLevel) &&
    !isForwarder(shipment.currentUserRole) &&
    proposalData.status === IQuoteStatus.ACCEPTED;

  const isReawardEnabled =
    !!proposalData &&
    !isViewer(shipment.currentCollaborator.accessLevel) &&
    !isForwarder(shipment.currentUserRole) &&
    proposalData.status === IQuoteStatus.DECLINED &&
    proposalData.submittedAt;

  const [
    addComment,
    { data: respData, isLoading: isLoadingComment, isError, isSuccess },
  ] = useAddCommentMutation();

  const [revokeProposal] = useRevokeProposalMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to send comment',
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };

  const onSendRejectionEmailChange = (e: CheckboxChangeEvent) => {
    setSendRejectionEmail(e.target.checked);
  };

  const updateProposal = (respData: IProposalCommentEntity) => {
    dispatch(
      proposalsApi.util.updateQueryData(
        'getPropsalById',
        selectedProposal.id as string,
        (proposal) => {
          proposal?.proposalComments?.push(respData);
        }
      )
    );
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess && respData) {
      updateProposal(respData);
    }
    // eslint-disable-next-line
  }, [respData, isSuccess]);

  useEffect(() => {
    if (isError) errorNotification();
  }, [isError]);

  const onAddComment = async (message: IMentionInput) => {
    if (!message) return;
    if (proposalData) {
      addComment({
        comment: message.originalText,
        proposalId: proposalData.id,
      });
    }
  };

  const onRevokeClick = () => {
    confirm({
      title: 'Are you sure?',
      okText: 'Revoke',
      icon: null,
      content: 'Are you sure you want to revoke this quotation?',
      onOk: async () => {
        try {
          if (!proposalData?.id) {
            throw new Error();
          }
          await revokeProposal({ id: proposalData.id }).unwrap();
          notification.success({
            message: (
              <p>
                Proposal{' '}
                <strong>
                  {proposalData.company.name} (RFQ-{proposalData.slug})
                </strong>{' '}
                revoked successfuly
              </p>
            ),
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'Error on revoking proposal' });
        }
        return;
      },
    });
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      width="80vw"
      footer={null}
    >
      {isLoading ? (
        <div className="proposal-modal-loader-wrapper">
          <Spin />
        </div>
      ) : (
        proposalData && (
          <div className="proposal-details-wrapper">
            <Space
              direction="vertical"
              size="large"
              className="proposal-details-space-fix"
            >
              <HeaderSection
                proposal={proposalData}
                onEditClick={onEditClick}
                isEditEnabled={
                  !isViewer(shipment.currentCollaborator.accessLevel)
                }
              />

              {/* TODO: component under construction */}
              {isSuggestedProposalExist(proposalData) ? (
                <ProposalDiffViewer
                  proposal={proposalData}
                  isActionsEnabled={isShipmentCreatorOrCO(
                    shipment,
                    currentUser
                  )}
                  proposalCurrency={shipment.createdBy.company.currency}
                />
              ) : (
                <>
                  <TotalFeeSection
                    proposal={proposalData}
                    currency={shipment.createdBy.company.currency}
                  />
                  {proposalData.proposalShipmentItems.length > 0 && (
                    <ShipmentDetailsComparison
                      proposal={proposalData}
                      shipment={shipment}
                    />
                  )}
                  {proposalData.status !== IQuoteStatus.REQUESTED && (
                    <>
                      <FeeDetailSection proposal={proposalData} />
                      <div>
                        <DescriptionList
                          label="Carrier"
                          description={proposalData?.carrier?.name || ''}
                        />
                        <DescriptionList
                          label="Payment Term"
                          description={proposalData.paymentTerms}
                        />
                        <DescriptionList
                          label="Free Days"
                          description={proposalData.freeDays}
                        />
                        <DescriptionList
                          label="Transit Time (Days)"
                          description={proposalData.transitTime}
                        />
                        <DescriptionList
                          label="Expiry Date"
                          description={dayjs(
                            proposalData.expirationDate
                          ).format(dateFormat2Const)}
                        />
                        {proposalData.proposalReference && (
                          <DescriptionList
                            label="Forwarder Reference"
                            description={proposalData.proposalReference}
                          />
                        )}
                        {proposalData.etd && (
                          <DescriptionList
                            label="Estimated Departure"
                            description={dayjs(proposalData.etd).format(
                              dateFormat2Const
                            )}
                          />
                        )}
                        {proposalData.eta && (
                          <DescriptionList
                            label="Estimated Arrival"
                            description={dayjs(proposalData.eta).format(
                              dateFormat2Const
                            )}
                          />
                        )}
                        {proposalData.vesselName && (
                          <DescriptionList
                            label="Vessel Name"
                            description={proposalData.vesselName}
                          />
                        )}
                        {proposalData.routing && (
                          <DescriptionList
                            label="Routing"
                            description={proposalData.routing}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              {proposalData.files.length > 0 && (
                <DocumentSection proposal={proposalData} />
              )}

              <div>
                <Typography.Title level={4} className="mx-0 my-1">
                  Cargo Owner Description
                </Typography.Title>
                <Alert
                  description={
                    shipment?.additionalInfo
                      ? shipment?.additionalInfo
                      : 'No description added for this shipment!'
                  }
                  className="cargo-description"
                />
              </div>

              {proposalData.note && (
                <div>
                  <Title level={4} className="mx-0 my-1">
                    Forwarders Note ({computeFullName(proposalData.assignee)})
                  </Title>
                  <DescriptionContent title="" content={proposalData.note} />
                </div>
              )}

              <CommentSection
                proposal={proposalData}
                onAddComment={onAddComment}
                isLoading={isLoadingComment}
              />
              {isActionEnabled && (
                <Row>
                  <Checkbox
                    checked={sendRejectionEmail}
                    onChange={onSendRejectionEmailChange}
                  >
                    Notify the declined forwarders by email.
                  </Checkbox>
                </Row>
              )}
              <Row justify="space-between">
                {!isViewer(shipment.currentCollaborator.accessLevel) &&
                  !isForwarder(shipment.currentUserRole) && (
                    <Col span="6">
                      <ProposalContractButton
                        shipment={shipment}
                        proposal={proposalData}
                        actionButton={{ isBlock: false, size: 'middle' }}
                      />
                    </Col>
                  )}
                {isActionEnabled && (
                  <Col span="12">
                    <ProposalActions
                      sendRejectionEmail={sendRejectionEmail}
                      shipment={shipment}
                      proposal={proposalData}
                      actionButton={{ isBlock: true, size: 'middle' }}
                    />
                  </Col>
                )}
                {isRevokeEnabled && (
                  <Col span="3">
                    <Button
                      type="primary"
                      size="middle"
                      onClick={onRevokeClick}
                      block={true}
                    >
                      Revoke
                    </Button>
                  </Col>
                )}
                {onReawardClick && isReawardEnabled && (
                  <Col span="3">
                    <Button
                      type="primary"
                      size="middle"
                      onClick={() => onReawardClick(proposalData)}
                      block={true}
                    >
                      Re-award
                    </Button>
                  </Col>
                )}
              </Row>
            </Space>
          </div>
        )
      )}
    </Modal>
  );
};

export default ProposalDetailsModal;
