import ContactFilter from 'app/components/ShipmentFilters/ContactListFilter';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React, { FC } from 'react';
import { IPurchaseOrderContactsFilterProps } from 'types/feature/purchase-orders.types';

const POCreatorFilter: FC<IPurchaseOrderContactsFilterProps> = ({
  onFilterChange,
  selectedFilters,
  allContacts,
}) => {
  return (
    <div>
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <ContactFilter
            layout="horizontal"
            defaultValue={selectedFilters?.creators}
            contacts={allContacts}
            onChange={(values) =>
              onFilterChange(values as string[], 'creators')
            }
          />
        }
        buttonText={'Creators'}
        itemKey={'creators'}
      />
    </div>
  );
};

export default POCreatorFilter;
