/* eslint-disable @typescript-eslint/no-explicit-any */
import { GCErrorLoggingConfig } from 'api/common/config';
import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorsLogger: StackdriverErrorReporter | any;
if (process.env.NODE_ENV === 'production') {
  // only report errors to GC when we are on production
  errorsLogger = new StackdriverErrorReporter();
  errorsLogger.start({
    version: 'v1',
    ...GCErrorLoggingConfig,
  });
} else {
  errorsLogger = { report: console.error };
}

export default errorsLogger;
