import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { getAuthToken, getExpiryDate } from '../utils/auth-storage';
import { API_URL } from './common/config';
import { refreshTokens } from './helper/auth.helper';

const ExpiryLimitTime = 5 * 60 * 1000; // 5 min
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    headers.set('x-timezone', userTimeZone);

    // Use token from local storage
    const token = getAuthToken();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithIntercptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const expiresIn = getExpiryDate();
  const currentDate = dayjs().valueOf();
  const token = getAuthToken();

  if (!token) {
    // case of chinese users
    await refreshTokens(api);
  }

  // if 5 mins is left before expiration of token
  if (expiresIn && expiresIn - currentDate < ExpiryLimitTime) {
    await refreshTokens(api);
  }

  // // first attempt
  let result = await baseQuery(args, api, extraOptions);

  // unauthorized
  if (result.error && result.error.status === 401) {
    await refreshTokens(api);
    // second attempt
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  baseQuery: baseQueryWithIntercptor,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'Shipments',
    'Tracking',
    'ShipmentTasks',
    'BookingParty',
    'Companies',
    'Forwarders',
    'ShipmentContainer',
    'ProposalsById',
    'Profile',
    'Search',
    'ShipmentProposals',
    'ShipmentsById',
    'Documents',
    'Team',
    'Collaborators',
    'PurchaseOrders',
    'Connections',
    'Bookings',
    'BookingsCount',
    'UserTableSetting',
    'PlacesSearch',
    'Contracts',
    'FileAccess',
    'FileByShipment',
    'InvoiceById',
    'InvoiceList',
    'InvoiceListByShipment',
    'InvoiceCollaborator',
    'InvoiceCollaboratorByShipment',
    'InvoiceComment',
    'InvoiceItemComment',
    'ShipmentTags',
    'CompanyShipmentTags',
    'CarriersByProposals',
    'ForwardersByProposals',
    'AppNotifications',
    'CompanySettings',
    'ShipmentCollaborators',
    'PurchaseOrderCollaborators',
    'PurchaseOrderComments',
    'BookingComments',
    'BookingCollaborators',
    'DocumentExtractor',
    'ShipmentBookingCollaborators',
    'InvoiceCount',
    'InvoicePendingReview',
    'invoiceOnHold',
    'InvoicePendingApproval',
    'InvoiceDashboard',
    'SuggestedProposalByShipment',
    'AllShipmentMessages',
    'ShipmentTagsByAI',
    'ApiKeySettings',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
