import { PaginatorParams } from 'types/feature/pagination.types';

import { ICarrierEntity } from './carrier.entity';
import { ICompanyEntity } from './company.entity';
import { CurrencyEnum, UnitMeasurementEnum } from './fee.entity';
import { ContainerTypeEnum } from './proposal.entity';
import {
  ILocation,
  IShipmentTypeDef,
  ShipmentTypeEnum,
} from './shipment.entity';
import { IUserEntity } from './user.entity';

export enum ServiceModeEnum {
  CY = 'cy',
  DOOR = 'door',
}

export interface IChargeHeadEntity {
  id?: string;
  description: string;
  cost: number;
  currency: CurrencyEnum;
  containerType?: ContainerTypeEnum;
  measurementUnit: UnitMeasurementEnum;
}
export interface ICostHeadEntity {
  id: string;
  title: string;
  origin: ILocation;
  destination: ILocation;
  charges: IChargeHeadEntity[];
  transitDays: number;
  serviceMode: string;
  rate?: number;
}
export interface ICreateCostHeadEntity
  extends Pick<
    ICostHeadEntity,
    | 'charges'
    | 'title'
    | 'origin'
    | 'destination'
    | 'rate'
    | 'serviceMode'
    | 'transitDays'
  > {
  id?: string;
}

export interface IContractEntity {
  id: string;
  reference: string;
  forwarder: ICompanyEntity;
  buyer: ICompanyEntity;
  contact: IUserEntity;
  paymentTerms: number;
  startDate: string;
  endDate: string;
  carrier: ICarrierEntity;
  costHeads: ICostHeadEntity[];
  serviceMode: string | null;
  containerType: ContainerTypeEnum;
  shipmentType: IShipmentTypeDef;
}

export interface IContractCreateEntity
  extends Omit<IContractEntity, 'costHeads'> {
  carrierId: string;
  contactId: string;
  costHeads: ICreateCostHeadEntity[];
}

export interface GetContractListParams {
  params: PaginatorParams & {
    search?: string;
    shipmentType?: ShipmentTypeEnum;
  };
}

export interface ICreateContractReq {
  contract: Partial<IContractEntity>;
  proposalId?: string;
}

export interface IMatchContractRatesReq {
  containerType?: ContainerTypeEnum;
  shipmentType: ShipmentTypeEnum;
  shipmentTypeId: string;
  cargoReadyDate: string;
  destination: ILocation;
  origin: ILocation;
  quantity?: number;
  weight?: number;
  volume?: number;
}

export interface IUpdateContractReq {
  params: {
    contractId: string;
  };
  body: Partial<IContractEntity>;
}
