import { IFileBaseEntity, IFileEntity } from 'types/entities/files.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const fileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllFiles: build.query<IFileEntity[], void>({
      query: () => `/file/files`,
    }),

    getFilesByShipment: build.query<IFileEntity[], string>({
      query: (id) => `/file/${id}`,
      transformResponse: (resp: ApiResponse<IFileEntity[]>) => resp?.data,
      providesTags: ['FileByShipment'],
    }),

    editFileById: build.mutation<IFileEntity, Partial<IFileEntity>>({
      query: (params: Partial<IFileEntity>) => ({
        url: `/file/${params.id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: ['Documents'],
    }),
    updateMultipleFilseById: build.mutation<IFileEntity, IFileBaseEntity[]>({
      query: (payload) => ({
        url: '/file',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteFileById: build.mutation<IFileEntity, string>({
      query: (id: string) => ({
        url: `/file/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
});

export const {
  useGetAllFilesQuery,
  useGetFilesByShipmentQuery,
  useLazyGetFilesByShipmentQuery,
  useDeleteFileByIdMutation,
  useEditFileByIdMutation,
  useUpdateMultipleFilseByIdMutation,
} = fileApi;
