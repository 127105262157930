import {
  IBookingPurchaseItemEntity,
  IDraftBookingEntity,
} from 'types/entities/booking.entity';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';
import { CreateBookingsReqBody } from 'types/feature/bookings.types';

export const mapDraftBookings: (
  bookings: IDraftBookingEntity[]
) => CreateBookingsReqBody = (bookings) => {
  const mappedDraftBookings = bookings
    .map((booking: IDraftBookingEntity) => ({
      id: booking.order,
      bookingId: booking.bookingId,
      purchaseOrderItems: booking.items
        .filter((item) => !item.bookingItemId)
        .map((item: IPurchaseOrderItemEntity) => ({
          id: item.id ?? '',
          quantity: item.toBook ?? 0,
        })),
    }))
    .filter((draft) => draft.purchaseOrderItems.length);

  const request: CreateBookingsReqBody = {
    purchaseOrders: mappedDraftBookings,
  };
  return request;
};

export const mapDraftBookingsItems: (
  bookings: IDraftBookingEntity[]
) => IBookingPurchaseItemEntity[] = (bookings) => {
  const mappedBooking = bookings.reduce(
    (booking: IDraftBookingEntity, curr: IDraftBookingEntity) => {
      const items = curr.items.filter((item) => item.bookingItemId) ?? [];
      if (items.length) booking.items.push(...items);
      return booking;
    },
    { items: [], bookingId: null, order: '', refernce: '' }
  );

  return mappedBooking.items.map((item) => ({
    id: item.bookingItemId ?? '',
    quantity: item.toBook ?? 0,
    purchaseOrderItem: {},
  }));
};
