import { IBookingEntity } from 'types/entities/booking.entity';
import { ServiceModeEnum } from 'types/entities/contract.entity';
import {
  CargoTypeEnum,
  IShipmentEntity,
  IShipmentTypeEntity,
  ModalityEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';

import { roundToPrecision } from 'utils/common';

const getShipmentType = (
  modality: ModalityEnum,
  shipmentTypes: IShipmentTypeEntity
) => {
  return shipmentTypes[modality].find(
    (st) =>
      st.title !== ShipmentTypeEnum.FCL && st.title !== ShipmentTypeEnum.FTL
  );
};

// map booking items to cargo units ( by CHW )
const mapBookingItemsToCargoUnits = (bookings: IBookingEntity[]) => {
  const cargoUnit = { weight: 0, volume: 0 };
  bookings.forEach((booking) => {
    const { bookingPurchaseOrders } = booking;
    return bookingPurchaseOrders.forEach((bp) => {
      const { purchaseOrderItems } = bp;
      return purchaseOrderItems.forEach((poi) => {
        cargoUnit.weight += roundToPrecision(poi.weight ?? 0);
        cargoUnit.volume += roundToPrecision(poi.volume ?? 0);
      });
    });
  });
  return [cargoUnit];
};
export const createDefaultShipmentFromBookings: (
  bookings: IBookingEntity[],
  shipmentTypes: IShipmentTypeEntity
) => Partial<IShipmentEntity> = (bookings: IBookingEntity[], shipmentTypes) => {
  const { origin, destination, cargoReadyDate, bookingPurchaseOrders } =
    bookings[0];

  const { modality, incoterms } = bookingPurchaseOrders[0].purchaseOrder;
  return {
    origin: { ...origin, id: undefined, serviceMode: ServiceModeEnum.DOOR },
    destination: {
      ...destination,
      id: undefined,
      serviceMode: ServiceModeEnum.DOOR,
    },
    cargoReadyDate,
    shipmentType: getShipmentType(modality, shipmentTypes),
    incoterms,
    cargoUnits: mapBookingItemsToCargoUnits(bookings),
    additionalInfo: `** Bookings list : ${bookings
      .map((booking) => `BK-${booking.slug} (${booking.reference ?? ''})`)
      .join(', ')}`,
    cargoType: CargoTypeEnum.GENERAL_CARGO,
  };
};
