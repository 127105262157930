import { notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import { useEffect, useState } from 'react';
import {
  ICargoUnit,
  LengthMeasurementUnitEnum,
  PackageTypeEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import {
  ICargoUnitsTypeState,
  IShipmentTypeState,
  ITotalCargoUnitState,
  IShipmentDetailsForm,
} from 'types/feature/create-shipment.types';

import { roundToPrecision } from 'utils/common';

import {
  CARGO_UNITS_INIT_STATE,
  DIMENSION_TOTAL_INIT_STATE,
  computeChargebaleWeight,
  computeShipmentTypeOptions,
  computeTotalWeight,
  convertToCBM,
  extractActiveShipmentType,
  formatTotalVolume,
  isByUnit,
  isDefaultShipmentTypeExist,
} from '../ShipmentCreationForm/shipment.utils';

const useShipmentDetailsForm = (
  form,
  defaultValues,
  defaultShipmentType,
  viewShipmentTypeAndCargoDetails,
  proposalParams,
  deleteCargoUnitMutation
) => {
  const [cargoUnitsType, setCargoUnitsType] = useState<ICargoUnitsTypeState>({
    byContainer: true,
    byUnit: false,
  });

  const [shipmentTypeOptions, setShipmentTypeOptions] = useState<
    IShipmentTypeState[]
  >([]);

  const [total, setTotal] = useState<ITotalCargoUnitState>(
    DIMENSION_TOTAL_INIT_STATE
  );

  // shipments types
  const { data: allShipmentTypesData, isLoading: isLoadingTypes } =
    useGetExternalShipmentsTypesQuery();

  const onDeleteCargoUnit = async (
    remove: (index: number | number[]) => void,
    index: number
  ) => {
    const cargoUnits = form.getFieldValue('cargoUnits');

    if (viewShipmentTypeAndCargoDetails && cargoUnits[index].id) {
      await deleteCargoUnitMutation({
        id: cargoUnits[index].id,
        params: proposalParams,
        shipmentId: defaultValues.shipmentId,
      })
        .unwrap()
        .then(
          () => {
            notification.success({
              message: 'Shipment item has been deleted successfully!',
            });
          },
          () => {
            notification.warning({
              message: 'Unable to delete this shipment item, please try again!',
            });
          }
        );
    }

    remove(index);
  };

  const computeShipmentType = (activeShipmentTypeId: string) => {
    const activeShipmentType = extractActiveShipmentType(
      shipmentTypeOptions,
      activeShipmentTypeId
    );

    switch (activeShipmentType?.title) {
      case ShipmentTypeEnum.FCL:
      case ShipmentTypeEnum.FTL:
        setCargoUnitsType({ byContainer: true, byUnit: false });
        break;
      default:
        setCargoUnitsType({
          byContainer: false,
          byUnit: isByUnit(defaultValues?.cargoUnits),
        });
        break;
    }
  };

  const computeCargoUnitValues = (
    cargoUnits: ICargoUnit[],
    changedValues?: ICargoUnit[]
  ) => {
    const shipmentTypeId = form.getFieldValue('shipmentTypeId');

    const activeShipmentType = extractActiveShipmentType(
      shipmentTypeOptions,
      shipmentTypeId
    )?.title;

    const newItems = cargoUnits.map((item: ICargoUnit, index: number) => {
      const shouldUpdateWeightPerValue =
        changedValues && !!changedValues[index]?.totalWeight;
      return {
        ...item,
        ...(shouldUpdateWeightPerValue
          ? {
              weight: roundToPrecision(
                (item.totalWeight ?? 0) / (item.quantity ?? 1)
              ),
            }
          : { weight: item.weight }),
        ...(item.quantity && item.weight && !shouldUpdateWeightPerValue
          ? {
              totalWeight: (item.quantity ?? 0) * (item.weight ?? 0),
            }
          : { totalWeight: item.totalWeight }),
        ...(item.volume && {
          chargeableWeight: computeChargebaleWeight(
            item.weight ?? 0,
            item.volume ?? 0,
            activeShipmentType
          ),
        }),
      };
    });

    const totalVolume = convertToCBM(formatTotalVolume(newItems));
    const totalWeight = computeTotalWeight(newItems, activeShipmentType);

    form.setFieldValue('cargoUnits', newItems);

    setTotal({
      volume: totalVolume,
      weight: totalWeight[0],
      charges: totalWeight[1],
    });
  };

  const resetItemsFields = (byUnits?: boolean) => {
    form.setFieldsValue({
      cargoUnits: [
        {
          ...CARGO_UNITS_INIT_STATE[0],
          packageType: byUnits ? PackageTypeEnum.PALLETS : undefined,
          lengthMeasurement: LengthMeasurementUnitEnum.CM,
        },
      ],
    });

    setTotal(DIMENSION_TOTAL_INIT_STATE);
  };

  const onShipmentTypeChange = (shipmentTypeIdValue: string) => {
    if (
      shipmentTypeIdValue === defaultShipmentType?.id &&
      defaultValues &&
      defaultValues.cargoUnits
    ) {
      computeCargoUnitValues(defaultValues.cargoUnits);
    } else {
      resetItemsFields();
    }
    computeShipmentType(shipmentTypeIdValue);
  };

  const onModalityChange = (modalityValue: string) => {
    resetItemsFields();
    const shipmentTypeOptions = computeShipmentTypeOptions(
      modalityValue,
      allShipmentTypesData
    );
    setShipmentTypeOptions(shipmentTypeOptions);
    form.setFieldValue('shipmentTypeId', shipmentTypeOptions[0].id);
  };

  const onCargoUnitTypeChange = (e: CheckboxChangeEvent) => {
    const shipmentTypeId = form.getFieldValue('shipmentTypeId');

    const byUnit = e.target.checked;
    setCargoUnitsType({
      ...cargoUnitsType,
      byUnit,
    });

    if (
      shipmentTypeId === defaultShipmentType?.id &&
      defaultValues.cargoUnits &&
      byUnit === isByUnit(defaultValues.cargoUnits)
    ) {
      computeCargoUnitValues(defaultValues.cargoUnits);
    } else {
      resetItemsFields(byUnit);
    }
  };

  useEffect(() => {
    if (allShipmentTypesData) {
      form.setFieldValue('modality', defaultValues.modality);
      setShipmentTypeOptions(
        computeShipmentTypeOptions(defaultValues.modality, allShipmentTypesData)
      );
    }
  }, [allShipmentTypesData, defaultValues.modality]);

  useEffect(() => {
    if (shipmentTypeOptions.length > 0) {
      const isDefaultExist =
        defaultShipmentType &&
        isDefaultShipmentTypeExist(defaultShipmentType, shipmentTypeOptions);

      const shipmentTypeId = isDefaultExist
        ? defaultShipmentType?.id
        : shipmentTypeOptions[0].id;

      if (isDefaultExist && defaultValues.cargoUnits) {
        computeCargoUnitValues(defaultValues.cargoUnits);
      }

      form.setFieldValue('shipmentTypeId', shipmentTypeId);

      computeShipmentType(shipmentTypeId);
    }
  }, [shipmentTypeOptions, defaultShipmentType?.id]);

  const onShipmentFormValueChange = (
    changedValues: Partial<IShipmentDetailsForm>,
    values: IShipmentDetailsForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];

    switch (fieldName) {
      case 'cargoUnits':
        if (values.cargoUnits) {
          computeCargoUnitValues(values.cargoUnits, changedValues.cargoUnits);
        }
        break;

      case 'modality':
        onModalityChange(values.modality);
        break;

      case 'shipmentTypeId':
        if (values.shipmentTypeId) {
          onShipmentTypeChange(values.shipmentTypeId);
        }
        break;
    }
  };

  // Rest of the logic goes here...

  return {
    cargoUnitsType,
    shipmentTypeOptions,
    allShipmentTypesData,
    isLoadingTypes,
    total,
    onShipmentFormValueChange,
    onCargoUnitTypeChange,
    onDeleteCargoUnit,
  };
};

export default useShipmentDetailsForm;
