import {
  IAllShipmentsLocation,
  IDeleteShipmentItem,
  IDuplicateShipment,
  IShipmentEntity,
  IShipmentTypeEntity,
} from 'types/entities/shipment.entity';
import {
  ICreateShipmentResponseBody,
  IShipmentsProposalRequest,
  IShipmentsRequestBody,
  IShipmentsResponseBody,
  IUpdateShipmentRequestBody,
  IUploadShipmentDocsResponseBody,
} from 'types/feature/shipments.types';

import { api } from './base-api';

// example of endpoints injection
export const shipmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateShipmentsFilters: build.mutation<
      IShipmentsResponseBody,
      IShipmentsRequestBody
    >({
      query: (payload) => ({
        url: `/shipment/filters`,
        method: 'POST',
        params: { ...payload.params },
        body: { ...payload.body },
      }),
      invalidatesTags: ['Shipments'],
    }),
    updateShipmentsSearch: build.mutation<
      IShipmentsResponseBody,
      IShipmentsRequestBody
    >({
      query: (payload) => ({
        url: `/shipment/search-shipment-list`,
        method: 'POST',
        params: { ...payload.params },
        body: { ...payload.body },
      }),
      invalidatesTags: ['Shipments'],
    }),
    getShipmentProposals: build.query<
      IShipmentsResponseBody,
      IShipmentsProposalRequest
    >({
      query: (payload) => ({
        url: `/shipment/proposals/list`,
        method: 'GET',
        params: payload,
      }),
    }),
    getAllShipmentsLocation: build.query<IAllShipmentsLocation, void>({
      query: () => ({
        url: '/shipment/locations',
      }),
    }),

    getShipmentsBySlug: build.query<IShipmentEntity, string>({
      query: (slug) => `/shipment/${slug}`,
      providesTags: ['ShipmentsById'],
    }),

    editShipment: build.mutation<IShipmentEntity, IUpdateShipmentRequestBody>({
      query: (payload) => ({
        url: `/shipment/${payload.shipmentId}`,
        method: 'PUT',
        body: payload.shipment,
      }),
      invalidatesTags: ['ShipmentsById', 'ShipmentProposals'],
    }),

    getShipmentType: build.query<IShipmentEntity, string>({
      query: (id) => `/shipment-types/${id}`,
    }),
    getShipmentsTypes: build.query<IShipmentTypeEntity, void>({
      query: () => '/shipment-types',
    }),
    downloadShipmentExcelSheet: build.mutation<
      { data: Iterable<number> },
      { shipmentIds: string[] }
    >({
      query: (body) => ({
        url: `/shipment/download`,
        method: 'POST',
        body,
        responseType: 'arraybuffer',
        contentType: 'blob',
      }),
    }),
    createShipment: build.mutation<
      ICreateShipmentResponseBody,
      {
        body: Partial<IShipmentEntity>;
        params: { contractId?: string } | undefined;
      }
    >({
      query: ({ body, params }) => ({
        url: '/shipment',
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: ['Shipments'],
    }),

    createProxyShipment: build.mutation<
      ICreateShipmentResponseBody,
      Partial<IShipmentEntity>
    >({
      query: (shipment) => ({
        url: '/shipment/proxy',
        method: 'POST',
        body: shipment,
      }),
      invalidatesTags: ['Shipments'],
    }),

    uploadShipmentDocument: build.mutation<
      IUploadShipmentDocsResponseBody[],
      FormData
    >({
      query: (body) => ({
        url: '/shipment/upload',
        method: 'POST',
        formData: true,
        body,
      }),
      invalidatesTags: ['Shipments', 'Documents'],
    }),

    deleteShipmentById: build.mutation<IShipmentEntity, string>({
      query: (id) => ({
        url: `/shipment/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteShipmentsByIds: build.mutation<IShipmentEntity, string[]>({
      query: (shipmentIds) => ({
        url: `/shipment/bulk`,
        body: { shipmentIds },
        method: 'DELETE',
      }),
    }),
    deleteShipmentItemById: build.mutation<void, IDeleteShipmentItem>({
      query: ({ id, shipmentId }) => ({
        url: `/shipment-items/${shipmentId}/${id}`,
        method: 'DELETE',
      }),
    }),
    duplicateShipmentById: build.mutation<IShipmentEntity, IDuplicateShipment>({
      query: (payload) => ({
        url: `/shipment/duplicate/${payload.id}`,
        method: 'POST',
        body: payload.shipment,
      }),
    }),
  }),
});

export const {
  useGetAllShipmentsLocationQuery,
  useLazyGetShipmentProposalsQuery,
  useUpdateShipmentsFiltersMutation,
  useUpdateShipmentsSearchMutation,
  useEditShipmentMutation,
  useGetShipmentsBySlugQuery,
  useLazyGetShipmentsBySlugQuery,
  useGetShipmentsTypesQuery,
  useCreateShipmentMutation,
  useCreateProxyShipmentMutation,
  useUploadShipmentDocumentMutation,
  useDeleteShipmentByIdMutation,
  useDuplicateShipmentByIdMutation,
  useDeleteShipmentItemByIdMutation,
  useDownloadShipmentExcelSheetMutation,
  useDeleteShipmentsByIdsMutation,
} = shipmentsApi;
