/* eslint-disable */
import Icon, { FileExcelFilled, FilePdfFilled } from '@ant-design/icons';
import {
  Alert,
  Button,
  Modal,
  Select,
  Spin,
  Typography,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd';
import { useGetCompaniesQuery } from 'api/company';
import { useCreateOrderFromFileMutation } from 'api/freight-ai';
import { useUploadOrderFileMutation } from 'api/purchase-order';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { ICompanyConnectionEntity } from 'types/entities/company.entity';
import {
  IPOParsedDocumentEntity,
  IPurchaseOrderEntity,
  POFileType,
} from 'types/entities/purchase-order.entity';
import { UserConnectionTypes } from 'types/entities/user.entity';

import { purchaseOrderPdfUploadEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera-icon-filled.svg';
import { ReactComponent as PdfDocFilled } from '../../../../assets/pdf-filled.svg';
import './style.scss';
import { mapJSONToPurchaseOrder } from './utils/upload-order-file.helper';

const { Text } = Typography;
const { Dragger } = Upload;

const computeCompanyOptions = (companies: ICompanyConnectionEntity[]) => {
  return companies?.map((company) => ({
    label: company.partner.name,
    value: company.partner.id,
  }));
};
interface IUploadOrderFileModalprops {
  onClickShowModal: () => void;
  fileType: POFileType;
  onParseBookingSuccess?: (extractedData: IPurchaseOrderEntity) => void;
}
const UploadOrderFileModal: React.FC<IUploadOrderFileModalprops> = ({
  onClickShowModal,
  fileType,
  onParseBookingSuccess,
}) => {
  const isExcel = fileType === POFileType.XLSX;

  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const [uploadedFile, setUploadedFile] = useState<string | undefined>(
    undefined
  );
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [uploadDocument] = useUploadOrderFileMutation();
  const {
    data: suppliers,
    isLoading,
    isError: isCompanyListError,
  } = useGetCompaniesQuery({ role: UserConnectionTypes.SUPPLIER });
  const [
    createOrder,
    { data: extractedData, isLoading: isExtracting, isSuccess, isError, error },
  ] = useCreateOrderFromFileMutation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onClickShowModal();
  };

  const uploadOrderFile = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('file', file);

    uploadDocument(formdata)
      .unwrap()
      .then((payload) => {
        onSuccess();
        setUploadedFile(payload[0]);

        purchaseOrderPdfUploadEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        });
      })
      .catch((error) => onError());
  };

  const onConfirm = async () => {
    if (uploadedFile && !!selectedCompany && user) {
      await createOrder({
        fileLocation: uploadedFile,
        supplier: selectedCompany,
        fileType,
        companyId: user?.companyId,
      }).unwrap();
      handleCancel();
    }
  };

  const onParseSuccess = (extractedData: IPOParsedDocumentEntity) => {
    try {
      const PO = mapJSONToPurchaseOrder(extractedData);

      if (onParseBookingSuccess) {
        onParseBookingSuccess(PO as IPurchaseOrderEntity);
      } else {
        navigate('/purchase-orders/create', {
          state: {
            order: PO,
            files: !isExcel ? [extractedData?.file_url, uploadedFile] : null,
          },
        });
      }
    } catch (error) {
      notification.error({ message: 'Error while parsing file !' });
    }
  };

  useEffect(() => {
    if (isSuccess && extractedData) {
      onParseSuccess(extractedData);
    }
  }, [isSuccess, extractedData]);

  useEffect(() => {
    if (isError) {
      displayErrors(error, {
        title: 'Error while extracting data from PO file !',
      });
    }
  }, [isError]);

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    customRequest: uploadOrderFile,
    onRemove: (file) => {
      setUploadedFile(undefined);
    },
    onChange(info: any) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Button
        size="large"
        icon={
          isExcel ? (
            <FileExcelFilled className="xcl-file" />
          ) : (
            <FilePdfFilled className="pdf-file" />
          )
        }
        onClick={showModal}
      >
        Upload {isExcel ? 'Excel' : 'PDF'}
      </Button>
      <Modal
        title="Upload Document"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            onClick={onConfirm}
            disabled={!uploadedFile || isExtracting || !selectedCompany}
            icon={isExtracting ? <Spin size="small" /> : null}
          >
            Create Order
          </Button>,
        ]}
        width={450}
        destroyOnClose
      >
        <Select
          placeholder={'Select your supplier'}
          loading={isLoading}
          options={computeCompanyOptions(suppliers?.data.items ?? [])}
          onSelect={(value, option) => setSelectedCompany(option.label)}
          style={{ width: '100%', paddingBottom: '10px' }}
        />
        {!selectedCompany && (
          <Alert
            message={
              'Make sure to select a supplier in order to create your PO !'
            }
            type="info"
            showIcon
          />
        )}
        <div className="upload-document-wrapper">
          <Dragger {...props}>
            <div className="upload-file-wrapper">
              <Icon className="camera-icon" component={CameraIcon} />
              <Text className="upload-lebel">Attach Purchase Order</Text>
            </div>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default UploadOrderFileModal;
