import { Button, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import useShipmentCreation from 'app/components/ShipmentCreation/ShipmentCreationForm/UseShipmentCreation';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC, useEffect, useState } from 'react';
import {
  IAIFormScreenProps,
  ShipmentCreationScreenEnum,
} from 'types/feature/shipment-onboarding';

import { ReactComponent as EnterInfo } from '../../../../assets/icons/enter-info-Icons.svg';

const AIFormScreen: FC<IAIFormScreenProps> = ({ onScreenChange }) => {
  const [shipmentText, setShipmentText] = useState<string>('');

  const { defaultShipment, onExtractShipmentFromText, isExtracting } =
    useShipmentCreation();

  const navigateToShipmentFormScreen = () =>
    onScreenChange(ShipmentCreationScreenEnum.SHIPMENT_FORM_SCREEN, {
      extractedShipment: defaultShipment,
    });

  const handleExtractShipment = () => onExtractShipmentFromText(shipmentText);

  useEffect(() => {
    if (defaultShipment) {
      navigateToShipmentFormScreen();
    }
  }, [defaultShipment]);

  useEffect(() => {
    return () => {
      setShipmentText('');
    };
  }, []);

  return (
    <div className="sh-ai-form-screen">
      <ModalHeader
        title="Create a Shipment"
        subTitle="Copy and paste a recent shipment email, and Beebolt’s AI will enter the details for you."
      />
      <div className="sh-ai-form-screen__content">
        <TextArea
          value={shipmentText}
          onChange={(event) => setShipmentText(event.target.value)}
          autoSize={{ minRows: 5, maxRows: 50 }}
          placeholder="Copy and paste a shipment email, eg: 3 x 40' HC from Algiers to Dubai, Cargo is ready now, FOB, FAK. "
        />
        <Row justify="end" style={{ marginTop: '10px' }}>
          <Button
            onClick={handleExtractShipment}
            type="primary"
            disabled={!shipmentText}
            data-chameleon="submit-ai-shipment-form"
            loading={isExtracting}
          >
            Confirm
          </Button>
        </Row>
      </div>
      <div className="sh-ai-form-screen__content">
        <Typography.Paragraph>Or</Typography.Paragraph>
        <Button
          type="default"
          data-chameleon="submit-ai-shipment-form"
          icon={<EnterInfo />}
          onClick={navigateToShipmentFormScreen}
        >
          Enter information manually
        </Button>
      </div>
    </div>
  );
};

export default AIFormScreen;
