import { Tooltip, Typography } from 'antd';
import React, { FC } from 'react';
import { IShipementTags } from 'types/entities/metadata.entity';

import './style.scss';

const ShipmentTagsDisplay: FC<{
  tags: IShipementTags[] | undefined;
  maxNumber: number;
}> = ({ tags, maxNumber = 3 }) => {
  return (
    <div className="slug-tags-container">
      {/* displaying the first three items only */}
      {tags
        ?.slice()
        .sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1))
        .slice(0, maxNumber)
        .map((item) => (
          <Tooltip
            arrow={false}
            placement="bottom"
            title={
              <Typography.Text type="secondary" className="tooltip-text">
                {`${item.companyMetadata.metadataKey} : ${item.metadataValue}`}
              </Typography.Text>
            }
            color="#F7FAFF"
            key={item.companyMetadata.metadataKey}
          >
            <div
              style={{
                backgroundColor: item?.companyMetadata?.colorCode,
              }}
              className="slug-tag"
            />
          </Tooltip>
        ))}
    </div>
  );
};

export default ShipmentTagsDisplay;
