import Icon from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import {
  ICostHeadEntity,
  ServiceModeEnum,
} from 'types/entities/contract.entity';

import { formatServiceMode } from 'utils/format-fields';

import { ReactComponent as ShipIcon } from '../../../../assets/icons/boat-icon.svg';
import { ReactComponent as BuildingIcon } from '../../../../assets/icons/building-icon.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/icons/truck-icon.svg';
import { ReactComponent as ExchangeArrowsIcon } from '../../../../assets/icons/two-arrows-icon.svg';

export const renderCoastHeadIcon = (coastHead: ICostHeadEntity) => {
  let icon = TruckIcon;
  const serviceMode = coastHead.serviceMode;

  switch (serviceMode) {
    case 'cy/cy':
      icon = ShipIcon;
      break;
    case 'door/cy':
      icon = BuildingIcon;
      break;
    case 'cy/door':
      icon = TruckIcon;
      break;
  }

  return icon;
};

export const renderServiceMode = (serviceMode: string) => {
  const modes = serviceMode.split('/') as ServiceModeEnum[];
  if (modes.length < 2) return '__/__'; // this should never happen
  return (
    <Space>
      {formatServiceMode(modes[0])} <Icon component={ExchangeArrowsIcon} />
      {formatServiceMode(modes[1])}
    </Space>
  );
};

export const isServiceModeAvailable = (
  originServiceMode: ServiceModeEnum | null,
  destinationServiceMode: ServiceModeEnum | null
) => !!(originServiceMode && destinationServiceMode);
