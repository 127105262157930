import { ButtonType } from 'antd/es/button';
import { IContainerEntity } from 'types/entities/container.entity';
import { ShipmentTypeEnum } from 'types/entities/shipment.entity';

export enum ContainerModalTypeEnum {
  CONTAINER = 'CONTAINER',
  MBL = 'MBL',
  BOTH = 'BOTH',
}

export enum ContainerStatusEnum {
  IN_ORIGIN = 'in_origin',
  IN_TRANSIT = 'in_transit',
  IN_DESTINATION = 'in_destination',
  IN_CUSTOMS = 'in_customs',
  DELIVERED = 'delivered',
}

export interface IContainerFormData {
  number: string;
  carrierId: string;
  containerType: string;
}
export interface IBaseContainerModalProps {
  actionButton?: {
    className?: string;
    style?: React.CSSProperties;
    text?: string;
    type?: ButtonType;
    icon?: JSX.Element;
  };
  refetch?: () => void;
}

export interface IAddContainerModalProps extends IBaseContainerModalProps {
  shipmentType: ShipmentTypeEnum;
  defaultData?: IContainerFormData;
  shipmentId: string;
  shipmentSlug: string;
  containerModalType: ContainerModalTypeEnum;
  showConfirmModal: boolean;
}

export interface IEditContainerModalProps extends IBaseContainerModalProps {
  container: IContainerEntity;
  shipmentId: string;
  index: number;
}
