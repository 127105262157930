import { IShipementTags } from 'types/entities/metadata.entity';

export const shipmentTagOptions = (tagsData: IShipementTags[]) =>
  tagsData.map((tag) => ({
    label: `${tag.companyMetadata.metadataKey}: ${tag.metadataValue}`,
    value: tag.id,
  }));

export const extractTagColor = (
  tagsData: IShipementTags[],
  currentValue: string
) => tagsData.find((tag) => tag.id === currentValue)?.companyMetadata.colorCode;

export const extractTagIds = (tagsData: IShipementTags[]) =>
  tagsData.map((tag) => tag.id);
