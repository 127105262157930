import {
  AutoComplete,
  Space,
  Typography,
  Input,
  Button,
  notification,
  Spin,
} from 'antd';
import { useAddCompanyMutation, useSearchCompanyQuery } from 'api/company';
import React, {
  FC,
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';

import { debounce } from 'utils/debounce';
import { displayErrors } from 'utils/error-notification';

interface CompanyFieldProps {
  userRole?: string;
  handleSelectAcompany: (value: string, id: string | undefined) => void;
}
const CompanyField: FC<CompanyFieldProps> = ({
  handleSelectAcompany,
  userRole,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const deferredQuery = useDeferredValue(inputValue);

  const [companies, setCompanies] = useState<
    { label: string; value: string | undefined }[]
  >([]);

  // state
  const user = useSelector((state: RootState) => state.user.user);

  // TODO do we need default fetch
  const {
    isFetching: isSearching,
    data: companyData,
    isSuccess,
  } = useSearchCompanyQuery(deferredQuery, {
    skip: !deferredQuery,
  });

  // create new company
  const [
    addCompany,
    {
      isLoading: isCreating,
      isSuccess: isCreated,
      isError: isCreationFailed,
      error: creationError,
      data: createdCompany,
    },
  ] = useAddCompanyMutation();

  // methods
  const createNewCompany = async () => {
    if (!userRole) {
      notification.warning({
        message: 'Please select role before adding new company!',
      });
      return;
    }

    if (!user?.companyId) {
      notification.warning({ message: 'Your company infos are not set!' });
      // TODO add navigation to profile page
    } else {
      await addCompany({
        company: { companyName: inputValue, role: userRole },
        partnerId: user?.companyId,
      });
    }

    setInputValue('');
  };

  useEffect(() => {
    if (isCreationFailed) {
      displayErrors(creationError, { title: 'Error on creating company' });
    }
  }, [isCreationFailed, creationError]);

  // hooks
  const setOptions = useCallback(() => {
    setCompanies(
      companyData?.map((companyConnection) => {
        return {
          key: companyConnection.id,
          label: companyConnection.name,
          value: companyConnection.name,
        };
      }) ?? []
    );
  }, [companyData]);

  useEffect(() => {
    if (isSuccess) {
      setOptions();
    }
  }, [isSuccess, setOptions]);

  useEffect(() => {
    if (isCreated) {
      notification.success({
        message: 'The company was successfully added to your connections!',
      });

      if (createdCompany)
        handleSelectAcompany(createdCompany?.name, createdCompany?.id);
    }
    // eslint-disable-next-line
  }, [isCreated, createdCompany]);

  const onSelectCompany = (value: string) => {
    const companyId = companyData?.find((item) => item.name === value)?.id;
    handleSelectAcompany(value, companyId);
  };

  const debouncedHandler = debounce((value: string) => {
    if (value === '') return;
    setInputValue(value);
  }, 700);

  const handleInputOnChange = useCallback(
    (value: string) => debouncedHandler(value),
    // eslint-disable-next-line
    []
  );

  return (
    <AutoComplete
      placeholder="Search company or add one ..."
      data-testid="collaborator-company-input"
      onSelect={onSelectCompany}
      onSearch={handleInputOnChange}
      options={[
        ...companies,
        {
          value: undefined,
          label: isSearching ? (
            <Spin size="small"> searching ...</Spin>
          ) : (
            <Space direction="vertical">
              <Typography.Text disabled>New Company ?</Typography.Text>
              <Space>
                <Input value={inputValue} contentEditable={false} />
                <Button
                  type="primary"
                  onClick={() => createNewCompany()}
                  disabled={isCreating || !inputValue.length}
                >
                  Add
                </Button>
              </Space>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default CompanyField;
