import { Button, Col, Row, Spin, Typography } from 'antd';
import { useGetInvoiceByIdQuery } from 'api/invoice';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { InvoiceTableViewEnum } from 'types/feature/invoice.types';

import { invoiceStepChange, invoicesViewEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { isAdmin } from 'utils/shipment-helper';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import InvoiceMatching from './InvoiceMatching';
import InvoiceTimeline from './InvoiceTimeline';
import ProposalMatching from './ProposalMatching';
import ShareInvoice from './ShareInvoice';
import './invoice.scss';

const Invoice: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(1);
  const { id: invoiceId = '', slug = '' } = useParams();
  const user = useSelector((state: RootState) => state.user.user);
  const executedOnceRef = useRef(false);

  const {
    data: invoiceData,
    isLoading,
    isError,
  } = useGetInvoiceByIdQuery(invoiceId, {
    skip: !invoiceId,
  });

  const {
    data: shipmentData,
    isLoading: isShipmentLoading,
    isError: isShipmentError,
    error: shipmentError,
  } = useGetShipmentsBySlugQuery(slug);

  useEffect(() => {
    if (shipmentError && isShipmentError) {
      displayErrors(shipmentError, { title: 'Failed To Fetch Shipment' });
    }
  }, [isShipmentError, shipmentError]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      invoicesViewEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        invoice_id: invoiceId,
        shipment_id: invoiceData?.data?.shipmentId || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (invoiceData && user && !executedOnceRef.current) {
      if (invoiceData.data.createdById !== user.id && isAdmin(user.userRole)) {
        setActiveStep(3);
        // Set the flag to true to ensure this code block runs only once
        executedOnceRef.current = true;
      }
    }
  }, [user, invoiceData]);

  const onStepChange = (step: number) => {
    const tableViews = Object.values(InvoiceTableViewEnum);
    const current_step = tableViews[activeStep - 1];
    const next_step = tableViews[step - 1];

    if (user && Object.keys(user).length > 0) {
      invoiceStepChange({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        invoice_id: invoiceId,
        shipment_id: invoiceData?.data?.shipmentId || '',
        current_step,
        next_step,
      });
    }
    setActiveStep(step);
  };

  const onCloseClick = () => {
    navigate(`/shipments/${slug}/invoice`);
  };

  const renderTimelineScreens = () => {
    if (invoiceData && shipmentData) {
      switch (activeStep) {
        case 1:
          return (
            <InvoiceMatching
              invoice={invoiceData.data}
              onStepChange={onStepChange}
            />
          );

        case 2:
          return (
            <ProposalMatching
              invoice={invoiceData.data}
              onStepChange={onStepChange}
              proposalCurrency={shipmentData.createdBy.company.currency}
            />
          );
        case 3:
          return (
            <ShareInvoice
              invoice={invoiceData.data}
              shipment={shipmentData}
              onStepChange={onStepChange}
            />
          );
      }
    }
    return <></>;
  };

  if (isError) {
    return (
      <Row
        justify="center"
        align="middle"
        className="invoice-error-screen-wrapper"
      >
        <Col>
          <Typography.Paragraph>No Invoice Available!</Typography.Paragraph>
        </Col>
      </Row>
    );
  }

  return (
    <DashboardCard noMargin fitContent className="invoice-wrapper">
      <Button
        type="link"
        icon={<CloseIcon />}
        className="close-icob-button"
        onClick={onCloseClick}
      />
      <InvoiceTimeline
        activeStep={activeStep}
        items={[
          {
            step: 0,
            title: 'Parsing',
            subTitle: 'Get data from invoice pdf file',
          },
          {
            step: 1,
            title: 'Pdf matching',
            subTitle: 'Match the data retrieve from pdf file',
          },
          {
            step: 2,
            title: 'Proposal matching',
            subTitle: 'Match invoice with the shipment proposal',
          },
          {
            step: 3,
            title: 'Save & Share',
            subTitle: 'Save the invoice & share it with collaborators',
          },
        ]}
      />
      {isLoading && isShipmentLoading ? (
        <Row justify="center" align="middle" style={{ height: '60vh' }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      ) : (
        renderTimelineScreens()
      )}
    </DashboardCard>
  );
};

export default Invoice;
