import AddForwarderFormCompact from 'app/components/ShipmentRequestQuote/AddForwarderForm/AddForwarderFormCompact';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  InviteForwarderScreenProps,
  ShipmentCreationScreenEnum,
} from 'types/feature/shipment-onboarding';

const InviteForwarderScreen: FC<InviteForwarderScreenProps> = ({
  onScreenChange,
}) => {
  const createShipmentState = useSelector(
    (state: RootState) => state.createShipment
  );

  const onNavigateToShipmentPage = () => {
    onScreenChange(ShipmentCreationScreenEnum.BIDDING_FORM_SCREEN);
  };

  return (
    <div>
      <ModalHeader
        title="Invite Forwarders"
        subTitle="Forwarders will receive an email inviting them to submit quotes for your shipment."
      />
      {createShipmentState.createdShipmentResp && (
        <AddForwarderFormCompact
          shipmentId={createShipmentState.createdShipmentResp.id}
          onNavigateToShipmentPage={onNavigateToShipmentPage}
        />
      )}
    </div>
  );
};

export default InviteForwarderScreen;
