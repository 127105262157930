import {
  ICreateBookingCommentPayload,
  IGetBookingCommentPayload,
} from 'types/entities/booking-party';
import { ICommentEntity } from 'types/entities/comment.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const bookingCommentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookingCommentsById: build.query<
      ApiResponse<{ comments: ICommentEntity[] }>,
      IGetBookingCommentPayload
    >({
      query: ({ bookingId }) => ({
        url: `/booking-comments/${bookingId}`,
        method: 'GET',
      }),
      providesTags: ['BookingComments'],
    }),

    createBookingComment: build.mutation<
      ApiResponse<ICommentEntity>,
      ICreateBookingCommentPayload
    >({
      query: (body) => ({
        url: `/booking-comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BookingComments'],
    }),
  }),
});

export const {
  useGetBookingCommentsByIdQuery,
  useLazyGetBookingCommentsByIdQuery,
  useCreateBookingCommentMutation,
} = bookingCommentsApi;
