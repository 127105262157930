import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Spin, Tag, message } from 'antd';
import { useUpdateInvoiceByIdMutation } from 'api/invoice';
import {
  computeInvoiceStatusLabel,
  getAllowedInvoiceStatuses,
} from 'app/components/Invoice/InvoiceStatusSwitch/helpers/helper';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  InvoiceStatusEnum,
  InvoiceStatusSwitchProps,
} from 'types/feature/invoice.types';

import { updateInvoiceStatusEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import DeclineInvoiceModal from '../DeclineInvoiceModal';
import './task-switch.scss';

const InvoiceStatusSwitch: FC<InvoiceStatusSwitchProps> = ({
  invoice,
  updateInvoicesArgs,
  getUpdatedInvoices,
}) => {
  const [updateInvoice, { isLoading }] = useUpdateInvoiceByIdMutation();
  const [isDeclineOpen, setIsDeclineOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.user);

  const handleChangeStatus = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
  };

  // eslint-disable-next-line
  const handleStatusUpdated = async (status: any) => {
    if (status.key === 'declined') {
      setIsDeclineOpen(true);
    } else {
      try {
        await updateInvoice({
          id: invoice.id,
          invoice: {
            status: status?.key,
          },
        }).unwrap();
        message.success('Invoice status changed successfuly');

        updateInvoiceStatusEvent({
          user_id: user?.id || '',
          email: user?.email || '',
          company: user?.company?.name || '',
          company_id: user?.companyId || '',
          invoice_id: invoice.id,
          shipment_id: invoice.shipmentId,
          status: status?.key,
        });
        if (updateInvoicesArgs && getUpdatedInvoices) {
          getUpdatedInvoices(updateInvoicesArgs);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'Error on updating Invoice Status' });
      }
    }
  };

  const computeTagColor = () => {
    switch (invoice.status) {
      case InvoiceStatusEnum.DRAFT:
        return 'default';

      case InvoiceStatusEnum.IN_REVIEW:
        return 'orange';

      case InvoiceStatusEnum.ACCEPTED:
        return 'green';

      case InvoiceStatusEnum.DECLINED:
        return 'red';

      case InvoiceStatusEnum.PAID:
        return 'blue';

      default:
        return 'default';
    }
  };

  const renderDeclineModal = () => {
    return (
      <DeclineInvoiceModal
        isModalOpen={isDeclineOpen}
        invoice={invoice}
        setIsDeclineOpen={setIsDeclineOpen}
        getUpdatedInvoices={getUpdatedInvoices}
        updateInvoicesArgs={updateInvoicesArgs}
      />
    );
  };
  const allowedInvoiceStatuses = getAllowedInvoiceStatuses(invoice);

  return (
    <>
      <Dropdown
        arrow={false}
        disabled={isLoading || allowedInvoiceStatuses.length < 1}
        menu={{
          items: allowedInvoiceStatuses,
          onClick: handleStatusUpdated,
        }}
      >
        <Spin indicator={<LoadingOutlined spin />} spinning={isLoading}>
          <Tag
            className="task-tag"
            color={computeTagColor()}
            closable={allowedInvoiceStatuses.length > 1}
            onClose={handleChangeStatus}
            closeIcon={<DownOutlined />}
            onClick={handleChangeStatus}
          >
            {computeInvoiceStatusLabel(invoice.status)}
          </Tag>
        </Spin>
      </Dropdown>
      {renderDeclineModal()}
    </>
  );
};

export default InvoiceStatusSwitch;
