import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const timeFormat = 'HH:00';
const dateFormat = 'YYYY/MM/DD';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Calculate the date for yesterday
  const yesterday = dayjs().subtract(1, 'day').endOf('day');

  // Disable dates before yesterday
  return current && current < yesterday;
};

export const biddingDateHelper = {
  disabledDate,
  timeFormat,
  dateFormat,
};

export const isBiddingEnded = (date: string | null) => {
  if (date === null) {
    return false;
  }

  const minutesLeft = dayjs(date).diff(dayjs(), 'minute');
  return minutesLeft <= 0;
};
