import {
  IBookingCollaboratorCreateBody,
  IBookingCollaboratorUpdateBody,
} from 'types/entities/booking.entity';
import { IBookingCollaborators } from 'types/feature/bookings.types';

import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const bookingCollaboratorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookingCollaboratorsById: build.query<
      ApiResponse<IEntityList<IBookingCollaborators>>,
      { bookingId: string; search?: string; withCollaborators?: boolean }
    >({
      query: ({ bookingId, ...payload }) => ({
        url: `/booking/collaborators/${bookingId}`,
        params: payload,
        method: 'GET',
      }),
      providesTags: ['BookingCollaborators'],
    }),
    getBookingsCollaboratorsByShipmentId: build.query<
      string[],
      { shipmentId: string; search?: string }
    >({
      query: ({ shipmentId, ...payload }) => ({
        url: `/booking/collaborators/shipment/${shipmentId}`,
        params: payload,
        method: 'GET',
      }),
      providesTags: ['ShipmentBookingCollaborators'],
      transformResponse: (
        response: ApiResponse<IEntityList<{ collaboratorId }>>
      ) => response.data.items.map((item) => item.collaboratorId),
    }),

    createBookingCollaborator: build.mutation<
      string[],
      IBookingCollaboratorCreateBody
    >({
      query: (body) => ({
        url: `/booking/collaborators`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BookingCollaborators'],
    }),
    updateBookingCollaborator: build.mutation<
      string[],
      IBookingCollaboratorUpdateBody
    >({
      query: ({ bookingId, ...rest }) => ({
        url: `/booking/collaborators/${bookingId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['BookingCollaborators'],
    }),
    deleteBookingCollaboratorById: build.mutation<
      void,
      { bookingId: string; collaboratorId: string }
    >({
      query: ({ bookingId, collaboratorId }) => ({
        url: `/booking/collaborators/${bookingId}/${collaboratorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BookingCollaborators'],
    }),
  }),
});

export const {
  useGetBookingCollaboratorsByIdQuery,
  useCreateBookingCollaboratorMutation,
  useUpdateBookingCollaboratorMutation,
  useDeleteBookingCollaboratorByIdMutation,
  useLazyGetBookingsCollaboratorsByShipmentIdQuery,
} = bookingCollaboratorsApi;
