import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Spin,
  Row,
  Col,
  Select,
  InputNumber,
  Button,
  Checkbox,
  Space,
  Radio,
  Typography,
  Input,
  notification,
  Divider,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useState } from 'react';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import {
  ICargoUnit,
  LengthMeasurementUnitEnum,
  ModalityEnum,
  PackageTypeEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import {
  ICargoUnitsTypeState,
  IShipmentDetailsForm,
  IShipmentDetailsFormProps,
  IShipmentTypeState,
  ITotalCargoUnitState,
} from 'types/feature/create-shipment.types';

import { roundToPrecision } from 'utils/common';
import { capitalize, formatContainerName } from 'utils/format-fields';

import {
  CARGO_UNITS_INIT_STATE,
  DIMENSION_TOTAL_INIT_STATE,
  MAX_AIR_HEIGHT,
  computeChargebaleWeight,
  computeShipmentTypeOptions,
  formatTotalVolume,
  computeTotalWeight,
  convertToCBM,
  extractActiveShipmentType,
  isByUnit,
  isDefaultShipmentTypeExist,
} from '../ShipmentCreationForm/shipment.utils';
import { displayFreighMethod } from './freightMethod';

const { Text } = Typography;
const { Group, Button: RButton } = Radio;
const packageType = Object.values(PackageTypeEnum);

const ShipmentDetailsForm: FC<IShipmentDetailsFormProps> = ({
  form,
  defaultValues,
  defaultShipmentType,
  isEditView,
  footer,
  isFieldEditDisabled: isFieldActionsDisabled = false,
  proposalParams,
  deleteCargoUnitMutation,
  viewShipmentTypeAndCargoDetails,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [cargoUnitsType, setCargoUnitsType] = useState<ICargoUnitsTypeState>({
    byContainer: true,
    byUnit: false,
  });

  const [shipmentTypeOptions, setShipmentTypeOptions] = useState<
    IShipmentTypeState[]
  >([]);

  const [total, setTotal] = useState<ITotalCargoUnitState>(
    DIMENSION_TOTAL_INIT_STATE
  );

  // shipments types
  const { data: allShipmentTypesData, isLoading: isLoadingTypes } =
    useGetExternalShipmentsTypesQuery();

  const onDeleteCargoUnit = async (
    remove: (index: number | number[]) => void,
    index: number
  ) => {
    const cargoUnits = form.getFieldValue('cargoUnits');

    if (viewShipmentTypeAndCargoDetails && cargoUnits[index].id) {
      await deleteCargoUnitMutation({
        id: cargoUnits[index].id,
        params: proposalParams,
        shipmentId: defaultValues.shipmentId,
      })
        .unwrap()
        .then(
          () => {
            notification.success({
              message: 'Shipment item has been deleted successfully!',
            });
          },
          () => {
            notification.warning({
              message: 'Unable to delete this shipment item, please try again!',
            });
          }
        );
    }

    remove(index);
  };

  const computeCargoUnitValues = (
    cargoUnits: ICargoUnit[],
    changedValues?: ICargoUnit[]
  ) => {
    const shipmentTypeId = form.getFieldValue('shipmentTypeId');

    const activeShipmentType = extractActiveShipmentType(
      shipmentTypeOptions,
      shipmentTypeId
    )?.title;

    const newItems = cargoUnits.map((item: ICargoUnit, index: number) => {
      const shouldUpdateWeightPerValue =
        changedValues && !!changedValues[index]?.totalWeight;
      return {
        ...item,
        ...(shouldUpdateWeightPerValue
          ? {
              weight: roundToPrecision(
                (item.totalWeight ?? 0) / (item.quantity ?? 1)
              ),
            }
          : { weight: item.weight }),
        ...(item.quantity && item.weight && !shouldUpdateWeightPerValue
          ? {
              totalWeight: (item.quantity ?? 0) * (item.weight ?? 0),
            }
          : { totalWeight: item.totalWeight }),
        ...(item.volume && {
          chargeableWeight: computeChargebaleWeight(
            item.weight ?? 0,
            item.volume ?? 0,
            activeShipmentType
          ),
        }),
      };
    });

    const totalVolume = convertToCBM(formatTotalVolume(newItems));
    const totalWeight = computeTotalWeight(newItems, activeShipmentType);

    form.setFieldValue('cargoUnits', newItems);

    setTotal({
      volume: totalVolume,
      weight: totalWeight[0],
      charges: totalWeight[1],
    });
  };

  const resetItemsFields = (byUnits?: boolean) => {
    form.setFieldsValue({
      cargoUnits: [
        {
          ...CARGO_UNITS_INIT_STATE[0],
          packageType: byUnits ? PackageTypeEnum.PALLETS : undefined,
          lengthMeasurement: LengthMeasurementUnitEnum.CM,
        },
      ],
    });

    setTotal(DIMENSION_TOTAL_INIT_STATE);
  };

  const computeShipmentType = (activeShipmentTypeId: string) => {
    const activeShipmentType = extractActiveShipmentType(
      shipmentTypeOptions,
      activeShipmentTypeId
    );

    switch (activeShipmentType?.title) {
      case ShipmentTypeEnum.FCL:
      case ShipmentTypeEnum.FTL:
        setCargoUnitsType({ byContainer: true, byUnit: false });
        break;
      default:
        setCargoUnitsType({
          byContainer: false,
          byUnit: isByUnit(defaultValues?.cargoUnits),
        });
        break;
    }
  };

  const onShipmentTypeChange = (shipmentTypeIdValue: string) => {
    if (
      shipmentTypeIdValue === defaultShipmentType?.id &&
      defaultValues &&
      defaultValues.cargoUnits
    ) {
      computeCargoUnitValues(defaultValues.cargoUnits);
    } else {
      resetItemsFields();
    }
    computeShipmentType(shipmentTypeIdValue);
  };

  const onModalityChange = (modalityValue: string) => {
    resetItemsFields();
    const shipmentTypeOptions = computeShipmentTypeOptions(
      modalityValue,
      allShipmentTypesData
    );
    setShipmentTypeOptions(shipmentTypeOptions);
    form.setFieldValue('shipmentTypeId', shipmentTypeOptions[0].id);
  };

  const onCargoUnitTypeChange = (e: CheckboxChangeEvent) => {
    const shipmentTypeId = form.getFieldValue('shipmentTypeId');

    const byUnit = e.target.checked;
    setCargoUnitsType({
      ...cargoUnitsType,
      byUnit,
    });

    if (
      shipmentTypeId === defaultShipmentType?.id &&
      defaultValues.cargoUnits &&
      byUnit === isByUnit(defaultValues.cargoUnits)
    ) {
      computeCargoUnitValues(defaultValues.cargoUnits);
    } else {
      resetItemsFields(byUnit);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (allShipmentTypesData) {
      form.setFieldValue('modality', defaultValues.modality);
      setShipmentTypeOptions(
        computeShipmentTypeOptions(defaultValues.modality, allShipmentTypesData)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allShipmentTypesData, defaultValues.modality]);

  useEffect(() => {
    if (shipmentTypeOptions.length > 0) {
      const isDefaultExist =
        defaultShipmentType &&
        isDefaultShipmentTypeExist(defaultShipmentType, shipmentTypeOptions);

      const shipmentTypeId = isDefaultExist
        ? defaultShipmentType?.id
        : shipmentTypeOptions[0].id;

      if (isDefaultExist && defaultValues.cargoUnits) {
        computeCargoUnitValues(defaultValues.cargoUnits);
      }

      form.setFieldValue('shipmentTypeId', shipmentTypeId);

      computeShipmentType(shipmentTypeId);
    }
  }, [shipmentTypeOptions, defaultShipmentType?.id]);

  const onShipmentFormValueChange = (
    changedValues: Partial<IShipmentDetailsForm>,
    values: IShipmentDetailsForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];

    switch (fieldName) {
      case 'cargoUnits':
        if (values.cargoUnits) {
          computeCargoUnitValues(values.cargoUnits, changedValues.cargoUnits);
        }
        break;

      case 'modality':
        onModalityChange(values.modality);
        break;

      case 'shipmentTypeId':
        if (values.shipmentTypeId) {
          onShipmentTypeChange(values.shipmentTypeId);
        }
        break;
    }
  };

  const renderShipmentType = () =>
    allShipmentTypesData && (
      <Form.Item
        label="Shipment Type"
        name="shipmentTypeId"
        rules={[
          {
            required: true,
            message: 'Make sure to select the shipment type !',
          },
        ]}
      >
        <Group>
          {shipmentTypeOptions?.map((value: IShipmentTypeState) => (
            <RButton
              value={value.id}
              key={value.id}
              disabled={isEditView || isFieldActionsDisabled}
            >
              {value.title.toUpperCase()}
            </RButton>
          ))}
        </Group>
      </Form.Item>
    );

  const renderCargoByContainer = () => (
    <>
      <Row
        style={{
          display: 'flex',
          marginBottom: 8,
        }}
        align="middle"
        gutter={[12, 12]}
      >
        <Col xs={12} sm={8} md={6} lg={4}>
          <Text className="field-wrapper__text">Container Type</Text>
        </Col>
        <Col xs={9} sm={8} md={6} lg={4}>
          <Text className="field-wrapper__text">Container Quantity</Text>
        </Col>
      </Row>
      <Form.List
        name="cargoUnits"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error('Insert at least 1 cargo details')
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} gutter={[12, 12]}>
                <Col xs={12} sm={8} md={6} lg={4}>
                  <Form.Item
                    name={[field.name, 'containerType']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          'Please select container type or delete this line.',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Container Type"
                      options={Object.values(ContainerTypeEnum).map((value) => {
                        return {
                          value: value,
                          label: formatContainerName(value),
                        };
                      })}
                      disabled={isFieldActionsDisabled}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={9} sm={8} md={6} lg={4}>
                  <Form.Item
                    {...formItemLayoutWithOutLabel}
                    name={[field.name, 'quantity']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message:
                          'Please input the total quantity or delete this line.',
                      },
                      () => ({
                        validator(rule, value) {
                          if (value > 0 && value < 100001) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            'Allowed quantity range is 1 - 100,000'
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter Quantity"
                      className="custom-input"
                      min={0}
                      max={100000}
                      precision={0}
                      type="integer"
                      disabled={isFieldActionsDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} className="dynamic-delete-button">
                  {fields.length > 1 && (
                    <Button
                      disabled={isFieldActionsDisabled}
                      icon={<DeleteFilled />}
                      onClick={() => onDeleteCargoUnit(remove, field.name)}
                      danger
                    />
                  )}
                </Col>
                <Form.Item name={[field.name, 'id']} hidden={true}>
                  <Input disabled={isFieldActionsDisabled} />
                </Form.Item>
              </Row>
            ))}

            <Form.Item>
              <Button
                type="link"
                onClick={() => add(CARGO_UNITS_INIT_STATE[0])}
                icon={<PlusCircleOutlined />}
                disabled={isFieldActionsDisabled}
              >
                Add more
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );

  const renderCargoByUnits = () =>
    cargoUnitsType && (
      <>
        <div className="unit-dimensions-checkbox">
          <Checkbox
            defaultChecked={cargoUnitsType.byUnit}
            checked={cargoUnitsType.byUnit}
            onChange={onCargoUnitTypeChange}
          >
            By Units Dimensions
          </Checkbox>
        </div>

        {cargoUnitsType.byUnit ? (
          <>
            <Row
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="middle"
              gutter={[12, 12]}
            >
              <Col xs={12} md={12} lg={12}>
                <Text className="field-wrapper__text">
                  Dimensions (cm) - L*W*H
                </Text>
              </Col>
              {!isSmallScreen && (
                <>
                  <Col xs={12} md={6} lg={3}>
                    <Text className="field-wrapper__text">
                      Gross Weight / Unit
                    </Text>
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <Text className="field-wrapper__text">Quantity</Text>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <Text className="field-wrapper__text">
                      Total Gross Weight (Kg)
                    </Text>
                  </Col>
                </>
              )}
            </Row>
            <Form.List
              name="cargoUnits"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Insert at least 1 cargo details')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row key={field.key} gutter={[12, 12]}>
                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          label={isSmallScreen ? 'Package Type' : undefined}
                          name={[field.name, 'packageType']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the package type or delete this line.',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Choose Package Type"
                            options={packageType.map(
                              (value: PackageTypeEnum) => {
                                return {
                                  value: value,
                                  label: capitalize(value).replace('_', ' '),
                                };
                              }
                            )}
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={isSmallScreen ? 'Length' : undefined}
                          name={[field.name, 'length']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the length or delete this line.',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "Length can't be equal or less than 0"
                                );
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter Length (cm)"
                            className="custom-input"
                            min={0}
                            precision={2}
                            type="number"
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={isSmallScreen ? 'Width' : undefined}
                          name={[field.name, 'width']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the total quantity or delete this line.',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "Width can't be equal or less than 0"
                                );
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter Width (cm)"
                            className="custom-input"
                            min={0}
                            precision={2}
                            type="number"
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={isSmallScreen ? 'Height' : undefined}
                          name={[field.name, 'height']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the height or delete this line.',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "Height can't be equal or less than 0"
                                );
                              },
                            }),
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const modalityValue = getFieldValue('modality');
                                if (
                                  modalityValue === ModalityEnum.AIR &&
                                  value > MAX_AIR_HEIGHT
                                ) {
                                  return Promise.reject(
                                    `The maximum acceptable height for air cargo in passenger flights is ${MAX_AIR_HEIGHT}cm`
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                              warningOnly: true,
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter Height (cm)"
                            className="custom-input"
                            min={0}
                            precision={2}
                            type="number"
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={
                            isSmallScreen ? 'Gross Weight/unit' : undefined
                          }
                          name={[field.name, 'weight']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the weight or delete this line.',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "Weight can't be equal or less than 0"
                                );
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter weight"
                            className="custom-input"
                            min={0.1}
                            precision={2}
                            type="number"
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6} lg={2}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={isSmallScreen ? 'Quantity' : undefined}
                          name={[field.name, 'quantity']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please input the total quantity or delete this line.',
                            },
                            () => ({
                              validator(rule, value) {
                                if (value > 0) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "Quantity can't be equal or less than 0"
                                );
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Enter Quantity"
                            className="custom-input"
                            min={0}
                            precision={0}
                            type="number"
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6} lg={3}>
                        <Form.Item
                          {...formItemLayoutWithOutLabel}
                          label={
                            isSmallScreen
                              ? 'Total Gross Weight (Kg)'
                              : undefined
                          }
                          name={[field.name, 'totalWeight']}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <InputNumber
                            placeholder="Total weight"
                            min={0}
                            precision={2}
                            type="number"
                            // contentEditable={false}
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={10} md={4} lg={2}>
                        <Form.Item
                          label={isSmallScreen ? 'Measurement Unit' : undefined}
                          name={[field.name, 'lengthMeasurement']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please select the length measurement unit.',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Choose length measurement"
                            options={Object.values(
                              LengthMeasurementUnitEnum
                            ).map((value: LengthMeasurementUnitEnum) => {
                              return {
                                value: value,
                                label: value.toUpperCase(),
                              };
                            })}
                            disabled={isFieldActionsDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Form.Item name={[field.name, 'id']} hidden={true}>
                        <Input />
                      </Form.Item>
                      <Col xs={2} className="dynamic-delete-button">
                        {fields.length > 1 && (
                          <Button
                            disabled={isFieldActionsDisabled}
                            icon={<DeleteFilled />}
                            onClick={() =>
                              onDeleteCargoUnit(remove, field.name)
                            }
                            danger
                          />
                        )}
                      </Col>
                      {isSmallScreen && fields.length - 1 !== index && (
                        <Divider />
                      )}
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() =>
                        add({
                          ...CARGO_UNITS_INIT_STATE[0],
                          packageType: PackageTypeEnum.PALLETS,
                          lengthMeasurement: LengthMeasurementUnitEnum.CM,
                        })
                      }
                      icon={<PlusCircleOutlined />}
                      disabled={isFieldActionsDisabled}
                    >
                      Add more
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            {viewShipmentTypeAndCargoDetails && (
              <Row justify={'end'} align={'middle'}>
                <Col xs={24} sm={8} md={5} xl={4}>
                  <Space direction="vertical" className="units-footer__wrapper">
                    <Text className="units-footer__title">Total Weight</Text>
                    <div className="units-footer__amount-wrapper">
                      <Text className="units-footer__amount">
                        {total.weight} Kg
                      </Text>
                    </div>
                  </Space>
                </Col>
                <Col xs={24} sm={8} md={5} xl={4}>
                  <Space direction="vertical" className="units-footer__wrapper">
                    <Text className="units-footer__title">
                      Chargeable Weight
                    </Text>
                    <div className="units-footer__amount-wrapper">
                      <Text className="units-footer__amount">
                        {total.charges} Kg
                      </Text>
                    </div>
                  </Space>
                </Col>
                <Col xs={24} sm={8} md={5} xl={4}>
                  <Space direction="vertical" className="units-footer__wrapper">
                    <Text className="units-footer__title">Total Volume</Text>
                    <div className="units-footer__amount-wrapper">
                      <Text className="units-footer__amount">
                        {total.volume} cbm
                      </Text>
                    </div>
                  </Space>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <Form.List
            name="cargoUnits"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error('Insert at least 1 cargo details')
                    );
                  }
                },
              },
            ]}
          >
            {(fields) => (
              <>
                {fields.map((field) => (
                  <Row key={field.key} gutter={[12, 12]}>
                    <Col xs={12} sm={8} md={6} lg={5}>
                      <Form.Item
                        {...formItemLayoutWithOutLabel}
                        label="Weight (kg)"
                        name={[field.name, 'weight']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input the weight or delete this line.',
                          },
                          () => ({
                            validator(rule, value) {
                              if (value > 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                "Weight can't be equal or less than 0"
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter weight"
                          className="custom-input"
                          precision={2}
                          type="number"
                          disabled={isFieldActionsDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={5}>
                      <Form.Item
                        {...formItemLayoutWithOutLabel}
                        name={[field.name, 'volume']}
                        label="Volume (cm³)"
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input the total quantity or delete this line.',
                          },
                          () => ({
                            validator(rule, value) {
                              if (value > 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                "Volume can't be equal or less than 0"
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter Quantity"
                          className="custom-input"
                          precision={2}
                          type="number"
                          disabled={isFieldActionsDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={5}>
                      <Form.Item
                        {...formItemLayoutWithOutLabel}
                        label="Chargeable Weight (kg)"
                        name={[field.name, 'chargeableWeight']}
                      >
                        <Input placeholder="Enter chargeable weight" disabled />
                      </Form.Item>
                    </Col>
                    <Form.Item name={[field.name, 'id']} hidden={true}>
                      <Input />
                    </Form.Item>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        )}
      </>
    );

  return viewShipmentTypeAndCargoDetails ? (
    <DashboardCard
      headerTitle="Shipment Details"
      className="clear-background"
      large
      fitContent
      footer={footer}
    >
      <Form
        {...(defaultValues && { initialValues: defaultValues })}
        layout="vertical"
        form={form}
        scrollToFirstError
        onValuesChange={onShipmentFormValueChange}
        name="shipment-items-detailts"
      >
        <Form.Item label="Freight Method" name="modality">
          {isLoadingTypes ? (
            <Spin />
          ) : (
            <Group>
              {Object.keys(allShipmentTypesData ?? {})?.map(
                (method: string) => (
                  <RButton
                    disabled={isEditView || isFieldActionsDisabled}
                    value={method}
                    key={method}
                  >
                    {displayFreighMethod(method as ModalityEnum)}
                  </RButton>
                )
              )}
            </Group>
          )}
        </Form.Item>
        {isLoadingTypes ? <Spin /> : renderShipmentType()}
        {!cargoUnitsType ? (
          <></>
        ) : cargoUnitsType.byContainer ? (
          renderCargoByContainer()
        ) : (
          renderCargoByUnits()
        )}
      </Form>
    </DashboardCard>
  ) : (
    <Form
      {...(defaultValues && { initialValues: defaultValues })}
      layout="vertical"
      form={form}
      scrollToFirstError
      onValuesChange={onShipmentFormValueChange}
      name="shipment-items-detailts"
    >
      {!cargoUnitsType ? (
        <></>
      ) : cargoUnitsType.byContainer ? (
        renderCargoByContainer()
      ) : (
        renderCargoByUnits()
      )}
    </Form>
  );
};

export default ShipmentDetailsForm;
