import { DeleteFilled, FolderOpenFilled } from '@ant-design/icons';
import { Button, Modal, Tooltip, notification } from 'antd';
import {
  useDeleteShipmentByIdMutation,
  useEditShipmentMutation,
} from 'api/shipment';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDeletekButtonProps } from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';

import './delete-button.scss';

const { confirm } = Modal;

const DeleteButton: FC<IDeletekButtonProps> = ({
  shipmentId,
  isShipmentArchived = false,
}) => {
  const navigate = useNavigate();

  const [deleteShipment, { isLoading, isSuccess }] =
    useDeleteShipmentByIdMutation();
  const [archiveShipment, { isLoading: isArchiving }] =
    useEditShipmentMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to delete shipment',
    });
  };

  const onDeleteClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',
      icon: null,
      content: 'Are you sure you want to delete this shipment definitely ?',
      onOk: async () => {
        deleteShipment(shipmentId)
          .unwrap()
          .then(
            () => {
              navigate('/shipments');
            },
            () => errorNotification()
          );
      },
    });
  };

  const onArchiveClick = () => {
    confirm({
      title: 'Confirmation',
      okText: 'Archive',
      okType: 'danger',
      icon: null,
      content: 'Are you sure you want to archive this shipment?',
      onOk: async () => {
        archiveShipment({ shipmentId, shipment: { archived: true } })
          .unwrap()
          .then(
            () => {
              notification.success({
                message: 'Shipment archived successfully !',
              });
              navigate('/shipments');
            },
            (error) => displayErrors(error, { title: 'Archive Shipment Error' })
          );
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Shipment deleted successfully !',
      });
    }
  }, [isSuccess]);
  return (
    <Tooltip
      placement="top"
      title={isShipmentArchived ? 'Delete Shipment' : 'Archive Shipment'}
    >
      <Button
        danger
        data-testid="shipment-delete-btn"
        className="delete-btn"
        type="text"
        loading={isLoading || isArchiving}
        icon={isShipmentArchived ? <DeleteFilled /> : <FolderOpenFilled />}
        onClick={isShipmentArchived ? onDeleteClick : onArchiveClick}
      />
    </Tooltip>
  );
};

export default DeleteButton;
