export enum IncotermsEnum {
  EXW = 'exw',
  FCA = 'fca',
  FAS = 'fas',
  FOB = 'fob',
  CFR = 'cfr',
  CIF = 'cif',
  CPT = 'cpt',
  CIP = 'cip',
  DPU = 'dpu',
  DAP = 'dap',
  DDP = 'ddp',
}
export interface IProductEntity {
  id: string;
  category: string;
  companyId: string | null;
}
