import { useChecklistItem } from '@dopt/react-checklist/hooks';
import { Col, Modal, Row } from 'antd';
import {
  useCreateProxyShipmentMutation,
  useCreateShipmentMutation,
} from 'api/shipment';
import { useCreateShipmentTagsByAIMutation } from 'api/shipment-metadata';
import errorsLogger from 'app/layout/ErrorBoundary/useErrorReport';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  ShipmentInviteTypeEnum,
  TOnCreateShipmentParams,
} from 'types/feature/create-shipment.types';

import { createShipmentViewEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import ShipmentCreationForm from './ShipmentCreationForm';
import useShipmentCreation from './ShipmentCreationForm/UseShipmentCreation';
import ShipmentTextPreview from './ShipmentTextPreview';
import ShipmentCreationPrompt from './ShipmentTextPreview/shipmentCreationPrompt';
import SuccessModal from './SuccessModal';
import './shipment-creation.scss';

const ShipmentCreation: FC = () => {
  const [shipmentInfos, setShipmentInfos] = useState<{
    id: string;
    slug: string;
  }>({
    id: '',
    slug: '',
  });
  const campaign = useUTMState();

  const user = useSelector((state: RootState) => state.user.user);
  const [shipmentInviteType, setShipmentInviteType] =
    useState<ShipmentInviteTypeEnum>(ShipmentInviteTypeEnum.FORWARDER);
  const [hasExternalConnections, setHasExternalConnections] =
    useState<boolean>(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isTextReview, setIsTextReview] = useState<boolean>(false);

  const navigate = useNavigate();
  const rfqChecklistItem = useChecklistItem(
    'announcement.create_shipment_screen'
  );

  const {
    defaultShipment,
    onExtractShipmentFromText,
    isExtracting,
    isExtracted,
    extractedShipment,
  } = useShipmentCreation();

  const [createShipment, { isSuccess, isLoading }] =
    useCreateShipmentMutation();

  const [
    createProxyShipment,
    { isSuccess: isProxyShipmentSuccess, isLoading: isProxyShipmentLoading },
  ] = useCreateProxyShipmentMutation();

  const [createShipmentTagsByAI] = useCreateShipmentTagsByAIMutation();

  // methods
  const onRequestQuote = (shipmentId: string, slug: string) => {
    const inviteUrl =
      shipmentInviteType === ShipmentInviteTypeEnum.FORWARDER
        ? `/shipments/${slug}/request-quote`
        : `/shipments/${slug}/collaborators`;
    navigate(inviteUrl, { state: { shipmentId } });
    setIsSuccessModalOpen(false);
  };

  const onViewShipment = (shipmentId: string, slug: string) => {
    navigate(`/shipments/${slug}`, {
      state: { shipmentId, showBookingPartyModal: true },
    });
    setIsSuccessModalOpen(false);
  };

  const createTagsByAI = async (shipmentId: string) => {
    if (shipmentId) {
      try {
        await createShipmentTagsByAI(shipmentId).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const location = window.location.pathname;
        errorsLogger.report(`FE Suggested Tag Error at ${location} : ${error}`);
      }
    }
  };

  const onCreateShipment: TOnCreateShipmentParams = async (
    shipment,
    inviteType,
    hasExternalConnections
  ) => {
    try {
      let resp;
      if (hasExternalConnections) {
        resp = await createProxyShipment(shipment).unwrap();
      } else {
        resp = await createShipment({
          body: shipment,
          params: undefined,
        }).unwrap();
      }

      setShipmentInfos({ id: resp.id, slug: `SH-${resp.slug}` });
      setShipmentInviteType(inviteType);
      setHasExternalConnections(hasExternalConnections);

      createTagsByAI(resp.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Creation Error' });
    }
  };

  // message
  const [modal, contextHolder] = Modal.useModal();
  let modalInstance: {
    destroy: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (configUpdate: any) => void;
  };

  const updateDetroyableModal = () => {
    modalInstance = modal.success({
      title: 'Shipment details populated 🎉',
      content: `Please check shipment details then submit the form !`,
    });

    setTimeout(() => {
      modalInstance.destroy();
    }, 5000);
  };

  // extract shipment infos from data
  const openPreviewTab = () => setIsTextReview(true);
  const closePreviewTab = () => setIsTextReview(false);

  // hooks
  useEffect(() => {
    if (isSuccess || isProxyShipmentSuccess) {
      setIsSuccessModalOpen(true);
    }
  }, [isSuccess, isProxyShipmentSuccess]);

  useEffect(() => {
    if (isExtracted && extractedShipment) {
      updateDetroyableModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtracted, extractedShipment]);

  useEffect(() => {
    if (user) {
      createShipmentViewEvent(
        {
          user_id: user.id,
          email: user.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        },
        { campaign }
      );
    }
  }, [user, campaign]);

  return (
    <div className="shipment-form-wrapper">
      <Row>
        <Col span={isTextReview ? 14 : 24}>
          <ShipmentCreationForm
            defaultShipment={defaultShipment}
            onCreateShipment={onCreateShipment}
            isLoading={isLoading || isProxyShipmentLoading}
            openPreviewTab={openPreviewTab}
          />
        </Col>
        <Col span={isTextReview ? 10 : 0}>
          <ShipmentTextPreview
            onSubmitShipmentText={onExtractShipmentFromText}
            onClosePreviewTab={closePreviewTab}
          />
        </Col>
      </Row>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        shipmentId={shipmentInfos}
        shipmentInviteType={shipmentInviteType}
        hasExternalConnections={hasExternalConnections}
        onRequestQuote={onRequestQuote}
        onViewShipment={onViewShipment}
      />

      <Modal
        title="🤖 Let me setup the shipment for you!"
        open={isExtracting}
        footer={null}
        closable={false}
      >
        <ShipmentCreationPrompt />
      </Modal>
      {contextHolder}
    </div>
  );
};

export default ShipmentCreation;
