import { ICarrierEntity } from 'types/entities/carrier.entity';

export const getCarriersOptions = (carriers: ICarrierEntity[] = []) => {
  return carriers.map((carrier) => ({
    value: carrier.id,
    label: carrier.name,
  }));
};

export const filterCarriers = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
