import { FormInstance, FormListFieldData } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { ICreateCommentBasePayload } from 'types/entities/comment.entity';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import { IncotermsEnum } from 'types/entities/product.entity';
import {
  IPurchaseOrderCreateBody,
  IPurchaseOrderEntity,
  IPurchaseOrderItemEntity,
  PurchaseOrderStatusEnum,
  SplitScreenMode,
} from 'types/entities/purchase-order.entity';
import {
  IAllShipmentsLocation,
  ILocation,
  ModalityEnum,
} from 'types/entities/shipment.entity';
import { UserRoleEnum } from 'types/entities/user.entity';

export enum AccessLevelEnum {
  VIEW = 'view',
  EDIT = 'edit',
  MANAGE = 'manage',
}

export enum POCreationFormTabEnum {
  ORDER_DETAILS = 'order-details',
  ORDER_ITEM = 'order-item',
  ADDITIONAL_INFO = 'additional-info',
}

export interface IExpandableRowProps {
  isBookingOngoing: boolean;
  isShipmentTab: boolean;
  purchaseOrder: IPurchaseOrderEntity;
  shipmentId?: string;
}
export interface IPurchaseOrderItemsProps {
  accessLevel: AccessLevelEnum;
  items: IPurchaseOrderItemEntity[];
  selectedColumns: string[];
  purchaseOrderId: string;
  purchaseOrderRef: string | null;
  isBookingOngoing: boolean;
  isShipmentTab: boolean;
  isDraft: boolean;
}

export interface IOrderCreationFormProps {
  defaultPurchaseOrder: IPurchaseOrderEntity | null;
  isEditView: boolean;
  isProcessing: boolean;
  file: string[] | null;
  createBookingFlow: boolean;
  showItemTag?: boolean;
  onCancel: (() => void) | undefined;
  onFinish: (purchaseOrder: IPurchaseOrderCreateBody) => Promise<void>;
}

export interface IOrderDetailsForm {
  status: PurchaseOrderStatusEnum | null;
  slug: number;
  cargoReadyDate: dayjs.Dayjs;
  orderIssueDate: dayjs.Dayjs;
  destination: string;
  origin: string;
  destinationFull: ILocation;
  originFull: ILocation;
  reference: string;
  attachments: string[];
  supplier: string;
  modality: ModalityEnum;
  incoterms: IncotermsEnum;
}

export interface IOrderDetailsState {
  origin: ILocation | undefined;
  destination: ILocation | undefined;
}

export interface IOrderDetailsFormProps {
  defaultValues: IOrderDetailsForm | null;
  form: FormInstance<IOrderDetailsForm>;
  createBookingFlow: boolean;
}

export interface IAdditionalFormProps {
  footer?: JSX.Element;
  uploadedPurchaseOrderfile: string[] | null;
}

export interface IOrderLinesForm {
  purchaseOrderId?: string;
  items: IPurchaseOrderItemEntity[];
}
export interface IOrderLinesFormProps {
  form: FormInstance<IOrderLinesForm>;
  defaultValues: IOrderLinesForm;
  isEditView: boolean;
  footer: JSX.Element;
}

export interface ITotalItemsState {
  volume?: number;
  weight?: number;
  price?: string;
  quantity?: number;
  boxes?: number;
}

export interface IPurchaseOrderEditProps {
  order: IPurchaseOrderEntity | null;
  isOpen: boolean;
  onCloseEditModal: () => void;
}

export interface IPurchaseOrdersTabProps {
  mode: SplitScreenMode;
  onCreateBooking: () => void;
  shipmentId?: string;
  currentUserRole?: UserRoleEnum;
}
export interface IPurchaseOrderFilters {
  isDraft?: boolean;
  modality?: string;
  destinations?: string[];
  origins?: string[];
  poStatuses?: PurchaseOrderStatusEnum[];
  createdDate?: string[];
  modalities?: string[];
  suppliers?: string[];
  creators?: string[];
}

export interface IPurchaseOrdersTableProps {
  isBookingOngoing: boolean;
  columns: ColumnsType<IPurchaseOrderEntity> | undefined;
  shipmentId?: string;
  isDraft?: boolean;
  search?: string;
  selectedFilters: IPurchaseOrderFilters;
}

export interface IPurchaseOrdersBookingProps {
  onCloseBookingTab: () => void;
  onCreateBooking: () => void;
  mode: SplitScreenMode;
}

export interface IPurchaseOrderFiltersProps {
  onFilterChange: (value: string[], name: string) => void;
  selectedFilters: IPurchaseOrderFilters;
  onResetFilters: () => void;
  // handleInputOnChange: (value: string) => void;
  // handleOnSearch: (value: string) => void;
  onFilterDrawerChange: (filter: IPurchaseOrderFilters) => void;
}
export interface IPurchaseOrderCreatedAtFilterProps {
  onFilterChange: (value: string[], name: string) => void;
  selectedFilters: IPurchaseOrderFilters;
}
export interface IPurchaseOrderStatusFilterProps {
  layout: 'horizontal' | 'vertical';
  defaultValue: string[] | undefined;
  onChange: (statusFilter: PurchaseOrderStatusEnum[]) => void;
}
export interface IPurchaseOrderModalitiesFilterProps {
  layout: 'horizontal' | 'vertical';
  defaultValue: string[] | undefined;
  onChange: (statusFilter: ModalityEnum[]) => void;
}

export interface IPOStatusFilterForm {
  poStatusFilter: PurchaseOrderStatusEnum[];
}
export interface IPOModalityFilterForm {
  poModalityFilter: ModalityEnum[];
}
export interface IPurchaseOrderLocationFilterProps {
  onFilterChange: (value: string[], name: string) => void;
  selectedFilters: IPurchaseOrderFilters;
  allShipmentsLocation: IAllShipmentsLocation;
}
export interface IPurchaseOrderContactsFilterProps {
  onFilterChange: (value: string[], name: string) => void;
  selectedFilters: IPurchaseOrderFilters;
  allContacts: IUserPartnerEntity[];
}
export interface IPurchaseOrderFilterDrawerProps {
  defaultValue: IPurchaseOrderFilters;
  onChange: (filter: IPurchaseOrderFilters) => void;
  onReset: () => void;
  allContacts: IUserPartnerEntity[];
  allPurchaseOrderLocations: IAllShipmentsLocation | undefined;
}

export interface IDraftBookingsProps {
  goToNextStep: () => void;
  onCancel: () => void;
}

export interface ICreatedBookingsProps {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isNewShipment: boolean;
  onChangeOption: () => void;
  onCancel: () => void;
}

export interface IPurchaseOrderCollaboratorsProps {
  purchaseOrderId: string;
  showCollaboratorModal?: (id: string, accessLevel: AccessLevelEnum) => void;
  hideCollaboratorModal?: () => void;
  currentUserAccessLevel: AccessLevelEnum;
  isCollaboratorOpen: boolean;
}

export interface IPurchaseOrderCollaboratorEntity {
  id: string;
  fullname: string;

  photo: string;
}

export interface IPurchaseOrderCollaborator {
  id: string;
  accessLevel: AccessLevelEnum;
  collaborator: IPurchaseOrderCollaboratorEntity;
}

export interface IPurchaseOrderCollaborators {
  id: string;
  fullname: string;

  email: string;
  photo: string | null;
  isCollaborator: boolean;
  companyId: string;
  companyName: string;
  accessLevel: AccessLevelEnum;
  addedBy: string;
}

export interface CollaboratorAccessDropdownProps {
  collaborator: ICollaboratorsEntity;
}

export interface ICreatePurchaseOrderCommentPayload
  extends ICreateCommentBasePayload {
  purchaseOrderId: string;
}

export interface IGetPurchaseOrderCommentPayload {
  purchaseOrderId: string;
}

export interface IPurchaseOrderCommentsProps {
  purchaseOrderId: string;
  shipmentId?: string;
  currentUserAccessLevel: AccessLevelEnum;
}
export interface IPurchaseOrderActivityProps {
  purchaseOrderId: string;
  shipmentId?: string;
  currentUserAccessLevel: AccessLevelEnum;
}

export interface IOrderLinesFormFieldsProps {
  title: string;
  fields: FormListFieldData[];
  field: FormListFieldData;
  onRemoveField: () => void;
}

export interface ICreatePurchaseOrderForm {
  createdPurchaseOrderResp: IPurchaseOrderEntity | null;
  orderDetailsForm: IOrderDetailsForm | null;
  orderLinesForm: IOrderLinesForm | null;
}

export type TComputeReviewTagCountDef = (props: {
  createOrderItemState: IPurchaseOrderItemEntity[] | undefined;
  defaultOrderItem: IPurchaseOrderItemEntity[] | undefined;
}) => number;
