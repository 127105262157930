import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Typography } from 'antd';
import {
  useGetProposalsByShipmentQuery,
  useUpdatePropsalViewMutation,
} from 'api/proposal';
import ProfileImage from 'app/components/ProfilePicture';
import React, { FC, useEffect } from 'react';
import { IProposalsListProps } from 'types/entities/message.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';

import { displayErrors } from 'utils/error-notification';

const ProposalsList: FC<IProposalsListProps> = ({
  shipmentId,
  selectedProposal,
  setSelectedProposal,
  switchToShipmentMessagesTab,
}) => {
  const {
    data: proposalcomments,
    isLoading,
    isError,
    error,
  } = useGetProposalsByShipmentQuery({
    shipmentId,
  });
  const [updateProposal] = useUpdatePropsalViewMutation();
  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error fetching proposal' });
    }
  }, [isError, error]);
  const handleClick = async (proposal: IProposalEntity) => {
    const proposalCommentIds = proposal.proposalComments.map(
      (comment) => comment.id
    );
    try {
      await updateProposal({ proposalCommentIds: proposalCommentIds }).unwrap();
      setSelectedProposal(proposal);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'failed to update proposal' });
    }
  };
  return (
    <>
      <Card className="header-card" bordered={false}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>Proposals</Typography.Title>
            <Typography.Text>
              Total Proposals: {proposalcomments?.length}
            </Typography.Text>
          </Col>
          <Button
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={switchToShipmentMessagesTab}
          ></Button>
        </Row>
      </Card>
      <Card bordered={false} className="list-card">
        <List
          loading={isLoading}
          dataSource={proposalcomments}
          renderItem={(proposal: IProposalEntity) => (
            <div
              onClick={() => handleClick(proposal)}
              className={`shipment-message-wrapper${
                selectedProposal === proposal ? '__selected' : ''
              }`}
            >
              <List.Item>
                <List.Item.Meta
                  className="message-item"
                  avatar={
                    <ProfileImage
                      wrapperClassName="user-gravatar"
                      name={proposal.company.name}
                      photoUrl={proposal.company.photo}
                      size={30}
                    />
                  }
                  title={proposal.company.name}
                  description={
                    proposal.proposalComments.length < 1
                      ? 'no comments'
                      : `${proposal.proposalComments.length} comments`
                  }
                />
              </List.Item>
            </div>
          )}
        ></List>
      </Card>
    </>
  );
};

export default ProposalsList;
