import { ColumnsType } from 'antd/es/table';
import { formatUnitMeasurement } from 'app/components/Proposal/Fees/fees.const';
import dayjs from 'dayjs';
import React from 'react';
import { IFeeEntity } from 'types/entities/fee.entity';
import { IFileEntity, IProposalEntity } from 'types/entities/proposal.entity';

type TProposalItemsTableProps = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  diffKeys?: Record<keyof IProposalEntity, any>;
}) => ColumnsType<IFeeEntity>;
type TQouteDocumentTableProps = () => ColumnsType<IFileEntity>;

export const renderProposalItems: TProposalItemsTableProps = ({ diffKeys }) => {
  return [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_, proposalItem, index) => {
        return (
          <div
            className={`${diffKeys?.proposalItems[index]?.description} text-value`}
          >
            {proposalItem.description}
          </div>
        );
      },
    },
    {
      title: 'Unit',
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      render: (_, proposalItem, index) => (
        <div
          className={`${diffKeys?.proposalItems[index]?.measurementUnit} text-value`}
        >
          {formatUnitMeasurement(proposalItem.measurementUnit)}
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_, proposalItem, index) => (
        <div
          className={`${diffKeys?.proposalItems[index]?.quantity} text-value`}
        >
          {proposalItem.quantity}
        </div>
      ),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (_, proposalItem, index) => (
        <div className={`${diffKeys?.proposalItems[index]?.rate} text-value`}>
          {proposalItem.rate}
        </div>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (_, proposalItem, index) => (
        <div
          className={`${diffKeys?.proposalItems[index]?.currency} text-value`}
        >
          {proposalItem.currency.toUpperCase()}
        </div>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (_, proposalItem, index) => (
        <div
          className={`${diffKeys?.proposalItems[index]?.totalCost} text-value`}
        >
          {proposalItem.totalCost}
        </div>
      ),
    },
  ];
};

export const renderQouteDocumentTable: TQouteDocumentTableProps = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, file) => (
        <a href={file.file} target="_blanck">
          {file.name}
        </a>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'type',
      render: (_, file) => file.type,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, file) => dayjs(file.updatedAt).format('MMM DD, YYYY'),
    },
  ];
};
