import { Modal, Typography } from 'antd';
import React, { FC } from 'react';
import { SuccessTrackingModalProps } from 'types/feature/Success-tracking-modal.types';

import TrackingIllustration from '../../../../../assets/onboarding/tracking-illustration.svg';
import './success-tracking-modal.scss';

const { Text, Title } = Typography;

const SuccessTrackingModal: FC<SuccessTrackingModalProps> = ({
  isOpen,
  shipmentId,
  onViewTracking,
  onCanceltrackingModal,
}) => {
  const onViewShipmentClick = () => {
    onViewTracking(shipmentId.id, shipmentId.slug);
  };
  return (
    <Modal
      open={isOpen}
      destroyOnClose
      onCancel={onCanceltrackingModal}
      onOk={onViewShipmentClick}
      okText={'View Tracking'}
    >
      <div className="modal-container">
        <img src={TrackingIllustration} alt="tracking activated" />
        <div className="modal-info">
          <Title className="modal-title" level={3}>
            Tracking Activated
          </Title>
          <Text className="modal-text" type="secondary">
            The Tracking for this shipment is activated. Now you can track your
            shipment in real time.
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessTrackingModal;
