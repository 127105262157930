import { ApiResponse } from 'api/common/response.type';
import { IApiKeyEntity } from 'types/entities/setting.entity';

import { api } from './base-api';

export const apiKeySettingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiKey: build.query<ApiResponse<IApiKeyEntity>, void>({
      query: () => ({ url: `/api-key` }),
      providesTags: ['ApiKeySettings'],
    }),

    createApiKey: build.mutation<Record<string, any>, void>({
      query: () => ({
        url: `/api-key`,
        method: 'POST',
      }),
      invalidatesTags: ['ApiKeySettings'],
    }),
  }),
});

export const { useGetApiKeyQuery, useCreateApiKeyMutation } = apiKeySettingApi;
