import { IProductEntity } from '../types/entities/product.entity';
import { api } from './base-api';

export const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<IProductEntity[], void>({
      query: () => ({ url: '/product' }),
    }),
  }),
});

export const { useGetProductsQuery } = productsApi;
