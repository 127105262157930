import Icon, { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Typography, Spin } from 'antd';
import { useExtractDocs } from 'providers/extract-docs-provider';
import React, { useState } from 'react';

import DocumentExtractor from '..';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-circle-filled.svg';
import { ReactComponent as UploadedFileIcon } from '../../../../assets/icons/file-icon-outlined-blue.svg';
import { ReactComponent as UploadFileIcon } from '../../../../assets/icons/file-icon-outlined-gray.svg';

const DocumentExtractMinimize = () => {
  const {
    isExtractingDocument,
    isExtractingScuccess,
    showDocExtractMinimize,
    setShowDocExtractMinimize,
    selectedDoc,
    extractedFileId = null,
    setExtractedFileId,
  } = useExtractDocs();
  const [isExtractedDocsModalOpen, setIsExtractedDocsModalOpen] =
    useState<boolean>(false);

  const onCloseShowMinimizeFile = () => setShowDocExtractMinimize(false);

  const onExtractedDocsClick = () => {
    setIsExtractedDocsModalOpen(true);
  };

  const onExtractedDocsClose = () => {
    setIsExtractedDocsModalOpen(false);
    setExtractedFileId(null);
    onCloseShowMinimizeFile();
  };

  return (
    <>
      {showDocExtractMinimize ? (
        <div className="minimized-upload--wrapper">
          <div className="minimized-upload--header">
            Document Parsing
            <Button
              icon={<CloseOutlined style={{ color: '#fff' }} />}
              onClick={onCloseShowMinimizeFile}
              type="text"
              size="small"
            />
          </div>
          <div className="minimized-upload--sub-header">
            {isExtractingScuccess ? 'Upload Complete' : 'In Progress...'}
          </div>
          <div
            className={`minimized-upload--items-wrapper ${
              isExtractingScuccess ? 'uploaded' : ''
            }`}
            {...(isExtractingScuccess && { onClick: onExtractedDocsClick })}
          >
            <div>
              <Icon
                component={
                  isExtractingScuccess ? UploadedFileIcon : UploadFileIcon
                }
              />
              <Typography.Text className="file-title">
                {selectedDoc?.name}
              </Typography.Text>
            </div>
            {isExtractingScuccess ? (
              <Icon component={CheckIcon}></Icon>
            ) : (
              <Spin
                indicator={<LoadingOutlined spin />}
                spinning={isExtractingDocument}
                size="small"
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {isExtractedDocsModalOpen && (
        <DocumentExtractor
          shipmentId={null}
          fileId={extractedFileId}
          isOpen={isExtractedDocsModalOpen}
          onClose={onExtractedDocsClose}
        />
      )}
    </>
  );
};

export default DocumentExtractMinimize;
