import { Button, Dropdown, Space, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { useGetCollaboratorByShipmentQuery } from 'api/collaborators';
import React, { FC, useState } from 'react';
import {
  ICollaboratorsDef,
  ICollaboratorsEntity,
} from 'types/entities/collaborator.entity';
import { ITeamSelectProps } from 'types/feature/team-select.types';

import { computeFullName } from 'utils/user-utils';

import ProfileImage from '../ProfilePicture';

const { Text } = Typography;

const renderAvatarIcon = (
  collab: ICollaboratorsEntity | undefined,
  size?: number
) => {
  return (
    <ProfileImage
      name={
        collab
          ? computeFullName<ICollaboratorsDef>(collab.collaborator)
          : 'Unassigned'
      }
      photoUrl={collab?.collaborator.photo}
      size={23}
    />
  );
};

const renderItems = (collab: ICollaboratorsEntity | undefined) => {
  return (
    <Space align="center">
      {renderAvatarIcon(collab)}
      <Text>{collab ? collab.collaborator.fullname : 'Unassigned'}</Text>
    </Space>
  );
};

export const generateCollaboratorsItem = (
  collaborators: ICollaboratorsEntity[]
): { label: JSX.Element; key: string }[] => {
  return collaborators.map((c) => ({
    key: c.collaborator.id,
    label: renderItems(c),
  }));
};

const TeamSelect: FC<ITeamSelectProps> = ({
  shipmentId,
  onChange,
  defaultValue,
  disabled,
  className,
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null | undefined>(
    defaultValue
  );
  const { data: collabData, isLoading: isCollabLoading } =
    useGetCollaboratorByShipmentQuery(shipmentId, {
      refetchOnMountOrArgChange: true,
    });

  const itemsOnClick: MenuProps['onClick'] = ({ key }) => {
    setSelectedItem(key);
    onChange(key === 'unassigned' ? null : key);
  };

  const items: MenuProps['items'] = [
    {
      key: 'unassigned',
      label: renderItems(undefined),
    },
    ...generateCollaboratorsItem(collabData || []),
  ];

  const filteredCollaborator = () =>
    (collabData ?? []).find((c) => c.collaborator.id === selectedItem);

  return (
    <>
      <Dropdown
        className={className}
        disabled={disabled || isCollabLoading}
        menu={{
          items: items,
          onClick: itemsOnClick,
          selectable: true,
          ...(defaultValue && { defaultSelectedKeys: [defaultValue] }),
        }}
        trigger={['click']}
      >
        <Button
          loading={isCollabLoading}
          type="text"
          onClick={(e) => e.preventDefault()}
          icon={renderAvatarIcon(filteredCollaborator())}
        />
      </Dropdown>
    </>
  );
};

export default TeamSelect;
