import { IBookingEntity } from 'types/entities/booking.entity';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

export const canAssignBooking = (booking: IBookingEntity) => {
  const purchaseOrdersAcces = booking.bookingPurchaseOrders.map(
    (bookingPurchaseOrder) =>
      bookingPurchaseOrder.purchaseOrder.purchaseOrderCollaborators[0]
        .accessLevel
  );

  // find the highest access level
  const highestAccessLevel = purchaseOrdersAcces.find((accessLevel) =>
    [AccessLevelEnum.EDIT, AccessLevelEnum.MANAGE].includes(accessLevel)
  );

  return highestAccessLevel !== undefined;
};
