import {
  Alert,
  Col,
  Empty,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd';
import {
  isServiceModeAvailable,
  renderServiceMode,
} from 'app/components/ContractRates/CostHeadCollapse/cost-head.helper';
import DashboardCard from 'app/design-system/DashboardCard';
import DescriptionList from 'app/design-system/DescriptionList';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import {
  ICargoUnit,
  IProposalShipmentEntity,
} from 'types/entities/shipment.entity';

import { displayCountry, formatServiceModeString } from 'utils/format-fields';

// style
import './shipment-details.scss';
import { displayShipmentFiles } from './shipment-files';
import { displayCargoUnitsByType } from './shipment-units';

const ShipmentDetails: FC<{
  id?: string;
  shipment?: IProposalShipmentEntity;
}> = ({ id, shipment: shipmentDetails }) => {
  const [showUnits, setShowUnits] = useState<boolean>(true);
  const [showFiles, setShowFiles] = useState<boolean>(true);

  return (
    <DashboardCard headerTitle="Shipment Details" large fitContent>
      {!shipmentDetails ? (
        <Skeleton active />
      ) : (
        <>
          <Row gutter={[24, 24]} justify="start">
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                label="Commodity Type"
                description={shipmentDetails?.product.category}
                isVertical
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="Amount & Service"
                description={shipmentDetails?.incoterms?.toUpperCase() ?? ''}
              />
            </Col>
            {isServiceModeAvailable(
              shipmentDetails.origin.serviceMode,
              shipmentDetails.destination.serviceMode
            ) && (
              <Col span={8} xs={12} sm={12} md={6}>
                <DescriptionList
                  className="mx-0"
                  isVertical
                  label="Service Mode"
                  description={renderServiceMode(
                    formatServiceModeString(
                      shipmentDetails.origin.serviceMode,
                      shipmentDetails.destination.serviceMode
                    )
                  )}
                />
              </Col>
            )}
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="Modality"
                description={
                  shipmentDetails?.shipmentType?.modality?.toUpperCase() ?? ''
                }
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="From"
                description={displayCountry(shipmentDetails.origin)}
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="To"
                description={displayCountry(shipmentDetails.destination)}
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="Cargo Ready Date"
                description={dayjs(shipmentDetails?.cargoReadyDate).format(
                  'DD MMM, YYYY'
                )}
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="Cargo Type"
                description={shipmentDetails?.cargoType}
              />
            </Col>
            <Col span={8} xs={12} sm={12} md={6}>
              <DescriptionList
                className="mx-0"
                isVertical
                label="Non-Stackable"
                description={shipmentDetails?.nonStackable ? 'Yes' : 'No'}
              />
            </Col>
          </Row>
          <Typography.Title level={5}>Cargo Owner Description</Typography.Title>
          <Alert
            description={
              shipmentDetails?.additionalInfo
                ? shipmentDetails?.additionalInfo
                : 'No description added for this shipment!'
            }
            className="cargo-description"
          ></Alert>
          {/* Container details */}
          <div>
            <Row justify="space-between" align="middle">
              <Typography.Title level={5}>Container Amount</Typography.Title>
              <Typography.Paragraph className="section-toggle-lable">
                <span>{showUnits ? 'Hide' : 'Show'} Section</span>
                <Switch
                  checked={showUnits}
                  onChange={(value) => setShowUnits(value)}
                />
              </Typography.Paragraph>
            </Row>

            <div hidden={!showUnits} className="units-section">
              {!shipmentDetails?.shipmentItems?.length ? (
                <Empty description="No cargo units have been attached to this shipment !" />
              ) : (
                displayCargoUnitsByType({
                  units:
                    shipmentDetails.shipmentItems as unknown as ICargoUnit[],
                  type: shipmentDetails.shipmentType.title,
                })
              )}
            </div>
          </div>
          {/* Attached documents */}
          <div>
            <Row justify="space-between" align="middle">
              <Typography.Title level={5}>Attachments</Typography.Title>
              <Typography.Paragraph className="section-toggle-lable">
                <Space>
                  <span>{showFiles ? 'Hide' : 'Show'} Section</span>
                  <Switch
                    checked={showFiles}
                    onChange={(value) => setShowFiles(value)}
                  />
                </Space>
              </Typography.Paragraph>
            </Row>

            <div hidden={!showFiles}>
              {!shipmentDetails?.files ? (
                <Empty description="No documents attached !" />
              ) : (
                displayShipmentFiles({
                  isLoading: !shipmentDetails?.files,
                  filesData: shipmentDetails?.files ?? [],
                })
              )}
            </div>
          </div>
        </>
      )}
    </DashboardCard>
  );
};

export default ShipmentDetails;
