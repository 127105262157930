import { DownCircleTwoTone } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { usePinCompanyMutation } from 'api/company';
import {
  useGetForwardersQuery,
  useInviteForwardersMutation,
} from 'api/forwarders';
import ProfileImage from 'app/components/ProfilePicture';
import DashboardCard from 'app/design-system/DashboardCard';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IForwarderEntity } from 'types/entities/forwarder.entity';
import {
  ForwardersKeys,
  IRecentForwardersProps,
} from 'types/feature/forwarders.types';

import { inviteForwarderEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { computeFullName } from 'utils/user-utils';

import { ReactComponent as PinFilled } from '../../../../assets/icons/pin-filled.svg';
import { ReactComponent as PinOutline } from '../../../../assets/icons/pin-outline.svg';
import AddForwarderForm from '../AddForwarderForm';
import ForwarderContactsTable from './forwarder-contacts';
import './recent-forwarders.scss';

const forwarderTagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const fullName = label?.toString().split('(')[1].slice(0, -1);
  return (
    <Tooltip title={label}>
      <Tag
        color={'blue'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {fullName}
      </Tag>
    </Tooltip>
  );
};

const getCompaniesList = (forwarders: IForwarderEntity[]) => {
  const uniqueCompanies = new Set<string>();
  forwarders.forEach((item) => {
    uniqueCompanies.add(item.name);
  });

  const companiesSet = Array.from(uniqueCompanies).map((name: string) => ({
    text: name,
    value: name,
  }));

  return companiesSet;
};

const computeForwarderSelectOptions = (forwarders: IForwarderEntity[]) => {
  const users = forwarders.flatMap((forwarder) =>
    forwarder.forwarders.map((user) => ({
      value: `${forwarder.id}/${user.id}`,
      label: `${user.email} (${computeFullName(user)})`,
    }))
  );

  return users;
};

const getContactRowsKeys = (keys: ForwardersKeys[], compnayId: string) =>
  keys.find((key) => compnayId === key.companyId)?.forwarders ?? [];

const RecentForwarders: FC<IRecentForwardersProps> = ({ shipmentId }) => {
  const [selectedForwarder, setSelectedFowarders] = useState<ForwardersKeys[]>(
    []
  );
  const user = useSelector((state: RootState) => state.user.user);
  const {
    data: forwarders,
    isLoading,
    isError,
    refetch: refetchForwardersList,
  } = useGetForwardersQuery({ shipmentId });

  const [pinCompany, { isLoading: isPinCompnayLoading }] =
    usePinCompanyMutation();

  const onPinClick = async (id: string, pinned: boolean) => {
    try {
      await pinCompany({ id, pinned }).unwrap();
      await refetchForwardersList();
      //eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Delete Booking Error' });
    }
  };
  const onSelectAllCompany = (companyId: React.Key) => {
    const forwarder = {
      companyId,
      forwarders: forwarders
        ?.find((company) => company.id === companyId)
        ?.forwarders.map((contact) => contact.id) as React.Key[],
    };

    setSelectedFowarders((forwarderKeys) => [...forwarderKeys, forwarder]);
  };
  const onSelectCompanyRowChange = (newSelectedRowKeys: React.Key[]) => {
    const forwarderKeys = newSelectedRowKeys.map((key) => {
      const selectedUsers = selectedForwarder.find(
        (ff) => ff.companyId === key
      )?.forwarders;
      return {
        companyId: key,
        forwarders: selectedUsers?.length
          ? selectedUsers
          : (forwarders
              ?.find((company) => company.id === key)
              ?.forwarders.map((contact) => contact.id) as React.Key[]),
      };
    });
    setSelectedFowarders(forwarderKeys);
  };

  const onSelectContactRowChange = (
    newSelectedRowKeys: React.Key[],
    companyId: React.Key
  ) => {
    const selectedKeys = [...selectedForwarder];

    const forwarderCompanyIdx = selectedKeys.findIndex(
      (forwarder) => forwarder.companyId === companyId
    );

    if (forwarderCompanyIdx >= 0) {
      // company already selected
      if (newSelectedRowKeys.length)
        selectedKeys[forwarderCompanyIdx].forwarders = newSelectedRowKeys;
      else {
        selectedKeys.splice(forwarderCompanyIdx, 1);
      }
    } else {
      selectedKeys.push({ companyId, forwarders: newSelectedRowKeys });
    }
    setSelectedFowarders(selectedKeys);
  };

  const onSelectChange = (selectedUsers: React.Key[]) => {
    const mappedForwarders: ForwardersKeys[] = [];
    selectedUsers.forEach((key) => {
      const ids = key.toString().split('/');
      const idx = mappedForwarders.findIndex(
        (value) => value.companyId === ids[0]
      );
      if (idx >= 0) {
        mappedForwarders[idx].forwarders.push(ids[1]);
      } else {
        mappedForwarders.push({
          companyId: ids[0],
          forwarders: [ids[1]],
        });
      }
    });

    setSelectedFowarders(mappedForwarders);
  };

  const companyRowSelection: TableRowSelection<IForwarderEntity> = {
    selectedRowKeys: selectedForwarder.map(
      (selectedKeys) => selectedKeys.companyId
    ),
    onChange: onSelectCompanyRowChange,
  };

  const hasSelected = selectedForwarder.length > 0;
  const isCompanySelected = (id: string) =>
    hasSelected && selectedForwarder.find((ff) => ff.companyId === id);

  // state

  const [
    inviteForwarders,
    {
      isLoading: isInvitating,
      isError: isInvitationError,
      error: invitationError,
      isSuccess,
      data: invitingRes,
    },
  ] = useInviteForwardersMutation();

  // methods
  const onInviteForwarders = async (selectedForwarders: ForwardersKeys[]) => {
    if (shipmentId) {
      const ids = selectedForwarders.flatMap(
        (forwarder) => forwarder.forwarders as string[]
      );

      const body = selectedForwarders.map(({ companyId, forwarders }) => ({
        companyId: companyId as string,
        forwarders: forwarders as string[],
      }));

      await inviteForwarders({ shipmentId, body });

      inviteForwarderEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        shipment_id: shipmentId,
        forwarder_id: ids,
        company_id: user?.companyId,
      });
    }
  };

  const onInviteOneForwarder = async (companyId: string) => {
    const target = selectedForwarder.find(
      (forwarder) => forwarder.companyId === companyId
    );
    if (target) await onInviteForwarders([target]);
  };

  // hooks
  useEffect(() => {
    if (isInvitationError && invitationError) {
      displayErrors(invitationError, {
        title: 'Errors while inviting forwarders',
      });
    }
  }, [isInvitationError, invitationError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Invitation sent to forwarders !' });
      setSelectedFowarders([]);
    }
  }, [isSuccess, invitingRes]);

  const allColumns: ColumnsType<IForwarderEntity> = [
    {
      key: 'company',
      title: 'Company',
      render: (value, record, index) => {
        return (
          <Space>
            <ProfileImage name={record.name} photoUrl={null} />
            <Typography.Text>{record.name}</Typography.Text>
          </Space>
        );
      },
      sorter: (recordA, recordB) => recordA.name.localeCompare(recordB.name),
      filters: getCompaniesList(forwarders ?? []),
      onFilter: (value: string | number | boolean, record) =>
        record.name.startsWith(value.toString()),
      filterSearch: true,
    },
    {
      key: 'users',
      title: 'Recipients',
      render: (value, record, index) => {
        const invited = record.forwarders
          .filter((user) => user.invited)
          .map((user) => computeFullName(user));
        return invited.length > 0 ? (
          <Tooltip title={invited.join(', ')}>
            <Typography.Paragraph
              type="secondary"
              style={{ width: 200 }}
              ellipsis={{
                symbol: ` +${invited.length} more`,
                rows: 1,
                expandable: true,
              }}
            >
              {invited.join(', ')}
            </Typography.Paragraph>
          </Tooltip>
        ) : (
          <Typography.Text strong type="secondary">
            --
          </Typography.Text>
        );
      },
    },
    {
      key: 'pin',
      width: '2%',
      render: (_: unknown, record: IForwarderEntity, index: number) =>
        record.pinned ? (
          <Button
            icon={<Icon component={PinFilled} />}
            type="text"
            disabled={isPinCompnayLoading}
            onClick={() => onPinClick(record.companyConnectionId, false)}
          />
        ) : (
          <Button
            icon={<Icon component={PinOutline} />}
            type="text"
            disabled={isPinCompnayLoading}
            onClick={() => onPinClick(record.companyConnectionId, true)}
          />
        ),
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
    },
    {
      key: 'action',
      width: '2%',
      render: (_: unknown, record: IForwarderEntity, index: number) =>
        isCompanySelected(record.id) ? (
          <Button
            type="default"
            onClick={() => onInviteOneForwarder(record.id)}
          >
            Invite
          </Button>
        ) : (
          <Button type="default" onClick={() => onSelectAllCompany(record.id)}>
            Select All
          </Button>
        ),
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
    },
    {
      key: 'expand',
      width: '2%',
      render: (_: unknown, record: IForwarderEntity, index: number) => (
        <Button icon={<DownCircleTwoTone />} type="text" />
      ),
    },
  ];
  return (
    <DashboardCard headerTitle="Forwarders List" large fitContent>
      <Row justify="space-between">
        <Col span="16">
          <Select
            className="users-search"
            mode="multiple"
            optionFilterProp="children"
            placeholder="Search a user by email, name or company name"
            onChange={onSelectChange}
            options={computeForwarderSelectOptions(forwarders ?? [])}
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input)
            }
            tokenSeparators={[',']}
            maxTagCount="responsive"
            tagRender={forwarderTagRender}
            value={selectedForwarder.flatMap((ff) =>
              ff.forwarders.map((user) => `${ff.companyId}/${user}`)
            )}
          />
        </Col>

        <Space>
          <Button
            type="primary"
            disabled={!hasSelected || isInvitating}
            onClick={() => onInviteForwarders(selectedForwarder)}
          >
            Request Quotes
            {selectedForwarder.length ? ` (${selectedForwarder.length})` : ''}
          </Button>
          <AddForwarderForm shipmentId={shipmentId} />
        </Space>
      </Row>
      {isError ? (
        <Alert
          description="An error has occured while fetching recent forwarders .. "
          type="error"
          showIcon
        />
      ) : (
        <Table
          loading={isLoading}
          className="forwarders-table"
          dataSource={forwarders ?? []}
          columns={allColumns}
          rowSelection={companyRowSelection}
          locale={{ emptyText: 'No recent forwarders added' }}
          rowKey={(item: IForwarderEntity) => item.id}
          pagination={false}
          expandable={{
            fixed: false,
            expandRowByClick: true,
            showExpandColumn: false,
            expandedRowRender: (record: IForwarderEntity) => (
              <ForwarderContactsTable
                companyId={record.id}
                forwarders={record.forwarders}
                onInviteForwarders={onInviteForwarders}
                contactsRowSelection={{
                  selectedRowKeys: getContactRowsKeys(
                    selectedForwarder,
                    record.id
                  ),
                  onChange: (selectedKeys) =>
                    onSelectContactRowChange(selectedKeys, record.id),
                }}
              />
            ),
          }}
        />
      )}
    </DashboardCard>
  );
};

export default RecentForwarders;
