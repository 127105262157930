import { Table, Space, Typography, TableProps } from 'antd';
import { useGetPurchaseOrdersQuery } from 'api/purchase-order';
import React, { FC, useEffect, useState } from 'react';
import { setIsBookingAvailable } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IPurchaseOrderEntity } from 'types/entities/purchase-order.entity';
import { PaginatorParams } from 'types/feature/pagination.types';
import {
  IPurchaseOrderFilters,
  IPurchaseOrdersTableProps,
} from 'types/feature/purchase-orders.types';

import { ReactComponent as EmptyOrders } from '../../../../../assets/orders.svg';
import ExpandableRow from '../ExpandableRow';

const { Text } = Typography;
const defaultPagination: PaginatorParams = {
  page: 1,
  limit: 10,
};

const PurchaseOrderTable: FC<IPurchaseOrdersTableProps> = ({
  columns,
  isBookingOngoing,
  shipmentId,
  isDraft,
  search,
  selectedFilters,
}) => {
  const isShipmentTap = !!shipmentId;
  const [detailedRows, setDetailedRows] = useState<string[]>([]);
  const [params, setParams] = useState<PaginatorParams>(defaultPagination);
  const [filters, setFilters] = useState<Partial<IPurchaseOrderFilters>>({
    ...(shipmentId ? { shipmentId } : {}),
    isDraft,
  });
  // hooks
  const dispatch = useAppDispatch();
  const { data: resp, isFetching } = useGetPurchaseOrdersQuery(
    {
      ...params,
      ...filters,
      ...selectedFilters,
    },
    { refetchOnMountOrArgChange: true }
  );

  // methods
  const onExpandRow = (expanded: boolean, record: IPurchaseOrderEntity) => {
    if (expanded) {
      setDetailedRows([...detailedRows, record.id]);
    } else {
      const items = detailedRows.filter((id: string) => id !== record.id);
      setDetailedRows(items);
    }
  };

  useEffect(() => {
    if (resp) {
      setDetailedRows([]);
      dispatch(setIsBookingAvailable({ isAvailable: resp.items.length > 0 }));
    }
  }, [resp, dispatch]);

  const onChange: TableProps<IPurchaseOrderEntity>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const mappedFilters = {
      ...(filters.status
        ? { status: filters.status as unknown as string }
        : {}),
      ...(filters.modality
        ? { modality: filters.modality as unknown as string }
        : {}),
      ...(shipmentId ? { shipmentId } : {}),
      isDraft,
      search,
    };

    // map filters

    setFilters(mappedFilters);
  };

  useEffect(() => {
    setFilters((filters) => ({ ...filters, search }));
  }, [search]);

  useEffect(() => {
    setParams({ ...params, page: 1 });
    // eslint-disable-next-line
  }, [selectedFilters]);

  return (
    <Table
      columns={columns}
      dataSource={resp?.items ?? []}
      className="po-table"
      rowClassName={'po-row'}
      rowKey="id"
      expandable={{
        expandedRowRender: (record: IPurchaseOrderEntity) => (
          <ExpandableRow
            purchaseOrder={record}
            isBookingOngoing={isBookingOngoing}
            isShipmentTab={isShipmentTap}
            shipmentId={shipmentId}
          />
        ),
        rowExpandable: (record) => true,
        onExpand: onExpandRow,
        expandedRowKeys: detailedRows,
        expandedRowClassName: (record) => 'po-row-expanded__sub',
      }}
      size="middle"
      loading={isFetching}
      pagination={{
        showQuickJumper: true,
        showSizeChanger: true,
        pageSize: params.limit,
        pageSizeOptions: ['5', '10', '50', '100'],
        total: resp?.meta?.totalItems,
        current: params.page,
        onChange: (page, pageSize) => {
          setParams({ page: page, limit: pageSize });
        },
      }}
      locale={{
        emptyText: (
          <Space direction="vertical" className="empty-table-wrapper">
            <EmptyOrders />
            <Text strong>Purchase Orders</Text>
            <Text type="secondary">Create new orders to view items</Text>
          </Space>
        ),
      }}
      onChange={onChange}
    />
  );
};

export default PurchaseOrderTable;
