import { ArrowRightOutlined } from '@ant-design/icons';
import { Typography, Space, Row, Col } from 'antd';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import {
  IAirTrackingEntity,
  ITrackingEntity,
} from 'types/entities/tracking.entity';

import { removeZFromISO } from 'utils/common';

const { Text } = Typography;

export const renderTrackingEvent: FunctionComponent<{
  item: ITrackingEntity;
}> = ({ item }) => {
  const date = dayjs(removeZFromISO(item?.date)).format('DD.MM.YY');

  return (
    <Row key={item.id} justify="space-between" align="bottom">
      <Space key={item.id} direction="vertical" size={[1, 1]}>
        <Text className="event-name" strong>
          {item.description}
        </Text>
        <Text type="secondary">
          {item.portName}, {item.countryCode}
        </Text>
      </Space>
      <Text className="event-date" type="secondary">
        {date}
      </Text>
    </Row>
  );
};
export const renderAirTrackingEvent: FunctionComponent<{
  item: IAirTrackingEntity;
  isCourier: boolean;
}> = ({ item, isCourier }) => {
  const date =
    item?.timeOfEvent &&
    dayjs(removeZFromISO(item.timeOfEvent)).format('DD.MM.YY HH:mm');

  return (
    <Row key={item.id} justify="space-between" align="bottom">
      <Col span={18}>
        <Space key={item.id} direction="vertical" size={[1, 1]}>
          <Text strong className="event-name">
            {item.type ?? item.statusDescription}
          </Text>
          {isCourier ? (
            <Text strong type="secondary">
              {item.details}
            </Text>
          ) : item.origin ? (
            <Text strong type="secondary">
              {item.origin} <ArrowRightOutlined /> {item.destination}
            </Text>
          ) : (
            <Text strong type="secondary">
              {item.destination ?? item.airportOfDelivery}
            </Text>
          )}
        </Space>
      </Col>
      <Col span={6}>
        <Text className="event-date">{date}</Text>
      </Col>
    </Row>
  );
};

export const renderContainerTrackingEvent: FunctionComponent<{
  item: ITrackingEntity;
}> = ({ item }) => {
  const date = dayjs(removeZFromISO(item?.date)).format('DD MMM YY');

  return (
    <Row key={item.id} justify="space-between" align="bottom">
      <Space key={item.id} direction="vertical" size={[1, 1]}>
        <Text className={`event-title${item.actual ? '__checked' : ''}`} strong>
          {item.portName}, {item.countryCode}
        </Text>
        <Text type="secondary">{item.description}</Text>
      </Space>
      <Text className="event-date" type="secondary">
        {date}
      </Text>
    </Row>
  );
};
