import { BellFilled } from '@ant-design/icons';
import {
  Row,
  Dropdown,
  Typography,
  Divider,
  Badge,
  theme,
  Empty,
  Skeleton,
  Tooltip,
  Button,
} from 'antd';
import {
  useLazyGetNotificationsQuery,
  useUpdateNotificationMutation,
  useUpdateAllNotificationsMutation,
} from 'api/notification';
import { useLazyGetPropsalByIdQuery } from 'api/proposal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useProposalState } from 'providers/proposal-provider';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  INotification,
  NotificationEntity,
  INotifications,
  INotificationRequest,
} from 'types/entities/notification.entity';

import {
  notifcationsShowAllEvent,
  notifcationsShowUnreadEvent,
  notificationClearEvent,
  notificationViewEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import {
  cleanNotificationText,
  filterNotifications,
} from './Notification.utils';
import NotificationsFooter from './NotificationsFooter';
import NotificationsHeader from './NotificationsHeader';
import './notification.scss';
import { usePusher } from './usePusherBeam.hook';
import { usePusherChannel } from './usePusherChannel.hook';

dayjs.extend(relativeTime);

const { useToken } = theme;

const { Title, Text } = Typography;
const AppNotifications: FC = () => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const proposalContext = useProposalState();
  const { setShowProposalDetailsModal, setSelectedProposal } = proposalContext;
  const [defaultParams, setDefaultParams] = useState<INotificationRequest>({
    page: 1,
    limit: 15,
    read: true,
  });
  const [notifications, setNotifications] = useState<INotifications>({
    items: [],
  });
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const [showUnreadNotificationsOnly, setShowUnreadNotificationsOnly] =
    useState<boolean>(false);
  const [getNotifications, { isLoading, data, error, isFetching }] =
    useLazyGetNotificationsQuery();

  // segment events
  const viewNotificationsCTA = () => {
    if (currentUser) {
      notificationViewEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
      });
    }
  };

  const showUnreadNotificationsCTA = () => {
    if (currentUser) {
      notifcationsShowUnreadEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
      });
    }
  };

  const showMoreNotificationsCTA = () => {
    if (currentUser) {
      notifcationsShowAllEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
      });
    }
  };

  const markAllNotificationsAsReadCTA = () => {
    if (currentUser) {
      notificationClearEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
      });
    }
  };

  // methods
  const fetchNotifications = async (defaultParams: INotificationRequest) => {
    try {
      await getNotifications(defaultParams).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to fetch data' });
    }
  };
  useEffect(() => {
    fetchNotifications(defaultParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultParams]);

  const [updateNotification] = useUpdateNotificationMutation();
  const [updateAllNotifications] = useUpdateAllNotificationsMutation();
  const { token } = useToken();
  const navigate = useNavigate();

  const MarkAllNotificationsAsRead = async () => {
    try {
      await updateAllNotifications().unwrap();
      markAllNotificationsAsReadCTA();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to update data' });
    }
  };
  usePusher(currentUser);
  usePusherChannel(currentUser);

  useEffect(() => {
    setNotifications(data);
  }, [data]);

  const onDropdownOpenChange = (open: boolean) => {
    setDropdownOpen(open);
    if (open) {
      viewNotificationsCTA();
    }
  };

  const onDropdownClose = () => {
    setDropdownOpen(false);
  };

  const handleNavigation = (notification: INotification) => {
    const url = notification.notificationBody.url.split('=')[1];
    const documentId =
      notification.notificationBody.documentIds &&
      notification.notificationBody.documentIds.length > 0
        ? notification.notificationBody.documentIds[0]
        : null;
    const proposalId = notification.notificationBody.proposalId;

    if (documentId) {
      navigate(url, { state: { documentId } });
    } else if (proposalId) {
      navigate(notification.notificationBody.url, { state: { proposalId } });
    } else {
      navigate(notification.notificationBody.url);
    }
  };

  const handleShowMore = (event) => {
    setDefaultParams({
      ...defaultParams,
      limit: defaultParams.limit + 5,
    });
    showMoreNotificationsCTA();
  };
  const [getProposalById] = useLazyGetPropsalByIdQuery();
  const fetchProposal = async (proposalId: string) => {
    try {
      return await getProposalById(proposalId).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to get proposal' });
    }
  };
  const handleMarkAsRead = async (notification: INotification) => {
    try {
      await updateNotification(notification.id).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to update data' });
    }
  };
  const handleMarkAsReadClick = (
    notification: INotification,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    handleMarkAsRead(notification);
  };
  const navigateToNotification = async (notification: INotification) => {
    onDropdownClose();
    handleMarkAsRead(notification);
    handleNavigation(notification);
    if (notification.notificationBody.proposalId) {
      const proposalId = notification.notificationBody.proposalId;
      const proposal = await fetchProposal(proposalId);
      if (proposal) {
        setShowProposalDetailsModal(true);
        setSelectedProposal(proposal);
      }
    }
  };
  const handleShowOnlyUnread = (checked: boolean) => {
    setDefaultParams({ ...defaultParams, read: !checked });
    setShowUnreadNotificationsOnly(checked);
    showUnreadNotificationsCTA();
  };
  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const renderNotificationItems = (): NotificationEntity[] => {
    if (isLoading) {
      return [
        {
          key: 'loading',
          label: <Skeleton />,
        },
      ];
    }

    if (error || !notifications) {
      return [
        {
          key: 'error',
          label: (
            <div className="error">
              <Text>Error retrieving the data ..</Text>
            </div>
          ),
        },
      ];
    }
    return filterNotifications(
      notifications.items,
      showUnreadNotificationsOnly
    ).map((notification: INotification) => ({
      key: notification.id,
      label: (
        <>
          <div
            className="notification-card"
            onClick={() => navigateToNotification(notification)}
          >
            <div className="notification-text">
              <Title className="notification-title" level={4}>
                {notification.notificationBody.title}
              </Title>
              <Title
                className="notification-reference"
                level={5}
                type="secondary"
              >
                {notification.notificationBody.reference}
              </Title>
              <Text className="notification-message" type="secondary" ellipsis>
                {cleanNotificationText(notification.notificationBody.body)}
              </Text>
            </div>
            <div className="notification-status">
              {!notification.read && (
                <div className="unread-icon">
                  <Tooltip title="Mark as read">
                    <Button
                      type="text"
                      onClick={(event) => {
                        handleMarkAsReadClick(notification, event);
                      }}
                    >
                      <Badge status="processing" />
                    </Button>
                  </Tooltip>
                </div>
              )}
              <Text type="secondary">
                {dayjs(notification.createdAt).fromNow()}
              </Text>
            </div>
          </div>
          <Divider className="notification-divider" />
        </>
      ),
    }));
  };

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <Row className="notification-wrapper">
      <Dropdown
        menu={{ items: renderNotificationItems() }}
        onOpenChange={onDropdownOpenChange}
        open={dropdownOpen}
        placement="topRight"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
        dropdownRender={(menu) => (
          <div className="notification-dropdown" style={contentStyle}>
            <NotificationsHeader
              MarkAllNotificationsAsRead={MarkAllNotificationsAsRead}
              handleShowOnlyUnread={handleShowOnlyUnread}
              onDropdownClose={onDropdownClose}
              currentUser={currentUser}
            />

            {(notifications?.items.length > 0 &&
              !showUnreadNotificationsOnly) ||
            (showUnreadNotificationsOnly && data?.meta.unreadCount > 0) ? (
              <>
                <div className="notification-dropdown__scroll-wrapper">
                  {React.cloneElement(menu as React.ReactElement, {
                    style: menuStyle,
                  })}
                </div>
                <NotificationsFooter
                  isAllLoading={!!(defaultParams.limit < data?.meta.totalItems)}
                  isFetching={isFetching}
                  handleShowMore={handleShowMore}
                />
              </>
            ) : (
              <div className="notification-dropdown__empty">
                <Empty description="no notifications" />
              </div>
            )}
          </div>
        )}
      >
        <Badge count={data ? data.meta.unreadCount : 0} overflowCount={99}>
          <BellFilled />
        </Badge>
      </Dropdown>
    </Row>
  );
};

export default AppNotifications;
