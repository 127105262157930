import React from 'react';

import './accordion.scss';

export interface IAccordionItemDef {
  key: string;
  label: string | JSX.Element;
  children: JSX.Element;
  index?: number;
}

export interface IAccordionProps {
  defaultActiveKey?: string;
  items: IAccordionItemDef[];
  activeKey: string;
  type: 'filled' | 'outlined';
  footer?: (index: number, key: string) => JSX.Element | null;
  onChange: (key: string, prevKey: string) => void;
}

// Import custom CSS file for styling
export interface IPanelProps {
  item: IAccordionItemDef;
  activeKey: string;
  type: 'filled' | 'outlined';
  showHeader: boolean;
  onClick: (key: string, prevKey: string) => void;
  footer?: (index: number, key: string) => JSX.Element | null;
}

export const Panel: React.FC<IPanelProps> = ({
  item,
  type,
  activeKey,
  showHeader,
  footer,
  onClick,
}) => {
  const onTabClick = () => onClick(item.key, activeKey);
  const isActive = !!(activeKey === item.key);
  const renderHeader = () => (
    <div className="panel-header" onClick={onTabClick}>
      {item.label}
    </div>
  );

  return (
    <div className={`panel ${type}`}>
      {isActive && showHeader && renderHeader()}

      {!isActive && renderHeader()}

      {isActive && (
        <>
          <div className={`panel-content ${activeKey && 'show'}`}>
            {item.children}
          </div>

          <div className="panel-footer">
            {footer && footer(item.index || 0, item.key)}
          </div>
        </>
      )}
    </div>
  );
};

const Accordion: React.FC<IAccordionProps> = ({
  activeKey,
  items,
  type,
  footer,
  onChange,
}) => {
  return (
    <>
      <div className="accordion">
        {items.map((item, index) => (
          <Panel
            showHeader={true}
            item={{ ...item, index }}
            key={item.key}
            activeKey={activeKey}
            onClick={onChange}
            footer={footer}
            type={type}
          />
        ))}
      </div>
    </>
  );
};

export default Accordion;
