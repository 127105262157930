import {
  IPurchaseOrderCollaboratorCreateBody,
  IPurchaseOrderCollaboratorUpdateBody,
} from 'types/entities/purchase-order.entity';
import { IPurchaseOrderCollaborators } from 'types/feature/purchase-orders.types';

import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const purchaseOrderCollaboratorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPurchaseOrderCollaboratorsById: build.query<
      ApiResponse<IEntityList<IPurchaseOrderCollaborators>>,
      { purchaseOrderId: string; search?: string; withCollaborators?: boolean }
    >({
      query: ({ purchaseOrderId, ...payload }) => ({
        url: `/purchase-order/collaborators/${purchaseOrderId}`,
        method: 'GET',
        params: payload,
      }),
      providesTags: ['PurchaseOrderCollaborators'],
    }),

    createPurchaseOrderCollaborator: build.mutation<
      string[],
      IPurchaseOrderCollaboratorCreateBody
    >({
      query: (body) => ({
        url: `/purchase-order/collaborators`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PurchaseOrderCollaborators'],
    }),
    updatePurchaseOrderCollaborator: build.mutation<
      string[],
      IPurchaseOrderCollaboratorUpdateBody
    >({
      query: ({ purchaseOrderId, ...rest }) => ({
        url: `/purchase-order/collaborators/${purchaseOrderId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['PurchaseOrderCollaborators'],
    }),
    deletePurchaseOrderCollaboratorById: build.mutation<
      void,
      { purchaseOrderId: string; collaboratorId: string }
    >({
      query: ({ purchaseOrderId, collaboratorId }) => ({
        url: `/purchase-order/collaborators/${purchaseOrderId}/${collaboratorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PurchaseOrderCollaborators'],
    }),
  }),
});

export const {
  useGetPurchaseOrderCollaboratorsByIdQuery,
  useCreatePurchaseOrderCollaboratorMutation,
  useUpdatePurchaseOrderCollaboratorMutation,
  useDeletePurchaseOrderCollaboratorByIdMutation,
} = purchaseOrderCollaboratorsApi;
