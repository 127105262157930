import {
  ICompanySetting,
  IUpdateCompanySettingsReqBody,
} from 'types/entities/setting.entity';

import { api } from './base-api';

export const companySettingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanySettings: build.query<ICompanySetting[], void>({
      query: () => ({ url: `/company-settings` }),
      providesTags: ['CompanySettings'],
    }),

    updateCompanySettings: build.mutation<
      ICompanySetting[],
      IUpdateCompanySettingsReqBody
    >({
      query: (body) => ({
        url: `/company-settings`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CompanySettings'],
    }),
  }),
});

export const { useGetCompanySettingsQuery, useUpdateCompanySettingsMutation } =
  companySettingApi;
