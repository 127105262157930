import { Button, Form, Modal, Typography, notification } from 'antd';
import BiddingDetailsForm from 'app/components/ShipmentCreation/ShipmentCreationForm/BiddingDetailsForm';
import { computeBiddingFormDate } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { IEditBiddingDateModalProps } from 'types/feature/bidding';
import { IBiddingDetailsForm } from 'types/feature/create-shipment.types';

import './edit-bidding-date-modal.scss';

const EditBiddingDateModal: FC<IEditBiddingDateModalProps> = ({
  deadline,
  isUpdateShipmentLoading,
  isOpen,
  onClose,
  onUpdateBidClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [biddingDetailsForm] = Form.useForm<IBiddingDetailsForm>();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const onUpdateDeadline = async () => {
    try {
      await biddingDetailsForm.validateFields();
      const biddingDetailsFormValue = await biddingDetailsForm.getFieldsValue();
      const computedDate = computeBiddingFormDate(biddingDetailsFormValue);

      if (computedDate) {
        onUpdateBidClick(computedDate);
      }
    } catch (error: unknown) {
      notification.warning({
        message: 'Wrong inputs',
        description: 'Make sure to fill all mandotary fields correctly !',
      });
    }
  };

  return (
    <>
      <Modal
        title="Change Deadline"
        open={isModalOpen}
        onCancel={handleClose}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="booking-party-form"
            onClick={onUpdateDeadline}
            loading={isUpdateShipmentLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          Choose a new date / hour to extend the bidding deadline
        </Typography.Paragraph>
        <div className="bidding-detail-form-wrapper">
          <BiddingDetailsForm
            defaultValues={{
              dueDate: dayjs(deadline),
              dueTime: dayjs(deadline),
            }}
            form={biddingDetailsForm}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditBiddingDateModal;
