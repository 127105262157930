import Icon, { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  message,
  notification,
} from 'antd';
import { useUpdateExtractedDocumentByIdMutation } from 'api/document-extractor';
import {
  useEditFileByIdMutation,
  useLazyGetFilesByShipmentQuery,
} from 'api/file';
import Papa from 'papaparse';
import React, { FC, useEffect, useState } from 'react';
import {
  IDocumentTableView,
  ISaveExtractedDocumentForm,
  TDcoumentExtractorTableTypes,
} from 'types/feature/document-extractor.types';

import { downloadFile } from 'utils/download-helper';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as DownloadIcon } from '../../../../assets/download-icon-blue.svg';
import { ReactComponent as DeleteBinIcon } from '../../../../assets/icons/bin-icon-filled-grey.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/chevron-down-icon.svg';
import SaveDocumentModal from '../SaveDocumentModal';
import {
  computeCsvColumns,
  computeCsvData,
  computeCsvToExcelLink,
  generateRandonId,
  removeRandomIds,
} from '../document-extractor.utils';
import { EditableRow, EditableCell } from './EditableTable';
import './document-table-view.scss';

export const DocumentTableView: FC<IDocumentTableView> = ({
  extractedData,
  shipmentId,
  onModalClose,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [csvData, setCsvData] = useState<any[]>([]);

  const [updateDocument, { isLoading: isUpdatingDocument }] =
    useEditFileByIdMutation();

  const [updateExtractedDocument, { isLoading: isUpdatingExtractedDocument }] =
    useUpdateExtractedDocumentByIdMutation();

  const [refetchFilesByShipment] = useLazyGetFilesByShipmentQuery();

  const onDeleteColumn = async (column: string) => {
    const newCsvData = csvData.map((item) => {
      const { [column]: removedProperty, ...rest } = item;
      return rest;
    });

    setCsvData(newCsvData);
  };

  const renderTitleDropdown = (column: string) => {
    return (
      <Dropdown
        menu={{
          items: [
            {
              key: 'remove-column',
              label: 'Remove Column',
              onClick: () => onDeleteColumn(column),
            },
          ],
        }}
      >
        <Button type="text" icon={<ChevronDownIcon />} size="small" />
      </Dropdown>
    );
  };

  const csvColumns: TDcoumentExtractorTableTypes = computeCsvColumns(
    csvData,
    renderTitleDropdown
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRecordSave = (row: any, dataIndex: string) => {
    setCsvData((prevData) => {
      const itemIndex = prevData.findIndex((item) => item.__id === row.__id);

      if (itemIndex !== -1) {
        const newData = [...prevData];
        newData[itemIndex] = { ...newData[itemIndex], ...row };
        return newData;
      }

      return prevData;
    });
  };

  const onDownloadExcelClick = () => {
    if (csvData) {
      downloadFile(
        computeCsvToExcelLink(removeRandomIds(csvData)),
        'shipment-document'
      );
    }
  };

  const onExtractedFileSave = async (isDraft: boolean) => {
    const csv = Papa.unparse(removeRandomIds(csvData));

    try {
      await updateExtractedDocument({
        fileId: extractedData.parsedFileId,
        csv,
        excelFile: extractedData.excelFile,
        isDraft,
      });

      notification.success({
        message: 'Document Saved successfully!',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error updating CSV' });
    }
  };

  const onEditClose = () => {
    setIsEditModalOpen(false);
  };

  const onSaveClick = () => {
    onExtractedFileSave(false);
  };

  const onEditSave = async (values: ISaveExtractedDocumentForm) => {
    try {
      await updateDocument({
        id: extractedData.parsedFileId,
        name: values.name,
      });
      await onExtractedFileSave(false);

      if (shipmentId) {
        await refetchFilesByShipment(shipmentId);
      }
    } catch (error) {
      message.warning('Make sure to fill all fields !');
    }
    onEditClose();
  };

  const tableComponents = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  // Add a new row
  const onAddRow = () => {
    const newRow = Object.keys(csvData[0]).reduce((row, key) => {
      return {
        ...row,
        __id: generateRandonId(),
        [key]: '',
      };
    }, {});

    setCsvData((prevState) => {
      return [...prevState, newRow];
    });
  };

  const onAddColumn = () => {
    const colIndex = csvColumns.length + 1;
    const colName = `New Column ${colIndex}`;

    const newCsvDta = csvData.map((column) => ({
      ...column,
      [colName]: '',
    }));

    setCsvData([...newCsvDta]);
  };

  const onDeleteRow = async (id: string, index) => {
    const newData = csvData.filter((item) => item.__id !== id);
    setCsvData(newData);
  };

  const ediatableColumns = [
    ...csvColumns.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title as string,
          handleSave: onRecordSave,
        }),
      };
    }),
    {
      title: 'Actions',
      dataIndex: 'actions',
      editable: true,
      key: 'actions',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_, record: any, rowIndex) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => onDeleteRow(record.__id, rowIndex)}
        >
          <Button type="link" icon={<DeleteBinIcon />} />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (extractedData.csv) {
      setCsvData(computeCsvData(extractedData.csv));
    }
  }, [extractedData]);

  return (
    <>
      <Table
        rowKey="__id"
        size="small"
        components={tableComponents}
        columns={ediatableColumns}
        dataSource={csvData}
        className="docs-table-wrapper"
        rowClassName="docs-editable-row"
        pagination={false}
        scroll={{ x: '100%' }}
      />
      <Space className="my-2">
        <Button icon={<PlusOutlined />} onClick={onAddRow} size="small">
          Add Row
        </Button>
        <Button icon={<PlusOutlined />} onClick={onAddColumn} size="small">
          Add Column
        </Button>
      </Space>
      <div className="footer-wrapper">
        <Space>
          <Button
            type="link"
            className="download-btn"
            icon={<Icon component={DownloadIcon} />}
            onClick={onDownloadExcelClick}
          >
            Download Excel File
          </Button>
          {/* <Button icon={<Icon component={CopyIcon} />}>Copy</Button> */}
        </Space>
        <Space>
          <Button onClick={onModalClose} disabled={isUpdatingExtractedDocument}>
            Close
          </Button>
          {/* <Button
            onClick={onSaveAsDraftClick}
            loading={isUpdatingExtractedDocument}
          >
            Save as draft
          </Button> */}
          <Button
            type="primary"
            onClick={onSaveClick}
            loading={isUpdatingExtractedDocument}
          >
            Save
          </Button>
        </Space>
      </div>
      {isEditModalOpen && shipmentId && (
        <SaveDocumentModal
          shipmentId={shipmentId}
          file={extractedData.parsedFile}
          isOpen={isEditModalOpen}
          onClose={onEditClose}
          onSave={onEditSave}
          isLoading={isUpdatingDocument || isUpdatingExtractedDocument}
        />
      )}
    </>
  );
};
