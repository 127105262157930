import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, MenuProps, Row, Space } from 'antd';
import { useUpdateBasicInfosMutation } from 'api/user';
import CreatePurchaseOrderModal from 'app/components/CreatePurchaseOrderModal';
import ImportShipmentsByTrackingModal from 'app/components/ImportShipments/ImportShipmentsModal';
import SignupOnboardingModal from 'app/components/UserOnboarding/SignupOnboardingModal';
import NavigationBreadcrumb from 'app/design-system/Breadcrumbs';
import { useFeatureFlags } from 'providers/feature-flag-provider';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { userLogout } from 'state/slices/user.slice';
import { IBaseAnalyticsEvents } from 'types/feature/analytics-events.type';

import {
  createShipmentCtaClickEvent,
  messagesPageVisitEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as MessagesIcon } from '../../../assets/menuIcons/messages.svg';
import AppNotifications from './Notification';
import { beamsClient } from './Notification/pusher.utils';
import SearchInput from './SearchInput';
import './header.style.scss';

const ErrorComponent: React.FC = () => {
  throw new Error('This is a test error thrown during render');
};

const MessagesButton: FC<{ navigateToMessagesCenter }> = ({
  navigateToMessagesCenter,
}) => {
  // we can include badge for count of total unread messages
  return (
    <Button
      icon={<MessagesIcon />}
      type="text"
      onClick={navigateToMessagesCenter}
      className="messages-btn"
    />
  );
};
const renderCreateDropdownItems = (
  onCreateShipmentClick
): MenuProps['items'] => {
  return [
    {
      key: '1',
      label: (
        <Link
          className="create-dropdown-item"
          to={{ pathname: '/shipments/create' }}
          onClick={onCreateShipmentClick}
        >
          New shipment
        </Link>
      ),
    },
    {
      key: '2',
      label: <ImportShipmentsByTrackingModal />,
    },
    {
      key: '3',
      label: <CreatePurchaseOrderModal text="New Purchase Order" />,
    },
  ];
};

const Header: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [throwErrorBoundry, setThrowErrorBoundry] = useState(false);

  const { featureFlags } = useFeatureFlags();
  const isSignupOnboardingEnabled = featureFlags
    ? featureFlags['FEATURE_SIGNUP_ONBOARDING_ENABLED']
    : false;

  const isTestOnboardingBtnEnabled = featureFlags
    ? featureFlags['FEATURE_TEST_ONBOARDING_BTN_ENABLED']
    : false;

  const [updateOnboardingFlagInfos] = useUpdateBasicInfosMutation();

  const onLogout = () => {
    const notificationClient = beamsClient();
    if (notificationClient) {
      notificationClient
        ?.stop()
        .then(() => dispatch(userLogout({})))
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      dispatch(userLogout({}));
    }
  };

  const onCreateShipmentClick = () => {
    if (user) {
      createShipmentCtaClickEvent({
        user_id: user.id,
        email: user.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }
  };

  const profileMenu: MenuProps = {
    items: [
      {
        key: 'profile',
        label: 'Profile',
        onClick: () => navigate('/profile'),
      },

      {
        key: 'settings',
        label: 'Settings',
        onClick: () => navigate('/settings'),
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: 'Sign out',
        onClick: onLogout,
      },
    ],
  };

  useEffect(() => {
    if (user && user.showOnboardingOnLogin) {
      setShowOnboardingModal(true);
    }
  }, [user]);

  const onOboardingModalClose = async () => {
    setShowOnboardingModal(false);

    if (user && user.showOnboardingOnLogin) {
      try {
        await updateOnboardingFlagInfos({ showOnboardingOnLogin: false });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'Error on onbaording flag update' });
      }
    }
  };
  const navigateToMessagesCenter = () => {
    navigate('/messages');
    messagesPageVisitEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
    });
  };

  const throwRandomError = () => {
    const errors = [Error, TypeError, RangeError];
    const randomError = errors[Math.floor(Math.random() * errors.length)];
    throw new randomError('Random error occurred.');
  };

  return (
    <Layout.Header className="header-wrapper">
      <Row className="header-row" justify="space-between" align="middle">
        <NavigationBreadcrumb />

        <Space size={16} align="center">
          <>
            {throwErrorBoundry && <ErrorComponent />}
            <Button ghost onClick={() => setThrowErrorBoundry(true)}>
              Error Boundery
            </Button>
            <Button ghost onClick={throwRandomError}>
              Random Error
            </Button>

            {/* TODO: REMOVE ONCE TESTING IS COMPLETE */}
            {isTestOnboardingBtnEnabled && (
              <>
                <Button ghost onClick={() => setShowOnboardingModal(true)}>
                  Test Onboarding
                </Button>
              </>
            )}
            {isSignupOnboardingEnabled && showOnboardingModal && (
              <SignupOnboardingModal
                isOpen={showOnboardingModal}
                onClose={onOboardingModalClose}
              />
            )}
          </>
          <Dropdown
            overlayClassName="create-dropdown"
            menu={{ items: renderCreateDropdownItems(onCreateShipmentClick) }}
            placement="bottom"
            arrow={{ pointAtCenter: true }}
            trigger={['click']}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              data-chameleon="create-shipment-cta"
            >
              Create
            </Button>
          </Dropdown>
          <SearchInput />
          <MessagesButton navigateToMessagesCenter={navigateToMessagesCenter} />
          <AppNotifications />

          <Dropdown menu={profileMenu} trigger={['click']}>
            <Avatar icon={<UserOutlined />} size={40} src={user?.photo} />
          </Dropdown>
        </Space>
      </Row>
    </Layout.Header>
  );
};

export default Header;
