import { ICommentEntity } from 'types/entities/comment.entity';
import {
  ICreatePurchaseOrderCommentPayload,
  IGetPurchaseOrderCommentPayload,
} from 'types/feature/purchase-orders.types';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const purchaseOrderCommentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPurchaseOrderCommentsById: build.query<
      ApiResponse<{ comments: ICommentEntity[] }>,
      IGetPurchaseOrderCommentPayload
    >({
      query: ({ purchaseOrderId }) => ({
        url: `/purchase-order-comments/${purchaseOrderId}`,
        method: 'GET',
      }),

      providesTags: ['PurchaseOrderComments'],
    }),

    createPurchaseOrderComment: build.mutation<
      ApiResponse<ICommentEntity>,
      ICreatePurchaseOrderCommentPayload
    >({
      query: (body) => ({
        url: `/purchase-order-comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PurchaseOrderComments'],
    }),
  }),
});

export const {
  useGetPurchaseOrderCommentsByIdQuery,
  useLazyGetPurchaseOrderCommentsByIdQuery,
  useCreatePurchaseOrderCommentMutation,
} = purchaseOrderCommentsApi;
