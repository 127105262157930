import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import {
  IStatusTimelineItem,
  IStatusTimelineProps,
} from 'types/feature/shipments.types';

import { dateFormatConst } from 'utils/shipment-type';

import { ReactComponent as CheckCircleFilled } from '../../../assets/icons/check-circle-filled.svg';
import { ReactComponent as CheckCircleOutline } from '../../../assets/icons/check-circle-outline.svg';
import './status-timeline.scss';

const { Text } = Typography;

const StatusTimeline: FC<IStatusTimelineProps> = ({ items }) => {
  return (
    <div className="timeline-wrapper">
      {items.map((item: IStatusTimelineItem, index: number) => {
        return (
          <div key={item.key} className="timeline-item-wrapper">
            <div className="item">
              <Icon
                className="icon"
                component={
                  item.isActive ? CheckCircleFilled : CheckCircleOutline
                }
              />
              <Text className={item.isActive ? 'label active' : 'label'} strong>
                {item.label}
              </Text>
              <Text className="date">
                {item.date && dayjs(item.date).format(dateFormatConst)}
              </Text>
            </div>

            {items.length - 1 !== index && (
              <div className={item.isActive ? 'divider active' : 'divider'} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StatusTimeline;
