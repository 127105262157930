import { EditFilled, DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import { useDeleteBookingByIdMutation } from 'api/bookings';
import React, { FC, FunctionComponent } from 'react';
import {
  removeAllBookingItems,
  removeCreatedBookingId,
} from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IBookingEntity } from 'types/entities/booking.entity';

import { displayCountry } from 'utils/format-fields';

import { bookingColumns } from '../created-booking';
import '../created-booking.scss';
import { mapPOItemsToBookingItems } from './helper';

const { Text } = Typography;
const InfoWrapper: FunctionComponent<{ title: string; info: string }> = ({
  info,
  title,
}) => (
  <Space direction="vertical" size={[1, 1]}>
    <Text>{title}</Text>
    <Text type="secondary">{info} </Text>
  </Space>
);
const { confirm } = Modal;

const BookingCard: FC<{
  booking: IBookingEntity;
  onUpdateClick: () => void;
}> = ({ booking, onUpdateClick }) => {
  const dispatch = useAppDispatch();
  // queries
  const [deleteOneBooking, { isLoading: isDeleting }] =
    useDeleteBookingByIdMutation();

  const onBookingDeleted = () => {
    // remove items
    booking.bookingPurchaseOrders.forEach((item) => {
      dispatch(removeAllBookingItems({ order: item.purchaseOrder.id }));
    });

    // remove booking id
    dispatch(removeCreatedBookingId({ id: booking.id }));
  };
  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',
      icon: null,
      content: 'Are you sure you want to delete this booking?',
      onOk: async () => {
        await deleteOneBooking(id)
          .unwrap()
          .then(() => {
            onBookingDeleted();
            notification.success({
              message: 'Booking Deleted',
              description: `The selected booking has been deleted successfully ! `,
            });
          });
      },
    });
  };

  return (
    <div className="booking-card">
      <Row justify={'space-between'} className="bookink-section">
        <Space direction="vertical" size={[1, 1]}>
          <Text className="booking-title">Booking {booking.reference}</Text>
          <Text type="secondary">{booking.supplier.company?.name} </Text>
        </Space>
        <Space>
          <Button
            size="small"
            icon={<EditFilled />}
            type="text"
            onClick={onUpdateClick}
            disabled={isDeleting}
          ></Button>
          <Button
            size="small"
            icon={<DeleteFilled />}
            type="text"
            onClick={() => onDeleteClick(booking.id)}
            disabled={isDeleting}
          ></Button>
        </Space>
      </Row>
      <Row justify={'space-between'} className="bookink-section">
        <InfoWrapper title={'Origin'} info={displayCountry(booking.origin)} />
        <InfoWrapper
          title={'Destination'}
          info={displayCountry(booking.destination)}
        />
        <InfoWrapper
          title={'Supplier'}
          info={`${booking.supplier.fullname} `}
        />
      </Row>
      <Table
        columns={bookingColumns}
        dataSource={mapPOItemsToBookingItems(booking)}
        rowKey="id"
        size="small"
        className="items-table"
        pagination={false}
      />
    </div>
  );
};

export default BookingCard;
