import { ICostHeadEntity } from 'types/entities/contract.entity';

export const getTotalCost = (costHeads: ICostHeadEntity[]) =>
  costHeads.reduce(
    (prev, current) => {
      prev.rate += current.rate ?? 0;
      return prev;
    },
    { rate: 0 }
  ).rate;
