import {
  MetabaseEntity,
  MetabaseQueryReq,
} from 'types/entities/metabase.entity';

import { api } from './base-api';

export const metabaseTokenApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboardToken: build.query<MetabaseEntity, MetabaseQueryReq>({
      query: (params) => {
        return {
          url: `/dashboard/token`,
          params,
        };
      },
    }),
  }),
});

export const { useGetDashboardTokenQuery } = metabaseTokenApi;
