import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ShipmentCreationAccordion from 'app/components/ShipmentCreation/ShipmentCreationAccordion';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC } from 'react';
import {
  ShipmentCreationScreenEnum,
  IShipmentIFormScreenProps,
} from 'types/feature/shipment-onboarding';

const ShipmentFormScreen: FC<IShipmentIFormScreenProps> = ({
  onScreenChange,
  defaultShipment,
}) => {
  const navigateToShipmentInviteFFScreen = () =>
    onScreenChange(ShipmentCreationScreenEnum.INVITE_FORWARDER_SCREEN);

  const navigateToAIFormScreen = () =>
    onScreenChange(ShipmentCreationScreenEnum.AI_FORM_SCREEN);

  return (
    <div className="sh-form-screen">
      <ModalHeader
        title="Create a Shipment"
        leftControls={
          <Button
            type="default"
            icon={<LeftOutlined />}
            onClick={navigateToAIFormScreen}
          />
        }
      />

      <div className="sh-form-screen__content">
        <ShipmentCreationAccordion
          defaultShipment={defaultShipment}
          onCreateSuccess={navigateToShipmentInviteFFScreen}
        />
      </div>
    </div>
  );
};

export default ShipmentFormScreen;
