import { api } from 'api/base-api';
import { freightAIConvertionApi } from 'api/freight-ai';
import { externalProposalApi } from 'api/proposal-external.api';
import { combineReducers } from 'redux';
import chatSlice from 'state/slices/chat.slice';
import createPurchaseOrderSlice from 'state/slices/create-purchase-order.slice';
import createShipmentSlice from 'state/slices/create-shipment.slice';
import draftBookingSlice from 'state/slices/draft-booking.slice';
import userSlice from 'state/slices/user.slice';

import { authApi } from '../../api/auth';
import { userApi } from '../../api/user';
import trackingByAiReducer from '../slices/trackingByAi.slice';

const rootReducer = combineReducers({
  trackingByAi: trackingByAiReducer,
  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [freightAIConvertionApi.reducerPath]: freightAIConvertionApi.reducer,
  [api.reducerPath]: api.reducer,
  [externalProposalApi.reducerPath]: externalProposalApi.reducer,
  user: userSlice,
  chat: chatSlice,
  booking: draftBookingSlice,
  createShipment: createShipmentSlice,
  createPurchaseOrder: createPurchaseOrderSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
