import {
  ShrinkOutlined,
  ExpandAltOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { resetBookings } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { SplitScreenMode } from 'types/entities/purchase-order.entity';

import { purchaseOrderBookingCreationStartEvent } from 'utils/analytics-events';

import PurchaseOrderBooking from '../PurchaseOrderBooking';
import PurchaseOrdersTabs from './PurchaseOrdersTabs';
import './purchase-orders.scss';

const getSpanByMode = (mode: SplitScreenMode, inverse: boolean) => {
  switch (mode) {
    case SplitScreenMode.ONLY_PO:
      return inverse ? 0 : 24;
    case SplitScreenMode.BOTH:
      return inverse ? 12 : 12;
    case SplitScreenMode.ONLY_BOOKING:
      return inverse ? 24 : 0;
  }
};

const PurchaseOrder: FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const isExpanded = (mode) => mode === SplitScreenMode.ONLY_BOOKING;

  const [splitScreenMode, setSplitScreenMode] = useState<SplitScreenMode>(
    SplitScreenMode.ONLY_PO
  );

  const onCreateBooking = () => {
    setSplitScreenMode(SplitScreenMode.BOTH);

    purchaseOrderBookingCreationStartEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      source: 'createBookingCta',
      company_id: user?.companyId,
    });
  };

  const onCloseBookingTab = () => {
    dispatch(resetBookings({}));
    setSplitScreenMode(SplitScreenMode.ONLY_PO);
  };

  const onExpandBookingTab = () => {
    setSplitScreenMode(SplitScreenMode.ONLY_BOOKING);
  };
  return (
    <Row justify="space-between" data-testid="purchase-order-page">
      <Col
        span={getSpanByMode(splitScreenMode, false)}
        className={
          splitScreenMode === SplitScreenMode.ONLY_PO
            ? ''
            : 'purchase-order-wrapper'
        }
      >
        <PurchaseOrdersTabs
          mode={splitScreenMode}
          onCreateBooking={onCreateBooking}
        />
      </Col>
      <Col
        span={getSpanByMode(splitScreenMode, true)}
        className="booking-wrapper"
      >
        <DashboardCard
          headerTitle="Bookings"
          noMargin
          fitContent
          rightAccessory={
            <Space>
              {isExpanded(splitScreenMode) ? (
                <Tooltip title="Shrink Bookings tab">
                  <Button
                    type="text"
                    icon={<ShrinkOutlined />}
                    onClick={onCreateBooking}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Expand Bookings tab">
                  <Button
                    type="text"
                    icon={<ExpandAltOutlined />}
                    onClick={onExpandBookingTab}
                  />
                </Tooltip>
              )}

              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={onCloseBookingTab}
              ></Button>
            </Space>
          }
        >
          <PurchaseOrderBooking
            mode={splitScreenMode}
            onCloseBookingTab={onCloseBookingTab}
            onCreateBooking={onCreateBooking}
          />
        </DashboardCard>
      </Col>
    </Row>
  );
};

export default PurchaseOrder;
