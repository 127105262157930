import React from 'react';

import { ReactComponent as I1 } from '../../../assets/dash-icon1.svg';
import { ReactComponent as I2 } from '../../../assets/dash-icon2.svg';
import { ReactComponent as I3 } from '../../../assets/dash-icon3.svg';
import { ReactComponent as I4 } from '../../../assets/dash-icon4.svg';
import { ReactComponent as I5 } from '../../../assets/dash-icon5.svg';

const matchIndicatorToLogo = (title: string) => {
  switch (title.toLocaleLowerCase()) {
    case 'rfq requested':
      return <I1 />;
    case 'rfq submitted':
      return <I3 />;
    case 'in origin':
      return <I4 />;
    case 'in destination':
      return <I5 />;
    default:
      return <I2 />;
  }
};

export { matchIndicatorToLogo };
