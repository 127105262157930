import { Typography } from 'antd';
import React, { FC } from 'react';
import { InvoiceAnalyticCardProps } from 'types/feature/invoice-list';

const InvoiceAnalyticCard: FC<InvoiceAnalyticCardProps> = ({
  title,
  subtitle,
  isToBeCollected,
  isReceived,
}) => {
  return (
    <div className="card-wrapper">
      <div className="invoice-card-content">
        <Typography.Title
          className={`card-title${
            isReceived
              ? '__received'
              : isToBeCollected
              ? '__to-be-collected'
              : ''
          }`}
          level={4}
        >
          {title}
        </Typography.Title>
        <Typography.Text>{subtitle}</Typography.Text>
      </div>
    </div>
  );
};

export default InvoiceAnalyticCard;
