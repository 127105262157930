import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { UserRoleEnum } from 'types/entities/user.entity';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';

import ProfileImage from '../ProfilePicture';

export const collaboratorColumnsConst: ColumnsType<IRecentCollaboratorDef> = [
  {
    key: 'avatar',
    render: (value, record, index) => {
      return <ProfileImage name={record.fullname} photoUrl={record.photo} />;
    },
  },
  {
    key: 'name',
    render: (value, record, index) => {
      return <Typography.Text>{record.fullname}</Typography.Text>;
    },
  },
  {
    key: 'email',
    dataIndex: 'email',
    render: (value, record, index) => {
      return <Typography.Text type="secondary">{record.email}</Typography.Text>;
    },
  },
  // {
  //   key: 'company',
  //   render: (value, record, index) => {
  //     return (
  //       <Typography.Text type="secondary">
  //         {record?.company?.name}
  //       </Typography.Text>
  //     );
  //   },
  // },
];

export const roleOptionConst = [
  {
    label: 'Cargo Owner',
    value: UserRoleEnum.CARGO_OWNER,
  },
  {
    label: 'Forwarder',
    value: UserRoleEnum.FORWARDER,
  },
  {
    label: 'Custom Agency',
    value: UserRoleEnum.CUSTOMS_AGENCY,
  },
  {
    label: 'Local Transporter',
    value: UserRoleEnum.LOCAL_TRANSPORTER,
  },
  {
    label: 'Supplier',
    value: UserRoleEnum.SUPPLIER,
  },
  {
    label: 'Other',
    value: UserRoleEnum.OTHER,
  },
];
