import {
  IUserPartnerEntity,
  ITeamResponseBody,
  IUserConnectionEntity,
} from 'types/entities/company.entity';
import { PaginatorParams } from 'types/feature/pagination.types';

import {
  UpdateCompanyRequest,
  UpdatePasswordRequest,
  IUserEntity,
  IGetConnectionsParams,
  ICreateUserEntity,
  IUserBaseEntity,
} from '../types/entities/user.entity';
import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ApiResponse<IUserEntity>, void | null>({
      query: () => '/user/profile',
      providesTags: ['Profile'],
      // TODO: ONLY FOR DEV TESTING
      // transformResponse: (resp: ApiResponse<IUserEntity>) => {
      //   return {
      //     ...resp,
      //     data: {
      //       ...resp.data,
      //       showOnboardingOnLogin: true,
      //     },
      //   };
      // },
    }),

    updateBasicInfos: builder.mutation<
      ApiResponse<IUserEntity>,
      Partial<IUserEntity>
    >({
      query: (user: Partial<IUserEntity>) => ({
        url: '/user/details',
        body: { ...user },
        method: 'PUT',
      }),
      invalidatesTags: ['Profile'],
    }),

    updatePassword: builder.mutation<
      ApiResponse<string>,
      UpdatePasswordRequest
    >({
      query: (payload: UpdatePasswordRequest) => ({
        url: '/user/change-password',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Profile'],
    }),

    updateCompanyInfos: builder.mutation<
      ApiResponse<string>,
      UpdateCompanyRequest
    >({
      query: (payload: UpdateCompanyRequest) => ({
        url: '/company',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Profile'],
    }),

    getUserConnections: builder.query<
      IUserConnectionEntity[],
      IGetConnectionsParams | null
    >({
      query: (params: IGetConnectionsParams) => ({
        url: '/user-connection',
        params: params,
      }),
      providesTags: ['Connections'],
    }),

    getExternalUserConnections: builder.query<IUserBaseEntity[], void>({
      query: () => ({
        url: '/user-connection/external-user-connections',
      }),
    }),

    getUserCompany: builder.query<
      ApiResponse<ITeamResponseBody>,
      PaginatorParams
    >({
      query: (params) => ({ url: '/user/list', params: params }),
      providesTags: ['Team'],
    }),
    addCompanyUser: builder.mutation<
      ApiResponse<ITeamResponseBody>,
      Partial<IUserEntity>
    >({
      query: (params) => ({
        url: '/user-connection/add-member',
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Team'],
    }),

    inviteUser: builder.mutation<
      ApiResponse<IUserEntity>,
      Partial<ICreateUserEntity>
    >({
      query: (params) => ({
        url: '/user/invite',
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Connections'],
    }),

    updateUserById: builder.mutation<
      ApiResponse<IUserEntity>,
      Partial<ICreateUserEntity>
    >({
      query: (params) => ({
        url: `/user-connection/${params.userId}`,
        body: params,
        method: 'PATCH',
      }),
      invalidatesTags: ['Connections'],
    }),

    deleteUser: builder.mutation<ApiResponse<IUserEntity>, string>({
      query: (id) => ({
        url: `/user-connection/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Connections'],
    }),

    getTeamMembers: builder.query<IUserPartnerEntity[], void>({
      query: () => ({ url: 'user-connection/team-members' }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetUserCompanyQuery,
  useGetUserConnectionsQuery,
  useGetExternalUserConnectionsQuery,
  useUpdatePasswordMutation,
  useUpdateCompanyInfosMutation,
  useUpdateBasicInfosMutation,
  useAddCompanyUserMutation,
  useInviteUserMutation,
  useDeleteUserMutation,
  useUpdateUserByIdMutation,
  useGetTeamMembersQuery,
} = userApi;
