import { Collapse, CollapseProps, Divider } from 'antd';
import BookingParties from 'app/components/BookingParties';
import OriginDestinationPreview from 'app/components/ShipmentDetails/OriginDestinationPreview';
import ShipmentInfo from 'app/components/ShipmentDetails/ShipmentInfo';
import ShipmentProgress from 'app/components/ShipmentDetails/ShipmentProgress';
import ShipmentTasks from 'app/components/ShipmentDetails/ShipmentTasks';
import ShipmentTracking from 'app/components/ShipmentDetails/ShipmentTracking';
import React, { FC } from 'react';
import { IShipmentEntity } from 'types/entities/shipment.entity';

import '../style.scss';

const ShipmentOverview: FC<{ shipment: IShipmentEntity }> = ({ shipment }) => {
  const renderItems: CollapseProps['items'] = [
    {
      label: 'Shipment Details',
      children: <ShipmentInfo shipment={shipment} cardOverlay={false} />,
      key: 'details',
    },
    {
      label: 'Live Tracking',
      children: (
        <ShipmentTracking
          shipment={shipment}
          showMap={false}
          cardOverlay={false}
        />
      ),
      key: 'tracking',
    },
    {
      label: 'Tasks',
      children: <ShipmentTasks shipment={shipment} cardOverlay={false} />,
      key: 'tasks',
    },
    {
      label: 'Booking Parties',
      key: 'booking',
      children: <BookingParties shipment={shipment} cardOverlay={false} />,
    },
  ];
  return (
    <>
      <ShipmentProgress shipment={shipment} />
      <OriginDestinationPreview shipment={shipment} />
      <Divider />
      <Collapse items={renderItems} defaultActiveKey="details" />
    </>
  );
};

export default ShipmentOverview;
