import {
  Alert,
  Button,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useGetQuotesForReviewQuery } from 'api/dashboard';
import BiddingCounter from 'app/components/Bidding/BiddingCounter';
import { forwarderRequestedAndSubmittedQuoteCount } from 'app/components/Proposal/helpers/helper';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ShipmentsCardProps } from 'types/feature/dashboard.types';

import {
  quotesForReviewClickEvent,
  viewAllQuotesClickEvent,
} from 'utils/analytics-events';
import {
  formatShipmentState,
  formatOriginToDestDashBoard,
  formatBids,
} from 'utils/format-fields';
import { isForwarder } from 'utils/shipment-helper';

import './quotes-card.scss';

const QuotesCard: FC<ShipmentsCardProps> = ({ navigateToShipmentsPage }) => {
  const user = useSelector((state: RootState) => state.user.user);

  const {
    data: quotes,
    isLoading,
    error,
  } = useGetQuotesForReviewQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const onRow = (record: IShipmentEntity, index: number | undefined) => {
    return {
      onClick: () => {
        quotesForReviewClickEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company.name,
          company_id: user?.companyId,
        });
        navigateToShipmentsPage({
          id: record.id,
          slug: record.slug,
        });
      },
    };
  };

  const columns: ColumnsType<IShipmentEntity> = [
    {
      key: 'status',
      dataIndex: 'status',
      render: (value, record, index) => {
        const text = record.shipmentReference && `${record.slug}`;

        return (
          <Space
            className="shipment-details"
            direction="vertical"
            align="center"
            size={2}
          >
            <div className="status">{formatShipmentState(record.status)}</div>
            <div className="shipment-text">
              <Typography.Paragraph className="shipment-text--slug">
                {text}
              </Typography.Paragraph>
              <Tooltip
                placement="top"
                title={record.shipmentReference ? record.shipmentReference : ''}
              >
                <Typography.Paragraph className="shipment-text--slug">
                  {record.shipmentReference ? record.shipmentReference : ''}
                </Typography.Paragraph>
              </Tooltip>
            </div>
          </Space>
        );
      },
      align: 'center',
    },
    {
      key: 'fromTo',
      render: (value, record, index) => {
        return formatOriginToDestDashBoard(record);
      },
      align: 'center',
    },
    {
      key: 'deadline',
      render: (value, record, index) => {
        const isUserForwarder = isForwarder(record.currentUserRole);

        const forwarderQuotes = isUserForwarder
          ? forwarderRequestedAndSubmittedQuoteCount(
              record.proposals as IProposalEntity[],
              user?.companyId as string
            )
          : null;

        return (
          <div className="bidding-count">
            {record.dueDate && (
              <BiddingCounter
                shipmentParams={{
                  id: record.id,
                  slug: record.slug,
                }}
                deadline={record.dueDate}
                showDropDownActions={!isUserForwarder}
                trigger="click"
              />
            )}
            {isUserForwarder ? (
              <Typography.Paragraph className="quoted-text">{`${forwarderQuotes?.submittedQuotes} / ${forwarderQuotes?.requestedQuotes} quoted`}</Typography.Paragraph>
            ) : (
              <Typography.Paragraph className="quoted-text">{`${record.submittedQuotes} / ${record.totalQuotes} quoted`}</Typography.Paragraph>
            )}
          </div>
        );
      },
      align: 'center',
    },
    {
      key: 'bids',
      render: (value, record, index) => {
        const isNotForwarder = !isForwarder(record.currentUserRole);
        return isNotForwarder ? formatBids(record) : '';
      },
      align: 'center',
    },
  ];
  const onViewAllQuotes = () => {
    viewAllQuotesClickEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company.name,
      company_id: user?.companyId,
    });
    navigateToShipmentsPage({}, 'pending-quotations');
  };

  return (
    <DashboardCard
      headerTitle="Quotes for review"
      rightAccessory={
        <Button type="text" onClick={onViewAllQuotes}>
          View All Quotes
        </Button>
      }
      fitContent
    >
      {error && (
        <Alert showIcon message="Unexpected error while fetching quotes" />
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          className="quotes-table"
          rowClassName="quotes-row"
          columns={columns}
          showHeader={false}
          dataSource={quotes ?? []}
          showSorterTooltip
          pagination={{ hideOnSinglePage: true }}
          size="middle"
          rowKey="id"
          onRow={onRow}
        />
      )}
    </DashboardCard>
  );
};

export default QuotesCard;
