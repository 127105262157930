import { Button, Row, Space } from 'antd';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotFoungBG } from '../../../assets/notFound.svg';

const NotFound: FC = () => {
  const navigate = useNavigate();
  return (
    <Row align="middle" justify="center" style={{ marginTop: '30px' }}>
      <Space direction="vertical" size={[24, 24]} align="center">
        <NotFoungBG />
        <Button
          type="primary"
          onClick={() => navigate('/dashboard')}
          className="my-1"
        >
          Back Home
        </Button>
      </Space>
    </Row>
  );
};

export default NotFound;
