import { TCanAssignInvoiceMethod } from 'types/feature/invoice.types';

export const canAssignInvoice: TCanAssignInvoiceMethod = ({
  shipmentCreator,
  invoiceCreator,
  user,
}) => {
  return !!(
    shipmentCreator?.id === user?.id ||
    shipmentCreator?.company?.id === invoiceCreator?.company?.id
  );
};
