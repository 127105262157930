import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import PhoneInput from 'antd-phone-input';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useSignupMutation } from 'api/auth';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect } from 'react';
import { AuthRequestBody, PhoneNumber } from 'types/entities/auth.entity';
import { IBaseAnalyticsContext } from 'types/feature/analytics-events.type';
import { AuthTypeEnum, ISignUpFormProps } from 'types/feature/auth.types';

import { signupCompleteEvent, signupPageEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { formatPhoneNumber } from 'utils/inputs-validators';

import { ReactComponent as BeeboltLinedLogo } from '../../../../assets/appIcons/beebolt-icon.svg';
import SocialAuthButtons from '../SocialAuthButtons';
// style
import './signup-form.style.scss';

const { Item } = Form;
const { Text } = Typography;

const AuthSignUpForm: FC<ISignUpFormProps> = ({
  login,
  navigateToMainPage,
}) => {
  const [form] = useForm();
  const [signup, { isSuccess, isLoading }] = useSignupMutation();
  const campaign = useUTMState();

  // methods
  const onSubmit = async () => {
    let values: AuthRequestBody;
    try {
      values = await form.validateFields(); // Validate the form fields
    } catch (errorInfo) {
      return;
    }

    try {
      const resp = await signup({
        ...values,
        mobileNumber: formatPhoneNumber(values.mobileNumber as PhoneNumber),
      }).unwrap();

      if (resp && resp?.data) {
        const context: IBaseAnalyticsContext = {
          campaign: campaign,
        };
        signupCompleteEvent(
          {
            user_id: resp?.data?.userId || '',
            email: resp?.data?.email || '',
            company: resp?.data?.company?.name || '',
            company_id: resp?.data?.company?.id || '',
            first_name: resp?.data?.fullname || '',
            last_name: '',
            phone_number: resp.data?.mobileNumber || '',
            type: 'Normal',
          },
          context
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Oops!' });
    }
  };

  // hooks

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Account created successfully',
        description: 'Check your inbox to confirm your account email !',
      });
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isSuccess, login]);

  useEffect(() => {
    signupPageEvent({ campaign });
  }, [campaign]);

  // component
  return (
    <div className="auth-form">
      <BeeboltLinedLogo />
      <Typography.Title level={2}>Create your account</Typography.Title>

      {isSuccess ? (
        <div className="signup-success-message">
          <Typography.Title level={4}>
            Your account successfully created 🎉 !
          </Typography.Title>

          <Typography.Text>
            Follow the link in the email you received to verify and confirm your
            email address!
          </Typography.Text>
        </div>
      ) : (
        <>
          <SocialAuthButtons
            navigateToMainPage={navigateToMainPage}
            mode={AuthTypeEnum.SIGNUP}
          />

          <Divider>
            <Text type="secondary">OR</Text>
          </Divider>
          <Form layout="vertical" form={form}>
            <Item
              label="Full Name"
              name="fullName"
              key="fullName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your fullname',
                },
              ]}
            >
              <Input placeholder="Enter your fullname" />
            </Item>
            <FormItem
              label="Phone number"
              name="mobileNumber"
              key="mobileNumber"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Phone Number!',
                },
                () => ({
                  validator(rule, value) {
                    if (value && value.valid) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'The inserted phone number  is not valid !'
                    );
                  },
                }),
              ]}
            >
              <PhoneInput enableSearch />
            </FormItem>
            <Item
              label="Company Email"
              name="email"
              key="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email!',
                },
                {
                  type: 'email',
                  warningOnly: true,
                  message: 'Please enter a valid email !',
                },
              ]}
            >
              <Input type="email" placeholder="Enter a valid email" />
            </Item>

            <Item
              label="Password"
              name="password"
              key="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your password!',
                },
                {
                  min: 8,
                  message: 'Password needs to be at least 8 characters long !',
                },
              ]}
            >
              <Input.Password type="password" placeholder="******" />
            </Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('Should accept agreement')),
                },
              ]}
            >
              <Checkbox>
                I agree to the Beebolt’s{' '}
                <a href="https://beebolt.com/saas-agreement/" target="_blanck">
                  Terms & Conditions
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="auth-form__sign-btn"
                onClick={onSubmit}
                disabled={isLoading}
                icon={isLoading && <LoadingOutlined />}
              >
                Sign up
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      <Row align="middle" justify="center">
        <Typography.Text>You already have an account ?</Typography.Text>
        <Button type="link" onClick={login}>
          Log In
        </Button>
      </Row>
    </div>
  );
};

export default AuthSignUpForm;
