import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, Statistic, notification } from 'antd';
import { useEditShipmentMutation } from 'api/shipment';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IBiddingCounter } from 'types/feature/bidding';

import { changeShipmentBiddingClickEvent } from 'utils/analytics-events';
import { isBiddingEnded } from 'utils/bidding-date-helper';
import { displayErrors } from 'utils/error-notification';

import EditBiddingDateModal from '../EditBiddingDateModal';
import './bidding-counter.scss';

const { Countdown } = Statistic;

const BiddingCounter: FC<IBiddingCounter> = ({
  trigger,
  deadline: biddingDeadline,
  fitContent,
  showDropDownActions,
  shipmentParams,
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [deadline, setDeadline] = useState<string>(biddingDeadline);
  const days = dayjs(deadline).diff(dayjs(), 'day');
  const [isEditBidOpen, setIsEditBidOpen] = useState<boolean>(false);

  const [updateShipment, { isLoading: isUpdateShipmentLoading }] =
    useEditShipmentMutation();

  const onEditBiddingTimeCta = (change_details: string) => {
    changeShipmentBiddingClickEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      company_id: user?.companyId,
      shipment_id: shipmentParams?.id,
      change_details,
    });
  };

  useEffect(() => {
    setDeadline(biddingDeadline);
  }, [biddingDeadline]);

  const onUpdateDeadline = async (
    date: string | null,
    isCancel: boolean,
    cta_type: 'extend_24_hours' | 'change_deadline' | 'cancel_bidding'
  ) => {
    if (!shipmentParams) return;

    await updateShipment({
      shipmentId: shipmentParams.id,
      shipment: { dueDate: date },
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: isCancel ? 'Bidding Canceled' : 'Deadline Changed',
          description: isCancel
            ? 'Bidding canceled successfully'
            : 'Deadline changed successfully',
        });
        onEditBiddingTimeCta(cta_type);
        if (date) {
          setDeadline(date);
        }
      })
      .catch((error) => {
        displayErrors(error, { title: 'Change Bidding Due Date Error' });
      });
  };

  const onCancelBidClick = async () => {
    const date = dayjs().toISOString();
    await onUpdateDeadline(date, true, 'cancel_bidding');
  };

  const onExtendBidding = async () => {
    const deadlineDayjs = dayjs(deadline).isBefore(dayjs())
      ? dayjs()
      : dayjs(deadline);
    const extendedDeadline = deadlineDayjs.add(1, 'day').toISOString();
    await onUpdateDeadline(extendedDeadline, false, 'extend_24_hours');
  };

  const onUpdateBidClick = async (updatedDueDate: string | null) => {
    await onUpdateDeadline(updatedDueDate, false, 'change_deadline');
    setIsEditBidOpen(false);
  };

  const onEditBidClick = () => {
    setIsEditBidOpen(true);
  };

  const onEditBidClose = () => {
    setIsEditBidOpen(false);
  };

  const menu: MenuProps = {
    items: [
      {
        key: 'extend',
        label: 'Extend 24 hrs',
        onClick: onExtendBidding,
      },
      {
        key: 'edit',
        label: 'Change Deadline',
        onClick: onEditBidClick,
      },
      ...(!isBiddingEnded(deadline)
        ? [
            {
              key: 'cancel',
              label: 'Cancel Bidding',
              onClick: onCancelBidClick,
            },
          ]
        : []),
    ],
  };
  const deadlineDayjs = dayjs(deadline);
  const now = dayjs();
  const diffInHours = deadlineDayjs.diff(now, 'hour');

  const renderCountdown = (
    <div
      className={` ${
        fitContent
          ? 'shipment-bidding-countdown__fit'
          : diffInHours < 2
          ? 'shipment-bidding-countdown__danger'
          : 'shipment-bidding-countdown'
      } ${trigger ? 'bidding-dashboard' : ''}`}
    >
      {isBiddingEnded(deadline) ? (
        <Space>
          <span className="bidding-text">Bidding has ended</span>
          {showDropDownActions && <DownOutlined className="dropdown-icon" />}
        </Space>
      ) : (
        <div
          className={`bidding-counter-wrapper ${
            showDropDownActions ? 'cursor-pointer' : ''
          }`}
        >
          {!trigger && <span className="bidding-text">Deadline:</span>}
          <Countdown
            value={deadline}
            format={days > 0 ? 'DD day and HH:mm:ss' : 'H[hrs] m[min] s[s]'}
          />
          {showDropDownActions && <DownOutlined className="dropdown-icon" />}
        </div>
      )}
    </div>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {showDropDownActions ? (
        <Dropdown
          menu={menu}
          trigger={trigger ? [trigger] : ['click', 'hover']}
          disabled={!showDropDownActions}
          overlayClassName={
            trigger
              ? 'Bidding-counter__dropdown__dashboard'
              : 'bidding-counter__dropdown'
          }
        >
          {renderCountdown}
        </Dropdown>
      ) : (
        renderCountdown
      )}

      {shipmentParams && (
        <EditBiddingDateModal
          deadline={deadline}
          isOpen={isEditBidOpen}
          isUpdateShipmentLoading={isUpdateShipmentLoading}
          onClose={onEditBidClose}
          onUpdateBidClick={onUpdateBidClick}
        />
      )}
    </div>
  );
};

export default BiddingCounter;
