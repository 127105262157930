import { Button, Modal, Typography } from 'antd';
import React, { FC } from 'react';
import { SuccessSubmitModalProps } from 'types/feature/success-modal.types';

import './success-modal.scss';

const { Text, Title } = Typography;
const ProposalSuccessModal: FC<SuccessSubmitModalProps> = ({
  isOpen,
  onGoToDashboard,
  onCloseModal,
}) => {
  return (
    <Modal open={isOpen} footer={null} destroyOnClose onCancel={onCloseModal}>
      <div className="modal-container">
        <Title level={3}>Thank you for submitting your quote 🎉</Title>
        <Text className="modal-content">
          The cargo owner will review your quote and will get back to you as
          soon as possible.
        </Text>

        <Button type="primary" onClick={onGoToDashboard}>
          Go to Dashboard
        </Button>
      </div>
    </Modal>
  );
};

export default ProposalSuccessModal;
