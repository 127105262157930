import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IFileEntity } from 'types/entities/files.entity';

import { flattenedEnumValue } from 'utils/common';

export const documentTableConst: ColumnsType<IFileEntity> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, file) => (
      <a href={file.file} target="_blanck">
        {file.name}
      </a>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'type',
    key: 'type',
    render: (_, file) => flattenedEnumValue(file.type),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (_, file) => dayjs(file.updatedAt).format('MMM DD, YYYY'),
  },
  {
    title: 'Shared with',
    dataIndex: 'shared-with',
    key: 'shared-with',
    render: (_, file) =>
      file.companies.map((company) => company.name).join(', '),
  },
];
