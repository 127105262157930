import {
  DeleteFilled,
  DownOutlined,
  DownloadOutlined,
  EditFilled,
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import {
  Badge,
  Button,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useLazyGetBookingsCollaboratorsByShipmentIdQuery } from 'api/Booking-collaborators.ts';
import {
  bookingApi,
  useDeleteBookingByIdMutation,
  useDownloadBookingsSheetMutation,
  useGetBookingsCountQuery,
  useGetBookingsQuery,
  useUnassignBookingsToShipmentMutation,
} from 'api/bookings';
import { useInviteExistingCollaboratorMutation } from 'api/collaborators';
import AppTabs from 'app/design-system/AppTabs';
import CheckboxList from 'app/design-system/CheckList';
import { useAdjustColumns } from 'app/hooks/adjustColumns.hook';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IBookingCount, IBookingEntity } from 'types/entities/booking.entity';
import {
  BookingTabEnum,
  EditMode,
  IBookingsDrawerState,
  IBookingsTableProps,
} from 'types/feature/bookings.types';
import { PaginatorParams } from 'types/feature/pagination.types';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { isViewer } from 'utils/collaborators';
import { downloadExcelFileFromBuffer } from 'utils/download-excel-file';
import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';

import { ReactComponent as AddUserIcon } from '../../../../assets/icons/add-user-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove_circle_outline.svg';
import { ReactComponent as EmptyOrders } from '../../../../assets/orders.svg';
import { BookingAssignButton } from '../BookingAssignButton';
import BookingAssignDrawer from '../BookingAssignDrawer';
import BookingCreateModal from '../BookingCreateModal';
import BookingUpdateDrawer from '../BookingUpdateDrawer';
import '../bookings.scss';
import { canAssignBooking } from '../helpers/assign-booking.helper';
import BookingsCollaborators from './BookingCollaborators';
import { bookingColumnConst, columns } from './bookings-table.const';

const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;
const defaultPagination: PaginatorParams = {
  page: 1,
  limit: 10,
};

const getBookingStatusByTab = (status: BookingTabEnum) => {
  switch (status) {
    case BookingTabEnum.IN_PROGRESS:
      return true;
    case BookingTabEnum.UNASSIGNED:
      return false;
    default:
      return undefined;
  }
};

const displayBookingTabTitle = (
  status: BookingTabEnum,
  count: IBookingCount | undefined
) => {
  switch (status) {
    case BookingTabEnum.IN_PROGRESS:
      return (
        <Space>
          In Progress <Badge count={count?.assigned} color="blue" />
        </Space>
      );
    case BookingTabEnum.UNASSIGNED:
      return (
        <Space>
          Unassigned <Badge count={count?.unassigned} color="blue" />
        </Space>
      );
    default: {
      const total = count ? count?.assigned + count?.unassigned : 0;
      return (
        <Space>
          All Bookings
          <Badge count={total} color="blue" />
        </Space>
      );
    }
  }
};

const BookingsTable: FC<IBookingsTableProps> = ({
  shipmentId,
  shipmentSlug,
  currentCollaborator,
}) => {
  const isShipmentTab = !!shipmentId;
  const isActionEnabled =
    !!currentCollaborator && !isViewer(currentCollaborator.accessLevel);
  const { selectColumns, selectedColumns } = useAdjustColumns(
    columns,
    UserTablesEnum.BookingTable
  );
  const [params, setParams] = useState<PaginatorParams>(defaultPagination);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [defaultKey, setTabKey] = useState<BookingTabEnum>(BookingTabEnum.ALL);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState(false);
  const [currentUserAccessLevel, setCurrentUserAccessLevel] =
    useState<AccessLevelEnum>(AccessLevelEnum.VIEW);
  const [isEditMode, setIsEditMode] = useState<IBookingsDrawerState>({
    open: false,
    bookingId: null,
  });
  const [selectedBookings, setSelectedBookings] = useState<string[]>([]);

  const dispatch = useDispatch();

  // queries
  const [deleteOneBooking, { isLoading: isDeleting }] =
    useDeleteBookingByIdMutation();

  const [unassignBookingsFromShipment] =
    useUnassignBookingsToShipmentMutation();

  const { data: count } = useGetBookingsCountQuery();

  const mapFilters = {
    ...params,
    ...(shipmentId ? { shipmentId } : {}),
    inProgress: getBookingStatusByTab(defaultKey),
    search,
  };
  const {
    data: bookings,
    isLoading,
    refetch: refetchBookingsList,
  } = useGetBookingsQuery(mapFilters, {
    refetchOnMountOrArgChange: true,
  });

  const [refetchBookingsColabs, { data: bookingsCollaboratorsData }] =
    useLazyGetBookingsCollaboratorsByShipmentIdQuery();
  const [
    addExistingCollaborators,
    {
      isLoading: isInvitating,
      isError: isInvitationError,
      error: invitationError,
      isSuccess: invitationSuccess,
    },
  ] = useInviteExistingCollaboratorMutation();
  const getBookingsCollaborators = async (shipmentId: string) => {
    try {
      return await refetchBookingsColabs({ shipmentId, search: '' });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'fetching shipment collabs error' });
    }
  };
  const [downloadExcelFile, { isLoading: isDownloading }] =
    useDownloadBookingsSheetMutation();

  //hooks

  useEffect(() => {
    if (shipmentId) {
      getBookingsCollaborators(shipmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId]);

  useEffect(() => {
    if (invitationError && isInvitationError) {
      displayErrors(invitationError, { title: 'Add Collaborator Error' });
    }
  }, [isInvitationError, invitationError]);
  useEffect(() => {
    if (invitationSuccess) {
      notification.success({
        message: 'Collaborators Added',
        description: `Booking collaborators have been added to shipment successfully `,
      });
    }
  }, [invitationSuccess]);
  // methods

  const onEdit = (record: IBookingEntity) =>
    setIsEditMode({ open: EditMode.EDIT, bookingId: record.id });

  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this booking?',
      onOk: async () => {
        await deleteOneBooking(id)
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Booking Deleted',
              description: `The selected booking has been deleted successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete Booking Error' });
          });
      },
    });
  };

  const onUnassignClick = (id: string, shipmentId?: string) => {
    if (!shipmentId) return;
    confirm({
      title: 'Confirmation',
      okText: 'Unassign',
      okType: 'danger',

      icon: null,
      content:
        'Are you sure you want to unassign chosen booking from current shipment ?',
      onOk: async () => {
        await unassignBookingsFromShipment({ bookingId: id, shipmentId })
          .unwrap()
          .then(async () => {
            await refetchBookingsList().unwrap();
            notification.success({
              message: 'Booking unassigned',
              description: `The selected booking has been unassigned from this shipment successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Unassign shipment' });
          });
      },
    });
  };
  const hideCollaboratorModal = () => {
    setIsCollaboratorOpen(false);
    dispatch(bookingApi.util.invalidateTags([{ type: 'Bookings' }]));
  };
  const showCollaboratorModal = (id: string, accessLevel: AccessLevelEnum) => {
    setIsCollaboratorOpen(true);
    setSelectedRow(id);
    setCurrentUserAccessLevel(accessLevel);
  };
  const renderCollaboratorModal = (
    id: string,
    accessLevel: AccessLevelEnum
  ) => (
    <BookingsCollaborators
      hideCollaboratorModal={hideCollaboratorModal}
      showCollaboratorModal={showCollaboratorModal}
      bookingId={id}
      currentUserAccessLevel={currentUserAccessLevel}
      isCollaboratorOpen={isCollaboratorOpen}
    />
  );
  const onDownloadExcelFile = async () => {
    try {
      const resp = await downloadExcelFile({
        bookingIds: selectedBookings,
      }).unwrap();

      // Convert the buffer data array to a Uint8Array
      const bufferData = new Uint8Array(resp.data);
      const fileName = `Bookings_${dayjs().format('DD-MM-YYYY')}.xlsx`;

      // Download the file
      downloadExcelFileFromBuffer(bufferData, fileName);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on downloading file' });
    } finally {
      setSelectedBookings([]);
    }
  };

  const actionColumn: ColumnsType<IBookingEntity> = [
    {
      key: 'shipment-reference',
      title: 'Shipment Reference',
      dataIndex: 'shipment-reference',
      align: 'right',

      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },
      render: (_, record: IBookingEntity) =>
        BookingAssignButton({
          booking: record,
          isDisabled: isDeleting,
          openAssignDrawer: () =>
            setIsEditMode({ open: EditMode.ASSIGN, bookingId: record.id }),
        }),
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'action',
      align: 'center',
      render: (_, record: IBookingEntity) => {
        const isViewer = !canAssignBooking(record);
        return (
          <Space>
            <Button
              className="add-user-button"
              icon={<AddUserIcon />}
              size="small"
              type="text"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={(e: any) => {
                e.stopPropagation();
                showCollaboratorModal(record.id, AccessLevelEnum.MANAGE);
              }}
            />
            <Button
              icon={<EditFilled />}
              size="large"
              type="text"
              onClick={() => onEdit(record)}
            />
            {isShipmentTab ? (
              <Tooltip title="Unassign from current shipment">
                <Button
                  icon={<Icon component={RemoveIcon} />}
                  danger
                  size="large"
                  type="text"
                  disabled={isDeleting || isViewer}
                  onClick={() =>
                    onUnassignClick(record.id, record.shipment?.id)
                  }
                />
              </Tooltip>
            ) : (
              <Button
                icon={<DeleteFilled />}
                danger
                size="large"
                type="text"
                disabled={isDeleting || isViewer}
                onClick={() => onDeleteClick(record.id)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const onRow = (record: IBookingEntity, index: number | undefined) => {
    return {
      onClick: () => {
        onEdit(record);
      },
    };
  };
  const rowSelection: TableRowSelection<IBookingEntity> = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IBookingEntity[]
    ) => {
      setSelectedBookings(selectedRowKeys as string[]);
    },
    selectedRowKeys: selectedBookings,
  };
  const renderTableByState = () => {
    return (
      <Table
        columns={[
          ...filterColumns(bookingColumnConst, selectedColumns),
          ...actionColumn,
        ]}
        dataSource={bookings?.data.items}
        className="bookings-table"
        rowClassName="bookings-row"
        rowKey="id"
        size="middle"
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: params.limit,
          pageSizeOptions: ['5', '10', '50', '100'],
          total: bookings?.data?.meta?.totalItems,
          current: params.page,
          onChange: (page, pageSize) => {
            setParams({ page: page, limit: pageSize });
          },
        }}
        onRow={onRow}
        loading={isLoading}
        rowSelection={rowSelection}
        locale={{
          emptyText: (
            <Space direction="vertical" className="empty-table-wrapper">
              <EmptyOrders />
              <Text strong>Bookings</Text>
              <Text type="secondary">Create new orders to view items</Text>
            </Space>
          ),
        }}
      />
    );
  };

  const updateBooking = useCallback(() => {
    if (isEditMode.bookingId) {
      const booking = bookings?.data.items.find(
        (item) => item.id === isEditMode.bookingId
      );

      if (booking)
        setIsEditMode((current) => ({ ...current, bookingId: booking.id }));
      else setIsEditMode({ open: false, bookingId: null });
    }
  }, [bookings, isEditMode.bookingId]);

  const handleInputOnChange = (value: string) => {
    if (value === '') {
      setSearch(undefined);
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    setSearch(value);
  };

  const handleCloseDrawer = () =>
    setIsEditMode({ open: false, bookingId: null });

  const addAllCollabs = async (shipmentId: string, userIds: string[]) => {
    try {
      await addExistingCollaborators({ shipmentId, userIds }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Add Collaborator Error' });
    }
  };

  useEffect(() => {
    if (bookings?.data.items) updateBooking();
  }, [bookings, updateBooking]);

  const renderBookingTable = () =>
    isShipmentTab ? (
      renderTableByState()
    ) : (
      <AppTabs
        size="small"
        defaultActiveKey={defaultKey}
        activeKey={defaultKey}
        onTabClick={(key) => {
          setTabKey(key as BookingTabEnum);
        }}
        items={[
          {
            label: displayBookingTabTitle(BookingTabEnum.ALL, count?.data),
            key: BookingTabEnum.ALL,
            children: renderTableByState(),
          },
          {
            label: displayBookingTabTitle(
              BookingTabEnum.UNASSIGNED,
              count?.data
            ),
            key: BookingTabEnum.UNASSIGNED,
            children: renderTableByState(),
          },
          {
            label: displayBookingTabTitle(
              BookingTabEnum.IN_PROGRESS,
              count?.data
            ),
            key: BookingTabEnum.IN_PROGRESS,
            children: renderTableByState(),
          },
        ]}
      />
    );
  return (
    <>
      <Row justify="space-between" className="po-actions-bar">
        <div className="search-wrapper">
          <Search
            placeholder="search"
            allowClear
            onChange={(event) => handleInputOnChange(event.target.value)}
            onSearch={handleOnSearch}
          />
        </div>
        <Space>
          <Button
            icon={<DownloadOutlined />}
            disabled={!selectedBookings.length}
            type="primary"
            onClick={onDownloadExcelFile}
            loading={isDownloading}
          >
            Download Excel File
          </Button>
          <CheckboxList
            options={columns}
            selectedItems={selectedColumns}
            onChange={selectColumns}
            tableName={UserTablesEnum.BookingTable}
          >
            <Button icon={<DownOutlined />}> Adjust Columns</Button>
          </CheckboxList>
          {isActionEnabled && (
            <>
              {isShipmentTab && bookingsCollaboratorsData && (
                <Button
                  loading={isInvitating}
                  type="primary"
                  disabled={bookingsCollaboratorsData?.length < 1}
                  onClick={() =>
                    addAllCollabs(shipmentId, bookingsCollaboratorsData)
                  }
                >
                  Add Collaborators to shipment
                </Button>
              )}

              <BookingCreateModal
                isShipmentTab={isShipmentTab}
                shipmentId={shipmentId}
                shipmentSlug={shipmentSlug}
              />
            </>
          )}
        </Space>
      </Row>
      {renderBookingTable()}
      <BookingUpdateDrawer
        bookingId={isEditMode.bookingId}
        isOpen={isEditMode.open === EditMode.EDIT}
        onCloseEditModal={handleCloseDrawer}
        onUnassign={onUnassignClick}
        onAssign={() =>
          setIsEditMode((current) => ({ ...current, open: EditMode.ASSIGN }))
        }
      />
      <BookingAssignDrawer
        isOpen={isEditMode.open === EditMode.ASSIGN}
        bookingId={isEditMode.bookingId ?? null}
        onCloseEditModal={handleCloseDrawer}
      />
      {selectedRow &&
        renderCollaboratorModal(selectedRow, currentUserAccessLevel)}
    </>
  );
};

export default BookingsTable;
