import { useGetCompaniesQuery } from 'api/company';
import { useCreateOrderFromFileMutation } from 'api/freight-ai';
import { useUploadOrderFileMutation } from 'api/purchase-order';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { POFileType } from 'types/entities/purchase-order.entity';
import { UserConnectionTypes } from 'types/entities/user.entity';

import { purchaseOrderPdfUploadEvent } from 'utils/analytics-events';

const usePurchaseOrderUpload = () => {
  const [uploadedFile, setUploadedFile] = useState<string | undefined>(
    undefined
  );
  const [uploadedFileType, setUploadedFileType] = useState<
    POFileType | undefined
  >(undefined);
  const [uploadDocument] = useUploadOrderFileMutation();
  const user = useSelector((state: RootState) => state.user.user);

  const {
    data: companyListData,
    isLoading: isCompanyListLoading,
    isError: isCompanyListError,
  } = useGetCompaniesQuery({ role: UserConnectionTypes.SUPPLIER });

  const [extractOrder, { data: extractedData, isLoading: isExtracting }] =
    useCreateOrderFromFileMutation();

  const getUploadedFileType = (file) => {
    const fileName = file.name.toLowerCase();
    if (fileName.endsWith('.pdf')) {
      return POFileType.PDF;
    } else if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
      return POFileType.XLSX;
    } else {
      return POFileType.UNKNOWN;
    }
  };

  const uploadOrderFile = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('file', file);

    uploadDocument(formdata)
      .unwrap()
      .then((payload) => {
        onSuccess();
        setUploadedFileType(getUploadedFileType(file));

        setUploadedFile(payload[0]);
        purchaseOrderPdfUploadEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        });
      })
      .catch((error) => onError());
  };

  const onUploadedFileChange = (newValue: POFileType | undefined) => {
    setUploadedFile(newValue);
  };

  return {
    uploadedFile,
    uploadedFileType,
    companyListData,
    isCompanyListLoading,
    isCompanyListError,
    extractedData,
    isExtracting,
    extractOrder,
    uploadOrderFile,
    onUploadedFileChange,
  };
};

export default usePurchaseOrderUpload;
