import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { UploadAllowedFormatEnum } from 'types/feature/shipments.types';

import { formatDocumentType } from 'utils/format-fields';

export const RULE_CONST = [
  {
    required: true,
    message: 'Field Required',
  },
];

export const documentTypeOptions = Object.values(IDocumentTypeEnum).map(
  (type: IDocumentTypeEnum) => ({
    label: formatDocumentType(type),
    value: type,
  })
);

export const ACCEPTED_FILE_TYPE_CONST = {
  [UploadAllowedFormatEnum.JPG]: [],
  [UploadAllowedFormatEnum.JPEG]: [],
  [UploadAllowedFormatEnum.PNG]: [],
  [UploadAllowedFormatEnum.XLS]: [],
  [UploadAllowedFormatEnum.XLSX]: [],
  [UploadAllowedFormatEnum.CSV]: [],
  [UploadAllowedFormatEnum.PDF]: [],
  [UploadAllowedFormatEnum.ZIP]: [],
  [UploadAllowedFormatEnum.ZIP_COMPRESSED]: [],
  [UploadAllowedFormatEnum.DOC]: [],
  [UploadAllowedFormatEnum.DOCX]: [],
};
