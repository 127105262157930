import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { useEditShipmentMutation } from 'api/shipment';
import BiddingDetailsForm from 'app/components/ShipmentCreation/ShipmentCreationForm/BiddingDetailsForm';
import {
  computeBiddingFormDate,
  computeDefaultBiddingDate,
} from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IBiddingDetailsForm } from 'types/feature/create-shipment.types';
import {
  ShipmentCreationScreenEnum,
  IShipmentIFormScreenProps,
} from 'types/feature/shipment-onboarding';

import { displayErrors } from 'utils/error-notification';
import { hasShipmentStarted } from 'utils/shipment-helper';

const BiddingFormScreen: FC<IShipmentIFormScreenProps> = ({
  onScreenChange,
  defaultShipment,
}) => {
  const navigate = useNavigate();
  const createShipmentState = useSelector(
    (state: RootState) => state.createShipment
  );
  const [editShipment, { isSuccess, isLoading }] = useEditShipmentMutation();
  const [biddingDetailsForm] = Form.useForm<IBiddingDetailsForm>();

  const onNavigateToShipmentPage = () => {
    onScreenChange(ShipmentCreationScreenEnum.CLOSE_MODAL);
    if (createShipmentState.createdShipmentResp) {
      navigate(`/shipments/SH-${createShipmentState.createdShipmentResp.slug}`);
    }
  };

  const onNavigateToInviteFormScreen = () => {
    onScreenChange(ShipmentCreationScreenEnum.INVITE_FORWARDER_SCREEN);
  };

  const onUpdateShipmentBidding = async () => {
    try {
      await biddingDetailsForm.validateFields();
      const biddingDetailsFormValue = await biddingDetailsForm.getFieldsValue();

      if (createShipmentState?.createdShipmentResp) {
        await editShipment({
          shipmentId: createShipmentState?.createdShipmentResp?.id,
          shipment: {
            dueDate: biddingDetailsFormValue.dueDate
              ? computeBiddingFormDate(biddingDetailsFormValue)
              : null,
          },
        }).unwrap();

        onNavigateToShipmentPage();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Edit Error' });
    }
  };

  return (
    <div className="bidding-form-screen">
      <ModalHeader
        title="Quotation Deadline"
        subTitle="Set a deadline for forwarders to submit quotes, allowing you to review all available options at once."
        leftControls={
          <Button
            type="default"
            icon={<LeftOutlined />}
            onClick={onNavigateToInviteFormScreen}
          />
        }
      />

      <div className="bidding-form-screen__content">
        <BiddingDetailsForm
          defaultValues={computeDefaultBiddingDate(
            defaultShipment?.dueDate,
            false
          )}
          form={biddingDetailsForm}
          {...(defaultShipment && {
            isDisabled: hasShipmentStarted(defaultShipment?.status),
          })}
          isCompact={true}
        />
        <Row justify="end" style={{ marginTop: '10px' }}>
          <Button type="primary" onClick={onUpdateShipmentBidding}>
            Submit Request
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default BiddingFormScreen;
