import { FilterFilled } from '@ant-design/icons';
import { Button } from 'antd';
import AppTabs from 'app/design-system/AppTabs';
import CheckboxList from 'app/design-system/CheckList';
import { useAdjustColumns } from 'app/hooks/adjustColumns.hook';
import React, { FC, useState } from 'react';
import { IPurchaseOrderEntity } from 'types/entities/purchase-order.entity';
import { IExpandableRowProps } from 'types/feature/purchase-orders.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import PurchaseOrderActivity from '../../PurchaseOrderActivity';
import PurchaseOrderItems from '../../PurchaseOrderItems';
import {
  filteredItemsClumns,
  itemsColumns,
} from '../../PurchaseOrderItems/po-items-table.const';

const renderPOItemsTabs = (
  selectedColumns: string[],
  order: IPurchaseOrderEntity,
  isBookingOngoing: boolean,
  isShipmentTab: boolean,
  shipmentId?: string
) => [
  {
    label: 'Details',
    key: 'details',
    children: (
      <PurchaseOrderItems
        accessLevel={order.currentUserAccessLevel.accessLevel}
        items={order.purchaseOrderItems}
        selectedColumns={selectedColumns}
        purchaseOrderId={order.id}
        purchaseOrderRef={order.reference}
        isBookingOngoing={isBookingOngoing}
        isShipmentTab={isShipmentTab}
        isDraft={order.isDraft}
      />
    ),
  },
  {
    label: 'Activity',
    key: 'activity',
    children: (
      <PurchaseOrderActivity
        purchaseOrderId={order.id}
        shipmentId={shipmentId}
        currentUserAccessLevel={order.currentUserAccessLevel.accessLevel}
      />
    ),
  },
];
const ExpandableRow: FC<IExpandableRowProps> = ({
  isBookingOngoing,
  isShipmentTab,
  purchaseOrder,
  shipmentId,
}) => {
  // state
  const [defaultKey, setTabKey] = useState<string>();

  // queries
  const { selectedColumns, selectColumns } = useAdjustColumns(
    itemsColumns,
    UserTablesEnum.PurchaseOrderItemsTable
  );

  return (
    <AppTabs
      size="small"
      defaultActiveKey={defaultKey}
      activeKey={defaultKey}
      onTabClick={(key: string) => {
        setTabKey(key);
      }}
      items={renderPOItemsTabs(
        isBookingOngoing ? filteredItemsClumns : selectedColumns,
        purchaseOrder,
        isBookingOngoing,
        isShipmentTab,
        shipmentId
      )}
      tabBarExtraContent={
        <CheckboxList
          options={itemsColumns}
          selectedItems={selectedColumns}
          onChange={selectColumns}
          tableName={UserTablesEnum.PurchaseOrderItemsTable}
        >
          <Button icon={<FilterFilled />} size="small">
            Adjust Table
          </Button>
        </CheckboxList>
      }
    />
  );
};

export default ExpandableRow;
