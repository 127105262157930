import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Tag } from 'antd';
import React, { FC, useState } from 'react';
import { TaskStatusEnum } from 'types/entities/task.entity';

import { splitState } from 'utils/format-fields';

import './task-switch.scss';

interface TaskStatusSwitchProps {
  status: TaskStatusEnum;
  updateStatus: (status: TaskStatusEnum) => void;
  toUpdate: boolean;
}
const items = Object.values(TaskStatusEnum).map((type: TaskStatusEnum) => ({
  label: splitState(type as unknown as string),
  value: type,
  key: type,
}));
const TaskStatusSwitch: FC<TaskStatusSwitchProps> = ({
  status,
  updateStatus,
  toUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChangeStatus = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  // eslint-disable-next-line
  const handleStatusUpdated = (status: any) => {
    updateStatus(status?.key);
    setIsOpen(false);
  };

  let color = 'orange';
  switch (status) {
    case TaskStatusEnum.DONE:
      color = 'green';
      break;
    case TaskStatusEnum.TODO:
      color = 'blue';
      break;
    default:
      color = 'orange';
  }

  return (
    <Dropdown
      menu={{
        items: items,
        onClick: handleStatusUpdated,
      }}
      open={isOpen}
    >
      <Tag
        className="task-tag"
        color={color}
        closable={toUpdate}
        onClose={handleChangeStatus}
        closeIcon={<DownOutlined />}
        onClick={handleChangeStatus}
      >
        {splitState(status)}
      </Tag>
    </Dropdown>
  );
};

export default TaskStatusSwitch;
