import { IBookingPurchaseItemEntity } from 'types/entities/booking.entity';

export const computeBookingTotals = (items: IBookingPurchaseItemEntity[]) => {
  const totals = { quantity: 0, volume: 0, weight: 0, boxes: 0 };

  items.forEach((item) => {
    totals.quantity += item.quantity;
    totals.volume += item.volume ?? 0;
    totals.weight += item.weight ?? 0;
    totals.boxes += item.purchaseOrderItem?.boxes ?? 0;
  });

  return totals;
};
