import { CaretDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface NotificationsFooterProps {
  isAllLoading: boolean;
  isFetching: boolean;
  handleShowMore: (event) => void;
}

const NotificationsFooter: React.FC<NotificationsFooterProps> = ({
  isAllLoading,
  isFetching,
  handleShowMore,
}) => {
  return (
    <div className="notification-see-more-action">
      <Button
        type="link"
        loading={isFetching}
        disabled={!isAllLoading}
        onClick={handleShowMore}
        {...(isAllLoading && { icon: <CaretDownOutlined /> })}
      >
        {isAllLoading ? 'Show more' : 'All Loaded'}
      </Button>
    </div>
  );
};

export default NotificationsFooter;
