import AppTabs from 'app/design-system/AppTabs';
import React, { FC, useState } from 'react';
import { ShipmentTypeEnum } from 'types/entities/shipment.entity';

import ContractsTable from '../ContractsTable';

export const ContractsTabs: FC = () => {
  const [defaultKey, setTabKey] = useState<string>();

  const renderTableByState = () =>
    Object.values(ShipmentTypeEnum).map((item) => {
      return {
        label: item.toUpperCase(),
        key: item,
        children: <ContractsTable shipmentType={item} />,
      };
    });

  return (
    <AppTabs
      size="small"
      defaultActiveKey={defaultKey}
      activeKey={defaultKey}
      onTabClick={(key: string) => {
        setTabKey(key);
      }}
      items={renderTableByState()}
    />
  );
};
