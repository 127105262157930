import {
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Form,
  Switch,
  Dropdown,
  Typography,
  Button,
  MenuProps,
} from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IUserEntity } from 'types/entities/user.entity';

import { notificationSettingClickEvent } from 'utils/analytics-events';

import { ReactComponent as MoreIcon } from '../../../../assets/menuIcons/more.svg';

interface NotificationsHeaderProps {
  handleShowOnlyUnread: (checked: boolean) => void;
  MarkAllNotificationsAsRead: () => void;
  onDropdownClose: () => void;
  currentUser?: IUserEntity;
}
const { Title } = Typography;

const NotificationsHeader: FC<NotificationsHeaderProps> = ({
  MarkAllNotificationsAsRead,
  handleShowOnlyUnread,
  onDropdownClose,
  currentUser,
}) => {
  const onNotificationsSettingsCTA = () => {
    if (currentUser) {
      notificationSettingClickEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
      });
    }
  };

  const onSettingClick = () => {
    onDropdownClose();
    onNotificationsSettingsCTA();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          to={'/settings'}
          state={{ fromLink: true }}
          onClick={onSettingClick}
        >
          <Button
            icon={<SettingOutlined />}
            className="notification-menu-button"
            type="text"
          >
            Notification Settings
          </Button>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Button
          className="notification-menu-button"
          type="text"
          onClick={MarkAllNotificationsAsRead}
        >
          Mark All As Read
        </Button>
      ),
    },
  ];
  return (
    <div className="notification-header">
      <Row justify="space-around" align="middle">
        <Col span={10}>
          <Title level={3} className="m-0">
            Notifications
          </Title>
        </Col>
        <Col
          span={9}
          offset={2}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Form.Item
            style={{ marginBottom: 0 }}
            label="Only Show Unread"
            valuePropName="checked"
          >
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(checked) => handleShowOnlyUnread(checked)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Dropdown
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            trigger={['click']}
            menu={{ items }}
            overlayClassName="settings-menu"
          >
            <Button icon={<MoreIcon />} type="text" />
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationsHeader;
