import ContactFilter from 'app/components/ShipmentFilters/ContactListFilter';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React from 'react';
import { SubmittedByFilterProps } from 'types/feature/invoice-list';

const SubmittedByFilter: React.FC<SubmittedByFilterProps> = ({
  onFilterChange,
  selectedFilters,
  allContacts,
}) => {
  return (
    <FilterDropdown
      filters={selectedFilters}
      renderFilterComponent={
        <ContactFilter
          layout="horizontal"
          defaultValue={selectedFilters?.submittedBy}
          contacts={allContacts}
          onChange={(values) =>
            onFilterChange(values as string[], 'submittedBy')
          }
        />
      }
      buttonText="Submitted By"
      itemKey="submittedBy"
    />
  );
};

export default SubmittedByFilter;
