// the use of this function if to convert the object returned from Color picker to hex code

export function hsvToHex(h: number, s: number, v: number): string {
  h = Math.floor(h); // Ensure h is an integer
  s = Math.floor(s * 100); // Convert s to a percentage
  v = Math.floor(v * 100); // Convert v to a percentage

  // Convert HSV to RGB
  const c = (v / 100) * (s / 100);
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v / 100 - c;
  let r: number, g: number, b: number;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }
  // Convert RGB to hex
  const hexR = Math.floor((r + m) * 255)
    .toString(16)
    .padStart(2, '0');
  const hexG = Math.floor((g + m) * 255)
    .toString(16)
    .padStart(2, '0');
  const hexB = Math.floor((b + m) * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${hexR}${hexG}${hexB}`;
}
