import { Button, Col, Modal, Row, Select } from 'antd';
import { useUpdateInvoiceAssigneeMutation } from 'api/invoice';
import { useGetPotentialAssigneeQuery } from 'api/invoice-collaborator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserEntity } from 'types/entities/user.entity';
import { IInvoiceAssigneeModalProps } from 'types/feature/invoice.types';

import { invoiceAssignEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { computeFullName } from 'utils/user-utils';

const computeSelectOptions = (users: IUserEntity[] | undefined) => {
  return (
    users?.map((user) => ({
      label: `${computeFullName(user)} (${user.email})`,
      value: user.id,
      key: user.id,
    })) ?? []
  );
};

const InvoiceAssigneeModal: React.FC<IInvoiceAssigneeModalProps> = ({
  invoiceId,
  shipmentId,
  handleCloseAcceptInvoiceModal,
  buttonText,
  buttonType,
}) => {
  const { data: resp, isLoading } = useGetPotentialAssigneeQuery(invoiceId);
  const [assigneeId, setAssignee] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.user);

  const [updateInvoice, { isLoading: isAssigning, isSuccess }] =
    useUpdateInvoiceAssigneeMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  // methods

  const onAssignUser = async () => {
    try {
      await updateInvoice({
        id: invoiceId,
        invoice: {
          assigneeId,
        },
      }).unwrap();

      invoiceAssignEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        invoice_id: invoiceId,
        shipment_id: shipmentId,
        assignee_id: assigneeId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on updating Invoice' });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    if (handleCloseAcceptInvoiceModal) {
      handleCloseAcceptInvoiceModal();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSelect = (value: string) => {
    setAssignee(value);
  };

  return (
    <div>
      <Button
        type={buttonType || 'primary'}
        onClick={showModal}
        loading={isLoading}
      >
        {buttonText || 'Request Assignee'}
      </Button>

      <Modal
        title="Assign Invoice"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Select
              loading={isLoading || isAssigning}
              placeholder="Select an assignee from the list"
              value={assigneeId}
              onChange={onSelect}
              style={{ width: '100%' }}
              options={computeSelectOptions(resp?.data.users)}
            />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              onClick={onAssignUser}
              loading={isAssigning}
              disabled={!assigneeId}
              block
            >
              Request Approval
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default InvoiceAssigneeModal;
