import PurchaseOrderUploadScreen from 'app/components/PurchaseOrderCreationCompact/UploadPdfModal/PurchaseOrderUploadScreen';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetCreateShipment } from 'state/slices/create-shipment.slice';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import {
  IPurchaseOrderCreationOnboardingScreenProps,
  PurchaseOrderCreationScreenEnum,
} from 'types/feature/purchase-orders-onboarding.types';

import OverviewScreen from './OverviewScreen';
import './purchase-order-onboardifing.scss';

const PurchaseOrderCreationOnboardingScreen: FC<
  IPurchaseOrderCreationOnboardingScreenProps
> = ({ onClose }) => {
  const [activeScreen, setActiveScreen] =
    useState<PurchaseOrderCreationScreenEnum>(
      PurchaseOrderCreationScreenEnum.OVERVIEW_SCREEN
    );
  const dispatch = useDispatch();

  const onModalClose = () => {
    setActiveScreen(PurchaseOrderCreationScreenEnum.OVERVIEW_SCREEN);
    dispatch(resetCreateShipment());
    onClose();
  };

  const onScreenChange = (
    newScreen: PurchaseOrderCreationScreenEnum,
    screenData?: Record<string, any>
  ) => {
    setActiveScreen(newScreen);
  };

  const renderScreen = () => {
    switch (activeScreen) {
      case PurchaseOrderCreationScreenEnum.UPLOAD_SCREEN: {
        return (
          <PurchaseOrderUploadScreen
            canSelectSupplier={false}
            onModalClose={onModalClose}
          />
        );
      }
      case PurchaseOrderCreationScreenEnum.OVERVIEW_SCREEN:
      default:
        return <OverviewScreen onScreenChange={onScreenChange} />;
    }
  };

  return <>{renderScreen()}</>;
};

export default PurchaseOrderCreationOnboardingScreen;
