import { Checkbox, Form, Space } from 'antd';
import React, { FC, useCallback, useEffect } from 'react';
import { ModalityEnum } from 'types/entities/shipment.entity';
import {
  IModalityFilterForm,
  IModalityFilterProps,
} from 'types/feature/shipments.types';

import { splitState } from 'utils/format-fields';

import '../shipment-filter.scss';

const ModalityFilter: FC<IModalityFilterProps> = ({
  onChange,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IModalityFilterForm>();

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        modalityFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ modalityFilter }: IModalityFilterForm) => {
    onChange(modalityFilter);
  };

  return (
    <Form
      form={form}
      onValuesChange={onFormChange}
      layout="vertical"
      className="filters-form-wrapper"
    >
      <Form.Item name="modalityFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {Object.values(ModalityEnum).map((modality: ModalityEnum) => (
              <div className="input-space-wrapper" key={modality}>
                <Checkbox value={modality}>{splitState(modality)}</Checkbox>
              </div>
            ))}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default ModalityFilter;
