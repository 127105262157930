import { isUserWithinTargetTimeZone } from 'api/common/config';
import { firebaseAuth } from 'api/common/firebase';
import { Auth, User } from 'firebase/auth';
import { IUserConnectionEntity } from 'types/entities/company.entity';
import { IUserWithCompany } from 'types/entities/user.entity';

import { getSessionEmail } from './auth-storage';
import { computeFullName } from './user-utils';

export const waitForAuthStateChange = (firebaseAuth: Auth) => {
  return new Promise<User | null>((resolve) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    });
  });
};

export const isAuthenticated = async () => {
  if (getSessionEmail() && isUserWithinTargetTimeZone()) {
    const user = {
      emailVerified: true,
      isAnonymous: false,
      email: getSessionEmail(),
    };
    return user;
  }
  const user = await waitForAuthStateChange(firebaseAuth);
  return user;
};

export const computePartnersSelectOptions = (
  supplier: IUserConnectionEntity[]
) =>
  supplier?.map((user) => {
    const { fullname, company } = user.partner;
    return {
      label: `${fullname} (${company.name})`,
      value: user.partner.id,
      key: user.partner.id,
    };
  });

export const computeTeamSelectOptions = (
  users: IUserWithCompany[] | undefined
) => {
  return (
    users?.map((user) => ({
      label: `${computeFullName(user)} (${user.company.name})`,
      value: user.id,
      key: user.id,
    })) ?? []
  );
};
