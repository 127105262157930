import { Typography } from 'antd';
import React, { FC } from 'react';

import './modal.scss';

interface IModalHeaderProps {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  leftControls?: JSX.Element;
  rightControls?: JSX.Element;
}

const ModalHeader: FC<IModalHeaderProps> = ({
  title,
  subTitle,
  leftControls,
  rightControls,
}) => {
  return (
    <div className="modal-wrapper__header">
      <div className="modal-wrapper__header__left-control">{leftControls}</div>
      <div className="modal-wrapper__header__content">
        <Typography.Title level={4} className="font-medium mt-0">
          {title}
        </Typography.Title>
        <Typography.Paragraph type="secondary">{subTitle}</Typography.Paragraph>
      </div>
      <div className="modal-wrapper__header__right-control">
        {rightControls}
      </div>
    </div>
  );
};

export default ModalHeader;
