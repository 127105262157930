import { Typography, Divider, Space, Button } from 'antd';
import { useGetAllShipmentsLocationQuery } from 'api/shipment';
import DateFilter from 'app/components/ShipmentFilters/DateFilter';
import FilterDrawer from 'app/components/ShipmentFilters/FilterDrawer';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import LocationFilter from 'app/components/ShipmentFilters/LocationFilter';
import ShipmentTypeFilter from 'app/components/ShipmentFilters/ShipmentTypeFilter';
import StatusFilter from 'app/components/ShipmentFilters/StatusFilter';
import React, { FunctionComponent } from 'react';
import { IShipmentFiltersProps } from 'types/feature/shipments.types';

import { checkObjectNotEmpty } from 'utils/object.helpers';

import { removeEmptyArrays } from '../helpers/helper';

export const ShipmentFilters: FunctionComponent<IShipmentFiltersProps> = ({
  selectedFilters: shipmentSearch,
  onChange,
  isBookingAssignment,
  onDrawerChange,
  onReset,
}) => {
  const { data: allShipmentsLocation } = useGetAllShipmentsLocationQuery();

  return (
    <div>
      <Typography.Text>
        Filters <Divider type="vertical" />
      </Typography.Text>
      <Space>
        <FilterDropdown
          filters={shipmentSearch}
          renderFilterComponent={
            <StatusFilter
              layout="vertical"
              defaultValue={shipmentSearch?.statuses}
              onChange={(status) => onChange(status, 'statuses')}
            />
          }
          buttonText="Status"
          itemKey="statuses"
        />
        <FilterDropdown
          filters={shipmentSearch}
          renderFilterComponent={
            <DateFilter
              layout="vertical"
              defaultValue={shipmentSearch?.etds}
              onChange={(dateFilter) => onChange(dateFilter, 'etds')}
            />
          }
          buttonText="Departure Date"
          itemKey="etds"
        />
        {!isBookingAssignment && (
          <>
            <FilterDropdown
              filters={shipmentSearch}
              renderFilterComponent={
                <ShipmentTypeFilter
                  layout="vertical"
                  defaultValue={shipmentSearch?.shipmentType}
                  onChange={(shipmentType) =>
                    onChange(shipmentType, 'shipmentType')
                  }
                />
              }
              buttonText="Freight Type"
              itemKey="shipmentType"
            />
            {allShipmentsLocation && (
              <FilterDropdown
                filters={shipmentSearch}
                renderFilterComponent={
                  <LocationFilter
                    layout="horizontal"
                    defaultValue={shipmentSearch?.origins}
                    locations={allShipmentsLocation?.origins}
                    onChange={(origins) => onChange(origins, 'origins')}
                  />
                }
                buttonText="Origins"
                itemKey="origins"
              />
            )}
            {allShipmentsLocation && (
              <FilterDropdown
                filters={shipmentSearch}
                renderFilterComponent={
                  <LocationFilter
                    layout="vertical"
                    defaultValue={shipmentSearch?.destinations}
                    locations={allShipmentsLocation?.destinations}
                    onChange={(destinations) =>
                      onChange(destinations, 'destinations')
                    }
                  />
                }
                buttonText="Destination"
                itemKey="destinations"
              />
            )}
          </>
        )}
        <FilterDrawer
          defaultValue={shipmentSearch}
          onChange={onDrawerChange}
          onReset={onReset}
        />
        {checkObjectNotEmpty(removeEmptyArrays(shipmentSearch)) && (
          <Button type="link" block onClick={onReset}>
            Clear All
          </Button>
        )}
      </Space>
    </div>
  );
};
