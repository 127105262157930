import { IUserBaseEntity, IUserNameEntity } from 'types/entities/user.entity';

import { capitalize } from './format-fields';

export const computeFullName = <T extends IUserNameEntity>(user: T) =>
  `${capitalize(user.fullname)}`;

export const computeUserNameWithCompany = (user: {
  fullname: string;

  company: { name: string };
}) => `${user.fullname} (${user.company.name})`;

export const computeUserSelectOptions = (userList: IUserBaseEntity[]) =>
  userList?.map((user) => ({
    label: `${user.fullname} (${user?.email})`,
    value: user.id,
    key: user.id,
  }));

export const userSelectOptionFilterByLabel = (input, option) =>
  ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
