import {
  IAllCompanyTags,
  IAllCompanyTagsReq,
  ICreateShipmentTagReq,
  IShipmentMetadata,
  IEditShipmentTagReq,
  IShipementTags,
} from 'types/entities/metadata.entity';

import { api } from './base-api';

// example of endpoints injection
export const metadaraApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCompanyShipmentTags: build.query<
      IAllCompanyTags[],
      IAllCompanyTagsReq
    >({
      query: (params) => ({
        url: '/company-metadata/get',
        params,
      }),
      providesTags: ['CompanyShipmentTags'],
    }),

    createCompanyShipmentTag: build.mutation<
      IAllCompanyTags[],
      ICreateShipmentTagReq
    >({
      query: (payload) => ({
        url: `/company-metadata/create`,
        method: 'POST',
        body: payload.shipmentTag,
      }),
      invalidatesTags: ['CompanyShipmentTags'],
    }),
    deleteCompanyShipmentTag: build.mutation<void, string>({
      query: (shipmentId) => ({
        url: `/company-metadata/${shipmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyShipmentTags'],
    }),
    editCompanyShipmentTag: build.mutation<void, IEditShipmentTagReq>({
      query: (payload) => ({
        url: `/company-metadata/edit`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CompanyShipmentTags'],
    }),

    getShipmentMetadata: build.query<IShipementTags[], string>({
      query: (id) => ({
        url: `shipment-metadata/${id}`,
      }),
      providesTags: ['ShipmentTags'],
    }),

    addMetadataToShipment: build.mutation<
      IAllCompanyTags[],
      IShipmentMetadata[]
    >({
      query: (payload) => ({
        url: `/shipment-metadata/create`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ShipmentTags'],
    }),
    deleteMetadaShipment: build.mutation<void, string>({
      query: (id) => ({
        url: `/shipment-metadata/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ShipmentTags'],
    }),
    getShipmentTagsValues: build.query<string[], string>({
      query: (id) => ({
        url: `/shipment-metadata/metadata-values/${id}`,
      }),
    }),
  }),
});

export const {
  useGetAllCompanyShipmentTagsQuery,
  useCreateCompanyShipmentTagMutation,
  useDeleteCompanyShipmentTagMutation,
  useEditCompanyShipmentTagMutation,
  useGetShipmentMetadataQuery,
  useAddMetadataToShipmentMutation,
  useDeleteMetadaShipmentMutation,
  useGetShipmentTagsValuesQuery,
} = metadaraApi;
