import { IFileEntity } from 'types/entities/proposal.entity';
import {
  IPOsResponseBody,
  IPurchaseOrderCreateBody,
  IPurchaseOrderEntity,
  IPurchaseOrderItemEntity,
} from 'types/entities/purchase-order.entity';
import { IAllShipmentsLocation } from 'types/entities/shipment.entity';
import { IPurchaseOrderFilters } from 'types/feature/purchase-orders.types';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const purchaseOrdersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPurchaseOrders: build.query<IPOsResponseBody, IPurchaseOrderFilters>({
      query: (params) => ({ url: '/purchase-order/list', params }),
      transformResponse: (response: ApiResponse<IPOsResponseBody>) =>
        response.data,
      providesTags: (result: IPOsResponseBody = { items: [] }, error, arg) => [
        'PurchaseOrders',
        ...result.items.map((item) => ({
          type: 'PurchaseOrders' as const,
          id: item.id,
        })),
      ],
    }),
    createPurchaseOrders: build.mutation<
      ApiResponse<IPurchaseOrderEntity[]>,
      IPurchaseOrderCreateBody
    >({
      query: (payload) => ({
        url: '/purchase-order',
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['PurchaseOrders'],
    }),
    getPurchaseOrderById: build.query<IPurchaseOrderEntity, string>({
      query: (id: string) => ({
        url: `/purchase-order/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'PurchaseOrders', id: arg },
      ],
    }),
    updatePurchaseOrders: build.mutation<
      ApiResponse<IPurchaseOrderEntity[]>,
      { id: string; body: Partial<IPurchaseOrderCreateBody> }
    >({
      query: (payload) => ({
        url: `/purchase-order/${payload.id}`,
        body: payload.body,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PurchaseOrders', id: arg.id },
      ],
    }),
    deletePurchaseOrderById: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/purchase-order/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PurchaseOrders', id: arg },
      ],
    }),

    deletePurchaseOrderItemById: build.mutation<
      void,
      { id: string; poId?: string }
    >({
      query: ({ id }) => ({
        url: `/purchase-order-item/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PurchaseOrders', id: arg.poId },
      ],
    }),
    getPurchaseOrderItemsById: build.query<IPurchaseOrderItemEntity[], string>({
      query: (id: string) => ({
        url: `/purchase-order-item/list/${id}`,
        method: 'GET',
      }),
    }),
    uploadOrderFile: build.mutation<string[], FormData>({
      query: (body) => ({
        url: '/purchase-order/upload',
        method: 'POST',
        formData: true,
        body,
      }),
    }),
    getOrderFilesById: build.query<IFileEntity[], string>({
      query: (id: string) => ({
        url: `/purchase-order/files/${id}`,
      }),
    }),
    getAllPurchaseOrderLocations: build.query<IAllShipmentsLocation, void>({
      query: () => ({
        url: '/purchase-order/locations',
      }),
    }),
  }),
});

export const {
  useGetPurchaseOrdersQuery,
  useLazyGetPurchaseOrdersQuery,
  useCreatePurchaseOrdersMutation,
  useUpdatePurchaseOrdersMutation,
  useDeletePurchaseOrderByIdMutation,
  useDeletePurchaseOrderItemByIdMutation,
  useGetOrderFilesByIdQuery,
  useUploadOrderFileMutation,
  useLazyGetPurchaseOrderItemsByIdQuery,
  useLazyGetPurchaseOrderByIdQuery,
  useGetAllPurchaseOrderLocationsQuery,
} = purchaseOrdersApi;
