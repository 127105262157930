import { Badge, Empty, Row, Space, Spin } from 'antd';
import { useGetSuggestedProposalByShipmentQuery } from 'api/proposal';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import { useLazyGetShipmentTagsByAIQuery } from 'api/shipment-metadata';
import AppTabs from 'app/design-system/AppTabs';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ShipmentTabsEnum } from 'types/feature/shipments.types';

import { shipmentPageViewEvent } from 'utils/analytics-events';
import { scrollIntoView } from 'utils/common';
import { displayErrors } from 'utils/error-notification';
import {
  hasShipmentStarted,
  isCommunicationTabHidden,
} from 'utils/shipment-helper';
import { isAirTransportation } from 'utils/shipment-type';

import ShipmentHeader from './ShipmentHeader';
import ShipmentTagsByAIModal from './ShipmentTagsByAIModal';
import './shipment-details.style.scss';

const renderShipmentTabs = (
  shipment: IShipmentEntity,
  isSuggestedProposalExist: boolean
) => [
  {
    label: 'Overview',
    key: ShipmentTabsEnum.OVERVIEW,
    children: <Outlet context={{ shipment }} />,
  },
  {
    label: (
      <Space>
        Quotes
        {isSuggestedProposalExist && <Badge dot color="red" />}
      </Space>
    ),
    key: ShipmentTabsEnum.QUOTES,
    children: <Outlet context={{ shipment }} />,
  },

  ...(!isAirTransportation(shipment.shipmentType.title)
    ? [
        {
          label: 'Containers',
          key: ShipmentTabsEnum.CONTAINERS,
          children: <Outlet context={{ shipment }} />,
        },
      ]
    : []),
  {
    label: 'Documents',
    key: ShipmentTabsEnum.DOCUMENTS,
    children: <Outlet context={{ shipment }} />,
  },
  ...(isCommunicationTabHidden(shipment.currentUserRole, shipment.status)
    ? []
    : [
        {
          label: 'Communication',
          key: ShipmentTabsEnum.COMMUNICATIONS,
          children: <Outlet context={{ shipment }} />,
        },
      ]),
  {
    label: 'Bookings',
    key: 'bookings',
    children: (
      <Outlet
        context={{
          shipment,
        }}
      />
    ),
  },
  ...(hasShipmentStarted(shipment.status)
    ? [
        {
          label: 'Finance',
          key: ShipmentTabsEnum.FINANCE,
          children: <Outlet context={{ shipment }} />,
          disabled: false,
        },
      ]
    : []),
];

const ShipmentDetails: FC = () => {
  const location = useLocation();
  const { slug = '' } = useParams();
  const navigate = useNavigate();
  const campaign = useUTMState();

  const { tabState, resetShareBookingParty, shareBookingPartyModal } =
    useBookingPartyState();
  const [activeTabKey, setActiveTabKey] = useState<string>(
    ShipmentTabsEnum.OVERVIEW
  );
  const [isTagsOpen, setIsTagsOpen] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.user.user);
  const shipmentWrapperRef = useRef<HTMLDivElement>(null);

  const {
    data: shipment,
    isLoading,
    isError,
  } = useGetShipmentsBySlugQuery(slug, {
    refetchOnMountOrArgChange: true,
  });

  const { data: suggestedProposalByShipmentData } =
    useGetSuggestedProposalByShipmentQuery(
      {
        shipmentId: shipment?.id || '',
      },
      { skip: !shipment }
    );

  const [getTagsByAI, { data: tagsByAIData }] =
    useLazyGetShipmentTagsByAIQuery();

  const fetchTagsByAI = async () => {
    if (shipment) {
      try {
        const response = await getTagsByAI(shipment.id).unwrap();

        if (response.length > 0) {
          setIsTagsOpen(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'Suggested Tags Error' });
      }
    }
  };

  useEffect(() => {
    if (shipment && !shipment.shipmentAccess.hasAccessToShipment) {
      navigate(
        `/proposals/${shipment.shipmentAccess.targetId}?email=${shipment.shipmentAccess.currentUserEmail}`,
        { replace: true, relative: 'route' }
      );
    }

    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1] as ShipmentTabsEnum;
    if (
      segments.length > 3 &&
      Object.values(ShipmentTabsEnum).includes(lastSegment)
    ) {
      setActiveTabKey(lastSegment || 'overview');
    }

    // Get suggested tags by AI
    if (shipment) {
      try {
        getTagsByAI(shipment.id).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'Suggested Tags Error' });
      }
    }

    fetchTagsByAI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  useEffect(() => {
    if (user && shipment) {
      shipmentPageViewEvent(
        {
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          shipment_id: shipment.id,
          company_id: user?.companyId,
        },
        { campaign }
      );
    }
  }, [user, shipment, campaign]);

  useEffect(() => {
    if (tabState) {
      setActiveTabKey(tabState);
      navigate(tabState);

      if (shareBookingPartyModal && shipmentWrapperRef.current) {
        const scrollTimeOut = setTimeout(() => {
          scrollIntoView(shipmentWrapperRef, `booking-parties-card`);
        }, 500);

        return () => clearTimeout(scrollTimeOut);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState, shipmentWrapperRef, shareBookingPartyModal]);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
    navigate(key);

    if (resetShareBookingParty) {
      resetShareBookingParty(key);
    }
  };

  return (
    <div ref={shipmentWrapperRef} className="shipment-details-wrapper">
      {isError && <Empty description="ERROR: No shipment available" />}
      {isLoading && (
        <div className="shipment-details-loading">
          <Spin size="large" />
        </div>
      )}
      {shipment && suggestedProposalByShipmentData && (
        <>
          <Row>
            <ShipmentHeader shipment={shipment} />
          </Row>
          <AppTabs
            destroyInactiveTabPane={true}
            activeKey={activeTabKey}
            onTabClick={onTabChange}
            items={renderShipmentTabs(
              shipment,
              suggestedProposalByShipmentData.isSuggestedProposalExist
            )}
            className="shipment-details-tabs"
          />
        </>
      )}
      {tagsByAIData && (
        <ShipmentTagsByAIModal tagsData={tagsByAIData} isOpen={isTagsOpen} />
      )}
    </div>
  );
};

export default ShipmentDetails;
