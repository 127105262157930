import { Popover, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { convertToCBM } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import dayjs from 'dayjs';
import React from 'react';
import { IBookingEntity } from 'types/entities/booking.entity';

import { roundToPrecision } from 'utils/common';
import { displayCountry } from 'utils/format-fields';
import { convertSlug, SlugPrefixEnum } from 'utils/slug-formattor';

export const filteredColumns = [
  'ID #',
  'POs',
  'Origin',
  'Destination',
  'Supplier',
  'CRD',
  'Status',
];

export const bookingColumnConst: ColumnsType<IBookingEntity> = [
  {
    title: 'ID #',
    key: 'ref',
    dataIndex: 'number',
    render: (_, record: IBookingEntity) => (
      <Space direction="vertical" align="center">
        <Typography.Text strong className="row-po-text">
          {record.reference ? `${record.reference}` : ''}
        </Typography.Text>
        <Typography.Text type="secondary" className="row-sub-text">
          {convertSlug(record.slug, SlugPrefixEnum.BK)}
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'POs',
    dataIndex: 'pos',
    key: 'pos',
    render: (_, record: IBookingEntity) => (
      <Space direction="vertical">
        {record.bookingPurchaseOrders.length > 2 ? (
          <Popover
            title="Associated POs"
            content={record.bookingPurchaseOrders
              .slice(2)
              .map((item) => item.purchaseOrder?.reference)
              .join(', ')}
            placement="topRight"
          >
            <Space size={[2, 2]} wrap>
              <Typography.Text strong>
                {record.bookingPurchaseOrders
                  .slice(0, 2)
                  .map((item) => item.purchaseOrder?.reference)
                  .join(', ')}
              </Typography.Text>
              <Typography.Link>+ More</Typography.Link>
            </Space>
          </Popover>
        ) : (
          <Typography.Text strong>
            {record.bookingPurchaseOrders
              ?.map((item) => item.purchaseOrder?.reference)
              .join(', ')}
          </Typography.Text>
        )}
        <Typography.Text type="secondary" className="row-sub-text">
          No° of POs ({record.bookingPurchaseOrders.length})
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'CRD',
    key: 'crd',
    dataIndex: 'crd',
    render: (value, record: IBookingEntity) => {
      return dayjs(record.cargoReadyDate).format('DD, MMM YYYY');
    },
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',

    render: (_, record: IBookingEntity) => (
      <Space direction="vertical">
        <Typography.Text>{displayCountry(record.origin)}</Typography.Text>
      </Space>
    ),
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',

    render: (_, record: IBookingEntity) => (
      <Space direction="vertical">
        <Typography.Text>{displayCountry(record.destination)}</Typography.Text>
      </Space>
    ),
  },

  {
    title: 'Supplier',
    key: 'supplier',

    render: (_, record: IBookingEntity) => (
      <Space direction="vertical">
        <Typography.Text>{record.supplier.fullname}</Typography.Text>
        <Typography.Text type="secondary" className="row-sub-text">
          {record.supplier.company?.name}
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'Weight (Kg)',
    key: 'totalWeight',
    dataIndex: 'totalWeight',
    align: 'right',
    render: (_, { totalWeight }: IBookingEntity) => {
      return roundToPrecision(totalWeight ?? 0);
    },
  },
  {
    title: 'Volume (CBM)',
    key: 'totalVolume',
    dataIndex: 'totalVolume',
    render: (value, record: IBookingEntity) => {
      return convertToCBM(record.totalVolume?.toString());
    },
    align: 'right',
  },

  {
    title: 'Boxes',
    key: 'totalBoxes',
    dataIndex: 'totalBoxes',
    align: 'right',
  },
  {
    title: 'Items',
    key: 'totalQuantity',
    dataIndex: 'totalQuantity',
    align: 'right',
  },
];

export const columns = bookingColumnConst.map((column) => ({
  value: column.key as string,
  label: column.title as string,
}));
