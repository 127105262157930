import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { IUserEntity } from 'types/entities/user.entity';

export const forwarderColumnsConst: ColumnsType<IUserEntity> = [
  {
    key: 'name',
    title: 'Full name',
    render: (value, record, index) => {
      return <Typography.Text>{record.fullname}</Typography.Text>;
    },

    width: '30%',
  },
  {
    key: 'email',
    dataIndex: 'email',
    title: 'Email',
    render: (value, record, index) => {
      return <Typography.Text type="secondary">{record.email}</Typography.Text>;
    },

    width: '30%',
  },

  {
    key: 'invited',
    title: 'Status',
    render: (value, record, index) =>
      record.invited ? (
        <Tag color="success" icon={<CheckCircleOutlined />}>
          Invited
        </Tag>
      ) : (
        <Tag color="gold" icon={<MinusCircleOutlined />}>
          Not Invited
        </Tag>
      ),
    align: 'center',
  },
];
