import {
  IContainerTrackingRequestBody,
  IShipmentTrackingResponse,
  ITrackingEntity,
} from 'types/entities/tracking.entity';
import {
  IAddTrackingFormData,
  IAirTrackingRequestBody,
  ITrackingByAiRequestBody,
  ITrackingByBillRequestBody,
} from 'types/feature/tracking.types';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

// example of endpoints injection
export const trackingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTrackingById: build.query<IShipmentTrackingResponse, string>({
      query: (id) => ({ url: `/tracking/${id}` }),
      providesTags: ['Tracking'],
    }),

    getAirTrackingById: build.query<IShipmentTrackingResponse, string>({
      query: (id) => {
        return { url: `/tracking/air/${id}` };
      },
      providesTags: ['Tracking'],
    }),

    updateAirTrackingById: build.mutation<IShipmentTrackingResponse, string>({
      query: (id) => {
        return { url: `/tracking/air/${id}`, method: 'PATCH' };
      },
      invalidatesTags: ['Tracking'],
    }),

    getUpdatedTrackingById: build.mutation<ITrackingEntity[], string>({
      query: (id) => ({
        url: `/tracking/${id}`,
        method: 'PATCH',
      }),
      transformResponse: (resp: ApiResponse<ITrackingEntity[]>) => resp.data,
    }),

    addTrackingByContainer: build.mutation<
      ITrackingEntity,
      Partial<IContainerTrackingRequestBody>
    >({
      query: (body) => ({
        url: '/tracking',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ShipmentsById', 'Tracking'],
    }),

    addTrackingByBill: build.mutation<
      IAddTrackingFormData,
      Partial<ITrackingByBillRequestBody>
    >({
      query: (body) => ({
        url: '/tracking/mbl',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ShipmentsById', 'Tracking'],
    }),

    addAirTracking: build.mutation<
      IAddTrackingFormData,
      Partial<IAirTrackingRequestBody>
    >({
      query: (body) => ({
        url: '/tracking/air',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ShipmentsById', 'Tracking'],
    }),
    addTrackingByAi: build.mutation<
      IAddTrackingFormData,
      ITrackingByAiRequestBody
    >({
      query: (body) => ({
        url: '/tracking/document-mbl',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ShipmentsById', 'Tracking'],
    }),
  }),
});

export const {
  useGetTrackingByIdQuery,
  useGetUpdatedTrackingByIdMutation,
  useAddTrackingByContainerMutation,
  useAddTrackingByBillMutation,
  useAddAirTrackingMutation,
  useGetAirTrackingByIdQuery,
  useLazyGetAirTrackingByIdQuery,
  useUpdateAirTrackingByIdMutation,
  useLazyGetTrackingByIdQuery,
  useAddTrackingByAiMutation,
} = trackingApi;
