import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import React from 'react';

const AppTabs: React.FC<TabsProps> = (props) => {
  const handleClickCapture = () => {
    document.body.click();
  };

  return <Tabs {...props} onChange={handleClickCapture} />;
};

export default AppTabs;
