import { ColumnsType } from 'antd/es/table';
import { IPendingReviewInvoice } from 'types/entities/invoice.entity';

import { formatAmount } from 'utils/monetary-utils';

export const columns: ColumnsType<IPendingReviewInvoice> = [
  {
    title: 'Assignee Email',
    sorter: true,
    dataIndex: 'assignee',
    key: 'assignee',
  },
  {
    title: 'Count',
    sorter: true,
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Total Cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    render: (_, record) => formatAmount(record.totalCost, record.currency),
  },
];
