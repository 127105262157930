import { UploadProps, message } from 'antd';
import { API_URL } from 'api/common/config';
import { RcFile } from 'rc-upload/lib/interface';
import { useState } from 'react';

import { getAuthToken } from 'utils/auth-storage';

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
export const useUploadProfilePicture = ({
  refetch,
}: {
  refetch: () => void;
}) => {
  const [profileImgUrl, setProfileImgUrl] = useState<string | undefined>(
    undefined
  );
  const props: UploadProps = {
    name: 'file',
    multiple: false,
    method: 'POST',
    listType: 'picture-circle',
    maxCount: 1,
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },

    action: `${API_URL}/user/profile/upload`,
    beforeUpload: beforeUpload,
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        getBase64(info.file.originFileObj as RcFile, (url) => {
          setProfileImgUrl(url);
        });
        refetch();
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return { props, profileImgUrl };
};
