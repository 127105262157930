import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { IUserEntity } from 'types/entities/user.entity';

export const columns: ColumnsType<IUserEntity> = [
  {
    title: 'Full Name',
    key: 'name',
    dataIndex: 'name',
    render: (_, record: IUserEntity) => (
      <Typography.Text strong>{record?.fullname}</Typography.Text>
    ),
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    render: (_, record: IUserEntity) => record.email,
  },
];
