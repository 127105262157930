import Icon from '@ant-design/icons';
import { Button, Modal, Space, notification } from 'antd';
import { useCreateBookingForShipmentMutation } from 'api/bookings';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import OrderCreationForm from 'app/components/PurchaseOrderCreation/CreationForm';
import UploadOrderFileModal from 'app/components/PurchaseOrderCreation/UploadPdfModal';
import dayjs from 'dayjs';
import React, { FC, Reducer, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IPurchaseOrderCreateBody,
  IPurchaseOrderEntity,
  POFileType,
} from 'types/entities/purchase-order.entity';
import { BookingCreateModalProps } from 'types/feature/bookings.types';

import { ReactComponent as OrderFilled } from '../../../../assets/order-filled.svg';
import {
  BookingCreateModalActionEnum,
  IBookingCreateModalAction,
  IBookingCreateState,
  reducer,
} from './bookingCreateReducer';

const BookingCreateModal: FC<BookingCreateModalProps> = ({
  isShipmentTab,
  shipmentId,
  shipmentSlug,
}) => {
  // rtk query
  const navigate = useNavigate();
  const { data: shipment } = useGetShipmentsBySlugQuery(shipmentSlug || '');
  const [createBooking, { isLoading }] = useCreateBookingForShipmentMutation();
  const [{ isOpen, step, extractedData }, dispatch] = useReducer<
    Reducer<IBookingCreateState, IBookingCreateModalAction>,
    IBookingCreateState
  >(
    reducer,
    {
      isOpen: false,
      step: 0,
      extractedData: undefined,
    }, // Add the correct type for the initial state
    () => ({
      isOpen: false,
      step: 0,
      extractedData: undefined,
    })
  );

  // methods
  const onCloseModal = () =>
    dispatch({ type: BookingCreateModalActionEnum.CLOSE, payload: {} });
  const onOpenModal = () =>
    dispatch({ type: BookingCreateModalActionEnum.OPEN, payload: {} });
  const onStepChange = (step: number) =>
    dispatch({ type: BookingCreateModalActionEnum.STEP, payload: { step } });

  const navigateToPurchseOrderPage = () => {
    navigate('/purchase-orders');
    onCloseModal();
  };

  // hooks
  const onParseSuccess = (extractedData: IPurchaseOrderEntity) => {
    try {
      dispatch({
        type: BookingCreateModalActionEnum.PARSE,
        payload: { extractedData },
      });
    } catch (error) {
      notification.error({ message: 'Error while parsing file !' });
    }
  };

  const onFinish = async (booking: IPurchaseOrderCreateBody) => {
    try {
      if (shipmentId && shipment) {
        const body = {
          ...booking,
          origin: shipment.origin,
          destination: shipment.destination,
          incoterms: shipment.incoterms,
          cargoReadyDate: dayjs(shipment.cargoReadyDate),
          modality: shipment.shipmentType.modality || booking.modality,
        };
        await createBooking({ body, shipmentId }).unwrap();
        onCloseModal();
      }
    } catch (error) {
      notification.warning({ message: 'Unknown Error !' });
    }
  };

  const renderUploadButtons = () => {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Button
          size="large"
          icon={<Icon component={OrderFilled} />}
          onClick={() => onStepChange(1)}
        >
          Create Manually
        </Button>
        <Space>
          <UploadOrderFileModal
            onClickShowModal={() => onStepChange(1)}
            fileType={POFileType.PDF}
            onParseBookingSuccess={onParseSuccess}
          />
          <UploadOrderFileModal
            onClickShowModal={() => onStepChange(1)}
            fileType={POFileType.XLSX}
            onParseBookingSuccess={onParseSuccess}
          />
        </Space>
      </Space>
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={isShipmentTab ? onOpenModal : navigateToPurchseOrderPage}
      >
        Create New Booking
      </Button>
      <Modal
        title="Create New Booking"
        open={isOpen}
        width={step === 0 ? 450 : '80vw'}
        footer={null}
        onCancel={onCloseModal}
        centered
      >
        {step === 0 ? (
          renderUploadButtons()
        ) : (
          <OrderCreationForm
            defaultPurchaseOrder={
              (extractedData as IPurchaseOrderEntity) ?? null
            }
            isEditView={false}
            onFinish={onFinish}
            isProcessing={isLoading}
            onCancel={onCloseModal}
            file={null}
            createBookingFlow={true}
          />
        )}
      </Modal>
    </>
  );
};

export default BookingCreateModal;
