import { LinkOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import ProfileImage from 'app/components/ProfilePicture';
import { computeTotalFees } from 'app/components/Proposal/Fees/fees.const';
import CommentBox from 'app/design-system/CommentBox';
import DescriptionTitle from 'app/design-system/DescriptionTitle';
import React, { FC } from 'react';
import {
  ICommentSectionProps,
  IHeaderSectionProps,
  IProposalSectionsBasePropos,
  ITotalFeeSectionProps,
} from 'types/feature/proposal.types';

import { computeTagColors } from 'utils/format-fields';
import { computeFullName } from 'utils/user-utils';

import {
  renderProposalItems,
  renderQouteDocumentTable,
} from './ProposalDetailsTable';
import './proposal-modal.scss';

const { Text, Title } = Typography;

const HeaderSection: FC<IHeaderSectionProps> = ({
  proposal,
  isEditEnabled,
  onEditClick,
}) => {
  const assigneeName = proposal.assignee
    ? computeFullName(proposal.assignee)
    : '';

  const copyProposalId = () => {
    const url = `${new URL(window.location.href).origin}/proposals/${
      proposal.id
    }?email=${proposal.assignee.email}`;
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Success',
      description: 'Proposal link was copied to your clipboard',
    });
  };

  return (
    <div className="header">
      <div className="info">
        <Tooltip title={assigneeName}>
          <div>
            <ProfileImage
              name={assigneeName}
              photoUrl={proposal?.assignee?.photo}
              size={40}
              wrapperClassName="user-gravatar"
            />
          </div>
        </Tooltip>

        <DescriptionTitle
          title={
            <div className="title-edit-wrapper">
              {`RFQ-${proposal.slug}`}

              {isEditEnabled && onEditClick && (
                <Space>
                  <Button
                    onClick={() => onEditClick(proposal)}
                    size="small"
                    className="edit-btn"
                  >
                    Edit
                  </Button>
                  <Button
                    shape="round"
                    size="small"
                    className="copy-btn"
                    icon={<LinkOutlined rotate={45} />}
                    onClick={copyProposalId}
                  >
                    Copy link
                  </Button>
                </Space>
              )}
            </div>
          }
          description={proposal?.company?.name}
        />
      </div>
      <div className="status">
        <Tag
          color={computeTagColors(proposal.status)}
          style={{ textTransform: 'capitalize' }}
        >
          {proposal.status}
        </Tag>
      </div>
    </div>
  );
};

const TotalFeeSection: FC<ITotalFeeSectionProps> = ({
  proposal,
  currency,
  className,
}) => (
  <div className={`proposal-cost-box ${className}`}>
    <Text strong>Total Amount:</Text>
    <Space className="amount-value-box" direction="vertical" size={[1, 1]}>
      <Text strong className="amount-value-box__total-converted">
        {proposal.proposalCost} {currency?.toUpperCase()}
      </Text>

      <Text strong className="amount-value-box__total-brut">
        {computeTotalFees(proposal.proposalItems)}
      </Text>
    </Space>
  </div>
);

const FeeDetailSection: FC<IProposalSectionsBasePropos> = ({
  proposal,
  diffKeys,
}) => (
  <div>
    <Title level={5} className="mx-0 my-1">
      Fee Details:
    </Title>
    <Table
      rowKey="id"
      columns={renderProposalItems({ diffKeys })}
      dataSource={proposal.proposalItems}
      pagination={false}
      size="middle"
      bordered
      rowClassName={(record, index) => {
        return diffKeys?.proposalItems[index]?._diffType || '';
      }}
    />
  </div>
);

const DocumentSection: FC<IProposalSectionsBasePropos> = ({ proposal }) => (
  <div>
    <Title level={5} className="mx-0 my-1">
      Quote Documents
    </Title>
    <Table
      rowKey="id"
      columns={renderQouteDocumentTable()}
      dataSource={proposal.files}
      pagination={false}
      size="middle"
      bordered
    />
  </div>
);

const CommentSection: FC<ICommentSectionProps> = ({
  proposal,
  onAddComment,
  isLoading,
}) => (
  <>
    <Title level={5} className="mx-0 my-1">
      Add your comments and questions
    </Title>
    <CommentBox
      comments={proposal.proposalComments}
      onAddComment={onAddComment}
      isLoading={isLoading}
      isExternal={false}
      userList={[]}
    />
  </>
);

export {
  HeaderSection,
  TotalFeeSection,
  FeeDetailSection,
  DocumentSection,
  CommentSection,
};
