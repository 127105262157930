import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useGetCarriersForProposalQuery } from 'api/proposal-external.api';
import DashboardCard from 'app/design-system/DashboardCard';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { ProposalRoutingEnum } from 'types/feature/proposal.types';
import { IImportantDetailsProps } from 'types/feature/proposals.types';

import { filterCarriers, getCarriersOptions } from 'utils/carriers';

const DEFAULT_FORMAT = 'YYYY-MM-DD';

const ImportantDetails: FC<IImportantDetailsProps> = ({
  isFieldEditDisabled,
  form,
  defaultValues,
}) => {
  const { data: carrierData, isLoading: isCarrierLoading } =
    useGetCarriersForProposalQuery();

  return (
    <DashboardCard
      headerTitle="Important Details"
      className="clear-background"
      large
      fitContent
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          ...defaultValues,
          ...(defaultValues.eta && { eta: dayjs(defaultValues.eta) }),
          ...(defaultValues.etd && { etd: dayjs(defaultValues.etd) }),
        }}
        scrollToFirstError
        name="important-details"
      >
        <Row gutter={[12, 12]}>
          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item
              name="transitTime"
              label="Transit Time (Days)"
              rules={[
                {
                  required: true,
                  message: 'Please insert transit time (in days) !',
                },
                {
                  type: 'number',

                  min: 1,
                  message: 'Transit time must be greater than 0 !',
                },
              ]}
              hasFeedback
              required
            >
              <InputNumber
                placeholder="Enter number of days"
                min={1}
                precision={0}
                type="number"
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>

          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item
              name="freeDays"
              label="Free Days"
              rules={[
                {
                  required: true,
                  message: 'Please insert free days, set it to 0 if none !',
                },
              ]}
              hasFeedback
              required
            >
              <InputNumber
                placeholder="Enter number of days"
                min={0}
                precision={0}
                type="number"
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>

          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item
              name="paymentTerms"
              label="Payment Terms (Days)"
              rules={[
                {
                  required: true,
                  message: 'Please insert payment terms (in days) !',
                },
              ]}
              hasFeedback
            >
              <InputNumber
                placeholder="Enter number of days"
                min={0}
                precision={0}
                type="number"
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item label="Carrier" name="carrierId">
              <Select
                placeholder="Select province"
                loading={isCarrierLoading}
                showSearch
                filterOption={filterCarriers}
                disabled={isFieldEditDisabled}
                options={[
                  {
                    value: null,
                    label: 'Unassigned Carrier',
                  },
                  ...getCarriersOptions(carrierData),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item name="eta" label="Estimated Arrival">
              <DatePicker
                format={DEFAULT_FORMAT}
                showTime={false}
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>

          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item name="etd" label="Estimated Departure">
              <DatePicker
                format={DEFAULT_FORMAT}
                showTime={false}
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>

          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item name="vesselName" label="Vessel Name">
              <Input
                placeholder="Enter Vessel Name"
                disabled={isFieldEditDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={24} sm={12} md={6}>
            <Form.Item label="Routing" name="routing">
              <Select
                disabled={isFieldEditDisabled}
                placeholder="Select Routing"
                options={[
                  {
                    value: ProposalRoutingEnum.DIRECT,
                    label: 'Direct',
                  },
                  {
                    value: ProposalRoutingEnum.INDIRECT,
                    label: 'In-direct',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DashboardCard>
  );
};
export default ImportantDetails;
