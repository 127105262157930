import { DeleteRowOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Divider,
  Select,
  Typography,
  Alert,
  Tooltip,
  Popconfirm,
  InputNumber,
  message,
} from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { useDeleteCostheadByIdMutation } from 'api/contract';
import LocationFields from 'app/design-system/LocationFields';
import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import {
  IChargeHeadEntity,
  ICostHeadEntity,
} from 'types/entities/contract.entity';
import { IContractFeesFormProps } from 'types/feature/contracts.type';

import { displayErrors } from 'utils/error-notification';
import { createServiceModeOptions } from 'utils/format-fields';

import ChargeTableForm from './ChargeTableForm';

const ContractFeesForm: FC<IContractFeesFormProps> = ({
  form,
  defaultValues,
  contractId,
}) => {
  const costHeadsChanges = Form.useWatch('costHeads', form);

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [deleteCostHead, { isLoading }] = useDeleteCostheadByIdMutation();

  const onDeleteCostHead = async (fieldName: number) => {
    try {
      const id = form.getFieldValue(['costHeads', fieldName, 'id']);
      if (contractId && id) {
        await deleteCostHead({ contractId, id }).unwrap();
        message.success('Cost Head deleted succesfully !');
      }

      // eslint-disable-next-line
    } catch (err: any) {
      displayErrors(err, { title: 'Delete Costhead Error' });
    }
  };

  const onChargesDataChanged = async (
    charges: IChargeHeadEntity[],
    fieldName: NamePath
  ) => {
    form.setFieldValue(fieldName, charges);
    await form.validateFields();
  };

  const allowToAddCostHead = () => {
    const costHeads: ICostHeadEntity[] = form.getFieldValue('costHeads');
    const costHeadWithoutCharges = costHeads.find(
      (costHead) => !costHead.charges?.length
    );

    return !costHeadWithoutCharges;
  };

  const scrollToBottom = useCallback(() => {
    const lastItem = messageContainerRef.current?.getElementsByClassName(
      `costHead-${costHeadsChanges?.length - 1}`
    );
    if (lastItem && lastItem.length > 0) {
      lastItem[lastItem.length - 1].scrollIntoView({ behavior: 'smooth' });
    }
  }, [costHeadsChanges?.length]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costHeadsChanges?.length]);

  const renderContractFees = () => (
    <Form.List
      name="costHeads"
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 1) {
              return Promise.reject(new Error('Insert at least 1 cost head '));
            } else {
              for (const costHead of names) {
                if (!costHead.charges?.length) {
                  return Promise.reject(
                    new Error(
                      'Please insert at least one charge head (fee details) for each cost head.'
                    )
                  );
                }
              }

              return Promise.resolve();
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <div ref={messageContainerRef}>
          <Row justify="space-between" align="middle">
            <Form.Item>
              <Typography.Title level={3} className="m-4">
                Contract Fees
              </Typography.Title>
            </Form.Item>
            <Form.Item>
              <Tooltip
                title={
                  allowToAddCostHead()
                    ? 'Add new cost head'
                    : 'Make sure to add at least one charge to the empty cost head ! '
                }
              >
                <Button
                  type="link"
                  onClick={() => {
                    add({ charges: [], title: 'New Cost Head' });
                  }}
                  icon={<PlusCircleOutlined />}
                  size="large"
                  disabled={!allowToAddCostHead() || isLoading}
                >
                  Add Cost Head
                </Button>
              </Tooltip>
            </Form.Item>
          </Row>
          <Form.ErrorList
            errors={errors.map((error: ReactNode) => (
              <Alert
                key={error?.toString()}
                message={error}
                type="error"
                showIcon
              />
            ))}
          />
          <br />

          {fields.map((field) => {
            return (
              <div className={`costHead-${field.key}`} key={field.key}>
                <Row key={field.key} gutter={[12, 1]}>
                  <Form.Item name={[field.name, 'id']} hidden>
                    <Input />
                  </Form.Item>
                  <Col span={4}>
                    <Form.Item
                      name={[field.name, 'title']}
                      label="Cost Head"
                      required
                    >
                      <Input placeholder="Ex. Clearance Fees" />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Service Mode"
                      name={[field.name, 'serviceMode']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a service mode.',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Service Mode"
                        options={createServiceModeOptions()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Transit Time (Days)"
                      name={[field.name, 'transitDays']}
                      rules={[
                        {
                          required: true,
                          message: 'Please insert transit time (in days) !',
                        },
                        {
                          type: 'number',

                          min: 1,
                          message: 'Transit time must be greater than 0 !',
                        },
                      ]}
                      hasFeedback
                      required
                    >
                      <InputNumber
                        placeholder="Enter number of days"
                        min={1}
                        precision={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <LocationFields
                    form={form}
                    isEditView={true}
                    defaultLocations={
                      defaultValues?.costHeads[field.name]
                        ? {
                            origin: defaultValues?.costHeads[field.name].origin,
                            destination:
                              defaultValues?.costHeads[field.name].destination,
                          }
                        : null
                    }
                    colSpan={5}
                    filedsName={[
                      [field.name, 'origin'],
                      [field.name, 'destination'],
                      [field.name, 'originFull'],
                      [field.name, 'destinationFull'],
                    ]}
                    groupField="costHeads"
                  />
                  <Col span={1} className="dynamic-delete-order-button">
                    {fields.length > 1 ? (
                      <Form.Item>
                        <Popconfirm
                          title="Delete Cost Head"
                          description="Are you sure to delete this cost head?"
                          okText="Confirm"
                          cancelText="Cancel"
                          onConfirm={async () => {
                            await onDeleteCostHead(field.name);
                            remove(field.name);
                          }}
                        >
                          <Button
                            icon={<DeleteRowOutlined />}
                            danger
                            disabled={isLoading}
                            loading={isLoading}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <ChargeTableForm
                  contractId={contractId}
                  defaultValues={
                    form.getFieldValue(['costHeads', field.name, 'charges']) ??
                    []
                  }
                  onValuesChange={(charges) =>
                    onChargesDataChanged(charges, [
                      'costHeads',
                      field.name,
                      'charges',
                    ])
                  }
                  tableKey={`charges-${field.name}`}
                />

                <Divider />
              </div>
            );
          })}
        </div>
      )}
    </Form.List>
  );

  return (
    <Form
      {...(defaultValues && { initialValues: defaultValues })}
      layout="vertical"
      form={form}
      scrollToFirstError
    >
      {renderContractFees()}
    </Form>
  );
};

export default ContractFeesForm;
