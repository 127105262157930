import { createSlice } from '@reduxjs/toolkit';
import { ChatHistory } from 'types/entities/chat.entity';

interface ChatState {
  chat: ChatHistory[];
}

const initialState = {
  chat: [
    {
      user: 'ai',
      text: '👋 Hi! I am your AI Supply Chain assistant, ask me anything about beebolt platform and your supply chain data!',
      name: 'action.payload.name',
    },
  ],
} as ChatState;

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addQuestion: (state, action) => {
      state.chat?.push({
        user: action.payload.user,
        text: action.payload.text,
        name: action.payload.name,
      });
    },

    removeQuestion: (state) => {
      if (state.chat) {
        const lastMsgIndex = state.chat.length - 1;
        if (lastMsgIndex >= 0) {
          const updatedChat = [...state.chat];
          updatedChat.splice(lastMsgIndex, 1);
          state.chat = updatedChat; // Update chat history with modified message
        }
      }
    },

    resetQuestion: (state) => {
      state.chat = initialState.chat;
    },

    addResponse: (state, action) => {
      if (state.chat) {
        const lastMsgIndex = state.chat.length - 1;
        if (lastMsgIndex >= 0) {
          const updatedChat = [...state.chat]; // Create a copy of chat history array
          updatedChat[lastMsgIndex] = {
            ...updatedChat[lastMsgIndex],
            text:
              (updatedChat[lastMsgIndex].text ?? '') + (action.payload ?? ''),
          };
          state.chat = updatedChat; // Update chat history with modified message
        }
      }
    },
  },
});

export const { addQuestion, addResponse, resetQuestion, removeQuestion } =
  chatSlice.actions;
export default chatSlice.reducer;
