import { Button, Row, Skeleton } from 'antd';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import React, { FC, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import RecentForwarders from './ForwardersList';

const ShipmentRequestQuote: FC = () => {
  const [_, setSearchParams] = useSearchParams();
  const { slug = '' } = useParams();
  const location = useLocation();
  const { shipmentId = '' } = location.state ?? {};
  const navigate = useNavigate();

  const onNavigateToShipmentDetails = () => {
    navigate(`/shipments/${slug}`, { state: { shipmentId } });
  };

  const { data: shipment, isLoading } = useGetShipmentsBySlugQuery(slug, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!location.state?.shipmentId) {
      setSearchParams(undefined, {
        state: { shipmentId: shipment?.id },
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [shipment, location]);

  return isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <RecentForwarders shipmentId={shipmentId} />
      <Row justify={'end'}>
        <Button type="primary" onClick={onNavigateToShipmentDetails}>
          Go to shipment details
        </Button>
      </Row>
    </>
  );
};

export default ShipmentRequestQuote;
