import { EditOutlined } from '@ant-design/icons';
import { Button, Typography, notification } from 'antd';
import { useEditShipmentMutation } from 'api/shipment';
import { useAddTrackingByBillMutation } from 'api/tracking';
import AddContainerModal from 'app/components/Container/AddContainerModal';
import { renderServiceMode } from 'app/components/ContractRates/CostHeadCollapse/cost-head.helper';
import { computeDimensionValue } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import DashboardCard from 'app/design-system/DashboardCard';
import DescriptionList from 'app/design-system/DescriptionList';
import EditableText from 'app/design-system/EditableText';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { UserRoleEnum } from 'types/entities/user.entity';
import { ContainerModalTypeEnum } from 'types/feature/container.types';
import { IShipmentInfoProps } from 'types/feature/shipments.types';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import { capitalize } from 'utils/format-fields';
import { isShipmentCreatedByExternal } from 'utils/shipment-helper';
import { isAirTransportation, isTrackingActive } from 'utils/shipment-type';

import './shipment-info.style.scss';

const { Text } = Typography;

const ShipmentInfo: FC<IShipmentInfoProps> = ({ shipment, cardOverlay }) => {
  const [updateShipment, { isLoading }] = useEditShipmentMutation();
  const [addTracking, { isLoading: isAddTrackingLoading }] =
    useAddTrackingByBillMutation();

  const acceptedShipment = {
    shipmentType: shipment?.acceptedShipmentType ?? shipment?.shipmentType,
    cargoUnits:
      shipment?.acceptedCargoUnits.length > 0
        ? shipment?.acceptedCargoUnits
        : shipment?.cargoUnits,
    shipmentItems: shipment?.acceptedShipmentItems
      ? shipment?.acceptedShipmentItems
      : shipment?.shipmentItems,
  };

  const dimensionFields = computeDimensionValue(
    acceptedShipment.shipmentType,
    acceptedShipment.cargoUnits
  );

  const onSaveReference = (value: string) => {
    updateShipment({
      shipmentId: shipment.id,
      shipment: {
        shipmentReference: value,
      },
    });
  };
  const trackingNumber =
    shipment.mbl ?? shipment.awb ?? shipment.trackingNumber;
  const isCargoOwner = !!(
    shipment.currentUserRole === UserRoleEnum.CARGO_OWNER
  );
  const isForwarder = !!(shipment.currentUserRole === UserRoleEnum.FORWARDER);
  const isActionAllowed =
    !isViewer(shipment.currentCollaborator.accessLevel) &&
    (isForwarder || isCargoOwner);

  const booleanToYesNo = (value: string | boolean) => {
    return value.toString() === 'true' ? 'Yes' : 'No';
  };

  const onRefreshMBL = async () => {
    if (!shipment?.carrierId || !shipment?.mbl) {
      notification.error({
        message: 'Validation Error',
        description: 'Make sure to add an MBL!',
      });
      return;
    }

    try {
      await addTracking({
        carrierId: shipment.carrierId,
        mbl: shipment.mbl,
        shipmentId: shipment.id,
      }).unwrap();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, {
        title: 'Sorry, Unable to update container',
      });
    }
  };

  const renderShipmentDetails = () => (
    <div className="shipment-info-wrapper">
      <DescriptionList label="Shipment Info" description={shipment.slug} />
      <DescriptionList
        label="Reference"
        description={
          !isViewer(shipment.currentCollaborator.accessLevel) ? (
            <EditableText
              defaultValue={shipment.shipmentReference}
              onSave={onSaveReference}
              isLoading={isLoading}
            />
          ) : (
            shipment.shipmentReference ?? 'N/A'
          )
        }
      />
      <DescriptionList
        label="Shipment Type"
        description={shipment.shipmentType.title?.toUpperCase()}
      />

      <DescriptionList
        label="Contact Person"
        description={shipment.createdBy.fullname}
      />
      {isShipmentCreatedByExternal(
        shipment.createdBy,
        shipment.initiatedBy
      ) && (
        <DescriptionList
          label="Initiated By"
          description={shipment.initiatedBy.fullname}
        />
      )}
      {shipment.proposalReference && (
        <DescriptionList
          label="Forwarder Reference"
          description={shipment.proposalReference}
        />
      )}
      <DescriptionList
        label="Quantity"
        description={acceptedShipment.shipmentItems}
      />
      {dimensionFields && (
        <>
          <DescriptionList
            label="Total Gross Weight"
            description={`${dimensionFields.weight} kg`}
          />
          <DescriptionList
            label="Total Volume"
            description={`${dimensionFields.volume} cbm`}
          />
          <DescriptionList
            label="Chargeable Weight"
            description={`${dimensionFields.chargeableWeight} kg`}
          />
        </>
      )}
      <DescriptionList
        label="Product Type"
        description={shipment?.product?.category}
      />
      <DescriptionList
        label="Incoterm"
        description={shipment.incoterms.toUpperCase()}
      />
      {shipment.serviceMode && (
        <DescriptionList
          label="Service Mode"
          description={renderServiceMode(shipment.serviceMode)}
        />
      )}
      <DescriptionList
        label="Non Stackable"
        description={booleanToYesNo(shipment.nonStackable)}
      />
      <DescriptionList
        label="Cargo Type"
        description={capitalize(shipment.cargoType)}
      />
      <DescriptionList
        label={
          isAirTransportation(acceptedShipment.shipmentType.title)
            ? 'Airway Bill'
            : 'Master BL'
        }
        description={
          <div className="mbl-number">
            <Text>{trackingNumber}</Text>
            {isActionAllowed ? (
              <>
                <AddContainerModal
                  shipmentType={acceptedShipment.shipmentType.title}
                  defaultData={{
                    number: trackingNumber ?? '',
                    containerType: 'lading-bill',
                    carrierId: shipment.carrierId ?? '',
                  }}
                  showConfirmModal={!!(shipment.containers.length > 0)}
                  shipmentId={shipment.id}
                  shipmentSlug={shipment.slug}
                  containerModalType={ContainerModalTypeEnum.MBL}
                  {...(isTrackingActive(shipment)
                    ? {
                        actionButton: {
                          text: '',
                          type: 'link',
                          icon: <EditOutlined />,
                        },
                      }
                    : {
                        actionButton: {
                          text: isAirTransportation(
                            acceptedShipment.shipmentType.title
                          )
                            ? 'Add AWB'
                            : 'Add MBL',
                          type: 'dashed',
                        },
                      })}
                />
                {!shipment.currentLocation && shipment.mbl && (
                  <Button
                    loading={isAddTrackingLoading}
                    onClick={onRefreshMBL}
                    type="link"
                  >
                    refresh
                  </Button>
                )}
              </>
            ) : (
              'N/A'
            )}
          </div>
        }
      />
      <DescriptionList
        label="Ready Date"
        description={dayjs(shipment.cargoReadyDate).format('MMM DD, YYYY')}
      />
      <DescriptionList
        label="Created Date"
        description={dayjs(shipment.createdAt).format('MMM DD, YYYY')}
      />
    </div>
  );

  return cardOverlay ? (
    <DashboardCard
      headerTitle="Details"
      fitContent
      noMargin
      className="overview-card-wrapper-2"
    >
      {renderShipmentDetails()}
    </DashboardCard>
  ) : (
    renderShipmentDetails()
  );
};

export default ShipmentInfo;
