import { initRemoteConfig } from 'api/common/remoteConfig';
import React, {
  createContext,
  useState,
  ReactNode,
  FC,
  useEffect,
  useContext,
} from 'react';

type TFeatureFlagsContext = {
  featureFlags: Record<string, boolean> | null;
};

type IFeatureFlagsProviderProps = {
  children: ReactNode;
};

const FeatureFlagsContext = createContext<TFeatureFlagsContext | undefined>(
  undefined
);

export const FeatureFlagsProvider: FC<IFeatureFlagsProviderProps> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<Record<
    string,
    boolean
  > | null>(null);

  useEffect(() => {
    const getFeatureFlags = async () => {
      try {
        const flags = await initRemoteConfig();
        setFeatureFlags(flags);
      } catch (e) {
        console.log(e);
      }
    };

    if (!featureFlags) {
      getFeatureFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const featureFlags = useContext(FeatureFlagsContext);

  return { ...featureFlags };
};
