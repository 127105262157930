import { Table, Typography } from 'antd';
import React, { FC } from 'react';
import { IInvoiceEntity } from 'types/entities/invoice.entity';
import { IInvoiceListTableProps } from 'types/feature/invoice-list';

import { ReactComponent as InvoiceIllustration } from '../../../../../assets/invoice-illustration.svg';
import './invoices-list.scss';

const { Text, Title } = Typography;

const InvoicesListTable: FC<IInvoiceListTableProps> = ({
  handleTableChange,
  updatedInvoicesData,
  isLoading,
  rowSelection,
  selectedColumns,
  paginationAndSortingState,
}) => {
  return (
    <>
      <div className="invoices-wrapper">
        <Table
          rowKey={(record: IInvoiceEntity) => record?.id}
          columns={selectedColumns}
          dataSource={updatedInvoicesData?.items}
          size="middle"
          onChange={handleTableChange}
          loading={isLoading}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: paginationAndSortingState.limit,
            pageSizeOptions: ['10', '25', '50'],
            total: updatedInvoicesData?.meta.totalItems ?? 0,
            current: updatedInvoicesData?.meta.currentPage ?? 1,
          }}
          rowSelection={rowSelection}
          locale={{
            emptyText: (
              <div className="invoice-empty-screen">
                <InvoiceIllustration />
                <Title level={4}>Invoices</Title>
                <Text>
                  Create new invoices to collect payments & keep records.
                </Text>
              </div>
            ),
          }}
        />
      </div>
    </>
  );
};

export default InvoicesListTable;
