import { Col, Row, Typography } from 'antd';
import { formSearchParams, useUTMState } from 'providers/utm-provider';
import React, { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthTypeEnum, IAuthProps } from 'types/feature/auth.types';

import AnalyticsLogo from '../../../assets/onboarding/analytics-logo.svg';
import DocumentLogo from '../../../assets/onboarding/document-logo.svg';
import InvoiceLogo from '../../../assets/onboarding/invoice-logo.svg';
import PoLogo from '../../../assets/onboarding/po-logo.svg';
import RfqLogo from '../../../assets/onboarding/rfq-logo.svg';
import SignupIllustration from '../../../assets/onboarding/signup-illustration.svg';
import TrackingLogo from '../../../assets/onboarding/tracking-logo.svg';
import ForgotPassword from './ForgotPassword/forgotPassword';
import ResetPasswordExternal from './ForgotPassword/resetPasswordExternal';
// child components
import AuthLoginForm from './LoginForm';
import AuthSignUpForm from './SignupForm';
// style
import './auth.style.scss';

const { Paragraph, Text } = Typography;

const Auth: FC<IAuthProps> = ({ type, external }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaign = useUTMState();
  const utmSearchParam = formSearchParams(campaign);

  const redirctTo = params.get('redirect') ?? '/dashboard';
  // functions
  const signup = () => navigate('/signup');
  const login = () => navigate('/login');
  const navigateToMainPage = () => {
    document.location.href = `${redirctTo}?${utmSearchParam}`;
  };
  const onForgotPassword = () => navigate('/forgot-password');
  const onPasswordEmailSent = () => navigate('/reset-password');

  // component
  return (
    <div className="login-container">
      <Row className="login-wrapper">
        <Col xs={24} sm={24} md={10} lg={10} xl={12}>
          <div className="login-wrapper__form">
            {type === AuthTypeEnum.LOGIN ? (
              <AuthLoginForm
                signup={signup}
                navigateToMainPage={navigateToMainPage}
                onForgotPassword={onForgotPassword}
              />
            ) : type === AuthTypeEnum.SIGNUP ? (
              <AuthSignUpForm
                login={login}
                navigateToMainPage={navigateToMainPage}
              />
            ) : type === AuthTypeEnum.SEND_EMAIL ? (
              <ForgotPassword
                navigateToMainPage={login}
                onPasswordEmailSent={onPasswordEmailSent}
              />
            ) : (
              <ResetPasswordExternal
                navigateToMainPage={login}
                resendVerificationCode={onForgotPassword}
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={12}>
          <div className="login-wrapper__illustration">
            <img src={SignupIllustration} alt="login trade freight" />
            <Paragraph className="login-wrapper__illustration__caption-text">
              Supercharge your <Text strong>supply chain</Text> with Beebolt!
            </Paragraph>
            <div className="login-wrapper__illustration__logos">
              <img src={TrackingLogo} alt="login trade freight" />
              <img src={RfqLogo} alt="login trade freight" />
              <img src={AnalyticsLogo} alt="login trade freight" />
            </div>
            <div className="login-wrapper__illustration__logos">
              <img src={DocumentLogo} alt="login trade freight" />
              <img src={InvoiceLogo} alt="login trade freight" />
              <img src={PoLogo} alt="login trade freight" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Auth;
