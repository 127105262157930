import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Space, Badge, theme, Spin } from 'antd';
import React, { FC } from 'react';
import { IRenderFilterProps } from 'types/feature/shipments.types';

const { useToken } = theme;

const FilterDropdown: FC<IRenderFilterProps> = ({
  onOpenTableView,
  onCloseTableView,
  isTableViewOpen,
  filters,
  renderFilterComponent,
  buttonText,
  itemKey,
  fromFilter,
  isLoading = false,
  isDisabled,
}) => {
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    maxHeight: '50vh',
    overflow: 'auto',
    maxWidth: '250px',
    padding: '15px',
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const onDropdownOpenchange = () => {
    if (onOpenTableView && onCloseTableView) {
      isTableViewOpen ? onCloseTableView() : onOpenTableView();
    }
  };
  return (
    <Spin spinning={isLoading}>
      <Dropdown
        onOpenChange={onDropdownOpenchange}
        open={isTableViewOpen}
        disabled={isDisabled}
        trigger={['click']}
        dropdownRender={(menu) => (
          <div style={contentStyle} onClick={handleClick}>
            {renderFilterComponent}
          </div>
        )}
      >
        <Button className={fromFilter ? 'filter-btn-fixed' : 'filter-btn'}>
          <span className="btn-text">{buttonText}</span>
          <Space>
            {filters[itemKey]?.length > 0 && !isDisabled && (
              <Badge
                count={itemKey === 'date' ? 1 : filters[itemKey]?.length}
                color="#6F6F6F"
              />
            )}

            <CaretDownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Spin>
  );
};

export default FilterDropdown;
