import Icon, { DownOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Row,
  Table,
  Input,
  MenuProps,
  Dropdown,
  Space,
  notification,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useCreateBookingCollaboratorMutation,
  useGetBookingCollaboratorsByIdQuery,
  useUpdateBookingCollaboratorMutation,
} from 'api/Booking-collaborators.ts';
import AddConnectionModal from 'app/components/Connections/AddConnectionModal';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IBookingCollaboratorsProps } from 'types/feature/bookings.types';
import { IBookingCollaborators } from 'types/feature/bookings.types';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

import { getAccessLevels } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import {
  formatAccessLevel,
  formatAccessLevelOptions,
} from 'utils/format-fields';

import { ReactComponent as AddUserIcon } from '../../../../../assets/icons/add-user-icon.svg';
import './booking-collaborators.scss';
import { bookingColumnsConst } from './collaborators.const';

const { Search } = Input;
const { confirm } = Modal;

const BookingsCollaborators: FC<IBookingCollaboratorsProps> = ({
  bookingId,
  showCollaboratorModal,
  hideCollaboratorModal,
  currentUserAccessLevel,
  isCollaboratorOpen,
}) => {
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    undefined
  );
  const [loadingStates, setLoadingStates] = useState({});
  const [collaborators, setCollaborators] = useState<
    IBookingCollaborators[] | null
  >();

  const user = useSelector((state: RootState) => state.user.user);
  const {
    data: bookingCollaboratorsData,
    isLoading,
    isError,
    error,
  } = useGetBookingCollaboratorsByIdQuery({
    bookingId,
    search: searchedValue || '',
  });
  const [
    createCollaborator,
    { isSuccess: isInviteSuccess, isError: isInviteError, error: inviteError },
  ] = useCreateBookingCollaboratorMutation();

  const [
    updateAccessLevel,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateBookingCollaboratorMutation();

  // methods
  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setSearchedValue(undefined);
    }
  };

  const handleAddCollaborator = async (
    collaborator: IBookingCollaborators,
    accessLevel: AccessLevelEnum
  ) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Invite',

      icon: null,
      content: `Are you sure you want to invite ${collaborator.fullname}? 
    please note that inviting a collaborator will also invite them to the underlying purchase orders.`,
      onOk: async () => {
        if (user) {
          setLoadingStates((prevStates) => ({
            ...prevStates,
            [collaborator.id]: true,
          }));
          try {
            await createCollaborator({
              bookingId,
              collaborators: [
                { collaboratorId: collaborator.id, accessLevel: accessLevel },
              ],
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            displayErrors(error, { title: 'error adding collaborator' });
          }
          setLoadingStates((prevStates) => ({
            ...prevStates,
            [collaborator.id]: false,
          }));
        }
      },
    });
  };

  const handleUpdateCollab = async (
    bookingId: string,
    collaborator: IBookingCollaborators,
    accessLevel: AccessLevelEnum
  ) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Update',

      icon: null,
      content: `Are you sure you want to change access level for  ${collaborator.fullname} ? 
    please note that this will reflect on their access level on the underlying purchase orders.`,
      onOk: async () => {
        setLoadingStates((prevStates) => ({
          ...prevStates,
          [collaborator.id]: true,
        }));
        try {
          await updateAccessLevel({
            bookingId,
            collaboratorId: collaborator.id,
            accessLevel,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'error updating access level' });
        }
        setLoadingStates((prevStates) => ({
          ...prevStates,
          [collaborator.id]: false,
        }));
      },
    });
  };
  const handleClick = (
    collaborator: IBookingCollaborators,
    accessLevel: AccessLevelEnum
  ) => {
    collaborator.accessLevel
      ? handleUpdateCollab(bookingId, collaborator, accessLevel)
      : handleAddCollaborator(collaborator, accessLevel);
  };

  // hooks
  useEffect(() => {
    if (bookingCollaboratorsData) {
      setCollaborators(
        [...bookingCollaboratorsData.data.items].sort(
          (collaboratorA, collaboratorB) =>
            collaboratorB.isCollaborator ? 1 : -collaboratorA.isCollaborator
        )
      );
    }
  }, [bookingCollaboratorsData]);
  useEffect(() => {
    if (isInviteSuccess) {
      notification.success({ message: 'collaborator added' });
    }
  }, [isInviteSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({ message: 'Access level updated' });
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error getting collaborators' });
    }
  }, [error, isError]);
  useEffect(() => {
    if (isInviteError && inviteError) {
      displayErrors(inviteError, { title: 'add collaborator error' });
    }
  }, [inviteError, isInviteError]);

  useEffect(() => {
    if (isUpdateError && updateError) {
      displayErrors(updateError, { title: 'updating AccessLevel error' });
    }
  }, [updateError, isUpdateError]);

  const renderDropdown = (collaborator: IBookingCollaborators) => {
    const isCollaboratorinvited = collaborator.accessLevel ? true : false;
    const items: MenuProps['items'] = [
      ...getAccessLevels(currentUserAccessLevel).map((accessLevel) => {
        return {
          label: (
            <div onClick={() => handleClick(collaborator, accessLevel)}>
              {formatAccessLevelOptions(isCollaboratorinvited, accessLevel)}
            </div>
          ),
          key: accessLevel,
        };
      }),
    ];

    return (
      <Dropdown
        overlayClassName="access-level-container"
        menu={{ items }}
        trigger={['click']}
      >
        <Button
          type={isCollaboratorinvited ? 'text' : 'default'}
          loading={loadingStates[collaborator.id]}
        >
          <Space>
            {formatAccessLevel(collaborator.accessLevel)}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };
  const allColumns: ColumnsType<IBookingCollaborators> = [
    ...bookingColumnsConst,
    {
      align: 'right',
      key: 'action',
      render: (_: unknown, record: IBookingCollaborators, index: number) =>
        renderDropdown(record),
    },
  ];
  return (
    <Modal
      title="Invite Collaborators"
      open={isCollaboratorOpen}
      onCancel={hideCollaboratorModal}
      footer={null}
    >
      <Row justify="space-between" gutter={[0, 52]}>
        {/* TODO: API donot support search feature */}
        <Search
          placeholder="search"
          onSearch={(v: string) => setSearchedValue(v)}
          onChange={handleEmptySearch}
          allowClear
        />
      </Row>
      <AddConnectionModal
        currentUserAccessLevel={currentUserAccessLevel}
        hideCollaboratorModal={hideCollaboratorModal}
        showCollaboratorModal={showCollaboratorModal}
        bookingId={bookingId}
        btnProps={{
          text: 'Add New Collaborator',
          icon: <Icon component={AddUserIcon} type="text" />,
        }}
      />
      {isError ? (
        <Alert
          description="An error has occured while fetching collaborators .. "
          type="error"
          showIcon
        />
      ) : (
        <>
          <Table
            loading={isLoading}
            pagination={false}
            className="collaborators-table"
            rootClassName="collaborator-table-row"
            dataSource={collaborators ?? []}
            columns={allColumns}
            showHeader={false}
            rowKey="id"
          />
        </>
      )}
    </Modal>
  );
};

export default BookingsCollaborators;
