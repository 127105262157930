import { useChecklist } from '@dopt/react-checklist';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useFeatureFlags } from 'providers/feature-flag-provider';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { ShipmentStatusEnum } from 'types/feature/shipments.types';

import { dashboardViewEvent } from 'utils/analytics-events';

import Indicators from './Indicators';
import OnboardingChecklistCard from './OnboardingChecklistCard';
import PurchaseOrdersCard from './PurchaseOrdersCard';
import QuotesCard from './QuotesCard';
import ReviewInvoicesCard from './ReviewInvoicesCard';
import ShipmentsCard from './ShipmentsCard';
import TasksCard from './TasksCard';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const campaign = useUTMState();
  const onboardingChecklist = useChecklist(
    'announcement.signup-user-onboarding'
  );

  const showOnboardingChecklist =
    !onboardingChecklist.dismissed &&
    !onboardingChecklist.completed &&
    user &&
    dayjs(user.createdAt).isAfter('2024-04-20', 'day');

  const { featureFlags } = useFeatureFlags();
  const isOnboardingChecklistEnabled = featureFlags
    ? featureFlags['FEATURE_ONBOARDING_CHECKLIST_ENABLED']
    : false;

  // methods
  const navigateToShipmentsPage = (
    param: {
      id?: string;
      slug?: string;
      redirct?: { tab: string };
      status?: ShipmentStatusEnum;
    },
    tab?: string
  ) => {
    const {
      id = undefined,
      redirct = undefined,
      status = undefined,
      slug,
    } = param;
    if (tab) {
      navigate('/shipments', { state: { tab } });
      return;
    }
    if (slug)
      navigate(
        {
          pathname: `/shipments/${slug}/${redirct?.tab ?? 'quotes'}`,
        },
        {
          state: { shipmentId: id },
          replace: true,
        }
      );
    else
      navigate(
        {
          pathname: `/shipments`,
        },
        {
          state: { status: status },
        }
      );
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      dashboardViewEvent(
        {
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        },
        { campaign }
      );
    }
  }, [user, campaign]);

  return (
    <>
      <Indicators navigateToShipmentsPage={navigateToShipmentsPage} />
      <Row>
        <Col lg={12} xs={24}>
          {isOnboardingChecklistEnabled && showOnboardingChecklist && (
            <OnboardingChecklistCard />
          )}
          <QuotesCard navigateToShipmentsPage={navigateToShipmentsPage} />
          <TasksCard navigateToShipmentsPage={navigateToShipmentsPage} />
          <PurchaseOrdersCard />
        </Col>
        <Col lg={12} xs={24}>
          <ShipmentsCard navigateToShipmentsPage={navigateToShipmentsPage} />
          <ReviewInvoicesCard />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
