import Icon from '@ant-design/icons/lib/components/Icon';
import { Button, Row, Space, Spin, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useDeleteInvoiceByIdMutation,
  useGetAllInvoiceByShipmentIdQuery,
} from 'api/invoice';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IInvoiceEntity } from 'types/entities/invoice.entity';
import { IShipmentFinanceProps } from 'types/feature/shipments.types';

import {
  invoiceDeleteEvent,
  invoiceDownloadEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/bin-icon-filled-grey.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon-filled-grey.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf-icon-filled.svg';
import { ReactComponent as InvoiceIllustration } from '../../../assets/invoice-illustration.svg';
import InvoiceStatusSwitch from '../Invoice/InvoiceStatusSwitch';
import UploadInvoiceModal from '../Invoice/UploadInvoiceModal';
import { shipmentFinanceTabel } from './shipment-finance.const';
import './shipment-finance.scss';

const { Text, Title } = Typography;

const ShipmentFinance: FC<IShipmentFinanceProps> = ({
  shipment: { id: shipmentId, slug: shipmentSlug },
}) => {
  const user = useSelector((state: RootState) => state.user.user);

  const {
    data: invoicesData,
    isLoading,
    error,
    isError,
  } = useGetAllInvoiceByShipmentIdQuery(shipmentId, { skip: !shipmentId });

  const [deleteInvoiceById, { isLoading: isDeleting }] =
    useDeleteInvoiceByIdMutation();

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Error on getting invoices' });
    }
  }, [error, isError]);

  const onDeleteInvoice = async (invoiceId: string) => {
    try {
      await deleteInvoiceById(invoiceId).unwrap();
      message.success('Invoice deleted successfuly');

      invoiceDeleteEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        shipment_id: shipmentId,
        invoice_id: invoiceId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on deleting invoice' });
    }
  };

  const onAttachmentClick = (invoiceId: string, shipmentId: string) => {
    invoiceDownloadEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
      shipment_id: shipmentId,
      invoice_id: invoiceId,
    });
  };

  const columns: ColumnsType<IInvoiceEntity> = [
    {
      title: 'Invoice No',
      dataIndex: 'slug',
      key: 'slug',
      render: (_, record) => (
        <Space direction="vertical">
          <Link to={`/shipments/${shipmentSlug}/invoice/${record.id}`}>
            {record.slug}
          </Link>
          <Typography.Text strong>{record.invoiceNo}</Typography.Text>
        </Space>
      ),
    },
    ...shipmentFinanceTabel,
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return <InvoiceStatusSwitch invoice={record} />;
      },
    },
    {
      title: 'Attachment',
      dataIndex: 'attachment',
      key: 'attachment',
      render: (_, record) => {
        return (
          <Link
            to={record.files[0].file}
            target="_blank"
            onClick={() => onAttachmentClick(record.id, record.shipmentId)}
          >
            <Icon component={PdfIcon} className="pdf-icon-filled" />
          </Link>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Link to={`/shipments/${shipmentSlug}/invoice/${record.id}`}>
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <Button
              icon={<DeleteIcon />}
              onClick={() => onDeleteInvoice(record.id)}
              type="text"
              loading={isDeleting}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div className="shipment-finance-wrapper">
      <div className="invoice-header">
        <div></div>
        <UploadInvoiceModal
          shipmentId={shipmentId}
          shipmentSlug={shipmentSlug}
        />
      </div>

      {invoicesData && invoicesData.data.invoices.length > 0 ? (
        <Table
          rowKey="id"
          columns={columns}
          dataSource={invoicesData.data.invoices}
          pagination={false}
          size="middle"
        />
      ) : isLoading ? (
        <Row className="py-3" justify="center">
          <Spin />
        </Row>
      ) : (
        <div className="invoice-empty-screen">
          <InvoiceIllustration />
          <Title level={4}>Invoices</Title>
          <Text>Create new invoices to collect payments & keep records.</Text>
        </div>
      )}
    </div>
  );
};

export const ShipmentFinanceRouteWrapper: FC = () => {
  const props = useOutletContext<IShipmentFinanceProps>();
  return <ShipmentFinance {...props} />;
};

export default ShipmentFinance;
