/* eslint-disable @typescript-eslint/no-explicit-any */
import { useExtractDocumentMutation } from 'api/document-extractor';
import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
} from 'react';
import { IFileEntity } from 'types/entities/files.entity';

interface IExtractDocsProviderProps {
  children: JSX.Element;
}
type IExtractDocsContextType =
  | {
      isExtractingDocument: boolean;
      isExtractingError: boolean;
      isExtractingScuccess: boolean;
      showDocExtractMinimize: boolean;
      onExtractDocument: any;
      setShowDocExtractMinimize: any;
      selectedDoc: IFileEntity | null;
      setSelectedDoc: any;
      extractedFileId: string | null;
      setExtractedFileId: any;
    }
  | undefined;

export const ExtractDocsContext =
  createContext<IExtractDocsContextType>(undefined);

export const ExtractDocsContextProvider: FC<IExtractDocsProviderProps> = (
  props
) => {
  const [showDocExtractMinimize, setShowDocExtractMinimize] =
    useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<IFileEntity | null>(null);
  const [extractedFileId, setExtractedFileId] = useState<string | null>(null);

  const [
    onExtractDocument,
    {
      isLoading: isExtractingDocument,
      isError: isExtractingError,
      isSuccess: isExtractingScuccess,
    },
  ] = useExtractDocumentMutation();

  useEffect(() => {
    if (isExtractingDocument) {
      setShowDocExtractMinimize(false);
    }
  }, [isExtractingDocument]);

  return (
    <ExtractDocsContext.Provider
      value={{
        onExtractDocument,
        isExtractingDocument,
        isExtractingError,
        isExtractingScuccess,
        showDocExtractMinimize,
        setShowDocExtractMinimize,
        selectedDoc,
        setSelectedDoc,
        extractedFileId,
        setExtractedFileId,
      }}
      {...props}
    />
  );
};

export const useExtractDocs = () => {
  const extractDocs = useContext(ExtractDocsContext);

  return { ...extractDocs };
};
