import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import CompanyField from 'app/design-system/CompanyField';
import React, { FC, useEffect, useState } from 'react';
import {
  IAddForwarderFormProps,
  IForwrdersFormProps,
} from 'types/feature/forwarders.types';

import { UseForwarderForm } from './UseForwarderForm';

const AddForwarderForm: FC<IAddForwarderFormProps> = ({ shipmentId }) => {
  const [form] = useForm<IForwrdersFormProps>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    onInviteForwarder,
    handleSelectAcompany,
    addForwarderSuccess,
    addForwarderLoading,
  } = UseForwarderForm(form, shipmentId);

  // method
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (addForwarderSuccess) {
      notification.success({ message: 'Invitation sent to forwarders !' });
      handleCancel();
    }
  }, [addForwarderSuccess]);

  return (
    <>
      <Button type="default" onClick={showModal}>
        Invite New Forwarders
      </Button>
      <Modal
        title="Invite Forwarders"
        open={isModalOpen}
        width="70vw"
        onCancel={handleCancel}
        footer={
          <>
            <Button
              onClick={handleCancel}
              disabled={addForwarderLoading}
              loading={addForwarderLoading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onInviteForwarder}
              disabled={addForwarderLoading}
              loading={addForwarderLoading}
            >
              Invite
            </Button>
          </>
        }
      >
        <Form
          form={form}
          initialValues={{
            forwareders: [{ fullname: '', email: '', company: '' }],
          }}
        >
          <Row
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="middle"
            gutter={[12, 12]}
          >
            <Col span={10}>
              <Typography.Text className="field-wrapper__text">
                Name
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text className="field-wrapper__text">
                Email
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text className="field-wrapper__text">
                Company
              </Typography.Text>
            </Col>
          </Row>
          <Form.List name="forwareders">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row key={field.key} gutter={[12, 12]}>
                    <Col span={10}>
                      <Form.Item
                        name={[field.name, 'fullname']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Field is required',
                          },
                        ]}
                      >
                        <Input placeholder="Full name" />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        {...formItemLayoutWithOutLabel}
                        name={[field.name, 'email']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: "Please input the forwarder's email.",
                          },
                          {
                            type: 'email',
                            message: 'Please input a correct email address.',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter email"
                          className="custom-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name={[field.name, 'companyId']}
                        hidden
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...formItemLayoutWithOutLabel}
                        name={[field.name, 'company']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (value) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                'The company is required, if it is new make sure to add it first!'
                              );
                            },
                          }),
                        ]}
                      >
                        <CompanyField
                          userRole="forwarder"
                          handleSelectAcompany={handleSelectAcompany([
                            field.name,
                            'company',
                          ])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} className="dynamic-delete-button">
                      {fields.length > 1 ? (
                        <Button
                          icon={<DeleteFilled />}
                          onClick={() => remove(field.name)}
                          danger
                        ></Button>
                      ) : null}
                    </Col>
                  </Row>
                ))}

                <Button type="link" onClick={add} icon={<PlusCircleOutlined />}>
                  Add forwarder
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default AddForwarderForm;
