import { Typography } from 'antd';
import { computeDimensionValue } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import DescriptionList from 'app/design-system/DescriptionList';
import React, { FC } from 'react';
import { TShipmentDetailsComparisonProps } from 'types/feature/proposal.types';

import './shipment-details-comparison.scss';

const ShipmentDetailsComparison: FC<TShipmentDetailsComparisonProps> = ({
  shipment,
  proposal,
}) => {
  const shipmentDimensionFields =
    shipment &&
    computeDimensionValue(shipment.shipmentType, shipment.cargoUnits);

  const proposalDimensionFields =
    proposal &&
    computeDimensionValue(
      proposal.shipmentType,
      proposal.proposalShipmentItems
    );

  return (
    <div className=" shipment-details-comparison-wrapper">
      <Typography.Title level={4}>Shipment Details</Typography.Title>
      <div className="comparison-wrapper">
        <div className="left-wrapper">
          <div className="comparison-header">REQUESTED</div>

          <DescriptionList
            label="Freight Method"
            description={shipment.shipmentType.modality}
          />
          <DescriptionList
            label="Shipment Type"
            description={
              <Typography.Text
                className={`shipment-type-title ${shipment.shipmentType.title.replace(
                  ' ',
                  '-'
                )}`}
              >
                {shipment.shipmentType.title.toUpperCase()}
              </Typography.Text>
            }
          />

          <DescriptionList
            label="Quantity"
            description={shipment.shipmentItems}
          />
          {shipmentDimensionFields && (
            <>
              <DescriptionList
                label="Total Gross Weight"
                description={`${shipmentDimensionFields.weight} kg`}
              />
              <DescriptionList
                label="Total Volume"
                description={`${shipmentDimensionFields.volume} cbm`}
              />
              <DescriptionList
                label="Chargeable Weight"
                description={`${shipmentDimensionFields.chargeableWeight} kg`}
              />
            </>
          )}
        </div>
        <div className="right-wrapper">
          <div className="comparison-header">PROPOSED</div>
          <DescriptionList
            label="Freight Method"
            description={proposal.shipmentType.modality}
          />
          <DescriptionList
            label="Shipment Type"
            description={
              <Typography.Text
                className={`shipment-type-title ${proposal.shipmentType.title.replace(
                  ' ',
                  '-'
                )}`}
              >
                {proposal.shipmentType.title.toUpperCase()}
              </Typography.Text>
            }
          />

          <DescriptionList
            label="Quantity"
            description={proposal.proposalShipmentItemsFormatted}
          />
          {proposalDimensionFields && (
            <>
              <DescriptionList
                label="Total Gross Weight"
                description={`${proposalDimensionFields.weight} kg`}
              />
              <DescriptionList
                label="Total Volume"
                description={`${proposalDimensionFields.volume} cbm`}
              />
              <DescriptionList
                label="Chargeable Weight"
                description={`${proposalDimensionFields.chargeableWeight} kg`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetailsComparison;
