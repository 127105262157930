import { Form, Input, InputNumber, Select } from 'antd';
import {
  currencyOptions,
  units,
} from 'app/components/Proposal/Fees/fees.const';
import React from 'react';
import { IChargeHeadEntity } from 'types/entities/contract.entity';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';

import { formatContainerName, splitState } from 'utils/format-fields';

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: T;
  index: number;
  required: boolean;
  children: React.ReactNode;
}

const getInputType = (dataIndex: string) => {
  switch (dataIndex) {
    case 'cost':
      return <InputNumber />;
    case 'description':
      return <Input />;
    case 'containerType':
      return (
        <Select
          placeholder="Container Type"
          options={Object.values(ContainerTypeEnum).map((value) => {
            return {
              value: value,
              label: formatContainerName(value),
            };
          })}
        />
      );
    case 'currency':
      return <Select placeholder="Currency" options={currencyOptions} />;
    case 'measurementUnit':
      return <Select placeholder="Unit Measurement" options={units} />;
    default:
      return <Input />;
  }
};
export const ChargesEditableCell: React.FC<
  EditableCellProps<IChargeHeadEntity>
> = ({ editing, dataIndex, title, record, index, children, ...restProps }) => {
  const inputNode = getInputType(dataIndex);
  const required = dataIndex !== 'containerType';
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const columns = [
  {
    key: 'title',
    title: 'Description',
    dataIndex: 'description',
    editable: true,
    required: true,
  },
  {
    key: 'containerType',
    dataIndex: 'containerType',
    title: 'Container Type',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      record.containerType ? formatContainerName(record.containerType) : '--',
    width: '15%',
    editable: true,
    required: false,
  },

  {
    key: 'cost',
    dataIndex: 'cost',
    required: true,

    title: 'Cost',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      record.cost,
    width: '10%',
    editable: true,
  },

  {
    key: 'currency',
    title: 'Currency',
    dataIndex: 'currency',
    required: true,

    render: (_: string, record: IChargeHeadEntity, index: number) =>
      record.currency.toUpperCase(),
    width: '5%',
    editable: true,
  },

  {
    key: 'unit',
    title: 'Unit',
    render: (_: string, record: IChargeHeadEntity, index: number) =>
      splitState(record.measurementUnit ?? ''),
    width: '10%',
    editable: true,
    required: true,
    dataIndex: 'measurementUnit',
  },
];
