import { computeDefaultShipmentDetails } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import { CurrencyEnum, UnitMeasurementEnum } from 'types/entities/fee.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IProposalShipmentEntity } from 'types/entities/shipment.entity';

export const computeDefaultFeeItem = (
  proposal: IProposalEntity,
  isForwarder: boolean
) => {
  if (isForwarder && proposal.suggestedProposal) {
    return proposal.suggestedProposal?.proposalItems;
  } else {
    return proposal.proposalItems.length
      ? proposal.proposalItems
      : [
          {
            description: '',
            quantity: undefined,
            rate: undefined,
            currency: CurrencyEnum.USD,
            measurementUnit: UnitMeasurementEnum.FIXD,
            totalCost: 0,
            proposalId: proposal.id,
          },
        ];
  }
};

export const computeDefaultImportantDetails = (
  proposal: IProposalEntity,
  isForwarder: boolean
) => {
  const newProposal =
    isForwarder && proposal.suggestedProposal
      ? proposal.suggestedProposal
      : proposal;

  return {
    transitTime: newProposal.transitTime,
    freeDays: newProposal.freeDays,
    paymentTerms: newProposal.paymentTerms,
    carrierId: newProposal.carrierId,
    vesselName: newProposal.vesselName,
    routing: newProposal.routing,
    eta: newProposal.eta,
    etd: newProposal.etd,
  };
};

export const computeProposalDefaultShipmentDetails = (
  proposal: IProposalEntity,
  shipment: IProposalShipmentEntity,
  isForwarder: boolean
) => {
  const isSuggested = isForwarder && !!proposal.suggestedProposal;
  const newProposal =
    isForwarder && !!proposal.suggestedProposal
      ? proposal.suggestedProposal
      : proposal;

  const defaultShipmentItems = isSuggested
    ? []
    : shipment.shipmentItems.map((s) => ({ ...s, id: undefined }));
  return computeDefaultShipmentDetails({
    ...shipment,
    cargoUnits: newProposal.proposalShipmentItems.length
      ? newProposal.proposalShipmentItems
      : defaultShipmentItems,
    shipmentType: newProposal.shipmentType ?? shipment?.shipmentType,
    shipmentItems: '',
    serviceMode: null,
  });
};
