import { Button, Empty, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { useGetCratedBookingsQuery } from 'api/bookings';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { updateBookingIds } from 'state/slices/draft-booking.slice';
import { useAppDispatch } from 'state/store';
import { IBookingEntity } from 'types/entities/booking.entity';
import { ICreatedBookingsProps } from 'types/feature/purchase-orders.types';

import { ReactComponent as EmptyOrders } from '../../../../assets/orders.svg';
import BookingCard from './BookingCard';
import './created-booking.scss';

const CreatedBookings: FC<ICreatedBookingsProps> = ({
  isNewShipment,
  onChangeOption,
  goToNextStep,
  goToPreviousStep,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const ids = useSelector((state: RootState) => state.booking.ids);
  const { data: bookings, isLoading } = useGetCratedBookingsQuery(
    {
      bookingIds: ids ?? [],
    },
    { skip: !ids || !ids.length }
  );

  const mapBookingIds = useCallback(
    (items: IBookingEntity[]) => {
      dispatch(updateBookingIds({ items }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (bookings?.data.items) {
      mapBookingIds(bookings?.data.items);
    }
  }, [mapBookingIds, bookings]);

  useEffect(() => {
    if (!ids?.length) goToPreviousStep();
  }, [ids, goToPreviousStep]);

  return (
    <div>
      {isLoading ? (
        <Spin />
      ) : (bookings?.data?.items?.length ?? 0) > 0 ? (
        <>
          {bookings?.data?.items.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              onUpdateClick={goToPreviousStep}
            />
          ))}
          <Row justify={'center'} className="action-section">
            <Radio.Group
              options={[
                { label: 'Create new shipment', value: true },
                { label: 'Select an existing one', value: false },
              ]}
              value={isNewShipment}
              onChange={(e: RadioChangeEvent) => onChangeOption()}
            />
          </Row>
        </>
      ) : (
        <Empty
          style={{ marginTop: 50 }}
          image={<EmptyOrders />}
          description="Add items from purchase orders on left tab to book !"
        />
      )}

      <Row justify="end" className="action-footer">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          onClick={goToNextStep}
          disabled={!bookings?.data?.items.length}
        >
          {isNewShipment ? 'Create Shipment' : 'Assign to a shipment'}
        </Button>
      </Row>
    </div>
  );
};

export default CreatedBookings;
