import {
  Button,
  Checkbox,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  notificationsApi,
  useGetAllNotificationsQuery,
  useUpdateNotificationSettingsMutation,
} from 'api/notification';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'state/store';
import { INotificationSetting } from 'types/entities/setting.entity';
import { SettingsTypeEnum } from 'types/feature/settings.types';

import { displayErrors } from 'utils/error-notification';
import { splitState } from 'utils/format-fields';

import { ReactComponent as BellIcon } from '../../../../assets/icons/notification-bell-icon.svg';
import './notification-settings.scss';

const { Text } = Typography;
const NotificationsSettings: FC = () => {
  const dispatch = useAppDispatch();
  const [checkedCounts, setCheckedCounts] = useState({
    email: 0,
    platform: 0,
    total: 0,
  });

  const [isSaved, setIsSaved] = useState<boolean>(true);

  const { data, isLoading, isError, error } = useGetAllNotificationsQuery();
  const [updateSettings, { isLoading: isUpdating, isSuccess }] =
    useUpdateNotificationSettingsMutation();

  useEffect(() => {
    if (data) {
      const checkedEmail = data.filter((item) => item.emailNotification).length;
      const checkedPlatform = data.filter(
        (item) => item.platformNotification
      ).length;
      setCheckedCounts({
        email: checkedEmail,
        platform: checkedPlatform,
        total: data.length,
      });
    }
  }, [data]);
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Notification settings have been updated successfully !',
      });
      setIsSaved(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error)
      displayErrors(error, { title: 'Update Settings Error' });
  }, [isError, error]);

  const onSettingChange = (
    emailNotification: boolean,
    platformNotification: boolean,
    id: string
  ) => {
    if (isSaved) setIsSaved(false);
    dispatch(
      notificationsApi.util.updateQueryData(
        'getAllNotifications',
        undefined,
        (draftSettings) => {
          const index = draftSettings.findIndex((p) => p.id === id);
          draftSettings[index] = {
            ...draftSettings[index],
            emailNotification: emailNotification,
            platformNotification: platformNotification,
          };
        }
      )
    );
  };

  const selectAllOrNone = (all: boolean, column: SettingsTypeEnum) => {
    if (isSaved) setIsSaved(false);
    dispatch(
      notificationsApi.util.updateQueryData(
        'getAllNotifications',
        undefined,
        (draftSettings) => {
          draftSettings.forEach((p, index) => {
            const item =
              column === 'emailNotification'
                ? {
                    ...p,
                    emailNotification: p.isEmailCanBeEnabled
                      ? all
                      : p.emailNotification,
                  }
                : {
                    ...p,
                    platformNotification: p.isPlatformCanBeEnabled
                      ? all
                      : p.platformNotification,
                  };
            draftSettings[index] = item;
          });
        }
      )
    );
  };

  const onSaveChanges = async () => {
    try {
      const request = data?.map(
        ({
          id,
          emailNotification,
          platformNotification,
        }: INotificationSetting) => {
          return { id, emailNotification, platformNotification };
        }
      );
      await updateSettings({ items: request ?? [] });
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          'Something went wrong while updating the notification settings !',
      });
    }
  };
  const isIndeterminate = (checked: number) =>
    checked > 0 && checked < checkedCounts.total;

  const settingsColumns: ColumnsType<INotificationSetting> = [
    {
      title: 'Actions',
      render: (_, record: INotificationSetting) => (
        <Space direction="vertical" size={[1, 1]}>
          <Typography.Text>{splitState(record.emailTemplate)}</Typography.Text>
          <Typography.Text type="secondary">
            {record.description}
          </Typography.Text>
        </Space>
      ),
      align: 'left',
    },
    {
      title: (
        <Space>
          <Checkbox
            indeterminate={isIndeterminate(checkedCounts.email)}
            checked={checkedCounts.email === checkedCounts.total}
            onChange={(event) => {
              selectAllOrNone(event.target.checked, SettingsTypeEnum.Email);
            }}
          />
          Email Notifications
        </Space>
      ),
      dataIndex: 'emailNotification',
      render: (_, record: INotificationSetting) =>
        record.isEmailCanBeEnabled ? (
          <Checkbox
            checked={record.emailNotification ?? false}
            onChange={(e) =>
              onSettingChange(
                e.target.checked,
                record.platformNotification,
                record.id
              )
            }
          />
        ) : (
          '-'
        ),
      align: 'center',
      width: 200,
    },
    {
      title: (
        <Space>
          <Checkbox
            indeterminate={isIndeterminate(checkedCounts.platform)}
            checked={checkedCounts.platform === checkedCounts.total}
            onChange={(event) => {
              selectAllOrNone(event.target.checked, SettingsTypeEnum.Platform);
            }}
          />
          Platform Notifications
        </Space>
      ),
      dataIndex: 'platformNotification',
      render: (_, record: INotificationSetting) =>
        record.isPlatformCanBeEnabled ? (
          <Checkbox
            checked={record.platformNotification ?? false}
            onChange={(e) =>
              onSettingChange(
                record.emailNotification,
                e.target.checked,
                record.id
              )
            }
          />
        ) : (
          '-'
        ),
      align: 'center',
      width: 200,
    },
  ];

  return (
    <>
      <Space direction="vertical">
        <div className="settings-header">
          <Text strong>
            <Space size="middle">
              <BellIcon />
              Notification Preferences
            </Space>
          </Text>

          <Text type="secondary">
            These preferences only apply to email and In-app notifications for
            shipments, Bookings, Invoices and Purchase Orders you&apos;re
            watching. Select which notifications you&apos;d like to receive via
            email.
          </Text>
          <Space size="large" direction="vertical">
            <Text type="secondary">
              Note: You&apos;ll always get emails for invites, direct mentions,
              when you&apos;re added to a tasks, quotes, and more.
            </Text>
            <Row justify="end" align="middle">
              <Button
                type="primary"
                icon={isUpdating && <Spin spinning size="small" />}
                onClick={() => onSaveChanges()}
                disabled={isSaved || isUpdating}
              >
                Save Changes
              </Button>
            </Row>
          </Space>
        </div>
        <Table
          columns={settingsColumns}
          dataSource={data ?? []}
          showSorterTooltip
          pagination={{ hideOnSinglePage: true }}
          size="small"
          loading={isLoading}
          rowKey="id"
          className="settings-table"
        />
      </Space>
    </>
  );
};

export default NotificationsSettings;
