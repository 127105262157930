import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';

import { dateFormat2Const } from 'utils/shipment-type';

export const bookingColumns: ColumnsType<{
  id: string;
  quantity: number;
  purchaseOrderItem: Partial<IPurchaseOrderItemEntity>;
}> = [
  {
    title: 'SN #',
    dataIndex: 'reference',
    render: (value, record, index) => record.purchaseOrderItem.reference,
  },
  {
    title: 'Product Items',
    dataIndex: 'description',
    render: (value, record, index) => record.purchaseOrderItem.description,
  },
  {
    title: 'PO ID',
    dataIndex: 'orderId',
    render: (value, record, index) => record.purchaseOrderItem.order,
  },

  {
    title: 'CRD',
    dataIndex: 'cargoReadyDate',
    render: (value, record, index) =>
      dayjs(record.purchaseOrderItem.cargoReadyDate).format(dateFormat2Const),
  },
  {
    title: 'Booked',
    dataIndex: 'quantity',
  },
];
