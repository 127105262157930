import dayjs, { Dayjs } from 'dayjs';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IncotermsEnum } from 'types/entities/product.entity';
import {
  IPurchaseOrderEntity,
  IPurchaseOrderItemEntity,
  MeasurementUnitEnum,
  PurchaseOrderStatusEnum,
} from 'types/entities/purchase-order.entity';
import { ILocation, ModalityEnum } from 'types/entities/shipment.entity';
import {
  IOrderDetailsForm,
  IOrderLinesForm,
  TComputeReviewTagCountDef,
} from 'types/feature/purchase-orders.types';

import { roundToPrecision } from 'utils/common';

const defaultLocation: ILocation = {
  country: '',
  code: '',
  city: '',
  latitude: 0,
  longitude: 0,
  serviceMode: null,
};

export const mapOrderDetails = (
  orderDetails: IOrderDetailsForm,
  isDraft: boolean,
  userId?: string
) => {
  const { originFull, destinationFull, ...restInfos } = orderDetails;

  if (isDraft)
    return {
      ...restInfos,
      status: restInfos.status ?? PurchaseOrderStatusEnum.CONFIRMED,
      incoterms: restInfos.incoterms ?? IncotermsEnum.CFR,
      modality: restInfos.modality ?? ModalityEnum.SEA,
      cargoReadyDate: restInfos.cargoReadyDate ?? dayjs(),
      orderIssueDate: restInfos.orderIssueDate ?? dayjs(),
      origin: originFull ?? defaultLocation,
      destination: destinationFull ?? defaultLocation,
      supplier: restInfos.supplier ?? userId,
    };
  else {
    return {
      ...restInfos,
      origin: originFull,
      destination: destinationFull,
    };
  }
};

export const mapOrderItems = (
  orderItems: IOrderLinesForm | null,
  date: dayjs.Dayjs,
  isDraft: boolean
) => {
  return (
    orderItems?.items?.map(
      ({
        id,
        unitOfMeasurement,
        unitPrice = isDraft ? 1 : undefined,
        volumePerUnit,
        weightPerUnit,
        currencyCode = isDraft ? CurrencyEnum.USD : undefined,
        bookedQuantity,
        requestedQuantity = isDraft ? 1 : 0,
        description,
        taxRate,
        reference,
        boxes,
        totalWeight,
        totalVolume,
        hsCode,
        coo,
      }) =>
        ({
          id,
          description,
          hsCode,
          coo,
          currencyCode,
          unitOfMeasurement: unitOfMeasurement ?? MeasurementUnitEnum.QUANTITY,
          unitPrice,
          volumePerUnit,
          weightPerUnit,
          cargoReadyDate: date,
          bookedQuantity: bookedQuantity ?? 0,
          requestedQuantity,
          taxRate,
          boxes,
          totalVolume,
          totalWeight,
          ...(requestedQuantity && {
            totalAmount: unitPrice
              ? roundToPrecision(unitPrice * requestedQuantity)
              : null,
          }),
          reference,
        } as IPurchaseOrderItemEntity)
    ) ?? []
  );
};

export const getDefaultCRD = (
  defaultPurchaseOrder: IPurchaseOrderEntity | null
) => {
  return defaultPurchaseOrder?.purchaseOrderItems &&
    defaultPurchaseOrder?.purchaseOrderItems?.length > 0
    ? dayjs(defaultPurchaseOrder?.purchaseOrderItems[0].cargoReadyDate)
    : dayjs();
};

export const ORDER_ITEM_INITIAL_STATE = {
  id: undefined,
  description: undefined,
  unitPrice: null,
  taxRate: 0,
  shipments: [],
  requestedQuantity: null,
  bookedQuantity: null,
  weightPerUnit: null,
  volumePerUnit: null,
  boxes: null,
  toBook: undefined,
  cargoReadyDate: dayjs(),
  unitOfMeasurement: MeasurementUnitEnum.QUANTITY,
  totalWeight: undefined,
  totalVolume: undefined,
  totalAmount: undefined,
  currencyCode: null,
  taxAmount: undefined,
  reference: null,
  order: undefined,
  bookingItemId: null,
  hsCode: null,
  coo: null,
};

export const computeOrderLineCargoReadyDate = (
  cargoReadyDate: string | Dayjs
) => {
  return typeof cargoReadyDate === 'string'
    ? cargoReadyDate // If it's already a string, leave it as is
    : cargoReadyDate.format('YYYY-MM-DDTHH:mm:ss.00[Z]').toString(); // If it's a Dayjs object, format it
};

export const computeReviewTagCount: TComputeReviewTagCountDef = ({
  createOrderItemState,
  defaultOrderItem,
}) => {
  if (createOrderItemState) {
    return createOrderItemState?.length;
  }

  if (defaultOrderItem) {
    return defaultOrderItem?.length;
  }

  return 0;
};
