import { Button, Form, Modal, message, notification } from 'antd';
import { useCreateBookingCollaboratorMutation } from 'api/Booking-collaborators.ts';
import { useCreatePurchaseOrderCollaboratorMutation } from 'api/purchase-order-collaborators';
import { useInviteUserMutation } from 'api/user';
import React, { useEffect, useState } from 'react';
import { UserConnectionTypes } from 'types/entities/user.entity';
import {
  IAddConnectionModalProps,
  IConnectionForm,
} from 'types/feature/connections.type';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import './add-supplier.scss';
import ConnectionForm from './connection-form';

const AddConnectionModal: React.FC<IAddConnectionModalProps> = ({
  btnProps,
  isRoleHidden = false,
  onBtnClicked,
  purchaseOrderId,
  showCollaboratorModal,
  hideCollaboratorModal,
  currentUserAccessLevel,
  bookingId,
}) => {
  const { confirm } = Modal;
  const isCollaboratorModal = purchaseOrderId || bookingId;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [inviteSupplier, { isLoading, isSuccess, isError, error }] =
    useInviteUserMutation();
  const [
    createPurchaseOrderCollaborator,
    {
      isSuccess: isInvitePOSuccess,
      isLoading: isInvitePOLoading,
      isError: isInvitePOError,
      error: invitePOError,
    },
  ] = useCreatePurchaseOrderCollaboratorMutation();

  const [
    createBookingCollaborator,
    {
      isSuccess: isInviteBookingSuccess,
      isLoading: isInviteBookingLoading,
      isError: isInviteBookingError,
      error: inviteBookingError,
    },
  ] = useCreateBookingCollaboratorMutation();

  const [form] = Form.useForm<IConnectionForm>();

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isInviteBookingSuccess || isInvitePOSuccess) {
      notification.success({ message: 'collaborator added' });
    }
  }, [isInviteBookingSuccess, isInvitePOSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Add supplier error' });
    }
  }, [isError, error]);

  useEffect(() => {
    if (isInvitePOError && invitePOError) {
      displayErrors(invitePOError, { title: 'Add collaborator error' });
    }
  }, [isInvitePOError, invitePOError]);

  useEffect(() => {
    if (isInviteBookingError && inviteBookingError) {
      displayErrors(inviteBookingError, { title: 'Add collaborator error' });
    }
  }, [isInviteBookingError, inviteBookingError]);

  // methods

  const onFinish = async () => {
    if (!bookingId) {
      try {
        const values = await form.validateFields();
        // TODO create an enum for invite type
        await inviteSupplier({
          ...values,
          userInviteFor: 'purchaseOrder',
        });
      } catch {
        message.error('Make sure to fill all required fields !');
      }
    } else {
      confirm({
        title: 'Are you sure ?',
        okText: 'invite',
        icon: null,
        content:
          'Are you sure you want to invite this user to the booking? please note that inviting a collaborator will also invite them to the underlying purchase orders.',
        onOk: async () => {
          try {
            const values = await form.validateFields();
            // TODO create an enum for invite type
            const response = await inviteSupplier({
              ...values,
              userInviteFor: 'purchaseOrder',
            }).unwrap();
            if (purchaseOrderId && values.accessLevel) {
              await createPurchaseOrderCollaborator({
                purchaseOrderId,
                collaborators: [
                  {
                    collaboratorId: response.data.id,
                    accessLevel: values.accessLevel,
                  },
                ],
              });
            } else if (bookingId && values.accessLevel) {
              await createBookingCollaborator({
                bookingId,
                collaborators: [
                  {
                    collaboratorId: response.data.id,
                    accessLevel: values.accessLevel || AccessLevelEnum.EDIT,
                  },
                ],
              });
            }

            form.resetFields();
          } catch {
            message.error('Make sure to fill all required fields !');
          }
          if (showCollaboratorModal && currentUserAccessLevel) {
            if (purchaseOrderId) {
              showCollaboratorModal(purchaseOrderId, currentUserAccessLevel);
            } else if (bookingId) {
              showCollaboratorModal(bookingId, currentUserAccessLevel);
            }
          }
        },
      });
    }
  };

  const showModal = () => {
    if (hideCollaboratorModal && purchaseOrderId) {
      hideCollaboratorModal(purchaseOrderId);
    } else if (hideCollaboratorModal && bookingId) {
      hideCollaboratorModal(bookingId);
    }
    setIsModalOpen(true);
    if (onBtnClicked) onBtnClicked();
  };

  const handleCancel = () => {
    if (showCollaboratorModal && currentUserAccessLevel) {
      if (purchaseOrderId) {
        showCollaboratorModal(purchaseOrderId, currentUserAccessLevel);
      } else if (bookingId) {
        showCollaboratorModal(bookingId, currentUserAccessLevel);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <div className="add-supplier-wrapper">
      <div
        className={
          isCollaboratorModal ? 'collaborator-btn-wrapper' : 'btn-wrapper'
        }
      >
        <Button type="text" onClick={showModal} {...btnProps}>
          {btnProps?.text ?? 'Add new supplier'}
        </Button>
      </div>

      <Modal
        title={btnProps?.text ?? 'Add Supplier'}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          isCollaboratorModal
            ? [
                <Button key="submit" type="default" onClick={handleCancel}>
                  Back
                </Button>,
                <Button
                  loading={
                    isLoading || isInvitePOLoading || isInviteBookingLoading
                  }
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={onFinish}
                >
                  Add Collaborator
                </Button>,
              ]
            : [
                <Button
                  loading={isLoading}
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={onFinish}
                >
                  Save
                </Button>,
              ]
        }
      >
        <ConnectionForm
          currentUserAccessLevel={currentUserAccessLevel}
          isPoCollaborator={isCollaboratorModal ? true : false}
          initialValues={{
            userConnectionType: UserConnectionTypes.SUPPLIER,
            userInviteFor: '',
          }}
          form={form}
          isRoleHidden={isRoleHidden}
        />
      </Modal>
    </div>
  );
};

export default AddConnectionModal;
