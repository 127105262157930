import { Drawer, Button, Typography, Badge, Space, Divider } from 'antd';
import { useGetAllCarriersQuery } from 'api/carrier';
import { useGetAllShipmentsLocationQuery } from 'api/shipment';
import { useGetTeamMembersQuery, useGetUserConnectionsQuery } from 'api/user';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import {
  IFilterDrawerProps,
  IShipmentsFiltersState,
} from 'types/feature/shipments.types';

import { applyShipmentFiltersCtaClickEvent } from 'utils/analytics-events';
import { computeAllFiltersCount } from 'utils/shipment-helper';

import CarrierFilter from '../CarrierFilter';
import CollaboratorFilter from '../CollaboratorFilter';
import ContactFilter from '../ContactListFilter';
import DateFilter from '../DateFilter';
import FilterDropdown from '../FilterDropdown';
import LocationFilter from '../LocationFilter';
import ModalityFilter from '../ModalityFilter';
import ShipmentTypeFilter from '../ShipmentTypeFilter';
import StatusFilter from '../StatusFilter';

const FilterDrawer: FC<IFilterDrawerProps> = ({
  defaultValue,
  onChange,
  onReset,
  fromPendingQuotes,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [open, setOpen] = useState(false);
  const [allContacts, setAllContacts] = useState<IUserPartnerEntity[]>([]);

  const [shipmentFilters, setShipmentFilters] =
    useState<IShipmentsFiltersState>({});

  const { data: allShipmentsLocation } = useGetAllShipmentsLocationQuery();

  const { data: teamData, isLoading: isTeamLoading } = useGetTeamMembersQuery(); // get team members

  const { data: connectionsData, isLoading: isConnectionsLoading } =
    useGetUserConnectionsQuery(null); // get connections members

  const { data: carrierData, isLoading: isCarrierLoading } =
    useGetAllCarriersQuery({});

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setShipmentFilters({});
    onReset();
  };

  const onApplyFiltersCTAClick = (filters: string[]) => {
    if (currentUser) {
      applyShipmentFiltersCtaClickEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
        selected_filters: filters,
      });
    }
  };

  const onApplyFilter = () => {
    onApplyFiltersCTAClick(Object.keys(shipmentFilters) as string[]);
    onChange(shipmentFilters);
    setOpen(false);
  };

  const renderFilterCount = () => {
    const filterCount = computeAllFiltersCount(shipmentFilters);
    return filterCount > 0 && <Badge count={filterCount} color="#6F6F6F" />;
  };

  useEffect(() => {
    setShipmentFilters(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (connectionsData && teamData && currentUser) {
      const newConnections = connectionsData?.map(({ partner }) => partner);
      const combinedContacts = [currentUser, ...teamData, ...newConnections];
      setAllContacts(combinedContacts);
    }
  }, [connectionsData, teamData, currentUser]);

  return (
    <>
      <Button onClick={showDrawer}>
        <Space>All Filters {renderFilterCount()}</Space>
      </Button>
      <Drawer
        title="Filters"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
        footer={
          <div className="filter-footer-actions">
            <Button onClick={onClose} className="mx-1">
              Cancel
            </Button>
            <Button type="primary" onClick={onApplyFilter}>
              <Space>Apply Filters {renderFilterCount()}</Space>
            </Button>
          </div>
        }
      >
        <div>
          <Typography.Title level={5}>Shipment Status</Typography.Title>
          <StatusFilter
            layout="horizontal"
            defaultValue={defaultValue.statuses}
            onChange={(statuses) =>
              setShipmentFilters({
                ...shipmentFilters,
                statuses,
              })
            }
            fromPendingQuotes={fromPendingQuotes}
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>ETD</Typography.Title>

          <DateFilter
            layout="horizontal"
            defaultValue={shipmentFilters?.etds}
            onChange={(dateFilter) => {
              setShipmentFilters({
                ...shipmentFilters,
                etds: dateFilter,
              });
            }}
          />
        </div>
        <div>
          <Typography.Title level={5}>ETA</Typography.Title>
          <DateFilter
            layout="horizontal"
            defaultValue={shipmentFilters?.etas}
            onChange={(dateFilter) =>
              setShipmentFilters({
                ...shipmentFilters,
                etas: dateFilter,
              })
            }
          />
        </div>

        <Divider />
        <div>
          <Typography.Title level={5}>Shipment Type</Typography.Title>
          <ShipmentTypeFilter
            layout="horizontal"
            defaultValue={shipmentFilters?.shipmentType}
            onChange={(shipmentType) =>
              setShipmentFilters({
                ...shipmentFilters,
                shipmentType,
              })
            }
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Modality</Typography.Title>
          <ModalityFilter
            layout="horizontal"
            defaultValue={shipmentFilters?.modalities}
            onChange={(modalities) =>
              setShipmentFilters({
                ...shipmentFilters,
                modalities,
              })
            }
          />
        </div>
        <Divider />
        {allShipmentsLocation && (
          <div>
            <Typography.Title level={5}>Origins</Typography.Title>
            <FilterDropdown
              fromFilter
              filters={shipmentFilters}
              renderFilterComponent={
                <LocationFilter
                  layout="horizontal"
                  defaultValue={shipmentFilters?.origins}
                  locations={allShipmentsLocation?.origins}
                  onChange={(origins) =>
                    setShipmentFilters({
                      ...shipmentFilters,
                      origins,
                    })
                  }
                />
              }
              buttonText="Origins"
              itemKey="origins"
            />
          </div>
        )}
        {allShipmentsLocation && (
          <div>
            <Typography.Title level={5}>Destinations</Typography.Title>

            <FilterDropdown
              fromFilter
              filters={shipmentFilters}
              renderFilterComponent={
                <LocationFilter
                  layout="horizontal"
                  defaultValue={shipmentFilters?.destinations}
                  locations={allShipmentsLocation?.destinations}
                  onChange={(destinations) =>
                    setShipmentFilters({
                      ...shipmentFilters,
                      destinations,
                    })
                  }
                />
              }
              buttonText="Destinations"
              itemKey="destinations"
            />
          </div>
        )}
        <Divider />
        <div>
          <Typography.Title level={5}>Collaborator</Typography.Title>

          <FilterDropdown
            isLoading={isConnectionsLoading || isTeamLoading}
            fromFilter
            filters={shipmentFilters}
            renderFilterComponent={
              <CollaboratorFilter
                layout="horizontal"
                defaultValue={shipmentFilters?.collaborators}
                collaborators={allContacts}
                onChange={(collaborators) =>
                  setShipmentFilters({
                    ...shipmentFilters,
                    collaborators,
                  })
                }
              />
            }
            buttonText="Collaborators"
            itemKey="collaborators"
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Contact Person</Typography.Title>

          <FilterDropdown
            isLoading={isConnectionsLoading || isTeamLoading}
            fromFilter
            filters={shipmentFilters}
            renderFilterComponent={
              <ContactFilter
                layout="horizontal"
                defaultValue={shipmentFilters?.creators}
                contacts={allContacts}
                onChange={(creators) =>
                  setShipmentFilters({
                    ...shipmentFilters,
                    creators,
                  })
                }
              />
            }
            buttonText="Contact Person"
            itemKey="creators"
          />
        </div>
        <Divider />
        <div>
          <Typography.Title level={5}>Initiated by</Typography.Title>

          <FilterDropdown
            isLoading={isConnectionsLoading || isTeamLoading}
            fromFilter
            filters={shipmentFilters}
            renderFilterComponent={
              <ContactFilter
                layout="horizontal"
                defaultValue={shipmentFilters?.initiatedBy}
                contacts={allContacts}
                onChange={(contacts) =>
                  setShipmentFilters({
                    ...shipmentFilters,
                    initiatedBy: contacts,
                  })
                }
              />
            }
            buttonText="Initiated by "
            itemKey="initiatedBy"
          />
        </div>
        <Divider />
        {carrierData && (
          <div>
            <Typography.Title level={5}>Carrier</Typography.Title>

            <FilterDropdown
              isLoading={isCarrierLoading}
              fromFilter
              filters={shipmentFilters}
              renderFilterComponent={
                <CarrierFilter
                  layout="horizontal"
                  defaultValue={shipmentFilters?.carriers}
                  carriers={carrierData}
                  onChange={(carriers) =>
                    setShipmentFilters({
                      ...shipmentFilters,
                      carriers,
                    })
                  }
                />
              }
              buttonText="Carrier"
              itemKey="carriers"
            />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default FilterDrawer;
