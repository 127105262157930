import { IBookmarkEntity } from 'types/entities/bookmark.entity';

import { api } from './base-api';

export const bookmarkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookmarkByShipment: build.query<IBookmarkEntity, string>({
      query: (shipmentId) => `/shipment-bookmark/${shipmentId}`,
    }),

    createBookmark: build.mutation<IBookmarkEntity, string>({
      query: (shipmentId) => ({
        url: `/shipment-bookmark/${shipmentId}`,
        method: 'POST',
      }),
    }),

    deleteBookmark: build.mutation<IBookmarkEntity, string>({
      query: (shipmentId) => ({
        url: `/shipment-bookmark/${shipmentId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetBookmarkByShipmentQuery,
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
} = bookmarkApi;
