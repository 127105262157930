import dayjs from 'dayjs';
import {
  CargoTypeEnum,
  IShipmentTypeDef,
  LengthMeasurementUnitEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import {
  ICargoUnit,
  IShipmentEntity,
  ModalityEnum,
} from 'types/entities/shipment.entity';
import {
  IBiddingDetailsForm,
  ICargoDetailsForm,
  IShipmentDetailsForm,
  IShipmentTypeState,
} from 'types/feature/create-shipment.types';
import { ShipmentStatusEnum } from 'types/feature/shipments.types';

import { roundToPrecision } from 'utils/common';
import { formatServiceModeString, splitState } from 'utils/format-fields';
import {
  hasShipmentStarted,
  shipmentStartedStatusConst,
} from 'utils/shipment-helper';
import { isCourier, isLTL } from 'utils/shipment-type';

const COURIER = 5000;
const CARGO = 6000;
const ROAD = 4000;

export const MAX_AIR_HEIGHT = 155;

export const DIMENSION_TOTAL_INIT_STATE = {
  volume: '0',
  weight: '0',
  charges: '0',
};

export const CARGO_UNITS_INIT_STATE = [
  {
    containerType: undefined,
    quantity: undefined,
    height: undefined,
    width: undefined,
    length: undefined,
    packageType: undefined,
    volume: undefined,
    weight: undefined,
    id: undefined,
  },
];

export const convertToCM = (
  value: number,
  unit?: LengthMeasurementUnitEnum
) => {
  switch (unit) {
    case LengthMeasurementUnitEnum.CM:
      return value;

    case LengthMeasurementUnitEnum.IN:
      return value * 2.54;

    default:
      return value;
  }
};

export const computeChargebaleWeight = (
  weight: number,
  volume: number,
  type?: ShipmentTypeEnum
) => {
  //chargebale weight = (L*H*W*qt/6000)
  const divideBy = isCourier(type) ? COURIER : isLTL(type) ? ROAD : CARGO;
  const chargebale = volume / divideBy ?? 0;
  const formattedChargebale = Math.max(weight, chargebale).toLocaleString(
    'en-US'
  );
  return formattedChargebale;
};

// volume = (L*H*W)* qt
const computeTotalVolume = (items: ICargoUnit[]) => {
  const totalVolume =
    items?.reduce(
      (prev, curr) => {
        const currVolume =
          convertToCM(curr.height ?? 0, curr.lengthMeasurement) *
          convertToCM(curr.width ?? 0, curr.lengthMeasurement) *
          convertToCM(curr.length ?? 0, curr.lengthMeasurement) *
          (curr.quantity ?? 0);

        prev.volume = (prev.volume ?? 0) + currVolume;
        return prev;
      },
      { volume: 0 }
    ).volume ?? 0;

  return totalVolume;
};
export const formatTotalVolume = (items: ICargoUnit[]) => {
  const totalVolume = computeTotalVolume(items);
  const formattedTotal = totalVolume.toFixed(4);

  return formattedTotal;
};

export const computeTotalWeight = (
  items: ICargoUnit[],
  type?: ShipmentTypeEnum
) => {
  //chargebale weight = (L*H*W*qt/6000)
  const divideBy = isCourier(type) ? COURIER : isLTL(type) ? ROAD : CARGO;
  const totatVolume = computeTotalVolume(items);
  let chargebale = totatVolume / divideBy ?? 0;

  let total =
    items.reduce(
      (prev, curr) => {
        prev.weight =
          (prev.weight ?? 0) + (curr.weight ?? 0) * (curr.quantity ?? 0);
        return prev;
      },
      { weight: 0 }
    ).weight ?? 0;

  total = roundToPrecision(total);
  chargebale = roundToPrecision(chargebale);
  const formattedTotal = roundToPrecision(total).toString();
  const formattedChargebale = Math.max(total, chargebale).toString();

  return [formattedTotal, formattedChargebale];
};

export const convertToCBM = (value: string, decimals = 4) =>
  (parseFloat(value) / 1000000).toFixed(decimals);

function formatLocation(city, country) {
  if (city && country) {
    return `${city}, ${country}`;
  } else if (city) {
    return city;
  } else if (country) {
    return country;
  } else {
    return undefined;
  }
}

export const computeDefaultCargoDetails = (
  defaultShipment: IShipmentEntity | null,
  setFullLocation: boolean
): Partial<ICargoDetailsForm> | null =>
  defaultShipment
    ? {
        productId: defaultShipment.product?.id,
        origin: formatLocation(
          defaultShipment.origin.city,
          defaultShipment.origin.country
        ),
        destination: formatLocation(
          defaultShipment.destination.city,
          defaultShipment.destination.country
        ),
        originFull: setFullLocation ? defaultShipment.origin : undefined,
        destinationFull: setFullLocation
          ? defaultShipment.destination
          : undefined,
        incoterms: defaultShipment.incoterms,
        cargoReadyDate: dayjs(defaultShipment.cargoReadyDate),
        cargoType: defaultShipment.cargoType,
        nonStackable: defaultShipment.nonStackable,
        autoUpdateEta: defaultShipment.autoUpdateEta,
        autoUpdateEtd: defaultShipment.autoUpdateEtd,
        ...(defaultShipment.eta && { eta: dayjs(defaultShipment.eta) }),
        ...(defaultShipment.etd && { etd: dayjs(defaultShipment.etd) }),
        serviceMode:
          defaultShipment.origin.serviceMode &&
          defaultShipment.destination.serviceMode
            ? formatServiceModeString(
                defaultShipment.origin.serviceMode,
                defaultShipment.destination.serviceMode
              )
            : undefined,
      }
    : {
        cargoType: CargoTypeEnum.GENERAL_CARGO,
      };

export const computeDefaultShipmentDetails = (
  defaultShipment: IShipmentEntity | null
): IShipmentDetailsForm => {
  if (defaultShipment) {
    const containsAcceptedShipment =
      Object.keys(defaultShipment).includes('acceptedCargoUnits');
    let shipmentType = defaultShipment?.shipmentType;
    let cargoUnits = defaultShipment?.cargoUnits;

    if (
      containsAcceptedShipment &&
      defaultShipment?.acceptedShipmentType &&
      defaultShipment?.acceptedCargoUnits.length > 0
    ) {
      shipmentType = defaultShipment?.acceptedShipmentType;
      cargoUnits = defaultShipment?.acceptedCargoUnits;
    }

    return {
      shipmentId: defaultShipment.id,
      modality: shipmentType.modality,
      cargoUnits: cargoUnits.length ? cargoUnits : CARGO_UNITS_INIT_STATE,
      shipmentTypeId: shipmentType.id,
    };
  } else {
    return {
      cargoUnits: CARGO_UNITS_INIT_STATE,
      modality: ModalityEnum.SEA,
    };
  }
};

export const computeDefaultBiddingDate = (
  defaultBiddingDate: string | null | undefined,
  isEditView: boolean
) => {
  const tomorrowDate = dayjs().add(1, 'day');
  const biddingDate = defaultBiddingDate
    ? dayjs(defaultBiddingDate)
    : isEditView
    ? null
    : tomorrowDate;

  return {
    dueDate: biddingDate,
    dueTime: biddingDate,
  };
};

export const computeBiddingFormDate = (values: IBiddingDetailsForm) => {
  if (!values.dueDate && !values.dueTime) return null;

  const selectedTime = dayjs(values.dueTime).get('hour');
  const selectedDate = dayjs(values.dueDate)
    .startOf('day')
    .set('hour', selectedTime);

  return selectedDate.toISOString();
};

export const shipmentStatusOptions = (currentStatus: ShipmentStatusEnum) => {
  const allShipmentStatuses = Object.values(ShipmentStatusEnum);
  const indexOfStatus = allShipmentStatuses.indexOf(currentStatus);
  const statusOptions: Record<string, string>[] = [];
  const shipmentStarted = hasShipmentStarted(currentStatus);

  const allowedStatuses = shipmentStarted
    ? shipmentStartedStatusConst
    : allShipmentStatuses;

  allowedStatuses
    .filter((status, index) => shipmentStarted || index >= indexOfStatus)
    .forEach((status) => {
      statusOptions.push({
        value: status,
        label: splitState(status),
        key: status,
      });
    });

  return statusOptions;
};

export const isFullLoad = (shipmentType: IShipmentTypeDef) =>
  shipmentType.title === ShipmentTypeEnum.FCL ||
  shipmentType.title === ShipmentTypeEnum.FTL;

type TComputeDimensionValueDef = (
  shipmentType: IShipmentTypeDef,
  cargoUnits: ICargoUnit[]
) => { weight: string; chargeableWeight: string; volume: string } | null;

export const computeDimensionValue: TComputeDimensionValueDef = (
  shipmentType: IShipmentTypeDef,
  cargoUnits: ICargoUnit[]
) => {
  if (!isFullLoad(shipmentType) && cargoUnits?.length) {
    const isByUnit = !!cargoUnits[0]?.height;
    if (isByUnit) {
      const shipmentTotalWeight = computeTotalWeight(
        cargoUnits,
        shipmentType.title
      );

      const shipmentTotalVolume = convertToCBM(formatTotalVolume(cargoUnits));

      return {
        weight: shipmentTotalWeight[0],
        chargeableWeight: shipmentTotalWeight[1],
        volume: shipmentTotalVolume,
      };
    } else {
      return {
        weight: (cargoUnits[0]?.weight ?? 0).toString(),
        chargeableWeight: computeChargebaleWeight(
          cargoUnits[0].weight ?? 0,
          cargoUnits[0].volume ?? 0,
          shipmentType.title
        ),
        volume: convertToCBM((cargoUnits[0].volume ?? 0).toString()),
      };
    }
  } else {
    return null;
  }
};

export const formatShipmentAmount = (
  shipmentType: IShipmentTypeDef,
  cargoUnits: ICargoUnit[],
  formattedShipmentItems: string
) => {
  if (isFullLoad(shipmentType)) return formattedShipmentItems;
  else {
    const dimensions = computeDimensionValue(shipmentType, cargoUnits);

    return `${dimensions?.chargeableWeight || 0} KG`;
  }
};

export const mappedCargoUnits = (
  weight?: number,
  volume?: number,
  cargoUnitId?: string,
  cargoUnits?: ICargoUnit[]
): ICargoUnit[] | undefined => {
  const mappedCargoUnits = cargoUnits?.length
    ? cargoUnits?.map((cargoUnit) => {
        return {
          containerType: cargoUnit.containerType,
          packageType: cargoUnit.packageType,
          quantity: cargoUnit.quantity,
          length: cargoUnit.length,
          width: cargoUnit.width,
          height: cargoUnit.height,
          weight: cargoUnit.weight,
          volume: cargoUnit.volume,
          id: cargoUnit.id,
        };
      })
    : weight || volume
    ? [
        {
          id: cargoUnitId,
          weight,
          volume,
        },
      ]
    : undefined;

  return mappedCargoUnits;
};

export const computeShipmentTypeOptions = (
  currentModality: string,
  allShipmentTypes
): IShipmentTypeState[] => {
  const activeModality = Object.keys(allShipmentTypes).find(
    (m) => m === currentModality
  );
  return activeModality ? allShipmentTypes[activeModality] : [];
};

export const extractActiveShipmentType = (
  shipmentTypeOptions: IShipmentTypeState[],
  shipmentTypeId: string
) => shipmentTypeOptions.find((type) => type.id === shipmentTypeId);

export const isByUnit = (cargoUnits: ICargoUnit[] | undefined) =>
  cargoUnits
    ? !!(cargoUnits[0]?.height || cargoUnits[0]?.width || cargoUnits[0]?.length)
    : false;

export const isDefaultShipmentTypeExist = (
  defaultShipmentType: IShipmentTypeDef,
  shipmentTypeOptions: IShipmentTypeState[]
) =>
  shipmentTypeOptions.some((option) => option.id === defaultShipmentType?.id);
