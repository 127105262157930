import { Row, Col, Table, Button, Space, Modal, Badge } from 'antd';
import {
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceByIdMutation,
} from 'api/invoice';
import PDFViewer from 'app/design-system/PDFViewer';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { ICommentEntity } from 'types/entities/comment.entity';
import { IInvoiceItemEntity } from 'types/entities/invoice.entity';
import {
  ColumnTypes,
  IInvoiceDetails,
  IShareInvoiceProps,
  InvoiceStatusEnum,
  InvoiceTableViewEnum,
} from 'types/feature/invoice.types';

import { updateInvoiceStatusEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { isAdminCO } from 'utils/shipment-helper';

import { ReactComponent as AddCommentIcon } from '../../../../assets/icons/comment-add-icon-outlined.svg';
import DeclineInvoiceModal from '../DeclineInvoiceModal';
import InvoiceDetails from '../InvoiceDetails';
import InvoiceHeader from '../InvoiceHeader';
import InvoiceItemComments from '../InvoiceItemComments';
import { searchHiglightedFileUrl } from '../InvoiceMatching';
import { computeGrandTotal } from '../InvoiceMatchingTable/InvoiceMatchingTable.utils';
import {
  renderInvoiceMatchingTableCol,
  renderTotalAmount,
} from '../InvoiceMatchingTable/invoice-matching-table.const';
import './invoice-matching.scss';
import { canAssignInvoice } from './share-invoice-util';
import {
  computeSuccessMessage,
  renderActionButtons,
} from './share-invoice.const';

const ShareInvoice: FC<IShareInvoiceProps> = ({
  invoice,
  onStepChange,
  shipment,
}) => {
  const { slug = '' } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [dataSource, setDataSource] = useState<IInvoiceItemEntity[]>([]);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [selectedIitem, setSelectedItem] = useState<string | null>(null);

  const [isSuccessOpen, setisSuccessOpen] = useState<boolean>(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState<boolean>(false);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<IInvoiceDetails>(
    {
      mbl: invoice.mbl,
      dueDate: invoice.dueDate,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      invoiceNo: invoice.invoiceNo,
      supplierVatNo: invoice.supplierVatNo,
      buyerVatNo: invoice.buyerVatNo,
    }
  );

  const [updateInvoice, { isLoading, isSuccess }] =
    useUpdateInvoiceByIdMutation();

  const [refetchGetInvoiceById, { isLoading: refetchGetInvoiceByIdLoading }] =
    useLazyGetInvoiceByIdQuery();

  useEffect(() => {
    setDataSource(invoice.invoiceItems);
  }, [invoice.invoiceItems]);

  useEffect(() => {
    setInvoiceDetailsData({
      mbl: invoice.mbl,
      dueDate: invoice.dueDate,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      invoiceNo: invoice.invoiceNo,
      supplierVatNo: invoice.supplierVatNo,
      buyerVatNo: invoice.buyerVatNo,
    });
  }, [
    invoice.mbl,
    invoice.dueDate,
    invoice.issueDate,
    invoice.currency,
    invoice.invoiceNo,
    invoice.supplierVatNo,
    invoice.buyerVatNo,
  ]);

  useEffect(() => {
    if (isSuccess) {
      setisSuccessOpen(true);
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setisSuccessOpen(false);
  };

  const onStatusActionClick = async (status: InvoiceStatusEnum) => {
    if (status === InvoiceStatusEnum.DECLINED) {
      setIsDeclineOpen(true);
    } else if (status === InvoiceStatusEnum.DRAFT) {
      setisSuccessOpen(true);
    } else {
      try {
        await updateInvoice({
          id: invoice.id,
          invoice: {
            status,
          },
        }).unwrap();

        updateInvoiceStatusEvent({
          user_id: user?.id || '',
          email: user?.email || '',
          company: user?.company?.name || '',
          company_id: user?.companyId || '',
          invoice_id: invoice.id,
          shipment_id: invoice.shipmentId,
          status: status,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'Error on updating Invoice' });
      }
    }
  };

  const onBackClick = async () => {
    try {
      await refetchGetInvoiceById(invoice.id).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on fetching Invoice' });
    }
    onStepChange(2);
  };

  const onViewClick = () => {
    navigate(`/shipments/${slug}/invoice`);
    setisSuccessOpen(false);
  };

  const onCloseClick = () => {
    navigate(`/shipments/${slug}`);
  };

  const onShowCommentModal = (invoiceItemId: string) => {
    setIsCommentsOpen(true);
    setSelectedItem(invoiceItemId);
  };

  const onHideCommentModal = () => {
    setIsCommentsOpen(false);
    setSelectedItem(null);
  };

  const onCommentsUpdate = (
    newComments: ICommentEntity,
    invoiceItemId: string
  ) => {
    setDataSource((prevDataSource) => {
      return prevDataSource.map((item) =>
        item.id === invoiceItemId
          ? {
              ...item,
              comments: item.comments
                ? [...item.comments, newComments]
                : [newComments],
            }
          : item
      );
    });
  };

  const renderCommentsModal = () => (
    <Modal
      title="Invoice Item Comment"
      open={isCommentsOpen}
      onCancel={onHideCommentModal}
      footer={null}
    >
      {selectedIitem && (
        <InvoiceItemComments
          shipmentId={invoice.shipmentId}
          invoiceId={invoice.id}
          invoiceItemId={selectedIitem}
          onUpdate={onCommentsUpdate}
        />
      )}
    </Modal>
  );

  const renderSuccessModal = () => {
    return (
      <Modal
        open={isSuccessOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,

          <Button key="submit" type="primary" onClick={onViewClick}>
            View Invoices
          </Button>,
        ]}
      >
        {computeSuccessMessage(invoice.status)}
      </Modal>
    );
  };

  const renderDeclineModal = () => {
    return (
      <DeclineInvoiceModal
        isModalOpen={isDeclineOpen}
        invoice={invoice}
        setIsDeclineOpen={setIsDeclineOpen}
      />
    );
  };

  const defaultColumns = [
    ...renderInvoiceMatchingTableCol({
      isItemDetailEditabale: false,
      tableView: InvoiceTableViewEnum.SAVE_SAHRE,
      onCopyActualCost: null,
      isTaxEnabled: !!invoice?.totalTax,
    }),
    {
      title: '',
      dataIndex: 'operation',
      editable: false,
      width: '50px',
      fixed: 'right',
      render: (_, record, rowIndex) => (
        <div className="icon-wrapper">
          <Space align="center">
            <Badge dot={!!(record.comments?.length > 0)}>
              <Button
                type="link"
                icon={<AddCommentIcon />}
                onClick={() => onShowCommentModal(record.id)}
              />
            </Badge>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[32, 32]} className="pdf-matchin-wrapper">
        <Col span={13} className="invoice-table-wrapper">
          <InvoiceHeader invoice={invoice} />
          <InvoiceDetails
            invoiceDetails={invoiceDetailsData}
            isEditabale={false}
            isLoading={false}
          />
          <Row>
            <Col span={24}>
              {dataSource && (
                <Table
                  rowKey="id"
                  columns={defaultColumns as ColumnTypes}
                  dataSource={dataSource}
                  className="table-wrapper--no-border invoice-matching-table-wrapper save-share-view"
                  rowClassName="editable-row"
                  pagination={false}
                  size="middle"
                  scroll={{ x: '800px' }}
                  summary={(record) => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} />
                        <Table.Summary.Cell index={1} />
                        <Table.Summary.Cell index={2} />
                        {!!invoice?.totalTax && (
                          <Table.Summary.Cell index={3} />
                        )}
                        <Table.Summary.Cell index={4}>
                          Total Amount
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={5}>
                          {renderTotalAmount(
                            computeGrandTotal(record).invoiceTotal,
                            computeGrandTotal(record).proposalTotal,
                            invoice.currency
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          {renderTotalAmount(
                            computeGrandTotal(record).proposalTotal,
                            computeGrandTotal(record).invoiceTotal,
                            invoice.currency
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              )}
            </Col>
          </Row>
          <Row className="invoice-matching-footer" justify="end">
            <Space direction="horizontal">
              <Button
                type="default"
                onClick={onBackClick}
                loading={refetchGetInvoiceByIdLoading}
              >
                Back
              </Button>
              {renderActionButtons({
                canBypass: invoice.invoiceProposalCostDifference === 0,
                invoiceId: invoice.id,
                shipmentId: invoice.shipmentId,
                status: invoice.status,
                isLoading: isLoading,
                isAdminCO: isAdminCO(user?.userRole, shipment.currentUserRole),
                canAssignInvoice: canAssignInvoice({
                  shipmentCreator: shipment.createdBy,
                  invoiceCreator: invoice.createdBy,
                  user,
                }),
                onStatusActionClick: onStatusActionClick,
                onCloseClick: onCloseClick,
              })}
            </Space>
          </Row>
        </Col>
        <Col span={11} className="invoice-preview-wrapper">
          <PDFViewer
            file={searchHiglightedFileUrl(invoice.files, invoice.status)}
          />
        </Col>
      </Row>
      {renderDeclineModal()}
      {renderSuccessModal()}
      {selectedIitem && renderCommentsModal()}
    </>
  );
};

export default ShareInvoice;
