import * as Sentry from '@sentry/react';
import AdminLogin from 'app/components/Admin';
import Analytics from 'app/components/Analytics';
import Auth from 'app/components/Auth';
import Bookings, { BookingsRouteWrapper } from 'app/components/Bookings';
import { ContainersRouteWrapper } from 'app/components/Container/Containers';
import Contracts from 'app/components/ContractRates';
import ContractCreation from 'app/components/ContractRatesCreation';
import Dashboard from 'app/components/Dashboard';
import Documents from 'app/components/Documents';
import Invoice from 'app/components/Invoice';
import InvoicesTabs from 'app/components/Invoice/InvoiceTabs';
import Messages from 'app/components/Messages';
import NotFound from 'app/components/NotFound';
import Profile from 'app/components/Profile';
import Proposals from 'app/components/Proposal';
import PurchaseOrder from 'app/components/PurchaseOrder';
import PurchaseOrderCreationCompact from 'app/components/PurchaseOrderCreationCompact';
import Settings from 'app/components/Settings';
import ShipmentCreation from 'app/components/ShipmentCreation';
import ShipmentDetails from 'app/components/ShipmentDetails';
import { ShipmentOverviewRouteWrapper } from 'app/components/ShipmentDetails/ShipmentOverview';
import { ShipmentDocumentsRouteWrapper } from 'app/components/ShipmentDocuments';
import { ShipmentFinanceRouteWrapper } from 'app/components/ShipmentFinance';
import { ShipmentMessagesRouteWrapper } from 'app/components/ShipmentMessages';
import { ShipmentQuotesRouteWrapper } from 'app/components/ShipmentQuotes';
import ShipmentRequestQuote from 'app/components/ShipmentRequestQuote';
import Shipments from 'app/components/Shipments';
import Main from 'app/layout/Main';
import { BookingPartyContextProvider } from 'providers/booking-parties-provider';
import React, { FC } from 'react';
import { Route, Navigate } from 'react-router';
import { Routes } from 'react-router-dom';
import { AuthTypeEnum } from 'types/feature/auth.types';
import { ShipmentTabsEnum } from 'types/feature/shipments.types';

import { ProtectedRoutes, LoginPortectedRoutes } from './RoutesWrappers';

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRoutes: FC = () => {
  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <Main />
          </ProtectedRoutes>
        }
      >
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <Navigate to="/dashboard" />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/analytics"
          element={
            <ProtectedRoutes>
              <Analytics />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoutes>
              <Settings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/profile/team"
          element={
            <ProtectedRoutes>
              <Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/documents"
          element={
            <ProtectedRoutes>
              <Documents />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/shipments"
          element={
            <ProtectedRoutes>
              <Shipments />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/shipments/:slug"
          element={
            <ProtectedRoutes>
              <BookingPartyContextProvider>
                <ShipmentDetails />
              </BookingPartyContextProvider>
            </ProtectedRoutes>
          }
        >
          <Route index element={<ShipmentOverviewRouteWrapper />} />
          <Route
            path={ShipmentTabsEnum.OVERVIEW}
            element={<ShipmentOverviewRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.QUOTES}
            element={<ShipmentQuotesRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.CONTAINERS}
            element={<ContainersRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.DOCUMENTS}
            element={<ShipmentDocumentsRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.BOOKINGS}
            element={<BookingsRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.COMMUNICATIONS}
            element={<ShipmentMessagesRouteWrapper />}
          />
          <Route
            path={ShipmentTabsEnum.FINANCE}
            element={<ShipmentFinanceRouteWrapper />}
          />
          <Route path="*" element={<ShipmentOverviewRouteWrapper />} />
        </Route>
        <Route
          path="/shipments/:slug/invoice/:id"
          element={
            <ProtectedRoutes>
              <Invoice />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoutes>
              <InvoicesTabs />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/shipments/create"
          element={
            <ProtectedRoutes>
              <ShipmentCreation />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/shipments/:slug/request-quote"
          element={
            <ProtectedRoutes>
              <ShipmentRequestQuote />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/purchase-orders"
          element={
            <ProtectedRoutes>
              <PurchaseOrder />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/purchase-orders/create"
          element={
            <ProtectedRoutes>
              <PurchaseOrderCreationCompact />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/bookings"
          element={
            <ProtectedRoutes>
              <Bookings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/contracts"
          element={
            <ProtectedRoutes>
              <Contracts />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoutes>
              <Messages />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/contracts/create"
          element={
            <ProtectedRoutes>
              <ContractCreation />
            </ProtectedRoutes>
          }
        />
      </Route>
      <Route path="/proposals/:id" element={<Proposals />} />

      <Route
        path="login"
        element={
          <LoginPortectedRoutes>
            <Auth type={AuthTypeEnum.LOGIN} />
          </LoginPortectedRoutes>
        }
      />

      <Route
        path="signup"
        element={
          <LoginPortectedRoutes>
            <Auth type={AuthTypeEnum.SIGNUP} />
          </LoginPortectedRoutes>
        }
      />
      <Route
        path="forgot-password"
        element={
          <LoginPortectedRoutes>
            <Auth type={AuthTypeEnum.SEND_EMAIL} />
          </LoginPortectedRoutes>
        }
      />
      <Route
        path="reset-password"
        element={
          <LoginPortectedRoutes>
            <Auth type={AuthTypeEnum.RESET} />
          </LoginPortectedRoutes>
        }
      />
      <Route path="admin-login" element={<AdminLogin />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  );
};

export default AppRoutes;
