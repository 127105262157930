import { Checkbox, Form } from 'antd';
import React, { FC, useEffect } from 'react';
import { ModalityEnum } from 'types/entities/shipment.entity';
import {
  IPOModalityFilterForm,
  IPurchaseOrderModalitiesFilterProps,
} from 'types/feature/purchase-orders.types';

const POModalitiesFilter: FC<IPurchaseOrderModalitiesFilterProps> = ({
  layout,
  defaultValue,
  onChange,
}) => {
  const [form] = Form.useForm<IPOModalityFilterForm>();
  const onFormChange = ({ poModalityFilter }: IPOModalityFilterForm) => {
    onChange(poModalityFilter);
  };
  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({ poModalityFilter: defaultValue });
    }
    //eslint-disable-next-line
  }, [defaultValue]);
  return (
    <Form
      className="filters-form-wrapper"
      form={form}
      initialValues={{ poModalityFilter: defaultValue }}
      onValuesChange={onFormChange}
    >
      <Form.Item name="poModalityFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {Object.values(ModalityEnum).map(
              (modality: ModalityEnum, index: number) => (
                <div className="input-space-wrapper" key={modality}>
                  <Checkbox value={modality}>{modality}</Checkbox>
                </div>
              )
            )}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default POModalitiesFilter;
