import Icon from '@ant-design/icons';
import { Button, ButtonProps, Modal, Space, Typography } from 'antd';
import UploadOrderFileModal from 'app/components/PurchaseOrderCreation/UploadPdfModal';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POFileType } from 'types/entities/purchase-order.entity';

import { ReactComponent as OrderFilled } from '../../../assets/order-filled.svg';
import './style.scss';

interface ICreatePurchaseOrderProps {
  buttonProps?: ButtonProps;
  text?: string;
}
const CreatePurchaseOrderModal: FC<ICreatePurchaseOrderProps> = ({
  buttonProps,
  text,
}) => {
  const [isOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);
  const goToCreateOrderPage = () => {
    navigate('/purchase-orders/create');
    onCloseModal();
  };

  return (
    <>
      {text ? (
        <Typography.Text className="create-dropdown-item" onClick={onOpenModal}>
          New Purchase Order
        </Typography.Text>
      ) : (
        <Button {...buttonProps} onClick={() => setIsModalOpen(true)}>
          Create New Order
        </Button>
      )}
      <Modal
        open={isOpen}
        title="Create New Order"
        footer={null}
        onCancel={onCloseModal}
        width={450}
        className="create-po-modal"
        centered
      >
        <Space direction="vertical" align="center">
          <Button
            size="large"
            icon={<Icon component={OrderFilled} />}
            onClick={goToCreateOrderPage}
          >
            Create Manually
          </Button>
          <Space>
            <UploadOrderFileModal
              onClickShowModal={onCloseModal}
              fileType={POFileType.PDF}
            />
            <UploadOrderFileModal
              onClickShowModal={onCloseModal}
              fileType={POFileType.XLSX}
            />
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default CreatePurchaseOrderModal;
