export enum PurchaseOrderCreationScreenEnum {
  OVERVIEW_SCREEN = 'overview-screen',
  UPLOAD_SCREEN = 'upload_screen',
}

export interface IPurchaseOrderCreationOnboardingScreenProps {
  onClose: () => void;
}

export interface IPOOverviewScreenProps {
  onScreenChange: (
    newScreen: PurchaseOrderCreationScreenEnum,
    screenData?: Record<string, any>
  ) => void;
}
