import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IProposalDiffViewerProps } from 'types/feature/proposal.types';

import AcceptedProposalActions from '../AcceptedProposalActions';
import {
  FeeDetailSection,
  TotalFeeSection,
} from '../ProposalDetailsModal/ProposalDetailsComponent';
import ProposalImportantDetails from '../ProposalImprotantDetails';
import ProposalShipmentItems from '../ProposalShipmentItems';
import './proposal-diff-viewer.scss';
import { computeDiffKeys } from './proposal-diff-viewer.utils';

const ProposalDiffViewer: FC<IProposalDiffViewerProps> = ({
  proposal,
  isActionsEnabled,
  proposalCurrency,
}) => {
  const company = useSelector((state: RootState) => state.user.user?.company);

  const changedProposal = useMemo(() => {
    return {
      ...proposal,
      ...proposal.suggestedProposal,
    };
  }, [proposal]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const proposalEntityDiffKeys: Record<keyof IProposalEntity, any> = useMemo(
    () => computeDiffKeys(proposal, changedProposal),
    [proposal, changedProposal]
  );

  return (
    <div className="proposal-diff-wrapper">
      <div className="proposal-diff-wrapper--split-view">
        <div className="split-view-row">
          <div className="split-view-row--left p-0">
            <div className="header old-view">Accepted Quote</div>
          </div>
          <div className="split-view-row--right p-0">
            <div className="header new-view">
              Changes requested by Forwarder
            </div>
          </div>
        </div>
        <div className="split-view-row">
          <div className="split-view-row--left">
            <TotalFeeSection
              proposal={proposal}
              currency={proposalCurrency || company?.currency}
            />
          </div>
          <div className="split-view-row--right">
            <TotalFeeSection
              proposal={changedProposal}
              currency={proposalCurrency || company?.currency}
              className={proposalEntityDiffKeys.proposalCost}
            />
          </div>
        </div>
        <div className="split-view-row">
          <div className="split-view-row--left">
            <ProposalShipmentItems proposal={proposal} />
          </div>
          <div className="split-view-row--right">
            <ProposalShipmentItems
              proposal={changedProposal}
              diffKeys={proposalEntityDiffKeys}
            />
          </div>
        </div>
        <div className="split-view-row">
          <div className="split-view-row--left">
            <FeeDetailSection proposal={proposal} />
          </div>
          <div className="split-view-row--right">
            <FeeDetailSection
              proposal={changedProposal}
              diffKeys={proposalEntityDiffKeys}
            />
          </div>
        </div>
        <div className="split-view-row">
          <div className="split-view-row--left">
            <ProposalImportantDetails proposal={proposal} />
          </div>
          <div className="split-view-row--right">
            <ProposalImportantDetails
              proposal={changedProposal}
              diffKeys={proposalEntityDiffKeys}
            />
          </div>
        </div>
        {isActionsEnabled && (
          <div className="split-view-row">
            <div className="split-view-row--left"></div>
            <div className="split-view-row--right">
              <AcceptedProposalActions
                proposal={proposal}
                actionButton={{ isBlock: true, size: 'middle' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalDiffViewer;
