import { IDocumentExtractorEntity } from 'types/entities/document-extractor.entity';
import {
  IDocumentExtractorPayload,
  IUpdateExtractedDocumentRequestBody,
} from 'types/feature/document-extractor.types';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const documentExtractorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocumentExtractorById: build.query<
      ApiResponse<IDocumentExtractorEntity>,
      IDocumentExtractorPayload
    >({
      query: ({ fileId }) => ({
        url: `/document-extractor/${fileId}`,
        method: 'GET',
      }),
      providesTags: ['DocumentExtractor'],
    }),

    extractDocument: build.mutation<
      ApiResponse<IDocumentExtractorPayload>,
      IDocumentExtractorPayload
    >({
      query: (body) => ({
        url: `/document-extractor/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DocumentExtractor'],
    }),

    updateExtractedDocumentById: build.mutation<
      ApiResponse<IDocumentExtractorEntity>,
      IUpdateExtractedDocumentRequestBody
    >({
      query: ({ fileId, ...body }) => ({
        url: `/document-extractor/${fileId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['DocumentExtractor'],
    }),
  }),
});

export const {
  useGetDocumentExtractorByIdQuery,
  useLazyGetDocumentExtractorByIdQuery,
  useExtractDocumentMutation,
  useUpdateExtractedDocumentByIdMutation,
} = documentExtractorApi;
