import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { ITaskEntity } from 'types/entities/task.entity';

import { formatTaskState, splitState } from 'utils/format-fields';

export const taskColumnsConst: ColumnsType<ITaskEntity> = [
  {
    key: 'status',
    dataIndex: 'status',
    render: (value, record, index) => {
      return (
        <Space direction="vertical" align="center">
          {formatTaskState(record.status)}
          <span className="shipment-text--sub">{record.shipment}</span>
        </Space>
      );
    },
  },

  {
    key: 'name',
    render: (value, record, index) => {
      return splitState(record.name);
    },
  },
  {
    key: 'date',
    render: (value, record, index) => {
      return (
        <Typography.Text type={record.isPastDeadline ? 'danger' : 'secondary'}>
          {record.dueDate ? dayjs(record.dueDate).format('MMM DD') : 'N/A'}
        </Typography.Text>
      );
    },
    width: 70,
  },
];
