import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'api/common/config';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ITrackingEntity } from 'types/entities/tracking.entity';

import { isAirTransportation } from 'utils/shipment-type';

import {
  displayShipmentOriginDest,
  displayTrackingEvent,
  displayVisselMarker,
} from './Markers';
import { style } from './map-style';
import './map.scss';
import { ShipmentsMapProps } from './map.types';
import useTrackingMap from './useTrackingMap';

// CONFIG
const defaultCenter = {
  lat: 0,
  lng: 0,
};

const OPTIONS = {
  minZoom: 2,
  maxZoom: 30,
  styles: style,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LIBRAIRY = ['geometry', 'drawing', 'places'] as any;

export const mapConfig = {
  id: 'google-map-script',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries: LIBRAIRY,
};

const generateKeyMap = (shipments: IShipmentEntity[], type: string) =>
  type === 'SINGLE' ? `map-${type}-${shipments?.[0]?.id}` : `global-map`;

// COMPONENT
const ShipmentsMap: FC<ShipmentsMapProps> = ({
  shipments,
  trackingData,
  className,
  type,
  polyline,
}) => {
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader(mapConfig);
  const [currentMap, setCurrentMap] = useState<google.maps.Map>();
  const [activatedMarkerId, setActivatedMarkerId] = useState<
    string | undefined
  >(undefined);
  const isAirTracking =
    type === 'SINGLE' && isAirTransportation(shipments[0].shipmentType.title);

  const center =
    shipments?.length && shipments?.[0].currentLocation
      ? {
          lat: shipments?.[0].currentLocation.latitude,
          lng: shipments?.[0].currentLocation.longitude,
        }
      : defaultCenter;

  // METHODS
  const goToShipment = (shipmentId: string, slug: string) =>
    navigate(`/shipments/${slug}`, { state: { shipmentId } });
  const onMapLoaded = useCallback((map: google.maps.Map) => {
    setCurrentMap(map);
  }, []);
  const onMapUnmounted = useCallback((map: google.maps.Map) => {
    setCurrentMap(undefined);
  }, []);
  const onMarkerClicked = (id: string) => {
    setActivatedMarkerId(id);
  };

  // HOOKS
  const { groupByLocation } = useTrackingMap();

  const drawTrackingRoad = useCallback(
    (polyline: string, map: google.maps.Map) => {
      if (google.maps.geometry && polyline) {
        const decodedPolyline =
          google.maps.geometry.encoding.decodePath(polyline);
        const donePath = new google.maps.Polyline({
          path: decodedPolyline,
          strokeColor: '#5c71f7',
          strokeOpacity: 0.9,
          strokeWeight: 1.5,
        });
        donePath.setMap(map);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [polyline]
  );
  const drawTrackingAir = useCallback(
    (map: google.maps.Map) => {
      const path: google.maps.LatLng[] = [
        new google.maps.LatLng({
          lat: shipments[0].origin.latitude,
          lng: shipments[0].origin.longitude,
        }),
        new google.maps.LatLng({
          lat: shipments[0].destination.latitude,
          lng: shipments[0].destination.longitude,
        }),
      ];
      const donePath = new google.maps.Polyline({
        path: path,
        strokeColor: '#5c71f7',
        strokeOpacity: 0.9,
        strokeWeight: 1.5,
        geodesic: true,
      });
      donePath.setMap(map);
    },
    [shipments]
  );
  const onTrackingDataUpdated = useCallback(
    (map: google.maps.Map) => {
      if (type === 'SINGLE') {
        if (polyline) {
          drawTrackingRoad(polyline, map);
        } else {
          drawTrackingAir(map);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [polyline, type, drawTrackingRoad]
  );

  useEffect(() => {
    if (currentMap) onTrackingDataUpdated(currentMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMap, polyline]);

  return (
    <div className={`map-wrapper ${className}`}>
      {isLoaded && (
        <GoogleMap
          key={generateKeyMap(shipments, type)}
          center={center}
          zoom={2}
          mapContainerClassName="map-wrapper__container"
          onLoad={onMapLoaded}
          onUnmount={onMapUnmounted}
          options={OPTIONS}
        >
          <>
            {trackingData &&
              trackingData.length > 0 &&
              (trackingData as ITrackingEntity[])?.map(
                // eslint-disable-next-line array-callback-return
                (tracking: ITrackingEntity) => {
                  if (
                    tracking.latitude !==
                      shipments[0].currentLocation?.latitude &&
                    tracking.longitude !==
                      shipments[0].currentLocation?.longitude
                  ) {
                    return displayTrackingEvent({
                      shipment: shipments[0],
                      tracking,
                      onMarkerClicked,
                      activatedMarkerId: activatedMarkerId,
                      onCloseInfoWindow: () => setActivatedMarkerId(undefined),
                    });
                  }
                }
              )}
            {type === 'SINGLE'
              ? shipments.map((shipment: IShipmentEntity) => {
                  return (
                    <div key={shipment.id}>
                      {displayShipmentOriginDest({
                        shipment,
                        onMarkerClicked: onMarkerClicked,
                        activatedMarkerId: activatedMarkerId,
                        onCloseInfoWindow: () =>
                          setActivatedMarkerId(undefined),
                        type: 'origin',
                      })}
                      {displayShipmentOriginDest({
                        shipment,
                        onMarkerClicked: onMarkerClicked,
                        activatedMarkerId: activatedMarkerId,
                        onCloseInfoWindow: () =>
                          setActivatedMarkerId(undefined),
                        type: 'destination',
                      })}
                      {shipment.currentLocation &&
                        displayVisselMarker({
                          shipments: shipment,
                          onMarkerClicked: onMarkerClicked,
                          activatedMarkerId: activatedMarkerId,
                          onCloseInfoWindow: () =>
                            setActivatedMarkerId(undefined),
                        })}
                    </div>
                  );
                })
              : Object.values(groupByLocation(shipments)).map(
                  (shipments: IShipmentEntity[]) => {
                    return (
                      <div key={shipments[0].id}>
                        {displayVisselMarker({
                          shipments,
                          onMarkerClicked: onMarkerClicked,
                          activatedMarkerId: activatedMarkerId,
                          onCloseInfoWindow: () =>
                            setActivatedMarkerId(undefined),
                          showRedirct: goToShipment,
                        })}
                      </div>
                    );
                  }
                )}
          </>
        </GoogleMap>
      )}
    </div>
  );
};

export default memo(ShipmentsMap);
