import Icon from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import { ModalityEnum } from 'types/entities/shipment.entity';

import { splitState } from 'utils/format-fields';

import { ReactComponent as BoatIcon } from '../../../../assets/icons/boat-filled-icon.svg';
import { ReactComponent as PlaneIcon } from '../../../../assets/icons/plane-icon.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/icons/truck-icon.svg';

export const displayFreighMethod = (method: ModalityEnum, withTitle = true) => {
  switch (method) {
    case ModalityEnum.AIR:
      return (
        <Space>
          <Icon component={PlaneIcon} /> {withTitle && splitState(method)}
        </Space>
      );
    case ModalityEnum.ROAD:
      return (
        <Space>
          <Icon component={TruckIcon} /> {withTitle && splitState(method)}
        </Space>
      );

    default:
      return (
        <Space>
          <Icon component={BoatIcon} /> {withTitle && splitState(method)}
        </Space>
      );
  }
};
