import { Checkbox, Col, Form, Modal, Select } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { IShipmentStatusFormProps } from 'types/feature/create-shipment.types';

import { hasShipmentStarted } from 'utils/shipment-helper';

import { shipmentStatusOptions } from '../ShipmentCreationForm/shipment.utils';

const ShipmentStatusForm: FC<IShipmentStatusFormProps> = ({
  form,
  defaultValues,
}) => {
  const handleSelectChange = (value) => {
    Modal.confirm({
      title:
        'Would you like to turn off Auto-updating of the status of the shipment?',
      onOk() {
        form.setFieldsValue({
          status: value,
          autoUpdateStatus: false,
        });
      },
      onCancel() {
        form.setFieldsValue({
          status: defaultValues.status,
        });
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  return (
    <DashboardCard large headerTitle="Shipment Details">
      <Form form={form} initialValues={defaultValues}>
        <Col span={10}>
          <Form.Item label="Shipment Status" name="status">
            <Select
              options={shipmentStatusOptions(defaultValues.status)}
              {...(hasShipmentStarted(defaultValues.status) &&
                defaultValues.autoUpdateStatus && {
                  onChange: handleSelectChange,
                })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="autoUpdateStatus"
            valuePropName="checked"
            hidden={true}
          >
            <Checkbox>autoUpdateStatus</Checkbox>
          </Form.Item>
        </Col>
      </Form>
    </DashboardCard>
  );
};

export default ShipmentStatusForm;
