import { Button, Form, Modal, Select, Tag, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import {
  useBulkDeleteShipmentTagsByAIMutation,
  useBulkUpdateShipmentTagsByAIMutation,
} from 'api/shipment-metadata';
import React, { FC, useEffect, useState } from 'react';
import {
  IShipmentTagsByAIProps,
  TTagsSelectRenderer,
} from 'types/feature/metadata.types';

import { displayErrors } from 'utils/error-notification';

import './shipment-tags-by-ai.scss';
import {
  extractTagColor,
  extractTagIds,
  shipmentTagOptions,
} from './shipment-tags.utils';

const ShipmentTagsByAIModal: FC<IShipmentTagsByAIProps> = ({
  tagsData,
  isOpen,
}) => {
  const [form] = useForm<{ tags: string[] }>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [bulkUpdateShipmentTagsByAI] = useBulkUpdateShipmentTagsByAIMutation();
  const [bulkDeleteShipmentTagsByAI] = useBulkDeleteShipmentTagsByAIMutation();

  const deleteAllTags = async () => {
    try {
      const tagsId = extractTagIds(tagsData);
      await bulkDeleteShipmentTagsByAI({ ids: tagsId }).unwrap();
      form.setFieldValue('tags', []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Suggested Tags Delete Error' });
    }
  };

  const handleSelectedTag = async () => {
    try {
      const { tags } = await form.validateFields();

      const deletedTagsPayload = tagsData.filter(
        (tag) => !tags.includes(tag.id)
      );
      const deletedTagIds = extractTagIds(deletedTagsPayload);
      const selectedTagsPayload = tags.map((tagId) => ({
        id: tagId,
        isSuggested: false,
      }));

      await bulkDeleteShipmentTagsByAI({ ids: deletedTagIds }).unwrap();
      await bulkUpdateShipmentTagsByAI(selectedTagsPayload).unwrap();

      notification.success({ message: 'Tags added successfuly' });
      setIsModalOpen(false);
      form.setFieldValue('tags', []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Suggested Tags Delete Error' });
    }
  };

  const handleOk = () => {
    handleSelectedTag();
    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    await deleteAllTags();
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    const tagsId = extractTagIds(tagsData);
    form.setFieldValue('tags', tagsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onRenderTagsSelect: TTagsSelectRenderer = (tagSelectProps) => {
    const { label, value, closable, onClose } = tagSelectProps;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={extractTagColor(tagsData, value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Modal
      title="Shipments Tags"
      open={isModalOpen}
      onOk={handleOk}
      okText="Add tags to shipment"
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          form="ai-tags-form"
          onClick={handleSelectedTag}
        >
          Add tags to shipment
        </Button>,
      ]}
    >
      <p>
        We have identified following tags based on your shipment reference and
        other information in this shipment, would you like to create these tags?
      </p>
      <>
        <Form form={form} layout="vertical" id="ai-tags-form">
          <FormItem
            label="Extracted Tags"
            name="tags"
            rules={[
              {
                required: true,
                message: 'Please Select Tag',
              },
            ]}
          >
            <Select
              mode="multiple"
              tagRender={onRenderTagsSelect}
              defaultValue={shipmentTagOptions(tagsData)}
              style={{ width: '100%' }}
              options={shipmentTagOptions(tagsData)}
            />
          </FormItem>
        </Form>
      </>
    </Modal>
  );
};

export default ShipmentTagsByAIModal;
