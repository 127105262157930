import {
  ICostHeadEntity,
  ICreateCostHeadEntity,
} from 'types/entities/contract.entity';
import { IContractFeesForm } from 'types/feature/contracts.type';

import { mapValuesToLocation } from 'utils/location';

export const mapContractFeesFormValues = (
  values: IContractFeesForm,
  defaultCostHeads: ICreateCostHeadEntity[] | undefined
): ICostHeadEntity[] => {
  const costHeads: ICostHeadEntity[] = values.costHeads?.map((costHead) => {
    const {
      id,
      originFull,
      destinationFull,
      serviceMode,
      charges,
      title,
      transitDays,
    } = costHead;

    const defaultCostHead = defaultCostHeads?.find((ch) => ch.id === id);

    const serviceModes = serviceMode ? serviceMode.split('/') : undefined;
    if (!charges?.length && !id)
      throw Error(
        'You need to insert at least one charge head (fees) per Cost Head !'
      );

    return {
      id,
      title,
      transitDays,
      origin: mapValuesToLocation(
        defaultCostHead?.origin ?? null,
        originFull,
        serviceModes ? serviceModes[0] : undefined
      ),
      destination: mapValuesToLocation(
        defaultCostHead?.destination ?? null,
        destinationFull,
        serviceModes ? serviceModes[1] : undefined
      ),
      charges: charges?.map(({ id, ...rest }) => ({ ...rest })),
    } as ICostHeadEntity;
  });

  return costHeads;
};
