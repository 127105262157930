import Icon, { MenuUnfoldOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, MenuProps, Tooltip } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import { ReactComponent as BeeboltLogo } from '../../../assets/main-logo.svg';
import { ReactComponent as AnalyticsIcon } from '../../../assets/menuIcons/analytics.svg';
import { ReactComponent as BookingIcon } from '../../../assets/menuIcons/booking.svg';
import { ReactComponent as ContractsIcon } from '../../../assets/menuIcons/contracts.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/menuIcons/document.svg';
import { ReactComponent as HomeIcon } from '../../../assets/menuIcons/home.svg';
import { ReactComponent as InfoIcon } from '../../../assets/menuIcons/info.svg';
import { ReactComponent as InvoiceIcon } from '../../../assets/menuIcons/invoice-icon.svg';
import { ReactComponent as PersonAddIcon } from '../../../assets/menuIcons/person-add.svg';
import { ReactComponent as POIcon } from '../../../assets/menuIcons/purchase-order.svg';
import { ReactComponent as BoatIcon } from '../../../assets/menuIcons/shipment.svg';
import { useChat } from '../../../providers/chat-provider';
import './sider.scss';

const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  disabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    disabled,
  } as MenuItem;
}

const topItems: MenuItem[] = [
  getItem('Home', 'dashboard', <Icon component={HomeIcon} />),
  getItem('Shipments', 'shipments', <Icon component={BoatIcon} />),
  getItem('Purchase Orders', 'purchase-orders', <Icon component={POIcon} />),
  getItem('Invoices', 'invoices', <Icon component={InvoiceIcon} />),
  getItem('Analytics', 'analytics', <Icon component={AnalyticsIcon} />),
  getItem('More', 'more', <Icon component={MoreIcon} />, [
    getItem('Documents', 'documents', <Icon component={DocumentIcon} />),
    getItem('Contracts', 'contracts', <Icon component={ContractsIcon} />),
    getItem('Bookings', 'bookings', <Icon component={BookingIcon} />),
  ]),
];

const bottomItems: MenuItem[] = [
  getItem('Help center', 'help-center', <Icon component={InfoIcon} />),
  getItem('Invite new member', 'team', <Icon component={PersonAddIcon} />),
];
const SiderNavigation: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [defaultPage, setDefaultPage] = useState<string>('dashboard');

  const { openChat } = useChat();

  const goToPage = (route: string) => {
    switch (route) {
      case 'help-center':
        window.open('https://help.beebolt.com');
        setDefaultPage('help-center');
        break;
      case 'team':
        navigate('profile/team');
        break;
      case 'contact-support':
        openChat();
        setDefaultPage('contact-support');
        break;
      default:
        navigate(route);
    }
  };

  const extractCurrentPage = useCallback(() => {
    const parts = location.pathname.split('/');

    if (parts.length) {
      if (parts.length > 2) {
        setDefaultPage(parts[2]);
      } else {
        setDefaultPage(parts[1]);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    extractCurrentPage();
  }, [location.pathname, extractCurrentPage]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={null}
      className="side-menu"
    >
      <Tooltip title={collapsed ? 'Expand' : 'Collapse'}>
        <Button
          type="text"
          icon={
            collapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <BeeboltLogo className="beebolt-icon" />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
          className="collapse-btn"
        />
      </Tooltip>

      <div className="menu-top">
        <Menu
          defaultSelectedKeys={[defaultPage]}
          selectedKeys={[defaultPage]}
          items={topItems}
          mode="inline"
          onClick={(info) => {
            goToPage(info.key);
          }}
        />
      </div>

      <div className="menu-bottom">
        <Menu
          items={bottomItems}
          selectedKeys={[defaultPage]}
          onClick={(info) => {
            goToPage(info.key);
          }}
        />
      </div>
    </Sider>
  );
};

export default SiderNavigation;
