import { CloseOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Button, Input, Row, Typography } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useState } from 'react';

import { ReactComponent as Stars } from '../../../../assets/stars.svg';

const { TextArea } = Input;
const ShipmentTextPreview: FC<{
  onSubmitShipmentText: (text: string) => Promise<void>;
  onClosePreviewTab;
}> = ({ onSubmitShipmentText, onClosePreviewTab }) => {
  const [shipmentInfos, setShipmentInfos] = useState<string>('');

  return (
    <DashboardCard
      headerTitle="Enter the shipment details"
      rightAccessory={
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={onClosePreviewTab}
        />
      }
      fitContent
      large
    >
      <Typography.Text type="secondary">
        Type the shipment information or simply copy/paste a pre-alert message
        from your email and we will fill the form for you!
      </Typography.Text>
      <TextArea
        value={shipmentInfos}
        onChange={(event) => setShipmentInfos(event.target.value)}
        autoSize={{ minRows: 17, maxRows: 200 }}
        placeholder="EX: Please provide the best rate for the following shipment 3 x 40' HC from Algiers to Dubai, Cargo is ready now, FOB, FAK. "
        style={{ marginTop: 10 }}
      />
      <Row justify="end" style={{ marginTop: '10px' }}>
        <Button
          onClick={() => onSubmitShipmentText(shipmentInfos)}
          type="primary"
          disabled={!shipmentInfos}
          data-chameleon="submit-ai-shipment-form"
        >
          Fill in the Form <Icon component={Stars} />
        </Button>
      </Row>
    </DashboardCard>
  );
};

export default ShipmentTextPreview;
