import { EditFilled } from '@ant-design/icons';
import { Button, ConfigProvider, Modal, message, notification } from 'antd';
import {
  useEditShipmentMutation,
  useLazyGetShipmentsBySlugQuery,
} from 'api/shipment';
import React, { useEffect, useState } from 'react';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { TOnCreateShipmentParams } from 'types/feature/create-shipment.types';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';

import ShipmentCreationForm from '../ShipmentCreation/ShipmentCreationForm';
import './shipment-edit.scss';

interface IShipmentEditModalProps {
  shipment: IShipmentEntity;
}

const ShipmentEditModal: React.FC<IShipmentEditModalProps> = ({ shipment }) => {
  const [open, setOpen] = useState(false);
  const [editShipment, { isSuccess, isLoading }] = useEditShipmentMutation();
  const [getShipment] = useLazyGetShipmentsBySlugQuery();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = async () => {
    try {
      await getShipment(shipment.slug).unwrap();
      setOpen(false);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Refresh Error' });
    }
  };

  const onCreateShipment: TOnCreateShipmentParams = async (
    updatedShipment: Partial<IShipmentEntity>
  ) => {
    try {
      await editShipment({
        shipmentId: shipment.id,
        shipment: updatedShipment,
      }).unwrap();

      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Edit Error' });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Updated Shipment Details Successfully !',
      });
    }
  }, [isSuccess]);

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            controlWidth: 100,
          },
        },
      }}
    >
      <Button
        data-testid="shipment-edit-btn"
        className="edit-btn"
        type="link"
        onClick={showModal}
        icon={<EditFilled />}
        disabled={
          shipment.currentCollaborator &&
          isViewer(shipment.currentCollaborator?.accessLevel)
        }
      />
      <Modal
        open={open}
        title="Edit Shipment"
        onCancel={handleCancel}
        footer={null}
        width="70vw"
        destroyOnClose
        data-testid="shipment-update-modal"
      >
        <ShipmentCreationForm
          defaultShipment={shipment}
          onCreateShipment={onCreateShipment}
          isLoading={isLoading}
          isEditView={true}
          openPreviewTab={() => {
            message.info('Not implemented for update !');
          }}
        />
      </Modal>
    </ConfigProvider>
  );
};

export default ShipmentEditModal;
