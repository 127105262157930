import { PhoneNumber } from 'types/entities/auth.entity';

export const checkPasswordValidation = (value: string) => {
  const isContainsUppercase = /^(?=.*[A-Z])/;
  if (!isContainsUppercase.test(value)) {
    return 'Password must have at least one Uppercase Character.';
  }

  const isContainsLowercase = /^(?=.*[a-z])/;
  if (!isContainsLowercase.test(value)) {
    return 'Password must have at least one Lowercase Character.';
  }

  const isContainsNumber = /^(?=.*[0-9])/;
  if (!isContainsNumber.test(value)) {
    return 'Password must contain at least one Digit.';
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/;
  if (!isContainsSymbol.test(value)) {
    return 'Password must contain at least one Special Symbol.';
  }

  return null;
};

export const isEmailValid = (value: string) => {
  const isEmail = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
  return value && value.length && isEmail.test(value);
};

export const isPhoneNumberValid = (value: string) => {
  const isPhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

  return value && value.length && isPhone.test(value);
};

export const formatPhoneNumber = (value: PhoneNumber) => {
  return `${value?.countryCode}${value?.areaCode}${value?.phoneNumber}`;
};
