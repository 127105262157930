import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import {
  MyMultipleFileUploadProps,
  IMultipleUploadFileDataSourcDef,
  IMultipleUploadFileForm,
} from 'types/feature/shipments.types';

import { ReactComponent as DeleteIcon } from '../../../../assets/delete_icon.svg';
import uploadIcon from '../../../../assets/icons/upload-file-icon.svg';
import {
  ACCEPTED_FILE_TYPE_CONST,
  RULE_CONST,
  documentTypeOptions,
} from './file-upload.const';
import './file-upload.scss';

const { confirm } = Modal;
const { Title } = Typography;

const MultipleFileUpload: React.FC<MyMultipleFileUploadProps> = ({
  shipmentId,
  isLoading,
  defaultValues,
  onAccept,
  onSave,
  onDelete,
}) => {
  const [form] = Form.useForm<IMultipleUploadFileForm>();
  const [uploadedFiles, setUploadedFiles] = useState<
    IMultipleUploadFileDataSourcDef[]
  >([]);

  const {
    getRootProps,
    getInputProps,
    open: openFileUpload,
  } = useDropzone({
    multiple: true,
    maxFiles: 5,
    accept: ACCEPTED_FILE_TYPE_CONST, // Set accepted file types
    onDrop: (files: File[], fileRejections: FileRejection[]) => {
      // You can handle rejected files if needed
      if (fileRejections.length > 0) {
        console.log('Rejected files:', fileRejections);
      }

      if (files?.length > 0) {
        const filesData: IMultipleUploadFileDataSourcDef[] = files.map(
          (file: File, index: number) => ({
            id: '',
            url: '',
            name: file.name,
            file,
            type: IDocumentTypeEnum.OTHER,
          })
        );

        form.setFieldsValue({ fileItems: [...uploadedFiles, ...filesData] });
        setUploadedFiles([...uploadedFiles, ...filesData]);
        onAccept(filesData);
      }
    },
  });

  const onFileDelete = (
    remove: (index: number | number[]) => void,
    index: number
  ) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this document ?',
      onOk: async () => {
        try {
          const fileItems = form.getFieldValue('fileItems');

          if (fileItems[index].id) {
            await onDelete(fileItems, index);
          }

          // remove(index);
        } catch {
          message.error('Make sure to fill all required fields !');
        }
      },
    });
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      onSave(values.fileItems);
    } catch {
      message.error('Make sure to fill all required fields !');
    }
  };

  useEffect(() => {
    form.setFieldsValue({ fileItems: defaultValues });
    setUploadedFiles(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div className="file-upload-wrapper">
      {uploadedFiles.length < 1 ? (
        <div {...getRootProps({ className: 'file-dropzone' })}>
          <input {...getInputProps()} />
          <img src={uploadIcon} alt="upload icon" />
          <p>Drag drop some files here, or click to select files</p>
        </div>
      ) : (
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[24, 24]} className="file-table-header">
            <Col span={10}>
              <Title level={5} className="m-0">
                Name
              </Title>
            </Col>
            <Col span={10}>
              <Title level={5} className="m-0">
                Type
              </Title>
            </Col>
            <Col span={4}>
              <Title level={5} className="m-0">
                Actions
              </Title>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.List name="fileItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row
                        key={field.key}
                        gutter={[12, 12]}
                        className="file-item-wrapper"
                      >
                        <Col span={0}>
                          <Form.Item
                            name={[field.name, 'id']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={RULE_CONST}
                            hidden={true}
                          >
                            <Input
                              size="middle"
                              placeholder="Id"
                              disabled={isLoading}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name={[field.name, 'name']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={RULE_CONST}
                          >
                            <Input
                              size="middle"
                              placeholder="Document type"
                              disabled={isLoading}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name={[field.name, 'type']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={RULE_CONST}
                          >
                            <Select
                              size="middle"
                              placeholder="Document type"
                              options={documentTypeOptions}
                              className="documents-type-selector"
                              disabled={isLoading}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <div className="action-wrapper">
                            {isLoading ? (
                              <Spin indicator={<LoadingOutlined spin />} />
                            ) : (
                              <Button
                                icon={<DeleteIcon />}
                                type="text"
                                onClick={() => onFileDelete(remove, index)}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          {uploadedFiles.length < 5 && (
            <Button
              type="link"
              className="p-0"
              disabled={isLoading}
              icon={<PlusOutlined />}
              onClick={openFileUpload}
            >
              Upload Document
            </Button>
          )}
          <div className="file-upload-footer">
            <Button
              htmlType="submit"
              type="primary"
              className="save-file-btn"
              loading={isLoading}
              disabled={defaultValues.length === 0}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default MultipleFileUpload;
