import { ConfigProvider, Modal } from 'antd';
import ImportShipmentsByTracking from 'app/components/ImportShipments';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetCreateShipment } from 'state/slices/create-shipment.slice';
import { APP_THEME_CONST } from 'style-config';
import {
  ISignupOnboardingModalProps,
  SignupOnboardingScreenEnum,
} from 'types/feature/user-onboarding';

import PurchaseOrderCreationOnboardingScreen from '../PurchaseOrderOnboardingScreen';
import ShipmentCreationOnboardingModal from '../ShipmentCreationOnboardingModal';
import OverviewScreen from './OverviewScreen';
import './signup-onboarding-modal.scss';

const SignupOnboardingModal: FC<ISignupOnboardingModalProps> = ({
  isOpen,
  defaultScreen,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCloseable, setIsModalCloseable] = useState<boolean>(true);
  const [activeScreen, setActiveScreen] = useState<SignupOnboardingScreenEnum>(
    SignupOnboardingScreenEnum.DEFAULT
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetCreateShipment());
    onClose();
  };

  const onScreenChange = (
    newScreen: SignupOnboardingScreenEnum,
    screenData?: Record<string, any>
  ) => {
    setActiveScreen(newScreen);
  };

  const handleImportModalCloseable = (canClose: boolean) =>
    setIsModalCloseable(canClose);

  useEffect(() => {
    if (defaultScreen) {
      setActiveScreen(defaultScreen);
    }
  }, []);

  const renderScreen = () => {
    switch (activeScreen) {
      case SignupOnboardingScreenEnum.CREATE_SHIPMENT_SCREEN: {
        return <ShipmentCreationOnboardingModal onClose={onModalClose} />;
      }
      case SignupOnboardingScreenEnum.IMPORT_SHIPMENT_SCREEN: {
        return (
          <ImportShipmentsByTracking
            onClose={onModalClose}
            handleModalCloseable={handleImportModalCloseable}
          />
        );
      }
      case SignupOnboardingScreenEnum.PO_SCREEN: {
        return <PurchaseOrderCreationOnboardingScreen onClose={onModalClose} />;
      }
      case SignupOnboardingScreenEnum.DEFAULT:
      default: {
        return (
          <OverviewScreen
            onScreenChange={onScreenChange}
            onClose={onModalClose}
          />
        );
      }
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={onModalClose}
      width={720}
      className="signup-onboarding-modal-wrapper"
      footer={null}
      maskClosable={isModalCloseable}
      closable={isModalCloseable}
      destroyOnClose={true}
    >
      <ConfigProvider theme={APP_THEME_CONST}>
        <div className="app-theme-wrapper">{renderScreen()}</div>
      </ConfigProvider>
    </Modal>
  );
};

export default SignupOnboardingModal;
