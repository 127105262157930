import { useUTMState, formSearchParams } from 'providers/utm-provider';
import React, { FC } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useAuthState } from 'utils/authProvider';

export interface IProtectedRoutesProps {
  children: JSX.Element;
}

export const LoginPortectedRoutes: FC<IProtectedRoutesProps> = ({
  children,
}) => {
  const auth = useAuthState();
  const [params] = useSearchParams();
  const redirctTo = params.get('redirect') ?? '/dashboard';
  const campaign = useUTMState();
  const utmSearchParam = formSearchParams(campaign);

  if (auth.user === undefined) return <div />;

  return auth.isAuthenticated && auth.user?.emailVerified ? (
    <Navigate to={`${redirctTo}?${utmSearchParam}`} />
  ) : (
    children
  );
};

export const ProtectedRoutes: FC<IProtectedRoutesProps> = ({ children }) => {
  const auth = useAuthState();
  const [params] = useSearchParams();
  const redirctTo = params.get('redirect');

  if (auth.user === undefined) return <div />;
  return auth.isAuthenticated && auth.user?.emailVerified ? (
    redirctTo ? (
      <Navigate to={redirctTo} />
    ) : (
      children
    )
  ) : (
    <Navigate to="/login" />
  );
};
