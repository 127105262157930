import ContactFilter from 'app/components/ShipmentFilters/ContactListFilter';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React from 'react';
import { AssigneeFilterProps } from 'types/feature/invoice-list';

const AssigneeFilter: React.FC<AssigneeFilterProps> = ({
  onFilterChange,
  selectedFilters,
  allContacts,
}) => {
  return (
    <FilterDropdown
      filters={selectedFilters}
      renderFilterComponent={
        <ContactFilter
          layout="horizontal"
          defaultValue={selectedFilters?.assignee}
          contacts={allContacts}
          onChange={(values) => onFilterChange(values as string[], 'assignee')}
        />
      }
      buttonText="Assigned To"
      itemKey="assignee"
    />
  );
};

export default AssigneeFilter;
