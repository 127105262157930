import { Checkbox, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  ICarrierFilterForm,
  ICarrierFilterProps,
} from 'types/feature/shipments.types';

import { debounce } from 'utils/debounce';

import '../shipment-filter.scss';

const CarrierFilter: FC<ICarrierFilterProps> = ({
  onChange,
  layout,
  carriers,
  defaultValue,
}) => {
  const [form] = Form.useForm<ICarrierFilterForm>();
  const [carrierState, setCarrierState] = useState(carriers);

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        carrierFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ carrierFilter }: ICarrierFilterForm) => {
    onChange(carrierFilter);
  };

  const onSearch = (value: string) => {
    //array.filter expects a return
    return carriers.filter((l) =>
      l.name.toLowerCase().includes(value.toLowerCase())
    );
  };

  const debouncedHandler = debounce((value: string) => {
    if (value === '') return;
    setCarrierState(onSearch(value));
  }, 200);

  const onLocationSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setCarrierState(carriers);
    debouncedHandler(e.target.value);
  };

  return (
    <div>
      <Form
        form={form}
        onValuesChange={onFormChange}
        layout="vertical"
        initialValues={{ carrierFilter: defaultValue }}
        className="filters-form-wrapper"
      >
        <Input
          className="location-search-feild"
          placeholder="Search Carrier"
          onChange={onLocationSearch}
          allowClear
        />

        <Form.Item name="carrierFilter">
          <Checkbox.Group>
            <div className={`filter-input-wrapper ${layout}`}>
              {carrierState.map((carrier) => (
                <div className="input-space-wrapper" key={carrier.id}>
                  <Checkbox value={carrier.id}>{carrier.name}</Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CarrierFilter;
