import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SetStateAction } from 'react';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';

import { IMentionInput } from './messages.type';

export enum ProposalRoutingEnum {
  DIRECT = 'direct',
  INDIRECT = 'in-direct',
}

export interface IShipmentQuotesProps {
  shipment: IShipmentEntity;
}

export interface IProposalEditPorps {
  selectedProposal: IProposalEntity; /// replaced by id
  isOpen: boolean;
  onClose: () => void;
  shipment: IShipmentEntity;
  isFieldEditDisabled: boolean;
}

export interface IProposalCardProps {
  proposal: IProposalEntity;
  shipment: IShipmentEntity;
}

export type TShipmentDetailsComparisonProps = IProposalCardProps;

export interface IProposalDetailsPorps {
  selectedProposal: IProposalEntity;
  shipment: IShipmentEntity;
  isOpen: boolean;
  currency?: CurrencyEnum | null;
  onEditClick?: (proposal: IProposalEntity) => void;
  onReawardClick?: (proposal: IProposalEntity) => void;
  onClose: () => void;
}

export interface IBaseProposalActionsProps {
  proposal: IProposalEntity;
  actionButton: {
    size: SizeType;
    isBlock: boolean;
  };
}

export interface IProposalActionsProps extends IBaseProposalActionsProps {
  shipment: IShipmentEntity;
  sendRejectionEmail?: boolean;
}

export interface IDescriptionContentProps {
  title: string;
  content: string;
}

export enum AllStatusEnum {
  ALL = 'all',
}

export type IProposalStatusFilter = IQuoteStatus | AllStatusEnum;

export interface IProposalsProps {
  shipment: IShipmentEntity;
  onSubmitProposal: () => Promise<void>;
}

export interface IProposalProviderProps {
  children: JSX.Element;
}

export type IProposalContextType = {
  selectedProposal: IProposalEntity | null;
  showProposalDetailsModal: boolean;
  setSelectedProposal: React.Dispatch<SetStateAction<IProposalEntity | null>>;
  setShowProposalDetailsModal: React.Dispatch<SetStateAction<boolean>>;
};

export interface INotificationActionProps {
  isProposalNotifEnabled: boolean;
  onProposalNotifChange: (isProposalNotifEnabled: boolean) => void;
  proposalParams: {
    id: string;
    email: string;
  };
}

export interface IProposalImportantDetailsProps {
  proposal: IProposalEntity;
  diffKeys?: Record<keyof IProposalEntity, any>;
}

export type TProposalShipmentItemsPropos = IProposalImportantDetailsProps;

export type MapArrayWithDifferences = <T>(
  items: T[],
  addedDiff?: Partial<Record<string, any>>,
  updatedDiff?: Partial<Record<string, any>>,
  deletedDiff?: Partial<Record<string, any>>
) => T[];

export enum ProposalDiffTypeEnum {
  ADDED = 'added',
  UPDATED = 'updated',
  DELETED = 'deleted',
  UNTOUCHED = 'untouched',
}

export interface IProposalDiffViewerProps {
  proposal: IProposalEntity;
  isActionsEnabled: boolean;
  // TODO: Hot fix to keep proposal currency consisten for everyone
  proposalCurrency: CurrencyEnum | null;
}

export interface IProposalSectionsBasePropos {
  proposal: IProposalEntity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  diffKeys?: Record<any, any>;
}

export interface IHeaderSectionProps extends IProposalSectionsBasePropos {
  onEditClick?: (proposal: IProposalEntity) => void;
  isEditEnabled: boolean;
}

export interface ITotalFeeSectionProps extends IProposalSectionsBasePropos {
  proposal: IProposalEntity;
  currency?: CurrencyEnum | null;
  className?: string;
}

export interface ICommentSectionProps extends IProposalSectionsBasePropos {
  proposal: IProposalEntity;
  onAddComment: (message: IMentionInput) => void;
  isLoading: boolean;
}
