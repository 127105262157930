import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { notification } from 'antd';
import { API_URL } from 'api/common/config';
import { useEffect } from 'react';
import { IUserEntity } from 'types/entities/user.entity';

import { getAuthToken } from 'utils/auth-storage';

import { beamsClient } from './pusher.utils';

export const usePusher = (user: IUserEntity | undefined) => {
  useEffect(() => {
    const userToken = getAuthToken();
    if (user?.id && userToken) {
      try {
        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: `${API_URL}/app-notifications/token`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        beamsClient()
          ?.start()
          .then(() => beamsClient()?.setUserId(user.id, beamsTokenProvider))
          .catch((error) => {
            if (error.name !== 'NotAllowedError') {
              console.log('error', error);
            } else {
              Notification.requestPermission();
            }
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        notification.error({
          message: 'Notification Error',
          description: error?.message,
        });
      }
    }
  }, [user]);
};
