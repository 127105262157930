import {
  IContractEntity,
  IMatchContractRatesReq,
} from 'types/entities/contract.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';

export const createDefaultShipmentFromContract: (
  contract: IContractEntity,
  extraData: IMatchContractRatesReq
) => [Partial<IShipmentEntity>, string] = (
  contract: IContractEntity,
  extraData: IMatchContractRatesReq
) => {
  const { serviceMode, costHeads, shipmentType, reference } = contract;

  const { origin, destination } = costHeads[0];

  const { containerType, cargoReadyDate, quantity, volume, weight } = extraData;
  return [
    {
      serviceMode,
      origin: { ...origin, id: undefined },
      destination: { ...destination, id: undefined },
      cargoReadyDate,
      shipmentType,
      cargoUnits: [{ containerType, quantity, volume, weight }],
      additionalInfo: `**This shipment is booked based on contract ${
        reference ?? ''
      }`,
    },
    contract.id,
  ];
};
