const isLocalhost = /localhost|0\.0\.0\.0|127\.0\.0\.0\.1/i.test(
  document.location.hostname
);

const isStaging = /st/i.test(document.location.hostname);
const isDevTesting = /dev/i.test(document.location.hostname);

const HOST = window.location.host;
const isTesting = isStaging || isDevTesting || isLocalhost;

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_VERSION = 'v1';
const API_URL = `${BASE_URL}/api/${API_VERSION}`;

const CONVERSATION_API_URL = process.env.REACT_APP_CONVERSATION_API_URL;
const PUSER_SECRET_KEY = process.env.REACT_APP_PUSER_SECRET_KEY || '';
const PUSHER_INSTANCE_ID = process.env.REACT_APP_PUSHER_INSTANCE_ID || '';
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const DOPT_BLOCK_API_KEY = process.env.REACT_APP_DOPT_BLOCK_API_KEY || '';
const DOPT_API_KEY = process.env.REACT_APP_DOPT_API_KEY || '';

const GCErrorLoggingConfig = {
  key: process.env.REACT_APP_GC_ERROR_LOGGING_KEY,
  projectId: process.env.REACT_APP_GC_ERROR_LOGGING_PROJECT,
  service: process.env.REACT_APP_GC_ERROR_LOGGING_SERVICE,
};

const targetTimeZones = ['Asia/Beijing', 'Asia/Shanghai'];
const stagingTimeZones = ['Asia/Beijing', 'Asia/Shanghai'];

const isUserWithinTargetTimeZone = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return isTesting
    ? stagingTimeZones.includes(userTimeZone)
    : targetTimeZones.includes(userTimeZone);
};

const commitSha = process.env.REACT_APP_SENTRY_RELEASE;
const sentryEnv = process.env.REACT_APP_SENTRY_ENV;
const sentryRelease =
  commitSha && sentryEnv
    ? `${commitSha.substring(0, 7)}-${sentryEnv}`
    : undefined;

export {
  API_URL,
  CONVERSATION_API_URL,
  GOOGLE_MAPS_API_KEY,
  HOST,
  isTesting,
  GCErrorLoggingConfig,
  isUserWithinTargetTimeZone,
  targetTimeZones,
  PUSER_SECRET_KEY,
  PUSHER_INSTANCE_ID,
  DOPT_BLOCK_API_KEY,
  DOPT_API_KEY,
  isLocalhost,
  sentryRelease,
};
