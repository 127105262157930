import { Col, Form, Input, Row, Skeleton } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import DescriptionList from 'app/design-system/DescriptionList';
import React, { FC } from 'react';
import { IReferencesProps } from 'types/feature/proposals.types';

const References: FC<IReferencesProps> = ({ proposal, form, shipment }) => {
  return (
    <DashboardCard headerTitle="References" large fitContent>
      {!shipment ? (
        <Skeleton active />
      ) : (
        <Row gutter={[24, 24]} justify={'start'}>
          <Col xs={24} sm={12} md={6}>
            <DescriptionList
              className="mx-0"
              label="Shipment Reference"
              description={shipment.shipmentReference ?? ''}
              isVertical
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <DescriptionList
              className="mx-0"
              label="RFQ ID"
              description={`RFQ-${proposal.slug}`}
              isVertical
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{ proposalReference: proposal.proposalReference }}
            >
              <Form.Item name="proposalReference" label="Forwarder Reference">
                <Input placeholder="Forwarder Reference Number" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </DashboardCard>
  );
};

export default References;
