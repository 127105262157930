import {
  Button,
  Checkbox,
  Dropdown,
  Row,
  message,
  notification,
  theme,
} from 'antd';
import { useSetUserTableSettingMutation } from 'api/user-table-columns';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import './checklist.scss';

const { useToken } = theme;

interface ICheckboxListProps {
  options: { value: string; label: string }[];
  selectedItems: string[];
  onChange: (selected: string[]) => void;
  tableName: UserTablesEnum;
}

const CheckboxList: FC<ICheckboxListProps & { children?: ReactNode }> = ({
  children,
  options,
  selectedItems,
  onChange,
  tableName,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { token } = useToken();

  const [setUserTableSetting, { isSuccess: isNewSettingSaved }] =
    useSetUserTableSettingMutation();
  useEffect(() => {
    if (isNewSettingSaved)
      message.success(
        `Settings for ${tableName} table are successfully saved  !`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewSettingSaved]);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const onSaveClick = async () => {
    try {
      const values: Record<string, boolean> = {};
      selectedItems.forEach((col) => {
        values[col] = true;
      });
      await setUserTableSetting({
        tableName,
        setting: JSON.stringify(values),
      });
      setOpen(false);
    } catch (error: unknown) {
      notification.error({
        message: 'Something went wrong while save table settings !',
      });
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const renderDropdownContent = () => (
    <div style={contentStyle} className="checklist-wrapper">
      <Checkbox.Group
        value={selectedItems}
        options={options}
        onChange={(values) => onChange(values as string[])}
      />
      <Row justify="end" style={{ margin: '5px 0px' }}>
        <Button type="primary" size="small" onClick={onSaveClick}>
          Save
        </Button>
      </Row>
    </div>
  );

  return (
    <>
      <Dropdown
        open={open}
        trigger={['click']}
        className="checklist-dropdown"
        overlayClassName="checklist-dropdown-overlay"
        onOpenChange={handleOpenChange}
        dropdownRender={renderDropdownContent}
      >
        {children}
      </Dropdown>
    </>
  );
};

export default CheckboxList;
