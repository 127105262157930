import { ColumnsType } from 'antd/es/table';
import { IOnHoldInvoice } from 'types/entities/invoice.entity';

import { formatAmount } from 'utils/monetary-utils';

export const columns: ColumnsType<IOnHoldInvoice> = [
  {
    title: 'Vendor',
    sorter: true,
    dataIndex: 'vendor',
    key: 'vendor',
  },
  {
    title: 'Count',
    sorter: true,
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Total Cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    render: (_, record) => formatAmount(record.totalCost, record.currency),
  },
];
