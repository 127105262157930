import { Typography } from 'antd';
import React, { FC } from 'react';

import './description-title.scss';

const { Text, Title } = Typography;

export interface IDescriptionTitleProps {
  title: string | number | JSX.Element;
  description: string | number | JSX.Element;
  invert?: boolean;
}

const DescriptionTitle: FC<IDescriptionTitleProps> = ({
  title,
  description,
}) => {
  return (
    <div className="description-title-wrapper">
      <Title className="title" level={5} ellipsis={{ rows: 2 }}>
        {title}
      </Title>
      <Text className="description"> {description} </Text>
    </div>
  );
};

export default DescriptionTitle;
