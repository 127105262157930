export enum IQuoteStatus {
  REQUESTED = 'requested',
  SUBMITTED = 'submitted',
  REVOKED = 'revoked',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export enum ProposalAmendStatusEnum {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}
