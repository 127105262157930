import { Row, Col, Typography } from 'antd';
import React, { FC } from 'react';
import {
  IProposalMatchingProps,
  InvoiceTableViewEnum,
} from 'types/feature/invoice.types';

import InvoiceHeader from '../InvoiceHeader';
import InvoiceMatchingTable from '../InvoiceMatchingTable';
import ProposalPreview from '../ProposalPreview';
import './proposal-matching.scss';

const ProposalMatching: FC<IProposalMatchingProps> = ({
  invoice,
  proposalCurrency,
  onStepChange,
}) => {
  const onContinueClick = () => {
    onStepChange(3);
  };

  const onBackClick = () => {
    onStepChange(1);
  };

  return (
    <>
      <Row gutter={[52, 52]} className="pdf-matchin-wrapper">
        <Col span={12} className="invoice-table-wrapper">
          <InvoiceHeader invoice={invoice} />
          <InvoiceMatchingTable
            invoice={invoice}
            tableView={InvoiceTableViewEnum.PROPOSAL_MATCHING}
            onContinueClick={onContinueClick}
            onBackClick={onBackClick}
            isItemDetailEditabale={false}
          />
        </Col>
        <Col span={12} className="invoice-preview-wrapper">
          {invoice.proposal ? (
            <ProposalPreview
              proposal={invoice.proposal}
              proposalCurrency={proposalCurrency}
            />
          ) : (
            <div className="empty-wrapper">
              <div className="empty-proposal-illustration" />
              <Typography.Title level={5} className="my-0">
                Proposed Quote
              </Typography.Title>
              <Typography.Text className="empty-proposal-content">
                Quote for this shipment is not available. <br /> Continue to
                next step.
              </Typography.Text>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProposalMatching;
