import { INotification } from 'types/entities/notification.entity';

export const filterNotifications = (
  notifications: INotification[],
  showUnreadNotificationsOnly: boolean
): INotification[] => {
  return notifications.filter(
    (notification: INotification) =>
      !showUnreadNotificationsOnly || !notification.read
  );
};

export const cleanNotificationText = (message: string): string => {
  message = message.replace(/\(\s*\)/g, '');
  message = message.charAt(0).toUpperCase() + message.slice(1);
  return message;
};
