import {
  CaretDownFilled,
  CheckOutlined,
  DeleteFilled,
  HourglassOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Dropdown,
  Empty,
  MenuProps,
  Modal,
  Space,
  Spin,
  Table,
  Timeline,
  message,
} from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import {
  useDeleteContainerMutation,
  useGetContainersQuery,
  useUpdateContainerMutation,
} from 'api/container';
import { useGetUserTableSettingQuery } from 'api/user-table-columns';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import { renderContainerTrackingEvent } from 'app/components/Tracking/TrackingList/TrackingEvent';
import TableViewFilter from 'app/design-system/TableViewFilter';
import React, { FC, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { IContainerEntity } from 'types/entities/container.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { UserRoleEnum } from 'types/entities/user.entity';
import {
  ContainerModalTypeEnum,
  ContainerStatusEnum,
} from 'types/feature/container.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';
import { formatShipmentState, splitState } from 'utils/format-fields';

import AddContainerModal from '../AddContainerModal';
import {
  containersColumnConst,
  defaultSelectedColumns,
} from './Containers.const';
import './constianers.scss';

interface IContainersProps {
  shipment: IShipmentEntity;
}

const { confirm } = Modal;

const Containers: FC<IContainersProps> = ({ shipment }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableViewFilter, setTableViewFilter] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<
    ColumnsType<IContainerEntity>
  >([]);
  const [isTableViewOpen, setIsTableViewOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { data: containerData, isLoading } = useGetContainersQuery(
    shipment.id,
    { skip: !shipment, refetchOnMountOrArgChange: true }
  );
  const [deleteContainer] = useDeleteContainerMutation();

  const isCargoOwner = !!(
    shipment?.currentUserRole === UserRoleEnum.CARGO_OWNER
  );
  const isForwarder = !!(shipment?.currentUserRole === UserRoleEnum.FORWARDER);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data: tableSettingData, isSuccess: isSettingRetrieved } =
    useGetUserTableSettingQuery({
      tableName: UserTablesEnum.containersTable,
    });
  const items: MenuProps['items'] = Object.entries(ContainerStatusEnum).map(
    ([key, value]) => ({
      key: value,
      label: splitState(value),
    })
  );
  const [updateContainer, { isLoading: isUpdateLoading }] =
    useUpdateContainerMutation();

  const updateStatus = async (id: string, status: ContainerStatusEnum) => {
    try {
      await updateContainer({
        containerId: id,
        status,
        shipmentId: shipment.id,
      }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'update status error' });
    }
  };

  const onDeleteContainers = (ids: string[], isMultipleContainers = false) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: `Are you sure you want to delete ${
        isMultipleContainers ? 'the selected containers ?' : 'this container'
      }`,
      onOk: async () => {
        deleteContainer({ containerIds: ids, shipmentId: shipment.id })
          .unwrap()
          .then(
            () => {
              message.success(
                `The container${
                  isMultipleContainers ? 's were' : ' was'
                }  successfully removed !`
              );
            },
            (error) =>
              displayErrors(error, { title: 'Delete Containers Error' })
          );
      },
    });
  };
  const handleExpandCollapse = (recordId: string) => {
    setExpandedRowKeys((prevState) => {
      if (prevState.includes(recordId)) {
        return prevState.filter((id) => id !== recordId);
      } else {
        return [...prevState, recordId];
      }
    });
  };

  const columns: ColumnsType<IContainerEntity> = [
    ...containersColumnConst,
    ...(!isViewer(shipment.currentCollaborator.accessLevel)
      ? [
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record: IContainerEntity) => (
              <Dropdown
                disabled={isUpdateLoading || record.events.length < 1}
                menu={{
                  items,
                  onClick: ({ key }) =>
                    updateStatus(record.id, key as ContainerStatusEnum),
                }}
              >
                <Spin
                  indicator={<LoadingOutlined spin />}
                  spinning={isUpdateLoading}
                >
                  {record.events.length > 0
                    ? formatShipmentState(record.status, true)
                    : formatShipmentState('Not_Active')}
                </Spin>
              </Dropdown>
            ),
          },
          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_: string, record: IContainerEntity, index: number) => (
              <Space>
                <Button
                  danger
                  type="text"
                  icon={<DeleteFilled />}
                  onClick={() => onDeleteContainers([record.id])}
                />
                <Button
                  type="text"
                  disabled={record.events.length < 1}
                  icon={<CaretDownFilled />}
                  onClick={() => {
                    handleExpandCollapse(record.id);
                  }}
                />
              </Space>
            ),
            width: 100,
            align: 'center',
          } as ColumnType<IContainerEntity>,
        ]
      : [
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record: IContainerEntity) =>
              formatShipmentState(record.status, false),
          },
          {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_: string, record: IContainerEntity, index: number) => (
              <Space>
                <Button
                  type="text"
                  icon={<CaretDownFilled />}
                  onClick={() => {
                    handleExpandCollapse(record.id);
                  }}
                />
              </Space>
            ),
            width: 100,
            align: 'center',
          } as ColumnType<IContainerEntity>,
        ]),
  ];
  const computeTableColumnsWithFilters = (
    selectedKeys: string[],
    columns: ColumnsType<IContainerEntity>
  ) => {
    setSelectedColumns(
      filterColumns(columns, selectedKeys, defaultSelectedColumns)
    );
    setTableViewFilter(selectedKeys);
  };
  useEffect(() => {
    if (isSettingRetrieved && tableSettingData) {
      computeTableColumnsWithFilters(
        tableSettingData.length === 0
          ? defaultSelectedColumns
          : tableSettingData,
        columns
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSettingData, isSettingRetrieved]);

  const tableViewOnChange = (selectedKeys: string[]) => {
    computeTableColumnsWithFilters(selectedKeys, columns);
  };
  const onDeleteSelectedContainers = () => {
    onDeleteContainers(selectedRowKeys as string[], true);
  };

  const onOpenTableView = () => {
    setIsTableViewOpen(true);
  };
  const onCloseTableView = () => {
    setIsTableViewOpen(false);
  };

  const handleExpand = (expanded: boolean, record: IContainerEntity) => {
    if (expanded) {
      setExpandedRowKeys((prevState) => [...prevState, record.id]);
    } else {
      setExpandedRowKeys((prevState) =>
        prevState.filter((id) => id !== record.id)
      );
    }
  };

  const renderExpandRow = (record: IContainerEntity) => (
    <>
      <Timeline
        className="time-line-wrapper"
        items={record.events.map((item, index) => {
          return {
            dot: item.actual ? <CheckOutlined /> : <HourglassOutlined />,
            color: item.actual ? 'blue' : 'gray',
            children: renderContainerTrackingEvent({ item }),
            className: item.actual
              ? `tracking-active tracking-item-${index}`
              : `tracking-item-${index}`,
          };
        })}
      />
    </>
  );

  const expandable = {
    showExpandColumn: false,
    onExpand: handleExpand,
    expandedRowKeys: expandedRowKeys,
    expandedRowRender: renderExpandRow,
  };

  return (
    <div>
      {containerData ?? [] ? (
        <Card loading={isLoading}>
          <div className="container-header-wrapper">
            {(isCargoOwner || isForwarder) && (
              <Space>
                <FilterDropdown
                  filters={{ tableView: tableViewFilter }}
                  onOpenTableView={onOpenTableView}
                  onCloseTableView={onCloseTableView}
                  isTableViewOpen={isTableViewOpen}
                  renderFilterComponent={
                    <TableViewFilter
                      layout="vertical"
                      defaultValue={tableViewFilter}
                      tableName={UserTablesEnum.containersTable}
                      columns={columns}
                      onChange={tableViewOnChange}
                      onCloseTableView={onCloseTableView}
                    />
                  }
                  buttonText="Table Adjust"
                  itemKey="tableView"
                />
                {!isViewer(shipment.currentCollaborator.accessLevel) && (
                  <>
                    <Button
                      disabled={selectedRowKeys.length < 1}
                      onClick={onDeleteSelectedContainers}
                    >
                      Delete All
                    </Button>
                    <AddContainerModal
                      shipmentType={shipment.shipmentType.title}
                      shipmentId={shipment.id}
                      shipmentSlug={shipment.slug}
                      containerModalType={ContainerModalTypeEnum.CONTAINER}
                      showConfirmModal={!!shipment.mbl}
                    />
                  </>
                )}
              </Space>
            )}
          </div>
          <Table
            expandable={expandable}
            className="container-table"
            rowSelection={rowSelection}
            rowKey={(record: IContainerEntity) => record.id}
            columns={selectedColumns}
            dataSource={containerData}
          />
        </Card>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export const ContainersRouteWrapper: FC = () => {
  const props = useOutletContext<IContainersProps>();
  return <Containers {...props} />;
};

export default Containers;
