import {
  IShipmentCreateArray,
  IShipmentMetadata,
} from 'types/entities/metadata.entity';

export const addShipmentTags = (
  values,
  rows,
  companyTags,
  shipment
): IShipmentMetadata[] => {
  const transformedData: {
    type: string;
    title: string;
    value: string[];
    colorCode: any; //TODO: fix the type
  }[] = [];

  const maxIndex = Math.max(
    ...Object.keys(values)
      .filter((key) => key.startsWith('title-'))
      .map((key) => parseInt(key.slice('title-'.length), 10))
  );

  for (let i = 0; i <= maxIndex; i++) {
    const type = rows[i].type;
    const title = values[`title-${i}`] || '';
    const value = values[`values-${i}`] || [];
    const colorCode = values[`colorCode-${i}`] || undefined;

    transformedData.push({
      type,
      title,
      value,
      colorCode,
    });
  }
  const addTagArray = transformedData.filter((obj) => obj.type === 'addTag');

  const shipmentTagsArray: Array<IShipmentMetadata> = [];
  for (let i = 0; i < addTagArray.length; i++) {
    const result = companyTags?.find(
      (item) => item.metadataKey === addTagArray[i].title
    );

    const companyMetadataId = result?.id;

    if (companyMetadataId)
      shipmentTagsArray.push({
        companyMetadataId,
        metadataValue: addTagArray[i].value,
        shipmentId: shipment?.id,
      });
  }
  return shipmentTagsArray;
};

export const createShipmentTags = (values, rows): IShipmentCreateArray[] => {
  const tags: {
    type: string;
    title: string;
    value: string[];
    colorCode: any; //TODO: fix the type
  }[] = [];

  const maxIndex = Math.max(
    ...Object.keys(values)
      .filter((key) => key.startsWith('title-'))
      .map((key) => parseInt(key.slice('title-'.length), 10))
  );

  for (let i = 0; i <= maxIndex; i++) {
    const type = rows[i].type;
    const title = values[`title-${i}`] || '';
    const value = values[`values-${i}`] || [];
    const colorCode = values[`colorCode-${i}`] || undefined;

    tags.push({
      type,
      title,
      value,
      colorCode,
    });
  }

  const createTagArray = tags.filter((obj) => obj.type === 'createTag');

  return createTagArray;
};
