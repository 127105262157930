import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IUTMData } from 'types/feature/analytics-events.type';

type UTMContextType = {
  params: IUTMData;
};
interface IUTMProviderProps {
  children: JSX.Element;
}

export const UTMContext = createContext<UTMContextType>({
  params: {},
});

export const formSearchParams = (utmData: IUTMData) => {
  let param = '';
  Object.keys(utmData).forEach((key) => {
    if (utmData[key])
      param +=
        key === 'name'
          ? `utm_campaign=${utmData[key]}`
          : `utm_${key}=${utmData[key]}&`;
  });

  return param;
};

export const UTMContextProvider: FC<IUTMProviderProps> = (props) => {
  const [utmData, setUtmData] = useState<IUTMData>({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const params: IUTMData = {
        source: searchParams.get('utm_source') || undefined,
        medium: searchParams.get('utm_medium') || undefined,
        content: searchParams.get('utm_content') || undefined,
        term: searchParams.get('utm_term') || undefined,
        name: searchParams.get('utm_campaign') || undefined,
      };
      setUtmData(params);
    }
  }, []);

  return <UTMContext.Provider value={{ params: utmData }} {...props} />;
};

export const useUTMState = () => {
  const UTM = useContext(UTMContext);

  return { ...UTM.params };
};
