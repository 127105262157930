import { initials } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';
import React from 'react';

import './profile-image.scss';

interface ProfileImageProps {
  name: string;
  photoUrl?: string | null;
  size?: number;
  className?: string;
  wrapperClassName?: string;
}

const getAvatar = (seed: string, size: number) =>
  createAvatar(initials, {
    seed,
    size,
    radius: 50,
  });

const ProfileImage: React.FC<ProfileImageProps> = ({
  photoUrl,
  name,
  size = 30,
  className,
  wrapperClassName,
}) => {
  return (
    <div className={`profile-image-wrapper ${wrapperClassName}`}>
      {photoUrl ? (
        <img
          src={photoUrl}
          alt={name}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: '50%',
          }}
          className={className}
        />
      ) : (
        <img
          src={getAvatar(name, size).toDataUriSync()}
          alt="avatar"
          className={className}
        />
      )}
    </div>
  );
};

export default ProfileImage;
