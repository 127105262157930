import Icon, {
  CaretDownOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Space,
  message,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/es/table/interface';
import {
  useDeleteInvoiceByIdMutation,
  useDownloadInvoiceSheetMutation,
  useLazyGetFilesByInvoiceIdQuery,
  useUpdateAllInvoicesMutation,
  useUpdateMultipleInvoiceStatusesMutation,
} from 'api/invoice';
import { useGetUserTableSettingQuery } from 'api/user-table-columns';
import InvoiceStatusSwitch from 'app/components/Invoice/InvoiceStatusSwitch';
import { mappedInvoiceStatuses } from 'app/components/Invoice/InvoiceStatusSwitch/helpers/helper';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import AppTabs from 'app/design-system/AppTabs';
import DashboardCard from 'app/design-system/DashboardCard';
import TableViewFilter from 'app/design-system/TableViewFilter';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  IInvoiceEntity,
  IUpdateInvoicesRequestBody,
} from 'types/entities/invoice.entity';
import { IInvoiceFilters } from 'types/feature/invoice-list';
import { InvoiceStatusEnum } from 'types/feature/invoice.types';
import { SearchAndSortParamsInvoice } from 'types/feature/pagination.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import {
  invoiceDeleteEvent,
  invoiceDownloadEvent,
  invoicesListViewEvent,
} from 'utils/analytics-events';
import { downloadExcelFileFromBuffer } from 'utils/download-excel-file';
import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/bin-icon-filled-grey.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon-filled-grey.svg';
import { ReactComponent as PdfIcon } from '../../../../assets/icons/pdf-icon-filled.svg';
import InvoiceFilters from '../InvoiceFilters/InvoiceFilters';
import { searchHiglightedFileUrl } from '../InvoiceMatching';
import InvoiceAnalytics from '../invoiceAnalytics';
import InvoicesListTable from './InvoicesListTable';
import {
  defaultPaginationAndSorting,
  defaultSelectedColumns,
  invoiceListTable,
} from './InvoicesListTable/invoices-list.const';

const { Search } = Input;
const { confirm } = Modal;

const InvoicesTabs: FC = () => {
  //State

  const [defaultKey, setTabKey] = useState<string>();
  const [selectedFilters, setSelectedFilters] = useState<IInvoiceFilters>({});
  const [showAnalytics, setShowAnalytics] = useState<boolean>(true);
  const [tableViewFilter, setTableViewFilter] = useState<string[]>([]);
  const [paginationAndSortingState, setPaginationAndSortingState] =
    useState<SearchAndSortParamsInvoice>(defaultPaginationAndSorting);
  const [selectedColumns, setSelectedColumns] = useState<
    ColumnsType<IInvoiceEntity>
  >([]);
  const [isStatusFiltered, setIsStatusFiltered] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [isTableViewOpen, setIsTableViewOpen] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');

  const user = useSelector((state: RootState) => state.user.user);

  // Queries
  const [deleteInvoiceById, { isLoading: isDeleting }] =
    useDeleteInvoiceByIdMutation();
  const [updateInvoiceStatuses, { isLoading: isMultipleInvoiceUpdateLoading }] =
    useUpdateMultipleInvoiceStatusesMutation();
  const [downloadExcelFile, { isLoading: isDownloading }] =
    useDownloadInvoiceSheetMutation();

  const [getFilesById, { isLoading: isFileLoading }] =
    useLazyGetFilesByInvoiceIdQuery();
  const { data: tableSettingData, isSuccess: isSettingRetrieved } =
    useGetUserTableSettingQuery({
      tableName: UserTablesEnum.invoicesTable,
    });
  const [updateAllInvoices, { data: updatedInvoicesData, isLoading }] =
    useUpdateAllInvoicesMutation();

  //Methods
  const getUpdatedInvoices = async (params: IUpdateInvoicesRequestBody) => {
    try {
      return updateAllInvoices(params);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'fetching Invoices Error' });
    }
  };

  const handleMultpleInvoiceStatusUpdate = async (
    status: InvoiceStatusEnum
  ) => {
    try {
      await updateInvoiceStatuses({
        invoiceIds: selectedInvoices,
        status,
      }).unwrap();

      getUpdatedInvoices({
        params: { ...paginationAndSortingState },
        body: { ...selectedFilters },
      });

      message.success('Invoice status changed successfuly');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 403) {
        notification.warning({
          message: 'Error',
          description: error.data.message,
        });
        getUpdatedInvoices({
          params: paginationAndSortingState,
          body: selectedFilters,
        });
      } else {
        displayErrors(error, { title: 'Error on updating Invoice Status' });
      }
    }
  };

  const onDeleteInvoice = async (invoiceId: string, shipmentId: string) => {
    try {
      await deleteInvoiceById(invoiceId).unwrap();
      invoiceDeleteEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        shipment_id: shipmentId,
        invoice_id: invoiceId,
      });

      getUpdatedInvoices({
        params: paginationAndSortingState,
        body: selectedFilters,
      });

      message.success('Invoice deleted successfuly');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on deleting invoice' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeStatusClick = (obj: any) => {
    const status: InvoiceStatusEnum = obj.key
      ? obj.key
      : InvoiceStatusEnum.ACCEPTED;

    confirm({
      title: 'Confirmation',
      okText:
        status === InvoiceStatusEnum.ACCEPTED
          ? 'Approve all Invoices'
          : 'Change Status',
      okType: 'primary',
      icon: null,
      content:
        status === InvoiceStatusEnum.ACCEPTED ? (
          <div>
            <p>Are you sure ? </p>
            <p>
              <strong>Note:</strong> Are you sure you want to accecpt all
              invoices ?
            </p>
          </div>
        ) : (
          <div>
            <p>Are you sure ? </p>
            <p>
              <strong>Note:</strong> Are you sure you want to update the status
              of all selected invoices ?
            </p>
          </div>
        ),
      onOk: async () => {
        try {
          await handleMultpleInvoiceStatusUpdate(status);
        } catch {
          notification.error({
            message: 'Error',
            description: 'Oops!, Unable to update invoices.',
          });
        }
        return;
      },
    });
  };

  const onDownloadExcelFile = async () => {
    try {
      const resp = await downloadExcelFile({
        invoiceIds: isSelectedAll ? [] : selectedInvoices,
        filters: selectedFilters,
        keyword: searchedValue,
      }).unwrap();

      // Convert the buffer data array to a Uint8Array
      const bufferData = new Uint8Array(resp.data);
      const fileName = `Invoices_${dayjs().format('DD-MM-YYYY')}.xlsx`;

      // Download the file
      downloadExcelFileFromBuffer(bufferData, fileName);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on downloading file' });
    } finally {
      setSelectedInvoices([]);
    }
  };
  const onAttachmentClick = async (
    invoiceId: string,
    status: InvoiceStatusEnum,
    shipmentId: string
  ) => {
    try {
      const files = (await getFilesById(invoiceId).unwrap()).data;
      const fileURL = searchHiglightedFileUrl(files, status);
      window.open(fileURL, '_blank');
      invoiceDownloadEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        shipment_id: shipmentId,
        invoice_id: invoiceId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on downloading file' });
    }
  };

  // rowSelection object indicates the need for row selection
  const rowSelection: TableRowSelection<IInvoiceEntity> = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IInvoiceEntity[]
    ) => {
      if (selectedRowKeys.length >= paginationAndSortingState.limit) {
        setIsSelectedAll(true);
      } else {
        setIsSelectedAll(false);
      }
      setSelectedInvoices(selectedRowKeys as string[]);
    },
    selectedRowKeys: selectedInvoices,
  };

  const columns: ColumnsType<IInvoiceEntity> = [
    ...invoiceListTable,
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return (
          <InvoiceStatusSwitch
            updateInvoicesArgs={{
              params: { ...paginationAndSortingState },
              body: { ...selectedFilters },
            }}
            getUpdatedInvoices={getUpdatedInvoices}
            invoice={record}
          />
        );
      },
    },
    {
      title: 'Attachment',
      dataIndex: 'attachment',
      key: 'attachment',
      render: (_, record) => {
        return (
          <Button
            loading={isFileLoading}
            type="text"
            onClick={() =>
              onAttachmentClick(record.id, record.status, record.shipmentId)
            }
          >
            <Icon component={PdfIcon} className="pdf-icon-filled" />
          </Button>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Link
              to={`/shipments/SH-${record.shipment.slug}/invoice/${record.id}`}
              target="_blank"
            >
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <Button
              icon={<DeleteIcon />}
              onClick={() => onDeleteInvoice(record.id, record.shipmentId)}
              type="text"
              loading={isDeleting}
            />
          </Space>
        );
      },
    },
  ];

  const computeTableColumnsWithFilters = (
    selectedKeys: string[],
    columns: ColumnsType<IInvoiceEntity>
  ) => {
    setSelectedColumns(
      filterColumns(columns, selectedKeys, defaultSelectedColumns)
    );
    setTableViewFilter(selectedKeys);
  };

  const tableViewOnChange = (selectedKeys: string[]) => {
    computeTableColumnsWithFilters(selectedKeys, columns);
  };

  const onFilterDrawerChange = (value) => {
    setSelectedFilters(value);
  };

  const onResetClick = (isStatusFiltered: boolean) => {
    isStatusFiltered
      ? setSelectedFilters({ status: selectedFilters.status })
      : setSelectedFilters({}); //if status is filtered by changing tabs reset all filters except status
  };

  const onFilterChange = (value: string[], name: string) => {
    if (!value || value.length === 0) {
      // If value is empty, create a new object without the key
      const newFilters = { ...selectedFilters };
      delete newFilters[name];
      setSelectedFilters(newFilters);
    } else {
      setSelectedFilters({ ...selectedFilters, [name]: value });
    }
  };

  const handleTabClick = (key: string) => {
    setTabKey(key);
    setSelectedInvoices([]);
    const status: InvoiceStatusEnum[] =
      key === 'drafts'
        ? [InvoiceStatusEnum.DRAFT, InvoiceStatusEnum.ON_HOLD]
        : key === 'pending'
        ? [InvoiceStatusEnum.IN_REVIEW]
        : [];
    if (status.length > 0) {
      setIsStatusFiltered(true);
      setSelectedFilters({
        ...selectedFilters,
        status,
      });
    } else {
      setIsStatusFiltered(false);
      setSelectedFilters({});
    }
  };

  const handleInputOnChange = (value: string) => {
    setSearchedValue(value);
    if (!value) {
      setPaginationAndSortingState({
        ...paginationAndSortingState,
        keyword: '',
      });
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    if (searchedValue)
      setPaginationAndSortingState({
        ...paginationAndSortingState,
        page: 1,
        keyword: value,
      });
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IInvoiceEntity> | SorterResult<IInvoiceEntity>[]
  ) => {
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    setPaginationAndSortingState({
      ...paginationAndSortingState,
      page: pagination.current ?? 1,
      limit: pagination.pageSize ?? 25,
      sortBy: singleSorter.field?.toString() ?? 'invoiceNo',
      sortOrder: singleSorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };
  const renderInvoiceTable = () => (
    <InvoicesListTable
      handleTableChange={handleTableChange}
      selectedColumns={selectedColumns}
      rowSelection={rowSelection}
      updatedInvoicesData={updatedInvoicesData}
      isLoading={isLoading}
      paginationAndSortingState={paginationAndSortingState}
    />
  );
  const onToggleAnalytics = () => {
    setShowAnalytics(!showAnalytics);
  };

  const onOpenTableView = () => {
    setIsTableViewOpen(true);
  };
  const onCloseTableView = () => {
    setIsTableViewOpen(false);
  };

  //Hooks

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      invoicesListViewEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
      });
    }
  }, [user]);
  useEffect(() => {
    getUpdatedInvoices({
      params: { ...paginationAndSortingState, page: 1 },
      body: { ...selectedFilters },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    getUpdatedInvoices({
      params: { ...paginationAndSortingState },
      body: { ...selectedFilters },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationAndSortingState]);
  useEffect(() => {
    if (isSettingRetrieved && tableSettingData) {
      computeTableColumnsWithFilters(
        tableSettingData.length === 0
          ? defaultSelectedColumns
          : tableSettingData,
        columns
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSettingData, isSettingRetrieved]);

  return (
    <div>
      <DashboardCard
        headerTitle="Analytics"
        rightAccessory={
          <Button
            onClick={onToggleAnalytics}
            icon={showAnalytics ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          >
            {showAnalytics ? `Hide Analytics` : 'Show Analytics'}
          </Button>
        }
      >
        {showAnalytics && <InvoiceAnalytics />}
      </DashboardCard>
      <DashboardCard
        headerTitle="Invoices"
        fitContent
        className="invoices-dashbaord-wrapper"
      >
        <Space className="filter-wrapper">
          <InvoiceFilters
            onFilterChange={onFilterChange}
            selectedFilters={selectedFilters}
            onReset={() => onResetClick(isStatusFiltered)}
            onFilterDrawerChange={onFilterDrawerChange}
            isStatusFiltered={isStatusFiltered}
          />

          <Space>
            <Search
              placeholder="search"
              allowClear
              onChange={(event) => handleInputOnChange(event.target.value)}
              onSearch={handleOnSearch}
            />
            <Button
              icon={<DownloadOutlined />}
              disabled={!selectedInvoices.length}
              type="primary"
              onClick={onDownloadExcelFile}
              loading={isDownloading}
            >
              Download Excel File
            </Button>
            {selectedInvoices.length > 0 && (
              <>
                <Dropdown
                  disabled={isMultipleInvoiceUpdateLoading}
                  menu={{
                    items: mappedInvoiceStatuses,
                    onClick: onChangeStatusClick,
                  }}
                >
                  <Button
                    className="filter-btn"
                    disabled={isMultipleInvoiceUpdateLoading}
                  >
                    <span className="btn-text">Change Status</span>
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>

                <Button
                  className="filter-btn"
                  disabled={isMultipleInvoiceUpdateLoading}
                  onClick={onChangeStatusClick}
                >
                  <span className="btn-text">Approve All Invoices</span>
                </Button>
              </>
            )}

            <FilterDropdown
              filters={{ tableView: tableViewFilter }}
              isTableViewOpen={isTableViewOpen}
              onOpenTableView={onOpenTableView}
              onCloseTableView={onCloseTableView}
              renderFilterComponent={
                <TableViewFilter
                  onCloseTableView={onCloseTableView}
                  layout="vertical"
                  defaultValue={tableViewFilter}
                  tableName={UserTablesEnum.invoicesTable}
                  columns={columns}
                  onChange={tableViewOnChange}
                />
              }
              buttonText="Table Adjust"
              itemKey="tableView"
            />
          </Space>
        </Space>
        <AppTabs
          size="large"
          defaultActiveKey={defaultKey}
          activeKey={defaultKey}
          onTabClick={handleTabClick}
          items={[
            {
              label: 'All Invoices',
              key: 'all',
              children: renderInvoiceTable(),
            },
            {
              label: 'Pending Review',
              key: 'drafts',
              children: renderInvoiceTable(),
            },
            {
              label: 'Pending Approval',
              key: 'pending',
              children: renderInvoiceTable(),
            },
          ]}
        />
      </DashboardCard>
    </div>
  );
};

export default InvoicesTabs;
