import Icon from '@ant-design/icons';
import {
  Alert,
  Button,
  Table,
  Input,
  MenuProps,
  Dropdown,
  Space,
  Modal,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useGetRecentCollaboratorByShipmentQuery,
  useInviteRecentCollaboratorMutation,
  useRemoveCollaboratorMutation,
  useUpdateCollaboratorMutation,
} from 'api/collaborators';
import { useLazyCheckForwarderPerShipmentQuery } from 'api/forwarders';
import { proposalsApi } from 'api/proposal';
import { shipmentsApi } from 'api/shipment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { UserRoleEnum } from 'types/entities/user.entity';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

import {
  canRemoveCollab,
  getAccessLevels,
  isManager,
  isViewer,
} from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import {
  formatAccessLevel,
  formatAccessLevelOptions,
} from 'utils/format-fields';
import { isAdmin, isCargoOwner, isForwarder } from 'utils/shipment-helper';

import { ReactComponent as DownArrowIcon } from '../../../../assets/arrow_down_outline.svg';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/add-user-icon.svg';
import AddNewShipmentCollaborator from './AddNewShipmentCollaborator';
import { shipmentsCollabColumnsConst } from './shipment-collabs.const';
import './style.scss';

const { Search } = Input;
const { confirm } = Modal;
interface IShipmentCollaboratorsModal {
  shipmentId: string;
  currentUser: ICollaboratorsEntity;
}
const ShipmentCollaborators: FC<IShipmentCollaboratorsModal> = ({
  shipmentId,
  currentUser,
}) => {
  // state
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState<boolean>(false);

  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    undefined
  );
  const [loadingStates, setLoadingStates] = useState({});
  const [searchBody, setSearchBody] = useState<Record<string, string>>({
    shipmentId,
  });
  const user = useSelector((state: RootState) => state.user.user);
  const isCollaborator =
    !!currentUser && UserRoleEnum.CARGO_OWNER !== currentUser.role;

  // queries
  const dispatch = useDispatch();
  const {
    data: collaborators,
    isLoading,
    isError,
    error,
  } = useGetRecentCollaboratorByShipmentQuery(searchBody);

  const [inviteRecentCollab, { isSuccess }] =
    useInviteRecentCollaboratorMutation();
  const [updateCollabAccessLevel] = useUpdateCollaboratorMutation();
  const [removeCollaborator, { isSuccess: isRemoved }] =
    useRemoveCollaboratorMutation();
  const [checkForwardersPerShipment] = useLazyCheckForwarderPerShipmentQuery();

  // methods
  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setSearchedValue('');
    }
    setSearchedValue(event.target.value);
  };

  const clearSearchInput = () => {
    setSearchedValue('');
    setSearchBody((prev) => ({ ...prev, search: '' }));
  };

  const closeCollaboratorModal = () => {
    dispatch(shipmentsApi.util.invalidateTags([{ type: 'ShipmentsById' }]));
    setIsCollaboratorOpen(false);
    clearSearchInput();
  };

  const openCollaboratorModal = () => {
    setIsCollaboratorOpen(true);
  };

  const onSearch = (v: string) => {
    setSearchBody((prev) => ({ ...prev, search: v }));
  };

  const onAddCollaborator = async (
    id: string,
    accessLevel: AccessLevelEnum
  ) => {
    if (user) {
      setLoadingStates((prevStates) => ({ ...prevStates, [id]: true }));
      try {
        await inviteRecentCollab({
          shipmentId,
          userId: id,
          accessLevel,
        }).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'error adding collaborator' });
      }
      setLoadingStates((prevStates) => ({ ...prevStates, [id]: false }));
    }
  };

  const onUpdateCollab = async (
    collaboratorId: string,
    accessLevel: AccessLevelEnum
  ) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [collaboratorId]: true,
    }));
    try {
      await updateCollabAccessLevel({
        payload: { shipmentId, accessLevel },
        collaboratorId,
      }).unwrap();
      message.success(`Collaborator access level updated to ${accessLevel} !`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'error updating access level' });
    }
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [collaboratorId]: false,
    }));
  };

  const onRemoveShipmentCollaborator = async (
    shipmentId: string,
    collaborator: IRecentCollaboratorDef,
    isAlertDisplayed: boolean
  ) => {
    const { fullname, collaboratorId, role, id } = collaborator;
    confirm({
      width: '600px',
      title: 'Are you sure?',
      okText: isAlertDisplayed ? 'Proceed' : 'Remove',
      okType: 'danger',
      icon: null,
      content: (
        <Space direction="vertical">
          {isForwarder(role) && isAlertDisplayed && (
            <Alert
              showIcon
              type="error"
              message={`A requested proposal is found for this forwarder ${fullname} with no other remaining collaborators. By removing this collaborator the quotation request will be deleted too !`}
            />
          )}
          <Typography.Text>
            Are you sure you want to remove {fullname} ? they may not be able to
            access this shipment anymore.
          </Typography.Text>
        </Space>
      ),
      onOk: async () => {
        try {
          if (isForwarder(role) && !isAlertDisplayed) {
            const resp = await checkForwardersPerShipment({
              shipmentId,
              forwarderId: id,
            }).unwrap();

            if (resp.message !== 'OK') {
              onRemoveShipmentCollaborator(shipmentId, collaborator, true);
            } else {
              await removeCollaborator({
                collaboratorId,
                shipmentId,
                isLastForwarder: isAlertDisplayed,
              }).unwrap();
            }
          } else {
            await removeCollaborator({
              collaboratorId,
              shipmentId,
              isLastForwarder: isAlertDisplayed,
            }).unwrap();

            if (isAlertDisplayed) {
              dispatch(
                proposalsApi.util.invalidateTags([
                  { type: 'ShipmentProposals' },
                ])
              );
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'error deleting collaborator' });
        }
      },
    });
  };
  const onUpsertShipmentCollaborator = (
    collaborator: IRecentCollaboratorDef,
    accessLevel: AccessLevelEnum
  ) => {
    collaborator.accessLevel
      ? onUpdateCollab(collaborator.collaboratorId, accessLevel)
      : onAddCollaborator(collaborator.id, accessLevel);
  };

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error getting collaborators' });
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccess) {
      clearSearchInput();
      message.success('Collaborator added successfully');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isRemoved) {
      clearSearchInput();
      message.success('Collaborator removed successfully');
    }
  }, [isRemoved]);

  const renderDropdown = (collaborator: IRecentCollaboratorDef) => {
    const isCollaboratorinvited = collaborator.accessLevel ? true : false;
    const isAdminCO =
      isCargoOwner(collaborator.role) && isAdmin(collaborator.userRole);

    // NOTE : in case of a user is a viewer and the collaborator is invited, we should not allow editing the access level
    const accessLevels =
      isViewer(currentUser.accessLevel) && isCollaboratorinvited
        ? []
        : getAccessLevels(currentUser?.accessLevel, true);
    const items: MenuProps['items'] = [
      // for shipment collaborators , we only add editors or viewers
      ...accessLevels.map((accessLevel) => {
        return {
          label: (
            <div
              onClick={() =>
                onUpsertShipmentCollaborator(collaborator, accessLevel)
              }
            >
              {formatAccessLevelOptions(isCollaboratorinvited, accessLevel)}
            </div>
          ),
          key: accessLevel,
        };
      }),
      ...(isCollaboratorinvited
        ? [
            {
              type: 'divider' as const,
            },
            {
              label: 'remove',
              key: 'remove',
              disabled:
                isAdminCO || !canRemoveCollab(currentUser, collaborator),
              onClick: () =>
                onRemoveShipmentCollaborator(shipmentId, collaborator, false),
            },
          ]
        : []),
    ];
    return (
      <Dropdown
        overlayClassName="access-level-container"
        menu={{ items }}
        trigger={['hover']}
        disabled={loadingStates[collaborator.id]}
        autoAdjustOverflow
      >
        <Button type={'text'} loading={loadingStates[collaborator.id]}>
          <Space>
            {formatAccessLevel(collaborator.accessLevel)}
            <Icon component={DownArrowIcon} />
          </Space>
        </Button>
      </Dropdown>
    );
  };
  const allColumns: ColumnsType<IRecentCollaboratorDef> = [
    ...shipmentsCollabColumnsConst,
    {
      align: 'right',
      key: 'action',
      render: (_: unknown, record: IRecentCollaboratorDef, index: number) =>
        isManager(record.accessLevel) ? (
          <Typography.Text strong>Owner</Typography.Text>
        ) : (
          renderDropdown(record)
        ),
    },
  ];
  return (
    <>
      <Tooltip
        placement="top"
        title="Invite Shipment Collaborator!"
        destroyTooltipOnHide
      >
        <Button
          data-testid="shipment-collaborator-btn"
          className="add-collaborator-btn"
          type="link"
          icon={<AddUserIcon />}
          onClick={openCollaboratorModal}
        />
      </Tooltip>
      <Modal
        title="Invite Collaborators"
        open={isCollaboratorOpen}
        onCancel={closeCollaboratorModal}
        footer={null}
        width={500}
      >
        <Search
          placeholder="search"
          onSearch={onSearch}
          onChange={handleEmptySearch}
          allowClear
          value={searchedValue}
        />

        <AddNewShipmentCollaborator
          shipmentId={shipmentId}
          isCollaborator={isCollaborator}
          currentUser={currentUser}
        />

        {isError ? (
          <Alert
            description="An error has occured while fetching collaborators .. "
            type="error"
            showIcon
          />
        ) : (
          <div id="collaborators-modal">
            <Table
              loading={isLoading}
              pagination={false}
              className="collaborators-table"
              rootClassName="collaborator-table-row"
              dataSource={collaborators ?? []}
              columns={allColumns}
              showHeader={false}
              rowKey="id"
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ShipmentCollaborators;
