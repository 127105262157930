import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdateCompanyInfosMutation } from 'api/user';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect } from 'react';
import { UpdateCompanyRequest } from 'types/entities/user.entity';
import {
  CompanyInfosProps,
  ICompanyInfosForm,
} from 'types/feature/profile.types';

import { displayErrors } from 'utils/error-notification';

import { currencyOptions } from './utils';

const { confirm } = Modal;

const CompanyInfos: FC<CompanyInfosProps> = ({ company, isAdmin }) => {
  const [updateCompany, { isLoading, isSuccess, isError, error }] =
    useUpdateCompanyInfosMutation();
  const [form] = useForm<ICompanyInfosForm>();

  const onSave = async () => {
    try {
      await form.validateFields();
      const values: UpdateCompanyRequest = await form.getFieldsValue(
        true,
        ({ touched }) => touched
      );

      if (values.currency) {
        confirm({
          title: 'Update Currency Confirmation',
          okText: 'Proceed',
          okType: 'primary',

          icon: null,
          content:
            'By updating the company currency, all proposals costs will be recalculated to match new currency. Do you want to proceed ?',
          onOk: async () => {
            await updateCompany(values);
          },
        });
      } else {
        await updateCompany(values);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.warning({
        message: 'Error',
        description: 'make sure to fill all fields correctly !',
      });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Company infos updated successfully',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error);
    }
  }, [isError, error]);

  return (
    <DashboardCard headerTitle="Company Information">
      <Form
        layout="horizontal"
        labelCol={{ span: 24 }}
        form={form}
        initialValues={{ ...company }}
      >
        <Row gutter={[16, 1]} align={'middle'}>
          <Col span={7}>
            <Form.Item label="Company Name" name="name" required>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Currency" name="currency" required>
              <Select
                options={currencyOptions}
                placeholder="Choose a currency"
                disabled={!isAdmin}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 1]} align={'middle'}>
          <Col span={7}>
            <Form.Item label="Website" name="website">
              <Input placeholder="http://MyAwsomeCompany.com" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Country" name="country">
              <Input />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Company Registration Number (optional)"
              name="registrationNumber"
            >
              <Input />
            </Form.Item>
          </Col>
          <Button
            type="primary"
            className="save-btn"
            onClick={onSave}
            disabled={isLoading}
          >
            Save
          </Button>
        </Row>
      </Form>
    </DashboardCard>
  );
};

export default CompanyInfos;
