/* eslint-disable  @typescript-eslint/no-explicit-any*/
import dayjs from 'dayjs';
import { toPng, toSvg } from 'html-to-image';
import { DataHeaders, DownloadUtilProps } from 'types/feature/analytics.types';

import { captializeValue } from './analytics-helper';

// Download URL Method
export const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// CSV Generate content and download
const generateCSVContent = (data: any, headers: DataHeaders[]): string => {
  const headerRow = headers.map((header) => header?.label).join(',');
  const dataRows = data.map((item: any) =>
    headers
      .map((header) => {
        if (header?.key === 'date') {
          return dayjs(item[header?.key]).format('DD-MM-YYYY');
        }
        return item[header?.key];
      })
      .join(',')
  );
  return `${headerRow}\n${dataRows.join('\n')}`;
};

const downloadCSV = (
  data: any,
  headers: DataHeaders[],
  csvFileName: string
) => {
  const csvContent = generateCSVContent(data, headers);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  if ((navigator as any)?.msSaveBlob) {
    // For IE11 and Edge
    (navigator as any)?.msSaveBlob(blob, csvFileName);
  } else {
    // For other browsers
    const url = URL.createObjectURL(blob);
    downloadFile(url, csvFileName);
  }
};

// Export as SVG
const exportAsSvg = (fileName: string, chartRef: any) => {
  const svgContainer = chartRef?.current;
  if (!svgContainer) return;

  toSvg(svgContainer)
    .then((dataUrl) => {
      downloadFile(dataUrl, fileName);
    })
    .catch((error) => {
      console.error('Error exporting as SVG:', error);
    });
};

// Export as PNG
const exportAsPng = (fileName: string, chartRef: any) => {
  const pngContainer = chartRef?.current;
  if (!pngContainer) return;

  toPng(pngContainer)
    .then((dataUrl) => {
      downloadFile(dataUrl, fileName);
    })
    .catch((error) => {
      console.error('Error exporting as PNG:', error);
    });
};

// Specific Chart Helpers

export const rfqStreamDataHelper = (data: any) => {
  const headers: DataHeaders[] = Object.keys(data[0])?.map((item: string) => {
    return {
      key: item,
      label:
        item === 'id' || item === 'label'
          ? captializeValue(item)
          : item?.toUpperCase(),
    };
  });
  const uniqueData = [data[0], data[2], data[4]];
  return {
    data: uniqueData,
    headers: headers,
  };
};

export const standardDataHelper = (data: any) => {
  const headers: DataHeaders[] = Object.keys(data[0])?.map((item: string) => {
    return {
      key: item,
      label:
        item === 'id' || item === 'label'
          ? captializeValue(item)
          : 'Number Of Shipments',
    };
  });
  return {
    data: data,
    headers: headers,
  };
};

export const timelineDataHelper = (data: any) => {
  const headers: DataHeaders[] = Object.keys(data[0])?.map((item: string) => {
    return {
      key: item,
      label:
        item === 'date' || item === 'cost'
          ? captializeValue(item)
          : item?.toUpperCase(),
    };
  });

  return {
    data: data,
    headers: headers,
  };
};

// Main Download Method
export const onDownload = async ({
  fileName,
  type,
  data,
  headers,
  chartRef,
}: DownloadUtilProps) => {
  switch (type) {
    case 'csv':
      await downloadCSV(
        data,
        headers,
        `${fileName}_${dayjs().format('DD-MM-YYYY')}`
      );
      break;
    case 'png':
      await exportAsPng(
        `${fileName}_${dayjs().format('DD-MM-YYYY')}`,
        chartRef
      );
      break;
    case 'svg':
      await exportAsSvg(
        `${fileName}_${dayjs().format('DD-MM-YYYY')}`,
        chartRef
      );
      break;
    default:
      break;
  }
};
