import { DeleteOutlined, MessageOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Row,
  Space,
  Tag,
  Typography,
  notification,
} from 'antd';
import {
  useDeleteBookingPartyMutation,
  useGetAllBookingPartiesQuery,
} from 'api/booking-parties';
import DashboardCard from 'app/design-system/DashboardCard';
import DescriptionList from 'app/design-system/DescriptionList';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import React, { FC } from 'react';
import { IBookingPartyEntity } from 'types/entities/booking-party';
import { UserRoleEnum } from 'types/entities/user.entity';
import {
  IBookingItemProps,
  TBookingPartiesProps,
} from 'types/feature/shipments.types';

import { hasShipmentStarted } from 'utils/shipment-helper';

import AddBookingPartyModal from './AddBookingPartyModal';
import './booking-parties.scss';

const { Text } = Typography;

const BookingItem: FC<IBookingItemProps> = ({
  item,
  isEditable,
  isShareable,
}) => {
  const { onShareBookingPartyClick } = useBookingPartyState();
  const [deleteBookingParty, { isLoading: isDeleting, isError }] =
    useDeleteBookingPartyMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to delete booking party',
    });
  };

  const onDeleteClick = () => {
    deleteBookingParty(item.id);
    if (isError) errorNotification();
  };

  const onShareClick = (bookingPartyData: IBookingPartyEntity) => {
    if (onShareBookingPartyClick) {
      onShareBookingPartyClick(bookingPartyData);
    }
  };

  return (
    <div className="booking-item-wrapper">
      <Row className="item-row item-header">
        <Col span={14}>
          <Text className="company-text" strong>
            {item.companyName}
          </Text>
        </Col>
        <Col span={10}>
          <Row justify="end" align="middle">
            <Tag color="green">{item.role}</Tag>
            {isEditable && (
              <Space>
                <Button
                  loading={isDeleting}
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={onDeleteClick}
                />

                {isShareable && (
                  <Button
                    icon={<MessageOutlined />}
                    size="small"
                    onClick={() => onShareClick(item)}
                  />
                )}
              </Space>
            )}
          </Row>
        </Col>
      </Row>
      <div className="item-row">
        <DescriptionList
          label="Contact Person:"
          description={item.contactPerson}
          isVertical={true}
        />
        <DescriptionList
          label="Phone:"
          description={item.phoneNumber}
          isVertical={true}
        />
      </div>
      <div className="item-row">
        <DescriptionList
          label="Email:"
          description={item.email}
          isVertical={true}
        />
        <DescriptionList
          label="Country:"
          description={item.country}
          isVertical={true}
        />
      </div>
      <div className="item-row">
        <DescriptionList
          label="Address:"
          description={item.address}
          isVertical={true}
        />
      </div>
      <div className="item-row">
        <DescriptionList
          label="Notes:"
          description={item.note}
          isVertical={true}
        />
      </div>
    </div>
  );
};

const BookingParties: FC<TBookingPartiesProps> = ({
  shipment,
  cardOverlay,
}) => {
  const { shareBookingPartyModal } = useBookingPartyState();
  const { data: bookingData } = useGetAllBookingPartiesQuery(shipment.id);

  const isCargoOwner = !!(
    shipment.currentUserRole === UserRoleEnum.CARGO_OWNER
  );

  const renderBookingParties = () => {
    return (
      <div className="booking-parties-wrapper">
        {bookingData && bookingData.length > 0 ? (
          bookingData.map((party) => {
            return (
              <BookingItem
                key={party.id}
                item={party}
                isEditable={isCargoOwner}
                isShareable={hasShipmentStarted(shipment?.status)}
              />
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Booking Parties for this shipment !"
          />
        )}
      </div>
    );
  };

  const renderAddBookingPartyModal = () => (
    <AddBookingPartyModal shipmentId={shipment.id} />
  );

  return cardOverlay ? (
    <DashboardCard
      headerTitle="Booking Parties"
      fitContent
      noMargin
      rightAccessory={<>{isCargoOwner && renderAddBookingPartyModal()}</>}
      className={`overview-card-wrapper-2 booking-parties-card ${
        shareBookingPartyModal ? 'hightligh-animation' : ''
      }`}
    >
      {renderBookingParties()}
    </DashboardCard>
  ) : (
    <div className="booking-party-preview">
      <div className="booking-party-preview--header">
        {renderAddBookingPartyModal()}
      </div>
      {renderBookingParties()}
    </div>
  );
};

export default BookingParties;
