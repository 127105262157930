import { Button, Checkbox, Form, message, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSetUserTableSettingMutation } from 'api/user-table-columns';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IContainerEntity } from 'types/entities/container.entity';
import { IInvoiceEntity } from 'types/entities/invoice.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { IBaseFilterProps } from 'types/feature/shipments.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { adjustShipmentColumnsCtaClickEvent } from 'utils/analytics-events';
import { splitState } from 'utils/format-fields';

import '../../components/ShipmentFilters/shipment-filter.scss';

export interface ITableViewFilterProps extends IBaseFilterProps {
  tableName: UserTablesEnum;
  columns:
    | ColumnsType<IShipmentEntity>
    | ColumnsType<IInvoiceEntity>
    | ColumnsType<IContainerEntity>;
  onChange: (selectedKeys: string[]) => void;
  onCloseTableView: () => void;
}

export interface ITableViewFilterForm {
  tableView: string[];
}

const TableViewFilter: FC<ITableViewFilterProps> = ({
  tableName,
  onChange,
  columns,
  defaultValue,
  layout,
  onCloseTableView,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [form] = Form.useForm<ITableViewFilterForm>();

  const [
    setUserTableSetting,
    { isSuccess: isNewSettingSaved, isLoading: isNewSettingLoading },
  ] = useSetUserTableSettingMutation();

  const onApplyFiltersCTAClick = (selected_columns: string[]) => {
    if (currentUser) {
      adjustShipmentColumnsCtaClickEvent({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
        selected_columns,
      });
    }
  };
  const onFormChange = ({ tableView }: ITableViewFilterForm) => {
    onApplyFiltersCTAClick(tableView);
    onChange(tableView);
  };

  useEffect(() => {
    if (isNewSettingSaved)
      message.success('Settings for table are successfully saved!');
  }, [isNewSettingSaved]);

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        tableView: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFinish = async (value: ITableViewFilterForm) => {
    try {
      const values: Record<string, boolean> = {};
      const selectedColumnKeys = value.tableView;

      if (selectedColumnKeys) {
        selectedColumnKeys.forEach((col) => {
          values[col] = true;
        });
      }

      await setUserTableSetting({
        tableName: tableName,
        setting: JSON.stringify(values),
      });
    } catch (error: unknown) {
      notification.error({
        message: 'Something went wrong while save table settings!',
      });
    }
    onCloseTableView();
  };

  return (
    <Form
      className="filters-form-wrapper"
      form={form}
      initialValues={{ statusFilter: defaultValue }}
      onValuesChange={onFormChange}
      onFinish={onFinish}
    >
      <Form.Item name="tableView">
        <Checkbox.Group className="filter-values">
          <div className={`filter-input-wrapper ${layout}`}>
            {columns.map((col) => {
              const key = col.key as string;
              const title = col.title as string;

              return (
                <div className="input-space-wrapper" key={key}>
                  <Checkbox value={key}>{splitState(title)}</Checkbox>
                </div>
              );
            })}
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        wrapperCol={{ offset: 15, span: 3 }}
        className="filter-footer-cta"
      >
        <Button
          type="primary"
          htmlType="submit"
          size="small"
          loading={isNewSettingLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TableViewFilter;
