import { Col, Row, Space, Typography } from 'antd';
import {
  formatTotalVolume,
  computeTotalWeight,
  convertToCBM,
} from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import React, { FunctionComponent } from 'react';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import { ICargoUnit, ShipmentTypeEnum } from 'types/entities/shipment.entity';

import { formatContainerName } from 'utils/format-fields';

const formatUnit = (unit: ICargoUnit) => {
  if (unit.containerType) {
    return formatContainerName(unit.containerType as ContainerTypeEnum);
  } else {
    return `${unit.length} ${unit.lengthMeasurement} x ${unit.width} ${unit.lengthMeasurement} x ${unit.height} ${unit.lengthMeasurement}`;
  }
};
interface ICargoUnitByTypeProps {
  type: ShipmentTypeEnum;
  units: ICargoUnit[];
}

const { Text } = Typography;
export const displayCargoUnitsByType: FunctionComponent<
  ICargoUnitByTypeProps
> = (props: ICargoUnitByTypeProps) => {
  const [weight, charges] = computeTotalWeight(props.units, props.type);
  switch (props.type) {
    case ShipmentTypeEnum.FCL:
    case ShipmentTypeEnum.FTL:
      return (
        <>
          <Row
            style={{
              display: 'flex',
              marginBottom: 8,
            }}
            align="middle"
            gutter={[12, 12]}
          >
            <Col xs={16} md={4}>
              <Text className="field-wrapper__text" strong>
                Container Type
              </Text>
            </Col>
            <Col xs={8} md={4}>
              <Text className="field-wrapper__text" strong>
                x Quantity
              </Text>
            </Col>
          </Row>
          {props.units.map((unit) => {
            return (
              <Row key={unit.id}>
                <Col xs={16} md={4}>
                  <Text className="field-wrapper__text">
                    {formatContainerName(
                      unit.containerType as ContainerTypeEnum
                    )}
                  </Text>
                </Col>

                <Col xs={8} md={4}>
                  <Text className="field-wrapper__text"> {unit.quantity}</Text>
                </Col>
              </Row>
            );
          })}
        </>
      );
    default:
      if (
        props.units[0].height &&
        props.units[0].width &&
        props.units[0].length
      ) {
        return (
          <>
            <Row
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="middle"
              gutter={[12, 12]}
            >
              <Col xs={12} md={6}>
                <Text className="field-wrapper__text" strong>
                  Dimensions - L*W*H
                </Text>
              </Col>
              <Col xs={5} md={4}>
                <Text className="field-wrapper__text" strong>
                  Qty
                </Text>
              </Col>
              <Col xs={7} md={4}>
                <Text className="field-wrapper__text" strong>
                  Gross Weight (kg)
                </Text>
              </Col>
            </Row>
            {props.units.map((unit) => {
              return (
                <Row
                  key={unit.id}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="middle"
                  gutter={[12, 12]}
                >
                  <Col xs={12} md={6}>
                    <Text className="field-wrapper__text">
                      {formatUnit(unit)}
                    </Text>
                  </Col>
                  <Col xs={5} md={4}>
                    <Text className="field-wrapper__text">{unit.quantity}</Text>
                  </Col>
                  <Col xs={7} md={4}>
                    <Text className="field-wrapper__text">{unit.weight}</Text>
                  </Col>
                </Row>
              );
            })}
            <Row justify="end" align="middle">
              <Col xs={24} sm={8} md={5} xl={4}>
                <Space direction="vertical" className="units-footer__wrapper">
                  <Text className="units-footer__title">Total Weight</Text>
                  <div className="units-footer__amount-wrapper">
                    <Text className="units-footer__amount">{weight} Kg</Text>
                  </div>
                </Space>
              </Col>
              <Col xs={24} sm={8} md={5} xl={4}>
                <Space direction="vertical" className="units-footer__wrapper">
                  <Text className="units-footer__title">Chargeable Weight</Text>
                  <div className="units-footer__amount-wrapper">
                    <Text className="units-footer__amount">{charges} Kg</Text>
                  </div>
                </Space>
              </Col>
              <Col xs={24} sm={8} md={5} xl={4}>
                <Space direction="vertical" className="units-footer__wrapper">
                  <Text className="units-footer__title">Total Volume</Text>
                  <div className="units-footer__amount-wrapper">
                    <Text className="units-footer__amount">
                      {convertToCBM(formatTotalVolume(props.units))} cbm
                    </Text>
                  </div>
                </Space>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="middle"
              gutter={[12, 12]}
            >
              <Col span={4}>
                <Text className="field-wrapper__text" strong>
                  Weight (kg)
                </Text>
              </Col>
              <Col span={4}>
                <Text className="field-wrapper__text" strong>
                  Volume (cm³)
                </Text>
              </Col>
              <Col span={4}>
                <Text className="field-wrapper__text" strong>
                  Chargeable Weight (kg)
                </Text>
              </Col>
            </Row>
            {props.units.map((unit) => {
              return (
                <Row
                  key={unit.id}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="middle"
                  gutter={[12, 12]}
                >
                  <Col span={4}>
                    <Text className="field-wrapper__text">{unit.volume}</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="field-wrapper__text">{unit.weight}</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="field-wrapper__text">{unit.weight}</Text>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      }
  }
};
