import { Checkbox, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  ILocationFilterForm,
  ILocationFilterProps,
} from 'types/feature/shipments.types';

import { searchString } from 'utils/common';
import { debounce } from 'utils/debounce';

import '../shipment-filter.scss';

const LocationFilter: FC<ILocationFilterProps> = ({
  onChange,
  locations,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<ILocationFilterForm>();
  const [locationState, setLocationState] = useState(locations);

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        locationFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ locationFilter }: ILocationFilterForm) => {
    onChange(locationFilter);
  };

  const onSearch = (value: string) => {
    //array.filter expects a return
    return locations.filter((l) =>
      searchString(l).includes(searchString(value))
    );
  };

  const debouncedHandler = debounce((value: string) => {
    if (value === '') return;
    setLocationState(onSearch(value));
  }, 200);

  const onLocationSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setLocationState(locations);
    debouncedHandler(e.target.value);
  };

  return (
    <div>
      <Form
        form={form}
        onValuesChange={onFormChange}
        layout="vertical"
        initialValues={{ locationFilter: defaultValue }}
        className="filters-form-wrapper"
      >
        <Input
          className="location-search-feild"
          placeholder="Search Location"
          onChange={onLocationSearch}
          allowClear
        />

        <Form.Item name="locationFilter">
          <Checkbox.Group>
            <div className={`filter-input-wrapper ${layout}`}>
              {locationState.map((location) => (
                <div className="input-space-wrapper" key={location}>
                  <Checkbox value={location}>{location}</Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LocationFilter;
