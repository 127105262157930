import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const draftBookingColumns: (ColumnType<IPurchaseOrderItemEntity> & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: 'SN #',
    dataIndex: 'reference',
  },
  {
    title: 'Product Items',
    dataIndex: 'description',
  },

  {
    title: 'Requested',
    dataIndex: 'requestedQuantity',
    className: 'items-table__row__qte',
  },
];
