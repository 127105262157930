import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Spin, Tag, notification } from 'antd';
import { useEditShipmentMutation } from 'api/shipment';
import { shipmentStatusOptions } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IShipmentStatusSwitchProps,
  ShipmentStatusEnum,
} from 'types/feature/shipments.types';

import { updateShipmentStatusClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { splitState } from 'utils/format-fields';
import { hasShipmentStarted } from 'utils/shipment-helper';

import './shipment-status-switch.scss';

const { confirm } = Modal;

const ShipmentStatusSwitch: FC<IShipmentStatusSwitchProps> = ({ shipment }) => {
  const [editShipment, { isSuccess, isLoading }] = useEditShipmentMutation();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Updated Shipment Details Successfully !',
      });
    }
  }, [isSuccess]);

  const handleChangeStatus = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onEditShipment = async (status: any, autoUpdateStatus?: boolean) => {
    try {
      await editShipment({
        shipmentId: shipment.id,
        shipment: {
          status: status.key,
          ...(!autoUpdateStatus && { autoUpdateStatus }),
        },
      }).unwrap();

      updateShipmentStatusClickEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
        shipment_id: shipment.id,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Shipment Edit Error' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showPromiseConfirm = (status: any) => {
    confirm({
      title:
        'Would you like to turn off Auto-updating of the status of the shipment?',
      onOk() {
        return onEditShipment(status, false);
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const computeTagColor = () => {
    switch (shipment.status) {
      case ShipmentStatusEnum.DELIVERED:
      case ShipmentStatusEnum.RFQ_SUBMITTED:
        return 'green';
      case ShipmentStatusEnum.RFQ_REQUESTED:
        return 'blue';
      case ShipmentStatusEnum.IN_ORIGIN:
        return 'gold';
      case ShipmentStatusEnum.IN_TRANSIT:
        return 'orange';
      case ShipmentStatusEnum.IN_CUSTOMS:
        return 'volcano';
      case ShipmentStatusEnum.IN_DESTINATION:
        return 'pink';
      default:
        return 'default';
    }
  };

  return (
    <Dropdown
      className="shipment-status-switch"
      disabled={isLoading}
      menu={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        items: shipmentStatusOptions(shipment.status) as any,
        onClick:
          hasShipmentStarted(shipment.status) && shipment.autoUpdateStatus
            ? showPromiseConfirm
            : onEditShipment,
        selectedKeys: [shipment.status],
      }}
    >
      <Spin indicator={<LoadingOutlined spin />} spinning={isLoading}>
        <Tag
          className="shipment-status-switch--tag"
          color={computeTagColor()}
          closable={true}
          closeIcon={<DownOutlined />}
          onClose={handleChangeStatus}
          onClick={handleChangeStatus}
        >
          {splitState(shipment.status)}
        </Tag>
      </Spin>
    </Dropdown>
  );
};

export default ShipmentStatusSwitch;
