import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Row, Select, Skeleton, Space, Table } from 'antd';
import { useGetDocumentsQuery } from 'api/documents';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  IDocumentTypeEnum,
  IDocumentEntity,
} from 'types/entities/document.entity';
import { PaginatorParams } from 'types/feature/pagination.types';

import { documentPageEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { formatDocumentType } from 'utils/format-fields';

import { columns } from './documents.const';
import './documents.scss';

const defaultPagination: PaginatorParams = {
  page: 1,
  limit: 10,
};

const Documents: FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const [params, setParams] = useState<PaginatorParams>(defaultPagination);

  const {
    data: documents,
    isError,
    isFetching,
    error,
  } = useGetDocumentsQuery({
    ...params,
    documentType: selectedTypes,
  });

  useEffect(() => {
    documentPageEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      company_id: user?.companyId,
    });
  });

  useEffect(() => {
    if (isError && error) displayErrors(error);
  }, [isError, error]);

  const options = Object.values(IDocumentTypeEnum).map(
    (type: IDocumentTypeEnum) => ({
      label: formatDocumentType(type),
      value: type,
    })
  );

  const handleChangeDocumentType = (
    value: string,
    options:
      | { label: string; value: IDocumentTypeEnum }[]
      | { label: string; value: IDocumentTypeEnum }
  ) => {
    if (options instanceof Array) {
      setSelectedTypes(options.map((item) => item.value));
    } else setSelectedTypes([options.value]);

    setParams(defaultPagination);
  };

  const onViewShipment = (id: string, slug: string) => {
    navigate(
      {
        pathname: `/shipments/${slug}/documents`,
      },
      {
        state: { shipmentId: id },
      }
    );
  };

  return (
    <>
      <DashboardCard headerTitle="Documents" fitContent>
        <Row justify={'end'} className="document-actions my-1">
          <Select
            className="document-actions__selector"
            mode="multiple"
            size="middle"
            placeholder="Document type"
            onChange={handleChangeDocumentType}
            options={options}
          />
        </Row>
        {isFetching ? (
          <Skeleton active />
        ) : (
          <Table
            className="table-wrapper"
            columns={[
              ...columns,
              {
                key: 'action',
                render: (_, record: IDocumentEntity, index: number) => (
                  <Space>
                    <a type="ghost" href={record.file} download>
                      <DownloadOutlined size={120} />
                    </a>
                    <Button
                      type="link"
                      icon={<PaperClipOutlined />}
                      onClick={() =>
                        onViewShipment(
                          record.shipmentId,
                          `SH-${record.shipmentSlug}`
                        )
                      }
                    ></Button>
                  </Space>
                ),
              },
            ]}
            dataSource={documents?.items ?? []}
            rowKey={(record: IDocumentEntity) => record.id}
            size="middle"
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              pageSize: params.limit,
              pageSizeOptions: ['5', '10', '50'],
              total: documents?.meta.totalItems,
              current: params.page,
              onChange: (page, pageSize) => {
                setParams({ page: page, limit: pageSize });
              },
            }}
          />
        )}
      </DashboardCard>
    </>
  );
};

export default Documents;
