import { CaretRightOutlined } from '@ant-design/icons';
import { CollapseProps, Space, Typography } from 'antd';
import { Collapse, theme } from 'antd';
import { useGetOrderFilesByIdQuery } from 'api/purchase-order';
import dayjs from 'dayjs';
import type { CSSProperties, FC } from 'react';
import React from 'react';
import { IPurchaseOrderActivityProps } from 'types/feature/purchase-orders.types';

import PurchaseOrderComments from '../PurchaseOrderComments';
import PurchaseOrderFiles from '../PurchaseOrderFiles';
import './activity.scss';

const { Text } = Typography;
const displayLabel = (title: string, resume?: JSX.Element) => (
  <div className="activity-label-wrapper">
    <Space>
      <Text strong>{title}</Text>
      <Text type="secondary">{dayjs().format('DD MMM, YYYY')}</Text>
    </Space>
    {resume && <div className="activity-label-resume">{resume}</div>}
  </div>
);
const PurchaseOrderActivity: FC<IPurchaseOrderActivityProps> = ({
  purchaseOrderId,
  shipmentId,
  currentUserAccessLevel,
}) => {
  const { token } = theme.useToken();
  const { data: files } = useGetOrderFilesByIdQuery(purchaseOrderId);

  const panelStyle = {
    marginBottom: 16,
    background: '#ffffff',
    border: 'none',
    borderBottom: 'solid 1px #CCD0D3',
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
    {
      key: 'attachements',
      label: displayLabel(
        'Attachments',
        <Space>
          <Text>There is {files?.length} documents attached to this PO.</Text>
          <Text type="secondary">Click to view !</Text>
        </Space>
      ),
      children: <PurchaseOrderFiles orderId={purchaseOrderId} />,
      style: panelStyle,
      showArrow: false,
    },
    {
      key: 'comments',
      label: displayLabel('Comments'),
      children: (
        <PurchaseOrderComments
          purchaseOrderId={purchaseOrderId}
          shipmentId={shipmentId}
          currentUserAccessLevel={currentUserAccessLevel}
        />
      ),
      style: panelStyle,
      showArrow: false,
    },
  ];

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
      defaultActiveKey={['comments']}
      accordion
    />
  );
};

export default PurchaseOrderActivity;
