import { FormInstance, Form, InputRef, Input, Select, InputNumber } from 'antd';
import { currencyOptions } from 'app/components/Proposal/Fees/fees.const';
import { BaseSelectRef } from 'rc-select';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  EditableRowProps,
  EditableCellProps,
} from 'types/feature/invoice.types';

import './invoice-matching-table.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<BaseSelectRef>(null);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
      selectRef.current?.focus();
    }
  }, [editing]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {
      // Use this block to diable actions
      toggleEdit();
    }
  };

  const renderChildNode = () => {
    switch (dataIndex) {
      case 'currency': {
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Select
              ref={selectRef}
              placeholder="Currency"
              options={currencyOptions}
              onChange={save}
              onBlur={save}
            />
          </Form.Item>
        );
        break;
      }

      case 'description': {
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              className={`cell-edit-input ${dataIndex}`}
            />
          </Form.Item>
        );
        break;
      }

      default: {
        const isRequired = !!(
          dataIndex === 'parsedTotalCost' || dataIndex === 'totalCost'
        );

        return (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            {...(isRequired && {
              rules: [
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ],
            })}
          >
            <InputNumber
              type={dataIndex === 'quantity' ? 'integer' : 'number'}
              precision={dataIndex === 'quantity' ? 0 : 2}
              ref={inputNumberRef}
              onPressEnter={save}
              onBlur={save}
              className={`cell-edit-input ${dataIndex}`}
            />
          </Form.Item>
        );
      }
    }
  };

  return (
    <td {...restProps}>
      {editable ? (
        editing ? (
          renderChildNode()
        ) : (
          <div className="editable-cell-value-wrap" onClick={toggleEdit}>
            {children}
          </div>
        )
      ) : (
        children
      )}
    </td>
  );
};
