import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { notification } from 'antd';
import {
  API_URL,
  PUSER_SECRET_KEY,
  PUSHER_INSTANCE_ID,
} from 'api/common/config';
import errorsLogger from 'app/layout/ErrorBoundary/useErrorReport';
import Pusher from 'pusher-js';

import { getAuthToken } from 'utils/auth-storage';

const userToken = getAuthToken();

export const pusherClient = userToken
  ? new Pusher(PUSER_SECRET_KEY, {
      cluster: 'ap2',
      userAuthentication: {
        endpoint: `${API_URL}/app-notifications/auth`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        transport: 'ajax',
      },
    })
  : null;

export function beamsClient() {
  try {
    return new PusherPushNotifications.Client({
      instanceId: PUSHER_INSTANCE_ID,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notification.error({
      message: 'Notification Error',
      description: error?.message,
    });

    const location = window.location.pathname;
    errorsLogger.report(`FE Notification Error at ${location} : ${error}`);
  }
}
