import {
  FormInstance,
  Form,
  InputRef,
  Input,
  message,
  InputNumber,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  IDocumentExtractorEditableCellProps,
  IDocumentExtractorEditableRowProps,
} from 'types/feature/document-extractor.types';

import { precisionInputColumns } from '../document-extractor.utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableContext = React.createContext<FormInstance<any> | null>(null);

export const EditableRow: React.FC<IDocumentExtractorEditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<IDocumentExtractorEditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const inputNumberRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const onInputSave = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {
      message.error('Opps! The input could not be saved.');
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      childNode = (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          {precisionInputColumns.includes(dataIndex) ? (
            <InputNumber
              ref={inputNumberRef}
              onPressEnter={onInputSave}
              onBlur={onInputSave}
              className={`cell-edit-input ${dataIndex}`}
              precision={2}
            />
          ) : (
            <Input
              ref={inputRef}
              onPressEnter={onInputSave}
              onBlur={onInputSave}
              className={`cell-edit-input ${dataIndex}`}
            />
          )}
        </Form.Item>
      );
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};
