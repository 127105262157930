import { createSlice } from '@reduxjs/toolkit';
import {
  IBookingEntity,
  IBookingItemEntity,
  IBookingPurchaseItemEntity,
  IDraftBookingEntity,
} from 'types/entities/booking.entity';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';

interface DraftBookingState {
  bookings: IDraftBookingEntity[];
  ids?: string[];
  isBookingAvailable: boolean;
}

const initialState = {
  bookings: [],
  ids: [],
  isBookingAvailable: false,
} as DraftBookingState;

const transformBookingsResponse = (bookings: IBookingEntity[]) => {
  const items: Record<string, { id: string; po: IBookingItemEntity }> = {};

  bookings.forEach((booking) => {
    booking.bookingPurchaseOrders.forEach((po) => {
      items[po.purchaseOrder.id] = { id: booking.id, po };
    });
  });

  return items;
};

const mapPurchaseItemsToIds = (
  createdItems: IBookingPurchaseItemEntity[],
  items: IPurchaseOrderItemEntity[]
) => {
  return items.map((item) => {
    const id = createdItems.find(
      (bookingItem) => bookingItem.purchaseOrderItem.id === item.id
    )?.id;
    return { ...item, bookingItemId: id ?? null };
  });
};

const upsertOrderItem = (
  order: IDraftBookingEntity,
  item: IPurchaseOrderItemEntity
) => {
  const id = order.items.findIndex((it) => it.id === item.id);

  if (id > -1) {
    order.items[id].toBook = item.toBook;
  } else {
    order?.items.push(item);
  }
};

const draftBookingSlice = createSlice({
  name: 'draftBooking',
  initialState,
  reducers: {
    setIsBookingAvailable: (state, action) => {
      state.isBookingAvailable = action.payload.isAvailable;
    },
    setCreatedBookingIds: (state, action) => {
      state.ids = action.payload.ids;
    },
    removeCreatedBookingId: (state, action) => {
      const index = state.ids?.findIndex((id) => id === action.payload.id);
      if (index !== undefined && index > -1) {
        state.ids?.splice(index, 1);
      }
    },
    resetBookings: (state, action) => {
      state.bookings = [];
      state.ids = [];
    },

    addAllBookingItems: (state, action) => {
      const index = state.bookings.findIndex(
        (item) => item.order === action.payload.order.id
      );
      if (index > -1) {
        const order = state.bookings[index];
        action.payload.items.forEach((item) => {
          upsertOrderItem(order, item);
        });

        state.bookings[index] = order;
      } else {
        // create new order
        const order: IDraftBookingEntity = {
          order: action.payload.order.id,
          refernce: action.payload.order.reference,
          bookingId: null,
          items: action.payload.items,
        };
        state.bookings.push(order);
      }
    },

    removeAllBookingItems: (state, action) => {
      const index = state.bookings.findIndex(
        (item) => item.order === action.payload.order
      );
      state.bookings.splice(index, 1);
      if (state.bookings.length === 0) state.ids = [];
    },

    removeBookingItem: (state, action) => {
      const index = state.bookings.findIndex(
        (item) => item.order === action.payload.order
      );
      const order = state.bookings[index];

      const id = order.items.findIndex((item) => item.id === action.payload.id);

      if (id > -1) {
        order?.items.splice(id, 1);
        if (order.items.length === 0) state.bookings.splice(index, 1);
        if (state.bookings.length === 0) state.ids = [];
      }
    },

    updateBookingIds: (state, action) => {
      const matchedIds = transformBookingsResponse(action.payload.items);
      const newBookings = state.bookings.map((booking) => {
        const createdBooking = matchedIds[booking.order];

        return {
          ...booking,
          bookingId: createdBooking?.id ?? null,
          items: createdBooking
            ? mapPurchaseItemsToIds(
                createdBooking?.po.purchaseOrderItems,
                booking.items
              )
            : booking.items,
        };
      });

      state.bookings = newBookings;
    },
  },
});

export const {
  addAllBookingItems,
  removeAllBookingItems,
  removeBookingItem,
  resetBookings,
  setCreatedBookingIds,
  removeCreatedBookingId,
  updateBookingIds,
  setIsBookingAvailable,
} = draftBookingSlice.actions;
export default draftBookingSlice.reducer;
