import React, { FC } from 'react';

import './description-list.scss';

export interface IDescriptionListProps {
  label: string;
  description: string | number | JSX.Element;
  isVertical?: boolean;
  className?: string;
}

const DescriptionList: FC<IDescriptionListProps> = ({
  label,
  description,
  isVertical,
  className,
}) => {
  return (
    <div
      className={`description-list-wrapper ${
        isVertical && 'vertical'
      } ${className}`}
    >
      <label className="label">{label}</label>
      <div className="description">{description}</div>
    </div>
  );
};

export default DescriptionList;
