import Icon from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IDocumentEntity } from 'types/entities/document.entity';

import { splitState } from 'utils/format-fields';

import { ReactComponent as PDFFile } from '../../../assets/icons/pdf-file.svg';

export const columns: ColumnsType<IDocumentEntity> = [
  {
    key: 'file',
    render: (value, record, index) => {
      return <Icon component={PDFFile} />;
    },
    width: 100,
    align: 'center',
  },
  {
    key: 'shipment',
    title: 'Shipment Reference',
    render: (value, record, index) => {
      return (
        <Typography.Text type="secondary">
          {record.shipmentSlug ? `SH-00${record.shipmentSlug}` : 'N/A'}
        </Typography.Text>
      );
    },
    width: 150,
  },
  {
    key: 'name',
    title: 'File Name',
    render: (value, record, index) => {
      return <Typography.Text strong>{record.name}</Typography.Text>;
    },
  },
  {
    key: 'type',
    title: 'File Type',
    render: (value, record, index) => {
      return (
        <Tag bordered={false} color="blue">
          {splitState(record.type)}
        </Tag>
      );
    },
  },
  {
    key: 'date',
    title: 'Creation Date',
    render: (value, record, index) => {
      return (
        <Typography.Text type="secondary">
          {record.createdAt
            ? dayjs(record.createdAt).format('MMM DD, YYYY')
            : 'Not Set'}
        </Typography.Text>
      );
    },
  },
];
