import { Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { disaplayForwarders } from 'app/design-system/DisplayConnections';
import ShipmentReference from 'app/design-system/ShipmentReference';
import dayJs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { IBookingPartyEnum } from 'types/entities/booking-party';
import { IShipmentEntity } from 'types/entities/shipment.entity';

import { displayCountry, formatShipmentState } from 'utils/format-fields';
import { findBookingPartyByRole } from 'utils/shipment-helper';
import { dateFormat2Const } from 'utils/shipment-type';

// NOTE : this will be used only if no table setting is saved for the user
export const defaultSelectedColumns = [
  'slug',
  'origin',
  'destination',
  'type',
  'status',
  'eta',
  'etd',
  'quantity',
  'forwarderCompany',
  'cargoReadyDate',
  'actions',
];

export const mainColumnConst: ColumnsType<IShipmentEntity> = [
  {
    title: 'Shipment ID',
    dataIndex: 'slug',
    key: 'slug',
    render: (_, record: IShipmentEntity) => (
      <Space direction="vertical">
        <Link
          to={`/shipments/${record?.slug}`}
          state={{ shipmentId: record?.shipmentAccess?.targetId }}
        >
          {record?.slug}
        </Link>
        <ShipmentReference reference={record.shipmentReference} className="" />
      </Space>
    ),
    align: 'center',
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',
    render: (_, record: IShipmentEntity) => displayCountry(record?.origin),
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (_, record: IShipmentEntity) => displayCountry(record?.destination),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, record: IShipmentEntity) => {
      return record?.shipmentType?.title?.toUpperCase();
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record: IShipmentEntity) => {
      return formatShipmentState(record?.status);
    },
  },
];

export const shipmemtColumnConst: ColumnsType<IShipmentEntity> = [
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',
    render: (_, record: IShipmentEntity) => displayCountry(record?.origin),

    sorter: true,
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (_, record: IShipmentEntity) => displayCountry(record?.destination),

    sorter: true,
  },
  {
    title: 'Ready Date',
    dataIndex: 'cargoReadyDate',
    key: 'cargoReadyDate',
    render: (_, record: IShipmentEntity) =>
      dayJs(record?.cargoReadyDate).format(dateFormat2Const),

    sorter: true,
  },
  {
    title: 'ETA',
    dataIndex: 'eta',
    key: 'eta',
    render: (_, record: IShipmentEntity) =>
      record?.eta ? dayJs(record?.eta).format(dateFormat2Const) : 'N/A',
  },
  {
    title: 'ETD',
    key: 'etd',
    render: (_, record: IShipmentEntity) => {
      return record.etd ? dayJs(record.etd).format(dateFormat2Const) : 'N/A';
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (_, record: IShipmentEntity) => record?.shipmentItems,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, record: IShipmentEntity) => {
      return record?.shipmentType?.title?.toUpperCase();
    },
  },
  {
    title: 'Contact Person',
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: (_, record: IShipmentEntity) => {
      return (
        <Space direction="vertical">
          <Typography.Text strong>{record.createdBy.fullname}</Typography.Text>
          <Typography.Text
            type="secondary"
            ellipsis={{ tooltip: record.createdBy.company.name }}
          >
            {record.createdBy.company.name}
          </Typography.Text>
        </Space>
      );
    },
  },
  {
    title: 'Initiated By',
    dataIndex: 'initiatedBy',
    key: 'initiatedBy',
    render: (_, record: IShipmentEntity) => {
      return record.initiatedBy ? (
        <Space direction="vertical">
          <Typography.Text strong>
            {record.initiatedBy.fullname}
          </Typography.Text>
          <Typography.Text
            type="secondary"
            ellipsis={{ tooltip: record.initiatedBy.company.name }}
            className="company-text"
          >
            {record.initiatedBy.company.name}
          </Typography.Text>
        </Space>
      ) : (
        <Tag color="green">Created by CO</Tag>
      );
    },
  },

  {
    title: 'Shipper',
    key: 'shipper',
    render: (_, record: IShipmentEntity) => {
      const cosignee = findBookingPartyByRole(
        record.bookingParties,
        IBookingPartyEnum.SHIPPER
      );

      return cosignee ? (
        <Space direction="vertical">
          <Typography.Text strong>{cosignee?.companyName}</Typography.Text>
          <Typography.Text
            type="secondary"
            ellipsis={{ tooltip: cosignee?.contactPerson }}
          >
            {cosignee?.contactPerson}
          </Typography.Text>
        </Space>
      ) : (
        '--'
      );
    },
  },
  {
    title: 'Consignee',
    key: 'consignee',
    render: (_, record: IShipmentEntity) => {
      const cosignee = findBookingPartyByRole(
        record.bookingParties,
        IBookingPartyEnum.CONSIGNEE
      );

      return cosignee ? (
        <Space direction="vertical">
          <Typography.Text strong>{cosignee?.companyName}</Typography.Text>
          <Typography.Text
            type="secondary"
            ellipsis={{ tooltip: cosignee?.contactPerson }}
          >
            {cosignee?.contactPerson}
          </Typography.Text>
        </Space>
      ) : (
        '--'
      );
    },
  },
  {
    title: 'BL',
    key: 'bl',
    render: (_, record: IShipmentEntity) => {
      return record.mbl ?? record.awb ?? record.trackingNumber;
    },
  },

  {
    title: 'Forwarder',
    dataIndex: 'forwarder',
    key: 'forwarderCompany',
  },
  {
    title: 'Cargo Type',
    key: 'cargoType',
    render: (_, record: IShipmentEntity) => record.cargoType,
  },
  {
    title: 'Product Type',
    key: 'productType',
    render: (_, record: IShipmentEntity) => record.product.category,
  },
  {
    title: 'Incoterm',
    key: 'incoterms',
    render: (_, record: IShipmentEntity) => record.incoterms.toUpperCase(),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record: IShipmentEntity) => {
      return formatShipmentState(record?.status);
    },
    sorter: true,
  },
  {
    title: 'Team',
    dataIndex: 'team',
    key: 'team',
    render: (_, record: IShipmentEntity) => {
      return (
        <div className="user-gravatar-wrapper">
          {disaplayForwarders({ teamMemebers: record?.teams })}
        </div>
      );
    },
  },
];
