import { Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useGetAllCompanyShipmentTagsQuery } from 'api/company-metadata';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IShipmentEntity } from 'types/entities/shipment.entity';

export const useDynamicColumns = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [dynamicColumns, setDynamicColumns] = useState<
    ColumnsType<IShipmentEntity>
  >([]);

  const { data: tags, isSuccess } = useGetAllCompanyShipmentTagsQuery(
    {
      companyId: user?.companyId ?? '',
    },
    { skip: !user?.companyId }
  );

  useEffect(() => {
    if (isSuccess && tags.length > 0) {
      const columns = tags.map((tag) => ({
        title: tag.metadataKey,
        key: tag.id,
        render: (_, record: IShipmentEntity) => {
          const tagValues = record?.shipmentMetadata?.filter(
            (meta) => meta.companyMetadata.metadataKey === tag.metadataKey
          );

          return tagValues?.length ? (
            <Space size={[1, 1]} wrap>
              {tagValues.map((tagValue) => (
                <Tag
                  color={tagValue.companyMetadata?.colorCode}
                  style={{
                    backgroundColor: `${tagValue.companyMetadata?.colorCode}20`,
                    color: tagValue.companyMetadata?.colorCode,
                    border: `solid 1px ${tagValue.companyMetadata?.colorCode}`,
                  }}
                  key={tagValue.id}
                  closable={false}
                >
                  {tagValue.metadataValue}
                </Tag>
              ))}
            </Space>
          ) : (
            '--'
          );
        },
      }));

      setDynamicColumns(columns);
    }
  }, [tags, isSuccess]);

  return { dynamicColumns };
};
