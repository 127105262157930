import { ICompanyEntity } from 'types/entities/company.entity';
import {
  IFileAccessParams,
  IShareFileByCompaniesRequestBody,
  IUnshareFileByCompaniesRequestBody,
} from 'types/entities/file-access.entity';

import { api } from './base-api';

export const fileAccessApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompaniesAccessByFile: build.query<ICompanyEntity[], IFileAccessParams>({
      query: ({ shipmentId, fileId }) =>
        `/file-access/companies/${shipmentId}/${fileId}`,
      providesTags: ['FileAccess'],
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shareFileByCompanies: build.mutation<any, IShareFileByCompaniesRequestBody>(
      {
        query: (body) => ({
          url: `/file-access`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['FileAccess', 'FileByShipment'],
      }
    ),

    unshareFileByCompany: build.mutation<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      IUnshareFileByCompaniesRequestBody
    >({
      query: (body) => ({
        url: `/file-access`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['FileAccess', 'FileByShipment'],
    }),
  }),
});

export const {
  useGetCompaniesAccessByFileQuery,
  useShareFileByCompaniesMutation,
  useUnshareFileByCompanyMutation,
} = fileAccessApi;
