import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IInvoiceForReviewEntity } from 'types/entities/invoice.entity';

import { convertSlugToShipmentId } from 'utils/slug-formattor';

export const reviewInvoicesColumnsConst: ColumnsType<IInvoiceForReviewEntity> =
  [
    {
      title: 'Invoice',
      key: 'invoice',
      dataIndex: 'invoice',
      render: (value, record, index) => {
        return (
          <Space direction="vertical" align="center">
            {convertSlugToShipmentId(record.shipment.slug)}
            <span className="shipment-text--sub">
              {`Invoice #${record.slug || 0}`}
            </span>
          </Space>
        );
      },
    },

    {
      title: 'Date Created',
      key: 'date',
      render: (value, record, index) => {
        return dayjs(record.createdAt).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Days Passed',
      key: 'days',
      render: (value, record, index) => {
        return (
          <Typography.Text
            type={record.daysPassed > 6 ? 'danger' : 'secondary'}
          >
            {record.daysPassed}
          </Typography.Text>
        );
      },
    },
  ];
