import {
  EditFilled,
  DeleteFilled,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import {
  bookingApi,
  useDeleteBookingItemsMutation,
  useUpdateBookingItemsMutation,
} from 'api/bookings';
import { convertToCBM } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import dayjs from 'dayjs';
import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import {
  IBookingEntity,
  IBookingItemEntity,
  IBookingPurchaseItemEntity,
} from 'types/entities/booking.entity';

import { displayErrors } from 'utils/error-notification';
import { displayCountry, isEditAllowed } from 'utils/format-fields';

import { computeBookingTotals } from '../helpers/compute-booking-totals';
import './booking-card.scss';
import { ColumnTypes, bookingItemColumns } from './booking-detail';

const { Text } = Typography;
interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: T;
  index: number;
  children: React.ReactNode;
}

const BookingFooter: FunctionComponent<{
  items: IBookingPurchaseItemEntity[];
}> = ({ items }) => {
  const totals = computeBookingTotals(items);
  return (
    <Table.Summary.Row className="booking-footer">
      <Table.Summary.Cell index={1000} colSpan={3}>
        <Text strong>Totals</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1003}>
        <Text> {convertToCBM(totals.volume.toString())} CBM </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1004}>
        <Text>{totals.weight?.toFixed(2)} </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1001}>
        <Text>{totals.boxes}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1002} colSpan={2}>
        <Text strong>{totals.quantity}</Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

const EditableCell: React.FC<EditableCellProps<IBookingPurchaseItemEntity>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  let isEnabled = true;

  if ('weight' === dataIndex || 'volume' === dataIndex) {
    isEnabled = record.quantity === record.purchaseOrderItem.requestedQuantity;
  }

  return (
    <td {...restProps}>
      {editing && isEnabled ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        <Space direction="vertical">
          {children}
          {editing && !isEnabled && (
            <Typography.Text type="danger">
              Booked Quantity is less than Requested Quantity, you can&apos;t
              edit {dataIndex}
            </Typography.Text>
          )}
        </Space>
      )}
    </td>
  );
};

const InfoWrapper: FunctionComponent<{ title: string; info: string }> = ({
  info,
  title,
}) => (
  <Space direction="vertical" size={[1, 1]}>
    <Text>{title}</Text>
    <Text type="secondary">{info} </Text>
  </Space>
);
const BookingCard: FC<{
  booking: IBookingEntity;
  item: IBookingItemEntity;
}> = ({ booking, item }) => {
  // method
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>('');

  const [deleteBookingItems, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteBookingItemsMutation();
  const [updateBookingItems, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateBookingItemsMutation();

  const onItemsUpdated = () => {
    bookingApi.util.invalidateTags([{ type: 'Bookings', id: booking.id }]);
  };
  const onEditBookedValue = async (item: IBookingPurchaseItemEntity) => {
    try {
      const { quantity, weight, volume, boxes } = form.getFieldsValue();
      await updateBookingItems({
        purchaseOrderItems: [
          {
            id: item.id,
            quantity,
            weight,
            volume,
            boxes,
            purchaseOrderItem: {},
          },
        ],
      }).unwrap();
      onItemsUpdated();
      setEditingKey('');
      // eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Update Item Error' });
    }
  };

  const onDeleteItems = async (
    items: IBookingPurchaseItemEntity[],
    all: boolean
  ) => {
    try {
      const ids = items.map((item) => item.id);
      await deleteBookingItems({
        ids: ids,
        bookingId: booking.id,
      }).unwrap();
      onItemsUpdated();
    } catch (error) {
      notification.error({
        message: 'Delete Error',
        description:
          'Something went wrong while deleting booking items! please retry ...',
      });
    }
  };

  const isEditing = (record: IBookingPurchaseItemEntity) =>
    record.id === editingKey;
  const edit = (
    record: Partial<IBookingPurchaseItemEntity> & { key?: React.Key }
  ) => {
    form.setFieldsValue({ ...record, boxes: record.purchaseOrderItem?.boxes });
    setEditingKey(record.id ?? '');
  };

  const cancel = () => {
    setEditingKey('');
  };

  useEffect(() => {
    if (isUpdated)
      notification.success({
        message: "Item's quatity updated successfully !",
      });
  }, [isUpdated]);

  useEffect(() => {
    if (isDeleted)
      notification.success({
        message: 'Selected item deleted successfully !',
      });
  }, [isDeleted]);

  //
  const editColumn: (ColumnType<IBookingPurchaseItemEntity> & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: 'actions',
      dataIndex: 'actions',
      title: (record) => {
        const isAllowed = isEditAllowed(
          item.purchaseOrder.purchaseOrderCollaborators[0].accessLevel
        );
        return (
          <Tooltip
            title={
              isAllowed
                ? 'Remove All Booking Items'
                : "You can't delete this purchase order from booking as you have viewer access level!"
            }
          >
            <Button
              onClick={() => onDeleteItems(item.purchaseOrderItems, true)}
              disabled={!isAllowed}
            >
              Remove All
            </Button>
          </Tooltip>
        );
      },
      render: (_, record: IBookingPurchaseItemEntity) => {
        const editable = isEditing(record);
        const isAllowed = isEditAllowed(
          item.purchaseOrder.purchaseOrderCollaborators[0].accessLevel
        );

        return !editable ? (
          <Space>
            <Tooltip
              title={
                isAllowed
                  ? 'Edit Booked Quantity'
                  : "You can't edit this purchase order as you have viewer access level!"
              }
            >
              <Button
                size="small"
                icon={<EditFilled />}
                onClick={() => edit(record)}
                disabled={!isAllowed || isDeleting || isUpdating}
              />
            </Tooltip>
            <Tooltip
              title={
                isAllowed
                  ? 'Delete Booking Item'
                  : "You can't delete this purchase order item as you have viewer access level!"
              }
            >
              <Button
                size="small"
                danger
                icon={<DeleteFilled />}
                onClick={() => onDeleteItems([record], false)}
                disabled={!isAllowed || isDeleting || isUpdating}
              />
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Button
              size="small"
              icon={<CheckOutlined />}
              onClick={() => onEditBookedValue(record)}
              disabled={isUpdating}
            />
            <Button
              size="small"
              danger
              icon={<CloseOutlined />}
              onClick={() => cancel()}
              disabled={isUpdating}
            />
          </Space>
        );
      },
      align: 'right',
      width: 100,
    },
  ];
  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const defaultColumns = [...bookingItemColumns, ...editColumn];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IBookingPurchaseItemEntity) => ({
        record,
        editing: isEditing(record),
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: 'number',
      }),
    };
  });
  return (
    <div>
      <Row justify={'space-between'} className="order-header">
        <InfoWrapper title={`PO ID`} info={item?.purchaseOrder?.reference} />
        <InfoWrapper title={'Origin'} info={displayCountry(booking.origin)} />
        <InfoWrapper
          title={'Destination'}
          info={displayCountry(booking.destination)}
        />
        <InfoWrapper title={'Supplier'} info={booking.supplier.company?.name} />
        <InfoWrapper
          title={'CRD'}
          info={dayjs(booking.cargoReadyDate).format('YY , MMM YYYY')}
        />
      </Row>
      <Form form={form} component={false}>
        <Table
          columns={columns as ColumnTypes}
          components={components}
          dataSource={item.purchaseOrderItems}
          rowKey="id"
          size="small"
          className="items-table"
          pagination={false}
          bordered
          summary={() => <BookingFooter items={item.purchaseOrderItems} />}
        />
      </Form>
    </div>
  );
};

export default BookingCard;
