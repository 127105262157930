import { UserAddOutlined } from '@ant-design/icons';
import { Form, Select, Row, Col, DatePicker, Input, InputNumber } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useGetCarriersForProposalQuery } from 'api/proposal-external.api';
import { useGetShipmentsTypesQuery } from 'api/shipment';
import { useGetUserConnectionsQuery } from 'api/user';
import AddConnectionSelect from 'app/components/Connections/AddConnectionSelect';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { IUserConnectionEntity } from 'types/entities/company.entity';
import {
  ISingleShipmentTypeEntity,
  ModalityEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import { UserConnectionTypes } from 'types/entities/user.entity';
import { IContractDetailsFormProps } from 'types/feature/contracts.type';

import { filterCarriers, getCarriersOptions } from 'utils/carriers';
import { computeUserNameWithCompany } from 'utils/user-utils';

const computeSelectOptions = (forwarder: IUserConnectionEntity[]) => {
  return forwarder?.map((user) => ({
    label: computeUserNameWithCompany(user.partner),
    value: user.partner.id,
  }));
};

const ContractDetailsForm: FC<IContractDetailsFormProps> = ({
  form,
  defaultValues,
}) => {
  const startDateWatcher = Form.useWatch('startDate', form);
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const date = form.getFieldValue('startDate');
    if (date) return current < dayjs(date).startOf('day');
    else return false;
  };

  const { data: forwarders } = useGetUserConnectionsQuery({
    type: UserConnectionTypes.FORWARDER,
  });

  const { data: carrierData, isLoading: isCarrierLoading } =
    useGetCarriersForProposalQuery();
  const { data: modalities } = useGetShipmentsTypesQuery();

  const handleSelectForwarder = (value: string) => {
    const selectedForwarder = forwarders?.find(
      (forwarder) => forwarder.partner.id === value
    );
    if (selectedForwarder) {
      const { partner } = selectedForwarder;
      form.setFieldValue('forwarderName', partner.company.name);
      form.setFieldValue('forwarder', partner.company.id);
    }
  };

  useEffect(() => {
    const endDate = form.getFieldValue('endDate');
    if (dayjs(endDate).isBefore(dayjs(startDateWatcher))) {
      form.setFieldValue('endDate', startDateWatcher);
    }
    // eslint-disable-next-line
  }, [startDateWatcher]);

  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError
      {...(defaultValues && { initialValues: defaultValues })}
    >
      <Row gutter={[12, 12]} align={'bottom'}>
        <Col span={6}>
          <Form.Item
            label="Contact"
            name="contactId"
            rules={[
              {
                required: true,
                message: 'Please select a forwarder.',
              },
            ]}
          >
            {AddConnectionSelect({
              selectOptions: computeSelectOptions(forwarders ?? []),
              isRoleHidden: true,
              btnProps: {
                icon: <UserAddOutlined />,
              },
              onSelectChange: handleSelectForwarder,

              showAddNewUser: false,
            })}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Forwarder"
            name="forwarderName"
            rules={[
              {
                required: true,
                message: 'Please select a forwarder.',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Forwarder"
            name="forwarder"
            rules={[
              {
                required: true,
                message: 'Please select a forwarder.',
              },
            ]}
            hidden
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Shipment Type"
            name="shipmentTypeId"
            rules={[
              {
                required: true,
                message: 'Please select a valid shipment type.',
              },
            ]}
          >
            <Select
              options={Object.values(modalities ?? {})?.map(
                (method: ISingleShipmentTypeEntity[]) => ({
                  label: displayFreighMethod(method[0].modality),

                  options: method.map(
                    (type: {
                      id: string;
                      title: ShipmentTypeEnum;
                      modality: ModalityEnum;
                    }) => ({
                      label: type.title.toUpperCase(),
                      value: type.id,
                    })
                  ),
                })
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Reference" name="reference">
            <Input placeholder="Reference ex. XYZ-123" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} align={'bottom'}>
        <Col span={6}>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              {
                required: true,
                message: 'Please choose start date',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[
              {
                required: true,
                message: 'Please choose end date',
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Carrier" name="carrierId">
            <Select
              placeholder="Select carrier"
              loading={isCarrierLoading}
              showSearch
              filterOption={filterCarriers}
              options={getCarriersOptions(carrierData)}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Payment Terms (Days)"
            name="paymentTerms"
            rules={[
              {
                required: true,
                message: 'Please insert payment terms (in days) !',
              },
              {
                type: 'number',

                min: 1,
                message: 'Payment Terms must be greater than 0 !',
              },
            ]}
            hasFeedback
            required
          >
            <InputNumber
              placeholder="Enter number of days"
              min={1}
              precision={0}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractDetailsForm;
