import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, notification } from 'antd';
import { ApiResponse, IError } from 'api/common/response.type';
import React from 'react';

const displayErrorList = (list: IError[]) => {
  return (
    <ul>
      {list?.map((error) =>
        error.errors ? (
          <li key={error.row}>
            Row {error.row}: {error.errors[0].message}
          </li>
        ) : (
          <li key={error.message}>{error.message}</li>
        )
      )}
    </ul>
  );
};
export const displayErrors = (
  error: FetchBaseQueryError | SerializedError | undefined,
  extra?: { title: string }
) => {
  if (error && 'data' in error) {
    const response = error.data as ApiResponse<
      { property: string; message: string }[]
    >;
    notification.error({
      message: extra?.title ?? 'Error',
      description: response.errors ? (
        displayErrorList(response.errors)
      ) : (
        <>
          {response.message ? response.message : response._error}

          {response.data && (
            <ul>
              {response.data?.map((error) => {
                return (
                  <li key={error.property}>
                    <Button type="link" href={error.message}>
                      {error.property}
                    </Button>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      ),
    });
  } else {
    notification.error({
      message: 'Unexpected error',
      description: 'Your last action has failed, Please retry again !',
    });
  }
};
