import { Button, Space } from 'antd';
import { useGetAllPurchaseOrderLocationsQuery } from 'api/purchase-order';
import { useGetTeamMembersQuery, useGetUserConnectionsQuery } from 'api/user';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import { IPurchaseOrderFiltersProps } from 'types/feature/purchase-orders.types';

import { computeAllFiltersCount } from 'utils/shipment-helper';

import CreatedDatesFilter from './POCreatedDateFilter';
import POModalitiesFilter from './POModalitiesFilter';
import POStatusesFilter from './POStatusesFilter';
import PurchaseOrderFilterDrawer from './PurchaseOrderFilterDrawer';

const PurchaseOrderFilters: FC<IPurchaseOrderFiltersProps> = ({
  onFilterChange,
  selectedFilters,
  onResetFilters,
  onFilterDrawerChange,
}) => {
  const [allContacts, setAllContacts] = useState<IUserPartnerEntity[]>([]);
  const { data: allPurchaseOrderLocations } =
    useGetAllPurchaseOrderLocationsQuery();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const { data: teamData } = useGetTeamMembersQuery();
  const { data: connectionsData } = useGetUserConnectionsQuery(null);

  const filterCount = computeAllFiltersCount(selectedFilters);

  useEffect(() => {
    if (connectionsData && teamData && currentUser) {
      const newConnections = connectionsData?.map(({ partner }) => partner);
      const combinedContacts = [currentUser, ...teamData, ...newConnections];
      setAllContacts(combinedContacts);
    }
  }, [connectionsData, teamData, currentUser]);
  return (
    <Space>
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <POStatusesFilter
            layout="vertical"
            defaultValue={selectedFilters.poStatuses}
            onChange={(values) =>
              onFilterChange(values as string[], 'poStatuses')
            }
          />
        }
        buttonText={'Status'}
        itemKey={'poStatuses'}
      />
      <FilterDropdown
        filters={selectedFilters}
        renderFilterComponent={
          <POModalitiesFilter
            layout="vertical"
            defaultValue={selectedFilters.modalities}
            onChange={(values) =>
              onFilterChange(values as string[], 'modalities')
            }
          />
        }
        buttonText={'Modalities'}
        itemKey={'modalities'}
      />
      <CreatedDatesFilter
        onFilterChange={onFilterChange}
        selectedFilters={selectedFilters}
      />
      <PurchaseOrderFilterDrawer
        defaultValue={selectedFilters}
        onChange={onFilterDrawerChange}
        onReset={onResetFilters}
        allContacts={allContacts}
        allPurchaseOrderLocations={allPurchaseOrderLocations}
      />

      {filterCount > 0 && (
        <Button type="link" block onClick={onResetFilters}>
          Clear All
        </Button>
      )}
    </Space>
  );
};

export default PurchaseOrderFilters;
