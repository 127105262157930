import { Button, Row, Space, Typography } from 'antd';
import React from 'react';

import { ReactComponent as NotFoundBG } from '../../../../assets/websiteCrash.svg';

const { Text } = Typography;
const FallBack = () => {
  return (
    <Row
      align="middle"
      justify="center"
      style={{ marginTop: '30px', height: '100vh' }}
    >
      <Space direction="vertical" size={[24, 24]} align="center">
        <NotFoundBG />
        <Text>An unexpected error has occured, please refresh this page !</Text>
        <Button
          type="primary"
          onClick={() => window.location.replace('/dashboard')}
          className="my-1"
        >
          Back Home
        </Button>
      </Space>
    </Row>
  );
};

export default FallBack;
