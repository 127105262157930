import { DeleteFilled, EditFilled } from '@ant-design/icons';
import {
  Button,
  Row,
  Space,
  Table,
  Typography,
  notification,
  Modal,
  Col,
  Input,
  Form,
  ColorPicker,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import {
  useGetAllCompanyShipmentTagsQuery,
  useCreateCompanyShipmentTagMutation,
  useDeleteCompanyShipmentTagMutation,
  useEditCompanyShipmentTagMutation,
} from 'api/company-metadata';
import { useGetProfileQuery } from 'api/user';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ITagsSetting } from 'types/entities/setting.entity';

import { displayErrors } from 'utils/error-notification';
import { hsvToHex } from 'utils/hsvToHex-helper';

import './shipment-tags-settings.scss';

const { Text } = Typography;
const ShipmentTagsSettings: FC = () => {
  const { confirm } = Modal;
  const [createForm] = useForm();
  const [editForm] = useForm();

  const user = useSelector((state: RootState) => state.user.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState<{
    open: boolean;
    shipmentTag: ITagsSetting | null;
  }>({ open: false, shipmentTag: null });

  const { data: profile } = useGetProfileQuery();

  const isAdmin = profile?.data?.userRole === 'admin';

  const { data: tags, isLoading } = useGetAllCompanyShipmentTagsQuery(
    {
      companyId: user?.companyId ?? '',
    },
    { skip: !user?.companyId }
  );

  const [deleteTag] = useDeleteCompanyShipmentTagMutation();

  const onDeleteTagClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this Tag ?',
      onOk: async () => {
        await deleteTag(id)
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Tag Deleted',
              description: `The selected tag has been deleted successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete Tag Error' });
          });
      },
    });
  };
  const tagsColumns: ColumnsType<ITagsSetting> = [
    {
      title: 'Title',
      render: (_, record: ITagsSetting) => (
        <Space direction="vertical" size={[1, 1]}>
          <Typography.Text>{record.metadataKey}</Typography.Text>
        </Space>
      ),
      align: 'left',
    },
    {
      title: 'Color',
      dataIndex: 'instant',
      render: (_, record: ITagsSetting) => (
        <Space direction="vertical" size={[1, 1]}>
          <div
            style={{ backgroundColor: record.colorCode }}
            className="color-display"
          ></div>
        </Space>
      ),

      align: 'center',
      width: 200,
    },
    ...(isAdmin
      ? [
          {
            key: 'action',
            title: 'Actions',
            dataIndex: 'action',

            render: (_, record: ITagsSetting) => {
              return (
                <Space>
                  <Button
                    icon={<EditFilled />}
                    size="middle"
                    type="text"
                    onClick={() => {
                      setIsEdit({ open: true, shipmentTag: record });
                      editForm.setFieldsValue({
                        title: record.metadataKey,
                        colorCode: record.colorCode,
                      });
                    }}
                    data-testid="edit-button"
                  />
                  <Button
                    icon={<DeleteFilled />}
                    size="middle"
                    type="text"
                    onClick={() => onDeleteTagClick(record.id)}
                    data-testid="delete-button"
                  />
                </Space>
              );
            },

            width: 200,
          },
        ]
      : []),
  ];

  const handleClose = () => {
    createForm.resetFields();
    setIsModalOpen(false);
  };

  const [
    createShipmentTag,
    {
      isLoading: tagCreateLoading,
      isError: isTagError,
      error: tagError,
      isSuccess: tagIsSuccess,
    },
  ] = useCreateCompanyShipmentTagMutation();

  const [
    editShipmentTag,
    {
      isLoading: tagEditLoading,
      isError: isTagEditError,
      error: tagEditError,
      isSuccess: tagEditIsSuccess,
    },
  ] = useEditCompanyShipmentTagMutation();

  const onCreateShipmentTag = async () => {
    try {
      const { title: metadataKey, colorCode } =
        await createForm.validateFields();

      await createShipmentTag({
        shipmentTag: {
          CompanyMetadataDto: {
            metadataKey,
            colorCode,
          },
        },
      });
    } catch (error) {
      notification.error({
        message: 'Failed to add new tag, Try again !',
      });
    }
  };

  const onEditShipmentTag = async () => {
    const { shipmentTag } = isEdit;
    try {
      const { title: metadataKey, colorCode } = await editForm.validateFields();
      await editShipmentTag({
        id: isEdit.shipmentTag?.id ?? '',
        ...(shipmentTag?.colorCode !== colorCode ? { colorCode } : {}),
        ...(shipmentTag?.metadataKey !== metadataKey ? { metadataKey } : {}),
      });
    } catch (error) {
      notification.error({
        message: 'Failed to add new tag, Try again !',
      });
    }
    editForm.resetFields();
    setIsEdit({ open: false, shipmentTag: null });
  };

  useEffect(() => {
    if (tagEditError && isTagEditError) {
      displayErrors(tagEditError, { title: 'Failed To edit tag' });
    }
  }, [tagEditError, isTagEditError]);
  useEffect(() => {
    if (tagError && isTagError) {
      displayErrors(tagError, { title: 'Failed To fetch tag' });
    }
  }, [tagError, isTagError]);

  useEffect(() => {
    if (tagIsSuccess) {
      notification.success({
        message: `Tag ${createForm.getFieldValue('title')} added !`,
      });
      createForm.resetFields();
      setIsModalOpen(false);
    }
  }, [tagIsSuccess, createForm]);

  useEffect(() => {
    if (isTagError) {
      notification.error({
        message: ` Error creating tag ${createForm.getFieldValue(
          'title'
        )}, title already exists`,
      });
      createForm.resetFields();
      setIsModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTagError]);

  useEffect(() => {
    if (tagEditIsSuccess) {
      notification.success({
        message: `Tag ${editForm.getFieldValue('title')} edited successfuly !`,
      });
      editForm.resetFields();
      setIsEdit({ open: false, shipmentTag: null });
    }
  }, [tagEditIsSuccess, editForm]);

  return (
    <>
      <div style={{ width: '70%' }}>
        <div className="settings-header">
          <Text strong>Shipment Tags</Text>
          <Text type="secondary">
            Shipment tags are labels affixed to packages, containing crucial
            details like sender and recipient information, tracking numbers, and
            handling instructions to ensure accurate and secure shipping.
          </Text>
          <Text type="secondary">
            Note: You can predefine some tags and it’s values which will be
            shown as default in dropdown list.
          </Text>
        </div>
        <Table
          columns={tagsColumns}
          dataSource={tags ?? []}
          showSorterTooltip
          pagination={{ hideOnSinglePage: true }}
          size="small"
          loading={isLoading}
          rowKey="id"
          className="settings-table"
        />
        {/* Only admin users are able to create new shipment tags */}
        {isAdmin && (
          <Button type="link" onClick={() => setIsModalOpen(true)}>
            + Add new tag
          </Button>
        )}
      </div>

      <Modal
        destroyOnClose
        title="Create new shipment tag"
        open={isModalOpen}
        onCancel={handleClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            form="create-tag-form"
            onClick={onCreateShipmentTag}
            loading={tagCreateLoading}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          form={createForm}
          layout="vertical"
          initialValues={{
            title: '',
            colorCode: '#0062ff',
          }}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Field is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue="#0062ff"
                label="Color"
                name="colorCode"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <ColorPicker
                  defaultValue="#0062ff"
                  format="hex"
                  open={colorPickerOpen}
                  onOpenChange={setColorPickerOpen}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(color: any) => {
                    const { h, s, v } = color.metaColor.originalInput;
                    const hexColor = hsvToHex(h, s, v); // Convert to hex format

                    createForm.setFieldsValue({ colorCode: hexColor });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* edit shipment tag modal */}
      <Modal
        destroyOnClose
        title="Edit shipment tag"
        open={isEdit.open}
        onCancel={() => {
          setIsEdit({ open: false, shipmentTag: null });
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            form="edit-tag-form"
            onClick={onEditShipmentTag}
            loading={tagEditLoading}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={editForm} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: !isEdit.open,
                    whitespace: true,
                    message: 'Field is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Color"
                name="colorCode"
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <ColorPicker
                  format="rgb"
                  open={colorPickerOpen}
                  onOpenChange={setColorPickerOpen}
                  onChange={(color: any) => {
                    const { h, s, v } = color.metaColor.originalInput;
                    const hexColor = hsvToHex(h, s, v); // Convert to hex format

                    editForm.setFieldsValue({ colorCode: hexColor });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ShipmentTagsSettings;
