import Icon, { DownOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Row,
  Table,
  Input,
  MenuProps,
  Dropdown,
  Space,
  notification,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  useCreatePurchaseOrderCollaboratorMutation,
  useGetPurchaseOrderCollaboratorsByIdQuery,
  useUpdatePurchaseOrderCollaboratorMutation,
  useDeletePurchaseOrderCollaboratorByIdMutation,
} from 'api/purchase-order-collaborators';
import AddConnectionModal from 'app/components/Connections/AddConnectionModal';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IPurchaseOrderCollaboratorsProps,
  IPurchaseOrderCollaborators,
  AccessLevelEnum,
} from 'types/feature/purchase-orders.types';

import {
  canRemoveCollab,
  getAccessLevels,
  isManager,
  isViewer,
} from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import {
  formatAccessLevel,
  formatAccessLevelOptions,
} from 'utils/format-fields';

import { ReactComponent as AddUserIcon } from '../../../../../assets/icons/add-user-icon.svg';
import { purchaseOrderColumnsConst } from './collaborators.const';
import './purchase-order-collaborators.scss';

const { Search } = Input;
const { confirm } = Modal;

const PurchaseOrderCollaborators: FC<IPurchaseOrderCollaboratorsProps> = ({
  purchaseOrderId,
  showCollaboratorModal,
  hideCollaboratorModal,
  currentUserAccessLevel,
  isCollaboratorOpen,
}) => {
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    undefined
  );
  const [loadingStates, setLoadingStates] = useState({});

  const user = useSelector((state: RootState) => state.user.user);
  const {
    data: poCollaboratorsData,
    isLoading,
    isError,
    error,
  } = useGetPurchaseOrderCollaboratorsByIdQuery({
    purchaseOrderId,
    search: searchedValue || '',
  });

  const [
    createCollaborator,
    { isSuccess: isInviteSuccess, isError: isInviteError, error: inviteError },
  ] = useCreatePurchaseOrderCollaboratorMutation();

  const [
    updateAccessLevel,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdatePurchaseOrderCollaboratorMutation();
  const [
    removeCollaborator,
    { isSuccess: isRemoveSuccess, isError: isRemoveError, error: removeError },
  ] = useDeletePurchaseOrderCollaboratorByIdMutation();

  // methods
  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setSearchedValue(undefined);
    }
  };

  const handleAddCollaborator = async (
    id: string,
    accessLevel: AccessLevelEnum
  ) => {
    if (user) {
      setLoadingStates((prevStates) => ({ ...prevStates, [id]: true }));
      try {
        await createCollaborator({
          purchaseOrderId,
          collaborators: [{ collaboratorId: id, accessLevel }],
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        displayErrors(error, { title: 'error adding collaborator' });
      }
      setLoadingStates((prevStates) => ({ ...prevStates, [id]: false }));
    }
  };

  const handleUpdateCollab = async (
    purchaseOrderId: string,
    collaboratorId: string,
    accessLevel: AccessLevelEnum
  ) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [collaboratorId]: true,
    }));
    try {
      await updateAccessLevel({ purchaseOrderId, collaboratorId, accessLevel });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'error updating access level' });
    }
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [collaboratorId]: false,
    }));
  };

  const handleRemoveCollab = async (
    purchaseOrderId: string,
    collaborator: IPurchaseOrderCollaborators
  ) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: `Are you sure you want to remove ${collaborator.fullname} ? 
      they may not be able to access this purchase order anymore.`,
      onOk: async () => {
        setLoadingStates((prevStates) => ({
          ...prevStates,
          [collaborator.id]: true,
        }));
        try {
          removeCollaborator({
            purchaseOrderId,
            collaboratorId: collaborator.id,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'error deleting collaborator' });
        }
        setLoadingStates((prevStates) => ({
          ...prevStates,
          [collaborator.id]: false,
        }));
      },
    });
  };
  const handleClick = (
    collaborator: IPurchaseOrderCollaborators,
    accessLevel: AccessLevelEnum
  ) => {
    collaborator.accessLevel
      ? handleUpdateCollab(purchaseOrderId, collaborator.id, accessLevel)
      : handleAddCollaborator(collaborator.id, accessLevel);
  };

  // hooks
  useEffect(() => {
    if (isInviteSuccess) {
      notification.success({ message: 'collaborator added' });
    }
  }, [isInviteSuccess]);

  useEffect(() => {
    if (isRemoveSuccess) {
      notification.success({ message: 'collaborator removed' });
    }
  }, [isRemoveSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({ message: 'Access level updated' });
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error getting collaborators' });
    }
  }, [error, isError]);
  useEffect(() => {
    if (isInviteError && inviteError) {
      displayErrors(inviteError, { title: 'add collaborator error' });
    }
  }, [inviteError, isInviteError]);
  useEffect(() => {
    if (isRemoveError && removeError) {
      displayErrors(removeError, { title: 'remove collaborator error' });
    }
  }, [removeError, isRemoveError]);

  useEffect(() => {
    if (isUpdateError && updateError) {
      displayErrors(updateError, { title: 'updating AccessLevel error' });
    }
  }, [updateError, isUpdateError]);

  const renderDropdown = (collaborator: IPurchaseOrderCollaborators) => {
    const isCollaboratorinvited = collaborator.accessLevel ? true : false;
    const accessLevels =
      isCollaboratorinvited && isViewer(currentUserAccessLevel)
        ? []
        : getAccessLevels(currentUserAccessLevel);
    const items: MenuProps['items'] = [
      ...accessLevels.map((accessLevel) => {
        return {
          label: (
            <div onClick={() => handleClick(collaborator, accessLevel)}>
              {formatAccessLevelOptions(isCollaboratorinvited, accessLevel)}
            </div>
          ),
          key: accessLevel,
        };
      }),
      ...(isCollaboratorinvited
        ? [
            {
              type: 'divider' as const,
            },
            {
              label: 'remove',
              key: 'remove',
              disabled:
                !isManager(currentUserAccessLevel) &&
                collaborator.addedBy !== user?.id,
              onClick: () => handleRemoveCollab(purchaseOrderId, collaborator),
            },
          ]
        : []),
    ];

    return (
      <Dropdown
        overlayClassName="access-level-container"
        menu={{ items }}
        trigger={['click']}
      >
        <Button
          type={isCollaboratorinvited ? 'text' : 'default'}
          loading={loadingStates[collaborator.id]}
        >
          <Space>
            {formatAccessLevel(collaborator.accessLevel)}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const allColumns: ColumnsType<IPurchaseOrderCollaborators> = [
    ...purchaseOrderColumnsConst,
    {
      align: 'right',
      key: 'action',
      render: (
        _: unknown,
        record: IPurchaseOrderCollaborators,
        index: number
      ) => renderDropdown(record),
    },
  ];
  return (
    <Modal
      title="Invite Collaborators"
      open={isCollaboratorOpen}
      onCancel={hideCollaboratorModal}
      footer={null}
    >
      <Row justify="space-between" gutter={[0, 52]}>
        {/* TODO: API donot support search feature */}
        <Search
          placeholder="search"
          onSearch={(v: string) => setSearchedValue(v)}
          onChange={handleEmptySearch}
          allowClear
        />
      </Row>
      <AddConnectionModal
        currentUserAccessLevel={currentUserAccessLevel}
        hideCollaboratorModal={hideCollaboratorModal}
        showCollaboratorModal={showCollaboratorModal}
        purchaseOrderId={purchaseOrderId}
        btnProps={{
          text: 'Add New Collaborator',
          icon: <Icon component={AddUserIcon} type="text" />,
        }}
      />
      {isError ? (
        <Alert
          description="An error has occured while fetching collaborators .. "
          type="error"
          showIcon
        />
      ) : (
        <>
          <Table
            loading={isLoading}
            pagination={false}
            className="collaborators-table"
            rootClassName="collaborator-table-row"
            dataSource={poCollaboratorsData?.data.items ?? []}
            columns={allColumns}
            showHeader={false}
            rowKey="id"
          />
        </>
      )}
    </Modal>
  );
};

export default PurchaseOrderCollaborators;
