import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import { isTesting } from 'api/common/config';
import { ProposalContextProvider } from 'providers/proposal-provider';
import { UTMContextProvider } from 'providers/utm-provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';

import { AuthContextProvider } from 'utils/authProvider';

import { ChatProvider } from '../src/providers/chat-provider';
import App from './app';
import './index.scss';
import { appTheme } from './style-config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  release: 'beebolt-sentry-release',
  environment: isTesting ? 'staging' : 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  dist: './build',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://st.beebolt.com',
    'https://go.beebolt.com',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // reactComponentAnnotation: { enabled: true },
});

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ConfigProvider theme={appTheme}>
        <ChatProvider>
          <BrowserRouter>
            <UTMContextProvider>
              <ProposalContextProvider>
                <App />
              </ProposalContextProvider>
            </UTMContextProvider>
          </BrowserRouter>
        </ChatProvider>
      </ConfigProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

reportWebVitals();
