import { ColumnsType } from 'antd/es/table';
import TableDescriptionList from 'app/design-system/TableDescriptionList';
import dayjs from 'dayjs';
import React from 'react';
import { IInvoiceEntity } from 'types/entities/invoice.entity';

import { computeFullName } from 'utils/user-utils';

export const shipmentFinanceTabel: ColumnsType<IInvoiceEntity> = [
  {
    title: 'BL No',
    dataIndex: 'mbl',
    key: 'mbl',
  },
  {
    title: 'Submitted By',
    dataIndex: 'submitted-by',
    key: 'submitted-by',
    render: (_, record) => (
      <TableDescriptionList
        label={computeFullName(record?.createdBy)}
        description={record.createdBy?.mobileNumber || ''}
        isVertical={true}
      />
    ),
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    key: 'vendor',
    render: (_, record) => record?.vendor?.name?.toUpperCase() || '',
  },
  {
    title: 'Assigned to',
    dataIndex: 'assignee',
    key: 'assignee',
    render: (_, record) => {
      const assignee = record.assignee
        ? computeFullName(record.assignee)
        : 'unassigned';
      return (
        <TableDescriptionList
          label={record.assignee?.company?.name || ''}
          description={assignee}
          isVertical={true}
        />
      );
    },
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) =>
      record?.currency ? record?.currency.toUpperCase() : '',
  },
  {
    title: 'Total Tax',
    dataIndex: 'totalTax',
    key: 'totalTax',
    render: (_, record) => record?.totalTax || 0,
  },
  {
    title: 'Total Amount',
    dataIndex: 'totalCost',
    key: 'totalCost',
    render: (_, record) =>
      record?.totalCost ? parseInt(record?.totalCost?.toString()) : '',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (_, record) =>
      record.createdAt ? dayjs(record.createdAt).format('DD-MM-YYYY') : '',
  },
  {
    title: 'Invoice Date',
    dataIndex: 'issueDate',
    key: 'issueDate',
    render: (_, record) =>
      record.issueDate ? dayjs(record.issueDate).format('DD-MM-YYYY') : '',
  },
];
