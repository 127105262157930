import { Checkbox, Form, Input } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  ICollaboratorFilterForm,
  ICollaboratorFilterProps,
} from 'types/feature/shipments.types';

import { searchString } from 'utils/common';
import { debounce } from 'utils/debounce';
import { computeFullName } from 'utils/user-utils';

import '../shipment-filter.scss';

const CollaboratorFilter: FC<ICollaboratorFilterProps> = ({
  onChange,
  collaborators,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<ICollaboratorFilterForm>();
  const [collaboratorState, setCollaboratorState] = useState(collaborators);

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        collaboratorFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onFormChange = ({ collaboratorFilter }: ICollaboratorFilterForm) => {
    onChange(collaboratorFilter);
  };

  const onSearch = (value: string) => {
    //array.filter expects a return
    return collaborators.filter((collab) =>
      searchString(computeFullName(collab)).includes(searchString(value))
    );
  };

  const debouncedHandler = debounce((value: string) => {
    if (value === '') return;
    setCollaboratorState(onSearch(value));
  }, 200);

  const onLocationSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setCollaboratorState(collaborators);
    debouncedHandler(e.target.value);
  };

  return (
    <div>
      <Form
        form={form}
        onValuesChange={onFormChange}
        layout="vertical"
        initialValues={{ collaboratorFilter: defaultValue }}
        className="filters-form-wrapper"
      >
        <Input
          className="location-search-feild"
          placeholder="Search Collaborator"
          onChange={onLocationSearch}
          allowClear
        />

        <Form.Item name="collaboratorFilter">
          <Checkbox.Group>
            <div className={`filter-input-wrapper ${layout}`}>
              {collaboratorState.map((collaborator) => (
                <div className="input-space-wrapper" key={collaborator.id}>
                  <Checkbox value={collaborator.id}>
                    {computeFullName(collaborator)}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CollaboratorFilter;
