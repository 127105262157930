import { Checkbox, Form } from 'antd';
import React, { FC, useEffect } from 'react';
import { PurchaseOrderStatusEnum } from 'types/entities/purchase-order.entity';
import {
  IPOStatusFilterForm,
  IPurchaseOrderStatusFilterProps,
} from 'types/feature/purchase-orders.types';

import { splitState } from 'utils/format-fields';

const POStatusesFilter: FC<IPurchaseOrderStatusFilterProps> = ({
  onChange,
  defaultValue,
  layout,
}) => {
  const [form] = Form.useForm<IPOStatusFilterForm>();

  const onFormChange = ({ poStatusFilter }: IPOStatusFilterForm) => {
    onChange(poStatusFilter);
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({ poStatusFilter: defaultValue });
    }
    //eslint-disable-next-line
  }, [defaultValue]);

  return (
    <Form
      className="filters-form-wrapper"
      form={form}
      initialValues={{ statusFilter: defaultValue }}
      onValuesChange={onFormChange}
    >
      <Form.Item name="poStatusFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {Object.values(PurchaseOrderStatusEnum).map(
              (status: PurchaseOrderStatusEnum, index: number) => (
                <div className="input-space-wrapper" key={status}>
                  <Checkbox value={status}>{splitState(status)}</Checkbox>
                </div>
              )
            )}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default POStatusesFilter;
