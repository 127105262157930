import {
  IAIAssistEvent,
  IAddCollaboratorEvent,
  IBaseAnalyticsContext,
  IBaseAnalyticsEvents,
  ICompanySettingEvent,
  IDocumentPageEvent,
  IEditShipmentStatusEvent,
  IInviteForwarderEvent,
  IInvoicePageEvent,
  IInvoiceStatusEvent,
  IMessageSendEvent,
  IProposalPageEvent,
  IProposalSubmitEvent,
  IPurchaseOrderEvent,
  IQouteReviewEvent,
  IShipmentBiddingEvent,
  IShipmentPageEvent,
  IShipmentStatusClickEvent,
  ISignupCompleteEvent,
  ITableFiltersEvent,
  ITraitsData,
} from 'types/feature/analytics-events.type';

export function userIdentifyEvent(userId: string, traits?: ITraitsData) {
  window.analytics.identify(userId, traits);
}

export function userGroupEvent(groupId: string, traits?: ITraitsData) {
  window.analytics.group(groupId, traits);
}

export function dashboardViewEvent(
  events: IBaseAnalyticsEvents,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('dashboard_view', events, context);
}

export function previewShipmentCtaClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('shipment_preview_click', events);
}
export function updateShipmentStatusClickEvent(events: IShipmentPageEvent) {
  window.analytics.track('current_status_edit', events);
}

export function createShipmentCtaClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('create_new_shipment_click', events);
}
export function applyShipmentFiltersCtaClickEvent(events: ITableFiltersEvent) {
  window.analytics.track('apply_filters_click', events);
}
export function adjustShipmentColumnsCtaClickEvent(events: ITableFiltersEvent) {
  window.analytics.track('shipment_list_columns_adjust', events);
}
export function createShipmentFromTextClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('new_shipment_create_using_ai', events);
}

export function createShipmentViewEvent(
  events: IBaseAnalyticsEvents,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('new_shipmeht_page_view', events, context);
}

export function createShipmentSubmitClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('new_shipment_create', events);
}
export function changeShipmentBiddingClickEvent(events: IShipmentBiddingEvent) {
  window.analytics.track('bidding_edit', events);
}

export function createContractClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('new_contract_create', events);
}

export function searchContractClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('contract_search', events);
}

export function bookContractClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('contract_book', events);
}

export function inviteForwarderEvent(events: IInviteForwarderEvent) {
  window.analytics.track('forwarder_invite', events);
}

export function inviteRecentForwarderEvent(events: IInviteForwarderEvent) {
  window.analytics.track('forwarder_recent_invite', events);
}

export function shipmentListViewEvent(
  events: IBaseAnalyticsEvents,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('shipment_list_page_view', events, context);
}

export function shipmentPageViewEvent(
  events: IShipmentPageEvent,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('shipment_page_view', events, context);
}

export function contractPageViewEvent(
  events: IBaseAnalyticsEvents,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('contract_page_view', events, context);
}

export function addCollaboratorEvent(events: IAddCollaboratorEvent) {
  window.analytics.track('collaborator_add', events);
}

export function addForwarderColleagueEvent(events: IAddCollaboratorEvent) {
  window.analytics.track('forwarder_collaborators_add', events);
}

export function editShipmentSubmitEvent(events: IEditShipmentStatusEvent) {
  window.analytics.track('shipment_edit', events);
}

export function submitProposalEvent(events: IProposalSubmitEvent) {
  window.analytics.track('proposal_submit', events);
}

export function sendProposalCommentEvent(events: IProposalPageEvent) {
  window.analytics.track('comment_send', events);
}

export function createNewQoutationEbe(events: IShipmentPageEvent) {
  window.analytics.track('create_new_quotation_click', events);
}

export function qoutesReviewEvent(events: IQouteReviewEvent) {
  window.analytics.track('quote_review', events);
}

export function adjustQuotesColumnsCTA(events: ITableFiltersEvent) {
  window.analytics.track('qoute_columns_adjust', events);
}

export function filterQuoteCTA(events: ITableFiltersEvent) {
  window.analytics.track('qoute_filter', events);
}

export function sortQoutesCTA(events: IProposalPageEvent) {
  window.analytics.track('qoute_sort', events);
}

export function messageSendEvent(events: IMessageSendEvent) {
  window.analytics.track('message_send', events);
}

export function addDocumentEvent(events: IShipmentPageEvent) {
  window.analytics.track('file_add', events);
}

export function uploadDocumentsEvent(events: IDocumentPageEvent) {
  window.analytics.track('document_upload', events);
}

export function addBookingPartyEvent(events: IShipmentPageEvent) {
  window.analytics.track('booking_party_add', events);
}

export function activateLiveTrackingEvent(events: IShipmentPageEvent) {
  window.analytics.track('live_tracking_activate', events);
}

export function addtaskEvent(events: IShipmentPageEvent) {
  window.analytics.track('task_add', events);
}

export function editTaskStatusEvent(events: IEditShipmentStatusEvent) {
  window.analytics.track('task_status_edit', events);
}

export function editTaskDateEvent(events: IShipmentPageEvent) {
  window.analytics.track('task_due_date_edit', events);
}

export function assignTaskEvent(events: IShipmentPageEvent) {
  window.analytics.track('task_assign', events);
}

export function analyticsViewEvent(
  events: IBaseAnalyticsEvents,
  context: IBaseAnalyticsContext
) {
  window.analytics.page('analytics_page_view', events, context);
}

export function purchaseOrderViewEvent(
  events: IBaseAnalyticsEvents,
  context?: IBaseAnalyticsContext
) {
  window.analytics.page('purchase_orders_page_view', events, context);
}

export function submitPurchaseOrderEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('purchase_order_submit', events);
}

export function purchaseOrderPdfUploadEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('purchase_order_pdf_upload', events);
}

export function purchaseOrderBookingCreationStartEvent(
  events: IBaseAnalyticsEvents
) {
  window.analytics.track('booking_creation_start', events);
}

export function purchaseOrderBookingCreateEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('booking_create', events);
}

export function purchaseOrderAssignToShipmentEvent(
  events: IPurchaseOrderEvent
) {
  window.analytics.track('booking_assign_to_shipment', events);
}

export function documentPageEvent(events: IBaseAnalyticsEvents) {
  window.analytics.page('documents_page_view', events);
}

export function signupPageEvent(context: IBaseAnalyticsContext) {
  window.analytics.page('sign_up_page_view', context);
}

export function signupCompleteEvent(
  events: ISignupCompleteEvent,
  context: IBaseAnalyticsContext
) {
  window.analytics.track('sign_up_complete', events, context);
}

export function invoicesListViewEvent(
  events: IBaseAnalyticsEvents,
  context?: IBaseAnalyticsContext
) {
  window.analytics.page('invoice_list_page_view', events, context);
}

export function invoicesViewEvent(
  events: IInvoicePageEvent,
  context?: IBaseAnalyticsContext
) {
  window.analytics.page('invoice_page_view', events, context);
}

export function updateInvoiceStatusEvent(events: IInvoiceStatusEvent) {
  window.analytics.track('invoice_status_edit', events);
}

export function invoiceDownloadEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_download', events);
}

export function invoiceDeleteEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_delete', events);
}

export function invoiceSubmitEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_submit', events);
}

export function invoiceAuditStartEvent(events: IInvoicePageEvent) {
  window.analytics.track('audit_start', events);
}

export function invoiceAddCollaboratorEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_collaborator_add', events);
}

export function invoiceAddCommentEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_comment_add', events);
}

export function invoiceCopyToRightEvent(events: IInvoicePageEvent) {
  window.analytics.track('expected_cost_edit', events);
}

export function invoiceAssignEvent(events: IInvoicePageEvent) {
  window.analytics.track('invoice_assign', events);
}

export function invoiceStepChange(events: IInvoicePageEvent) {
  window.analytics.track('invoice_step_change', events);
}

export function companySettingsChange(events: ICompanySettingEvent) {
  window.analytics.track('proposal_ranking_toggle', events);
}

// notifcations events
export function notificationViewEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('notification_icon_click', events);
}

export function notificationSettingClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('notification_setting_click', events);
}

export function notificationClearEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('mark_as_read_click', events);
}

export function notifcationsShowUnreadEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('show_unread_click', events);
}

export function notifcationsShowAllEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('show_more_click', events);
}

export function aiAssistClickEvent(events: IBaseAnalyticsEvents) {
  window.analytics.track('ask_ai_assistant_click', events);
}

export function aiAssistMessageSendEvent(events: IAIAssistEvent) {
  window.analytics.track('ai_assistant_message_send', events);
}

export function shipmentStatusClickEvent(event: IShipmentStatusClickEvent) {
  window.analytics.track('shipment_status_click', event);
}
export function quotesForReviewClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('quotes_for_review_click', event);
}

export function viewAllQuotesClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('view_all_quotes_click', event);
}

export function mapClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('map_click', event);
}

export function viewTaskClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('view_task_click', event);
}

export function viewAllShipmentsClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('view_all_shipments_click', event);
}

export function bookmarkedShipmentsClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('bookmarked_shipments_click', event);
}

export function messagesPageVisitEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('messages_page_visit', event);
}

export function messagesSearchEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('messages_search', event);
}

export function messagesTabClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('messages_tab_click', event);
}

export function commentsTabClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('comments_tab_click', event);
}

export function messagesSendEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('message_send', event);
}
export function commentSendEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('comment_send', event);
}

export function fileAddEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('file_add', event);
}

export function conversationClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('conversation_click', event);
}

export function showMoreClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('show_more_click', event);
}

export function shipmentIdClickEvent(event: IBaseAnalyticsEvents) {
  window.analytics.track('shipment_id_click', event);
}
