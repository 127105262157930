import { Empty, Modal, Row, Spin } from 'antd';
import { useLazyGetDocumentExtractorByIdQuery } from 'api/document-extractor';
import PDFViewer from 'app/design-system/PDFViewer';
import React, { FC, useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { IDocumentExtractorEntity } from 'types/entities/document-extractor.entity';
import { IDocumentExtractorProps } from 'types/feature/document-extractor.types';

import { displayErrors } from 'utils/error-notification';

import { DocumentTableView } from './DocumentTableView';
import './document-extractor.scss';

const DocumentExtractor: FC<IDocumentExtractorProps> = ({
  shipmentId,
  fileId,
  isOpen,
  onClose,
}) => {
  const [extractedDoc, setExtractedDoc] =
    useState<IDocumentExtractorEntity | null>(null);

  const [
    getExtractedDocument,
    {
      isLoading,
      isSuccess: extractedDocsSuccess,
      isError: isExtractedDocsError,
      error: extractedDocsError,
      data: extractedDocsResp,
    },
  ] = useLazyGetDocumentExtractorByIdQuery();

  const handleCancel = async () => {
    onClose();
    setExtractedDoc(null);
  };

  useEffect(() => {
    if (isExtractedDocsError && extractedDocsError) {
      displayErrors(extractedDocsError, { title: 'Fetching Document Error' });
    }
  }, [isExtractedDocsError, extractedDocsError]);
  useEffect(() => {
    if (extractedDocsSuccess && extractedDocsResp?.data) {
      setExtractedDoc(extractedDocsResp.data || null);
    }
  }, [extractedDocsSuccess, extractedDocsResp]);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        if (fileId) {
          await getExtractedDocument({ fileId }).unwrap();
        }
        // eslint-disable-next-line
      } catch (error: any) {
        displayErrors(error, { title: 'Error on fetching extracted docs' });
      }
    };

    fetchDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  return (
    <div>
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        {...(isLoading ? { width: '500px' } : { width: '95%' })}
        className="document-extractor-modal-wrapper align-top"
      >
        {isLoading ? (
          <div className="loading">
            <Row className="py-3" justify="center">
              <Spin />
            </Row>
          </div>
        ) : (
          <>
            {extractedDoc ? (
              <div className="document-extractor-wrapper">
                <ReflexContainer orientation="horizontal">
                  <ReflexElement className="left-panel">
                    <PDFViewer
                      file={extractedDoc.highlightedFile}
                      className="pdf-viewer"
                    />
                  </ReflexElement>

                  <ReflexSplitter />

                  <ReflexElement className="right-panel">
                    <DocumentTableView
                      shipmentId={shipmentId}
                      extractedData={extractedDoc}
                      onModalClose={handleCancel}
                    />
                  </ReflexElement>
                </ReflexContainer>
              </div>
            ) : (
              <Row className="py-3" justify="center">
                <Empty />
              </Row>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default DocumentExtractor;
