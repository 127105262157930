import { IPlaceData } from 'types/entities/location.entity';

import { api } from './base-api';

export const locationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlacesPrediction: build.query<
      google.maps.places.AutocompletePrediction[],
      { search: string }
    >({
      query: (params) => ({
        url: `/places/search-address?search=${params.search}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'PlacesSearch', id: arg.search },
      ],
    }),

    getPlaceDetails: build.query<IPlaceData, { placeId: string }>({
      query: (params) => ({
        url: `/places/details`,
        params: params,
      }),
    }),
  }),
});

export const { useLazyGetPlaceDetailsQuery, useLazyGetPlacesPredictionQuery } =
  locationsApi;
