import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useMatchContractsMutation } from 'api/contract';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import {
  computeShipmentTypeOptions,
  convertToCBM,
  isFullLoad,
} from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import LocationFields from 'app/design-system/LocationFields';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IContractEntity,
  IMatchContractRatesReq,
} from 'types/entities/contract.entity';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import { ModalityEnum } from 'types/entities/shipment.entity';
import { IContractSearchProps } from 'types/feature/contracts.type';
import { IShipmentTypeState } from 'types/feature/create-shipment.types';

import { searchContractClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import {
  createServiceModeOptions,
  formatContainerName,
} from 'utils/format-fields';

import { ReactComponent as EmptyContracts } from '../../../../../assets/empty-contracts.svg';
import MatchedContractsRate from '../../MatchedContractsList';
import '../contract-search.style.scss';
import { mapSearchParams } from './helper';

const { Group, Button: GroupButton } = Radio;

const ContractsSearch: FC<IContractSearchProps> = ({
  isBookingOn,
  onBookClick,
}) => {
  const user = useSelector((state: RootState) => state.user.user);

  const [form] = Form.useForm<IMatchContractRatesReq>();
  const modality = Form.useWatch('modality', form);
  const shipmentTypeId = Form.useWatch('shipmentTypeId', form);
  const containerType = Form.useWatch('containerType', form);
  const shipmentType = Form.useWatch('shipmentType', form);
  const isPerContainer =
    shipmentType &&
    isFullLoad({
      id: shipmentTypeId,
      title: shipmentType,
      modality,
    });

  const [shipmentTypeOptions, setShipmentTypeOptions] = useState<
    IShipmentTypeState[]
  >([]);

  const { data: allShipmentTypesData, isLoading: isLoadingTypes } =
    useGetExternalShipmentsTypesQuery();

  const [matchRates, { isLoading, data: contracts }] =
    useMatchContractsMutation();

  const onSearchClick = async () => {
    try {
      const values = await form.validateFields();
      const payload = mapSearchParams(values);
      await matchRates(payload).unwrap();
      searchContractClickEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
      });
      //eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error);
    }
  };

  const renderShipmentType = () =>
    allShipmentTypesData && (
      <Form.Item
        name="shipmentTypeId"
        rules={[
          {
            required: true,
            message: 'Make sure to select the shipment type !',
          },
        ]}
      >
        <Select
          placeholder="Shipment Type"
          options={shipmentTypeOptions.map((value: IShipmentTypeState) => {
            return {
              value: value.id,
              label: value.title.toUpperCase(),
            };
          })}
        />
      </Form.Item>
    );
  const getShipmentType = useCallback(
    (shipmentTypeId: string) =>
      shipmentTypeOptions.find(({ id }) => id === shipmentTypeId)?.title,
    [shipmentTypeOptions]
  );

  const onSelectFreightData = () => {
    const shipmentType = getShipmentType(shipmentTypeId);
    const quantity = form.getFieldValue('quantity');

    if (quantity && containerType)
      form.setFieldValue(
        'freightDetails',
        `${shipmentType?.toUpperCase()}, ${formatContainerName(
          containerType
        )} x ${quantity}`
      );
    else {
      const volume = form.getFieldValue('volume');
      const weight = form.getFieldValue('weight');

      form.setFieldValue(
        'freightDetails',
        `${shipmentType?.toUpperCase()}, ${weight}Kg / ${convertToCBM(
          volume
        )} CBM`
      );
    }
  };
  const onModalityChange = useCallback(
    (modalityValue: string) => {
      const shipmentTypeOptions = computeShipmentTypeOptions(
        modalityValue,
        allShipmentTypesData
      );
      setShipmentTypeOptions(shipmentTypeOptions);
      form.setFieldValue('shipmentTypeId', shipmentTypeOptions[0].id);
    },

    [allShipmentTypesData, form]
  );

  useEffect(() => {
    if (shipmentTypeId) {
      form.setFieldValue('shipmentType', getShipmentType(shipmentTypeId));
    }
  }, [shipmentTypeId, form, getShipmentType]);

  useEffect(() => {
    if (modality) {
      onModalityChange(modality);
    }
  }, [modality, onModalityChange]);

  return (
    <>
      <div className="search-box-container">
        <Typography.Text className="search-box-container__title">
          Find the best freight contracts
        </Typography.Text>
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError
          labelCol={{ span: 24 }}
        >
          <Row gutter={[12, 1]} className="form-item-wrapper">
            <Col span={isBookingOn ? 12 : 4}>
              <Form.Item
                label="Service Mode"
                name="serviceMode"
                rules={[
                  {
                    required: true,
                    message: 'Please select a service mode.',
                  },
                ]}
              >
                <Select
                  placeholder="Service Mode"
                  options={createServiceModeOptions()}
                />
              </Form.Item>
            </Col>
            <Col span={isBookingOn ? 12 : 4}>
              <Form.Item
                label="Cargo Ready Date"
                name="cargoReadyDate"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the estimated cargo ready date',
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <LocationFields
              form={form}
              isEditView={true}
              defaultLocations={null}
              colSpan={isBookingOn ? 12 : 5}
              filedsName={[
                'origin',
                'destination',
                'originFull',
                'destinationFull',
              ]}
            />
            <Col span={isBookingOn ? 12 : 5}>
              <Dropdown
                dropdownRender={() => (
                  <Space
                    direction="vertical"
                    className="freight-details-container"
                    size={[1, 4]}
                  >
                    <Typography.Text strong>Freight Method</Typography.Text>
                    <Form.Item
                      name="modality"
                      rules={[
                        {
                          required: true,
                          message: 'Make sure to select shipment type !',
                        },
                      ]}
                    >
                      {isLoadingTypes ? (
                        <Spin />
                      ) : (
                        <Group>
                          {Object.keys(allShipmentTypesData ?? {})?.map(
                            (method: string) => (
                              <GroupButton value={method} key={method}>
                                {displayFreighMethod(method as ModalityEnum)}
                              </GroupButton>
                            )
                          )}
                        </Group>
                      )}
                    </Form.Item>
                    <Typography.Text strong>Shipment Type</Typography.Text>
                    <Form.Item name="shipmentType" hidden>
                      <Input />
                    </Form.Item>
                    {isLoadingTypes ? <Spin /> : renderShipmentType()}
                    <Typography.Text strong>
                      {isPerContainer ? 'Container Type' : 'Weight & Volume'}
                    </Typography.Text>
                    {isPerContainer ? (
                      <Space align="start">
                        <Form.Item
                          name="containerType"
                          rules={[
                            {
                              required: isPerContainer,
                              message: 'Make sure to select a container type !',
                            },
                          ]}
                          hidden={!isPerContainer}
                        >
                          <Select
                            placeholder="Container Type"
                            options={Object.values(ContainerTypeEnum).map(
                              (value) => {
                                return {
                                  value: value,
                                  label: formatContainerName(value),
                                };
                              }
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          name="quantity"
                          rules={[
                            {
                              required: isPerContainer,
                              message: 'Qunatity is required !',
                            },
                            {
                              min: 1,
                              type: 'number',
                              message: 'Quantity need be greater than 0 !',
                            },
                          ]}
                          hidden={!isPerContainer}
                        >
                          <InputNumber
                            placeholder="Enter Quantity"
                            min={1}
                            precision={0}
                            type="number"
                          />
                        </Form.Item>
                      </Space>
                    ) : (
                      <Space align="start">
                        <Form.Item
                          name="weight"
                          rules={[
                            {
                              required: !isPerContainer,
                              message: 'Weight is required !',
                            },
                            {
                              min: 1,
                              type: 'number',
                              message: 'Weight need be greater than 0 !',
                            },
                          ]}
                          hidden={isPerContainer}
                        >
                          <InputNumber
                            placeholder="Enter Weight (Kg)"
                            min={0.01}
                            precision={2}
                            type="number"
                          />
                        </Form.Item>
                        <Form.Item
                          name="volume"
                          rules={[
                            {
                              required: !isPerContainer,
                              message: 'Volume is required !',
                            },
                            {
                              min: 1,
                              type: 'number',
                              message: 'Volume need be greater than 0 !',
                            },
                          ]}
                          hidden={isPerContainer}
                        >
                          <InputNumber
                            placeholder="Enter Volume (CM3)"
                            min={0.01}
                            precision={2}
                            type="number"
                          />
                        </Form.Item>
                      </Space>
                    )}
                    <Button
                      type="primary"
                      className="select-btn"
                      onClick={onSelectFreightData}
                    >
                      Select
                    </Button>
                  </Space>
                )}
              >
                <Form.Item
                  label="Freight Details"
                  name="freightDetails"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the freight details',
                    },
                  ]}
                >
                  <Input
                    placeholder="Select freight method ..."
                    contentEditable={false}
                    prefix={displayFreighMethod(modality, false)}
                  />
                </Form.Item>
              </Dropdown>
            </Col>
            <Button
              icon={<SearchOutlined />}
              onClick={onSearchClick}
              className="btn-search"
            />
          </Row>
        </Form>
      </div>
      <Spin size="large" spinning={isLoading}>
        <div className="contracts-container">
          {contracts?.data?.items.length ? (
            <MatchedContractsRate
              contractsData={contracts.data}
              isLoading={isLoading}
              containerType={containerType}
              volume={form.getFieldValue('volume')}
              weight={form.getFieldValue('weight')}
              onBookClick={(contract: IContractEntity) =>
                onBookClick(contract, form.getFieldsValue())
              }
            />
          ) : (
            <Empty
              className="empty-contracts"
              image={<EmptyContracts />}
              description={
                <Space direction="vertical">
                  <Typography.Text strong>Contracts</Typography.Text>
                  <Typography.Text type="secondary">
                    Search shipment rates and book for hassle free experience.
                  </Typography.Text>
                </Space>
              }
            />
          )}
        </div>
      </Spin>
    </>
  );
};

export default ContractsSearch;
