import React, { FC } from 'react';

import './pdf-viewer.scss';

interface IPDFViewerProps {
  file: string;
  className?: string;
}

const PDFViewer: FC<IPDFViewerProps> = ({ file, className }) => {
  return (
    <div className={`pdf-viewer-wrapper ${className ?? ''}`}>
      <object
        data={file ?? null}
        type="application/pdf"
        width="100%"
        height="100%"
        className={className}
      >
        <p>
          Unable to display PDF file. <a href={file ?? null}>Download</a>{' '}
          instead.
        </p>
      </object>
    </div>
  );
};

export default PDFViewer;
