import Icon from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IFileEntity } from 'types/entities/proposal.entity';

import { downloadFile } from 'utils/download-helper';
import { splitState } from 'utils/format-fields';

import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg';
import { ReactComponent as MoreIcon } from '../../../assets/more_vert_icon.svg';
import './file-preview.scss';

export interface IFilePreviewProps {
  file?: IFileEntity;
  imageUrl: string;
  size: 'small' | 'middle';
  onRemove?: () => void;
  onUpdate?: () => void;
  isIconBtn?: boolean;
}

const { Text } = Typography;

const FilePreview: FC<IFilePreviewProps> = ({
  imageUrl,
  size,
  onRemove,
  onUpdate,
  file,
  isIconBtn,
}) => {
  const openFile = (url: string) => window.open(url, '_blank')?.focus();

  const menu: MenuProps = {
    items: [
      ...(onUpdate
        ? [
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => onUpdate(),
              icon: <Icon component={EditIcon} />,
            },
          ]
        : []),
      ...(onRemove
        ? [
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => onRemove(),
              icon: <Icon component={DeleteIcon} />,
            },
          ]
        : []),
    ],
  };
  return (
    <div className={`file-preview-wrapper ${size}`}>
      <div className="file-preview-actions">
        <Button
          type="link"
          className="file-actions-btn"
          icon={<Icon component={DownloadIcon} />}
          onClick={() =>
            downloadFile(imageUrl, file?.name ?? 'shipment-document')
          }
          size={size}
        ></Button>
        {file?.owner && (
          <Dropdown menu={menu} trigger={['click']}>
            <Button
              icon={<Icon component={MoreIcon} />}
              type="link"
              className="file-actions-btn"
              size={size}
            ></Button>
          </Dropdown>
        )}
      </div>
      <div
        style={{
          backgroundImage: `url(${imageUrl}) , url("https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png")`,
          backgroundSize: 'cover, 100px',
          backgroundRepeat: 'no-repeat',
        }}
        className="view-img"
        onClick={() => openFile(imageUrl)}
      ></div>

      {file && (
        <div className={`file-preview`}>
          <Space direction="vertical">
            <Text strong className="file-preview__title">
              {file.name}
            </Text>
            {size !== 'small' && (
              <>
                <Text strong className="file-preview__type">
                  {splitState(file.type)}
                </Text>
                <Text className="file-preview__date">
                  {dayjs(file.updatedAt).format('MMM DD, YYYY')}
                </Text>
              </>
            )}
          </Space>
        </div>
      )}
    </div>
  );
};

export default FilePreview;
