import { Checkbox } from 'antd';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React from 'react';
import { VendorsFilterProps } from 'types/feature/invoice-list';

const VendorsFilter: React.FC<VendorsFilterProps> = ({
  vendorsData,
  onFilterChange,
  selectedFilters,
}) => {
  return (
    <FilterDropdown
      filters={selectedFilters}
      renderFilterComponent={
        <Checkbox.Group
          value={selectedFilters.vendors}
          onChange={(values) => onFilterChange(values as string[], 'vendors')}
        >
          <div className={`filter-input-wrapper`}>
            {vendorsData &&
              Object.values(vendorsData).map((vendor) => {
                return (
                  <div className="input-space-wrapper" key={vendor.id}>
                    <Checkbox value={vendor.id}>{vendor.name}</Checkbox>
                  </div>
                );
              })}
          </div>
        </Checkbox.Group>
      }
      buttonText="Vendors"
      itemKey="vendors"
    />
  );
};

export default VendorsFilter;
