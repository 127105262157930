import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IBookingsProps } from 'types/feature/bookings.types';

import { purchaseOrderViewEvent } from 'utils/analytics-events';

import BookingsTable from './BookingsTable';
import './bookings.scss';

const Bookings: FC<IBookingsProps> = (props) => {
  const { shipment } = props;
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      purchaseOrderViewEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }
  }, [user]);

  return (
    <DashboardCard headerTitle="Bookings" fitContent noMargin>
      <BookingsTable
        shipmentId={shipment?.id}
        shipmentSlug={shipment?.slug}
        currentCollaborator={shipment?.currentCollaborator}
      />
    </DashboardCard>
  );
};

export const BookingsRouteWrapper: FC = () => {
  const props = useOutletContext<IBookingsProps>();

  return <Bookings {...props} />;
};

export default Bookings;
