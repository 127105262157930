import { Breadcrumb } from 'antd';
import type { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { captializeValue } from 'utils/analytics-helper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBreadcrumbItems: (path: string, state: any) => BreadcrumbItemType[] = (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) => {
  let currentHref = '';
  return path.split('/').map((item: string) => {
    currentHref += item ? `/${item}` : '';
    return {
      key: item,
      href: currentHref,
      title: (
        <Link to={currentHref} state={state}>
          {item ? captializeValue(item) : 'Home'}
        </Link>
      ),
    };
  });
};
const NavigationBreadcrumb: FC = () => {
  const location = useLocation();

  return (
    <Breadcrumb items={getBreadcrumbItems(location.pathname, location.state)} />
  );
};

export default NavigationBreadcrumb;
