import { Checkbox, Form } from 'antd';
import React, { FC, useEffect } from 'react';
import {
  IStatusFilterForm,
  IStatusFilterProps,
  ShipmentStatusEnum,
} from 'types/feature/shipments.types';

import { splitState } from 'utils/format-fields';

import '../shipment-filter.scss';

const StatusFilter: FC<IStatusFilterProps> = ({
  onChange,
  defaultValue,
  layout,
  fromPendingQuotes,
}) => {
  const [form] = Form.useForm<IStatusFilterForm>();

  const onFormChange = ({ statusFilter }: IStatusFilterForm) => {
    onChange(statusFilter);
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        statusFilter: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  let statusesToDisplay = Object.values(ShipmentStatusEnum);
  if (fromPendingQuotes) {
    statusesToDisplay = statusesToDisplay.filter((status) =>
      [
        ShipmentStatusEnum.RFQ_REQUESTED,
        ShipmentStatusEnum.RFQ_SUBMITTED,
      ].includes(status)
    );
  }

  return (
    <Form
      className="filters-form-wrapper"
      form={form}
      initialValues={{ statusFilter: defaultValue }}
      onValuesChange={onFormChange}
    >
      <Form.Item name="statusFilter">
        <Checkbox.Group>
          <div className={`filter-input-wrapper ${layout}`}>
            {statusesToDisplay.map(
              (status: ShipmentStatusEnum, index: number) => (
                <div className="input-space-wrapper" key={status}>
                  <Checkbox value={status}>{splitState(status)}</Checkbox>
                </div>
              )
            )}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default StatusFilter;
