import { SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Row,
  Space,
  Typography,
  RefSelectProps,
  Spin,
  Empty,
  Divider,
  Col,
} from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { useSearchShipmentQuery } from 'api/search';
import TrackingPreview from 'app/components/TrackingPreview';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import {
  IShipmentsResponseBody,
  ISingleShipmentResponse,
} from 'types/feature/shipments.types';

import { debounce } from 'utils/debounce';
import { formatOriginToDest } from 'utils/format-fields';
import { formatShipmentState } from 'utils/format-fields';

import './search-input.scss';

const SearchInput: FC = () => {
  const navigate = useNavigate();
  const inputRef = useRef<RefSelectProps>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isNewShipment, setisNewShipment] = useState(false);

  const [searchResults, setSearchResults] = useState<
    {
      label: string | JSX.Element;
      value: string | undefined;
    }[]
  >([]);
  const { isFetching: isSearching, data } = useSearchShipmentQuery(
    { keyword: searchedValue, page: 1, limit: 5 },
    {
      skip: !searchedValue,
      refetchOnMountOrArgChange: true,
    }
  );

  const setOptions = useCallback(
    (data: IShipmentsResponseBody | ISingleShipmentResponse) => {
      if ('items' in data) {
        setisNewShipment(false);
        setSearchResults(
          data.items.map((shipment: IShipmentEntity) => {
            return {
              label: (
                <Space direction="vertical" className="label-wrapper">
                  <Row justify="space-between" className="label-title">
                    <Typography.Text type="secondary">
                      <Typography.Text type="secondary" strong>
                        {shipment.slug}
                        {shipment.shipmentReference
                          ? ` \n(${shipment.shipmentReference})`
                          : ''}
                      </Typography.Text>
                    </Typography.Text>

                    {formatShipmentState(shipment.status)}
                  </Row>
                  {formatOriginToDest(shipment.destination, shipment.origin)}
                </Space>
              ),
              value: `${shipment.slug}`,
              key: shipment.id,
            };
          }) ?? []
        );
      } else {
        setisNewShipment(true);
        setSearchResults([
          {
            label: (
              <div className="tracking-result">
                <Typography.Text strong type="secondary">
                  New Shipments
                </Typography.Text>
                <Divider />
                <Row
                  align={'middle'}
                  justify={'space-between'}
                  className="container-shipment-item"
                >
                  <Col className="tracking-info">
                    <Typography.Text>{searchedValue}</Typography.Text>

                    <Typography.Text strong>
                      {formatOriginToDest(data.destination, data.origin)}
                    </Typography.Text>
                  </Col>
                  <Col>{formatShipmentState(data.status)}</Col>
                </Row>
              </div>
            ),
            value: '',
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    if (data) {
      setOptions(data);
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const onSelectResponse = (value: string, option: BaseOptionType) => {
    if (!isNewShipment) {
      navigate(`/shipments/${value}`, {
        state: { shipmentId: option.key },
      });
    } else {
      setIsDrawerOpen(true);
    }
    setSearchedValue('');
  };

  const debouncedHandler = debounce((value: string) => {
    setSearchedValue(value);
  }, 700);

  const handleInputOnChange = useCallback(
    (value: string) => debouncedHandler(value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };
  useEffect(() => {
    if (!searchedValue) {
      setSearchResults([]);
    }
  }, [searchedValue]);
  const handleDropdownVisibilityChange = (open: boolean) => {
    if (!open) {
      setSearchedValue('');
      setSearchResults([]);
    }
  };

  return (
    <Row className="search-input-wrapper">
      <AutoComplete
        ref={inputRef}
        className="search-input"
        popupClassName="search-dropdown"
        placeholder="Search by Shipment ID, destination, origin ... "
        bordered={false}
        onSelect={onSelectResponse}
        onSearch={handleInputOnChange}
        onDropdownVisibleChange={handleDropdownVisibilityChange}
        options={[
          ...(searchResults.length
            ? searchResults
            : !isSearching && searchedValue.length
            ? [
                {
                  value: undefined,
                  label: (
                    <Empty description="No shipment found ! Try another key word ..." />
                  ),
                },
              ]
            : []),
          ...(isSearching
            ? [
                {
                  value: undefined,
                  label: <Spin size="small"> searching ...</Spin>,
                },
              ]
            : []),
        ]}
        allowClear
        onClear={() => {
          setSearchedValue('');
        }}
      />
      <Button icon={<SearchOutlined />} type="ghost" onClick={handleClick} />
      <TrackingPreview
        shipment={data && !('items' in data) ? data : undefined}
        isOpen={isDrawerOpen}
        onClose={onCloseDrawer}
      />
    </Row>
  );
};

export default SearchInput;
