import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useGetUserTableSettingQuery } from 'api/user-table-columns';
import { useEffect, useState } from 'react';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

/**
 * A react hook that handles the adjustment (filter) of columns in the table.
 * @param {UserTablesEnum} tableName - The name of the target table.
 * @param {IAdjustColumns[]} columns - All columns of the target table.
 */

export interface IAdjustColumns {
  value: string;
  label: string;
}

export const useAdjustColumns = (
  columns: IAdjustColumns[],
  tableName: UserTablesEnum
) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const { data: setting, isSuccess: isSettingRetrieved } =
    useGetUserTableSettingQuery({
      tableName,
    });

  useEffect(() => {
    if (isSettingRetrieved) {
      if (setting.length) {
        setSelectedColumns(setting);
      } else {
        setSelectedColumns(columns.map((col) => col.value));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, isSettingRetrieved]);

  const selectColumns = (selected: CheckboxValueType[]) => {
    setSelectedColumns(selected as unknown as string[]);
  };

  return { selectedColumns, selectColumns };
};
