/* eslint-disable */
import Icon from '@ant-design/icons';
import { render } from '@testing-library/react';
import {
  Alert,
  Button,
  Modal,
  Select,
  Typography,
  Upload,
  UploadProps,
  message,
} from 'antd';
import {
  useDeleteFileByIdMutation,
  useLazyGetFilesByShipmentQuery,
  useUpdateMultipleFilseByIdMutation,
} from 'api/file';
import { useUploadShipmentDocumentMutation } from 'api/shipment';
import { set } from 'husky';
import React, { useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  setIsStartTracking,
  setShipmentId,
  setShipmentSlug,
  setFileUrl,
} from 'state/slices/trackingByAi.slice';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { IFileBaseEntity } from 'types/entities/files.entity';
import {
  IMultipleUploadFileDataSourcDef,
  IUploadDocumentModalProps,
  IUploadFileMethodDef,
  IUploadFilePromise,
} from 'types/feature/shipments.types';

import { addDocumentEvent, uploadDocumentsEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { formatDocumentType, splitState } from 'utils/format-fields';

import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera-icon-filled.svg';
import MultipleFileUpload from '../FileUpload';
import './upload-document-modal.scss';

const compilePromiseArrayResponse = (
  promisesResponse: IUploadFilePromise[]
) => {
  // compile promises responses to IMultipleUploadFileDataSourcDef[]
  let computedFileResponse: IMultipleUploadFileDataSourcDef[] = [];
  promisesResponse.forEach((promise) => {
    if (promise.success) {
      promise.data.map((file) => computedFileResponse.push(file));
    }
  });

  return computedFileResponse;
};

const MultipleDocsUploadModal: React.FC<IUploadDocumentModalProps> = ({
  actionButton,
  shipmentSlug,
  shipmentId,
  onUploadComplete,
  isMultiple,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [multipleFiles, setMultipleFiles] = useState<
    IMultipleUploadFileDataSourcDef[]
  >([]);

  const [uploadDocument, { isLoading }] = useUploadShipmentDocumentMutation();
  const [refetchFilesByShipment] = useLazyGetFilesByShipmentQuery();
  const [updateMultipleFiles, { isLoading: uploadMultipleFilesLoading }] =
    useUpdateMultipleFilseByIdMutation();
  const [deleteFile] = useDeleteFileByIdMutation();

  const addTrackingByAi = (
    shipmentId: string,
    shipmentSlug: string | undefined,
    fileUrl: string
  ) => {
    batch(() => {
      dispatch(setShipmentId(shipmentId));
      dispatch(setShipmentSlug(shipmentSlug || ''));
      dispatch(setFileUrl(fileUrl));
      dispatch(setIsStartTracking(true));
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = async () => {
    if (isLoading) {
      message.warning('Please wait while your files are being uploaded!');
      return;
    }

    await refetchFilesByShipment(shipmentId);
    setIsModalOpen(false);
    setMultipleFiles([]);
  };

  const uploadFile: IUploadFileMethodDef = async (fileData) => {
    const formdata = new FormData();
    formdata.append('file', fileData.file);
    formdata.append('type', '');
    formdata.append('shipmentId', shipmentId);

    try {
      const payload = await uploadDocument(formdata).unwrap();
      // Handle the successful upload
      return {
        success: true,
        data: payload.map((resp) => ({
          ...resp,
          file: fileData.file,
        })),
      };
    } catch (error: any) {
      displayErrors(error, { title: 'Document upload error' });
      // Reject the promise if there is an error during upload
      return {
        success: false,
        error,
        data: [fileData],
      };
    }
  };

  const onMultipleFileAccept = async (
    files: IMultipleUploadFileDataSourcDef[]
  ) => {
    // Upload file one by one to avoid dealy and timeout for large files.
    const uploadPromises = files.map(uploadFile);
    const promisesResponse = await Promise.all(uploadPromises);

    setMultipleFiles([
      ...multipleFiles,
      ...compilePromiseArrayResponse(promisesResponse),
    ]);

    addDocumentEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      shipment_id: shipmentId,
      company_id: user?.companyId,
    });
  };

  const onMultipleFileSave = async (
    files: IMultipleUploadFileDataSourcDef[]
  ) => {
    const payload: IFileBaseEntity[] = files.map((file) => ({
      id: file.id,
      name: file.name,
      type: file.type,
    }));

    try {
      // Saves multiple files name and type by id
      await updateMultipleFiles(payload).unwrap();

      uploadDocumentsEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
        document_ids: payload.map((file) => file.id),
      });

      // Filter tracking file and update tracking by AI
      const filterTrackingFile = files.find(
        (file) =>
          file.type === IDocumentTypeEnum.FINAL_MBL ||
          file.type === IDocumentTypeEnum.DRAFT_MBL
      );
      if (filterTrackingFile) {
        addTrackingByAi(shipmentId, shipmentSlug, filterTrackingFile.url);
      }

      // Adds list of file as attachment in communication
      if (onUploadComplete) {
        onUploadComplete(files.map((file) => file.url));
      }
      handleCancel();
    } catch (error: any) {
      displayErrors(error, { title: 'Error on saving multile files' });
    }
  };

  const onFileDelete = async (
    files: IMultipleUploadFileDataSourcDef[],
    index: number
  ) => {
    try {
      await deleteFile(files[index].id).unwrap();
      message.success('The document was successfully removed !');

      const updatedFiles = [...files];
      updatedFiles.splice(index, 1);
      setMultipleFiles(updatedFiles);
    } catch (error: any) {
      displayErrors(error, { title: 'Error on deleting file' });
    }
  };

  return (
    <div>
      <Button type="default" onClick={showModal} {...actionButton}>
        {actionButton.text}
      </Button>
      <Modal
        title={
          <Typography.Title level={3} className="m-0">
            Upload Document
          </Typography.Title>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={600}
        destroyOnClose
      >
        <MultipleFileUpload
          shipmentId={shipmentId}
          isLoading={isLoading || uploadMultipleFilesLoading}
          defaultValues={multipleFiles}
          onAccept={onMultipleFileAccept}
          onSave={onMultipleFileSave}
          onDelete={onFileDelete}
        />
      </Modal>
    </div>
  );
};

export default MultipleDocsUploadModal;
