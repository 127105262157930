import { createSlice } from '@reduxjs/toolkit';
import { ICreatePurchaseOrderForm } from 'types/feature/purchase-orders.types';

export type TInitialState = {
  createdPurchaseOrderResp: {
    slug: string;
    id: string;
  } | null;
} & ICreatePurchaseOrderForm;

const initialState: TInitialState = {
  createdPurchaseOrderResp: null,
  orderDetailsForm: null,
  orderLinesForm: null,
};

export const createPurchaseOrderSlice = createSlice({
  name: 'createPurchaseOrder',
  initialState,
  reducers: {
    setPurchaseOrderResp: (state, action) => {
      state.createdPurchaseOrderResp = action.payload;
    },
    setOrderDetailsForm: (state, action) => {
      state.orderDetailsForm = action.payload;
    },
    setOrderLinesForm: (state, action) => {
      state.orderLinesForm = action.payload;
    },
    resetPurchaseOrder: (state) => {
      state.createdPurchaseOrderResp = initialState.createdPurchaseOrderResp;
      state.orderDetailsForm = initialState.orderDetailsForm;
      state.orderLinesForm = initialState.orderLinesForm;
    },
  },
});

export const {
  setOrderDetailsForm,
  setOrderLinesForm,
  setPurchaseOrderResp,
  resetPurchaseOrder,
} = createPurchaseOrderSlice.actions;

export default createPurchaseOrderSlice.reducer;
