import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Typography,
} from 'antd';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IMessagesHeaderProps } from 'types/entities/message.entity';

import { shipmentIdClickEvent } from 'utils/analytics-events';
import { formatOriginToDest } from 'utils/format-fields';

import { ReactComponent as MoreIcon } from '../../../../assets/menuIcons/more.svg';

const MessagesHeader: FC<IMessagesHeaderProps> = ({
  selectedMessage,
  handleShowMembers,
}) => {
  const items: MenuProps['items'] = [
    {
      label: <div onClick={() => handleShowMembers()}>Members</div>,
      key: 'members',
    },
  ];
  const user = useSelector((state: RootState) => state.user.user);
  const handleShipmentSlugClick = () => {
    shipmentIdClickEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
    });
  };
  return (
    <Card className="header-card" bordered={false}>
      <Row>
        <Col className="shipment-icon">
          {displayFreighMethod(selectedMessage.shipmentType.modality, false)}
        </Col>
        <Row justify={'space-between'} style={{ flex: 1 }}>
          <Col className="header-content">
            <Typography.Title level={5}>
              {formatOriginToDest(
                selectedMessage.destination,
                selectedMessage.origin
              )}
            </Typography.Title>
            <Space>
              <Link
                onClick={handleShipmentSlugClick}
                to={`/shipments/SH-${selectedMessage.slug}`}
              >
                Shipment {selectedMessage.slug}
              </Link>
              {selectedMessage.shipmentReference && (
                <div className="shipment-reference">
                  <Divider type="vertical" />
                  {selectedMessage.shipmentReference}
                </div>
              )}
            </Space>
          </Col>
          <Col>
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button type="text">
                <MoreIcon />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default MessagesHeader;
