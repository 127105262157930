import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Col,
  InputNumber,
  Button,
  Space,
  Typography,
  Input,
  Divider,
  Select,
  Modal,
  notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDeletePurchaseOrderItemByIdMutation } from 'api/purchase-order';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import { currencyOptions } from 'app/components/Proposal/Fees/fees.const';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';
import {
  IOrderLinesForm,
  IOrderLinesFormProps,
  ITotalItemsState,
} from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import { computeTotals } from '../order-creation.utils';

const { Text } = Typography;
const { confirm } = Modal;

const OrderLinesForm: FC<IOrderLinesFormProps> = ({
  form,
  defaultValues,
  isEditView,
  footer,
}) => {
  const [total, setTotal] = useState<ITotalItemsState>({
    volume: 0,
    weight: 0,
    price: '0',
    quantity: 0,
    boxes: 0,
  });
  const [deleteLineItem] = useDeletePurchaseOrderItemByIdMutation();

  const onDeleteClick = (id: string, remove: () => void) => {
    if (isEditView && id) {
      confirm({
        title: 'Confirmation',
        okText: 'Delete',
        okType: 'danger',

        icon: null,
        content: 'Are you sure you want to delete this order item ?',
        onOk: async () => {
          await deleteLineItem({ id, poId: defaultValues.purchaseOrderId })
            .unwrap()
            .then(() => {
              remove();
              notification.success({
                message: 'Order Item Deleted',
                description: `The selected order line item has been deleted successfully ! `,
              });
            })
            .catch((error) => displayErrors(error));
        },
      });
    }
  };

  const calculateTotals = useCallback((items: IPurchaseOrderItemEntity[]) => {
    const totals = computeTotals(items ?? []);
    setTotal(totals);
  }, []);

  const updateOrderItemsTotals = (items: IPurchaseOrderItemEntity[]) => {
    const newItems = items?.map((item: IPurchaseOrderItemEntity) => {
      return {
        ...item,
        totalAmount: (item.requestedQuantity ?? 0) * (item.unitPrice ?? 0),
      };
    });
    form.setFieldValue('items', newItems);
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onFormValuesChange = (changedValue: any, values: IOrderLinesForm) => {
    const totals = computeTotals(values.items ?? []);
    setTotal(totals);
    updateOrderItemsTotals(values.items);
  };

  useEffect(() => {
    if (defaultValues.items) {
      calculateTotals(defaultValues.items);
      updateOrderItemsTotals(defaultValues.items);
    }
    // eslint-disable-next-line
  }, [defaultValues, calculateTotals]);

  const renderOrderItems = () => (
    <>
      <Form.List
        name="items"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error('Insert at least 1 order line')
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <>
                <Row key={field.key} gutter={[12, 1]}>
                  <Col span={3}>
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      label="Ref n°"
                      name={[field.name, 'reference']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message: "Please input the order item's reference.",
                        },
                      ]}
                    >
                      <Input placeholder="ex. 00-23AZ" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      label="HS Code"
                      name={[field.name, 'hsCode']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="ex. 45000xxx" />
                    </Form.Item>
                  </Col>
                  <Col span={17}>
                    <Form.Item name={[field.name, 'id']} hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      label="Description"
                      name={[field.name, 'description']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                          message: "Please input the order item's description.",
                        },
                      ]}
                    >
                      <TextArea placeholder="ex. Shoes, Red, Lether" />
                    </Form.Item>
                  </Col>
                  <Col span={1} className="dynamic-delete-order-button">
                    {fields.length > 1 ? (
                      <Button
                        icon={<DeleteFilled />}
                        onClick={() => {
                          const id = form.getFieldValue([
                            'items',
                            field.name,
                            'id',
                          ]);

                          if (id) onDeleteClick(id, () => remove(field.name));
                          else remove(field.name);
                        }}
                        danger
                      ></Button>
                    ) : null}
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      label="Country of Origin"
                      name={[field.name, 'coo']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="ex. India" />
                    </Form.Item>
                  </Col>

                  {/* <Col span={3}>
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'weightPerUnit']}
                      validateTrigger={['onChange', 'onBlur']}
                      label="Weight"
                      rules={[
                        {
                          required: false,
                          message:
                            'Please input the weight or delete this line.',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter weight"
                        className="custom-input"
                        min={0}
                        precision={2}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                   <Col span={3}>
                    <Form.Item
                      label="Volume"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'volumePerUnit']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                          message:
                            'Please input the volume or delete this line.',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter volume"
                        className="custom-input"
                        min={0}
                        precision={2}
                        type="number"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col span={3}>
                    <Form.Item
                      label="Boxes"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'boxes']}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <InputNumber
                        placeholder="Enter boxes"
                        className="custom-input"
                        min={0}
                        precision={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="Quantity"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'requestedQuantity']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message:
                            'Please input the quantity or delete this line.',
                        },
                        ({ getFieldValue, getFieldsValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              (getFieldValue([
                                'items',
                                field.name,
                                'bookedQuantity',
                              ]) ?? 0) <= value
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'The booked quantity should be greater or equal to requested quantity ! Input a valid quantity ..'
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter quantity"
                        min={1}
                        precision={0}
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'bookedQuantity']}
                      hidden
                    >
                      <InputNumber
                        placeholder="Enter quantity"
                        min={1}
                        precision={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="Price"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'unitPrice']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                          message:
                            'Please input the unit price or delete this line.',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter price"
                        className="custom-input"
                        min={0.01}
                        precision={2}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, 'currencyCode']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message:
                            'Please select currency or delete this line.',
                        },
                      ]}
                      label="Currency"
                    >
                      <Select
                        placeholder="Currency"
                        options={currencyOptions}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      label="Total Volume"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'totalVolume']}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <InputNumber
                        placeholder="Total Volume"
                        min={0}
                        precision={2}
                        type="number"
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="Total Weight"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'totalWeight']}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <InputNumber
                        placeholder="Total Weight"
                        min={0}
                        precision={2}
                        type="number"
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="Total Price"
                      {...formItemLayoutWithOutLabel}
                      name={[field.name, 'totalAmount']}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <InputNumber
                        placeholder="Total price"
                        min={0}
                        precision={2}
                        type="number"
                        contentEditable={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </>
            ))}

            <Form.Item>
              <Button
                type="link"
                onClick={add}
                icon={<PlusCircleOutlined />}
                size="large"
              >
                Add more
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row justify="space-between" align={'middle'}>
        <Space direction="vertical" className="units-footer__wrapper">
          <Text className="units-footer__title">Total Boxes</Text>
          <div className="units-footer__amount-wrapper">
            <Text className="units-footer__amount">{total.boxes}</Text>
          </div>
        </Space>

        <Space direction="vertical" className="units-footer__wrapper">
          <Text className="units-footer__title">Total Quantity</Text>
          <div className="units-footer__amount-wrapper">
            <Text className="units-footer__amount">{total.quantity}</Text>
          </div>
        </Space>

        <Space direction="vertical" className="units-footer__wrapper">
          <Text className="units-footer__title">Total Weight</Text>
          <div className="units-footer__amount-wrapper">
            <Text className="units-footer__amount">{total.weight} Kg</Text>
          </div>
        </Space>

        <Space direction="vertical" className="units-footer__wrapper">
          <Text className="units-footer__title">Total Volume</Text>
          <div className="units-footer__amount-wrapper">
            <Text className="units-footer__amount">{total.volume} cm³</Text>
          </div>
        </Space>

        <Space direction="vertical" className="units-footer__wrapper">
          <Text className="units-footer__title">Total Price</Text>
          <div className="units-footer__amount-wrapper">
            <Text className="units-footer__amount">{total.price ?? 0}</Text>
          </div>
        </Space>
      </Row>
    </>
  );

  return (
    <DashboardCard
      large
      headerTitle="Order Items"
      fitContent
      rightAccessory={footer}
    >
      <Form
        {...(defaultValues && { initialValues: defaultValues })}
        layout="vertical"
        form={form}
        onValuesChange={onFormValuesChange}
        scrollToFirstError
      >
        {renderOrderItems()}
      </Form>
    </DashboardCard>
  );
};

export default OrderLinesForm;
