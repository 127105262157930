import { useChecklistItem } from '@dopt/react-checklist';
import { Button, Form, notification } from 'antd';
import {
  useCreateShipmentMutation,
  useDeleteShipmentItemByIdMutation,
} from 'api/shipment';
import Accordion, { IAccordionItemDef } from 'app/design-system/Accordion';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  setAdditionalInfoForm,
  setCargoDetailsForm,
  setCreatedShipmentResp,
  setShipmentDetailsForm,
} from 'state/slices/create-shipment.slice';
import {
  IShipmentEntity,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import {
  IAdditionalInfoForm,
  ICargoDetailsForm,
  IShipmentCreationAccordionProps,
  IShipmentDetailsForm,
  ShipmentInviteTypeEnum,
} from 'types/feature/create-shipment.types';
import {
  ShipmentCreationFormTabEnum,
  THandleConfirmMethod,
} from 'types/feature/shipment-onboarding';

import { displayErrors } from 'utils/error-notification';
import { isAirTransportation } from 'utils/shipment-type';

import { ReactComponent as EnterIcon } from '../../../../assets/iconsNew/enter-Icons.svg';
import CargoDetailsFormCompact from '../CargoDetailsForm/CargoDetailsFormCompact';
import AdditionalInfoForm, {
  AdditionalInfoFormRef,
} from '../ShipmentCreationForm/AdditionalInfoForm';
import {
  computeDefaultCargoDetails,
  computeDefaultShipmentDetails,
} from '../ShipmentCreationForm/shipment.utils';
import ShipmentDetailsFormCompact from '../ShipmentDetailsForm/ShipmentDetailsFormCompact';
import { computeCreateShipmentPayload } from '../shipment-creation';

const formInputWarning = () =>
  notification.warning({
    message: 'Wrong inputs',
    description: 'Make sure to fill all mandotary fields correctly!',
  });

const ShipmentCreationAccordion: FC<IShipmentCreationAccordionProps> = ({
  onCreateSuccess,
  defaultShipment,
}) => {
  const dispatch = useDispatch();
  const createShipmentState = useSelector(
    (state: RootState) => state.createShipment
  );

  const [cargoDetailsForm] = Form.useForm<ICargoDetailsForm>();
  const [shipmentDetailsForm] = Form.useForm<IShipmentDetailsForm>();
  const [additionalInfoForm] = Form.useForm<IAdditionalInfoForm>();
  const [activeKey, setActiveKey] = useState<string>(
    ShipmentCreationFormTabEnum.CARGO_DETAILS_FORM
  );

  const additionalInfosRef = useRef<AdditionalInfoFormRef>(null); // create the ref

  const [createShipment, { isLoading }] = useCreateShipmentMutation();

  const [deleteCargoUnit] = useDeleteShipmentItemByIdMutation();

  const validateUpdateForm: THandleConfirmMethod = async ({
    currentKey,
    nextKey,
  }) => {
    try {
      switch (currentKey) {
        case ShipmentCreationFormTabEnum.CARGO_DETAILS_FORM: {
          const cargoDetailsFormValues =
            await cargoDetailsForm.validateFields();

          dispatch(
            setCargoDetailsForm({
              ...cargoDetailsFormValues,
              cargoReadyDate: cargoDetailsFormValues.cargoReadyDate
                .format('YYYY-MM-DDThh:mm:ss.00Z')
                .toString(),
            })
          );
          break;
        }
        case ShipmentCreationFormTabEnum.SHIPMENT_DETAILS_FORM: {
          const shipmentDetailsFormValues =
            await shipmentDetailsForm?.validateFields();

          dispatch(setShipmentDetailsForm(shipmentDetailsFormValues));
          break;
        }
        case ShipmentCreationFormTabEnum.ADDITIONAL_INFO: {
          const additionalInfoFormValues =
            await additionalInfoForm?.validateFields();
          additionalInfoForm.submit();

          dispatch(setAdditionalInfoForm(additionalInfoFormValues));
          break;
        }
      }

      if (nextKey) {
        setActiveKey(nextKey);
      }
    } catch (e: any) {
      formInputWarning();
    }
  };

  const handleKeyChange = async (nextKey: string, currentKey: string) => {
    validateUpdateForm({ currentKey, nextKey });
  };

  const handleCreate = async (currentKey: string) => {
    await validateUpdateForm({ currentKey });

    if (createShipmentState) {
      const shipmentPayload = computeCreateShipmentPayload(
        createShipmentState,
        null
      ) as Partial<IShipmentEntity>;

      const invalidFormKey = [
        ShipmentCreationFormTabEnum.CARGO_DETAILS_FORM,
        ShipmentCreationFormTabEnum.SHIPMENT_DETAILS_FORM,
      ].find((form) => createShipmentState[form] === null);

      try {
        if (invalidFormKey) {
          setActiveKey(invalidFormKey);
          formInputWarning();
          return;
        }

        const resp = await createShipment({
          body: {
            ...shipmentPayload,
            attachments: additionalInfosRef?.current?.getAttahments() ?? [],
          },
          params: undefined,
        }).unwrap();

        dispatch(setCreatedShipmentResp(resp));

        notification.success({
          message: 'Shipment Created successfully!',
        });
        onCreateSuccess();
      } catch (e: any) {
        displayErrors(e, { title: 'Shipment Creation Error' });
      }
    }
  };

  const handleCargoDetailsConfirm = () => {
    setActiveKey(ShipmentCreationFormTabEnum.SHIPMENT_DETAILS_FORM);
  };

  const handleShipmentDetailsConfirm = () => {
    setActiveKey(ShipmentCreationFormTabEnum.SHIPMENT_DETAILS_FORM);
  };

  const items: IAccordionItemDef[] = [
    {
      key: ShipmentCreationFormTabEnum.CARGO_DETAILS_FORM,
      label: 'Cargo Units',
      children: (
        <CargoDetailsFormCompact
          defaultValues={computeDefaultCargoDetails(defaultShipment, false)}
          form={cargoDetailsForm}
          isAirShipment={isAirTransportation(
            defaultShipment?.shipmentType?.title as ShipmentTypeEnum
          )}
          isEditView={false}
          onConfirm={handleCargoDetailsConfirm}
        />
      ),
    },
    {
      key: ShipmentCreationFormTabEnum.SHIPMENT_DETAILS_FORM,
      label: 'Shipment Details',
      children: (
        <ShipmentDetailsFormCompact
          form={shipmentDetailsForm}
          defaultValues={computeDefaultShipmentDetails(defaultShipment)}
          isFieldEditDisabled={false}
          isEditView={false}
          proposalParams={{ proposalId: '', email: '' }}
          deleteCargoUnitMutation={deleteCargoUnit}
          viewShipmentTypeAndCargoDetails={true}
          onConfirm={handleShipmentDetailsConfirm}
          {...(defaultShipment && {
            defaultShipmentType:
              defaultShipment?.acceptedShipmentType ??
              defaultShipment?.shipmentType,
          })}
        />
      ),
    },
    {
      key: ShipmentCreationFormTabEnum.ADDITIONAL_INFO,
      label: 'Additional Information',
      children: (
        <AdditionalInfoForm
          ref={additionalInfosRef}
          form={additionalInfoForm}
          defaultValues={{
            additionalInfo: defaultShipment?.additionalInfo ?? '',
            inviteType: ShipmentInviteTypeEnum.FORWARDER,
            shipmentReference: defaultShipment?.shipmentReference ?? '',
          }}
          isEditView={false}
          isInviteTypeDisabled={true}
          isCompact={true}
        />
      ),
    },
  ];

  return (
    <Accordion
      items={items}
      activeKey={activeKey}
      onChange={handleKeyChange}
      type="outlined"
      footer={(index, currentKey) =>
        items.length - 1 === index ? (
          <Button
            onClick={() => handleCreate(currentKey)}
            loading={isLoading}
            icon={<EnterIcon />}
          >
            Create Shipment
          </Button>
        ) : (
          <Button
            onClick={() =>
              validateUpdateForm({
                nextKey: `${items[index + 1]?.key}`,
                currentKey,
              })
            }
            icon={<EnterIcon />}
          >
            Confirm Details
          </Button>
        )
      }
    />
  );
};

export default ShipmentCreationAccordion;
