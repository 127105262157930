import React, { FC } from 'react';

import './table-description-list.scss';

export interface ITableDescriptionListProps {
  label: string | number | JSX.Element;
  description: string | number | JSX.Element;
  isVertical?: boolean;
  className?: string;
}

const TableDescriptionList: FC<ITableDescriptionListProps> = ({
  label,
  description,
  isVertical,
  className,
}) => {
  return (
    <div
      className={`table-description-list-wrapper ${isVertical && 'vertical'} ${
        className ?? ''
      }`}
    >
      <label className="label">{label}</label>
      <div className="description">{description}</div>
    </div>
  );
};

export default TableDescriptionList;
