import { DoptProvider } from '@dopt/react';
import { useIdentifyUser } from '@dopt/react-users';
import { Layout } from 'antd';
import { DOPT_BLOCK_API_KEY } from 'api/common/config';
import { useGetProfileQuery } from 'api/user';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'state/slices/user.slice';

import { userGroupEvent, userIdentifyEvent } from 'utils/analytics-events';

import Content from '../Content';
import Header from '../Header';
import SiderNavigation from '../Sider';
// style
import './main.style.scss';

// component
const Main: FC = () => {
  const dispatch = useDispatch();
  const { data: userData, isSuccess } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const user = useMemo(
    () => ({
      identifier: userData?.data.id || '',
      properties: {
        email: userData?.data.email || '',
        name: userData?.data.fullname || '',
        company: userData?.data.company || '',
        role: userData?.data.userRole || '',
        acountCreationDate: userData?.data.createdAt,
      },
    }),
    [userData]
  );

  /**
   * Identify the example user to Dopt the first time the App loads.
   */
  const userId = useIdentifyUser(user);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      const userId = userData.data.id;
      const companyId = userData.data.companyId;
      const companyName = userData.data?.company?.name || '';

      userIdentifyEvent(userId, {
        name: userData.data.fullname,
        email: userData.data.email,
        company: companyName,
        company_id: companyId,
      });

      userGroupEvent(companyId, {
        company_id: companyId,
        name: companyName,
        company: companyName,
      });
    }
  }, [userData]);

  // get user infos
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setCurrentUser({
          ...userData.data,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <DoptProvider
      apiKey={DOPT_BLOCK_API_KEY}
      userId={userId}
      flowVersions={{ announcement: 8 }}
    >
      <Layout className="layout-wrapper">
        <SiderNavigation />

        <Layout className="main-wrapper">
          <Header />
          <Content />
        </Layout>
      </Layout>
    </DoptProvider>
  );
};

export default Main;
