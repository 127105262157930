import { Row, Spin } from 'antd';
import { useGetCollaboratorByShipmentQuery } from 'api/collaborators';
import {
  useAddInvoiceItemCommentByIdMutation,
  useGetInvoiceItemCommentByIdQuery,
} from 'api/invoice-comments';
import { getMentionUserList } from 'app/components/ShipmentMessages/shipment-messages-util';
import CommentBox from 'app/design-system/CommentBox';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ICommentEntity } from 'types/entities/comment.entity';
import { IInvoiceItemCommentProps } from 'types/feature/invoice.types';
import { IMentionInput, TMentionUserList } from 'types/feature/messages.type';

import { invoiceAddCommentEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

const InvoiceItemComments: FC<IInvoiceItemCommentProps> = ({
  invoiceId,
  invoiceItemId,
  shipmentId,
  onUpdate,
}) => {
  const [commentState, setCommentState] = useState<ICommentEntity[]>([]);
  const user = useSelector((state: RootState) => state.user.user);
  const [collaborators, setCollaborators] = useState<TMentionUserList | []>([]);

  const {
    data: invoiceItemCommentData,
    isLoading,
    error,
    isError,
  } = useGetInvoiceItemCommentByIdQuery(
    { invoiceId, invoiceItemId },
    { refetchOnMountOrArgChange: true }
  );

  const [addItemInvoiceComment, { isLoading: isAddingComment }] =
    useAddInvoiceItemCommentByIdMutation();

  const { data: ShipmentCollabs } =
    useGetCollaboratorByShipmentQuery(shipmentId);
  useEffect(() => {
    if (ShipmentCollabs) {
      setCollaborators(getMentionUserList(ShipmentCollabs));
    }
  }, [ShipmentCollabs]);

  const onAddComment = async (comment: IMentionInput) => {
    if (!comment) return;

    try {
      const resp = await addItemInvoiceComment({
        invoiceItemId,
        invoiceId,
        originalText: comment.originalText,
        markupText: comment.markupText,
        mentions: comment.mentions,
      }).unwrap();

      onUpdate(resp.data, invoiceItemId);

      invoiceAddCommentEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
        shipment_id: shipmentId,
        invoice_id: invoiceId,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Comment Error' });
    }
  };

  useEffect(() => {
    if (invoiceItemCommentData)
      setCommentState(invoiceItemCommentData.data.comments);
  }, [invoiceItemCommentData]);

  useEffect(() => {
    if (isError) displayErrors(error, { title: 'Error fetching comments' });
  }, [isError, error]);

  return (
    <>
      {isLoading ? (
        <Row className="py-3" justify="center">
          <Spin />
        </Row>
      ) : (
        <CommentBox
          comments={commentState}
          onAddComment={onAddComment}
          isLoading={isAddingComment}
          isExternal={false}
          userList={collaborators || []}
        />
      )}
    </>
  );
};

export default InvoiceItemComments;
