import { CurrencyEnum } from 'types/entities/fee.entity';

// TODO : replace this with an API , and save exchange rates in DB
export const exchangeRates: Record<CurrencyEnum, number> = {
  [CurrencyEnum.CNY]: 0.14,
  [CurrencyEnum.EUR]: 1.09,
  [CurrencyEnum.INR]: 0.012,
  [CurrencyEnum.JOD]: 1.41,
  [CurrencyEnum.KWD]: 3.26,
  [CurrencyEnum.AED]: 0.27,
  [CurrencyEnum.QAR]: 0.27,
  [CurrencyEnum.SAR]: 0.27,
  [CurrencyEnum.GBP]: 1.28,
  [CurrencyEnum.AUD]: 0.68,
  [CurrencyEnum.JPY]: 0.0071,
  [CurrencyEnum.CAD]: 0.76,
  [CurrencyEnum.OMR]: 2.6,
  [CurrencyEnum.BHD]: 2.66,
  [CurrencyEnum.USD]: 1.0, // Default to 1.0 for USD or other unsupported currencies
};

/**
 * Convert Price from given currncy to the official company's currency
 * @param {Currency} currentCurrency - current currency of price
 * @param {Currency} companyCurrency - company currency
 * @param {Number} price - The price
 * @return {Number} Converted price
 */
export function convertMoney(
  currentCurrency: CurrencyEnum | null,
  companyCurrency: CurrencyEnum | null,
  price: number | null
): number {
  const exchangeRatesKeys = Object.keys(exchangeRates);

  if (
    currentCurrency &&
    companyCurrency &&
    exchangeRatesKeys.indexOf(currentCurrency) !== -1 &&
    exchangeRatesKeys.indexOf(companyCurrency) !== -1
  ) {
    const currentToUSD = exchangeRates[currentCurrency];
    const usdToCompany = exchangeRates[companyCurrency];
    const convertedPrice =
      (parseFloat(price?.toString() || '0') * currentToUSD) / usdToCompany;
    return convertedPrice;
  } else return 0;
}
