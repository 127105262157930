import { Row, Col } from 'antd';
import { shipmentsApi } from 'api/shipment';
import {
  useGetAirTrackingByIdQuery,
  useGetTrackingByIdQuery,
  useUpdateAirTrackingByIdMutation,
} from 'api/tracking';
import TrackingList from 'app/components/Tracking/TrackingList';
import DashboardCard from 'app/design-system/DashboardCard';
import ShipmentsMap from 'app/design-system/map';
import React, { FC, useCallback, useEffect } from 'react';
import { useAppDispatch } from 'state/store';
import { IShipmentHistoryDef } from 'types/entities/shipment.entity';
import {
  IShipmentTrackingProps,
  ShipmentStatusEnum,
} from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';
import { isSeaTransportation } from 'utils/shipment-type';

const ShipmentTracking: FC<IShipmentTrackingProps> = ({
  shipment,
  showMap,
  cardOverlay,
}) => {
  const { id: shipmentId, acceptedShipmentType, shipmentType } = shipment;
  const dispatch = useAppDispatch();
  const isSeaTracking = isSeaTransportation(
    acceptedShipmentType?.title ?? shipmentType.title
  );
  const [getUpdatedAirTrackingEvent] = useUpdateAirTrackingByIdMutation({});

  // Tracking for SEA shipments
  const {
    data: trackingData,
    refetch: refetchTracking,
    isLoading,
    isError,
    isSuccess,
  } = useGetTrackingByIdQuery(shipmentId, {
    skip: !isSeaTracking || !shipmentId,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: airTrackingData,
    isLoading: isLoadingAirTracking,
    isError: isAirTrackingError,
    isSuccess: isSuccessTracking,
  } = useGetAirTrackingByIdQuery(shipmentId, {
    skip: isSeaTracking || !shipmentId,
    refetchOnMountOrArgChange: true,
  });

  const updateShipmentHistory = (
    history: IShipmentHistoryDef[],
    status: ShipmentStatusEnum
  ) => {
    if (history && status)
      dispatch(
        shipmentsApi.util.updateQueryData(
          'getShipmentsBySlug',
          shipment.slug,
          (draftShipment) => {
            draftShipment.shipmentHistory = history;
            draftShipment.status = status;
          }
        )
      );
  };

  const getAirTrackingUpdates = useCallback(async () => {
    if (shipment.id)
      await getUpdatedAirTrackingEvent(shipment.id)
        .unwrap()
        .then((data) => {
          updateShipmentHistory(data.shipmentHistory, data.status);
        })
        .catch((error) =>
          displayErrors(error, { title: 'Air tracking error' })
        );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment.id]);

  useEffect(() => {
    if (!isSeaTracking) {
      getAirTrackingUpdates();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeaTracking]);

  useEffect(() => {
    if (isSeaTracking) {
      refetchTracking();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeaTracking]);

  useEffect(() => {
    if (isSuccess && trackingData) {
      updateShipmentHistory(trackingData.shipmentHistory, trackingData.status);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, trackingData]);

  useEffect(() => {
    if (isSuccessTracking && airTrackingData) {
      updateShipmentHistory(
        airTrackingData.shipmentHistory,
        airTrackingData.status
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTracking, airTrackingData]);

  const renderTrackingList = () => (
    <TrackingList
      shipment={shipment}
      isError={isError || isAirTrackingError}
      isLoading={isLoading || isLoadingAirTracking}
      trackingData={
        isSeaTracking
          ? trackingData?.trackingEvents
          : airTrackingData?.trackingEvents
      }
      isAirTracking={!isSeaTracking}
    />
  );

  return (
    <>
      {showMap ? (
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <ShipmentsMap
              shipments={[shipment]}
              trackingData={isSeaTracking ? trackingData?.trackingEvents : []}
              className="tracking-map"
              type="SINGLE"
              polyline={trackingData?.polyline ? trackingData?.polyline : null}
            />
          </Col>

          <Col span={showMap ? 9 : 24}>
            {cardOverlay ? (
              <DashboardCard
                headerTitle="Live Tracking"
                fitContent
                noMargin
                className="overview-card-wrapper-1"
              >
                {renderTrackingList()}
              </DashboardCard>
            ) : (
              renderTrackingList()
            )}
          </Col>
        </Row>
      ) : (
        <TrackingList
          shipment={shipment}
          isError={isError || isAirTrackingError}
          isLoading={isLoading || isLoadingAirTracking}
          trackingData={
            isSeaTracking
              ? trackingData?.trackingEvents
              : airTrackingData?.trackingEvents
          }
          isAirTracking={!isSeaTracking}
        />
      )}
    </>
  );
};

export default ShipmentTracking;
