import { CheckCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Row, Table, Tag, Input, notification } from 'antd';
import {
  useGetRecentInvoiceCollaboratorQuery,
  useInviteInvoiceCollaboratorMutation,
} from 'api/invoice-collaborator';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IInvoiceCollaboratorTableDef,
  IInvoiceCollaboratorsProps,
} from 'types/feature/invoice.types';

import { invoiceAddCollaboratorEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { invoiceCollaboratorColumnsConst } from './collaborators.const';
import './invoice-collaborators.scss';

const { Search } = Input;

const InvoiceCollaborators: FC<IInvoiceCollaboratorsProps> = ({
  invoiceId,
  shipmentId,
}) => {
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    undefined
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableData, setTableData] = useState<IInvoiceCollaboratorTableDef[]>();
  const user = useSelector((state: RootState) => state.user.user);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: IInvoiceCollaboratorTableDef) => ({
      disabled: record.isAlreadyInvited, // Column configuration not to be checked
    }),
  };

  const {
    data: collaboratorsData,
    isLoading,
    isError,
    refetch: refetchGetCollaboratorByShipment,
  } = useGetRecentInvoiceCollaboratorQuery(
    { invoiceId, search: searchedValue?.trim() },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    const flatenedData = collaboratorsData?.data.recentCollaborators?.map(
      (c) => ({
        id: c.id,
        fullname: c.fullname,
        email: c.email,
        isAlreadyInvited: c.isAlreadyInvited,
        photo: c.photo,
      })
    );
    setTableData(flatenedData ?? []);
  }, [collaboratorsData]);

  const [
    inviteInvoiceCollaborator,
    {
      isLoading: isInvitating,
      isError: isInvitationError,
      error: invitationError,
      isSuccess: invitationSuccess,
    },
    // eslint-disable-next-line no-use-before-define
  ] = useInviteInvoiceCollaboratorMutation();

  // methods
  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setSearchedValue(undefined);
    }
  };

  const onAddCollaboratorClick = () => {
    inviteInvoiceCollaborator({
      invoiceId,
      body: {
        collaboratorIds: selectedRowKeys,
      },
    });

    setSelectedRowKeys([]);
  };

  const onSuccessInvitation = () => {
    refetchGetCollaboratorByShipment();
    setSelectedRowKeys([]);

    invoiceAddCollaboratorEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
      shipment_id: shipmentId,
      invoice_id: invoiceId,
    });
  };

  // hooks
  useEffect(() => {
    if (isInvitationError && invitationError) {
      displayErrors(invitationError);
    }
  }, [isInvitationError, invitationError]);

  useEffect(() => {
    if (invitationSuccess) {
      notification.success({ message: 'Invitation sent to collaborators !' });
      onSuccessInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationSuccess]);

  const allColumns = [
    ...invoiceCollaboratorColumnsConst,
    {
      key: 'action',
      render: (
        _: unknown,
        record: IInvoiceCollaboratorTableDef,
        index: number
      ) =>
        record.isAlreadyInvited && (
          <Tag color="success" icon={<CheckCircleOutlined />}>
            Invitation sent
          </Tag>
        ),
    },
  ];
  return (
    <>
      <Row justify="space-between" gutter={[0, 52]}>
        {/* TODO: API donot support search feature */}
        <Search
          placeholder="search"
          onSearch={(v: string) => setSearchedValue(v)}
          onChange={handleEmptySearch}
          allowClear
        />
      </Row>
      {isError ? (
        <Alert
          description="An error has occured while fetching recent collaborators .. "
          type="error"
          showIcon
        />
      ) : (
        <>
          <Table
            loading={isLoading}
            pagination={{
              pageSize: 6,
              showSizeChanger: false,
            }}
            className="collaborators-table"
            dataSource={tableData ?? []}
            columns={allColumns}
            rowSelection={rowSelection}
            showHeader={false}
            locale={{ emptyText: 'No recent collaborators added' }}
            rowKey={(item: IInvoiceCollaboratorTableDef) => item.id}
          />
          <Row justify="end" gutter={[0, 52]}>
            <Button
              type="primary"
              disabled={selectedRowKeys.length < 1 || isInvitating}
              loading={isInvitating}
              onClick={onAddCollaboratorClick}
            >
              Add Collaborators
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default InvoiceCollaborators;
