import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import { useDeleteProposalItemMutation } from 'api/proposal';
import {
  useDeleteExternalProposalItemMutation,
  useGetExternalProposalItemDescriptionQuery,
} from 'api/proposal-external.api';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IDescriptionOptions, IFeeEntity } from 'types/entities/fee.entity';
import {
  FeesDetailsProps,
  IProposalItemsForm,
} from 'types/feature/proposals.types';

import { displayErrors } from 'utils/error-notification';
import { capitalize } from 'utils/format-fields';

import {
  computeDescriptionOptions,
  computeTotalFees,
  currencyOptions,
  units,
} from './fees.const';
import './fees.style.scss';

const { Text } = Typography;
// let index = 0;

const FeesDetails: FC<FeesDetailsProps> = ({
  form,
  defaultValues,
  isExternalForwarder,
  isFieldEditDisabled = false,
  proposalParams,
}) => {
  const [totalFees, setTotalFees] = useState<string>('0.00');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const [descriptionOptions, setDescriptionOptions] = useState<
    IDescriptionOptions[]
  >([]);
  const [otherDescription, setOtherDescription] = useState<string>('');
  const otherDescriptionInputRef = useRef<InputRef>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null);

  const handleCloseSelect = () => {
    // Call the blur method on the Select component's ref to close it
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };
  const [deleteItem, { isLoading: isDeleteItemLoading }] =
    useDeleteProposalItemMutation();

  const [
    deleteExternalProposalItem,
    { isLoading: isDeleteExternalItemLoading },
  ] = useDeleteExternalProposalItemMutation();

  const { data: descriptionData, isLoading: descriptionDataLoading } =
    useGetExternalProposalItemDescriptionQuery();

  useEffect(() => {
    if (descriptionData) {
      const options: IDescriptionOptions[] = computeDescriptionOptions(
        descriptionData.descriptionOptions
      );

      if (defaultValues.length > 0) {
        defaultValues.forEach(({ description }) => {
          if (!description) {
            return;
          }
          const optionExist = options.some((o) => o.value === description);
          if (!optionExist) {
            options.push({
              label: capitalize(description),
              value: description,
            });
          }
        });
      }

      setDescriptionOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionData?.descriptionOptions, defaultValues]);

  useEffect(() => {
    form.setFieldsValue({ proposalItems: defaultValues });

    if (defaultValues.length > 0) {
      const total = computeTotalFees(defaultValues);
      setTotalFees(total);
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  const onValuesChange = (
    changedValues: Partial<IProposalItemsForm>,
    values: IProposalItemsForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === 'proposalItems') {
      const items = form.getFieldValue('proposalItems');
      const newItems = items.map((item: IFeeEntity) => {
        return { ...item, totalCost: (item.quantity ?? 0) * (item.rate ?? 0) };
      });

      form.setFieldValue('proposalItems', newItems);
      const total = computeTotalFees(items);
      setTotalFees(total);
    }
  };

  const onDeleteItem = async (
    remove: (index: number | number[]) => void,
    index: number
  ) => {
    const proposals = form.getFieldValue('proposalItems');

    const deleteCargoUnitMutation = isExternalForwarder
      ? deleteExternalProposalItem
      : deleteItem;

    if (proposals[index].id) {
      await deleteCargoUnitMutation({
        id: proposals[index].id,
        params: proposalParams,
      })
        .unwrap()
        .then(
          () => {
            notification.success({
              message: 'Proposal item has been deleted successfully !',
            });
            remove(index);
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            displayErrors(error, {
              title: 'Unable to delete this proposal item',
            });
          }
        );
    } else {
      remove(index);
    }
  };

  const onOtherDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherDescription(event.target.value);
  };

  const onAddOtherDescription = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    index
  ) => {
    e.preventDefault();
    const descriptionValue = otherDescription.toLocaleLowerCase();
    const optionExist = descriptionOptions.some(
      (o) => o.value === descriptionValue
    );

    if (!optionExist) {
      const newOptions = [
        ...descriptionOptions,
        {
          label: capitalize(descriptionValue) || `New item ${index}`,
          value: descriptionValue || `New item ${index}`,
        },
      ];
      setDescriptionOptions(newOptions);
    }

    const proposals = form.getFieldValue('proposalItems');
    proposals[index].description = descriptionValue;
    form.setFieldValue('proposalItems', proposals);

    setOtherDescription('');
    setTimeout(() => {
      otherDescriptionInputRef.current?.focus();
    }, 0);
    handleCloseSelect();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DashboardCard
      headerTitle="Fees"
      large
      className="proposal-fee-wrapper clear-background"
      fitContent
    >
      {descriptionDataLoading ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            proposalItems: defaultValues,
          }}
          scrollToFirstError
          onValuesChange={onValuesChange}
          name="proposal-fee-items"
        >
          {!isSmallScreen && (
            <Row
              style={{
                display: 'flex',
                marginBottom: 10,
              }}
              align="middle"
              gutter={[12, 12]}
            >
              <Col span={6}>
                <Text className="field-wrapper__text">Description</Text>
              </Col>
              <Col span={3}>
                <Text className="field-wrapper__text">Unit</Text>
              </Col>
              <Col span={3}>
                <Text className="field-wrapper__text">Quantity</Text>
              </Col>
              <Col span={3}>
                <Text className="field-wrapper__text">Rate</Text>
              </Col>
              <Col span={3}>
                <Text className="field-wrapper__text">Currency</Text>
              </Col>
              <Col span={4}>
                <Text className="field-wrapper__text">Total</Text>
              </Col>
            </Row>
          )}

          <Form.List
            name="proposalItems"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error('Insert at least 1 cargo details')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  // search a way to implement Form.List with Table
                  // TODO : check this https://github.com/gges5110/ant-design-table-form/blob/master/src/EditableTableForm.tsx
                  <Row key={field.key} gutter={[12, 12]}>
                    {descriptionData && (
                      <Col span={6} xs={12} md={8} lg={6}>
                        <Form.Item
                          label={isSmallScreen ? 'Description' : undefined}
                          name={[field.name, 'description']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Please enter description or delete this line.',
                            },
                          ]}
                        >
                          <Select
                            data-testid="proposal-description-select"
                            ref={selectRef}
                            options={descriptionOptions}
                            placeholder="Description"
                            disabled={isFieldEditDisabled}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                  <Input
                                    placeholder="Other description"
                                    ref={otherDescriptionInputRef}
                                    value={otherDescription}
                                    onChange={onOtherDescriptionChange}
                                    disabled={isFieldEditDisabled}
                                  />
                                  <Button
                                    disabled={!otherDescription}
                                    type="text"
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    onClick={(e: any) =>
                                      onAddOtherDescription(e, index)
                                    }
                                  >
                                    Submit
                                  </Button>
                                </Space>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={3} xs={12} md={8} lg={3}>
                      <Form.Item
                        label={isSmallScreen ? 'Unit' : undefined}
                        name={[field.name, 'measurementUnit']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please select unit or delete this line.',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Unit Measurement"
                          options={units}
                          disabled={isFieldEditDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3} xs={12} md={8} lg={3}>
                      <Form.Item
                        label={isSmallScreen ? 'Quantity' : undefined}
                        name={[field.name, 'quantity']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input quantity or delete this line.',
                          },
                          {
                            type: 'number',
                            min: 1,
                            message: 'Quantity must be greater than 0',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Quantity"
                          min={1}
                          precision={0}
                          type="number"
                          disabled={isFieldEditDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                      <Form.Item
                        label={isSmallScreen ? 'Rate' : undefined}
                        name={[field.name, 'rate']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input the rate or delete this line.',
                          },
                          {
                            type: 'number',

                            min: 0.01,
                            message: 'Rate must be greater than 0',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Rate"
                          min={0.01}
                          precision={2}
                          type="number"
                          disabled={isFieldEditDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={9} md={6} lg={3}>
                      <Form.Item
                        label={isSmallScreen ? 'Currency' : undefined}
                        name={[field.name, 'currency']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              'Please select currency or delete this line.',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Currency"
                          options={currencyOptions}
                          disabled={isFieldEditDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={3}>
                      <Form.Item
                        label={isSmallScreen ? 'Total' : undefined}
                        name={[field.name, 'totalCost']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input total or delete this line.',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Total"
                          min={0}
                          precision={2}
                          type="number"
                          disabled={isFieldEditDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={3} md={2} className="dynamic-delete-button">
                      {fields.length > 1 && (
                        <Button
                          icon={<DeleteFilled />}
                          onClick={() => onDeleteItem(remove, field.name)}
                          danger
                          disabled={
                            isDeleteItemLoading ||
                            isDeleteExternalItemLoading ||
                            isFieldEditDisabled
                          }
                        />
                      )}
                    </Col>
                    {isSmallScreen && fields.length - 1 !== index && (
                      <Divider />
                    )}
                  </Row>
                ))}
                <div className="fees-footer">
                  <Button
                    disabled={isFieldEditDisabled}
                    type="link"
                    onClick={add}
                    icon={<PlusCircleOutlined />}
                  >
                    Add more
                  </Button>
                  <Space direction="vertical">
                    <Text className="fees-footer__title">Total Amount</Text>
                    <div className="fees-footer__amount-wrapper">
                      <Text className="fees-footer__amount">{totalFees}</Text>
                    </div>
                  </Space>
                </div>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </DashboardCard>
  );
};

export default FeesDetails;
