import { FilterOutlined } from '@ant-design/icons';
import { Button, Divider, Empty, Row, Space, Spin, Typography } from 'antd';
import { useGetProposalsByShipmentQuery } from 'api/proposal';
import AcceptedProposalStoryModal from 'app/components/UserOnboarding/AcceptedProposalStoryModal';
import CheckboxList from 'app/design-system/CheckList';
import { useAdjustColumns } from 'app/hooks/adjustColumns.hook';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  IProposalEntity,
  IProposalFilters,
} from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { IProposalsProps } from 'types/feature/proposal.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import { adjustQuotesColumnsCTA, filterQuoteCTA } from 'utils/analytics-events';
import { isBiddingEnded } from 'utils/bidding-date-helper';
import { isViewer } from 'utils/collaborators';
import {
  canInviteForwarder,
  hasShipmentStarted,
  isForwarder,
} from 'utils/shipment-helper';

import { ProposalTable } from '../ProposalTable';
import { columnsOptions } from '../ProposalTable/proposal-table.const';
import { ProposalFilters } from './proposal-filters';
import './proposal.scss';

const Proposals: FC<IProposalsProps> = ({ shipment, onSubmitProposal }) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [proposalSearch, setProposalSearch] = useState<IProposalFilters>({});
  const [filterAcceptedProposalData, setFilterAcceptedProposalData] =
    useState<IProposalEntity>();

  const { selectColumns, selectedColumns } = useAdjustColumns(
    columnsOptions,
    UserTablesEnum.proposalTable
  );

  // segment events
  const onApplyFiltersCTAClick = (filters: string[]) => {
    if (currentUser) {
      filterQuoteCTA({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
        selected_filters: filters,
      });
    }
  };

  const onAdjustColumns = (filters: string[]) => {
    selectColumns(filters);
    if (currentUser) {
      adjustQuotesColumnsCTA({
        user_id: currentUser.id,
        email: currentUser.email,
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId,
        selected_filters: filters,
      });
    }
  };

  // methods
  const isSubmitActionAllowed =
    isForwarder(shipment.currentUserRole) &&
    !hasShipmentStarted(shipment.status);

  const isInviteActionEnabled =
    !isViewer(shipment.currentCollaborator.accessLevel) &&
    !isForwarder(shipment.currentUserRole) &&
    canInviteForwarder(shipment.status);

  const onStatusFilterChange = (value: string[], name: string) => {
    if (!value || value.length === 0) {
      // If value is empty, create a new object without the key
      const newProposalSearch = { ...proposalSearch };
      delete newProposalSearch[name];
      setProposalSearch(newProposalSearch);
      onApplyFiltersCTAClick(Object.keys(newProposalSearch));
    } else {
      const newFilters = { ...proposalSearch, [name]: value };
      setProposalSearch(newFilters);
      onApplyFiltersCTAClick(Object.keys(newFilters));
    }
  };
  const { data: proposalData, isLoading } = useGetProposalsByShipmentQuery(
    { shipmentId: shipment.id, ...proposalSearch },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (proposalData) {
      setFilterAcceptedProposalData(
        proposalData.find((p) => p.status === IQuoteStatus.ACCEPTED)
      );
    }
  }, [proposalData]);

  if (isLoading) {
    return (
      <div className="empty-proposal-wrapper">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      <Row className="shipment-qoutes-action-header" justify="space-between">
        <Space>
          <Typography.Text>
            Filters <Divider type="vertical" />
          </Typography.Text>
          <ProposalFilters
            proposalSearch={proposalSearch}
            shipmentId={shipment.id}
            onStatusFilterChange={onStatusFilterChange}
            onResetClick={() => setProposalSearch({})}
          />
        </Space>
        <div>
          {!isBiddingEnded(shipment.dueDate) && isInviteActionEnabled && (
            <Link
              to={`/shipments/${shipment.slug}/request-quote`}
              state={{ shipmentId: shipment.id }}
            >
              <Button type="primary">Invite Forwarder</Button>
            </Link>
          )}
          {!isBiddingEnded(shipment.dueDate) && isSubmitActionAllowed && (
            <Button
              type="primary"
              onClick={onSubmitProposal}
              loading={isLoading}
              disabled={isLoading}
            >
              Submit Quote
            </Button>
          )}
          <Divider type="vertical" />
          <CheckboxList
            options={columnsOptions}
            selectedItems={selectedColumns}
            onChange={onAdjustColumns}
            tableName={UserTablesEnum.proposalTable}
          >
            <Button icon={<FilterOutlined />}>Adjust Columns</Button>
          </CheckboxList>
        </div>
      </Row>
      {proposalData && proposalData.length > 0 ? (
        <div className="shipment-quotes-wrapper" data-testid="shipment-quotes">
          <ProposalTable
            shipment={shipment}
            filteredProposals={proposalData}
            selectedColumns={selectedColumns}
          />

          {filterAcceptedProposalData && (
            <AcceptedProposalStoryModal
              shipmentId={shipment.id}
              company={filterAcceptedProposalData.company}
            />
          )}
        </div>
      ) : (
        <div className="empty-proposal-wrapper">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Quotes Available"
          />
        </div>
      )}
    </>
  );
};

export default Proposals;
