export enum CurrencyEnum {
  USD = 'usd',
  AED = 'aed',
  EUR = 'eur',
  SAR = 'sar',
  CNY = 'cny',
  INR = 'inr',
  QAR = 'qar',
  JOD = 'jod',
  KWD = 'kwd',
  GBP = 'gbp',
  JPY = 'jpy',
  AUD = 'aud',
  CAD = 'cad',
  OMR = 'omr',
  BHD = 'bhd',
}

export enum UnitMeasurementEnum {
  PERBL = 'per_bl',
  PERCONTAINER = 'per_container',
  FIXD = 'fixd',
  QTY = 'qty',
  GRWT = 'grwt',
  CHWT = 'chwt',
  PRFR = 'prfr',
  STFE = 'stfe',
}

export interface IFeeEntity {
  tempId?: string;
  id?: string;
  description: string;
  quantity?: number;
  rate?: number;
  propsal?: number;
  currency: CurrencyEnum;
  measurementUnit: UnitMeasurementEnum;
  totalCost: number;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  proposalId: string;
  createdBy?: string | null;

  _diffType?: string;
}

export interface IFeeItemDescriptionEntity {
  descriptionOptions: string[];
}

export interface IDescriptionOptions {
  value: string;
  label: string;
}
