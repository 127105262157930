import { FormInstance, FormListFieldData } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { IExternalProposalParams } from 'types/entities/proposal.entity';
import {
  CargoTypeEnum,
  ICargoUnit,
  ILocation,
  IShipmentEntity,
  IShipmentTypeDef,
  ModalityEnum,
  ShipmentTypeEnum,
} from 'types/entities/shipment.entity';
import { IUserBaseEntity } from 'types/entities/user.entity';

import { ShipmentStatusEnum } from './shipments.types';

export enum ShipmentInviteTypeEnum {
  FORWARDER = 'forwarder',
  COLLABORATOR = 'collaborator',
}

export type TOnCreateShipmentParams = (
  item: Partial<IShipmentEntity>,
  inviteType: ShipmentInviteTypeEnum,
  hasExternalConnections: boolean
) => Promise<void>;

export interface ShipmentCreationFormProps {
  isLoading: boolean;
  isEditView?: boolean;
  defaultShipment: IShipmentEntity | null;
  openPreviewTab: (() => void) | null;
  onCreateShipment: TOnCreateShipmentParams;
}

export interface IPlaceOption {
  value: string;
  key: string;
  label: string;
}

export interface IShipmentCreatingFormDef extends IShipmentEntity {
  inviteType: string;
}

export interface ICargoDetailsForm {
  cargoType: CargoTypeEnum;
  nonStackable: boolean;
  destination: string;
  origin: string;
  incoterms: string;
  cargoReadyDate: dayjs.Dayjs;
  productId: string;
  eta: dayjs.Dayjs;
  etd: dayjs.Dayjs;
  autoUpdateEta: boolean;
  autoUpdateEtd: boolean;
  originFull: ILocation | undefined;
  destinationFull: ILocation | undefined;
  serviceMode: string;
  productCategoryName?: string;
}

export interface ICargoDetailsState {
  origin: ILocation | undefined;
  destination: ILocation | undefined;
}

export interface ICargoDetailsFormProps {
  defaultValues: Partial<ICargoDetailsForm> | null;
  form: FormInstance<ICargoDetailsForm>;
  isAirShipment: boolean;
  isEditView: boolean;
  previewTabCta?: JSX.Element;
  onConfirm?: () => void;
}

export interface ICargoUnitsTypeState {
  byContainer: boolean;
  byUnit: boolean;
}

export interface IShipmentDetailsForm {
  modality: ModalityEnum;
  cargoUnits?: ICargoUnit[];
  shipmentTypeId?: string;
  shipmentId?: string;
}

export interface IShipmentTypeState {
  id: string;
  title: ShipmentTypeEnum;
}

export interface ITotalCargoUnitState {
  volume?: string;
  weight?: string;
  charges?: string;
}

export interface IShipmentDetailsState {
  byContainer: boolean;
  byUnit: boolean;
}

export interface IShipmentDetailsFormProps {
  form: FormInstance<IShipmentDetailsForm>;
  defaultValues: IShipmentDetailsForm;
  defaultShipmentType?: IShipmentTypeDef;
  isEditView: boolean;
  footer?: JSX.Element;
  isFieldEditDisabled: boolean;
  proposalParams: IExternalProposalParams;
  deleteCargoUnitMutation: any; // TODO: add type
  viewShipmentTypeAndCargoDetails: boolean;
  onConfirm?: () => void;
}
export interface IAdditionalInfoForm {
  additionalInfo: string | null;
  shipmentReference?: string;
  inviteType: ShipmentInviteTypeEnum;
}
export interface IAdditionalInfoFormProps {
  form: FormInstance<IAdditionalInfoForm>;
  isEditView: boolean;
  defaultValues: IAdditionalInfoForm;
  isInviteTypeDisabled: boolean;
  isCompact?: boolean;
}

export interface IShipmentStatusForm {
  status: ShipmentStatusEnum;
  autoUpdateStatus: boolean;
}
export interface IShipmentStatusFormProps {
  form: FormInstance<IShipmentStatusForm>;
  defaultValues: IShipmentStatusForm;
}

export interface IBiddingDetailsForm {
  dueDate: Dayjs | null;
  dueTime: Dayjs | null;
}

export interface IBiddingDetailsFormProps {
  form: FormInstance<IBiddingDetailsForm>;
  defaultValues: IBiddingDetailsForm;
  isDisabled?: boolean;
  isCompact?: boolean;
}

export interface IContactDetailsForm {
  userId: string;
}

export interface IContactDetailsFormProps {
  form: FormInstance<IContactDetailsForm>;
  externalUserList: IUserBaseEntity[];
  previewTabCta?: JSX.Element;
}

export interface IFieldListFormProps {
  field: FormListFieldData;
  isDeleteActionEnabled?: boolean;
  onRemoveField?: (index: number | number[]) => void;
}

export interface IContainerFormProps extends IFieldListFormProps {
  isFieldActionsDisabled: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDimensionFormProps extends IContainerFormProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnitDimensionFormProps extends IContainerFormProps {}

export type TCreateShipmentForm = {
  cargoDetailsForm: ICargoDetailsForm | null;
  shipmentDetailsForm: IShipmentDetailsForm | null;
  additionalInfoForm: IAdditionalInfoForm | null;
};

export interface IShipmentCreationAccordionProps {
  onCreateSuccess: () => void;
  defaultShipment: IShipmentEntity | null;
}

type NameType = (string | number)[];

export type THandleSelectAcompany = (
  name: NameType
) => (value: string, id: string | undefined) => void;

export type TRenderForwarderFieldListProps = (
  props: {
    handleSelectAcompany: THandleSelectAcompany;
  } & IFieldListFormProps
) => JSX.Element;
