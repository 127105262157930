import dayjs from 'dayjs';
import { ServiceModeEnum } from 'types/entities/contract.entity';
import { IProductEntity } from 'types/entities/product.entity';
import {
  IShipmentEntity,
  IShipmentTypeEntity,
} from 'types/entities/shipment.entity';

const formatServiceMode = (serviceMode: {
  originServiceMode: string;
  destinationServiceMode: string;
}) => {
  const { originServiceMode, destinationServiceMode } = serviceMode;
  return Object.values(ServiceModeEnum).includes(
    originServiceMode as ServiceModeEnum
  ) &&
    Object.values(ServiceModeEnum).includes(
      destinationServiceMode as ServiceModeEnum
    )
    ? `${originServiceMode}/${destinationServiceMode}`
    : null;
};

export const formatShipmentInfos = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: Record<string, string | number | boolean | any>,
  products: IProductEntity[] | undefined,
  modalities: IShipmentTypeEntity | undefined
) => {
  const shipment: Partial<IShipmentEntity> = {
    serviceMode: response.serviceMode
      ? formatServiceMode(response.serviceMode)
      : undefined,
    modality: response.modality,
    product: response.products
      ? products?.find(
          (item) =>
            item.category.toLowerCase() === response.products.toLowerCase()
        )
      : undefined,
    cargoReadyDate: response.cargoReadyDate ?? dayjs(),
    cargoType: response.cargoType,
    cargoUnits: response.cargoUnits ?? [],
    shipmentType: response.modality
      ? response.shipmentType
        ? modalities?.[response.modality].find((item) => {
            return item.title === response.shipmentType.toLowerCase();
          })
        : modalities?.[response.modality][0]
      : undefined,
    nonStackable: response.NonStackable ?? false,
    destination: {
      country: response.Destination,
      code: '',
      city: '',
      latitude: 0,
      longitude: 0,
      serviceMode: response.serviceMode?.destinationServiceMode,
    },
    origin: {
      country: response.Origin,
      code: '',
      city: '',
      latitude: 0,
      longitude: 0,
      serviceMode: response.serviceMode?.originServiceMode,
    },
    incoterms: response.incoTerms,
    additionalInfo: response.additionalInfo,
    shipmentReference: response.shipmentReference,
  };

  return shipment;
};
