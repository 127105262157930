import { Row, Col, Form, Select, InputNumber, Input, Button } from 'antd';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import React, { FC } from 'react';
import {
  PackageTypeEnum,
  ModalityEnum,
  LengthMeasurementUnitEnum,
} from 'types/entities/shipment.entity';
import { UnitDimensionFormProps } from 'types/feature/create-shipment.types';

import { capitalize } from 'utils/format-fields';

import { ReactComponent as BinOutlinedIcon } from '../../../../assets/iconsNew/bin-icon-outlined.svg';
import { MAX_AIR_HEIGHT } from '../ShipmentCreationForm/shipment.utils';

const packageType = Object.values(PackageTypeEnum);

const UnitDimensionForm: FC<UnitDimensionFormProps> = ({
  field,
  isDeleteActionEnabled,
  onRemoveField,
  isFieldActionsDisabled,
}) => {
  return (
    <div key={field.key}>
      <Row gutter={[12, 12]}>
        <Col span={12} className="my-1">
          <label>Dimensions (cm) - L*W*H</label>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            name={[field.name, 'packageType']}
            rules={[
              {
                required: true,
                message: 'Please input the package type or delete this line.',
              },
            ]}
          >
            <Select
              placeholder="Choose Package Type"
              options={packageType.map((value: PackageTypeEnum) => {
                return {
                  value: value,
                  label: capitalize(value).replace('_', ' '),
                };
              })}
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            name={[field.name, 'length']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please input the length or delete this line.',
              },
              () => ({
                validator(rule, value) {
                  if (value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Length can't be equal or less than 0");
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Length (cm)"
              className="custom-input"
              min={0}
              precision={2}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            name={[field.name, 'width']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please input the total quantity or delete this line.',
              },
              () => ({
                validator(rule, value) {
                  if (value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Width can't be equal or less than 0");
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Width (cm)"
              className="custom-input"
              min={0}
              precision={2}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            name={[field.name, 'height']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please input the height or delete this line.',
              },
              () => ({
                validator(rule, value) {
                  if (value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Height can't be equal or less than 0");
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const modalityValue = getFieldValue('modality');
                  if (
                    modalityValue === ModalityEnum.AIR &&
                    value > MAX_AIR_HEIGHT
                  ) {
                    return Promise.reject(
                      `The maximum acceptable height for air cargo in passenger flights is ${MAX_AIR_HEIGHT}cm`
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
                warningOnly: true,
              }),
            ]}
          >
            <InputNumber
              placeholder="Height (cm)"
              className="custom-input"
              min={0}
              precision={2}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            label="Gross Weight / Unit"
            name={[field.name, 'weight']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please input the weight or delete this line.',
              },
              () => ({
                validator(rule, value) {
                  if (value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Weight can't be equal or less than 0");
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Enter weight"
              className="custom-input"
              min={0.1}
              precision={2}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            label="Quantity"
            name={[field.name, 'quantity']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please input the total quantity or delete this line.',
              },
              () => ({
                validator(rule, value) {
                  if (value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "Quantity can't be equal or less than 0"
                  );
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Enter Quantity"
              className="custom-input"
              min={0}
              precision={0}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} align="middle">
        <Col span={12}>
          <Form.Item
            {...formItemLayoutWithOutLabel}
            label="Total Gross Weight (Kg)"
            name={[field.name, 'totalWeight']}
            validateTrigger={['onChange', 'onBlur']}
          >
            <InputNumber
              placeholder="Total weight"
              min={0}
              precision={2}
              type="number"
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={isDeleteActionEnabled ? 10 : 12}>
          <Form.Item
            label="Measurement Unit"
            name={[field.name, 'lengthMeasurement']}
            rules={[
              {
                required: true,
                message: 'Please select the length measurement unit.',
              },
            ]}
          >
            <Select
              placeholder="Choose length measurement"
              options={Object.values(LengthMeasurementUnitEnum).map(
                (value: LengthMeasurementUnitEnum) => {
                  return {
                    value: value,
                    label: value.toUpperCase(),
                  };
                }
              )}
              disabled={isFieldActionsDisabled}
            />
          </Form.Item>
        </Col>

        {onRemoveField && isDeleteActionEnabled && (
          <Col span={2}>
            <Button
              disabled={isFieldActionsDisabled}
              icon={<BinOutlinedIcon />}
              onClick={() => onRemoveField(field.name)}
              danger
            />
          </Col>
        )}
      </Row>
      <Form.Item name={[field.name, 'id']} hidden={true}>
        <Input />
      </Form.Item>
    </div>
  );
};

export default UnitDimensionForm;
