import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetCreateShipment } from 'state/slices/create-shipment.slice';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import {
  ShipmentCreationOnboardingModalProps,
  ShipmentCreationScreenEnum,
} from 'types/feature/shipment-onboarding';

import AIFormScreen from './AIFormScreen';
import BiddingFormScreen from './BiddingFormScreen';
import InviteForwarderScreen from './ForwarderInviteScreen';
import OverviewScreen from './OverviewScreen';
import ShipmentFormScreen from './ShipmentFromScreen';
import './shipment-creation.scss';

const ShipmentCreationOnboardingModal: FC<
  ShipmentCreationOnboardingModalProps
> = ({ onClose }) => {
  const [defaultShipment, setDefaultShipment] =
    useState<IShipmentEntity | null>(null);
  const [activeScreen, setActiveScreen] = useState<ShipmentCreationScreenEnum>(
    ShipmentCreationScreenEnum.OVERVIEW_SCREEN
  );
  const dispatch = useDispatch();

  const onModalClose = () => {
    setActiveScreen(ShipmentCreationScreenEnum.OVERVIEW_SCREEN);
    dispatch(resetCreateShipment());
    onClose();
  };

  const onScreenChange = (
    newScreen: ShipmentCreationScreenEnum,
    screenData?: Record<string, any>
  ) => {
    if (
      newScreen === ShipmentCreationScreenEnum.SHIPMENT_FORM_SCREEN &&
      screenData
    ) {
      setDefaultShipment(screenData?.extractedShipment as IShipmentEntity);
    }

    setActiveScreen(newScreen);
  };

  const renderScreen = () => {
    switch (activeScreen) {
      case ShipmentCreationScreenEnum.AI_FORM_SCREEN:
        return <AIFormScreen onScreenChange={onScreenChange} />;
      case ShipmentCreationScreenEnum.SHIPMENT_FORM_SCREEN:
        return (
          <ShipmentFormScreen
            onScreenChange={onScreenChange}
            defaultShipment={defaultShipment}
          />
        );
      case ShipmentCreationScreenEnum.INVITE_FORWARDER_SCREEN: {
        return <InviteForwarderScreen onScreenChange={onScreenChange} />;
      }
      case ShipmentCreationScreenEnum.CLOSE_MODAL: {
        onModalClose();
        return;
      }
      case ShipmentCreationScreenEnum.BIDDING_FORM_SCREEN: {
        return (
          <BiddingFormScreen
            onScreenChange={onScreenChange}
            defaultShipment={defaultShipment}
          />
        );
      }
      case ShipmentCreationScreenEnum.OVERVIEW_SCREEN:
      default:
        return <OverviewScreen onScreenChange={onScreenChange} />;
    }
  };

  return <>{renderScreen()}</>;
};

export default ShipmentCreationOnboardingModal;
