import { Progress, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import { disaplayForwarders } from 'app/design-system/DisplayConnections';
import PurchaseOrderStatusSwitch from 'app/design-system/PurchaseOrderStatus';
import dayjs from 'dayjs';
import React from 'react';
import {
  IPurchaseOrderEntity,
  PurchaseOrderStatusEnum,
} from 'types/entities/purchase-order.entity';
import { ModalityEnum } from 'types/entities/shipment.entity';

import { isViewer } from 'utils/collaborators';
import { displayCountry, splitState } from 'utils/format-fields';
import { dateFormat2Const } from 'utils/shipment-type';
import { SlugPrefixEnum, convertSlug } from 'utils/slug-formattor';
import { computeFullName } from 'utils/user-utils';

const caculatePercentage = (order: IPurchaseOrderEntity) => {
  const reduced = order.purchaseOrderItems.reduce(
    (prev, curr) => {
      prev.requestedQuantity += curr.requestedQuantity ?? 0;
      prev.bookedQuantity += curr.bookedQuantity ?? 0;

      return prev;
    },
    { requestedQuantity: 0, bookedQuantity: 0 }
  );
  return Number(
    ((reduced.bookedQuantity / reduced.requestedQuantity) * 100).toFixed(2)
  );
};
export const filteredColumns = [
  'ref',
  'origin',
  'destination',
  'supplier',
  'order_date',
  'status',
];
export const columns = [
  { value: 'ref', label: 'PO #' },
  { value: 'origin', label: 'Origin' },
  { value: 'destination', label: 'Destination' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'cargo_ready_date', label: 'CRD' },
  { value: 'incoterms', label: 'Incoterms' },
  { value: 'order_date', label: 'Order Date' },
  { value: 'transportation', label: 'Transportation' },
  { value: 'collaborators', label: 'Collaborators' },
  { value: 'booked', label: 'Booked' },
  { value: 'boxes', label: 'Boxes' },
  { value: 'status', label: 'Status' },
  { value: 'owner', label: 'Assigned' },
  { value: 'createdBy', label: 'Created By' },
  { value: 'attachments', label: 'Attachments' },
];

const orderStatus = Object.values(PurchaseOrderStatusEnum);
const modesOfTransportation = Object.values(ModalityEnum);

export const POsColumnConst: ColumnsType<IPurchaseOrderEntity> = [
  {
    title: 'PO #',
    key: 'ref',
    dataIndex: 'number',
    render: (_, record: IPurchaseOrderEntity, index) => (
      <Space direction="vertical">
        <Typography.Text strong className="row-po-text">
          {record.reference}
        </Typography.Text>
        <Typography.Text type="secondary">
          {convertSlug(record.slug, SlugPrefixEnum.PO)}
        </Typography.Text>
      </Space>
    ),
    align: 'center',
  },
  {
    title: 'Origin',
    key: 'origin',
    dataIndex: 'origin',
    render: (_, record: IPurchaseOrderEntity) => (
      <Space direction="vertical">
        <Typography.Text>{displayCountry(record.origin)}</Typography.Text>
        {/* <Typography.Text type="secondary" className="row-sub-text">
          {dayjs(record.etd).format(dateFormat2Const)}
        </Typography.Text> */}
      </Space>
    ),
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',

    render: (_, record: IPurchaseOrderEntity) => (
      <Space direction="vertical">
        <Typography.Text>{displayCountry(record.destination)}</Typography.Text>
        {/* <Typography.Text type="secondary" className="row-sub-text">
          {dayjs(record.eta).format(dateFormat2Const)}
        </Typography.Text> */}
      </Space>
    ),
  },

  {
    title: 'Supplier',
    key: 'supplier',

    render: (_, record: IPurchaseOrderEntity) => (
      <Space direction="vertical">
        <Typography.Text>{record.supplier?.company.name}</Typography.Text>
        <Typography.Text type="secondary" className="row-sub-text">
          {record.supplier?.fullname}
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'CRD',
    dataIndex: 'cargoReadyDate',
    key: 'cargo_ready_date',

    render: (_, record: IPurchaseOrderEntity) =>
      record.purchaseOrderItems.length
        ? dayjs(record.purchaseOrderItems[0].cargoReadyDate).format(
            dateFormat2Const
          )
        : '--',
  },
  {
    title: 'Incoterms',
    dataIndex: 'incoterms',
    key: 'incoterms',

    render: (_, record: IPurchaseOrderEntity) => record.incoterms.toUpperCase(),
  },
  {
    title: 'Order Date',
    key: 'order_date',

    render: (_, record: IPurchaseOrderEntity) =>
      dayjs(record.orderIssueDate).format(dateFormat2Const),
  },
  {
    title: 'Booked',
    key: 'booked',
    render: (_, record: IPurchaseOrderEntity) => {
      const total = record.purchaseOrderItems.reduce(
        (prev, curr) => {
          prev.requestedQuantity += curr.requestedQuantity ?? 0;
          prev.bookedQuantity += curr.bookedQuantity ?? 0;

          return prev;
        },
        { requestedQuantity: 0, bookedQuantity: 0 }
      );
      return (
        <Space direction="vertical">
          <Progress percent={caculatePercentage(record)} steps={5} showInfo />
          <Typography.Text>
            <Typography.Text strong>{total.bookedQuantity}</Typography.Text> /{' '}
            {total.requestedQuantity}
          </Typography.Text>
        </Space>
      );
    },
  },
  {
    title: 'Boxes',
    key: 'boxes',
    render: (_, record: IPurchaseOrderEntity) => record.totalBoxes,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',

    render: (_, record: IPurchaseOrderEntity) => {
      return (
        <PurchaseOrderStatusSwitch
          disabled={isViewer(record.currentUserAccessLevel.accessLevel)}
          status={record.status}
          toUpdate={true}
          id={record.id}
        />
      );
    },
    width: '8%',
    align: 'center',
    filters: orderStatus.map((status) => ({
      value: status,
      text: splitState(status),
    })),
    filterMultiple: false,
  },
  {
    title: 'Assigned',
    dataIndex: 'owner',
    key: 'owner',
    render: (_, record) => computeFullName(record.owner),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: (_, record) => computeFullName(record.createdBy),
  },
  {
    title: 'Transportation',
    key: 'transportation',
    dataIndex: 'modality',
    render: (_, record: IPurchaseOrderEntity) => {
      return displayFreighMethod(record.modality);
    },
    align: 'center',
    filters: modesOfTransportation.map((status) => ({
      value: status,
      text: displayFreighMethod(status),
    })),
    filterMultiple: false,
  },
  {
    title: 'Collaborators',
    dataIndex: 'collaborators',
    key: 'collaborators',
    render: (_, record: IPurchaseOrderEntity) => {
      return (
        <div className="user-gravatar-wrapper">
          {disaplayForwarders({
            teamMemebers: record?.purchaseOrderCollaborators,
            maxCount: 3,
          })}
        </div>
      );
    },
  },
];
