import dayjs from 'dayjs';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import {
  ICargoDetailsForm,
  IShipmentDetailsForm,
  TCreateShipmentForm,
} from 'types/feature/create-shipment.types';

import { mapValuesToLocation } from 'utils/location';

export const mapValuesToShipment = (
  values: ICargoDetailsForm & IShipmentDetailsForm,
  defaultShipment: IShipmentEntity | null
) => {
  const {
    cargoUnits = [],
    cargoReadyDate,
    originFull,
    destinationFull,
    productId,
    productCategoryName,
    serviceMode,
    ...restFields
  } = values;

  const modes = serviceMode?.split('/');
  return {
    ...restFields,
    ...(cargoUnits &&
      cargoUnits.length > 0 && {
        cargoUnits,
      }),
    ...(cargoReadyDate && {
      cargoReadyDate: dayjs(cargoReadyDate).format('YYYY-MM-DDThh:mm:ss.00Z'),
    }),
    ...(originFull && {
      origin: mapValuesToLocation(
        defaultShipment?.origin ?? null,
        originFull,
        modes?.length ? modes[0] : undefined
      ),
    }),
    ...(destinationFull && {
      destination: mapValuesToLocation(
        defaultShipment?.destination ?? null,
        destinationFull,
        modes?.length ? modes[1] : undefined
      ),
    }),
    ...(productCategoryName
      ? {
          productCategoryName,
        }
      : { productId }),
  };
};

export const computeCreateShipmentPayload = (
  forms: TCreateShipmentForm,
  defaultShipment: IShipmentEntity | null
) => {
  const values = {
    ...forms.cargoDetailsForm,
    ...forms.shipmentDetailsForm,
    ...forms.additionalInfoForm,
  };
  const {
    cargoUnits = [],
    cargoReadyDate,
    originFull,
    destinationFull,
    productId,
    productCategoryName,
    serviceMode,
    eta,
    etd,
    ...restFields
  } = values;

  const modes = serviceMode?.split('/');
  return {
    ...restFields,
    ...(cargoUnits &&
      cargoUnits.length > 0 && {
        cargoUnits,
      }),
    ...(eta && {
      eta: dayjs(eta).format('YYYY-MM-DDThh:mm:ss.00Z').toString(),
    }),
    ...(etd && {
      etd: dayjs(etd).format('YYYY-MM-DDThh:mm:ss.00Z').toString(),
    }),
    ...(cargoReadyDate && {
      cargoReadyDate: dayjs(cargoReadyDate)
        .format('YYYY-MM-DDThh:mm:ss.00Z')
        .toString(),
    }),
    ...(originFull && {
      origin: mapValuesToLocation(
        defaultShipment?.origin ?? null,
        originFull,
        modes?.length ? modes[0] : undefined
      ),
    }),
    ...(destinationFull && {
      destination: mapValuesToLocation(
        defaultShipment?.destination ?? null,
        destinationFull,
        modes?.length ? modes[1] : undefined
      ),
    }),
    ...(productCategoryName
      ? {
          productCategoryName,
        }
      : { productId }),
    dueDate: null,
  };
};
