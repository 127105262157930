import { Drawer, notification } from 'antd';
import { useUpdatePurchaseOrdersMutation } from 'api/purchase-order';
import React, { FC, useEffect } from 'react';
import { IPurchaseOrderCreateBody } from 'types/entities/purchase-order.entity';
import { IPurchaseOrderEditProps } from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import OrderCreationForm from '../PurchaseOrderCreation/CreationForm';

const PurchaseOrderEdit: FC<IPurchaseOrderEditProps> = ({
  order,
  isOpen,
  onCloseEditModal,
}) => {
  const [updatePurchaseOrder, { error, isError, isLoading, isSuccess }] =
    useUpdatePurchaseOrdersMutation();

  const onFinish = async (purchaseOrder: IPurchaseOrderCreateBody) => {
    try {
      if (order)
        await updatePurchaseOrder({ id: order.id, body: purchaseOrder });
    } catch (error) {
      notification.warning({
        message: 'Unknown error while updating purchase order !',
      });
    }
  };

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Purchase order update Error' });
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Purchase order updated !' });
      onCloseEditModal();
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Drawer
        open={isOpen}
        width={'80vw'}
        onClose={onCloseEditModal}
        destroyOnClose={true}
        title="Edit Order"
        footer={null}
      >
        <OrderCreationForm
          defaultPurchaseOrder={order}
          isEditView={true}
          onFinish={onFinish}
          isProcessing={isLoading}
          onCancel={onCloseEditModal}
          file={null}
          createBookingFlow={false}
        />
      </Drawer>
    </>
  );
};

export default PurchaseOrderEdit;
