import dayjs from 'dayjs';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IncotermsEnum } from 'types/entities/product.entity';
import {
  IPOParsedDocumentEntity,
  IPOParsedDocumentLineItemEntity,
  IPurchaseOrderEntity,
  IPurchaseOrderItemEntity,
  MeasurementUnitEnum,
  POFileType,
  PurchaseOrderStatusEnum,
} from 'types/entities/purchase-order.entity';
import { ModalityEnum } from 'types/entities/shipment.entity';

const getIncoterm = (items: IPOParsedDocumentLineItemEntity[]) => {
  return items?.length
    ? Object.values(IncotermsEnum).find(
        (item) =>
          (items[0]['line_item/incoterm'] as string)?.toLowerCase() === item
      )
    : undefined;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const mapOrderItems = (
  jsonItem: IPOParsedDocumentLineItemEntity,
  currency: CurrencyEnum,
  defaultCargoReadyDate: string | undefined
) => {
  const item: IPurchaseOrderItemEntity = {
    description:
      jsonItem['line_item/description'] ?? jsonItem['line_item/product_code'],
    reference: jsonItem['line_item/product_code'],
    requestedQuantity: jsonItem['line_item/quantity'],
    unitPrice: jsonItem['line_item/unit_price'],
    totalWeight: jsonItem['line_items/weight'],
    totalVolume: jsonItem['line_items/volume'],
    boxes: jsonItem['line_items/boxes'],
    currencyCode: currency,
    cargoReadyDate:
      defaultCargoReadyDate ??
      dayjs(jsonItem['line_item/item_delivery_date'] ?? dayjs()).format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
    totalAmount:
      jsonItem['line_item/unit_price'] * jsonItem['line_item/quantity'],
    taxRate: 0,
    shipments: [],
    bookedQuantity: null,
    unitOfMeasurement: MeasurementUnitEnum.QUANTITY,
    volumePerUnit: null,
    weightPerUnit: null,
  };

  return item;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const mapJSONToPurchaseOrder = (response: IPOParsedDocumentEntity) => {
  const currency = CurrencyEnum[response.currency] ?? CurrencyEnum.USD;
  const order: Partial<IPurchaseOrderEntity> = {
    status: response.status ?? PurchaseOrderStatusEnum.IN_PRODUCTION,
    orderIssueDate: dayjs(response.purchase_order_date ?? dayjs()).format(
      'YYYY-MM-DDTHH:mm:ss'
    ),

    eta: '',
    etd: '',
    destination: {
      country: response?.destination,
      code: '',
      city: response?.destination,
      latitude: 0,
      longitude: 0,
      serviceMode: null,
    },
    origin: {
      country: response?.origin,
      code: '',
      city: response?.origin,
      latitude: 0,
      longitude: 0,
      serviceMode: null,
    },
    reference: response.purchase_order_id,
    attachments: [],
    supplierId: '',
    supplier: undefined,
    requested: 0,
    booked: 0,
    modality: response.modality ?? ModalityEnum.SEA,
    incoterms: getIncoterm(response.line_items),
    purchaseOrderItems: response.line_items?.map((item) =>
      mapOrderItems(
        item,
        currency,
        response.cargo_ready_date
          ? dayjs(response.cargo_ready_date).format('YYYY-MM-DDTHH:mm:ss')
          : undefined
      )
    ),
  };

  return order;
};

export const isExcel = (fileType: POFileType) => fileType === POFileType.XLSX;
