import { Row, Col, Form, Select, InputNumber, Input, Button } from 'antd';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import React, { FC } from 'react';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import { IContainerFormProps } from 'types/feature/create-shipment.types';

import { formatContainerName } from 'utils/format-fields';

import { ReactComponent as BinOutlinedIcon } from '../../../../assets/iconsNew/bin-icon-outlined.svg';

const ContainerForm: FC<IContainerFormProps> = ({
  field,
  isDeleteActionEnabled,
  onRemoveField,
  isFieldActionsDisabled,
}) => {
  return (
    <Row key={field.key} gutter={[12, 12]} align="middle">
      <Col span={12}>
        <Form.Item
          name={[field.name, 'containerType']}
          validateTrigger={['onChange', 'onBlur']}
          label="Container Type"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please select container type or delete this line.',
            },
          ]}
        >
          <Select
            placeholder="Container Type"
            options={Object.values(ContainerTypeEnum).map((value) => {
              return {
                value: value,
                label: formatContainerName(value),
              };
            })}
            disabled={isFieldActionsDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={isDeleteActionEnabled ? 10 : 12}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'quantity']}
          label="Container Quantity"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'Please input the total quantity or delete this line.',
            },
            () => ({
              validator(rule, value) {
                if (value > 0 && value < 100001) {
                  return Promise.resolve();
                }

                return Promise.reject('Allowed quantity range is 1 - 100,000');
              },
            }),
          ]}
        >
          <InputNumber
            placeholder="Enter Quantity"
            className="custom-input"
            min={0}
            max={100000}
            precision={0}
            type="integer"
            disabled={isFieldActionsDisabled}
          />
        </Form.Item>
      </Col>
      {onRemoveField && isDeleteActionEnabled && (
        <Col span={2}>
          <Button
            icon={<BinOutlinedIcon />}
            onClick={() => onRemoveField(field.name)}
            type="default"
            danger
          />
        </Col>
      )}
      <Form.Item name={[field.name, 'id']} hidden={true}>
        <Input disabled={isFieldActionsDisabled} />
      </Form.Item>
    </Row>
  );
};

export default ContainerForm;
