/* eslint-disable */
import Icon from '@ant-design/icons';
import { Button, Modal, Typography, Upload, UploadProps, message } from 'antd';
import { useCreateInvoiceByShipmentIdMutation } from 'api/invoice';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IUploadInvoiceModalProps } from 'types/feature/invoice.types';

import {
  invoiceAuditStartEvent,
  invoiceSubmitEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera-icon-filled.svg';

const { Text } = Typography;
const { Dragger } = Upload;

const UploadInvoiceModal: FC<IUploadInvoiceModalProps> = ({
  shipmentId,
  shipmentSlug,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);

  const [
    createInvoiceByShipmentId,
    { data: responseData, isLoading: isExtracting, isError, error },
  ] = useCreateInvoiceByShipmentIdMutation();

  useEffect(() => {
    if (isError) {
      displayErrors(error, {
        title: 'Error while extracting data!',
      });
    }
  }, [isError, error]);

  const showModal = () => {
    invoiceSubmitEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
      shipment_id: shipmentId,
    });

    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFileUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('file', file);

    createInvoiceByShipmentId({ shipmentId, file: formdata })
      .unwrap()
      .then((payload) => onSuccess())
      .catch((error) => onError());
  };

  const onConfirm = async () => {
    if (responseData) {
      navigate(`/shipments/${shipmentSlug}/invoice/${responseData.data.id}`);
    }

    invoiceAuditStartEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
      shipment_id: shipmentId,
    });
  };

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    customRequest: onFileUpload,
    onChange(info: any) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Button onClick={showModal} type="primary">
        Submit Invoice
      </Button>
      <Modal
        title="Upload Document"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            onClick={onConfirm}
            disabled={isExtracting || !responseData}
            loading={isExtracting}
          >
            Start Audit Process
          </Button>,
        ]}
        width={450}
        destroyOnClose
      >
        <div className="upload-document-wrapper">
          <Dragger {...props}>
            <div className="upload-file-wrapper">
              <Icon className="camera-icon" component={CameraIcon} />
              <Text className="upload-lebel">Attach Invoice</Text>
            </div>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default UploadInvoiceModal;
