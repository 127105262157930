import { Modal } from 'antd';
import { useBookingPartyState } from 'providers/booking-parties-provider';
import React, { useEffect, useState } from 'react';
import {
  AcceptedProposalStoryModalProps,
  AcceptedProposalStoryScreenEnum,
} from 'types/feature/user-onboarding';

import AddForwarderColleageOnboardingScreen from '../AddForwarderColleageOnboardingScreen';
import ShareBookingPartyOnboardingScreen from '../ShareBookingPartyOnboardingScreen';

const AcceptedProposalStoryModal: React.FC<AcceptedProposalStoryModalProps> = ({
  shipmentId,
  company,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { shareBookingPartyModal, switchTabOnShare } = useBookingPartyState();
  const [currentScreen, setCurrentScreen] =
    useState<AcceptedProposalStoryScreenEnum>(
      AcceptedProposalStoryScreenEnum.FORWARDER_SCREEN
    );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (shareBookingPartyModal) {
      showModal();
    }
  }, [shareBookingPartyModal]);

  const onShareBookingPartyClick = () => {
    if (switchTabOnShare) {
      switchTabOnShare('overview');
      hideModal();
    }
  };

  const onScreenChange = (screen: AcceptedProposalStoryScreenEnum) => {
    setCurrentScreen(screen);
  };

  const onScreenReset = () => {
    hideModal();
    setCurrentScreen(AcceptedProposalStoryScreenEnum.FORWARDER_SCREEN);
  };

  const renderScreens = () => {
    switch (currentScreen) {
      case AcceptedProposalStoryScreenEnum.BOOKING_PARTY_SCREEN:
        return (
          <ShareBookingPartyOnboardingScreen
            onScreenChange={onScreenChange}
            onScreenReset={onScreenReset}
            onShareBookingPartyClick={onShareBookingPartyClick}
          />
        );

      case AcceptedProposalStoryScreenEnum.FORWARDER_SCREEN:
      default:
        return (
          <AddForwarderColleageOnboardingScreen
            shipmentId={shipmentId}
            company={company}
            onScreenChange={onScreenChange}
            onScreenReset={onScreenReset}
          />
        );
    }
  };

  return (
    <Modal open={isModalOpen} onCancel={hideModal} footer={null}>
      {renderScreens()}
    </Modal>
  );
};

export default AcceptedProposalStoryModal;
