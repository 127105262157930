import { Card, Modal, notification } from 'antd';
import { useSubmitProposalMutation } from 'api/proposal';
import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { IShipmentQuotesProps } from 'types/feature/proposal.types';

import { displayErrors } from 'utils/error-notification';
import { isForwarder } from 'utils/shipment-helper';

import BiddingCounter from '../Bidding/BiddingCounter';
import Proposals from './Proposals';
import './shipment-quotes.scss';

const { confirm } = Modal;

const ShipmentQuotes: FC<IShipmentQuotesProps> = ({ shipment }) => {
  const [submitProposal] = useSubmitProposalMutation();

  const onSubmitProposal = async () => {
    confirm({
      title: 'Confirmation',
      okText: 'Submit',
      okType: 'primary',

      icon: null,
      content: 'Do you really want to submit a quote for this shipment ?',
      onOk: async () => {
        await submitProposal({
          shipmentId: shipment.id,
          shipmentTypeId: shipment.shipmentTypeId,
        })
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Proposal Created !',
              description: 'Please edit the quote then submit it ! ',
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Eroor while submitting quote !' });
          });
      },
    });
  };

  return (
    <div className="shipment-document-wrapper">
      <Card>
        {shipment.dueDate && !isForwarder(shipment.currentUserRole) && (
          <div className="shipment-bidding-counter-wrapper">
            <BiddingCounter
              deadline={shipment.dueDate}
              showDropDownActions={true}
              shipmentParams={{
                id: shipment.id,
                slug: shipment.slug,
              }}
            />
          </div>
        )}

        <Proposals shipment={shipment} onSubmitProposal={onSubmitProposal} />
      </Card>
    </div>
  );
};

export const ShipmentQuotesRouteWrapper: FC = () => {
  const props = useOutletContext<IShipmentQuotesProps>();
  return <ShipmentQuotes {...props} />;
};

export default ShipmentQuotes;
