import { IIndicatorEntity } from 'types/entities/indicator.entity';
import { IInvoiceForReviewEntity } from 'types/entities/invoice.entity';
import { IPurchaseOrderEntity } from 'types/entities/purchase-order.entity';
import {
  IShipmentEntity,
  IBookmarkedShipment,
} from 'types/entities/shipment.entity';
import { ITaskEntity } from 'types/entities/task.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookmarkedShipments: build.query<IBookmarkedShipment[], void>({
      query: () => '/dashboard/bookmarked-shipments',
    }),
    getUpcomingTasks: build.query<ITaskEntity[], void>({
      query: () => '/dashboard/upcoming-tasks',
    }),
    getResumeShipments: build.query<IIndicatorEntity[], void>({
      query: () => '/dashboard/shipment-counts',
    }),
    getQuotesForReview: build.query<IShipmentEntity[], void>({
      query: () => '/dashboard/quotes-for-review',
    }),
    getActiveShipments: build.query<IShipmentEntity[], void>({
      query: () => '/dashboard/active-shipments',
    }),
    getDashboardPurchaseOrders: build.query<
      ApiResponse<IPurchaseOrderEntity[]>,
      void
    >({
      query: () => '/dashboard/purchase-order',
    }),
    getInvoicesForReview: build.query<
      ApiResponse<IInvoiceForReviewEntity[]>,
      void
    >({
      query: () => '/dashboard/invoices',
    }),
  }),
});

export const {
  useGetBookmarkedShipmentsQuery,
  useGetQuotesForReviewQuery,
  useGetResumeShipmentsQuery,
  useGetUpcomingTasksQuery,
  useGetActiveShipmentsQuery,
  useGetDashboardPurchaseOrdersQuery,
  useGetInvoicesForReviewQuery,
} = dashboardApi;
