import { CopyFilled } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Tooltip, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import {
  useDuplicateShipmentByIdMutation,
  useGetShipmentsBySlugQuery,
} from 'api/shipment';
import ShipmentDetailsForm from 'app/components/ShipmentCreation/ShipmentCreationForm/ShipmentDetailsForm';
import { computeDefaultShipmentDetails } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { IShipmentDetailsForm } from 'types/feature/create-shipment.types';
import { IDuplicatekButtonProps } from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';
import { convertSlugToShipmentId } from 'utils/slug-formattor';

import './duplicate-button.scss';

const DuplicateButton: FC<IDuplicatekButtonProps> = ({ shipmentId }) => {
  const navigate = useNavigate();

  const [duplicateShipment, { isLoading, isSuccess }] =
    useDuplicateShipmentByIdMutation();

  const [shipmentDetailsForm] = Form.useForm<IShipmentDetailsForm>();

  const { slug = '' } = useParams();
  const { data: shipment } = useGetShipmentsBySlugQuery(slug);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: Partial<IShipmentEntity>) => {
    await shipmentDetailsForm.validateFields();
    const { cargoUnits } = shipmentDetailsForm.getFieldsValue();

    try {
      const resp = await duplicateShipment({
        id: shipmentId,
        shipment: {
          cargoReadyDate: values.cargoReadyDate,
          additionalInfo: values.additionalInfo,
          shipmentReference: values.shipmentReference,
          cargoUnits,
        },
      }).unwrap();

      handleCancel();
      navigate(`/shipments/${convertSlugToShipmentId(resp.slug)}`, {
        state: { shipmentId: resp.id },
      });

      // eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Error Duplicating Shipment.' });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Shipment duplicated successfully !',
        description: 'You can check the newely created shipment on main list !',
      });
    }
  }, [isSuccess]);

  return (
    <Tooltip placement="top" title="Duplicate Shipment">
      <Button
        type="link"
        data-testid="shipment-duplicate-btn"
        className="duplicate-btn"
        loading={isLoading}
        icon={<CopyFilled />}
        onClick={showModal}
      />

      <Modal
        title="Duplicate Shipment"
        open={isModalOpen}
        onCancel={handleCancel}
        width={1600}
        footer={[
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="duplicate-form"
            loading={isLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          name="duplicate-form"
          layout="vertical"
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className="duplicate-form-wrapper"
        >
          <Form.Item
            label="Cargo Ready Date"
            name="cargoReadyDate"
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            required={false}
            label="Shipment Reference"
            name="shipmentReference"
          >
            <TextArea placeholder="Enter Shipment Reference ..." />
          </Form.Item>
          <Form.Item label="Additional Information" name="additionalInfo">
            <TextArea placeholder="Enter any additional informations ..." />
          </Form.Item>
        </Form>
        <ShipmentDetailsForm
          form={shipmentDetailsForm}
          defaultValues={computeDefaultShipmentDetails(
            shipment as IShipmentEntity
          )}
          defaultShipmentType={shipment?.shipmentType}
          isFieldEditDisabled={false}
          isEditView={true}
          proposalParams={{ proposalId: '', email: '' }}
          deleteCargoUnitMutation={null}
          viewShipmentTypeAndCargoDetails={false}
        />
      </Modal>
    </Tooltip>
  );
};

export default DuplicateButton;
