import Icon from '@ant-design/icons';
import { Button, Col, Form, Row, notification } from 'antd';
import { useDeleteShipmentItemByIdMutation } from 'api/shipment';
import { useGetExternalUserConnectionsQuery } from 'api/user';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ShipmentTypeEnum } from 'types/entities/shipment.entity';
import {
  IAdditionalInfoForm,
  IBiddingDetailsForm,
  ICargoDetailsForm,
  IContactDetailsForm,
  IShipmentDetailsForm,
  IShipmentStatusForm,
  ShipmentCreationFormProps,
  ShipmentInviteTypeEnum,
} from 'types/feature/create-shipment.types';

import {
  createShipmentSubmitClickEvent,
  editShipmentSubmitEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { hasShipmentStarted } from 'utils/shipment-helper';
import { isAirTransportation } from 'utils/shipment-type';

import { ReactComponent as Stars } from '../../../../assets/stars.svg';
import ContactDetailsForm from '../../../design-system/CommonForms/ContactDetailsForm';
import CargoDetailsForm from '../CargoDetailsForm';
import {
  computeBiddingFormDate,
  computeDefaultBiddingDate,
  computeDefaultCargoDetails,
  computeDefaultShipmentDetails,
} from '../ShipmentCreationForm/shipment.utils';
import ShipmentDetailsForm from '../ShipmentDetailsForm';
import { mapValuesToShipment } from '../shipment-creation';
import AdditionalInfoForm, {
  AdditionalInfoFormRef,
} from './AdditionalInfoForm';
import BiddingDetailsForm from './BiddingDetailsForm';
import ShipmentStatusForm from './ShipmentStatusForm';

const ShipmentCreationForm: FC<ShipmentCreationFormProps> = ({
  defaultShipment,
  isLoading,
  isEditView = false,
  openPreviewTab,
  onCreateShipment,
}) => {
  const [shipmentStatusForm] = Form.useForm<IShipmentStatusForm>();
  const [cargoDetailsForm] = Form.useForm<ICargoDetailsForm>();
  const [shipmentDetailsForm] = Form.useForm<IShipmentDetailsForm>();
  const [additionalInfoForm] = Form.useForm<IAdditionalInfoForm>();
  const [biddingDetailsForm] = Form.useForm<IBiddingDetailsForm>();
  const [contactPersonForm] = Form.useForm<IContactDetailsForm>();

  const isExternalContactSelected = !!Form.useWatch(
    'createdBy',
    contactPersonForm
  );

  const [deleteCargoUnit] = useDeleteShipmentItemByIdMutation();

  const {
    data: externalUserList,
    isError: isExternalUserListError,
    error: externalUserListError,
  } = useGetExternalUserConnectionsQuery();
  const hasExternalConnections = !!(
    externalUserList && externalUserList.length > 0
  );

  const user = useSelector((state: RootState) => state.user.user);
  const additionalInfosRef = useRef<AdditionalInfoFormRef>(null); // create the ref
  const onSubmitForm = async () => {
    try {
      // cargo details infos
      let cargoDetailsFormValues = await cargoDetailsForm.validateFields();
      if (isEditView) {
        cargoDetailsFormValues = await cargoDetailsForm.getFieldsValue(
          true,
          ({ touched }) => touched
        );
      }

      if (cargoDetailsFormValues.origin || cargoDetailsFormValues.serviceMode) {
        cargoDetailsFormValues = {
          ...cargoDetailsFormValues,
          originFull: {
            ...(isEditView && { id: defaultShipment?.origin.id }),
            ...(await cargoDetailsForm.getFieldValue('originFull')),
          },
        };
      }

      if (
        cargoDetailsFormValues.destination ||
        cargoDetailsFormValues.serviceMode
      ) {
        cargoDetailsFormValues = {
          ...cargoDetailsFormValues,
          destinationFull: {
            ...(isEditView && { id: defaultShipment?.destination.id }),
            ...(await cargoDetailsForm.getFieldValue('destinationFull')),
          },
        };
      }

      // shipment details form

      await shipmentDetailsForm.validateFields();
      let shipmentDetailsFormValues: IShipmentDetailsForm;
      if (isEditView) {
        shipmentDetailsFormValues = await shipmentDetailsForm.getFieldsValue(
          true,
          ({ touched }) => touched
        );
      } else {
        shipmentDetailsFormValues = await shipmentDetailsForm.getFieldsValue();
      }

      // we need to get all cargoUnits list in two cases :
      // either it is edit mode and some values of cargoUnits have been edited
      // or it is a shipment created from text
      if (shipmentDetailsFormValues.cargoUnits?.length) {
        shipmentDetailsFormValues = {
          ...shipmentDetailsFormValues,
          cargoUnits: await shipmentDetailsForm.getFieldValue('cargoUnits'),
        };
      }

      // additional infos
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const additionalInfoFormValues =
        await additionalInfoForm.validateFields();

      // shipment status
      await shipmentStatusForm.validateFields();
      const shipmentStatusFormValues = await shipmentStatusForm.getFieldsValue(
        true,
        ({ touched }) => touched
      );

      await biddingDetailsForm.validateFields();
      const biddingDetailsFormValue = await biddingDetailsForm.getFieldsValue();

      const mappedValues = mapValuesToShipment(
        {
          ...cargoDetailsFormValues,
          ...shipmentDetailsFormValues,
          ...additionalInfoFormValues,
        },
        defaultShipment
      );

      await onCreateShipment(
        {
          ...mappedValues,
          attachments: additionalInfosRef?.current?.getAttahments() ?? [],
          dueDate: biddingDetailsFormValue.dueDate
            ? computeBiddingFormDate(biddingDetailsFormValue)
            : null,
          ...(isEditView && {
            ...shipmentStatusFormValues,
          }),
          ...(isExternalContactSelected && {
            createdBy: contactPersonForm.getFieldValue('createdBy'),
          }),
        },
        additionalInfoFormValues.inviteType,
        !!isExternalContactSelected
      );
      if (isEditView) {
        editShipmentSubmitEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          shipment_id: defaultShipment?.slug,
          previous_status: defaultShipment?.status,
          new_status: shipmentStatusFormValues.status,
          company_id: user?.companyId,
        });
      } else {
        createShipmentSubmitClickEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        });
      }
    } catch (error: unknown) {
      notification.warning({
        message: 'Wrong inputs',
        description: 'Make sure to fill all mandotary fields correctly!',
      });
    }
  };

  useEffect(() => {
    if (defaultShipment) {
      cargoDetailsForm.resetFields();
      shipmentDetailsForm.resetFields();
      shipmentStatusForm.resetFields();
      additionalInfoForm.resetFields();
      biddingDetailsForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShipment]);

  useEffect(() => {
    if (isExternalUserListError) {
      displayErrors(externalUserListError, {
        title: 'Contact Person Error',
      });
    }
  }, [isExternalUserListError, externalUserListError]);

  const renderPreviewTabCta =
    !isEditView && openPreviewTab != null ? (
      <Button
        type="primary"
        onClick={openPreviewTab}
        icon={<Icon component={Stars} />}
      >
        Create Shipment by AI
      </Button>
    ) : (
      <></>
    );

  return (
    <div data-testid="shipment-form">
      {!isEditView && hasExternalConnections && (
        <DashboardCard
          large
          headerTitle="Contact Details"
          subTitle="Select the contact person to be notified about this shipment."
          rightAccessory={renderPreviewTabCta}
        >
          <ContactDetailsForm
            form={contactPersonForm}
            externalUserList={externalUserList}
          />
        </DashboardCard>
      )}

      {isEditView && defaultShipment && (
        <ShipmentStatusForm
          defaultValues={{
            status: defaultShipment.status,
            autoUpdateStatus: defaultShipment.autoUpdateStatus,
          }}
          form={shipmentStatusForm}
        />
      )}
      <CargoDetailsForm
        defaultValues={computeDefaultCargoDetails(defaultShipment, isEditView)}
        form={cargoDetailsForm}
        isAirShipment={isAirTransportation(
          defaultShipment?.shipmentType?.title as ShipmentTypeEnum
        )}
        isEditView={isEditView}
        {...(!hasExternalConnections && {
          previewTabCta: renderPreviewTabCta,
        })}
      />

      <ShipmentDetailsForm
        form={shipmentDetailsForm}
        defaultValues={computeDefaultShipmentDetails(defaultShipment)}
        isFieldEditDisabled={false}
        isEditView={isEditView}
        {...(defaultShipment && {
          defaultShipmentType:
            defaultShipment?.acceptedShipmentType ??
            defaultShipment?.shipmentType,
        })}
        proposalParams={{ proposalId: '', email: '' }}
        deleteCargoUnitMutation={deleteCargoUnit}
        viewShipmentTypeAndCargoDetails={true}
      />
      <DashboardCard
        large
        fitContent={true}
        headerTitle="Additional Information"
      >
        <AdditionalInfoForm
          ref={additionalInfosRef}
          form={additionalInfoForm}
          defaultValues={{
            additionalInfo: defaultShipment?.additionalInfo ?? '',
            inviteType: ShipmentInviteTypeEnum.FORWARDER,
            shipmentReference: defaultShipment?.shipmentReference ?? '',
          }}
          isEditView={isEditView}
          isInviteTypeDisabled={isExternalContactSelected}
        />
      </DashboardCard>

      {!isExternalContactSelected && (
        <DashboardCard large fitContent={true} headerTitle="Bidding Details">
          <BiddingDetailsForm
            defaultValues={computeDefaultBiddingDate(
              defaultShipment?.dueDate,
              isEditView
            )}
            form={biddingDetailsForm}
            {...(defaultShipment && {
              isDisabled: hasShipmentStarted(defaultShipment?.status),
            })}
          />
        </DashboardCard>
      )}

      <Row justify="end" gutter={[20, 20]}>
        <Col>
          <Button
            type="primary"
            onClick={onSubmitForm}
            disabled={isLoading}
            data-chameleon="submit-shipment-form"
          >
            {isEditView ? 'Update Shipment' : 'Create Shipment'}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ShipmentCreationForm;
