import { Layout } from 'antd';
import ChatAgent from 'app/components/ChatAgent';
import DocumentExtractMinimize from 'app/components/DocumentExtractor/DocumentExtractMinimize';
import { ExtractDocsContextProvider } from 'providers/extract-docs-provider';
import React from 'react';
import { Outlet } from 'react-router-dom';

import './content.style.scss';

const Content: React.FC = () => {
  return (
    <ExtractDocsContextProvider>
      <Layout.Content className="content-wrapper">
        <Outlet />
        <ChatAgent />
        <DocumentExtractMinimize />
      </Layout.Content>
    </ExtractDocsContextProvider>
  );
};

export default Content;
