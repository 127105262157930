import { Drawer, Radio, RadioChangeEvent, Row } from 'antd';
import { useGetExternalShipmentsTypesQuery } from 'api/proposal-external.api';
import AssignBookingsShipment from 'app/components/PurchaseOrderBooking/AssignBookingsShipment';
import React, { FC, useEffect, useState } from 'react';

import { displayErrors } from 'utils/error-notification';

const BookingAssignDrawer: FC<{
  bookingId: string | null;
  isOpen: boolean;
  onCloseEditModal: () => void;
}> = ({ bookingId, isOpen, onCloseEditModal }) => {
  const [isNewShipment, setNewShipment] = useState<boolean>(true);
  const {
    data: shipmentTypes,
    isError,
    error,
  } = useGetExternalShipmentsTypesQuery();

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Shipment Types Error' });
    }
  }, [isError, error]);
  return (
    <>
      <Drawer
        open={isOpen}
        width={'50vw'}
        onClose={onCloseEditModal}
        title="Assign booking to shipment"
      >
        <Row justify={'center'} className="action-section">
          <Radio.Group
            options={[
              { label: 'Create new shipment', value: true },
              { label: 'Select an existing one', value: false },
            ]}
            value={isNewShipment}
            onChange={(e: RadioChangeEvent) => setNewShipment(e.target.value)}
          />
        </Row>
        <AssignBookingsShipment
          existingShipment={!isNewShipment}
          ids={bookingId ? [bookingId] : []}
          shipmentTypes={shipmentTypes}
        />
      </Drawer>
    </>
  );
};

export default BookingAssignDrawer;
