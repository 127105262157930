import * as XLSX from 'xlsx';

export const downloadExcelFileFromBuffer = (
  bufferData: Uint8Array,
  fileName: string
) => {
  // Read the buffer using XLSX.read
  const workbook = XLSX.read(bufferData, { type: 'buffer' });
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  // The response data is the Excel file as a Blob
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a link element and trigger a download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
