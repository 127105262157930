import { notification } from 'antd';
import { useCreateShipmentFromTextMutation } from 'api/freight-ai';
import { useGetProductsQuery } from 'api/product';
import { useGetShipmentsTypesQuery } from 'api/shipment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IShipmentEntity } from 'types/entities/shipment.entity';

import { createShipmentFromTextClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import { formatShipmentInfos } from '../ShipmentTextPreview/helper';

const useShipmentCreation = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [defaultShipment, setDefaultShipment] =
    useState<IShipmentEntity | null>(null);

  const { data: products } = useGetProductsQuery();
  const { data: modalities } = useGetShipmentsTypesQuery();

  const [
    createShipmentFromText,
    {
      isLoading: isExtracting,
      isSuccess: isExtracted,
      data: extractedShipment,
    },
  ] = useCreateShipmentFromTextMutation({});

  const onExtractShipmentFromText = async (text: string) => {
    if (user) {
      createShipmentFromTextClickEvent({
        user_id: user.id,
        email: user.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }

    await createShipmentFromText({ text, companyName: user?.company?.name })
      .unwrap()
      .then((payload) => {
        if (payload) {
          const shipment = formatShipmentInfos(payload, products, modalities);
          if (shipment) setDefaultShipment(shipment as IShipmentEntity);
        } else {
          notification.info({
            message: 'Extract Shipment From Text',
            description:
              'Not able to extract shipments information from the given text, please edit it and try again! ',
          });
        }
      })
      .catch((error) => {
        displayErrors(error);
      });
  };

  return {
    defaultShipment,
    onExtractShipmentFromText,
    isExtracting,
    isExtracted,
    extractedShipment,
  };
};

export default useShipmentCreation;
