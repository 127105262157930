import { detailedDiff } from 'deep-object-diff';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { ProposalDiffTypeEnum } from 'types/feature/proposal.types';

export const getDiffType = (
  index: number,
  addedDiffIndex?: number[] | null,
  updatedDiffIndex?: number[] | null,
  deletedDiffIndex?: number[] | null
): ProposalDiffTypeEnum | undefined => {
  if (addedDiffIndex?.includes(index)) {
    return ProposalDiffTypeEnum.ADDED;
  }

  if (updatedDiffIndex?.includes(index)) {
    return ProposalDiffTypeEnum.UPDATED;
  }

  if (deletedDiffIndex?.includes(index)) {
    return ProposalDiffTypeEnum.DELETED;
  }

  return ProposalDiffTypeEnum.UNTOUCHED;
};

/**
 * Computes the difference keys between two instances of the IProposalEntity interface.
 * The function uses the detailedDiff utility to identify added, updated, and deleted
 * properties and organizes them into a structured object with a _diffType property
 * indicating the type of difference.
 *
 * @param oldValue - The original IProposalEntity instance.
 * @param newValue - The updated IProposalEntity instance.
 * @returns A structured object representing the differences between oldValue and newValue.
 *          Keys from the new value are marked as ProposalDiffTypeEnum.UNTOUCHED, added
 *          keys are marked as ProposalDiffTypeEnum.ADDED, updated keys as
 *          ProposalDiffTypeEnum.UPDATED, and deleted keys as ProposalDiffTypeEnum.DELETED.
 *          The proposalItems property is recursively mapped for nested differences.
 * */

export const computeDiffKeys = (
  oldValue: IProposalEntity,
  newValue: IProposalEntity
): Record<keyof IProposalEntity, any> => {
  const differences = detailedDiff(oldValue, newValue);
  const addedDiff = differences.added as Record<keyof IProposalEntity, any>;
  const updatedDiff = differences.updated as Record<keyof IProposalEntity, any>;
  const deletedDiff = differences.deleted as Record<keyof IProposalEntity, any>;

  // Removes uneccessary properties from updated diff object
  if (updatedDiff && updatedDiff.proposalItems) {
    updatedDiff.proposalItems = Object.fromEntries(
      Object.entries(updatedDiff.proposalItems)
        .map((item: any) => {
          const [key, value] = item;

          const {
            id,
            createdAt,
            updatedAt,
            proposalId,
            suggestedProposalId,
            ...rest
          } = value;
          return [key, Object.keys(rest).length > 0 ? rest : undefined];
        })
        .filter(([_, value]) => value !== undefined)
    );
  }

  const mapDiffKeys = (
    diff: Record<string, any>,
    status: ProposalDiffTypeEnum
  ): any => {
    return Object.keys(diff).reduce((acc: any, key: string) => {
      const value = diff[key];

      if (typeof value === 'object' && value !== null) {
        acc[key] = {
          ...mapDiffKeys(value, status),
          _diffType: status,
        };
      } else {
        acc[key] = status;
      }

      return acc;
    }, {});
  };

  const addedDiffKeys = mapDiffKeys(addedDiff, ProposalDiffTypeEnum.ADDED);
  const updatedDiffKeys = mapDiffKeys(
    updatedDiff,
    ProposalDiffTypeEnum.UPDATED
  );
  const deletedDiffKeys = mapDiffKeys(
    deletedDiff,
    ProposalDiffTypeEnum.DELETED
  );
  const newValueKeys = mapDiffKeys(newValue, ProposalDiffTypeEnum.UNTOUCHED);

  return {
    ...newValueKeys,
    ...addedDiffKeys,
    ...updatedDiffKeys,
    ...deletedDiffKeys,
    proposalItems: {
      ...newValueKeys.proposalItems,
      ...addedDiffKeys.proposalItems,
      ...updatedDiffKeys.proposalItems,
      ...deletedDiffKeys.proposalItems,
    },
  };
};

// const mapArrayWithDifferences: MapArrayWithDifferences = (
//     items,
//     addedDiff,
//     updatedDiff,
//     deletedDiff
//   ) => {
//     return items.map((item, index) => {
//       const addedDiffIndex = addedDiff
//         ? Object.keys(addedDiff).map((str) => +str)
//         : null;
//       const updatedDiffIndex = updatedDiff
//         ? Object.keys(updatedDiff).map((str) => +str)
//         : null;
//       const deletedDiffIndex = deletedDiff
//         ? Object.keys(deletedDiff).map((str) => +str)
//         : null;

//       const diffType = getDiffType(
//         index,
//         addedDiffIndex,
//         updatedDiffIndex,
//         deletedDiffIndex
//       );

//       return {
//         ...item,
//         diffType,
//       };
//     });
//   };

//   export const computedDifference = (
//     oldValue: IProposalEntity,
//     newValue: IProposalEntity
//   ) => {
//     const differences = detailedDiff(oldValue, newValue);
//     const addedDiff = differences.added as Record<string, any>;
//     const updatedDiff = differences.updated as Record<string, any>;
//     const deletedDiff = differences.deleted as Record<string, any>;

//     return {
//       ...newValue,
//       proposalShipmentItems: mapArrayWithDifferences<ICargoUnit>(
//         newValue.proposalShipmentItems,
//         addedDiff?.proposalShipmentItems,
//         updatedDiff?.proposalShipmentItems,
//         deletedDiff?.proposalShipmentItems
//       ),
//       proposalItems: mapArrayWithDifferences<IFeeEntity>(
//         newValue.proposalItems,
//         addedDiff?.proposalItems,
//         updatedDiff?.proposalItems,
//         deletedDiff?.proposalItems
//       ),
//     };
//   };
