import { Spin } from 'antd';
import { useGetDashboardTokenQuery } from 'api/metabase-url';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';

import './analytics.scss';

const Analytics: FC = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const { data, isLoading } = useGetDashboardTokenQuery(
    {
      company_id: user?.companyId ?? '',
    },
    { skip: !user?.companyId }
  );
  const iframeUrl = data?.iframeUrl;

  return (
    <div className="container">
      {isLoading ? (
        <Spin />
      ) : (
        <iframe
          src={iframeUrl}
          frameBorder="0"
          className="iframe-container"
          title="analytics"
        ></iframe>
      )}
    </div>
  );
};

export default Analytics;
