import React, { FC, useEffect, useState } from 'react';
import { IContainerShipmentEntity } from 'types/entities/container.entity';
import { IImportShipmentsByTrackingProps } from 'types/feature/importShipment';

import CheckTrackingNumbers from './CheckTrackingNumbers';
import CreateShipmentsFromTracking from './CreateShipmentsFromTracking';
import './create-shipment-from-tracking-styles.scss';

const ImportShipmentsByTracking: FC<IImportShipmentsByTrackingProps> = ({
  onClose,
  handleModalCloseable,
}) => {
  const [stepState, setStepState] = useState(1);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [invalidTrackingNumbers, setInvalidTrakingNumbers] = useState<string[]>(
    []
  );

  const [existingShipments, setExistingShipments] = useState<
    IContainerShipmentEntity[]
  >([]);
  const [createdShipments, setCreatedShipments] = useState<
    IContainerShipmentEntity[]
  >([]);

  const onNextStep = () => {
    setStepState(stepState + 1);
  };

  const onInputText = (text: string) => setTextAreaValue(text);

  const onPreviousStep = () => {
    setStepState(stepState - 1);
  };
  const onChangeInvalidTrackingNumbers = (invalidTrackingNumbers: string[]) => {
    setInvalidTrakingNumbers(invalidTrackingNumbers);
  };
  const onShowExistingShipments = (shipments: IContainerShipmentEntity[]) => {
    setExistingShipments(shipments);
  };
  const onShowCreatedShipments = (shipments: IContainerShipmentEntity[]) => {
    setCreatedShipments(shipments);
  };

  useEffect(() => {
    return () => {
      setTextAreaValue('');
    };
  }, []);

  useEffect(() => {
    handleModalCloseable(stepState === 1);
  }, [stepState]);

  return (
    <div className="create-shipment-by-tracking">
      {stepState === 1 ? (
        <CheckTrackingNumbers
          textAreaValue={textAreaValue}
          onInputText={onInputText}
          onCloseModal={onClose}
          onShowExistingShipments={onShowExistingShipments}
          onShowCreatedShipments={onShowCreatedShipments}
          onChangeInvalidTrackingNumbers={onChangeInvalidTrackingNumbers}
          onNextStep={onNextStep}
        />
      ) : (
        <CreateShipmentsFromTracking
          onPreviousStep={onPreviousStep}
          onCloseModal={onClose}
          existingShipments={existingShipments}
          createdShipments={createdShipments}
          InvalidTrackingNumbers={invalidTrackingNumbers}
        />
      )}
    </div>
  );
};

export default ImportShipmentsByTracking;
