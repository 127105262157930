import {
  EditFilled,
  MinusCircleOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { useUpdateBookingByIdMutation } from 'api/bookings';
import LocationFields from 'app/design-system/LocationFields';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { IBookingEntity } from 'types/entities/booking.entity';

import { displayErrors } from 'utils/error-notification';
import { displayCountry } from 'utils/format-fields';
import { dateFormat2Const } from 'utils/shipment-type';
import { convertSlugToShipmentId } from 'utils/slug-formattor';

import { canAssignBooking } from '../helpers/assign-booking.helper';
import './style.scss';

// BookingUpdateModalProps
const { Text } = Typography;
const BookingEditDetails: FC<{
  booking: IBookingEntity | null;
  onUnassign: (id: string, shipmentId?: string) => void;
  onAssign: () => void;
}> = ({ booking, onUnassign, onAssign }) => {
  const isBookingAssignment = booking && canAssignBooking(booking);

  const [form] = Form.useForm<Partial<IBookingEntity>>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateBooking, { isLoading, isSuccess, isError, error }] =
    useUpdateBookingByIdMutation();

  const activateEditMode = () => setIsEditMode(true);

  const onSubmitForm = async () => {
    try {
      // DETAILS
      // 1. validate fields
      await form.validateFields();
      let updateBookingReq: Partial<IBookingEntity>;

      // 2. get data from fields

      updateBookingReq = await form.getFieldsValue(
        true,
        ({ touched }) => touched
      );

      if (updateBookingReq.origin) {
        updateBookingReq = {
          ...updateBookingReq,
          origin: {
            ...{ id: booking?.origin?.id },
            ...(await form.getFieldValue('originFull')),
          },
        };
      }

      if (updateBookingReq.destination) {
        updateBookingReq = {
          ...updateBookingReq,
          destination: {
            ...{ id: booking?.destination.id },
            ...(await form.getFieldValue('destinationFull')),
          },
        };
      }

      if (booking?.id) {
        await updateBooking({ ...updateBookingReq, id: booking?.id });
        setIsEditMode(false);
        form.resetFields();
      }
    } catch (error: unknown) {
      notification.warning({
        message: 'Wrong inputs',
        description: 'Make sure to fill all mandotary fields correctly !',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Booking updated successfully!' });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Update Booking Error' });
    }
  }, [isError, error]);

  const setupTitle = (title: string) =>
    isEditMode || !isBookingAssignment ? (
      title
    ) : (
      <Space>
        {title}
        <Button
          size="small"
          type="text"
          icon={<EditFilled />}
          onClick={activateEditMode}
        />
      </Space>
    );
  return (
    <div className="booking-details-wrapper">
      <Form
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        initialValues={
          booking
            ? {
                ...booking,
                cargoReadyDate: dayjs(booking.cargoReadyDate),
                destinationFull: booking.destination,
                originFull: booking.origin,
                destination: booking.destination?.city,
                origin: booking.origin?.city,
              }
            : {}
        }
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item label={setupTitle('Reference')} name="reference">
              {isEditMode ? <Input /> : <Text>{booking?.reference}</Text>}
            </Form.Item>
          </Col>

          {!isEditMode ? (
            <>
              <Col span={8}>
                <Form.Item
                  label={setupTitle('Origin')}
                  name="origin"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the origin country.',
                    },
                  ]}
                >
                  <Text>
                    {booking?.origin ? displayCountry(booking?.origin) : ''}
                  </Text>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={setupTitle('Destination')}
                  name="destination"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the destination country.',
                    },
                  ]}
                >
                  <Text>
                    {booking?.origin
                      ? displayCountry(booking?.destination)
                      : ''}
                  </Text>
                </Form.Item>
              </Col>
            </>
          ) : (
            <LocationFields
              form={form}
              isEditView={false}
              defaultLocations={null}
              colSpan={8}
            />
          )}

          <Col span={8}>
            <Form.Item
              label={setupTitle('Cargo Ready Date')}
              name="cargoReadyDate"
              rules={[
                {
                  required: true,
                  message: 'Please choose the estimated cargo ready date',
                },
              ]}
            >
              {isEditMode ? (
                <DatePicker />
              ) : (
                <Text>
                  {dayjs(booking?.cargoReadyDate).format(dateFormat2Const)}
                </Text>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Supplier">
              <Text>{booking?.supplier.company.name}</Text>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Shipment Ref">
              {booking?.shipment?.id ? (
                <Space>
                  <Space direction="vertical" align="center">
                    <Typography.Text strong className="row-po-text">
                      {convertSlugToShipmentId(booking.shipment.slug)}
                    </Typography.Text>
                    <Typography.Text type="secondary" className="row-sub-text">
                      {booking.shipment.shipmentReference
                        ? `${booking.shipment.shipmentReference}`
                        : ''}
                    </Typography.Text>
                  </Space>
                  <Tooltip
                    title={
                      isBookingAssignment
                        ? 'Unassign Shipment'
                        : `You have a viewer access. In order to edit, please ask admin user to provide you "editor" access`
                    }
                  >
                    <Button
                      icon={<MinusCircleOutlined />}
                      size="small"
                      type="primary"
                      onClick={() =>
                        onUnassign(booking?.id, booking?.shipment?.id)
                      }
                      disabled={!isBookingAssignment}
                    >
                      Unassign
                    </Button>
                  </Tooltip>
                </Space>
              ) : (
                <Tooltip
                  title={
                    isBookingAssignment
                      ? 'Assign Shipment'
                      : `You have a viewer access. In order to edit, please ask admin user to provide you "editor" access`
                  }
                >
                  <Button
                    icon={<PaperClipOutlined />}
                    size="small"
                    type="primary"
                    onClick={onAssign}
                    disabled={!isBookingAssignment}
                  >
                    Assign shipment
                  </Button>
                </Tooltip>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isEditMode ? (
        <Row gutter={[12, 12]} justify="end" className="action-row">
          <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
          <Button type="primary" onClick={onSubmitForm} disabled={isLoading}>
            Save
          </Button>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BookingEditDetails;
