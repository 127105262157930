import ShipmentPendingQuotes from 'app/components/Shipments/PendingQuotes';
import AppTabs from 'app/design-system/AppTabs';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ShipmentsTable from './ShipmentsTable';
import './shipments.scss';

const Shipments: FC = () => {
  const location = useLocation();
  const [tabKey, setTabKey] = useState<string>(
    location.state?.tab || 'all-shipments'
  );

  const onTabKeyChange = (key: string) => setTabKey(key);

  const tabItems = [
    {
      label: 'All Shipments',
      key: 'all-shipments',
      children: <ShipmentsTable />,
    },
    {
      label: 'Pending Quotations',
      key: 'pending-quotations',
      children: <ShipmentPendingQuotes />,
    },
  ];

  return (
    <DashboardCard headerTitle="My Shipments">
      <div className="shipment-wrapper">
        <AppTabs
          size="small"
          defaultActiveKey={tabKey}
          activeKey={tabKey}
          onTabClick={onTabKeyChange}
          items={tabItems}
        />
      </div>
    </DashboardCard>
  );
};

export default Shipments;
