import { CheckOutlined, HourglassOutlined } from '@ant-design/icons';
import { Empty, Spin, Timeline } from 'antd';
import AddContainerModal from 'app/components/Container/AddContainerModal';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { ContainerModalTypeEnum } from 'types/feature/container.types';
import { ITrackingListProps } from 'types/feature/tracking.types';

import { isViewer } from 'utils/collaborators';
import { isCargoOwner, isForwarder } from 'utils/shipment-helper';
import { isCourier } from 'utils/shipment-type';

import { renderAirTrackingEvent, renderTrackingEvent } from './TrackingEvent';
import './tracking-list.scss';

const TrackingList: FC<ITrackingListProps> = (props) => {
  const {
    shipment,
    shipment: {
      currentUserRole,
      slug: shipmentSlug,
      id: shipmentId,
      currentCollaborator,
    },
    isError,
    isLoading,
    trackingData,
    isAirTracking,
  } = props;
  const trackingListRef = useRef<HTMLDivElement>(null);

  const isTrackingActivated = trackingData && trackingData.length > 0;
  const shipmentType = shipment?.acceptedShipmentType ?? shipment?.shipmentType;
  const isActionEnabled =
    (isCargoOwner(currentUserRole) || isForwarder(currentUserRole)) &&
    !isViewer(currentCollaborator.accessLevel);

  const scrollToBottom = useCallback(() => {
    if (trackingData) {
      const lastItem = trackingListRef.current?.getElementsByClassName(
        `tracking-item-${trackingData.length - 1}`
      );
      if (lastItem && lastItem.length > 0) {
        lastItem[lastItem.length - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [trackingData]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, trackingListRef]);

  return (
    <Spin spinning={isLoading}>
      <div className="tracking-wrapper" ref={trackingListRef}>
        {isError ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Tracking Availabes. Something went wrong!"
          />
        ) : (
          <>
            {isTrackingActivated ? (
              <Timeline
                items={
                  isAirTracking
                    ? trackingData?.map((item, index) => {
                        return {
                          dot: <CheckOutlined />,
                          children: renderAirTrackingEvent({
                            item,
                            isCourier: isCourier(shipment.shipmentType.title),
                          }),
                          className: `tracking-active tracking-item-${index}`,
                        };
                      })
                    : trackingData?.map((item, index) => {
                        return {
                          dot: item.actual ? (
                            <CheckOutlined />
                          ) : (
                            <HourglassOutlined />
                          ),
                          color: item.actual ? 'blue' : 'gray',
                          children: renderTrackingEvent({ item }),
                          className: item.actual
                            ? `tracking-active tracking-item-${index}`
                            : `tracking-item-${index}`,
                        };
                      })
                }
              />
            ) : (
              <div className="activate-tracking">
                {isActionEnabled ? (
                  <AddContainerModal
                    actionButton={{
                      text: 'Activate',
                      type: 'primary',
                    }}
                    showConfirmModal={false}
                    shipmentType={shipmentType.title}
                    shipmentId={shipmentId}
                    shipmentSlug={shipmentSlug}
                    containerModalType={ContainerModalTypeEnum.BOTH}
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Tracking Availabes"
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default TrackingList;
