import {
  Badge,
  Button,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  message,
} from 'antd';
import {
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceByIdMutation,
} from 'api/invoice';
import {
  useDeleteInvoiceItemByIdMutation,
  usePutInvoiceItemByIdMutation,
} from 'api/invoice-item';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ICommentEntity } from 'types/entities/comment.entity';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IInvoiceItemEntity } from 'types/entities/invoice.entity';
import {
  ColumnTypes,
  IInvoiceDetails,
  IInvoiceMatchingTableProps,
} from 'types/feature/invoice.types';

import { invoiceCopyToRightEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { convertMoney, exchangeRates } from 'utils/money-conversion';

import { ReactComponent as DeleteBinIcon } from '../../../../assets/icons/bin-icon-outlined.svg';
import { ReactComponent as AddCommentIcon } from '../../../../assets/icons/comment-add-icon-outlined.svg';
import InvoiceDetails from '../InvoiceDetails';
import InvoiceItemComments from '../InvoiceItemComments';
import { EditableRow, EditableCell } from './EditableTable';
import {
  computeGrandTotal,
  isProposalMatching,
  isRandomId,
} from './InvoiceMatchingTable.utils';
import {
  renderInvoiceMatchingTableCol,
  renderTotalAmount,
} from './invoice-matching-table.const';
import './invoice-matching-table.scss';

const InvoiceMatchingTable: FC<IInvoiceMatchingTableProps> = ({
  invoice,
  tableView,
  onContinueClick,
  onBackClick,
  isItemDetailEditabale = false,
}) => {
  const [count, setCount] = useState(2);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [selectedIitem, setSelectedItem] = useState<string | null>(null);

  const [dataSource, setDataSource] = useState<IInvoiceItemEntity[]>([]);
  const isProposalMatchingView = isProposalMatching(tableView);
  const user = useSelector((state: RootState) => state.user.user);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<IInvoiceDetails>(
    {
      mbl: invoice.mbl,
      dueDate: invoice.dueDate,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      invoiceNo: invoice.invoiceNo,
      supplierVatNo: invoice.supplierVatNo,
      buyerVatNo: invoice.buyerVatNo,
    }
  );

  useEffect(() => {
    setDataSource(invoice.invoiceItems);
  }, [invoice.invoiceItems]);

  useEffect(() => {
    setInvoiceDetailsData({
      mbl: invoice.mbl,
      dueDate: invoice.dueDate,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      invoiceNo: invoice.invoiceNo,
      supplierVatNo: invoice.supplierVatNo,
      buyerVatNo: invoice.buyerVatNo,
    });
  }, [
    invoice.mbl,
    invoice.dueDate,
    invoice.issueDate,
    invoice.currency,
    invoice.invoiceNo,
    invoice.supplierVatNo,
    invoice.buyerVatNo,
  ]);

  const [updateInvoiceItem, { isLoading: updateInvoiceItemLoading }] =
    usePutInvoiceItemByIdMutation();

  const [updateInvoiceById, { isLoading: updateInvoiceLoading }] =
    useUpdateInvoiceByIdMutation();

  const [refetchGetInvoiceById, { isLoading: refetchGetInvoiceByIdLoading }] =
    useLazyGetInvoiceByIdQuery();

  const [deleteInvoiceItem, { isLoading: isDeleteInvoiceItemLoading }] =
    useDeleteInvoiceItemByIdMutation();

  const onInvoiceDetailsChange = async (
    newInvoiceDetails: Partial<IInvoiceDetails>
  ) => {
    try {
      await updateInvoiceById({
        id: invoice.id,
        invoice: {
          ...newInvoiceDetails,
        },
      }).unwrap();
      await refetchGetInvoiceById(invoice.id).unwrap();

      message.success('Invoice details updated successfuly');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on update invoice header details' });
    }
  };

  const onCommentsUpdate = (
    newComments: ICommentEntity,
    invoiceItemId: string
  ) => {
    setDataSource((prevDataSource) => {
      return prevDataSource.map((item) =>
        item.id === invoiceItemId
          ? {
              ...item,
              comments: item.comments
                ? [...item.comments, newComments]
                : [newComments],
            }
          : item
      );
    });
  };

  const onCopyActualCost = (index: number) => {
    setDataSource((prevDataSource) => {
      return prevDataSource.map((item, itemIndex) =>
        itemIndex === index
          ? {
              ...item,
              totalCost: item.parsedTotalCost,
              costAndCurrency: {
                ...item.costAndCurrency,
                convertedCost:
                  // While copying invoiceCost to qoutedCost,
                  // and when invoiceItemCurrency != proposalItemCurrency we dont have convertedProposalCost from BE.
                  item.costAndCurrency.proposalItemCurrency &&
                  item.costAndCurrency.invoiceItemCurrency !==
                    item.costAndCurrency.proposalItemCurrency
                    ? convertMoney(
                        item.costAndCurrency.invoiceCurrency,
                        item.costAndCurrency.proposalItemCurrency ??
                          item.costAndCurrency.invoiceItemCurrency,
                        item.parsedTotalCost
                      )
                    : item.costAndCurrency.convertedParsedCost,
              },
            }
          : item
      );
    });

    invoiceCopyToRightEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
      shipment_id: invoice.shipmentId,
      invoice_id: invoice.id,
    });
  };

  const onShowCommentModal = (invoiceItemId: string) => {
    setIsCommentsOpen(true);
    setSelectedItem(invoiceItemId);
  };

  const onHideCommentModal = () => {
    setIsCommentsOpen(false);
    setSelectedItem(null);
  };

  const onInvoiceItemDelete = async (id: string, index) => {
    const newData = dataSource.filter((item) => item.id !== id);
    const deletedData = dataSource[index];

    if (deletedData && deletedData.id && !isRandomId(deletedData.id)) {
      await deleteInvoiceItem(deletedData.id)
        .unwrap()
        .then((resp) => {
          message.success('Item deleted Successfuly');
          refetchGetInvoiceById(invoice.id);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) =>
          displayErrors(error, { title: 'Error on deleting invoice' })
        );
    }
    setDataSource(newData);
  };

  const onInvoiceItemAdd = () => {
    const newData: IInvoiceItemEntity = {
      id: `$random$-${Date.now()}`,
      description: '',
      rate: 0,
      quantity: 0,
      tax: 0,
      parsedTotalCost: 0,
      totalCost: 0,
      currency: invoice.currency || CurrencyEnum.USD,
      measurementUnit: null,
      createdBy: '',
      proposalItemId: '',
      invoiceId: '',
      proposalItem: null,
      costAndCurrency: {
        cost: 0,
        convertedCost: 0,
        parsedCost: 0,
        convertedParsedCost: 0,
        invoiceCurrency: invoice.currency || CurrencyEnum.USD,
        invoiceItemCurrency: invoice.currency || CurrencyEnum.USD,
        proposalItemCurrency: null,
        conversionRate: exchangeRates[invoice.currency || CurrencyEnum.USD],
      },
      comments: [],
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const onInvoiceItemSave = (row: IInvoiceItemEntity, dataIndex: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = {
      ...newData[index],
    };

    newData.splice(index, 1, {
      ...item,
      ...row,
      costAndCurrency: {
        ...newData[index].costAndCurrency,
        cost: row.totalCost,
        convertedCost: convertMoney(
          row.costAndCurrency.invoiceCurrency,
          row.costAndCurrency.proposalItemCurrency ??
            row.costAndCurrency.invoiceItemCurrency,
          row.totalCost
        ),
        parsedCost: row.parsedTotalCost,
        convertedParsedCost: convertMoney(
          row.costAndCurrency.invoiceCurrency,
          row.costAndCurrency.invoiceItemCurrency,
          row.parsedTotalCost
        ),
      },
    });

    setDataSource(newData);
  };

  const defaultColumns = [
    ...renderInvoiceMatchingTableCol({
      isItemDetailEditabale,
      tableView,
      onCopyActualCost,
      isTaxEnabled: !!invoice?.totalTax,
    }),
    {
      title: '',
      dataIndex: 'operation',
      editable: false,
      width: '100px',
      render: (_, record, rowIndex) => (
        <div className="icon-wrapper">
          <Space>
            {!isRandomId(record.id) && (
              <Badge dot={!!(record.comments?.length > 0)}>
                <Button
                  type="link"
                  icon={<AddCommentIcon />}
                  onClick={() => onShowCommentModal(record.id)}
                  loading={isDeleteInvoiceItemLoading}
                />
              </Badge>
            )}

            {dataSource.length > 1 && isItemDetailEditabale && (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onInvoiceItemDelete(record.id, rowIndex)}
              >
                <Button
                  type="link"
                  icon={<DeleteBinIcon />}
                  loading={isDeleteInvoiceItemLoading}
                />
              </Popconfirm>
            )}
          </Space>
        </div>
      ),
    },
  ];

  const tableComponents = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IInvoiceItemEntity) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: onInvoiceItemSave,
      }),
    };
  });

  // Check if any required field is empty or null in the dataSource
  const isRequiredFieldsEmpty = () =>
    dataSource.some(
      (item) => !item.description || item.parsedTotalCost === null
    );

  const onInvoiceSave = async (canContinue: boolean) => {
    if (isRequiredFieldsEmpty()) {
      message.error('Please fill in all required fields.');
      return;
    }
    try {
      await updateInvoiceById({
        id: invoice.id,
        invoice: {
          ...invoiceDetailsData,
          totalCost: Number(computeGrandTotal(dataSource).invoiceTotal),
          proposalTotalCost: Number(
            computeGrandTotal(dataSource).proposalTotal
          ),
        },
      }).unwrap();

      await updateInvoiceItem({
        invoiceId: invoice.id,
        invoiceItems: dataSource.map((item) => {
          return {
            ...(isRandomId(item?.id) ? {} : { id: item.id }),
            proposalItemId: item?.proposalItem?.id ?? null,
            ...(!!invoice?.totalTax && { tax: item.tax }),
            description: item.description,
            rate: item.rate ? parseFloat(item?.rate?.toString()) : 0,
            quantity: item.quantity
              ? parseFloat(item?.quantity?.toString())
              : 0,
            totalCost: item.totalCost
              ? parseFloat(item?.totalCost?.toString())
              : 0,
            parsedTotalCost: item.parsedTotalCost
              ? parseFloat(item?.parsedTotalCost?.toString())
              : 0,
            currency: item?.currency ?? null,
            measurementUnit: item?.measurementUnit ?? null, // Corrected typo
          };
        }),
      }).unwrap();

      message.success('Table updated successfuly');

      if (canContinue) {
        onContinueClick();
      }
      await refetchGetInvoiceById(invoice.id).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, {
        title: 'Error on updating invoice',
      });
    }
  };

  const onBackBtnClick = async () => {
    await onInvoiceSave(false);
    onBackClick();
  };

  const renderCommentsModal = () => (
    <Modal
      title="Invoice Item Comment"
      open={isCommentsOpen}
      onCancel={onHideCommentModal}
      footer={null}
    >
      {selectedIitem && (
        <InvoiceItemComments
          invoiceId={invoice.id}
          shipmentId={invoice.shipmentId}
          invoiceItemId={selectedIitem}
          onUpdate={onCommentsUpdate}
        />
      )}
    </Modal>
  );

  return (
    <>
      <InvoiceDetails
        invoiceDetails={invoiceDetailsData}
        onChange={onInvoiceDetailsChange}
        isEditabale={isItemDetailEditabale}
        isLoading={updateInvoiceLoading}
      />

      <Table
        rowKey="id"
        components={tableComponents}
        columns={columns as ColumnTypes}
        dataSource={dataSource}
        className={`table-wrapper--no-border invoice-matching-table-wrapper ${tableView}`}
        rowClassName="editable-row"
        pagination={false}
        size="middle"
        {...(isProposalMatching(tableView)
          ? { scroll: { x: '500px' } }
          : { scroll: { x: '600px' } })}
        summary={(record) => (
          <Table.Summary>
            {isProposalMatchingView ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total Amount</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {renderTotalAmount(
                    computeGrandTotal(record).invoiceTotal,
                    computeGrandTotal(record).proposalTotal,
                    invoice.currency
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {renderTotalAmount(
                    computeGrandTotal(record).proposalTotal,
                    computeGrandTotal(record).invoiceTotal,
                    invoice.currency
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                {!!invoice?.totalTax && (
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                )}

                <Table.Summary.Cell index={4}>Total Amount</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {renderTotalAmount(
                    computeGrandTotal(record).invoiceTotal,
                    invoice.parsedTotalCost || 0,
                    invoice.currency
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          </Table.Summary>
        )}
      />
      {isItemDetailEditabale && (
        <Button onClick={onInvoiceItemAdd} type="default" size="small">
          Add a row
        </Button>
      )}
      <Row className="invoice-matching-footer" justify="end">
        <Space>
          {isProposalMatchingView && (
            <Button
              type="default"
              onClick={onBackBtnClick}
              disabled={
                updateInvoiceItemLoading ||
                updateInvoiceLoading ||
                refetchGetInvoiceByIdLoading
              }
            >
              Back
            </Button>
          )}
          <Button
            type="default"
            onClick={() => onInvoiceSave(false)}
            loading={
              updateInvoiceItemLoading ||
              updateInvoiceLoading ||
              refetchGetInvoiceByIdLoading
            }
          >
            Save
          </Button>
          <Button
            type="primary"
            onClick={() => onInvoiceSave(true)}
            disabled={
              updateInvoiceLoading ||
              updateInvoiceItemLoading ||
              refetchGetInvoiceByIdLoading
            }
          >
            Continue
          </Button>
        </Space>
      </Row>
      {selectedIitem && renderCommentsModal()}
    </>
  );
};

export default InvoiceMatchingTable;
