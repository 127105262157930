import { Button, Tooltip, notification } from 'antd';
import {
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
} from 'api/bookmark';
import React, { FC, useEffect, useState } from 'react';
import { IBookmarkButtonProps } from 'types/feature/bookmarks.types';

import { ReactComponent as BookmarkIcon } from '../../../assets/icons/bookmark-icon.svg';

const BookmarkButton: FC<IBookmarkButtonProps> = ({
  shipmentId,
  isBookmarked,
}) => {
  const [bookmarked, setBookmarked] = useState<boolean>(isBookmarked);

  useEffect(() => {
    setBookmarked(isBookmarked);
  }, [isBookmarked]);

  const [
    createBookmark,
    { isLoading: isUpdating, error: createError, isSuccess: isCreateSuccess },
  ] = useCreateBookmarkMutation();

  const [
    deleteBookmark,
    { isLoading: isDeleting, error: deleteError, isSuccess: isDeleteSuccess },
  ] = useDeleteBookmarkMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to update bookmark',
    });
  };

  useEffect(() => {
    if (createError || deleteError) {
      errorNotification();
    }
  }, [createError, deleteError]);

  useEffect(() => {
    if (isCreateSuccess) setBookmarked(true);
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) setBookmarked(false);
  }, [isDeleteSuccess]);

  const toggleBookmark = () => {
    if (bookmarked) deleteBookmark(shipmentId);
    else createBookmark(shipmentId);
  };

  return (
    <Tooltip
      placement="top"
      title={bookmarked ? 'Delete Bookmark' : 'Bookmark'}
    >
      <Button
        data-testid="shipment-bookmark-btn"
        type="link"
        loading={isUpdating || isDeleting}
        onClick={toggleBookmark}
        icon={<BookmarkIcon {...(bookmarked && { fill: '#000' })} />}
      />
    </Tooltip>
  );
};

export default BookmarkButton;
