import { Row, Col } from 'antd';
import PDFViewer from 'app/design-system/PDFViewer';
import React, { FC } from 'react';
import { IFileEntity } from 'types/entities/proposal.entity';
import {
  IInvoiceMatchingProps,
  InvoiceStatusEnum,
  InvoiceTableViewEnum,
} from 'types/feature/invoice.types';

import InvoiceHeader from '../InvoiceHeader';
import InvoiceMatchingTable from '../InvoiceMatchingTable';
import { isInvoiceApproved } from '../InvoiceStatusSwitch/helpers/helper';
import './invoice-matching.scss';

export const searchHiglightedFileUrl: (
  files: IFileEntity[],
  status: InvoiceStatusEnum
) => string = (files: IFileEntity[], status: InvoiceStatusEnum) => {
  let newFile = '';

  if (isInvoiceApproved(status)) {
    newFile =
      files.find((file) => file.file.includes('parsedApproved'))?.file ||
      files.find((file) => file.file.includes('approved'))?.file ||
      files[0]?.file ||
      '';
  } else {
    newFile =
      files.find((file) => file.file.includes('documentParsed'))?.file ||
      files[0]?.file ||
      '';
  }

  return newFile;
};

const InvoiceMatching: FC<IInvoiceMatchingProps> = ({
  invoice,
  onStepChange,
}) => {
  const onContinueClick = () => {
    onStepChange(2);
  };

  const onBackClick = () => {
    onStepChange(1);
  };

  return (
    <>
      <Row gutter={[32, 32]} className="pdf-matchin-wrapper">
        <Col span={12} className="invoice-table-wrapper">
          <InvoiceHeader invoice={invoice} />
          <InvoiceMatchingTable
            invoice={invoice}
            tableView={InvoiceTableViewEnum.INVOICE_MATCHING}
            onContinueClick={onContinueClick}
            onBackClick={onBackClick}
            isItemDetailEditabale={true}
          />
        </Col>
        <Col span={12} className="invoice-preview-wrapper">
          <PDFViewer
            file={searchHiglightedFileUrl(invoice.files, invoice.status)}
          />
        </Col>
      </Row>
    </>
  );
};

export default InvoiceMatching;
