import Icon from '@ant-design/icons';
import { Form, Button, Row, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import ControlCard from 'app/design-system/ControlCard';
import React, { FC, useEffect } from 'react';
import {
  IAddForwarderFormCompact,
  IForwrdersFormProps,
} from 'types/feature/forwarders.types';

import { ReactComponent as PlusOutlinedIcon } from '../../../../assets/iconsNew/plus-icon-outlined.svg';
import { renderForwarderFieldList } from './AddForwarderFieldListCompact';
import { UseForwarderForm } from './UseForwarderForm';

const AddForwarderFormCompact: FC<IAddForwarderFormCompact> = ({
  shipmentId,
  onNavigateToShipmentPage,
}) => {
  const [form] = useForm<IForwrdersFormProps>();

  const {
    onInviteForwarder,
    handleSelectAcompany,
    addForwarderSuccess,
    addForwarderLoading,
  } = UseForwarderForm(form, shipmentId);

  useEffect(() => {
    if (addForwarderSuccess) {
      notification.success({ message: 'Invitation sent to forwarders !' });

      if (onNavigateToShipmentPage) {
        onNavigateToShipmentPage();
      }
    }
  }, [addForwarderSuccess]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          forwareders: [{ fullname: '', email: '', company: '' }],
        }}
      >
        <Form.List name="forwareders">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <ControlCard
                  key={field.key}
                  renderMain={renderForwarderFieldList({
                    field,
                    isDeleteActionEnabled: !!(fields.length > 1),
                    onRemoveField: remove,
                    handleSelectAcompany,
                  })}
                />
              ))}

              <Row justify="end">
                <Button
                  className="my-2 float-right"
                  onClick={add}
                  icon={<Icon component={PlusOutlinedIcon} size={25} />}
                >
                  Add Another
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
      <Row gutter={[12, 12]} justify="end" className="my-2">
        <Button
          type="primary"
          onClick={onInviteForwarder}
          loading={addForwarderLoading}
        >
          Invite Forwarders
        </Button>
      </Row>
    </>
  );
};

export default AddForwarderFormCompact;
