import { Row, Col } from 'antd';
import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TShipmentOverviewProps } from 'types/feature/shipments.types';

import BookingParties from '../../BookingParties';
import ShipmentInfo from '../ShipmentInfo';
import ShipmentStatus from '../ShipmentStatus';
import ShipmentTasks from '../ShipmentTasks';
import ShipmentTracking from '../ShipmentTracking';
import './shipment-overview.scss';

const ShipmentOverview: FC<TShipmentOverviewProps> = ({ shipment }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={11} xs={24} md={10}>
          <ShipmentStatus shipment={shipment} />
        </Col>
        <Col lg={13} xs={24} md={14}>
          <ShipmentTracking shipment={shipment} showMap cardOverlay={true} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col lg={7} xs={24} md={12}>
          <ShipmentInfo shipment={shipment} cardOverlay={true} />
        </Col>
        <Col lg={8} xs={24} md={12}>
          <BookingParties shipment={shipment} cardOverlay={true} />
        </Col>
        <Col lg={9} xs={24} md={12}>
          <ShipmentTasks shipment={shipment} cardOverlay={true} />
        </Col>
      </Row>
    </>
  );
};

export const ShipmentOverviewRouteWrapper: FC = () => {
  const props = useOutletContext<TShipmentOverviewProps>();

  return <ShipmentOverview {...props} />;
};

export default ShipmentOverview;
