import { DownCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, FloatButton } from 'antd';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';

import { aiAssistClickEvent } from 'utils/analytics-events';

import { useChat } from '../../../providers/chat-provider';
import ChatBox from './ChatBox';
import './chat-agent.scss';

const ChatAgent: FC = () => {
  const { isChatOpen, closeChat } = useChat();
  const { openChat } = useChat();
  const user = useSelector((state: RootState) => state.user.user);

  const showOrHideChat = () => {
    if (isChatOpen) {
      closeChat();
    } else {
      openChat();
      aiAssistClickEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: '#4961F6',
            colorPrimaryHover: '#4961F6AA',
          },
          FloatButton: {
            colorPrimary: '#4961F6',
            colorPrimaryHover: '#4961F6AA',
          },
        },
      }}
    >
      <div className="chat-wrapper" hidden={!isChatOpen}>
        <ChatBox onClose={closeChat} />
      </div>

      <FloatButton
        icon={isChatOpen ? <DownCircleOutlined /> : <QuestionCircleOutlined />}
        type="primary"
        style={{ right: 24 }}
        onClick={() => showOrHideChat()}
      />
    </ConfigProvider>
  );
};

export default ChatAgent;
