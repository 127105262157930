import { SyncOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Space, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import remarkGfm from 'remark-gfm';
import { RootState } from 'state/reducer';
import {
  addResponse,
  addQuestion,
  resetQuestion,
  removeQuestion,
} from 'state/slices/chat.slice';
import { ChatUserEnum } from 'types/entities/chat.entity';
import { IChatBoxProps } from 'types/feature/chat.types';

import { aiAssistMessageSendEvent } from 'utils/analytics-events';

import { ReactComponent as BeeboltLogo } from '../../../../assets/main-logo.svg';
import { useChatRequest } from '../MessagesTab/useChatRequest';
import { FAQ_CONST, customAntComponents } from '../chat.const';
import './chat-box.scss';

function removeNameAttribute(arr) {
  return arr.map((item) => {
    const { name, ...rest } = item;
    return rest;
  });
}

const { Text, Title } = Typography;

const ChatBox: FC<IChatBoxProps> = ({ onClose }) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.user.user);

  const [question, setQuestion] = useState<string>('');
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.chat.chat);

  const { sendQuestion, onStreamReset, isStreaming } = useChatRequest({
    onSaveResponse: async (data: string) => {
      dispatch(addResponse(data));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onErrorResponse: async (error: any) => {
      dispatch(removeQuestion());
    },
  });

  const onClickSend = async (question: string, isSuggested: boolean) => {
    if (!question || !question.length) {
      return;
    }

    if (!user?.email) {
      return;
    }

    dispatch(
      addQuestion({
        name: user.fullname,
        text: question,
        user: ChatUserEnum.HUMAN,
      })
    );

    dispatch(
      addQuestion({
        name: 'Beebolt Bot',
        text: '',
        user: ChatUserEnum.AI,
      })
    );

    sendQuestion({
      chatRequest: {
        question,
        chatHistory: removeNameAttribute(messages),
        companyID: user.company?.id || '',
        userID: user.id || '',
      },
    });
    setQuestion('');
    aiAssistMessageSendEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company?.name || '',
      company_id: user?.companyId,
      message: question,
      is_suggested: isSuggested,
    });
  };

  const onReset = () => {
    dispatch(resetQuestion());
    onStreamReset();
  };

  const scrollToBottom = useCallback(() => {
    const lastItem = messageContainerRef.current?.getElementsByClassName(
      `message-item-${messages.length - 1}`
    );
    if (lastItem && lastItem.length > 0) {
      lastItem[lastItem.length - 1].scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, messageContainerRef]);

  return (
    <div className="chat-box">
      <div className="chat-box__header">
        <Space>
          <BeeboltLogo className="beebolt-icon" />
          <Title level={5} className="m-0">
            AI Assistant
          </Title>
        </Space>
        <Space>
          <Button
            type="text"
            icon={<SyncOutlined />}
            onClick={onReset}
            disabled={isStreaming}
          />
          <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
        </Space>
      </div>
      <div className="chat-box__body" ref={messageContainerRef}>
        {messages?.map((message, index) => (
          <div
            key={`message-item-${index}`}
            className={`message-item-wrapper message-item-${index} ${message.user}`}
          >
            <div className={`message-content ${message.user}`}>
              {!message.text ? (
                <Space align="center" direction="horizontal">
                  <Text className="loader-text">Looking for an answer</Text>
                  <span className="loader" />
                </Space>
              ) : (
                <Markdown
                  components={customAntComponents}
                  remarkPlugins={[remarkGfm]}
                >
                  {message.text}
                </Markdown>
              )}
            </div>
          </div>
        ))}
      </div>
      {!isStreaming && (
        <div className="chat-box__pills-wrapper">
          {FAQ_CONST.map((question, index) => (
            <div
              key={`question-${index}`}
              className="pills-item"
              onClick={() => onClickSend(question, true)}
            >
              {question}
            </div>
          ))}
        </div>
      )}
      <div className="chat-box__footer">
        <Input
          placeholder="Ask new question..."
          className="chat-box__footer-input"
          allowClear
          type="text"
          value={question}
          onChange={(event) => setQuestion(event.target.value)}
          onPressEnter={() => !isStreaming && onClickSend(question, false)}
        />
        <Button
          className="chat-box__footer-btn"
          type="primary"
          size="middle"
          onClick={() => onClickSend(question, false)}
          disabled={!question || isStreaming}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default ChatBox;
