import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography, Input } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import {
  useDownloadShipmentExcelSheetMutation,
  useUpdateShipmentsSearchMutation,
} from 'api/shipment';
import { useGetUserTableSettingQuery } from 'api/user-table-columns';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import ShipmentPreview from 'app/components/ShipmentPreview';
import {
  defaultPaginationAndSorting,
  removeEmptyArrays,
} from 'app/components/Shipments/helpers/helper';
import ShipmentReference from 'app/design-system/ShipmentReference';
import ShipmentTagsDisplay from 'app/design-system/ShipmentTags';
import TableViewFilter from 'app/design-system/TableViewFilter';
import dayjs from 'dayjs';
import { useUTMState } from 'providers/utm-provider';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { SearchAndSortParams } from 'types/feature/pagination.types';
import {
  IShipmentsFiltersState,
  IShipmentsTableProps,
} from 'types/feature/shipments.types';
import { UserTablesEnum } from 'types/feature/user-table-setting.type';

import {
  previewShipmentCtaClickEvent,
  shipmentListViewEvent,
} from 'utils/analytics-events';
import { downloadExcelFileFromBuffer } from 'utils/download-excel-file';
import { displayErrors } from 'utils/error-notification';
import {
  excludeEmptyKeyColumns,
  filterColumns,
} from 'utils/filter-table-columns';

import BookmarkButton from '../../BookmarkButton';
import { useDynamicColumns } from '../hooks/useDynamicColumns';
import {
  defaultSelectedColumns,
  mainColumnConst,
  shipmemtColumnConst,
} from '../shipment.const';
import '../shipments.scss';
import { ShipmentFilters } from './shipment-filters';

const { Search } = Input;

const ShipmentsTable: FC<IShipmentsTableProps> = ({
  isBookingAssignment = false,
  onAssignToShipment,
}) => {
  const { dynamicColumns } = useDynamicColumns();

  const user = useSelector((state: RootState) => state.user.user);

  const campaign = useUTMState();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  const [selectedColumns, setSelectedColumns] = useState<
    ColumnsType<IShipmentEntity>
  >([]);

  const [shipmentSearch, setShipmentSearch] = useState<IShipmentsFiltersState>({
    ...(location.state?.status && { statuses: [location.state?.status] }),
  });

  const [tableViewFilter, setTableViewFilter] = useState<string[]>([]);
  const [isTableViewOpen, setIsTableViewOpen] = useState(false);

  const [paginationAndSortingState, setPaginationAndSortingState] =
    useState<SearchAndSortParams>(defaultPaginationAndSorting);

  const [shipmentInPreview, setShipmentInPreview] = useState<string | null>(
    null
  );

  const [selectedShipments, setSelectedShipments] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedShipments(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedShipments,
    onChange: onSelectChange,
  };

  const onShipmentPreviewClose = () => {
    setShipmentInPreview(null);
  };

  const { data: tableSettingData, isSuccess: isSettingRetrieved } =
    useGetUserTableSettingQuery({
      tableName: UserTablesEnum.shipmentsTable,
    });

  const [updateShipmentsSearch, { data: updatedShipmentData, isLoading }] =
    useUpdateShipmentsSearchMutation();

  const [downloadExcelFile, { isLoading: isDownloading }] =
    useDownloadShipmentExcelSheetMutation();

  const shipmentDetailRoute = (id: string, slug: string) =>
    window.open(`/shipments/${slug}`, '_blank');

  // reconstruct function on every user change
  const shipmentProposalRoute = (id: string, email: string) => {
    navigate(`/proposals/${id}?email=${email}`);
  };

  const onShipmentOverview = ({ shipmentAccess, slug }: IShipmentEntity) => {
    // TODO: TEMPORARY FIX
    if (!shipmentAccess.hasAccessToShipment) {
      shipmentProposalRoute(
        shipmentAccess.targetId,
        shipmentAccess.currentUserEmail
      );
    } else {
      shipmentDetailRoute(shipmentAccess.targetId, slug);
    }
  };

  const onRow = (record: IShipmentEntity, index: number | undefined) => {
    return {
      onClick: () => {
        if (!isBookingAssignment) onShipmentOverview(record);
      },
    };
  };

  const onPreviewShipment = (shipment: IShipmentEntity) => {
    if (user) {
      previewShipmentCtaClickEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    }

    setShipmentInPreview(shipment.slug);
  };

  const onDownloadExcelFile = async () => {
    try {
      const resp = await downloadExcelFile({
        shipmentIds: selectedShipments as string[],
      }).unwrap();

      // Convert the buffer data array to a Uint8Array
      const bufferData = new Uint8Array(resp.data);
      const fileName = `Shipments_${dayjs().format('DD-MM-YYYY')}.xlsx`;

      // Download the file
      downloadExcelFileFromBuffer(bufferData, fileName);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on downloading file' });
    } finally {
      setSelectedShipments([]);
    }
  };

  const columns: ColumnsType<IShipmentEntity> = isBookingAssignment
    ? [
        ...mainColumnConst,
        {
          title: 'Actions',
          key: 'actions',
          onCell: (record) => {
            return {
              onClick: (event) => {
                event.stopPropagation(); // this will avoid onRow being called
              },
            };
          },
          render: (_: string, record: IShipmentEntity, index: number) => {
            return (
              <Button
                key={index}
                type="primary"
                size="small"
                icon={<PaperClipOutlined />}
                onClick={() => {
                  if (onAssignToShipment)
                    onAssignToShipment(record?.id, record.slug, false);
                }}
              >
                Assign
              </Button>
            );
          },
        },
      ]
    : [
        {
          title: 'Shipment ID',
          dataIndex: 'slug',
          key: 'slug',
          sorter: true,
          className: 'shipment-id-cell',
          fixed: 'left',
          onCell: (record) => {
            return {
              onClick: (event) => {
                event.stopPropagation(); // this will avoid onRow being called
              },
            };
          },
          render: (_, record: IShipmentEntity) => {
            return (
              <Space direction="vertical" align="center">
                <>
                  <Space>
                    {record.shipmentAccess.hasAccessToShipment ? (
                      <Link
                        to={`/shipments/${record?.slug}`}
                        state={{
                          shipmentId: record?.shipmentAccess?.targetId,
                        }}
                      >
                        {record?.slug}
                      </Link>
                    ) : (
                      <Typography.Link
                        onClick={() => {
                          shipmentProposalRoute(
                            record?.shipmentAccess?.targetId,
                            record.shipmentAccess.currentUserEmail
                          );
                        }}
                      >
                        {record?.slug}
                      </Typography.Link>
                    )}
                    <Button
                      className="preview-btn"
                      onClick={() => {
                        if (onPreviewShipment) onPreviewShipment(record);
                      }}
                      size="small"
                    >
                      Preview
                    </Button>
                  </Space>
                  <ShipmentReference
                    reference={record.shipmentReference}
                    className={
                      tableSettingData && tableSettingData.length <= 8
                        ? 'reference-text__long'
                        : ''
                    }
                  />
                </>

                <ShipmentTagsDisplay
                  tags={record.shipmentMetadata}
                  maxNumber={3}
                />
              </Space>
            );
          },
          align: 'center',
        },
        ...shipmemtColumnConst,
        ...dynamicColumns,
        {
          title: 'Actions',
          key: 'actions',
          onCell: (record) => {
            return {
              onClick: (event) => {
                event.stopPropagation(); // this will avoid onRow being called
              },
            };
          },
          render: (_: string, record: IShipmentEntity, index: number) => {
            return (
              <Space>
                <BookmarkButton
                  key={`bookemark-btn-${index}`}
                  shipmentId={record?.id}
                  isBookmarked={record?.isBookmarked}
                />

                {record?.shipmentAccess.hasAccessToShipment ? (
                  <Button
                    key={index}
                    onClick={() =>
                      shipmentDetailRoute(
                        record?.shipmentAccess?.targetId,
                        record?.slug as string
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => {
                      shipmentProposalRoute(
                        record?.shipmentAccess?.targetId,
                        record?.shipmentAccess.currentUserEmail
                      );
                    }}
                  >
                    View
                  </Button>
                )}
              </Space>
            );
          },
        },
      ];

  const computeTableColumnsWithFilters = (
    selectedKeys: string[],
    columns: ColumnsType<IShipmentEntity>
  ) => {
    const columnData = excludeEmptyKeyColumns(columns);
    // TODO : we are maintaining two states for columns, why ?
    setSelectedColumns(
      filterColumns(columnData, selectedKeys, defaultSelectedColumns)
    );
    setTableViewFilter(selectedKeys);
  };

  const tableViewOnChange = (selectedKeys: string[]) => {
    computeTableColumnsWithFilters(selectedKeys, columns);
  };

  const onShipmentSearchUpdate = (params: SearchAndSortParams) => {
    try {
      updateShipmentsSearch({
        body: removeEmptyArrays(shipmentSearch),
        params: {
          ...params,
          keyword: searchTerm,
          sortBy: paginationAndSortingState.sortBy,
          sortOrder: paginationAndSortingState.sortOrder,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      displayErrors(e);
    }
  };

  useEffect(() => {
    onShipmentSearchUpdate(defaultPaginationAndSorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentSearch, searchTerm]);

  useEffect(() => {
    if (updatedShipmentData) {
      onShipmentSearchUpdate(paginationAndSortingState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationAndSortingState]);

  useEffect(() => {
    // NOTE : dynamicColumns is added as dependency as we need to reset columns once fetching tags is done
    if (isSettingRetrieved && tableSettingData) {
      if (tableSettingData.length === 0) {
        computeTableColumnsWithFilters(defaultSelectedColumns, columns);
      } else {
        computeTableColumnsWithFilters(tableSettingData, columns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSettingData, isSettingRetrieved, dynamicColumns]);

  useEffect(() => {
    if (user) {
      shipmentListViewEvent(
        {
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          company_id: user?.companyId,
        },
        { campaign }
      );
    }
  }, [user, campaign]);

  const handleInputOnChange = (value: string) => {
    if (!value) {
      setSearchTerm(undefined);
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    if (value) setSearchTerm(value);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IShipmentEntity> | SorterResult<IShipmentEntity>[]
  ) => {
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    setPaginationAndSortingState({
      page: pagination.current ?? 1,
      limit: pagination.pageSize ?? 10,
      sortBy: singleSorter.field?.toString(),
      sortOrder: singleSorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onFilterChange = (value: string[], name: string) => {
    if (!value || value.length === 0) {
      // If value is empty, create a new object without the key
      const newFilters = { ...shipmentSearch };
      delete newFilters[name];
      setShipmentSearch(newFilters);
    } else {
      setShipmentSearch({ ...shipmentSearch, [name]: value });
    }
  };

  const onFilterReset = () => {
    setShipmentSearch({
      destinations: [],
      origins: [],
      date: [],
      statuses: [],
      modalities: [],
      shipmentType: [],
    });
  };

  const onFilterDrawerChange = (value) => {
    setShipmentSearch(value);
  };

  const onOpenTableView = () => {
    setIsTableViewOpen(true);
  };
  const onCloseTableView = () => {
    setIsTableViewOpen(false);
  };

  return (
    <>
      <div className="filter-wrapper">
        <ShipmentFilters
          selectedFilters={shipmentSearch}
          onChange={onFilterChange}
          isBookingAssignment={isBookingAssignment}
          onReset={onFilterReset}
          onDrawerChange={onFilterDrawerChange}
        />
        {tableSettingData && (
          <Space>
            <Search
              placeholder="search"
              allowClear
              onChange={(event) => handleInputOnChange(event.target.value)}
              onSearch={handleOnSearch}
            />
            {!isBookingAssignment && (
              <>
                <Button
                  icon={<DownloadOutlined />}
                  disabled={!selectedShipments.length}
                  type="primary"
                  onClick={onDownloadExcelFile}
                  loading={isDownloading}
                >
                  Download Excel File
                </Button>
                <FilterDropdown
                  onOpenTableView={onOpenTableView}
                  onCloseTableView={onCloseTableView}
                  isTableViewOpen={isTableViewOpen}
                  filters={{ tableView: tableViewFilter }}
                  renderFilterComponent={
                    <TableViewFilter
                      onCloseTableView={onCloseTableView}
                      layout="vertical"
                      defaultValue={tableViewFilter}
                      tableName={UserTablesEnum.shipmentsTable}
                      columns={excludeEmptyKeyColumns(columns)}
                      onChange={tableViewOnChange}
                    />
                  }
                  buttonText="Table Adjust"
                  itemKey="tableView"
                />
              </>
            )}
          </Space>
        )}
      </div>

      <Table
        loading={isLoading}
        className="table-wrapper"
        rowKey={(record: IShipmentEntity) => record?.id}
        columns={isBookingAssignment ? columns : selectedColumns}
        dataSource={updatedShipmentData?.items}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: paginationAndSortingState.limit,
          pageSizeOptions: ['5', '10', '50'],
          total: updatedShipmentData?.meta.totalItems ?? 0,
          current: updatedShipmentData?.meta.currentPage ?? 1,
        }}
        onChange={handleTableChange}
        onRow={onRow}
        scroll={{ x: 'max-content' }}
        rowSelection={rowSelection}
      />
      {shipmentInPreview && onShipmentOverview && (
        <ShipmentPreview
          shipmentSlug={shipmentInPreview}
          onClose={onShipmentPreviewClose}
          onNavigateToShipment={onShipmentOverview}
        />
      )}
    </>
  );
};

export default ShipmentsTable;
