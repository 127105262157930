import { useLazyGetNotificationsQuery } from 'api/notification';
import { useEffect } from 'react';
import { INotificationRequest } from 'types/entities/notification.entity';
import { IUserEntity } from 'types/entities/user.entity';

import { displayErrors } from 'utils/error-notification';

import { pusherClient } from './pusher.utils';

export const usePusherChannel = (user: IUserEntity | undefined) => {
  const [execute] = useLazyGetNotificationsQuery();
  const getNotifcationList = async (params: INotificationRequest) => {
    try {
      await execute(params).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to fetch data' });
    }
  };
  useEffect(() => {
    if (pusherClient) {
      pusherClient.signin();

      pusherClient.user.bind('notification', function (data: any) {
        getNotifcationList({
          page: 1,
          limit: 15,
          read: true,
        });
      });
    }
  }, []);
};
