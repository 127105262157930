import { useChecklistItem } from '@dopt/react-checklist';
import { FormInstance, notification } from 'antd';
import { ApiResponse } from 'api/common/response.type';
import { useInviteNewForwardersMutation } from 'api/forwarders';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IUserEntity } from 'types/entities/user.entity';
import { THandleSelectAcompany } from 'types/feature/create-shipment.types';
import { IForwrdersFormProps } from 'types/feature/forwarders.types';

import { inviteForwarderEvent } from 'utils/analytics-events';

export const UseForwarderForm = (
  form: FormInstance<IForwrdersFormProps>,
  shipmentId: string
) => {
  const rfqChecklistItem = useChecklistItem(
    'announcement.create_shipment_screen'
  );
  // queries
  const [
    addForwarder,
    {
      isLoading: addForwarderLoading,
      isError,
      error,
      isSuccess: addForwarderSuccess,
    },
  ] = useInviteNewForwardersMutation();
  const user = useSelector((state: RootState) => state.user.user);

  // methods
  const handleSelectAcompany: THandleSelectAcompany = (name) => (value, id) => {
    if (value && id) {
      form.setFieldValue(['forwareders', ...name], value);
      form.setFieldValue(['forwareders', name[0], 'companyId'], id);
    }
  };

  const onInviteForwarder = async () => {
    try {
      const values = await form.validateFields();
      if (shipmentId) {
        const invites: Partial<IUserEntity>[] = values?.forwareders.map(
          (user: IUserEntity) => {
            const { fullname, email, companyId } = user;
            return {
              fullname,
              email,
              companyId,
            };
          }
        );

        addForwarder({ forwarder: invites, shipmentId });

        if (!rfqChecklistItem.done) {
          rfqChecklistItem.complete();
        }

        inviteForwarderEvent({
          user_id: user?.id,
          email: user?.email,
          company: user?.company?.name || '',
          shipment_id: shipmentId,
          company_id: user?.companyId,
        });
      }

      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Unable to invite new forwarder, Try again !',
      });
    }
  };

  useEffect(() => {
    if (isError && error) {
      if ('data' in error) {
        // TODO make an utility for error handling
        const response = error.data as ApiResponse<IUserEntity>;
        notification.error({
          message: 'Errors while inviting forwarders',
          description: response.errors ? (
            <ul>
              {response.errors?.map((error) => {
                return <li key={error.message}>{error.message}</li>;
              })}
            </ul>
          ) : (
            response.message
          ),
        });
      } else {
        notification.warning({
          message: 'Error',
          description: 'An unexpected error has occured , please try again !',
        });
      }
    }
  }, [isError, error]);

  return {
    onInviteForwarder,
    handleSelectAcompany,
    addForwarderSuccess,
    addForwarderLoading,
  };
};
