import { CurrencyEnum } from 'types/entities/fee.entity';
import { IInvoiceItemEntity } from 'types/entities/invoice.entity';
import {
  IComputeMoneyDef,
  InvoiceTableViewEnum,
} from 'types/feature/invoice.types';

export const computeCellBorder = (actualValue, expectedValue): string =>
  parseFloat(actualValue.toString()) !== parseFloat(expectedValue.toString())
    ? 'cell-red-border'
    : '';

export const computeEmptyCellBorder = (value): string =>
  value ? '' : 'cell-red-border';

export const computeEmptyCellNumberBorder = (value): string =>
  value === null || value === undefined ? 'cell-red-border' : '';

export const computeGrandTotal = (
  invoiceItems: readonly IInvoiceItemEntity[]
) => {
  const { invoiceTotal, proposalTotal } = invoiceItems.reduce(
    (totals, item) => {
      const invoiceTotal =
        parseFloat(totals?.invoiceTotal?.toString() || '0') +
        parseFloat(item?.parsedTotalCost?.toString() || '0');

      const proposalTotal =
        parseFloat(totals?.proposalTotal?.toString() || '0') +
        parseFloat(item?.totalCost?.toString() || '0');

      return {
        invoiceTotal,
        proposalTotal,
      };
    },
    {
      invoiceTotal: 0,
      proposalTotal: 0,
    }
  );

  return {
    invoiceTotal: parseFloat(invoiceTotal.toFixed(2)),
    proposalTotal: parseFloat(proposalTotal.toFixed(2)),
  };
};

export const formatMoney = (
  value: number | null,
  currency: CurrencyEnum | null
): string => {
  return `${value?.toLocaleString() || '0'} ${currency?.toUpperCase() || ''}`;
};

export const computeMoney: IComputeMoneyDef = (
  cost,
  convertedCost,
  invoiceCurrency,
  itemCurrency
) => {
  return {
    money: formatMoney(cost, invoiceCurrency),
    // Show convertedMoney sub-text, if item currency !== invoice currency and if there is AI parsed convertedCost.
    convertedMoney:
      itemCurrency === invoiceCurrency && convertedCost !== null
        ? ''
        : formatMoney(convertedCost, itemCurrency),
  };
};

export const isProposalMatching = (view: InvoiceTableViewEnum) =>
  view === InvoiceTableViewEnum.PROPOSAL_MATCHING;

export const isInvoicelMatching = (view: InvoiceTableViewEnum) =>
  view === InvoiceTableViewEnum.INVOICE_MATCHING;

export const isSaveShare = (view: InvoiceTableViewEnum) =>
  view === InvoiceTableViewEnum.SAVE_SAHRE;

export const isRandomId = (id: string) => id.indexOf('$random$') !== -1;

export const canRenderInvoiceComparisonIcon = (
  tableView: InvoiceTableViewEnum,
  proposalItemCurrency: CurrencyEnum | null,
  invoiceItemCurrency: CurrencyEnum | null
) =>
  isProposalMatching(tableView) && proposalItemCurrency === invoiceItemCurrency;
