import { Typography, List } from 'antd';
import React from 'react';
import { ExtraProps } from 'react-markdown';

const { Title, Text } = Typography;

export const FAQ_CONST = [
  'How many shipment do I have “In Transit”?',
  'How to request quote from forwarders?',
  'How can I track my shipments?',
  'How can I add my colleagues?',
];

type TMDAntComponents = Partial<{
  [TagName in keyof JSX.IntrinsicElements]:  // Class component:
    | (new (
        props: JSX.IntrinsicElements[TagName] & ExtraProps
      ) => JSX.ElementClass)
    // Function component:
    | ((
        props: JSX.IntrinsicElements[TagName] & ExtraProps
      ) => JSX.Element | string | null | undefined)
    // Tag name:
    | keyof JSX.IntrinsicElements;
}>;

export const customAntComponents: TMDAntComponents = {
  h1: ({ node, ...props }) => <Title level={1}>{props.children}</Title>,
  h2: ({ node, ...props }) => <Title level={2}>{props.children}</Title>,
  h3: ({ node, ...props }) => <Title level={3}>{props.children}</Title>,
  h4: ({ node, ...props }) => <Title level={4}>{props.children}</Title>,
  h5: ({ node, ...props }) => <Title level={5}>{props.children}</Title>,
  h6: ({ node, ...props }) => <Title level={5}>{props.children}</Title>,
  p: ({ node, ...props }) => <Text>{props.children}</Text>,
  ul: ({ node, ...props }) => <List>{props.children}</List>,
  ol: ({ node, ...props }) => <List bordered={true}>{props.children}</List>,
  li: ({ node, ...props }) => <List.Item>{props.children}</List.Item>,
  code: ({ node, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return match ? (
      <code>{String(children).replace(/\n$/, '')}</code>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
  table: ({ node, ...props }) => (
    <div className="table-wrapper">
      <table>{props.children}</table>
    </div>
  ),
};
