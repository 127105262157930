import {
  IBookingPartyEntity,
  ICreateBookinPartyRequestBody,
  IUpdateBookingPartyRequestBody,
} from 'types/entities/booking-party';

import { api } from './base-api';

// example of endpoints injection
export const bookingParties = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBookingParties: build.query<IBookingPartyEntity[], string>({
      query: (shipmentId) => ({ url: `/booking-party/${shipmentId}` }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) =>
        Object.keys(response).map((r) => response[r]),
      providesTags: ['BookingParty'],
    }),

    createBookingParty: build.mutation<
      IBookingPartyEntity,
      Partial<ICreateBookinPartyRequestBody>
    >({
      query: (body) => ({
        url: '/booking-party',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BookingParty'],
    }),

    updateBookingParty: build.mutation<
      IBookingPartyEntity,
      Partial<IUpdateBookingPartyRequestBody>
    >({
      query: (body) => ({
        url: `/booking-party/${body.id}`,
        method: 'PATCH',
        body: body.data,
      }),
      invalidatesTags: ['BookingParty'],
    }),

    deleteBookingParty: build.mutation<IBookingPartyEntity, Partial<string>>({
      query: (id) => ({
        url: `/booking-party/${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: ['BookingParty'],
    }),
  }),
});

export const {
  useGetAllBookingPartiesQuery,
  useCreateBookingPartyMutation,
  useUpdateBookingPartyMutation,
  useDeleteBookingPartyMutation,
} = bookingParties;
