import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useLoginSecureMutation } from 'api/auth';
import { loginWithCreds } from 'api/auth.firebase';
import { isUserWithinTargetTimeZone } from 'api/common/config';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'state/slices/user.slice';
import { AuthTypeEnum, ILoginFormProps } from 'types/feature/auth.types';

import { getRememberMe, saveAuthTokens } from 'utils/auth-storage';
import { displayErrors } from 'utils/error-notification';

import { ReactComponent as BeeboltLinedLogo } from '../../../../assets/appIcons/beebolt-icon.svg';
import SocialAuthButtons from '../SocialAuthButtons';
// style
import './login-form.style.scss';

const { Item } = Form;
const { Text } = Typography;
const LoginForm: FC<ILoginFormProps> = ({
  signup,
  navigateToMainPage,
  onForgotPassword,
}) => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // for china users
  const [secureLogin] = useLoginSecureMutation();

  const dispatch = useDispatch();

  const onSubmit = async () => {
    let values;
    try {
      values = await form.validateFields(); // Validate the form fields
    } catch (errorInfo) {
      return;
    }

    try {
      setIsLoading(true);
      setError(false);

      if (isUserWithinTargetTimeZone()) {
        await secureLogin({ ...values })
          .unwrap()
          .then((res) => {
            const data = res.data;
            saveAuthTokens(
              {
                accessToken: data.tokens.accessToken,
                refreshToken: data.tokens.refreshToken,
                expiresIn: dayjs().add(1, 'hour').unix(),
              },
              values.email
            );
            form.resetFields();
            navigateToMainPage();
          })
          .catch((err) => {
            displayErrors(err, { title: 'Authentication Failed' });
          });
      } else {
        // else firebase auth
        const response = await loginWithCreds(
          values.email,
          values.password,
          true
        );

        if (!response.isSuccess) {
          throw new Error(response.errorMessage, {
            cause: response.errorMessage,
          });
        }

        if (response.additional) {
          const companyId = response.additional['companyId'] as string;
          const userId = response.additional['userId'] as string;

          dispatch(
            setCurrentUser({
              companyId,
              userId,
            })
          );
        }

        form.resetFields();
        navigateToMainPage();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notification.error({
        message: 'Authentication failed',
        description:
          err.message ?? 'Unknown issue has occured, Please try again !',
      });
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // component
  return (
    <div className="auth-form">
      <BeeboltLinedLogo />
      <Typography.Title level={2}>Welcome Back!</Typography.Title>
      {error && (
        <Alert
          message={'Credential not valid, please try again !'}
          type="error"
          showIcon
        />
      )}
      <SocialAuthButtons
        navigateToMainPage={navigateToMainPage}
        mode={AuthTypeEnum.LOGIN}
      />
      <Divider>
        <Text type="secondary">OR</Text>
      </Divider>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ rememberMe: getRememberMe() }}
      >
        <Item
          label="Email"
          name="email"
          key="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email!',
            },
            {
              type: 'email',
              warningOnly: true,
              message: 'Please enter a valid email !',
            },
          ]}
        >
          <Input type="email" />
        </Item>
        <Item
          label="Password"
          name="password"
          key="password"
          rules={[
            {
              required: true,
              message: 'Password is missing!',
            },
          ]}
        >
          <Input.Password type="password" />
        </Item>
        <Row className="auth-form__remember-me">
          <Item key="rememberMe" name="rememberMe" valuePropName="checked">
            <Checkbox> Remember me</Checkbox>
          </Item>
          <Button type="link" onClick={onForgotPassword}>
            Forgot password ?
          </Button>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            className="auth-form__login-btn"
            onClick={onSubmit}
            disabled={isLoading}
            icon={isLoading && <LoadingOutlined />}
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
      <Row align="middle" justify="center">
        <Typography.Text>Don&apos;t have an account ?</Typography.Text>
        <Button type="link" onClick={signup}>
          Sign up
        </Button>
      </Row>
    </div>
  );
};

export default LoginForm;
