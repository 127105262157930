import { Button, Col, Modal, Row, Select, Typography, message } from 'antd';
import {
  useGetCompaniesAccessByFileQuery,
  useShareFileByCompaniesMutation,
  useUnshareFileByCompanyMutation,
} from 'api/file-access';
import ProfileImage from 'app/components/ProfilePicture';
import { useEffect, useState } from 'react';
import React from 'react';
import { ICompanyEntity } from 'types/entities/company.entity';
import { IShipmentShareModalProps } from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as FileIcon } from '../../../../assets/icons/file-icon-outline.svg';
import './share-document-modal.scss';

const { Text } = Typography;

const computeSelectOptions = (comapnies: ICompanyEntity[] | undefined) => {
  return (
    comapnies?.map((company) => ({
      label: company.name,
      value: company.id,
    })) ?? []
  );
};

const ShipmentShareModal: React.FC<IShipmentShareModalProps> = ({
  file,
  isOpen,
  shipmentId,
  onClose,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { data: companiesData, isLoading: isCompaniesLoading } =
    useGetCompaniesAccessByFileQuery({
      shipmentId: shipmentId,
      fileId: file.id,
    });

  const [shareFile, { isLoading: isSharingFile }] =
    useShareFileByCompaniesMutation();

  const [removeFileAccess, { isLoading: isRemovingFileAccess }] =
    useUnshareFileByCompanyMutation();

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const onShareFile = () => {
    try {
      shareFile({
        shipmentId: shipmentId,
        fileId: file.id,
        companyIds: selectedItems,
      });
      setSelectedItems([]);
      message.success('The file shared successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error while sharing file' });
    }
  };

  const onRemoveClick = (companyId: string) => {
    try {
      removeFileAccess({
        shipmentId: shipmentId,
        fileId: file.id,
        companyId,
      });
      setSelectedItems([]);
      message.success('The file access removed successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error while removing file access' });
    }
  };

  const handleChange = (value: string) => {
    onRemoveClick(value);
  };

  return (
    <>
      <Modal
        title="Share Document"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="share-document-modal-container">
          <Row gutter={16}>
            <Col span={20}>
              <Select
                loading={isCompaniesLoading || isSharingFile}
                mode="multiple"
                placeholder="Inserted are removed"
                value={selectedItems}
                onChange={setSelectedItems}
                style={{ width: '100%' }}
                options={computeSelectOptions(companiesData)}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" block onClick={onShareFile}>
                Share
              </Button>
            </Col>
          </Row>
          <div className="file-access-preview-wrapper">
            <FileIcon className="file-access-icon" />
            <div className="file-access-preview-content">
              <Text strong>{file.name}</Text>
              <Text>{file.type}</Text>
            </div>
          </div>
          <div>
            {file.companies.map((company) => {
              return (
                <Row
                  gutter={16}
                  key={company.id}
                  align="middle"
                  style={{ marginBottom: '15px' }}
                >
                  <Col span={3}>
                    <ProfileImage name={company.name} />
                  </Col>
                  <Col span={15}>
                    <Text>{company.name}</Text>
                  </Col>
                  <Col span={6}>
                    {company.canBeRemoved && (
                      <Select
                        loading={isRemovingFileAccess}
                        defaultValue="can-view"
                        className="file-access-select"
                        onChange={handleChange}
                        options={[
                          { label: 'Can View', value: 'can-view' },
                          { label: 'Remove', value: company.id },
                        ]}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShipmentShareModal;
