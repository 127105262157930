import { Button, Select, Space, Typography, notification } from 'antd';
import {
  useGetCollaboratorByShipmentQuery,
  useInviteForwarderExistingColleaguesMutation,
} from 'api/collaborators';
import { useGetForwarderColleaguesQuery } from 'api/forwarders';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import AddForwarderColleagueModal from 'app/components/Connections/AddForwarderColleagueModal';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IUserBaseEntity } from 'types/entities/user.entity';
import {
  AcceptedProposalStoryScreenEnum,
  AddForwarderColleageOnboardingScreenProps,
} from 'types/feature/user-onboarding';

import { addForwarderColleagueEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import TargetIllustration from '../../../../assets/target-illustration.png';
import '../userOnboarding.scss';
import './add-forwarder-colleage-modal.scss';

const computeSelectOptions = (forwarders: IUserBaseEntity[]) =>
  forwarders?.map((user) => ({
    label: user.fullname,
    value: user.id,
    key: user.id,
  }));

const selectOptionFilter = (input, option) =>
  ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());

const AddForwarderColleageOnboardingScreen: FC<
  AddForwarderColleageOnboardingScreenProps
> = ({ shipmentId, company, onScreenReset, onScreenChange }) => {
  const { slug = '' } = useParams();
  const [selectedColleagues, setSelectedColleagues] = useState<string[]>([]);
  const { data: forwarderColleagueData, isSuccess } =
    useGetForwarderColleaguesQuery({
      shipmentId,
      companyId: company.id,
    });
  const userData = useSelector((state: RootState) => state.user.user);

  const [
    inviteForwarderExistingColleague,
    { isLoading: isInviteForwarderLoading },
  ] = useInviteForwarderExistingColleaguesMutation();

  const { refetch: refetchShipment } = useGetShipmentsBySlugQuery(slug);
  const { refetch: refetchShipmentCollaborator } =
    useGetCollaboratorByShipmentQuery(shipmentId);

  const showBookingPartyScreen = () =>
    onScreenChange(AcceptedProposalStoryScreenEnum.BOOKING_PARTY_SCREEN);

  const onInviteForwardersClick = () => {
    if (selectedColleagues) {
      inviteForwarderExistingColleague({
        shipmentId,
        userIds: selectedColleagues,
      })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Invitation sent to forwarders!' });
          showBookingPartyScreen();
          refetchShipment();
          refetchShipmentCollaborator();

          addForwarderColleagueEvent({
            user_id: userData?.id,
            email: userData?.email,
            company: userData?.company.name,
            collaborator_user_id: selectedColleagues,
            company_id: userData?.companyId,
            shipment_id: shipmentId,
          });
        })
        .catch((error) => {
          displayErrors(error, { title: 'Error' });
        });
    }
  };

  const onSelectChange = (value: string[]) => {
    setSelectedColleagues(value);
  };
  const onAddNewUser = (id: string) => {
    setSelectedColleagues([...selectedColleagues, id]);
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedColleagues(forwarderColleagueData.map((user) => user.id));
    }
  }, [isSuccess, forwarderColleagueData]);
  return (
    <div className="add-ff-colleague-wrapper">
      <img
        alt="target-illustration"
        className="target-illustration"
        src={TargetIllustration}
      />

      {/* TODO: Move to design system */}
      <div className="user-story-time-line-wrapper">
        <div className="time-line-label">Step 1 of 2</div>
        <div className="time-line-items-wrapper">
          <div className="story-item active"></div>
          <div className="story-item"></div>
        </div>
      </div>
      <Typography.Title level={3}>Add Forwarders Colleagues</Typography.Title>
      <Typography.Paragraph className="add-ff-colleague-content">
        Add forwarders colleagues as collaborators to the shipment
      </Typography.Paragraph>

      <Space>
        {forwarderColleagueData && (
          <Select
            className="forwarder-select"
            mode="multiple"
            value={selectedColleagues}
            allowClear
            showSearch
            placeholder={'Select Forwarder'}
            loading={false}
            filterOption={selectOptionFilter}
            options={computeSelectOptions(forwarderColleagueData)}
            dropdownRender={(menu) => {
              return (
                <>
                  <AddForwarderColleagueModal
                    company={company}
                    shipmentId={shipmentId}
                    onAddNewUser={onAddNewUser}
                  />
                  {menu}
                </>
              );
            }}
            {...(onSelectChange && { onChange: onSelectChange })}
          />
        )}
      </Space>

      <Space direction="vertical">
        <Button
          block
          type="primary"
          onClick={onInviteForwardersClick}
          disabled={isInviteForwarderLoading || !selectedColleagues.length}
        >
          Invite Forwarder(s)
        </Button>
        <Button type="link" block onClick={showBookingPartyScreen}>
          Skip
        </Button>
      </Space>
    </div>
  );
};

export default AddForwarderColleageOnboardingScreen;
