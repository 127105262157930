import { mapValuesToLocation } from 'utils/location';

/**
 * Map Form values to IMatchContractRatesReq
 * @param {Record<string, any>} values - Input values from form
 * @return {IMatchContractRatesReq} formatted request body
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapSearchParams = (values: Record<string, any>) => {
  const {
    originFull,
    destinationFull,
    serviceMode,
    containerType,
    shipmentType,
    shipmentTypeId,
    cargoReadyDate,
    quantity,
    weight,
    volume,
  } = values;
  const modes = serviceMode.split('/');
  return {
    containerType,
    shipmentType,
    shipmentTypeId,
    cargoReadyDate,
    destination: mapValuesToLocation(
      null,
      destinationFull,
      modes?.length ? modes[1] : undefined
    ),
    origin: mapValuesToLocation(
      null,
      originFull,
      modes?.length ? modes[0] : undefined
    ),
    quantity,

    volume,
    weight,
  };
};
