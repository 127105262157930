import { ICompanyEntity } from 'types/entities/company.entity';
import {
  IForwadersInviteRecentRequest,
  IForwadersInviteRequest,
  IForwadersInviteResponse,
  IForwarderEntity,
} from 'types/entities/forwarder.entity';
import {
  IProposalCollaboratorCheckParams,
  IPropsalColleaguesParams,
} from 'types/entities/proposal.entity';
import { IUserBaseEntity, IUserEntity } from 'types/entities/user.entity';

import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const forwardersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getForwarders: build.query<
      IForwarderEntity[],
      { shipmentId?: string; search?: string }
    >({
      query: ({ shipmentId, search }) => ({
        url: `/forwarders`,
        params: { shipmentId, search },
      }),
      transformResponse: (resp: ApiResponse<IEntityList<IForwarderEntity>>) =>
        resp.data.items,
      providesTags: ['Forwarders'],
    }),
    getForwarderById: build.query<ApiResponse<IUserEntity>, string>({
      query: (id) => `/forwarders/${id}`,
    }),
    inviteNewForwarders: build.mutation<
      ApiResponse<string>,
      IForwadersInviteRequest
    >({
      query: (payload) => ({
        url: `/forwarders/invite/${payload.shipmentId}`,
        method: 'POST',
        body: payload.forwarder,
      }),
      invalidatesTags: ['Forwarders', 'ForwardersByProposals', 'Collaborators'],
    }),
    inviteForwarders: build.mutation<
      IForwadersInviteResponse,
      IForwadersInviteRecentRequest
    >({
      query: ({ body, shipmentId }) => {
        return {
          url: `/forwarders/invite/recent/${shipmentId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Forwarders', 'ForwardersByProposals', 'Collaborators'],
    }),
    getForwarderColleagues: build.query<
      IUserBaseEntity[],
      IPropsalColleaguesParams
    >({
      query: (params) => ({
        url: '/forwarders/colleagues',
        params,
      }),
    }),
    checkForwarderPerShipment: build.query<
      { message: string },
      IProposalCollaboratorCheckParams
    >({
      query: ({ shipmentId, forwarderId }) => ({
        url: `/forwarders/check-proposals/${shipmentId}/${forwarderId}`,
      }),
      transformResponse: (resp: ApiResponse<{ message: string }>) => resp.data,
    }),
    getForwardersByShipmentProposals: build.query<
      ICompanyEntity[],
      { shipmentId: string }
    >({
      query: ({ shipmentId }) => ({
        url: `/forwarders/shipment/${shipmentId}`,
      }),
      transformResponse: (resp: ApiResponse<IEntityList<ICompanyEntity>>) =>
        resp.data.items,
      providesTags: ['ForwardersByProposals'],
    }),
  }),
});

export const {
  useGetForwardersQuery,
  useGetForwarderByIdQuery,
  useInviteNewForwardersMutation,
  useInviteForwardersMutation,
  useGetForwarderColleaguesQuery,
  useGetForwardersByShipmentProposalsQuery,
  useCheckForwarderPerShipmentQuery,
  useLazyCheckForwarderPerShipmentQuery,
} = forwardersApi;
