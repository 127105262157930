import { IPlaceData } from 'types/entities/location.entity';
import { ILocation } from 'types/entities/shipment.entity';

/**
 * Create a Location object from the place and gps coordinates
 * @param {IPlaceData} place - Place details returned from places API
 * @return {ILocation} - Location object ( origin , destination ... )
 */

export const buildLocation = (
  place: IPlaceData | google.maps.places.PlaceResult | null,
  gpsCoordinates: { latitude?: number; longitude?: number }
): ILocation => {
  const countryInfo = place?.address_components?.find(
    (adr: google.maps.GeocoderAddressComponent) => adr.types.includes('country')
  );
  const city = place?.address_components?.find(
    (adr: google.maps.GeocoderAddressComponent) =>
      adr.types.includes('locality')
  );

  return {
    country: countryInfo?.long_name || '',
    code: countryInfo?.short_name || '',
    city: city?.long_name || place?.formatted_address || '',
    ...gpsCoordinates,
  } as ILocation;
};
