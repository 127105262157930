import { Alert, Button, Modal, Typography } from 'antd';
import BiddingCounter from 'app/components/Bidding/BiddingCounter';
import React, { FC, useEffect, useState } from 'react';
import { ICreateProposalQouteModalProps } from 'types/feature/multiple-proposal';

import { formatRankNumber } from 'utils/rank.utils';

import ProposalNotificationAction from '../NotificationAction';
import './create-new-quote-modal.scss';

const { Title, Text, Paragraph } = Typography;

const CreateNewProposalModal: FC<ICreateProposalQouteModalProps> = ({
  proposal,
  proposalParams,
  isProposalNotifEnabled,
  onProposalNotifChange,
  onGoToDashboard,
  onClose,
  onEditProposal,
  isOpen,
}) => {
  const { bigDeadline, bidRank, shipmentReference, showBiddingRank } = proposal;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width="700px"
      >
        <div className="new-quote-modal-content-wrapper padding-fix ">
          <div className="new-quote-modal-header">
            <Title level={3} className="m-0">
              Congratulations <span className="party-icon">&#x1f389;</span>
            </Title>
            <div className="bidding-counter-wrapper">
              {bigDeadline && (
                <BiddingCounter
                  deadline={bigDeadline}
                  showDropDownActions={false}
                />
              )}
            </div>
          </div>

          <Paragraph className="content-box">
            Your quotation for shipment {shipmentReference ?? ''} has
            successfully submitted.
          </Paragraph>

          <div className="modal-footer">
            <Text strong className="bidding-text-wrapper">
              {showBiddingRank && bidRank > 0 && (
                <>
                  Your Rank Level:{' '}
                  <Text className="bidding-text" color="#0062FF">
                    {formatRankNumber(bidRank)}
                  </Text>
                </>
              )}
            </Text>

            <div className="modal-actions">
              <ProposalNotificationAction
                proposalParams={proposalParams}
                onProposalNotifChange={onProposalNotifChange}
                isProposalNotifEnabled={isProposalNotifEnabled}
              />
              <Button className="back-btn" onClick={onGoToDashboard}>
                Go to Dashboard
              </Button>
              <Button type="primary" onClick={onEditProposal}>
                Edit Proposal
              </Button>
            </div>
          </div>

          {showBiddingRank && (
            <Alert
              description="Please note that the rank group is only based on the total amount. Cargo Owner will also take into account other parameters such as transit time, carrier, and sailing schedule to decide and award the shipment."
              type="info"
              banner
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateNewProposalModal;
