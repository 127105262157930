import { Space, Checkbox, Button, Empty } from 'antd';
import { useGetCarriersOfShipmentQuery } from 'api/carrier';
import { useGetForwardersByShipmentProposalsQuery } from 'api/forwarders';
import FilterDropdown from 'app/components/ShipmentFilters/FilterDropdown';
import React, { FC } from 'react';
import { ICarrierEntity } from 'types/entities/carrier.entity';
import { ICompanyEntity } from 'types/entities/company.entity';
import { IProposalFilters } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';

import { splitState } from 'utils/format-fields';
import { checkObjectNotEmpty } from 'utils/object.helpers';

interface IProposalFiltersProps {
  proposalSearch: IProposalFilters;
  shipmentId: string;
  onStatusFilterChange: (value: string[], name: string) => void;
  onResetClick: () => void;
}
export const ProposalFilters: FC<IProposalFiltersProps> = ({
  proposalSearch,
  shipmentId,
  onStatusFilterChange,
  onResetClick,
}) => {
  const { data: carriers, isLoading: isCarrierLoading } =
    useGetCarriersOfShipmentQuery({
      shipmentId,
    });

  const { data: forwarders } = useGetForwardersByShipmentProposalsQuery({
    shipmentId,
  });

  return (
    <Space>
      <FilterDropdown
        filters={proposalSearch}
        renderFilterComponent={
          <Checkbox.Group
            defaultValue={[]}
            onChange={(values) =>
              onStatusFilterChange(values as string[], 'status')
            }
          >
            <div className={`filter-input-wrapper`}>
              {Object.values(IQuoteStatus).map(
                (status: IQuoteStatus, index: number) => (
                  <div className="input-space-wrapper" key={status}>
                    <Checkbox value={status}>{splitState(status)}</Checkbox>
                  </div>
                )
              )}
            </div>
          </Checkbox.Group>
        }
        buttonText="Status"
        itemKey="status"
      />
      <FilterDropdown
        filters={proposalSearch}
        renderFilterComponent={
          carriers?.length ? (
            <Checkbox.Group
              defaultValue={[]}
              onChange={(values) =>
                onStatusFilterChange(values as string[], 'carriers')
              }
            >
              <div className={`filter-input-wrapper`}>
                {carriers?.map((carrier: ICarrierEntity, index: number) => (
                  <div className="input-space-wrapper" key={carrier.id}>
                    <Checkbox value={carrier.id}> {carrier.name}</Checkbox>
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          ) : (
            <Empty description="No carriers infos available from quotes!" />
          )
        }
        buttonText="Carriers"
        itemKey="carriers"
        isLoading={isCarrierLoading}
      />
      <FilterDropdown
        filters={proposalSearch}
        renderFilterComponent={
          forwarders?.length ? (
            <Checkbox.Group
              defaultValue={[]}
              onChange={(values) =>
                onStatusFilterChange(values as string[], 'forwarders')
              }
            >
              <div className={`filter-input-wrapper`}>
                {forwarders?.map((company: ICompanyEntity, index: number) => (
                  <div className="input-space-wrapper" key={company.id}>
                    <Checkbox value={company.id}>{company.name}</Checkbox>
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          ) : (
            <Empty description="No forwarders available, try to invite some !" />
          )
        }
        buttonText="Forwarders"
        itemKey="forwarders"
      />

      {checkObjectNotEmpty(proposalSearch as Record<string, string[]>) && (
        <Button type="link" block onClick={onResetClick}>
          Clear All
        </Button>
      )}
    </Space>
  );
};
