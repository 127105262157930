import { EditFilled, DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useDeleteUserMutation, useGetUserConnectionsQuery } from 'api/user';
import AddConnectionModal from 'app/components/Connections/AddConnectionModal';
import EditConnectionModal from 'app/components/Connections/EditConnectionModal';
import React, { FC, useState } from 'react';
import { IUserConnectionEntity } from 'types/entities/company.entity';

import { displayErrors } from 'utils/error-notification';

import { columns } from './connections.const';
import './style.scss';

const { confirm } = Modal;
const { Search } = Input;
const Connections: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<{
    open: boolean;
    record?: IUserConnectionEntity;
  }>({ open: false });
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    undefined
  );

  // queries

  const { data: users, isLoading } = useGetUserConnectionsQuery({
    keyword: searchedValue,
  });
  const [deleteOneUser, { isLoading: isDeleting }] = useDeleteUserMutation();

  // methods
  const handleEmptySearch = (event) => {
    if (!event.target.value.length) {
      setSearchedValue(undefined);
    }
  };

  const onEdit = (record: IUserConnectionEntity) =>
    setIsModalOpen({ open: true, record });
  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content:
        'Are you sure you want to delete this user from your connections?',
      onOk: async () => {
        await deleteOneUser(id)
          .unwrap()
          .then(() => {
            notification.success({
              message: 'User Deleted',
              description: `The selected user has been deleted successfully ! `,
            });
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete User Error' });
          });
      },
    });
  };

  const actionColumn: ColumnsType<IUserConnectionEntity> = [
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'action',
      render: (_, record: IUserConnectionEntity) => {
        return (
          <Space>
            <Button icon={<EditFilled />} onClick={() => onEdit(record)} />

            <Button
              icon={<DeleteFilled />}
              danger
              disabled={isDeleting}
              onClick={() => onDeleteClick(record.partner.id)}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <Row justify="space-between" align="top" className="action-bar-wrapper">
        <Typography.Title level={3} style={{ margin: 'unset' }}>
          Connections
        </Typography.Title>
        <Space>
          <Search
            placeholder="search"
            onSearch={(value: string) => setSearchedValue(value)}
            onChange={handleEmptySearch}
            allowClear
          />
          <AddConnectionModal
            btnProps={{ type: 'primary', text: 'Add External Connection' }}
          />
        </Space>
      </Row>
      <Table
        dataSource={users}
        loading={isLoading}
        columns={[...columns, ...actionColumn]}
        rowKey={(user: IUserConnectionEntity) => user.id}
        pagination={false}
      />

      {isModalOpen.record && (
        <EditConnectionModal
          isModalOpen={isModalOpen.open}
          closeModal={() => setIsModalOpen({ open: false, record: undefined })}
          userToEdit={isModalOpen.record}
        />
      )}
    </>
  );
};

export default Connections;
