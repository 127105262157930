import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ProfileImage from 'app/components/ProfilePicture';
import React from 'react';
import { IPurchaseOrderCollaborators } from 'types/feature/purchase-orders.types';

export const purchaseOrderColumnsConst: ColumnsType<IPurchaseOrderCollaborators> =
  [
    {
      key: 'avatar',
      render: (value, record, index) => {
        return <ProfileImage name={record.fullname} photoUrl={record.photo} />;
      },
    },
    {
      key: 'name',
      render: (value, record, index) => {
        return <Typography.Text>{record.fullname}</Typography.Text>;
      },
    },
  ];
