import { Badge, Card, Col, Row, Space } from 'antd';
import { messagesApi, useLazyGetAllShipmentsMessagesQuery } from 'api/messages';
import AppTabs from 'app/design-system/AppTabs';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IGetMessages,
  IShipmentsMessageEntity,
  MessagesTabKeyEnum,
} from 'types/entities/message.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IBaseAnalyticsEvents } from 'types/feature/analytics-events.type';

import {
  commentsTabClickEvent,
  messagesTabClickEvent,
} from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';
import { isOtherUser } from 'utils/shipment-helper';

import { ReactComponent as ProposalCommentsIcon } from '../../../assets/icons/proposal-comments-icon.svg';
import { ReactComponent as ShipmentMessagesIcon } from '../../../assets/icons/shipments-messages-icon.svg';
import ShipmentMessages from '../ShipmentMessages';
import EmptyMessages from './EmptyMessages';
import MembersList from './MembersList';
import MessagesHeader from './MessagesHeader';
import ProposalComments from './ProposalComments';
import ProposalsList from './ProposalsList';
import ShipmentMessagesList from './ShipmentMessagesList';

const Messages: FC = () => {
  const [tabKey, setTabKey] = useState<MessagesTabKeyEnum>(
    MessagesTabKeyEnum.SHIPMENT_MESSAGES
  );
  const [selectedMessage, setSelectedMessage] =
    useState<IShipmentsMessageEntity>();
  const [selectedProposal, setSelectedProposal] = useState<IProposalEntity>();
  const [
    fetchMessages,
    { data: shipmentsMessages, isLoading, isFetching, isError, error },
  ] = useLazyGetAllShipmentsMessagesQuery({});
  const [initialResponseReceived, setInitialResponseReceived] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [unreadCommentsCount, setUnreadCommentsCount] = useState(0);
  const user = useSelector((state: RootState) => state.user.user);
  const changeDetails: IBaseAnalyticsEvents = {
    user_id: user?.id || '',
    email: user?.email || '',
    company: user?.company?.name || '',
    company_id: user?.companyId || '',
  };

  const dispatch = useDispatch();

  const getAllMessages = async (params: IGetMessages) => {
    return fetchMessages(params);
  };

  const switchToShipmentMessagesTab = () => {
    setTabKey(MessagesTabKeyEnum.SHIPMENT_MESSAGES);
  };
  const handleShowMembers = () => {
    switchToShipmentMessagesTab();
    setShowMembers(true);
  };
  const handleHideMembers = () => {
    setShowMembers(false);
  };
  useEffect(() => {
    switchToShipmentMessagesTab();
  }, [selectedMessage]);

  useEffect(() => {
    if (
      shipmentsMessages &&
      shipmentsMessages.items.length > 0 &&
      !initialResponseReceived
    ) {
      //when the first response is received if user has messages open first message
      setSelectedMessage(shipmentsMessages.items[0]);
      setInitialResponseReceived(true);
    }
  }, [shipmentsMessages, initialResponseReceived]);

  useEffect(() => {
    if (selectedMessage && shipmentsMessages) {
      const updatedMessage = shipmentsMessages.items.find(
        (message) => message.id === selectedMessage.id
      );
      setUnreadCommentsCount(updatedMessage?.unreadCommentCount || 0);
    }
  }, [selectedMessage, selectedProposal, shipmentsMessages]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error fetching messages' });
    }
  }, [isError, error]);

  return (
    <DashboardCard
      headerTitle="Messages"
      subTitle="View all shipment messages here"
      noMargin
      fitContent
      isMessages
    >
      <Row>
        <Col span={6}>
          <ShipmentMessagesList
            shipmentsMessages={shipmentsMessages?.items}
            isLoading={isLoading}
            isFetching={isFetching}
            getAllMessages={getAllMessages}
            setSelectedMessage={setSelectedMessage}
            selectedMessage={selectedMessage}
          />
        </Col>
        <Col
          span={
            showMembers || tabKey === MessagesTabKeyEnum.PROPOSAL_COMMENTS
              ? 14
              : 18
          }
        >
          {selectedMessage ? (
            <>
              <MessagesHeader
                handleShowMembers={handleShowMembers}
                selectedMessage={selectedMessage}
              />
              <Card bordered={false} className="content-card">
                <AppTabs
                  className="shipment-messages-tabs"
                  centered
                  size="large"
                  defaultActiveKey={tabKey}
                  activeKey={tabKey}
                  onTabClick={(key) => {
                    handleHideMembers();
                    dispatch(
                      messagesApi.util.invalidateTags(['AllShipmentMessages'])
                    );
                    setTabKey(key as MessagesTabKeyEnum);
                    if (key === MessagesTabKeyEnum.SHIPMENT_MESSAGES) {
                      messagesTabClickEvent(changeDetails);
                      setSelectedProposal(undefined);
                    } else {
                      commentsTabClickEvent(changeDetails);
                    }
                  }}
                  items={[
                    {
                      label: (
                        <Space>
                          <ShipmentMessagesIcon />
                          Shipment Messages
                        </Space>
                      ),
                      key: MessagesTabKeyEnum.SHIPMENT_MESSAGES,
                      children: (
                        <ShipmentMessages
                          firstUnreadMessageId={
                            selectedMessage.firstUnreadMessageId
                          }
                          fitContent={true}
                          shipment={selectedMessage}
                        />
                      ),
                    },
                    {
                      label: (
                        <Space>
                          <Badge color="blue" count={unreadCommentsCount}>
                            <ProposalCommentsIcon />
                          </Badge>
                          Proposal Comments
                        </Space>
                      ),
                      key: MessagesTabKeyEnum.PROPOSAL_COMMENTS,
                      disabled:
                        !selectedMessage.hasProposals ||
                        isOtherUser(selectedMessage?.currentUserRole),
                      children: selectedProposal && (
                        <ProposalComments
                          currentUserRole={selectedMessage.currentUserRole}
                          proposalId={selectedProposal.id}
                        />
                      ),
                    },
                  ]}
                />
              </Card>
            </>
          ) : (
            <EmptyMessages />
          )}
        </Col>
        {(showMembers || tabKey === MessagesTabKeyEnum.PROPOSAL_COMMENTS) &&
          selectedMessage && (
            <Col span={4}>
              {showMembers ? (
                <MembersList
                  shipmentId={selectedMessage.id}
                  handleHideMembers={handleHideMembers}
                />
              ) : (
                <ProposalsList
                  switchToShipmentMessagesTab={switchToShipmentMessagesTab}
                  setSelectedProposal={setSelectedProposal}
                  selectedProposal={selectedProposal}
                  shipmentId={selectedMessage.id}
                />
              )}
            </Col>
          )}
      </Row>
    </DashboardCard>
  );
};

export default Messages;
