import { Row, Spin, Typography, notification } from 'antd';
import { purchaseOrdersApi } from 'api/purchase-order';
import {
  useCreatePurchaseOrderCollaboratorMutation,
  useGetPurchaseOrderCollaboratorsByIdQuery,
} from 'api/purchase-order-collaborators';
import {
  useCreatePurchaseOrderCommentMutation,
  useGetPurchaseOrderCommentsByIdQuery,
} from 'api/purchase-order-comments';
import { getMentionPoUserList } from 'app/components/ShipmentMessages/shipment-messages-util';
import CommentBox from 'app/design-system/CommentBox';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ICommentEntity } from 'types/entities/comment.entity';
import { IMentionInput, TMentionUserList } from 'types/feature/messages.type';
import {
  AccessLevelEnum,
  IPurchaseOrderCommentsProps,
} from 'types/feature/purchase-orders.types';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';

const PurchaseOrderComments: FC<IPurchaseOrderCommentsProps> = ({
  purchaseOrderId,
  shipmentId,
  currentUserAccessLevel,
}) => {
  const [commentState, setCommentState] = useState<ICommentEntity[]>([]);
  const [collaborators, setCollaborators] = useState<TMentionUserList | []>([]);

  const dispatch = useDispatch();

  const {
    data: purchaseOrderCommentsData,
    isLoading: fetchingComments,
    refetch: refetchPurchaseOrderComments,
  } = useGetPurchaseOrderCommentsByIdQuery({ purchaseOrderId });

  const [addBookingComment, { isLoading: isAddingComment }] =
    useCreatePurchaseOrderCommentMutation();

  const { data: poCollaboratorsData } =
    useGetPurchaseOrderCollaboratorsByIdQuery({
      purchaseOrderId,
      withCollaborators: true,
    });

  const [
    createCollaborator,
    { isSuccess: isInviteSuccess, isError: isInviteError, error: inviteError },
  ] = useCreatePurchaseOrderCollaboratorMutation();

  useEffect(() => {
    if (poCollaboratorsData) {
      setCollaborators(
        getMentionPoUserList(
          poCollaboratorsData.data.items,
          isViewer(currentUserAccessLevel)
        )
      );
    }
  }, [currentUserAccessLevel, poCollaboratorsData]);

  const onAddComment = async (comment: IMentionInput) => {
    if (!comment) return;
    const collaboratorsToAdd: {
      collaboratorId: string;
      accessLevel: AccessLevelEnum;
    }[] = [];
    const existingCollaborators = poCollaboratorsData?.data.items.filter(
      (collaborator) => collaborator.isCollaborator
    );
    comment.mentions.forEach((mention) => {
      existingCollaborators?.some(
        (collaborator) => collaborator.id === mention
      ) ||
        collaboratorsToAdd.push({
          collaboratorId: mention,
          accessLevel: AccessLevelEnum.EDIT,
        });
    });
    if (collaboratorsToAdd.length > 0 && !isViewer(currentUserAccessLevel)) {
      await createCollaborator({
        purchaseOrderId,
        collaborators: collaboratorsToAdd,
      });
      dispatch(
        purchaseOrdersApi.util.invalidateTags([{ type: 'PurchaseOrders' }])
      );
    }
    try {
      await addBookingComment({
        purchaseOrderId,
        originalText: comment.originalText,
        markupText: comment.markupText,
        mentions: comment.mentions,
      }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Comment Error' });
    }
  };

  useEffect(() => {
    refetchPurchaseOrderComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderId]);

  useEffect(() => {
    setCommentState(purchaseOrderCommentsData?.data?.comments || []);
  }, [purchaseOrderCommentsData]);

  useEffect(() => {
    if (isInviteSuccess) {
      notification.success({ message: 'collaborator added' });
    }
  }, [isInviteSuccess]);

  useEffect(() => {
    if (isInviteError && inviteError) {
      displayErrors(inviteError, { title: 'add collaborator error' });
    }
  }, [inviteError, isInviteError]);

  return (
    <>
      {fetchingComments ? (
        <Row className="py-3" justify="center">
          <Spin />
        </Row>
      ) : (
        <div className="booking-comments-wrapper">
          <Typography.Title level={4}>Comments</Typography.Title>
          {shipmentId}
          <CommentBox
            topSuggestions
            comments={commentState}
            onAddComment={onAddComment}
            isLoading={isAddingComment}
            isExternal={false}
            userList={collaborators}
          />
        </div>
      )}
    </>
  );
};

export default PurchaseOrderComments;
