import { ICarrierEntity } from './carrier.entity';
import { ICompanyEntity } from './company.entity';
import { CurrencyEnum, IFeeEntity } from './fee.entity';
import { IQuoteStatus, ProposalAmendStatusEnum } from './quote.entity';
import {
  ICargoUnit,
  IShipmentEntity,
  IShipmentTypeDef,
} from './shipment.entity';
import { IUserEntity } from './user.entity';

export enum ContainerTypeEnum {
  TWENTY_C = '20_c',
  FOURTY_C = '40_c',
  FOURTY_HC = '40_hc',
  TWNTY_RF = '20_rf',
  FOURTY_RF_HC = '40_rf_hc',
  FOURTY_FIVE_HC = '45_hc',
  FOURTY_EIGHT_DRY_VAN = '48_dry_van',
  FIFTY_THREE_DRY_VAN = '53_dry_van',
  FOURTY_EIGHT_FLATBED = '48_flatbed',
  FIFTY_THREE_FLATBED = '53_flatbed',
  FOURTY_EIGHT_REFRIGERATED = '48_refrigerated',
  FIFTY_THREE_REFRIGERATED = '53_refrigerated',
}
export interface ICreatedByEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  fullname: string;

  mobileNumber: string;
  email: string;
  isActive?: boolean;
  isBlocked?: boolean;
  isProfileCompleted?: boolean;
  blockedDate?: string;
  photo: string;
  companyId: string;
}

export interface IProposalCommentEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  comment: string;
  proposalId: string;
  createdBy: ICreatedByEntity;
  proposalCommentViews: string[];
}

export interface IFileEntity {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  file: string;
  name: string;
  type: string;
  shipmentId: string;
  proposalId: string;
  owner?: boolean;
}

export interface IBaseProposalEntity {
  id: string;
  freeDays: number;
  paymentTerms: number;
  proposalCost: number;
  transitTime: number;
  shipmentId: string;
  carrierId: string | null;
  shipmentTypeId: string | null;
  eta: string | null;
  etd: string | null;
  vesselName: string;
  routing: string;
  totalFees: string | null;
  proposalItems: IFeeEntity[];
  proposalShipmentItems: ICargoUnit[];
  proposalShipmentItemsFormatted: string;
  shipmentType: IShipmentTypeDef;
}

export interface IProposalEntity extends IBaseProposalEntity {
  freeDays: number;
  currency?: CurrencyEnum; // not available in internal proposal API
  note: string | null;
  paymentTerms: number;
  transitTime: number;
  attachments: string[];
  slug: number;
  expirationDate: string;
  proposalItems: IFeeEntity[];
  proposalShipmentItems: ICargoUnit[];
  proposalShipmentItemsFormatted: string;
  shipmentId: string;
  assignee: IUserEntity;
  assigneeId: string;
  createdBy: string;
  id: string;
  proposalComments: IProposalCommentEntity[];
  proposalCost: number;
  status: IQuoteStatus;
  submittedBy: string | null;
  submittedByUser?: { fullname: string };
  files: IFileEntity[];
  carrier: ICarrierEntity | null;
  proposalReference: string | null;
  shipmentTypeId: string | null;
  rank: number;
  rankNotificationEnabled: boolean;
  companyId: string;
  eta: string | null;
  company: ICompanyEntity;
  etd: string | null;
  vesselName: string;
  routing: string;
  contractId: string | null;
  carrierId: string | null;
  submittedAt: string | null;
  statusChangedAt: string | null;
  totalFees: string | null;
  suggestedProposal: IBaseProposalEntity | null;
  previousBiddingPrice?: number;
  showBiddingRank: boolean; // should be moved to shipment level
  isSuggestedProposalExist?: boolean;
}

export interface IExternalProposalParams {
  proposalId: string;
  email: string;
}

export interface IProposalFilters {
  status?: IQuoteStatus[];
  forwarders?: string[];
  carriers?: string[];
}
export interface IGetProposalRequestBody extends IProposalFilters {
  shipmentId: string;
}
export interface IUpdateProposalRequestBody {
  proposal: IProposalEntity;
  shipmentItems: ICargoUnit[] | undefined;
}
export interface IUpdateProposalViewRequestBody {
  proposalCommentIds: string[];
}

export interface ISubmitProposalRequestBody {
  shipmentId: string;
  shipmentTypeId: string;
}

export interface ISuggestedProposalRequestBody {
  proposalId: string;
}

export interface IAmendProposalRequestBody
  extends ISuggestedProposalRequestBody {
  status: ProposalAmendStatusEnum;
}

export interface IUpdateAcceptedProposalRequestBody {
  proposal: {
    note: string | null;
    proposalReference: string | null;
  };
  params: {
    id: string;
    email: string;
  };
}

export interface IExternalProposalEntity extends IProposalEntity {
  shipment: IShipmentEntity;
}

export interface INewProposalRequestBody {
  proposalId: string;
  email: string;
}

export interface INewProposalEntity {
  proposalId: string;
  email: string;
}

export interface AcceptOrRejectProposalRequest {
  id: string;
  params: {
    sendRejectionEmail: boolean;
  };
}
export interface AddCommentProposolRequest {
  comment: string;
  proposalId: string;
  email?: string;
}

export type IUpdateProposalCommentByShipmentSlice = (
  data: IProposalCommentEntity,
  shipmentId: string,
  proposalId: string
) => void;

export type IUpdateProposalCommentSlice = (
  data: IProposalCommentEntity,
  proposalId: string
) => void;

export interface IPropsalColleaguesParams {
  shipmentId: string;
  companyId: string;
}

export interface IProposalCollaboratorCheckParams {
  shipmentId: string;
  forwarderId: string;
}

export interface ISuggestedProposalByShipmentPayload {
  isSuggestedProposalExist: boolean;
}
