import { SyncOutlined } from '@ant-design/icons';
import { Spin, notification } from 'antd';
import { useAddTrackingByAiMutation } from 'api/tracking';
import SuccessTrackingModal from 'app/components/ShipmentDocuments/UploadDocumentModal/SuccessTrackingModal';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { setIsStartTracking } from 'state/slices/trackingByAi.slice';

import { displayErrors } from 'utils/error-notification';

const TrackingByAi: FC = () => {
  const [isSuccessTrackingModal, setIsSuccessTrackingModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const trackingState = useSelector((state: RootState) => state.trackingByAi);
  const { shipmentSlug, shipmentId, isStartTracking, fileUrl } = trackingState;

  const navigate = useNavigate();
  const [addTrackingByAi, { isLoading, isError, error, isSuccess }] =
    useAddTrackingByAiMutation();
  const handleAddTrackingByAi = async (shipmentId: string, fileUrl: string) => {
    try {
      await addTrackingByAi({
        shipmentId,
        fileUrl,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description:
          error.message ?? 'something went wrong while activating tracking',
      });
    }
  };
  useEffect(() => {
    if (isStartTracking && shipmentId && fileUrl) {
      handleAddTrackingByAi(shipmentId, fileUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl, isStartTracking, shipmentId]);
  useEffect(() => {
    if (isSuccess) {
      setIsSuccessTrackingModal(true);
      dispatch(setIsStartTracking(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      dispatch(setIsStartTracking(false));
      displayErrors(error, { title: 'Failed to add tracking' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);
  const onViewTracking = (shipmentId: string, slug: string) => {
    navigate(`/shipments/${slug}`, {
      state: { shipmentId, showBookingPartyModal: true },
    });
    setIsSuccessTrackingModal(false);
  };

  const onCanceltrackingModal = () => {
    setIsSuccessTrackingModal(false);
  };

  return (
    <>
      {isLoading && (
        <Spin tip="Live Tracking" indicator={<SyncOutlined spin />}>
          <div className="content" />
        </Spin>
      )}
      {isSuccess && shipmentId && shipmentSlug && (
        <SuccessTrackingModal
          isOpen={isSuccessTrackingModal}
          shipmentId={{ id: shipmentId, slug: shipmentSlug }}
          onViewTracking={onViewTracking}
          onCanceltrackingModal={onCanceltrackingModal}
        />
      )}
    </>
  );
};

export default TrackingByAi;
