import { Button, Form, Space, notification } from 'antd';
import { useGetExternalUserConnectionsQuery } from 'api/user';
import ContactDetailsForm from 'app/design-system/CommonForms/ContactDetailsForm';
import DashboardCard from 'app/design-system/DashboardCard';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IContactDetailsForm } from 'types/feature/create-shipment.types';
import {
  IOrderDetailsForm,
  IOrderLinesForm,
  IOrderCreationFormProps,
} from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import '../order-creation.scss';
import AdditionalInfoForm, {
  AdditionalInfoFormRef,
} from './AdditionalInfoForm';
import OrderDetailsForm from './OrderDetailsForm';
import OrderLinesForm from './OrderLinesForm';
import { getDefaultCRD, mapOrderDetails, mapOrderItems } from './helper';

const OrderCreationForm: FC<IOrderCreationFormProps> = ({
  defaultPurchaseOrder,
  isEditView,
  onFinish,
  isProcessing,
  onCancel,
  file,
  createBookingFlow,
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [orderDetailsForm] = Form.useForm<IOrderDetailsForm>();
  const [orderLinesForm] = Form.useForm<IOrderLinesForm>();
  const [contactPersonForm] = Form.useForm<IContactDetailsForm>();

  const elementRef = useRef<AdditionalInfoFormRef>(null); // create the ref
  const navigate = useNavigate();

  const {
    data: externalUserList,
    isError: isExternalUserListError,
    error: externalUserListError,
  } = useGetExternalUserConnectionsQuery();
  const hasExternalConnections = !!(
    externalUserList && externalUserList.length > 0
  );

  const onSubmitForm = async (isDraft: boolean) => {
    try {
      // DETAILS
      // 1. validate fields
      // validation
      if (!isDraft) {
        await orderDetailsForm.validateFields();
        await orderLinesForm.validateFields();
        await contactPersonForm.validateFields();
      }
      let orderDetails: IOrderDetailsForm;

      // 2. get data from fields
      if (isEditView) {
        // 2.1. if edited only get the modified data
        orderDetails = await orderDetailsForm.getFieldsValue(
          true,
          ({ touched }) => touched
        );

        if (orderDetails.origin) {
          orderDetails = {
            ...orderDetails,
            originFull: {
              ...(isEditView && { id: defaultPurchaseOrder?.origin.id }),
              ...(await orderDetailsForm.getFieldValue('originFull')),
            },
          };
        }

        if (orderDetails.destination) {
          orderDetails = {
            ...orderDetails,
            destinationFull: {
              ...(isEditView && { id: defaultPurchaseOrder?.destination.id }),
              ...(await orderDetailsForm.getFieldValue('destinationFull')),
            },
          };
        }
      } else {
        // 2.2. if new order , get all fields
        orderDetails = await orderDetailsForm.getFieldsValue(true);
      }

      // ORDER ITEMS
      let orderItems: IOrderLinesForm | null = null;

      const fieldsEdited =
        orderLinesForm.isFieldsTouched() || orderDetails.cargoReadyDate;

      if (!isEditView || fieldsEdited) {
        // 2. get items lines
        orderItems = await orderLinesForm.getFieldsValue(true);
      }

      const ownerId = await contactPersonForm.getFieldValue('createdBy');

      // CREATE THE REQ BODY
      const purchaseOrderUpsertBody = {
        ...mapOrderDetails(orderDetails, isDraft, user?.id),
        purchaseOrderItems: mapOrderItems(
          orderItems,
          orderDetails.cargoReadyDate ?? getDefaultCRD(defaultPurchaseOrder),
          isDraft
        ),
        urls: elementRef.current?.getUrls() ?? [],
        isDraft,
        ownerId,
      };

      await onFinish(purchaseOrderUpsertBody);
    } catch (error: unknown) {
      const lines = await orderLinesForm.getFieldsValue(true);
      if (!isDraft && !lines.items.length) {
        notification.error({
          message: 'Validation error',
          description: 'Make sure to insert at least one puchase order item !',
        });
      } else {
        notification.warning({
          message: 'Wrong inputs',
          description: 'Make sure to fill all mandotary fields correctly !',
        });
      }
    }
  };

  const resetForms = useCallback(() => {
    orderDetailsForm.resetFields();
    orderLinesForm.resetFields();
  }, [orderLinesForm, orderDetailsForm]);

  useEffect(() => {
    resetForms();
  }, [defaultPurchaseOrder, resetForms]);

  useEffect(() => {
    if (isExternalUserListError) {
      displayErrors(externalUserListError, {
        title: 'Loading Connections Error',
      });
    }
  }, [isExternalUserListError, externalUserListError]);

  return (
    <>
      {!isEditView && hasExternalConnections && (
        <DashboardCard
          large
          headerTitle="Contact Details"
          subTitle="Select the contact person to be notified about this purchase order."
        >
          <ContactDetailsForm
            form={contactPersonForm}
            externalUserList={externalUserList}
          />
        </DashboardCard>
      )}
      <OrderDetailsForm
        form={orderDetailsForm}
        createBookingFlow={createBookingFlow}
        defaultValues={
          defaultPurchaseOrder
            ? {
                ...defaultPurchaseOrder,
                orderIssueDate: defaultPurchaseOrder?.orderIssueDate
                  ? dayjs(defaultPurchaseOrder?.orderIssueDate)
                  : dayjs(),
                cargoReadyDate: getDefaultCRD(defaultPurchaseOrder),
                status: defaultPurchaseOrder?.status ?? null,
                destinationFull: defaultPurchaseOrder.destination,
                originFull: defaultPurchaseOrder.origin,
                destination: defaultPurchaseOrder.destination?.city,
                origin: defaultPurchaseOrder.origin?.city,
                supplier: defaultPurchaseOrder?.supplier?.id,
              }
            : null
        }
      />

      <OrderLinesForm
        form={orderLinesForm}
        footer={
          isEditView ? (
            <Space>
              <Button disabled={isProcessing} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => onSubmitForm(false)}
                disabled={isProcessing}
              >
                Update Order
              </Button>
            </Space>
          ) : (
            <></>
          )
        }
        isEditView={isEditView}
        defaultValues={
          defaultPurchaseOrder?.purchaseOrderItems
            ? {
                purchaseOrderId: isEditView
                  ? defaultPurchaseOrder.id
                  : undefined,
                items: defaultPurchaseOrder?.purchaseOrderItems ?? [],
              }
            : {
                items: [],
              }
        }
      />

      {!isEditView && (
        <AdditionalInfoForm
          ref={elementRef}
          uploadedPurchaseOrderfile={file}
          {...(!isEditView && {
            footer: (
              <Space>
                <Button
                  onClick={() => navigate('/purchase-orders')}
                  disabled={isProcessing}
                >
                  Cancel
                </Button>
                {!createBookingFlow && (
                  <Button
                    onClick={() => onSubmitForm(true)}
                    disabled={isProcessing}
                  >
                    Save & Close
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() => onSubmitForm(false)}
                  disabled={isProcessing}
                >
                  {createBookingFlow ? 'Create Booking' : 'Create Order'}
                </Button>
              </Space>
            ),
          })}
        />
      )}
    </>
  );
};

export default OrderCreationForm;
