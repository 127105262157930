import { createSlice } from '@reduxjs/toolkit';
import { firebaseAuth } from 'api/common/firebase';
import { IUserEntity } from 'types/entities/user.entity';

import { clearAuthTokens } from 'utils/auth-storage';

interface UserState {
  user?: IUserEntity;
}

const initialState = {
  user: undefined,
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.user = action.payload;
    },
    userLogout: (state, action) => {
      clearAuthTokens();

      firebaseAuth.signOut().then(() => {
        document.location.href = '/login';
      });
      state.user = undefined;
    },
  },
});

export const { setCurrentUser, userLogout } = userSlice.actions;
export default userSlice.reducer;
