import { Empty, Space, Typography } from 'antd';
import { useGetOrderFilesByIdQuery } from 'api/purchase-order';
import FilePreview from 'app/design-system/FilePreview';
import React, { FC } from 'react';

const { Title } = Typography;
const PurchaseOrderFiles: FC<{ orderId: string | null }> = ({ orderId }) => {
  const { data: files } = useGetOrderFilesByIdQuery(orderId ?? '', {
    skip: !orderId,
  });

  return (
    <>
      {files && files?.length > 0 ? (
        <div className="files-card">
          <Title level={5} className="m-0">
            Purchase Order Documents
          </Title>

          <Space size={[10, 16]} wrap>
            {files.map((file) => (
              <FilePreview
                key={file.id}
                imageUrl={file.file}
                size="small"
                file={file}
              />
            ))}
          </Space>
        </div>
      ) : (
        <Empty description="No file attachments for this purchase order" />
      )}
    </>
  );
};

export default PurchaseOrderFiles;
