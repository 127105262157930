import { Drawer, notification } from 'antd';
import { useUpdateContractMutation } from 'api/contract';
import React, { FC, useEffect } from 'react';
import 'react-reflex/styles.css';
import { IContractEntity } from 'types/entities/contract.entity';

import { displayErrors } from 'utils/error-notification';

import CreationForm from '../ContractRatesCreation/CreationForm';

const ContractRatesUpdate: FC<{
  contractToEdit: IContractEntity | undefined;
  isOpen: boolean;
  onClose: () => void;
}> = ({ contractToEdit, isOpen, onClose }) => {
  const [updateContractRate, { error, isError, isSuccess, isLoading }] =
    useUpdateContractMutation();

  const onFinish = async (contract: Partial<IContractEntity>) => {
    try {
      if (contractToEdit)
        await updateContractRate({
          params: { contractId: contractToEdit.id },
          body: contract,
        });
    } catch (error) {
      notification.warning({ message: 'Unknown Error !' });
    }
  };

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Contract update Error' });
    }

    return;
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Contract updated !',
        description: 'You can now view in main page !',
      });
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Drawer
      open={isOpen}
      title={`Update Contract ${contractToEdit?.reference ?? ''}`}
      width={'70vw'}
      onClose={onClose}
    >
      <CreationForm
        defaultContract={
          contractToEdit
            ? {
                ...contractToEdit,
                carrierId: contractToEdit.carrier?.id,
                contactId: contractToEdit.contact.id,
              }
            : null
        }
        isEditView={true}
        onFinish={onFinish}
        isProcessing={isLoading}
      />
    </Drawer>
  );
};

export default ContractRatesUpdate;
