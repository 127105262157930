import {
  IBookingPartyEntity,
  IBookingPartyEnum,
} from 'types/entities/booking-party';
import { ICreatedByDef, IShipmentEntity } from 'types/entities/shipment.entity';
import {
  IUserEntity,
  UserRoleEnum,
  userProfileRoleEnum,
} from 'types/entities/user.entity';
import { IInvoiceFilters } from 'types/feature/invoice-list';
import {
  IShipmentsFiltersState,
  ShipmentStatusEnum,
} from 'types/feature/shipments.types';

export const convertToStatusValue = (status: string) => {
  return status.toLowerCase().replace(' ', '_') as ShipmentStatusEnum;
};

export const computeAllFiltersCount = (
  filters: IShipmentsFiltersState | IInvoiceFilters
) => {
  let totalCount = 0;

  Object.keys(filters).forEach((key) => {
    totalCount += filters[key].length;
  });

  return totalCount;
};

export const isAdmin = (userRole: userProfileRoleEnum | undefined) =>
  !!(userRole === userProfileRoleEnum.ADMIN);

export const isCargoOwner = (currentUserRole: UserRoleEnum | undefined) =>
  !!(currentUserRole === UserRoleEnum.CARGO_OWNER);

export const isAdminCO = (
  userRole: userProfileRoleEnum | undefined,
  userShipmentRole: UserRoleEnum | undefined
) => {
  return isCargoOwner(userShipmentRole) && isAdmin(userRole);
};

export const isShipmentCreator = (
  shipment: IShipmentEntity,
  currentUser: IUserEntity | undefined
) => {
  return !!(currentUser && shipment.createdBy.id === currentUser?.id);
};

export const isShipmentCreatorOrCO = (
  shipment: IShipmentEntity,
  currentUser: IUserEntity | undefined
) =>
  isCargoOwner(shipment.currentUserRole) ||
  isShipmentCreator(shipment, currentUser);

export const isForwarder = (currentUserRole: UserRoleEnum | undefined) =>
  !!(currentUserRole === UserRoleEnum.FORWARDER);

export const isOtherUser = (currentUserRole: UserRoleEnum | undefined) =>
  !!(
    currentUserRole !== UserRoleEnum.CARGO_OWNER &&
    currentUserRole !== UserRoleEnum.FORWARDER
  );

export const isShipmentCreatedByExternal = (
  createdBy: IUserEntity | ICreatedByDef,
  initiatedBy: IUserEntity | ICreatedByDef
) =>
  !!(
    initiatedBy &&
    createdBy &&
    createdBy.company?.id !== initiatedBy.company?.id
  );

export const shipmentStartedStatusConst = [
  ShipmentStatusEnum.IN_ORIGIN,
  ShipmentStatusEnum.IN_TRANSIT,
  ShipmentStatusEnum.IN_DESTINATION,
  ShipmentStatusEnum.IN_CUSTOMS,
  ShipmentStatusEnum.DELIVERED,
];

export const hasShipmentStarted = (status: ShipmentStatusEnum) =>
  shipmentStartedStatusConst.includes(status);

export const shipmentRFQStartedStatusConst = [
  ShipmentStatusEnum.RFQ_REQUESTED,
  ShipmentStatusEnum.RFQ_SUBMITTED,
];

export const hasShipmentRFQStarted = (status: ShipmentStatusEnum) =>
  shipmentRFQStartedStatusConst.includes(status);

export const isCommunicationTabHidden = (
  currentUserRole: UserRoleEnum,
  shipmentStatus: ShipmentStatusEnum
) =>
  !hasShipmentStarted(shipmentStatus) &&
  currentUserRole === UserRoleEnum.FORWARDER;

export const canInviteForwarder = (status: ShipmentStatusEnum) =>
  [
    ShipmentStatusEnum.CREATED,
    ShipmentStatusEnum.RFQ_REQUESTED,
    ShipmentStatusEnum.RFQ_SUBMITTED,
  ].includes(status);

export const findBookingPartyByRole = (
  bookingParties: IBookingPartyEntity[] | undefined,
  type: IBookingPartyEnum
) => {
  return bookingParties?.find((party) => party.role === type);
};

export const isShipmentDocumentUrl = (currentURL: string) =>
  currentURL.includes('documents') && currentURL.includes('shipments');
