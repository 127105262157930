export const formatValuesToUpperCase = (value: string) => {
  return value?.toUpperCase() || value;
};

export const captializeValue = (input: string) => {
  const words = input.split('-');
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(' ');
};
