import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, List, Button, Card, Badge, Spin, Divider } from 'antd';
import { useUpdateMessageMutation } from 'api/messages';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import {
  IGetMessages,
  IShipmentMessagesListProps,
  IShipmentsMessageEntity,
} from 'types/entities/message.entity';
import { IBaseAnalyticsEvents } from 'types/feature/analytics-events.type';

import {
  conversationClickEvent,
  messagesSearchEvent,
  showMoreClickEvent,
} from 'utils/analytics-events';
import { computeMessageDate } from 'utils/date-utils';
import { displayErrors } from 'utils/error-notification';
import { formatOriginToDest } from 'utils/format-fields';

import './shipment-messages-list.scss';

const { Search } = Input;
const defaultPaginationAndSearch: IGetMessages = {
  page: 1,
  limit: 15,
  keyword: '',
};
const ShipmentMessagesList: FC<IShipmentMessagesListProps> = ({
  shipmentsMessages,
  isLoading,
  isFetching,
  getAllMessages,
  setSelectedMessage,
  selectedMessage,
}) => {
  const [params, setParams] = useState<IGetMessages>(
    defaultPaginationAndSearch
  );
  const user = useSelector((state: RootState) => state.user.user);
  const changeDetails: IBaseAnalyticsEvents = {
    user_id: user?.id || '',
    email: user?.email || '',
    company: user?.company?.name || '',
    company_id: user?.companyId || '',
  };
  const onLoadMore = () => {
    setParams({ ...params, limit: params.limit + 5 });
    showMoreClickEvent(changeDetails);
  };

  const [updateShipment, { isLoading: isUpdateLoading }] =
    useUpdateMessageMutation();

  const handleUpdateShipment = async (shipmentId: string) => {
    try {
      await updateShipment(shipmentId).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to update shipment' });
    }
  };
  const handleInputOnChange = (value: string) => {
    if (value === '') {
      setParams({ ...params, keyword: '' });
      return;
    }
  };

  const handleOnSearch = (value: string) => {
    setParams({ ...params, keyword: value });
    messagesSearchEvent(changeDetails);
  };

  const handleClickMessage = (message: IShipmentsMessageEntity) => {
    setSelectedMessage(message);
    handleUpdateShipment(message.id);
    conversationClickEvent(changeDetails);
  };

  useEffect(() => {
    getAllMessages(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedMessage]); //refetch messages whenever user switch shipments

  const renderCount = (messages: number, comments: number, id: string) => {
    if (messages > 0 && isUpdateLoading && id === selectedMessage?.id) {
      return <Spin />;
    } else if (messages > 0 || comments > 0) {
      return <Badge count={messages + comments} color="blue" />;
    }
  };
  return (
    <>
      <Card className="header-card" bordered={false}>
        <Search
          className="search-field"
          prefix={<SearchOutlined className="search-icon" />}
          placeholder="Search shipment messages..."
          allowClear
          onChange={(event) => handleInputOnChange(event.target.value)}
          onSearch={handleOnSearch}
        />
      </Card>
      <Card bordered={false} className="list-card">
        <List
          loading={isLoading}
          dataSource={shipmentsMessages}
          renderItem={(message: IShipmentsMessageEntity) => (
            <div
              onClick={() => handleClickMessage(message)}
              className={`shipment-message-wrapper${
                selectedMessage === message ? '__selected' : ''
              }`}
            >
              <List.Item>
                <List.Item.Meta
                  className="message-item"
                  avatar={displayFreighMethod(
                    message.shipmentType.modality,
                    false
                  )}
                  title={formatOriginToDest(
                    message.destination,
                    message.origin
                  )}
                  description={
                    <>
                      Shipment {message.slug}
                      {message.shipmentReference && (
                        <>
                          <Divider type="vertical" />
                          {message.shipmentReference}
                        </>
                      )}
                    </>
                  }
                />

                <div className="date-time">
                  {computeMessageDate(message.latestMessage.createdAt)}
                  {renderCount(
                    message.unreadMessageCount,
                    message.unreadCommentCount,
                    message.id
                  )}
                </div>
              </List.Item>
            </div>
          )}
        ></List>
      </Card>
      <div className="shipment-messages-see-more-action">
        <Button
          type="link"
          loading={isFetching}
          onClick={onLoadMore}
          icon={<CaretDownOutlined />}
        >
          Show more
        </Button>
      </div>
    </>
  );
};

export default ShipmentMessagesList;
