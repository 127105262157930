import { IPurchaseOrderEntity } from 'types/entities/purchase-order.entity';

export interface IBookingCreateState {
  isOpen: boolean;
  step: number;
  extractedData?: Partial<IPurchaseOrderEntity>;
}

export enum BookingCreateModalActionEnum {
  OPEN = 'open',
  CLOSE = 'close',
  STEP = 'step',
  PARSE = 'parse',
}

// An interface for our actions
export interface IBookingCreateModalAction {
  type: BookingCreateModalActionEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>;
}

export const reducer = (
  state: IBookingCreateState,
  action: IBookingCreateModalAction
) => {
  switch (action.type) {
    case BookingCreateModalActionEnum.OPEN:
      return { isOpen: true, step: 0 };
    case BookingCreateModalActionEnum.CLOSE:
      return { ...state, isOpen: false };
    case BookingCreateModalActionEnum.STEP:
      return { ...state, step: action.payload.step };
    case BookingCreateModalActionEnum.PARSE:
      return { ...state, extractedData: action.payload.extractedData };
    default:
      return state;
  }
};
