import React, { createContext, useState, useContext, FC } from 'react';
import { IBookingPartyEntity } from 'types/entities/booking-party';
import {
  IBookingPartyContextType,
  IBookingPartyProviderProps,
} from 'types/feature/booking-party.types';
import { ShipmentTabsEnum } from 'types/feature/shipments.types';

export const BookingPartyContext =
  createContext<IBookingPartyContextType>(undefined);

export const BookingPartyContextProvider: FC<IBookingPartyProviderProps> = (
  props
) => {
  const [selectedBookingParty, setSelectedBookingParty] =
    useState<IBookingPartyEntity | null>(null);
  const [tabState, setTabState] = useState<string | null>(null);
  const [addBookingPartyModal, setAddBookingPartyModal] =
    useState<boolean>(false);
  const [shareBookingPartyModal, setShareBookingPartyModal] =
    useState<boolean>(false);

  const onShareBookingPartyClick = (bookingParty: IBookingPartyEntity) => {
    setSelectedBookingParty(bookingParty);
    setTabState(ShipmentTabsEnum.COMMUNICATIONS);
  };

  const resetShareBookingParty = (newTab?: string) => {
    setSelectedBookingParty(null);
    setShareBookingPartyModal(false);

    if (newTab) {
      setTabState(null);
    }
  };

  const switchTabOnShare = (newTab: string) => {
    setTabState(newTab);
  };

  const showAddBookingPartyModal = () => setAddBookingPartyModal(true);
  const hideAddBookingPartyModal = () => setAddBookingPartyModal(false);

  const showShareBookingPartyModal = () => setShareBookingPartyModal(true);
  const hideShareBookingPartyModal = () => setShareBookingPartyModal(false);

  return (
    <BookingPartyContext.Provider
      value={{
        tabState,
        bookingParty: selectedBookingParty,
        addBookingPartyOnboarding: addBookingPartyModal,
        shareBookingPartyModal,
        onShareBookingPartyClick,
        resetShareBookingParty,
        switchTabOnShare,

        showAddBookingPartyModal,
        hideAddBookingPartyModal,

        showShareBookingPartyModal,
        hideShareBookingPartyModal,
      }}
      {...props}
    />
  );
};

export const useBookingPartyState = () => {
  const bookingParty = useContext(BookingPartyContext);

  return {
    tabState: bookingParty?.tabState,
    bookingParty: bookingParty?.bookingParty,
    addBookingPartyOnboarding: bookingParty?.addBookingPartyOnboarding,
    shareBookingPartyModal: bookingParty?.shareBookingPartyModal,

    onShareBookingPartyClick: bookingParty?.onShareBookingPartyClick,
    resetShareBookingParty: bookingParty?.resetShareBookingParty,
    switchTabOnShare: bookingParty?.switchTabOnShare,

    showAddBookingPartyModal: bookingParty?.showAddBookingPartyModal,
    hideAddBookingPartyModal: bookingParty?.hideAddBookingPartyModal,

    showShareBookingPartyModal: bookingParty?.showShareBookingPartyModal,
    hideShareBookingPartyModal: bookingParty?.showShareBookingPartyModal,
  };
};
