import { ButtonType } from 'antd/es/button';
import { IBookingPartyEntity } from 'types/entities/booking-party';
import { ICarrierEntity } from 'types/entities/carrier.entity';
import { IUserPartnerEntity } from 'types/entities/company.entity';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { IFileEntity } from 'types/entities/files.entity';
import {
  ICargoUnit,
  IShipmentEntity,
  IShipmentTypeDef,
} from 'types/entities/shipment.entity';
import { ITaskEntity } from 'types/entities/task.entity';

import { IInvoiceFilters } from './invoice-list';
import { SearchAndSortParams } from './pagination.types';

export enum UploadAllowedFormatEnum {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  EXCEL = 'excel',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  ZIP_COMPRESSED = 'application/x-zip-compressed',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface IShipmentHeaderProps {
  shipment: IShipmentEntity;
}

export interface IShipmentInfoProps {
  shipment: IShipmentEntity;
  cardOverlay: boolean;
}

export interface IShipmentTrackingProps extends IShipmentInfoProps {
  showMap?: boolean;
}

export type TShipmentOverviewProps = IShipmentInfoProps;

export type TBookingPartiesProps = IShipmentInfoProps;

// Note: please put status in exact sequence.
export enum ShipmentStatusEnum {
  CREATED = 'created',
  RFQ_REQUESTED = 'rfq_requested',
  RFQ_SUBMITTED = 'rfq_submitted',
  IN_ORIGIN = 'in_origin',
  IN_TRANSIT = 'in_transit',
  IN_DESTINATION = 'in_destination',
  IN_CUSTOMS = 'in_customs',
  DELIVERED = 'delivered',
}

export interface IStatusTimelineItem {
  key: ShipmentStatusEnum;
  label: string | JSX.Element;
  isActive: boolean;
  date?: string;
}

export interface IBookingItemProps {
  item: IBookingPartyEntity;
  isEditable: boolean;
  isShareable: boolean;
}

export interface IShipmentTasksItemProps {
  task: ITaskEntity;
  shipmentId: string;
}

export type TShipmentTasksProps = IShipmentInfoProps;

export interface IShipmentDocumentsProps {
  shipment: IShipmentEntity;
}

export interface IUploadDocumentModalProps {
  shipmentSlug?: string;
  shipmentId: string;
  actionButton: {
    className?: string;
    style?: React.CSSProperties;
    text: string;
    type?: ButtonType;
    icon?: JSX.Element;
  };
  onUploadComplete?: (url: string[]) => void;
  isMultiple?: boolean;
}

export interface IDeletekButtonProps {
  shipmentId: string;
  isShipmentArchived: boolean;
}

export interface IDuplicatekButtonProps {
  shipmentId: string;
}

export interface IStatusTimelineProps {
  items: IStatusTimelineItem[];
}

export interface IUpdateShipmentRequestBody {
  shipmentId: string;
  shipment: Partial<IShipmentEntity>;
}

export interface IShipmentsResponseBody {
  items: IShipmentEntity[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface ISingleShipmentResponse extends IShipmentEntity {
  trackingNumber: string;
}

export interface ICreateShipmentResponseBody {
  id: string;
  slug: number;
}

export interface ShipmentsParams {
  keyword?: string;
}

export interface IShipmentsFilters {
  destinations?: string[];
  origins?: string[];
  date?: string[];
  statuses?: ShipmentStatusEnum[];
  modalities?: string[];
  shipmentType?: string[];
  tableView?: string[];
  collaborators?: string[];
  creators?: string[];
  initiatedBy?: string[];
  carriers?: string[];
  etds?: string[];
  etas?: string[];
}

export interface IShipmentsFiltersState extends IShipmentsFilters {
  dateType?: string;
}
export interface IShipmentsRequestBody {
  params?: SearchAndSortParams;
  body?: IShipmentsFilters;
}

export interface IShipmentsProposalRequest extends SearchAndSortParams {
  destinations?: string[];
  origins?: string[];
  date?: string[];
  statuses?: string[];
  modalities?: string[];
  shipmentType?: string[];
  collaborators?: string[];
  creators?: string[];
  initiatedBy?: string[];
  carriers?: string[];
  etds?: string[];
}

export interface IStatusFilterForm {
  statusFilter: ShipmentStatusEnum[];
}

export interface IBaseFilterProps {
  layout: 'horizontal' | 'vertical';
  defaultValue: string[] | undefined;
}

export interface IStatusFilterProps extends IBaseFilterProps {
  layout: 'horizontal' | 'vertical';
  defaultValue: string[] | undefined;
  onChange: (statusFilter: ShipmentStatusEnum[]) => void;
  fromPendingQuotes?: boolean;
}

export interface IDateFilterForm {
  dateFilter: string[];
}

export interface IDateFilter {
  type: string | undefined;
  date: string[] | undefined;
}

export interface IDateFilterProps {
  layout: 'horizontal' | 'vertical';
  defaultValue: string[] | undefined;
  onChange: (dateFilter: string[]) => void;
}

export interface ILocationFilterProps extends IBaseFilterProps {
  onChange: (locationFilter: string[]) => void;
  locations: string[];
}

export interface ILocationFilterForm {
  locationFilter: string[];
}

export interface ICollaboratorFilterProps extends IBaseFilterProps {
  onChange: (locationFilter: string[]) => void;
  collaborators: IUserPartnerEntity[];
}

export interface ICarrierFilterProps extends IBaseFilterProps {
  onChange: (locationFilter: string[]) => void;
  carriers: ICarrierEntity[];
}

export interface ICarrierFilterForm {
  carrierFilter: string[];
}

export interface IInviteByFilterProps extends IBaseFilterProps {
  onChange: (locationFilter: string[]) => void;
  inviteBy: IUserPartnerEntity[];
}

export interface IContactFilterProps extends IBaseFilterProps {
  onChange: (locationFilter: string[]) => void;
  contacts: IUserPartnerEntity[];
}

export interface ICollaboratorFilterForm {
  collaboratorFilter: string[];
}

export interface IContactFilterForm {
  contactFilter: string[];
}

export interface IInviteByFilterForm {
  inviteByFilter: string[];
}

export interface IModalityFilterProps extends IStatusFilterProps {
  onChange: (modalityFilter: string[]) => void;
}

export interface IModalityFilterForm {
  modalityFilter: string[];
}

export interface IShipmentTypeFilterProps extends IBaseFilterProps {
  onChange: (shipmentTypeFilter: string[]) => void;
}

export interface IShipmentTypeFilterForm {
  shipmentTypeFilter: string[];
}

export interface IFilterDrawerProps {
  defaultValue: IShipmentsFilters;
  onChange: (filter: IShipmentsFilters) => void;
  onReset: () => void;
  fromPendingQuotes?: boolean;
}

export interface IRenderFilterProps {
  filters: IShipmentsFilters | IInvoiceFilters | Record<string, string>;
  renderFilterComponent: JSX.Element;
  buttonText: string;
  itemKey: string;
  fromFilter?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onOpenTableView?: () => void;
  onCloseTableView?: () => void;
  isTableViewOpen?: boolean;
}

export interface IAcceptedShipmentState {
  cargoUnits: ICargoUnit[];
  shipmentItems: string;
  shipmentType: IShipmentTypeDef;
}

export interface IShipmentShareModalProps {
  shipmentId: string;
  file: IFileEntity;
  isOpen: boolean;
  onClose: () => void;
}

export interface IEditModalProps {
  shipmentId: string;
  shipmentSlug: string;
  file: IFileEntity;
  isOpen: boolean;
  onClose: () => void;
}

export interface IShipmentFinanceProps {
  shipment: IShipmentEntity;
}

export interface IShipmentMessagesProps {
  shipment: IShipmentEntity;
  fitContent?: boolean;
  firstUnreadMessageId?: string;
}

export interface IShipmentPreviewProps {
  shipmentSlug: string | null;

  onClose: () => void;
  onNavigateToShipment: (shipment: IShipmentEntity) => void;
}
export interface ITrackingPreviewProps {
  shipment?: ISingleShipmentResponse;
  isOpen: boolean;
  onClose: () => void;
}

export enum ShipmentTabsEnum {
  OVERVIEW = 'overview',
  QUOTES = 'quotes',
  CONTAINERS = 'containers',
  DOCUMENTS = 'documents',
  COMMUNICATIONS = 'communication',
  BOOKINGS = 'bookings',
  FINANCE = 'invoice',
}

export interface IShipmentStatusSwitchProps {
  shipment: IShipmentEntity;
}

export interface IUploadShipmentDocsResponseBody {
  id: string;
  url: string;
  name: string;
  type: IDocumentTypeEnum;
}

export interface IMultipleUploadFileDataSourcDef
  extends IUploadShipmentDocsResponseBody {
  file: File;
}

export interface MyMultipleFileUploadProps {
  shipmentId: string;
  isLoading: boolean;
  defaultValues: IMultipleUploadFileDataSourcDef[];
  onAccept: (files: IMultipleUploadFileDataSourcDef[]) => void;
  onSave: (files: IMultipleUploadFileDataSourcDef[]) => void;
  onDelete: (
    files: IMultipleUploadFileDataSourcDef[],
    index: number
  ) => Promise<void>;
}

export interface IMultipleUploadFileForm {
  fileItems: IMultipleUploadFileDataSourcDef[];
}

export interface IUploadFilePromise {
  success: boolean;
  data: IMultipleUploadFileDataSourcDef[];
  error?: undefined;
}

export type IUploadFileMethodDef = (
  fileData: IMultipleUploadFileDataSourcDef
) => Promise<IUploadFilePromise>;

export interface IShipmentFiltersProps {
  selectedFilters: IShipmentsFilters;
  isBookingAssignment: boolean;
  onChange: (value: string[], name: string) => void;
  onReset: () => void;
  onDrawerChange: (filter: IShipmentsFilters) => void;
}

export interface IShipmentsTableProps {
  isBookingAssignment?: boolean;
  onAssignToShipment?: (
    id: string,
    slug: string,
    isCreateMode: boolean
  ) => void;
}

export interface OriginDestinationPreviewProps {
  shipment: ISingleShipmentResponse | IShipmentEntity;
}
export interface ShipmentProgressProps {
  shipment: ISingleShipmentResponse | IShipmentEntity;
  isTracking?: boolean;
}
