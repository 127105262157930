import { Button, Drawer, Empty, Skeleton, Space, Spin } from 'antd';
import { useGetShipmentsBySlugQuery } from 'api/shipment';
import AppTabs from 'app/design-system/AppTabs';
import React, { FC, useEffect, useState } from 'react';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import {
  IShipmentPreviewProps,
  ShipmentTabsEnum,
} from 'types/feature/shipments.types';

import { isViewer } from 'utils/collaborators';
import { formatShipmentState } from 'utils/format-fields';
import {
  hasShipmentRFQStarted,
  hasShipmentStarted,
  isCommunicationTabHidden,
} from 'utils/shipment-helper';

import Bookings from '../Bookings';
import ShipmentTags from '../ShipmentDetails/ShipmentTags';
import ShipmentDocuments from '../ShipmentDocuments';
import ShipmentFinance from '../ShipmentFinance';
import ShipmentMessages from '../ShipmentMessages';
import ShipmentQuotes from '../ShipmentQuotes';
import ShipmentOverview from './ShipmentOverview';
import { formatTitle } from './ShipmentOverview/shipment-overview.utility';

const renderTabs = (shipment: IShipmentEntity) => [
  {
    label: 'Overview',
    key: 'overview',
    children: <ShipmentOverview shipment={shipment} />,
  },
  ...(isCommunicationTabHidden(shipment.currentUserRole, shipment.status)
    ? []
    : [
        {
          label: 'Communication',
          key: 'communication',
          children: <ShipmentMessages shipment={shipment} />,
        },
      ]),
  ...(!isViewer(shipment.currentCollaborator?.accessLevel)
    ? [
        {
          label: 'Quotes',
          key: 'quotes',
          children: <ShipmentQuotes shipment={shipment} />,
        },
      ]
    : []),

  {
    label: 'Documents',
    key: 'documents',
    children: <ShipmentDocuments shipment={shipment} />,
  },

  ...(hasShipmentStarted(shipment.status)
    ? [
        {
          label: 'Finance',
          key: 'finance',
          children: <ShipmentFinance shipment={shipment} />,
          disabled: false,
        },
      ]
    : []),
  {
    label: 'Bookings',
    key: 'bookings',
    children: <Bookings shipment={shipment} />,
  },
];

const ShipmentPreview: FC<IShipmentPreviewProps> = ({
  shipmentSlug,
  onClose,
  onNavigateToShipment,
}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(
    ShipmentTabsEnum.OVERVIEW
  );

  const { data: shipmentData, isLoading } = useGetShipmentsBySlugQuery(
    shipmentSlug || '',
    { skip: !shipmentSlug, refetchOnMountOrArgChange: true }
  );

  const isOpen = !!shipmentSlug;

  useEffect(() => {
    if (shipmentData && hasShipmentRFQStarted(shipmentData.status)) {
      setActiveTabKey(ShipmentTabsEnum.QUOTES);
    } else {
      setActiveTabKey(ShipmentTabsEnum.OVERVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData?.status]);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <Drawer
      destroyOnClose
      width={'70vw'}
      open={isOpen}
      onClose={onClose}
      headerStyle={{ background: '#DAE6EF' }}
      title={
        shipmentData ? (
          <Space direction="vertical">
            <Space>
              {formatTitle(shipmentData)}
              {formatShipmentState(shipmentData.status)}
            </Space>
            <ShipmentTags shipment={shipmentData} />
          </Space>
        ) : (
          <Spin>Loading</Spin>
        )
      }
      extra={
        shipmentData && (
          <Button
            onClick={() => onNavigateToShipment(shipmentData)}
            className="footer-action-btn"
          >
            View Shipment
          </Button>
        )
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : shipmentData ? (
        <AppTabs
          destroyInactiveTabPane={true}
          items={renderTabs(shipmentData)}
          activeKey={activeTabKey}
          onTabClick={onTabChange}
        />
      ) : (
        <Empty description="Unble to fetch shipment details" />
      )}
    </Drawer>
  );
};

export default ShipmentPreview;
