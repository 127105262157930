import { CurrencyEnum } from 'types/entities/fee.entity';

export const formatAmount = (price: number, currency: CurrencyEnum) =>
  `${currency.toUpperCase()} ${new Intl.NumberFormat().format(
    Math.round(price)
  )}`;

export const formatShortenAmount = (
  price: number,
  currency: CurrencyEnum
): string => {
  if (price >= 1000) {
    return `${(price / 1000).toFixed(1)}K ${currency.toUpperCase()}`;
  } else {
    return `${price} ${currency.toUpperCase()}`;
  }
};
