import { Button, Typography } from 'antd';
import React from 'react';
import {
  IInvoiceActionButton,
  InvoiceStatusEnum,
} from 'types/feature/invoice.types';

import InvoiceAcceptModal from '../InvoiceAcceptModal';
import InvoiceAssigneeModal from '../InvoiceAssigneeModal';

const { Title, Paragraph } = Typography;

export const computeSuccessMessage = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.DRAFT:
      return (
        <>
          <Title level={3}>Thanks for submitting the invoice 🎉</Title>
          <Paragraph className="modal-content">
            Your invoice has successfully created and under review.
          </Paragraph>
        </>
      );
    case InvoiceStatusEnum.ACCEPTED:
      return (
        <>
          <Title level={3}>Thanks for approving 🎉</Title>
          <Paragraph className="modal-content">
            We will inform the vendor accordingly.
          </Paragraph>
        </>
      );

    case InvoiceStatusEnum.PAID:
      return (
        <>
          <Title level={3}>Thank you for the payment 🎉</Title>
          <Paragraph className="modal-content">
            We will inform the vendor accordingly.
          </Paragraph>
        </>
      );

    case InvoiceStatusEnum.DECLINED:
      return (
        <>
          <Title level={3}>Thanks You 🎉</Title>
          <Paragraph className="modal-content">
            We will inform the vendor about the issue.
          </Paragraph>
        </>
      );
  }
};

const isInvoiceTreated = (status: InvoiceStatusEnum) =>
  !![
    InvoiceStatusEnum.ACCEPTED,
    InvoiceStatusEnum.DECLINED,
    InvoiceStatusEnum.PAID,
  ].includes(status);

export const renderActionButtons = ({
  canBypass,
  invoiceId,
  shipmentId,
  status,
  isLoading,
  isAdminCO,
  canAssignInvoice,
  onStatusActionClick,
  onCloseClick,
}: IInvoiceActionButton) => {
  if (isAdminCO && !isInvoiceTreated(status)) {
    return (
      <>
        <Button
          type="default"
          danger
          onClick={() => onStatusActionClick(InvoiceStatusEnum.DECLINED)}
          loading={isLoading}
        >
          Decline
        </Button>
        <Button
          type="primary"
          onClick={() => onStatusActionClick(InvoiceStatusEnum.ACCEPTED)}
          loading={isLoading}
        >
          Accept
        </Button>
      </>
    );
  } else if (status === InvoiceStatusEnum.DRAFT) {
    if (canAssignInvoice) {
      return canBypass ? (
        <InvoiceAcceptModal invoiceId={invoiceId} shipmentId={shipmentId} />
      ) : (
        <InvoiceAssigneeModal invoiceId={invoiceId} shipmentId={shipmentId} />
      );
    }
    return (
      <Button
        type="primary"
        loading={isLoading}
        onClick={() => onStatusActionClick(InvoiceStatusEnum.DRAFT)}
      >
        Submit
      </Button>
    );
  } else if (isAdminCO && status === InvoiceStatusEnum.ACCEPTED) {
    return (
      <Button
        type="primary"
        onClick={() => onStatusActionClick(InvoiceStatusEnum.PAID)}
        loading={isLoading}
      >
        Processed
      </Button>
    );
  } else {
    return (
      <Button type="default" onClick={onCloseClick} loading={isLoading}>
        Close
      </Button>
    );
  }
};
