import { BaseQueryApi } from '@reduxjs/toolkit/dist/query';
import { authApi } from 'api/auth';
import { isUserWithinTargetTimeZone } from 'api/common/config';
import { firebaseAuth } from 'api/common/firebase';
import dayjs from 'dayjs';
import {
  User,
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
} from 'firebase/auth';
import { userLogout } from 'state/slices/user.slice';

import {
  getRefreshToken,
  getSessionEmail,
  saveAuthTokens,
  setRememberMe,
} from 'utils/auth-storage';
import { waitForAuthStateChange } from 'utils/user-helper';

// refresh tokens helper
const refreshTokens = async (api?: BaseQueryApi) => {
  try {
    if (isUserWithinTargetTimeZone()) {
      if (getRefreshToken() === '') throw new Error('no refresh token');

      // if user is within target timezone, refresh token through BE api
      const resp = await api?.dispatch(
        authApi.endpoints.refreshClientTokens.initiate()
      );
      if (!resp?.data?.data) throw new Error('no user found');

      const { tokens: refreshedTokens } = resp.data.data;

      saveAuthTokens(
        {
          accessToken: refreshedTokens.accessToken,
          refreshToken: refreshedTokens.refreshToken,
          expiresIn: dayjs().add(1, 'hour').unix(),
        },
        getSessionEmail() ?? ''
      );
    } else if (firebaseAuth) {
      // if user is not within target timezone, refresh token through firebase
      const user = await waitForAuthStateChange(firebaseAuth);

      if (!user) {
        throw new Error('no user found');
      }

      const refreshedTokens = await user?.getIdTokenResult(true);
      saveAuthTokens(
        {
          accessToken: refreshedTokens?.token as string,
          expiresIn: Number(refreshedTokens?.claims.exp),
          refreshToken: '',
        },
        getSessionEmail() ?? user.email
      );
    } else {
      throw new Error('no firebase auth session');
    }
  } catch (error) {
    if (api) api.dispatch(userLogout({}));
    window.location.href = '/login';
  }
};

// set persistence helper
const setPersistenceForUser = (rememberMe: boolean) => {
  if (rememberMe) {
    setPersistence(firebaseAuth, browserLocalPersistence);
  } else {
    setPersistence(firebaseAuth, browserSessionPersistence);
  }
  setRememberMe(rememberMe);
};

const isUserEmailVerified = (user: User) => {
  return user.emailVerified;
};

export { refreshTokens, setPersistenceForUser, isUserEmailVerified };
