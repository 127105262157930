import { Alert, Skeleton, Table } from 'antd';
import { useGetUpcomingTasksQuery } from 'api/dashboard';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { ITaskEntity } from 'types/entities/task.entity';
import { ShipmentsCardProps } from 'types/feature/dashboard.types';

import { viewTaskClickEvent } from 'utils/analytics-events';

import { taskColumnsConst } from './tasks-card.const';

const TasksCard: FC<ShipmentsCardProps> = ({ navigateToShipmentsPage }) => {
  const { data, isLoading, error } = useGetUpcomingTasksQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const user = useSelector((state: RootState) => state.user.user);

  const onRow = (record: ITaskEntity, index: number | undefined) => {
    return {
      onClick: () => {
        navigateToShipmentsPage({
          id: record.shipmentId,
          slug: record.shipment,
          redirct: {
            tab: 'overview',
          },
        });
      },
    };
  };
  const onViewTask = () => {
    viewTaskClickEvent({
      user_id: user?.id,
      email: user?.email,
      company: user?.company.name,
      company_id: user?.companyId,
    });
  };
  return (
    <DashboardCard headerTitle="Upcoming Tasks" fitContent>
      {error && (
        <Alert
          showIcon
          message="Unexpected error while fetching tasks"
          type="error"
        />
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          columns={[
            ...taskColumnsConst,
            {
              key: 'action',
              render: (_, record: ITaskEntity) => (
                <Link
                  onClick={onViewTask}
                  to={{
                    pathname: `/shipments/${record.shipment}`,
                  }}
                  state={{ shipmentId: record.shipmentId }}
                >
                  View Task
                </Link>
              ),
            },
          ]}
          showHeader={false}
          dataSource={data ?? []}
          showSorterTooltip
          pagination={{ hideOnSinglePage: true }}
          size="middle"
          loading={isLoading}
          rowKey="id"
          onRow={onRow}
        />
      )}
    </DashboardCard>
  );
};

export default TasksCard;
