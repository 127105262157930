import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ShipmentStatusEnum } from 'types/feature/shipments.types';

export const calculateShipmentProgress = (status: ShipmentStatusEnum) => {
  switch (status) {
    case ShipmentStatusEnum.DELIVERED:
      return 100;
    case ShipmentStatusEnum.IN_CUSTOMS:
      return 90;
    case ShipmentStatusEnum.IN_DESTINATION:
      return 75;
    case ShipmentStatusEnum.IN_TRANSIT:
      return 60;
    case ShipmentStatusEnum.IN_ORIGIN:
      return 40;
    case ShipmentStatusEnum.RFQ_SUBMITTED:
      return 25;
    case ShipmentStatusEnum.CREATED:
    default:
      return 0;
  }
};

export const formatTitle = (shipment: IShipmentEntity) =>
  `${shipment.slug} ${
    shipment.shipmentReference ? '(' + shipment.shipmentReference + ')' : ''
  }`;
