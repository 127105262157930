import { Button, Col, Form, Input, Row, Spin, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdateBasicInfosMutation } from 'api/user';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useEffect } from 'react';
import { IUserEntity } from 'types/entities/user.entity';
import { BasicInfosProps } from 'types/feature/profile.types';

import { displayErrors } from 'utils/error-notification';

const BasicInfos: FC<BasicInfosProps> = ({ user }) => {
  const { fullname = '', mobileNumber = '' } = user ?? {};

  const [updateInfos, { isLoading, isSuccess, isError, error }] =
    useUpdateBasicInfosMutation();

  const [form] = useForm<IUserEntity>();

  const onSave = async () => {
    try {
      const values: Partial<IUserEntity> = await form.validateFields();
      await updateInfos(values);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.warning({
        message: 'Error',
        description: 'make sure to fill all fields correctly !',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Your information updated successfully',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error);
    }
  }, [isError, error]);

  return (
    <DashboardCard headerTitle="Personal Information">
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 24 }}
        initialValues={{
          fullname,
          mobileNumber, // : formulatePhone(mobileNumber as string),
        }}
      >
        <Row gutter={[12, 12]} align="middle">
          <Col span={10}>
            <Form.Item
              label="Full Name"
              name="fullname"
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Field is required',
                },
              ]}
            >
              <Input placeholder="Full name" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Phone number"
              name={'mobileNumber'}
              rules={[
                {
                  required: true,
                  message: 'Please enter your Phone Number!',
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value.length > 9) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Should be minimum 10 digits!');
                  },
                }),
              ]}
            >
              <Input type="tel" />
            </Form.Item>
          </Col>
          <Button
            type="primary"
            className="save-btn"
            onClick={onSave}
            disabled={isLoading}
            icon={isLoading && <Spin size="small" />}
          >
            Save
          </Button>
        </Row>
      </Form>
    </DashboardCard>
  );
};

export default BasicInfos;
