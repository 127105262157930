import { LeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import { title } from 'process';
import React, { FC } from 'react';
import {
  IOverviewScreenProps,
  ShipmentCreationScreenEnum,
} from 'types/feature/shipment-onboarding';

import { ReactComponent as ShipmentIntroIcon } from '../../../../assets/onboarding/shipment-intro.svg';

const OverviewScreen: FC<IOverviewScreenProps> = ({ onScreenChange }) => {
  const navigateToAiFormScreen = () =>
    onScreenChange(ShipmentCreationScreenEnum.AI_FORM_SCREEN);

  return (
    <div className="sh-onboarding-overview-screen">
      <ShipmentIntroIcon />
      <Typography.Title level={4} className="font-medium mt-0">
        Bigger Savings, Less Effort.
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        Say goodbye to email ping-pong with one central place to communicate
        with every supplier – and enjoy lower rates with our competitive bidding
        experience.
      </Typography.Paragraph>
      <Button onClick={navigateToAiFormScreen} type="primary">
        Continue
      </Button>
    </div>
  );
};

export default OverviewScreen;
