import Icon from '@ant-design/icons';
import { Form, Row, Col, Button, Typography, Modal, notification } from 'antd';
import { useDeletePurchaseOrderItemByIdMutation } from 'api/purchase-order';
import { CARGO_UNITS_INIT_STATE } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import { Panel } from 'app/design-system/Accordion';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { setOrderLinesForm } from 'state/slices/create-purchase-order.slice';
import { IPurchaseOrderItemEntity } from 'types/entities/purchase-order.entity';
import {
  IOrderLinesForm,
  IOrderLinesFormProps,
  ITotalItemsState,
} from 'types/feature/purchase-orders.types';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as EnterIcon } from '../../../../assets/iconsNew/enter-Icons.svg';
import { ReactComponent as PlusOutlinedIcon } from '../../../../assets/iconsNew/plus-icon-outlined.svg';
import { computeTotals } from '../order-creation.utils';
import OrderLinesFormFields from './OrderLinesFormFields';
import { computeOrderLineCargoReadyDate } from './helper';

const { Text } = Typography;
const { confirm } = Modal;

const OrderLinesForm: FC<IOrderLinesFormProps> = ({
  form,
  defaultValues,
  isEditView,
  footer,
}) => {
  const dispatch = useDispatch();
  const createPurchaseOrderState = useSelector(
    (state: RootState) => state.createPurchaseOrder
  );
  const [activeKey, setActiveKey] = useState<string>('item-0');

  const [total, setTotal] = useState<ITotalItemsState>({
    volume: 0,
    weight: 0,
    price: '0',
    quantity: 0,
    boxes: 0,
  });
  const [deleteLineItem] = useDeletePurchaseOrderItemByIdMutation();

  const onDeleteClick = (id: string, remove: () => void) => {
    if (isEditView && id) {
      confirm({
        title: 'Confirmation',
        okText: 'Delete',
        okType: 'danger',

        icon: null,
        content: 'Are you sure you want to delete this order item ?',
        onOk: async () => {
          await deleteLineItem({ id, poId: defaultValues.purchaseOrderId })
            .unwrap()
            .then(() => {
              remove();
              notification.success({
                message: 'Order Item Deleted',
                description: `The selected order line item has been deleted successfully ! `,
              });
            })
            .catch((error) => displayErrors(error));
        },
      });
    }
  };

  const calculateTotals = useCallback((items: IPurchaseOrderItemEntity[]) => {
    const totals = computeTotals(items ?? []);
    setTotal(totals);
  }, []);

  const updateOrderItemsTotals = (items: IPurchaseOrderItemEntity[]) => {
    const newItems = items?.map((item: IPurchaseOrderItemEntity) => {
      return {
        ...item,
        totalAmount: (item.requestedQuantity ?? 0) * (item.unitPrice ?? 0),
      };
    });
    form.setFieldValue('items', newItems);
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onFormValuesChange = (changedValue: any, values: IOrderLinesForm) => {
    const totals = computeTotals(values.items ?? []);
    setTotal(totals);
    updateOrderItemsTotals(values.items);
  };

  useEffect(() => {
    if (createPurchaseOrderState.orderLinesForm?.items) {
      calculateTotals(createPurchaseOrderState.orderLinesForm?.items);
      updateOrderItemsTotals(createPurchaseOrderState.orderLinesForm?.items);
    } else if (defaultValues.items) {
      calculateTotals(defaultValues.items);
      updateOrderItemsTotals(defaultValues.items);
    }
    // eslint-disable-next-line
  }, [defaultValues, calculateTotals]);

  const handleKeyChange = async (nextKey: string, currentKey: string) => {
    const orderLineValues = await form.validateFields();

    dispatch(
      setOrderLinesForm({
        ...orderLineValues,
        items: orderLineValues.items.map((item, itemIndex) => {
          return {
            ...item,
            cargoReadyDate: computeOrderLineCargoReadyDate(item.cargoReadyDate),
          };
        }),
      })
    );

    if (nextKey) {
      setActiveKey(nextKey);
    }
  };

  const renderOrderItems = () => (
    <>
      <Row>
        <Col span={24}>
          <Form.List
            name="items"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error('Insert at least 1 order line')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Panel
                    showHeader={false}
                    onClick={handleKeyChange}
                    type="outlined"
                    key={field.key}
                    activeKey={activeKey}
                    item={{
                      key: `item-${index}`,
                      label: `Item # ${index}`,
                      index,
                      children: (
                        <OrderLinesFormFields
                          title={`Item # ${index}`}
                          fields={fields}
                          field={field}
                          onRemoveField={() => {
                            const id = form.getFieldValue([
                              'items',
                              field.name,
                              'id',
                            ]);

                            if (id) onDeleteClick(id, () => remove(field.name));
                            else remove(field.name);
                          }}
                        />
                      ),
                    }}
                    footer={(index, currentKey) => (
                      <Button
                        onClick={() =>
                          handleKeyChange(`item-${index + 1}`, currentKey)
                        }
                        icon={<EnterIcon />}
                      >
                        Confirm Details
                      </Button>
                    )}
                  />
                ))}

                <Button
                  className="my-3 float-right"
                  type="default"
                  onClick={() =>
                    add({
                      ...CARGO_UNITS_INIT_STATE[0],
                      cargoReadyDate:
                        // TODO: Types refactoring required.
                        computeOrderLineCargoReadyDate(
                          defaultValues.items[0].cargoReadyDate
                        ),
                    })
                  }
                  icon={<Icon component={PlusOutlinedIcon} size={25} />}
                >
                  Add Item
                </Button>
              </>
            )}
          </Form.List>
        </Col>
      </Row>

      <Row justify="end" align="middle" className="my-2">
        <div className="units-footer__wrapper transparent">
          <Text className="units-footer__title light">Total Boxes</Text>
          <div className="units-footer__amount-wrapper transparent">
            <Text className="units-footer__amount">{total.boxes}</Text>
          </div>
        </div>
        <div className="units-footer__wrapper transparent">
          <Text className="units-footer__title light">Total Quantity</Text>
          <div className="units-footer__amount-wrapper transparent">
            <Text className="units-footer__amount">{total.quantity}</Text>
          </div>
        </div>
        <div className="units-footer__wrapper transparent">
          <Text className="units-footer__title light">Total Weight</Text>
          <div className="units-footer__amount-wrapper transparent">
            <Text className="units-footer__amount transparent">
              {total.weight} Kg
            </Text>
          </div>
        </div>
        <div className="units-footer__wrapper transparent">
          <Text className="units-footer__title light">Total Volume</Text>
          <div className="units-footer__amount-wrapper transparent">
            <Text className="units-footer__amount transparent">
              {total.volume} cm³
            </Text>
          </div>
        </div>
        <div className="units-footer__wrapper transparent">
          <Text className="units-footer__title light">Total Price</Text>
          <div className="units-footer__amount-wrapper transparent">
            <Text className="units-footer__amount transparent">
              {total.price ?? 0}
            </Text>
          </div>
        </div>
      </Row>
    </>
  );

  return (
    <Form
      {...(defaultValues && { initialValues: defaultValues })}
      layout="vertical"
      form={form}
      onValuesChange={onFormValuesChange}
      scrollToFirstError
    >
      {renderOrderItems()}
    </Form>
  );
};

export default OrderLinesForm;
