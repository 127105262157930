import dayjs from 'dayjs';
import { IDashBoardInvoiceEntity } from 'types/entities/invoice.entity';

export const getSumArray = (array: IDashBoardInvoiceEntity[] | undefined) => {
  if (!array) {
    return [];
  } else {
    return array.map((item) => (item.sum ? item.sum : 0));
  }
};
export const getWeekStartArray = (
  array: IDashBoardInvoiceEntity[] | undefined
) => {
  if (!array) {
    return [];
  } else {
    return array.map((item) => dayjs(item.week_start).format('YY/MM/DD'));
  }
};
export const getCountArray = (array: IDashBoardInvoiceEntity[] | undefined) => {
  if (!array) {
    return [];
  } else {
    return array.map((item) => parseInt(item.count));
  }
};
export const getThreshHold = (array: number[]) => {
  return Math.max(...array) * 0.75; //set the threshhold as 3 quarters of the maximum amount to render the labels accordignly;
};
