import { Col, Form, Input, InputNumber, Row } from 'antd';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import React, { FC } from 'react';
import { IDimensionFormProps } from 'types/feature/create-shipment.types';

const DimensionForm: FC<IDimensionFormProps> = ({
  field,
  isFieldActionsDisabled,
}) => {
  return (
    <Row key={field.key} gutter={[12, 12]}>
      <Col span={8}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Weight (kg)"
          name={[field.name, 'weight']}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'Please input the weight or delete this line.',
            },
            () => ({
              validator(rule, value) {
                if (value > 0) {
                  return Promise.resolve();
                }

                return Promise.reject("Weight can't be equal or less than 0");
              },
            }),
          ]}
        >
          <InputNumber
            placeholder="Enter weight"
            className="custom-input"
            precision={2}
            type="number"
            disabled={isFieldActionsDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          name={[field.name, 'volume']}
          label="Volume (cm³)"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: 'Please input the total quantity or delete this line.',
            },
            () => ({
              validator(rule, value) {
                if (value > 0) {
                  return Promise.resolve();
                }

                return Promise.reject("Volume can't be equal or less than 0");
              },
            }),
          ]}
        >
          <InputNumber
            placeholder="Enter Quantity"
            className="custom-input"
            precision={2}
            type="number"
            disabled={isFieldActionsDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          {...formItemLayoutWithOutLabel}
          label="Chargeable Weight (kg)"
          name={[field.name, 'chargeableWeight']}
        >
          <Input placeholder="Enter chargeable weight" disabled />
        </Form.Item>
      </Col>
      <Form.Item name={[field.name, 'id']} hidden={true}>
        <Input />
      </Form.Item>
    </Row>
  );
};

export default DimensionForm;
