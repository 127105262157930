const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    md: { span: 10 },
  },
};

const formItemLayoutWithOutLabel = {
  labelCol: {
    span: 24,
  },
  wrapperCol: { span: 24, offset: 0 },
};

export { formItemLayout, formItemLayoutWithOutLabel };
