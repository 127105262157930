import {
  IAddInvoiceByShipmentIdRequestBody,
  IInvoiceCollaboratorByShipmentRequestBody,
  IInvoiceCollaboratorByShipmentResp,
  IInvoiceCollaboratorResponseBody,
  IInvoiceEntity,
  IRecentInvoiceCollaboratorResp,
} from 'types/entities/invoice.entity';
import { IUserEntity } from 'types/entities/user.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const invoiceCollaboratorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceCollaboratorByShipment: build.query<
      ApiResponse<IInvoiceCollaboratorByShipmentResp>,
      IInvoiceCollaboratorByShipmentRequestBody
    >({
      query: ({ invoiceId, search }) => ({
        url: `invoice-collaborator/shipment/${invoiceId}`,
        params: { search },
      }),
      providesTags: ['InvoiceCollaboratorByShipment'],
    }),

    getRecentInvoiceCollaborator: build.query<
      ApiResponse<IRecentInvoiceCollaboratorResp>,
      IInvoiceCollaboratorByShipmentRequestBody
    >({
      query: ({ invoiceId, search }) => ({
        url: `invoice-collaborator/recent/${invoiceId}`,
        params: { search },
      }),
      providesTags: ['InvoiceCollaboratorByShipment'],
    }),

    getInvoiceCollaboratorById: build.query<
      ApiResponse<IInvoiceCollaboratorResponseBody>,
      string
    >({
      query: (invoiceId) => `/invoice-collaborator/${invoiceId}`,
      providesTags: ['InvoiceCollaborator'],
    }),

    getPotentialAssignee: build.query<
      ApiResponse<{ users: IUserEntity[] }>,
      string
    >({
      query: (invoiceId) => `/invoice-collaborator/assignee/${invoiceId}`,
    }),

    deleteInvoiceCollaboratorById: build.mutation<IInvoiceEntity, string>({
      query: (invoiceId) => ({
        url: `/invoice-collaborator/${invoiceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InvoiceCollaborator'],
    }),

    inviteInvoiceCollaborator: build.mutation<
      IInvoiceEntity,
      IAddInvoiceByShipmentIdRequestBody
    >({
      query: (payload) => ({
        url: `/invoice-collaborator/${payload.invoiceId}`,
        method: 'POST',
        body: payload.body,
      }),
      invalidatesTags: [
        'InvoiceCollaboratorByShipment',
        'InvoiceCollaborator',
        'ShipmentsById',
        'Collaborators',
        'Team',
        'ShipmentCollaborators',
      ],
    }),
  }),
});

export const {
  useGetInvoiceCollaboratorByShipmentQuery,
  useGetRecentInvoiceCollaboratorQuery,
  useGetInvoiceCollaboratorByIdQuery,
  useInviteInvoiceCollaboratorMutation,
  useDeleteInvoiceCollaboratorByIdMutation,
  useGetPotentialAssigneeQuery,
} = invoiceCollaboratorApi;
