import { Card, Typography } from 'antd';
import { useGetInvoiceDashboardQuery } from 'api/invoice-analytics';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  LineController,
  Legend,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { FC } from 'react';
import { Chart } from 'react-chartjs-2';
import { CurrencyEnum } from 'types/entities/fee.entity';

import { formatShortenAmount } from 'utils/monetary-utils';

import {
  getCountArray,
  getSumArray,
  getThreshHold,
  getWeekStartArray,
} from './helper';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  BarController,
  LineController,
  Tooltip
);

const SubmittedInvoicesChart: FC = () => {
  const { data: dashboardData } = useGetInvoiceDashboardQuery();
  const data = {
    labels: getWeekStartArray(dashboardData?.data),
    datasets: [
      {
        label: 'Invoice Count',
        data: getCountArray(dashboardData?.data),
        backgroundColor: '#B5A2FF',
        type: 'bar' as const,
        yAxisID: 'invoiceCount',
        order: 2,
        datalabels: {
          display: false,
        },
      },
      {
        label: 'Invoice Amount',
        data: getSumArray(dashboardData?.data),
        backgroundColor: '#5A31FF',
        type: 'line' as const,
        yAxisID: 'invoiceAmount',
        order: 1,
        datalabels: {
          color: '#000000',
          align: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return value < getThreshHold(getSumArray(dashboardData?.data))
              ? 'start'
              : 'end';
          },
          offset: -25,
          formatter: (value: number) => {
            return formatShortenAmount(
              value,
              dashboardData?.currency || CurrencyEnum.USD
            );
          },
        },
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => context.dataset.label + ': ' + context.parsed.y,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Submitted At',
          padding: { bottom: 0 },
          color: '#000000',
        },
      },
      invoiceCount: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Invoice Count',
          color: '#000000',
        },
      },
      invoiceAmount: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Invoice Amount',
          color: '#000000',
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value) {
            return formatShortenAmount(
              value,
              dashboardData?.currency || CurrencyEnum.USD
            );
          },
        },
      },
    },
  };

  return (
    <Card className="invoice-chart">
      <div>
        <Typography.Title className="invoice-card-title" level={5}>
          Submitted Invoices
        </Typography.Title>
        <Chart
          type="line"
          data={data}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    </Card>
  );
};

export default SubmittedInvoicesChart;
