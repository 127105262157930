import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Typography,
  notification,
} from 'antd';
import { useDeleteShipmentsByIdsMutation } from 'api/shipment';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IContainerShipmentEntity,
  ICreateShipmentsFromTrackingProps,
} from 'types/entities/container.entity';

import { displayErrors } from 'utils/error-notification';
import { formatOriginToDest, formatShipmentState } from 'utils/format-fields';

const { confirm } = Modal;
const CreateShipmentsFromTracking: FC<ICreateShipmentsFromTrackingProps> = ({
  InvalidTrackingNumbers,
  existingShipments,
  createdShipments,
  onCloseModal,
  onPreviousStep,
}) => {
  const navigate = useNavigate();
  const [deleteShipments] = useDeleteShipmentsByIdsMutation();
  const invalidNumbersCount = InvalidTrackingNumbers.length;
  const isMultipleShipments =
    existingShipments.length + createdShipments.length > 1;

  const onConfirm = (shipments: IContainerShipmentEntity[]) => {
    navigate(
      shipments.length > 1 ? '/shipments' : `/shipments/SH-${shipments[0].slug}`
    );
    onCloseModal();
    onPreviousStep();
  };

  const onCancel = () => {
    if (createdShipments.length < 1) {
      onCloseModal();
      onPreviousStep();
      return;
    }
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: `Canceling this action will delete ${
        isMultipleShipments ? 'these shipments' : 'this shipment'
      }. Are you sure you want to proceed ?`,
      onOk: async () => {
        const shipmentIds = createdShipments.map((shipment) => shipment.id);
        try {
          await deleteShipments(shipmentIds)
            .unwrap()
            .then(() => {
              notification.success({
                message: 'Shipment(s) Deleted',
                description: `${
                  isMultipleShipments ? 'Shipments have' : 'Shipment has'
                } been deleted successfully ! `,
              });
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'Failed to delete shipments' });
        }
        onCloseModal();
        onPreviousStep();
      },
    });
  };
  const renderShipments = () => {
    return (
      <Space size="large" direction="vertical">
        <div className="container-shipments-wrapper">
          {createdShipments.map((shipment) => (
            <div key={shipment.trackingNumber}>
              <Divider />
              <Row
                align={'middle'}
                justify={'space-between'}
                className="container-shipment-item"
              >
                <Col className="tracking">
                  <Typography.Text>{shipment.trackingNumber}</Typography.Text>

                  <Typography.Text strong>
                    {formatOriginToDest(shipment.destination, shipment.origin)}
                  </Typography.Text>
                </Col>
                <Col>{formatShipmentState(shipment.status)}</Col>
              </Row>
            </div>
          ))}
        </div>
      </Space>
    );
  };

  return (
    <>
      <ModalHeader
        title="Shipments Imported"
        {...(createdShipments.length > 0 && {
          subTitle: `The following ${
            createdShipments.length > 1 ? 'shipments have' : 'shipment has'
          } been created for you.`,
        })}
      />

      <div className="tracking-input-content">
        {createdShipments.length > 0 && renderShipments()}

        {existingShipments.length > 0 && (
          <div className="banner-wrapper-existing">
            <Typography.Text strong>
              The following tracking numbers already have Shipments associated
              to them:
              <ul className="tracking-numbers-list">
                {existingShipments.map((shipment, index) => (
                  <li
                    className="bullet-point"
                    key={`${shipment.trackingNumber}-${index}`}
                  >
                    {shipment.trackingNumber}
                  </li>
                ))}
              </ul>
            </Typography.Text>
          </div>
        )}
        {invalidNumbersCount > 0 && (
          <div className="banner-wrapper-invalid">
            <Typography.Text strong type="secondary">
              {`We couldn’t find the following tracking number${
                invalidNumbersCount > 1
                  ? 's, please double check them'
                  : ', please double check it'
              }and try again`}
              <ul className="tracking-numbers-list">
                {InvalidTrackingNumbers.map((trackingNumber, index) => (
                  <li className="bullet-point" key={index}>
                    {trackingNumber}
                  </li>
                ))}
              </ul>
            </Typography.Text>
          </div>
        )}
        <div className="footer">
          <Button onClick={onCancel}>Cancel</Button>

          {createdShipments.length > 0 ? (
            <Button type="primary" onClick={() => onConfirm(createdShipments)}>
              Confirm and Save
            </Button>
          ) : (
            existingShipments.length > 0 && (
              <Button
                type="primary"
                onClick={() => onConfirm(existingShipments)}
              >
                Go To Shipment
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default CreateShipmentsFromTracking;
