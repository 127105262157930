import { Button, Input, Modal } from 'antd';
import { useUpdateInvoiceByIdMutation } from 'api/invoice';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { InvoiceStatusEnum } from 'types/feature/invoice.types';
import { IDeclineInvoiceProps } from 'types/feature/invoice.types';

import { updateInvoiceStatusEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import './style.scss';

const DeclineInvoiceModal: FC<IDeclineInvoiceProps> = ({
  isModalOpen,
  invoice,
  setIsDeclineOpen,
  getUpdatedInvoices,
  updateInvoicesArgs,
}) => {
  const [cancelReason, setCancelReason] = useState<string>('');
  const currentUser = useSelector((state: RootState) => state.user.user);

  const [updateInvoice, { isLoading }] = useUpdateInvoiceByIdMutation();

  const handleCloseModal = () => {
    setIsDeclineOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelReason(event.target.value);
  };
  const handleDecline = async () => {
    try {
      await updateInvoice({
        id: invoice.id,
        invoice: {
          status: InvoiceStatusEnum.DECLINED,
          cancelReason,
        },
      }).unwrap();

      updateInvoiceStatusEvent({
        user_id: currentUser?.id || '',
        email: currentUser?.email || '',
        company: currentUser?.company?.name || '',
        company_id: currentUser?.companyId || '',
        invoice_id: invoice.id,
        shipment_id: invoice.shipmentId,
        status: InvoiceStatusEnum.DECLINED,
      });
      if (updateInvoicesArgs && getUpdatedInvoices) {
        getUpdatedInvoices(updateInvoicesArgs);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'error on declining the invoice' });
    }
    setIsDeclineOpen(false);
    setCancelReason('');
  };
  return (
    <>
      <Modal
        className="decline-modal"
        okText="Submit"
        onCancel={handleCloseModal}
        open={isModalOpen}
        title="Invoice Rejected"
        footer={[
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            onClick={handleDecline}
          >
            Submit
          </Button>,
        ]}
      >
        <p>Please share the reason to reject the invoice</p>
        <Input value={cancelReason} onChange={handleInputChange} />
      </Modal>
    </>
  );
};

export default DeclineInvoiceModal;
