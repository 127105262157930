/* eslint-disable */
import { InboxOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Modal,
  Space,
  Typography,
  UploadProps,
  message,
  notification,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { useUploadFilesMutation } from 'api/proposal';
import {
  useDeleteFilesExternalPropsalMutation,
  useUploadFilesExternalPropsalMutation,
} from 'api/proposal-external.api';
import DashboardCard from 'app/design-system/DashboardCard';
import FilePreview from 'app/design-system/FilePreview';
import React, { FC } from 'react';
import { OtherDetailsProps } from 'types/feature/proposals.types';

import { displayErrors } from 'utils/error-notification';

const { TextArea } = Input;
const { Title } = Typography;
const { confirm } = Modal;
const OtherDetails: FC<OtherDetailsProps> = ({
  proposalId,
  files,
  email,
  isExternalForwarder,
}) => {
  const [deleteDocumentExternal] = useDeleteFilesExternalPropsalMutation();
  const [uploadDocumentExternal] = useUploadFilesExternalPropsalMutation();
  const [uploadDocument] = useUploadFilesMutation();

  const onDelete = (url: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this file?',
      onOk: async () => {
        await deleteDocumentExternal({
          params: { proposalId, email },
          url,
        })
          .unwrap()
          .then(() => {
            message.success(`File deleted successfully.`);
          })
          .catch((error) => {
            displayErrors(error, { title: 'Delete File Error' });
          });
      },
    });
  };
  const uploadExternalProposalFiles = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('files', file);

    uploadDocumentExternal({ files: formdata, params: { proposalId, email } })
      .unwrap()
      .then((payload) => {
        onSuccess(payload);
      })
      .catch((error) => onError(error));
  };

  const uploadProposalFiles = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formdata = new FormData();
    formdata.append('files', file);
    uploadDocument({ files: formdata, params: { proposalId } })
      .unwrap()
      .then((payload) => {
        onSuccess(payload);
      })
      .catch((error) => onError(error));
  };

  const props: UploadProps = {
    multiple: true,
    maxCount: 5,
    customRequest: isExternalForwarder
      ? uploadExternalProposalFiles
      : uploadProposalFiles,
    onRemove(file) {
      onDelete(file.response.data[0]);
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        displayErrors(info.file?.error, { title: 'Upload Document Error' });
      }
    },
    showUploadList: {
      showRemoveIcon: isExternalForwarder,
      showPreviewIcon: true,
    },
  };

  return (
    <DashboardCard
      headerTitle="Attach any file (optional)"
      className="clear-background"
      large
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Attach Documents (Optional)</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>

      {files?.length > 0 && (
        <div className="proposal-card">
          <Title level={5}>Quote Documents</Title>

          <Space wrap>
            {files.map((file) => (
              <FilePreview
                key={file.id}
                imageUrl={file.file}
                size="small"
                onRemove={() => onDelete(file.id)}
              />
            ))}
          </Space>
        </div>
      )}
      <Typography.Title level={5}>Additional Information</Typography.Title>
      <Form.Item name={'note'}>
        <TextArea
          rows={4}
          placeholder="Add additional notes, such as Terms & Conditions, if necessary"
        />
      </Form.Item>
    </DashboardCard>
  );
};
export default OtherDetails;
