import { ConfigProvider, notification } from 'antd';
import { useCreatePurchaseOrdersMutation } from 'api/purchase-order';
import PDFViewer from 'app/design-system/PDFViewer';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import 'react-reflex/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { APP_THEME_CONST } from 'style-config';
import { IPurchaseOrderCreateBody } from 'types/entities/purchase-order.entity';

import { submitPurchaseOrderEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import OrderCreationForm from './CreationForm';
import { findHiglightedFileUrl } from './UploadPdfModal/utils/parsed-files.helper';

const PurchaseOrderCreationCompact: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.user);

  const [createPurchaseOrder, { error, isError, isSuccess, isLoading }] =
    useCreatePurchaseOrdersMutation();

  const onFinish = async (purchaseOrder: IPurchaseOrderCreateBody) => {
    try {
      await createPurchaseOrder(purchaseOrder);
      submitPurchaseOrderEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        company_id: user?.companyId,
      });
    } catch (error) {
      notification.warning({ message: 'Unknown Error !' });
    }
  };

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Purchase order creation Error' });
    }

    return;
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Purchase order created !',
        description: 'You can now view or edit from main page !',
      });
      navigate('/purchase-orders');
    }
  }, [isSuccess, navigate]);

  const isManualCreatedPO = !location?.state?.order;

  return (
    <ConfigProvider theme={APP_THEME_CONST}>
      <div className="app-theme-wrapper">
        {isManualCreatedPO ? (
          <OrderCreationForm
            defaultPurchaseOrder={location?.state?.order ?? null}
            file={location?.state?.files ?? null}
            isEditView={false}
            onFinish={onFinish}
            isProcessing={isLoading}
            onCancel={undefined}
            createBookingFlow={false}
            showItemTag={
              !!(location?.state?.order.purchaseOrderItems.length > 0)
            }
          />
        ) : (
          <ReflexContainer orientation="vertical" windowResizeAware={true}>
            <ReflexElement
              className="left-pane"
              flex={location?.state?.files ? 0.6 : 1}
            >
              <OrderCreationForm
                defaultPurchaseOrder={location?.state?.order ?? null}
                file={location?.state?.files ?? null}
                isEditView={false}
                onFinish={onFinish}
                isProcessing={isLoading}
                onCancel={undefined}
                createBookingFlow={false}
                showItemTag={
                  !!(location?.state?.order.purchaseOrderItems.length > 0)
                }
              />
            </ReflexElement>
            <ReflexSplitter />

            <ReflexElement className="right-pane">
              <PDFViewer
                file={findHiglightedFileUrl(location?.state?.files)}
                className="right-pane__pdf-viewer"
              />
            </ReflexElement>
          </ReflexContainer>
        )}
      </div>
    </ConfigProvider>
  );
};
export default PurchaseOrderCreationCompact;
