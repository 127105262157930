export enum IDocumentTypeEnum {
  FREIGHT_INVOICE = 'freight_invoice',
  DELIVERY_ORDER = 'delivery_order',
  COMMERCIAL_INVOICE = 'commercial_invoice',
  PACKING_LIST = 'packing_list',
  PURCHASE_ORDER = 'purchase_order',
  FINAL_MBL = 'final_mbl',
  DRAFT_MBL = 'draft_mbl',
  FINAL_HBL = 'final_hbl',
  DRAFT_HBL = 'draft_hbl',
  SHIPMENT_INVOICE = 'shipment_invoice',
  CUSTOMS_DUTY = 'customs_duty',
  CERTIFICATE_OF_ORIGIN = 'certificate_of_origin',
  CERTIFICATE_OF_ANALYSIS = 'certificate_of_analysis',
  CERTIFICATE_OF_CONFORMITY = 'certificate_of_conformity',
  PROFORMA_INVOICE = 'proforma_invoice',
  HEALTH_CERTIFICATE = 'health_certificate',
  ORGANIC_CERTIFICATE = 'organic_certificate',
  BATCH_CERTIFICATE = 'batch_certificate',
  MSDS = 'msds',
  AIRWAY_BILL = 'airway_bill',
  SHIPMENT_INSTRUCTION = 'shipment_instruction',
  PORT_CHARGES_INVOICE = 'port_charges_invoice',
  QUOTATION = 'quotation',
  CUSTOMS_SUMMARY_SHEET = 'customs_summary_sheet',
  HS_CODE = 'hs_codes',
  SHIPPING_ORDER = 'shipping_order',
  OTHER = 'other',
}

export enum IDocumentExtensionEnum {
  EXCEL = 'xlsx',
}

export interface IDocumentEntity {
  id: string;
  name: string;
  type: IDocumentTypeEnum;
  createdAt: string;
  shipmentSlug: number;
  file: string;
  shipmentId: string;
}

export interface IDocumentsResponseBody {
  items: IDocumentEntity[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}
