import { Button, Form, Input, Row, Spin, Typography, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useResetPasswordMutation } from 'api/auth';
import React, { FC, useCallback, useEffect } from 'react';
import { ResetPasswordRequest } from 'types/entities/auth.entity';
import { IResetPasswordProps } from 'types/feature/auth.types';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as BeeboltLinedLogo } from '../../../../assets/appIcons/beebolt-icon.svg';
// style
import '../LoginForm/login-form.style.scss';

const ResetPasswordExternal: FC<IResetPasswordProps> = ({
  navigateToMainPage,
  resendVerificationCode,
}) => {
  //oobCode
  const [resetPassword, { isSuccess, isError, error, isLoading }] =
    useResetPasswordMutation();
  const [form] = useForm<ResetPasswordRequest>();

  const onSave = async () => {
    try {
      const values: ResetPasswordRequest = await form.validateFields();
      await resetPassword(values);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.warning({
        message: 'Error',
        description: 'make sure to fill all fields correctly !',
      });
    }
  };

  const onSuccess = useCallback(() => {
    form.resetFields();
    navigateToMainPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Password updated successfully',
      });
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error);
    }
  }, [isError, error]);

  // component
  return (
    <div className="auth-form">
      <BeeboltLinedLogo />
      <Typography.Title level={2}>Reset My Password!</Typography.Title>

      <Form layout="vertical" form={form}>
        <Form.Item
          label="Verification Code"
          name="oobCode"
          key="oobCode"
          rules={[
            {
              required: true,
              message: 'Please enter your code!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item noStyle>
          <Row justify="end">
            <Button type="link" onClick={resendVerificationCode}>
              Resend verification code ?
            </Button>
          </Row>
        </Form.Item>
        <Form.Item
          label="New Password"
          name="password"
          key="password"
          rules={[
            {
              required: true,
              message: 'Please enter your password!',
            },
            {
              min: 8,
              message: 'Password needs to be at least 8 characters long !',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="repassword"
          key="repassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="auth-form__login-btn"
            onClick={onSave}
            disabled={isLoading}
            icon={isLoading && <Spin size="small" />}
          >
            Reset My Password
          </Button>
        </Form.Item>
      </Form>

      <Row align="middle" justify="center" className="auth-form__signup">
        <Typography.Text>Go back to login page ?</Typography.Text>
        <Button type="link" onClick={navigateToMainPage}>
          Log In
        </Button>
      </Row>
    </div>
  );
};

export default ResetPasswordExternal;
