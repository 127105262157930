import { Badge, Divider, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { CardProps } from 'types/feature/dashboard.types';

// style
import './card.scss';

const DashboardCard: FC<CardProps> = ({
  children,
  headerTitle,
  subTitle,
  headerInfo,
  footer,
  large,
  fitContent,
  rightAccessory,
  noMargin,
  className,
  style,
  isMessages,
  flat,
}) => {
  const displayHeader = !!headerTitle || !!subTitle;
  return (
    <div
      className={`card-wrapper ${className}  ${
        large ? 'card-wrapper-lg' : ''
      } ${noMargin ? 'card-wrapper-noMargin' : ''} ${
        fitContent ? 'card-wrapper-noLimit' : ''
      } ${flat ? 'card-wrapper-flat' : ''}`}
      style={style}
    >
      {displayHeader && (
        <Row className={!isMessages ? 'card-header' : 'messages-card-header'}>
          <div className="card-header__title-wrapper">
            <Typography.Title level={5} className="card-header__title">
              {headerTitle}
            </Typography.Title>
            {subTitle && (
              <Typography.Paragraph className="card-header__sub-title">
                {subTitle}
              </Typography.Paragraph>
            )}
          </div>
          {rightAccessory && <>{rightAccessory}</>}
          {headerInfo && <Badge count={headerInfo ?? 1} color="blue" />}
          {isMessages && <Divider className="messages-divider" />}
        </Row>
      )}
      <div className="card-content">{children}</div>
      {footer && <Row className="card-footer">{footer}</Row>}
    </div>
  );
};

export default DashboardCard;
