import {
  Button,
  Card,
  Dropdown,
  Empty,
  MenuProps,
  Modal,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import {
  useDeleteFileByIdMutation,
  useGetFilesByShipmentQuery,
} from 'api/file';
import { useCreateInvoiceFromUrlMutation } from 'api/invoice';
import { useExtractDocs } from 'providers/extract-docs-provider';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { IFileEntity } from 'types/entities/files.entity';
import { IShipmentDocumentsProps } from 'types/feature/shipments.types';

import { isViewer } from 'utils/collaborators';
import { downloadFile } from 'utils/download-helper';
import { displayErrors } from 'utils/error-notification';
import {
  hasShipmentStarted,
  isShipmentDocumentUrl,
} from 'utils/shipment-helper';

import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg';
import { ReactComponent as EditScanFileIcon } from '../../../assets/icons/edit-scan-file-icon.svg';
import { ReactComponent as NotificationFileIcon } from '../../../assets/icons/file-icon-outlined-green.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import { ReactComponent as ParseInvoiceIcon } from '../../../assets/icons/parse-invoice-icon.svg';
import { ReactComponent as ScanFileIcon } from '../../../assets/icons/scan-file-icon-outlined.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share-icon-filled.svg';
import DocumentExtractor from '../DocumentExtractor';
import DocumentExtractLoader from '../DocumentExtractor/DocumentExtractLoader';
import SaveDocumentModal from './EditDocumentModal';
import ShareDocumentModal from './ShareDocumentModal';
import MultipleDocsUploadModal from './UploadDocumentModal/MultipleUploadModal';
import { documentTableConst } from './shipment-document.const';
import './shipment-documents.scss';

const { confirm } = Modal;

const ShipmentDocuments: FC<IShipmentDocumentsProps> = ({
  shipment: {
    id: shipmentId,
    slug: shipmentSlug,
    currentCollaborator,
    status: shipmentStatus,
  },
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const documentId = state?.documentId;

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  // Set's open state for extracted document edit view
  const [isExtractedDocsModalOpen, setIsExtractedDocsModalOpen] =
    useState<boolean>(false);
  // Set's open state for parsing loader
  const [isDocumentExtractLoaderOpen, setIsDocumentExtractLoaderOpen] =
    useState<boolean>(false);

  const [selectedFile, setSelectedFile] = useState<IFileEntity | null>(null);

  // Hooks to handle ASYC behaviour of docs extraction across app.
  const {
    onExtractDocument,
    isExtractingDocument,
    showDocExtractMinimize,
    setShowDocExtractMinimize,
    setSelectedDoc,
    extractedFileId = null,
    setExtractedFileId,
  } = useExtractDocs();

  const {
    data: filesData,
    isLoading,
    isError,
    refetch: refetchShipmentDocuments,
  } = useGetFilesByShipmentQuery(shipmentId, { skip: !shipmentId });

  const [deleteFile] = useDeleteFileByIdMutation();
  const [parseInvoiceFile, { isLoading: isParsing }] =
    useCreateInvoiceFromUrlMutation();

  const errorNotification = () => {
    notification.error({
      message: 'Error',
      description: 'Sorry, Unable to fetch shipment files',
    });
  };

  if (isError) errorNotification();

  const onDeleteClick = (id: string) => {
    confirm({
      title: 'Confirmation',
      okText: 'Delete',
      okType: 'danger',

      icon: null,
      content: 'Are you sure you want to delete this document ?',
      onOk: async () => {
        deleteFile(id)
          .unwrap()
          .then(
            async () => {
              message.success('The document was successfully removed !');
              await refetchShipmentDocuments();
            },
            (error) => displayErrors(error, { title: 'Delete Document Error' })
          );
      },
    });
  };

  const onParseInvoiceClick = async (file: IFileEntity) => {
    try {
      const resp = await parseInvoiceFile({
        shipmentId,
        fileName: file.name,
        fileUrl: file.file,
        fileId: file.id,
      }).unwrap();
      message.success(
        'The document was successfully parsed, you can proceed from finance tab now !'
      );
      navigate(`/shipments/${shipmentSlug}/invoice/${resp.data.id}`);
      // eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Parse Invoice Error' });
    }
  };

  const onShareClick = (file: IFileEntity) => {
    setIsShareModalOpen(true);
    setSelectedFile(file);
  };

  const onEditClick = (file: IFileEntity) => {
    setIsEditModalOpen(true);
    setSelectedFile(file);
  };

  const onShareClose = () => {
    setIsShareModalOpen(false);
    setSelectedFile(null);
  };

  const onEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedFile(null);
  };

  const onExtractedDocsClose = () => {
    setIsExtractedDocsModalOpen(false);
    setExtractedFileId(null);
    setShowDocExtractMinimize(false);
  };

  const onExtractDocumentClick = async (file: IFileEntity) => {
    try {
      setSelectedDoc(file);
      setIsDocumentExtractLoaderOpen(true);

      const extractedFileResp = await onExtractDocument({
        fileId: file.id,
      }).unwrap();

      // Set's fileId of newly created extracted file.
      if (extractedFileResp) {
        setExtractedFileId(extractedFileResp?.data?.fileId);
      }

      // closes docs parsing loader.
      setIsDocumentExtractLoaderOpen(false);

      // Check if the current location pathname matches the desired route.
      // Refresh documents list only if user is on shipment document view.
      const currentURL = window.location.href;

      if (isShipmentDocumentUrl(currentURL)) {
        // Show extracted document edit view if not minimized.
        if (!showDocExtractMinimize) {
          setIsExtractedDocsModalOpen(true);
        }

        await refetchShipmentDocuments();
      } else {
        notification.open({
          message: 'Document Parsed',
          description: 'The document for commercial invoice has been parsed.',
          icon: <NotificationFileIcon />,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on document extraction' });
      setIsDocumentExtractLoaderOpen(false);
      setIsExtractedDocsModalOpen(false);
      setShowDocExtractMinimize(false);
    }
  };

  const onExtractDocumentConfirmClick = (file: IFileEntity) => {
    confirm({
      title: 'Confirmation',
      okText: 'Yes',
      icon: null,
      content: `Are you sure you want to parse “${file?.name}”`,
      onOk: async () => {
        onExtractDocumentClick(file);
      },
    });
  };

  const onExtractDocumentEditClick = (fileId: string) => {
    setExtractedFileId(fileId);
    setIsExtractedDocsModalOpen(true);
  };

  const onDocumentExtractLoaderClose = () => {
    setIsDocumentExtractLoaderOpen(false);

    if (isExtractingDocument) {
      setShowDocExtractMinimize(true);
    }
  };

  useEffect(() => {
    // Scroll to the row with the specific document ID and add the highlighted-row class to it
    const row = document.querySelector(`.document-row-${documentId}`);
    row?.scrollIntoView({ behavior: 'smooth' });
    row?.classList.add('highlighted-row');
    setTimeout(() => {
      row?.classList.remove('highlighted-row');
    }, 2000);
  }, [filesData, documentId]);

  useEffect(() => {
    if (selectedFile) {
      const newSelectedfile = filesData?.find(
        (file) => file.id === selectedFile?.id
      );
      setSelectedFile(newSelectedfile ?? null);
    }
  }, [filesData]); // WHY IS THIS NOT DEPENDENT ON SELECTEDFILE TOO ?

  const documentActionItems: (file: IFileEntity) => MenuProps['items'] = (
    file: IFileEntity
  ) => [
    ...(file.canRemoveAccess
      ? [
          {
            key: 'edit',
            label: 'Edit',
            onClick: () => onEditClick(file),
            icon: <EditIcon />,
          },
        ]
      : []),
    ...(file.type === IDocumentTypeEnum.FREIGHT_INVOICE &&
    hasShipmentStarted(shipmentStatus)
      ? [
          {
            key: 'invoice',
            label: 'Move to Invoice',
            onClick: () => onParseInvoiceClick(file),
            icon: <ParseInvoiceIcon />,
          },
        ]
      : []),
    ...(file.canRemoveAccess
      ? [
          {
            key: 'delete',
            label: 'Delete',
            onClick: () => onDeleteClick(file.id),
            icon: <DeleteIcon />,
          },
          {
            key: 'share',
            label: 'Share',
            onClick: () => onShareClick(file),
            icon: <ShareIcon />,
          },
        ]
      : []),
  ];

  const renderDocumentTable: ColumnsType<IFileEntity> = [
    ...documentTableConst,
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, file) => (
        <Space>
          {file.isExtracted ? (
            <Tooltip title="Edit extracted document" trigger="hover">
              <Button
                type="text"
                icon={<EditScanFileIcon />}
                onClick={() => onExtractDocumentEditClick(file.id)}
              />
            </Tooltip>
          ) : (
            !file.isExtracted &&
            file.type === IDocumentTypeEnum.COMMERCIAL_INVOICE && (
              <Tooltip
                title={
                  isExtractingDocument
                    ? 'Document extraction in progress!'
                    : 'Extract document with AI'
                }
                trigger="hover"
              >
                <Button
                  disabled={isExtractingDocument}
                  type="text"
                  icon={<ScanFileIcon />}
                  onClick={() => onExtractDocumentConfirmClick(file)}
                />
              </Tooltip>
            )
          )}

          <Button
            type="text"
            icon={<DownloadIcon />}
            onClick={() =>
              downloadFile(file.file, file?.name ?? 'shipment-document')
            }
          />
          {!isViewer(currentCollaborator.accessLevel) && (
            <Dropdown
              trigger={['click']}
              menu={{ items: documentActionItems(file) }}
            >
              <Button type="text" icon={<MoreIcon />} />
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="shipment-document-wrapper">
      <Card
        loading={isLoading}
        title="Documents"
        extra={[
          <div key="upload">
            <MultipleDocsUploadModal
              shipmentSlug={shipmentSlug}
              shipmentId={shipmentId}
              actionButton={{ text: 'Upload Documents' }}
            />
          </div>,
        ]}
      >
        <div>
          {filesData && filesData.length > 0 ? (
            <Table
              rowKey="id"
              columns={renderDocumentTable}
              dataSource={filesData}
              rowClassName={(record) => `document-row-${record.id}`}
              pagination={false}
              size="middle"
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Card>

      <Modal
        title="🤖 Parsing Invoice !"
        open={isParsing}
        footer={null}
        closable={false}
        confirmLoading={isParsing}
      >
        <Space
          direction="vertical"
          className="loader-content-wrapper"
          size={[16, 16]}
        >
          <Typography.Text>
            The system is parsing your invoice file, it will take some time.
            Please wait for few moment.
          </Typography.Text>
          <Spin tip="Loading" size="small">
            <div className="content" />
          </Spin>
        </Space>
      </Modal>

      {isDocumentExtractLoaderOpen && (
        <DocumentExtractLoader
          isOpen={isDocumentExtractLoaderOpen}
          onClose={onDocumentExtractLoaderClose}
        />
      )}

      {shipmentId && isExtractedDocsModalOpen && (
        <DocumentExtractor
          shipmentId={shipmentId}
          fileId={extractedFileId}
          isOpen={isExtractedDocsModalOpen}
          onClose={onExtractedDocsClose}
        />
      )}

      {selectedFile && isEditModalOpen && (
        <SaveDocumentModal
          shipmentId={shipmentId}
          file={selectedFile}
          isOpen={isEditModalOpen}
          onClose={onEditClose}
          shipmentSlug={shipmentSlug}
        />
      )}
      {selectedFile && isShareModalOpen && (
        <ShareDocumentModal
          shipmentId={shipmentId}
          file={selectedFile}
          isOpen={isShareModalOpen}
          onClose={onShareClose}
        />
      )}
    </div>
  );
};

export const ShipmentDocumentsRouteWrapper: FC = () => {
  const props = useOutletContext<IShipmentDocumentsProps>();
  return <ShipmentDocuments {...props} />;
};

export default ShipmentDocuments;
