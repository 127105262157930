import { Drawer, Empty, Spin } from 'antd';
import { useGetBookingByIdQuery } from 'api/bookings';
import React, { FC, useEffect } from 'react';
import { IBookingItemEntity } from 'types/entities/booking.entity';
import { IBookingsDrawerProps } from 'types/feature/bookings.types';

import { displayErrors } from 'utils/error-notification';

import BookingComments from '../BookingComments';
import BookingCard from '../BookingDetailCard';
import BookingEditDetails from '../BookingEditDetails';

// BookingUpdateModalProps

const BookingUpdateDrawer: FC<IBookingsDrawerProps> = ({
  bookingId,
  isOpen,
  onCloseEditModal,
  onUnassign,
  onAssign,
}) => {
  /** NOTE : allow edit details in two cases
   ** either booking is not assigned to shipment or booking is attached and user is cargo owner.
   ** non-assigned bookings won't appear for forwarders.
   **/
  const {
    data: booking,
    isLoading,
    isError,
    error,
  } = useGetBookingByIdQuery(bookingId || '', { skip: !bookingId });

  useEffect(() => {
    if (isError) {
      displayErrors(error, { title: 'Error while fetching booking details' });
    }
  }, [error, isError]);

  return (
    <Drawer
      open={isOpen}
      width={'60vw'}
      onClose={onCloseEditModal}
      title="Attached Purchase Orders"
    >
      {booking ? (
        <>
          <BookingEditDetails
            booking={booking}
            onUnassign={onUnassign}
            onAssign={onAssign}
          />
          {booking?.bookingPurchaseOrders.map((item: IBookingItemEntity) => (
            <BookingCard booking={booking} item={item} key={item.id} />
          ))}
          {booking && <BookingComments bookingId={booking?.id} />}
        </>
      ) : isLoading ? (
        <Spin spinning size="large" />
      ) : (
        <Empty />
      )}
    </Drawer>
  );
};

export default BookingUpdateDrawer;
