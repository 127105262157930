import { Empty, Space, Typography } from 'antd';
import {
  DocumentSection,
  HeaderSection,
  FeeDetailSection,
  TotalFeeSection,
} from 'app/components/ShipmentQuotes/ProposalDetailsModal/ProposalDetailsComponent';
import CommentBox from 'app/design-system/CommentBox';
import React, { FC } from 'react';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IProposalPreviewProps } from 'types/feature/invoice.types';

const { Title } = Typography;

const ProposalPreview: FC<IProposalPreviewProps> = ({
  proposal,
  proposalCurrency,
}) => {
  return (
    <div className="proposal-details-wrapper py-0">
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <HeaderSection proposal={proposal} isEditEnabled={false} />
        <FeeDetailSection proposal={proposal} />
        <TotalFeeSection
          proposal={proposal}
          currency={proposalCurrency || CurrencyEnum.USD}
        />
        <DocumentSection proposal={proposal} />
        <>
          <Title level={4} className="m-0">
            Comments
          </Title>
          {proposal.proposalComments && proposal.proposalComments.length > 0 ? (
            <CommentBox
              comments={proposal.proposalComments}
              onAddComment={() => {
                console.log('Not required');
              }}
              isLoading={false}
              hideActions={true}
              isExternal={false}
              userList={[]}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No comments available!"
            />
          )}
        </>
      </Space>
    </div>
  );
};

export default ProposalPreview;
