import { Collapse } from 'antd';
import DeveloperSettings from 'app/components/Settings/DeveloperSettings';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import CompanySettings from './CompanySettings';
import NotificationsSettings from './NotificationSettings';
import ShipmentTagsSettings from './ShipmentTagsSettings';
import './settings.scss';

const { Panel } = Collapse;

const Settings: FC = () => {
  const location = useLocation();
  const fromLink = location.state?.fromLink;
  return (
    <DashboardCard large fitContent headerTitle="Settings">
      <Collapse
        defaultActiveKey={fromLink ? ['1'] : ['2']}
        className="settings-custom-collapse"
      >
        <Panel header="Notifications" key="1">
          <NotificationsSettings />
        </Panel>
        <Panel header="Tags" key="2">
          <ShipmentTagsSettings />
        </Panel>
        <Panel header="Proposals" key="3">
          <CompanySettings />
        </Panel>
        <Panel header="Developer" key="4">
          <DeveloperSettings />
        </Panel>
      </Collapse>
    </DashboardCard>
  );
};

export default Settings;
