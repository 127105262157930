import { Button, Form, Input, Modal, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  useEditFileByIdMutation,
  useLazyGetFilesByShipmentQuery,
} from 'api/file';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import {
  setFileUrl,
  setIsStartTracking,
  setShipmentId,
  setShipmentSlug,
} from 'state/slices/trackingByAi.slice';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { IEditModalProps } from 'types/feature/shipments.types';

import { displayErrors } from 'utils/error-notification';
import { formatDocumentType } from 'utils/format-fields';

const options = Object.values(IDocumentTypeEnum).map(
  (type: IDocumentTypeEnum) => ({
    label: formatDocumentType(type),
    value: type,
  })
);

const EditDocumentModal: React.FC<IEditModalProps> = ({
  shipmentId,
  file,
  isOpen,
  onClose: closeModal,
  shipmentSlug,
}) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [updateDocument, { isLoading, isError, isSuccess, error }] =
    useEditFileByIdMutation();

  const [refetchFilesByShipment] = useLazyGetFilesByShipmentQuery();

  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };
  const addTrackingByAi = (
    shipmentId: string,
    shipmentSlug: string | undefined,
    fileUrl: string
  ) => {
    batch(() => {
      dispatch(setShipmentId(shipmentId));
      dispatch(setShipmentSlug(shipmentSlug || ''));
      dispatch(setFileUrl(fileUrl));
      dispatch(setIsStartTracking(true));
    });
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await updateDocument({
        id: file?.id,
        ...values,
      });
      if (
        values.type === IDocumentTypeEnum.FINAL_MBL ||
        values.type === IDocumentTypeEnum.DRAFT_MBL
      ) {
        addTrackingByAi(shipmentId, shipmentSlug, file.file);
      }

      await refetchFilesByShipment(shipmentId);
    } catch (error) {
      message.warning('Make sure to fill all fields !');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('The document was successfully updated !');
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Update document Error ! ' });
    }
  }, [isError, error]);

  return (
    <div>
      <Modal
        title="Edit Shipment's Document"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="update"
            onClick={handleUpdate}
            disabled={isLoading}
          >
            Update File
          </Button>,
        ]}
        width={450}
        destroyOnClose
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          initialValues={{
            name: file?.name,
            type: file?.type,
          }}
        >
          <Form.Item label="Document Name" name="name">
            <Input placeholder="Document Name" />
          </Form.Item>

          <Form.Item label="Document Type" name="type">
            <Select
              size="middle"
              placeholder="Document type"
              options={options}
              className="documents-type-selector"
              data-testid="document-edit-type-btn"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditDocumentModal;
