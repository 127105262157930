import { useChecklistItem } from '@dopt/react-checklist';
import { Button, Form, Space, notification } from 'antd';
import { useGetExternalUserConnectionsQuery } from 'api/user';
import Accordion, { IAccordionItemDef } from 'app/design-system/Accordion';
import ContactDetailsForm from 'app/design-system/CommonForms/ContactDetailsForm';
import DashboardCard from 'app/design-system/DashboardCard';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import {
  resetPurchaseOrder,
  setOrderDetailsForm,
  setOrderLinesForm,
} from 'state/slices/create-purchase-order.slice';
import { IContactDetailsForm } from 'types/feature/create-shipment.types';
import {
  IOrderDetailsForm,
  IOrderLinesForm,
  IOrderCreationFormProps,
  POCreationFormTabEnum,
} from 'types/feature/purchase-orders.types';
import { THandleConfirmMethod } from 'types/feature/shipment-onboarding';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as EnterIcon } from '../../../../assets/iconsNew/enter-Icons.svg';
import '../order-creation.scss';
import AdditionalInfoForm, {
  AdditionalInfoFormRef,
} from './AdditionalInfoForm';
import OrderDetailsForm from './OrderDetailsForm';
import OrderLinesForm from './OrderLinesForm';
import {
  ORDER_ITEM_INITIAL_STATE,
  computeOrderLineCargoReadyDate,
  computeReviewTagCount,
  getDefaultCRD,
  mapOrderDetails,
  mapOrderItems,
} from './helper';

const formInputWarning = () =>
  notification.warning({
    message: 'Wrong inputs',
    description: 'Make sure to fill all mandotary fields correctly!',
  });

const OrderCreationForm: FC<IOrderCreationFormProps> = ({
  defaultPurchaseOrder,
  isEditView,
  isProcessing,
  file,
  createBookingFlow,
  showItemTag = false,
  onFinish,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const createPurchaseOrderState = useSelector(
    (state: RootState) => state.createPurchaseOrder
  );
  const reviewTagCount = computeReviewTagCount({
    createOrderItemState: createPurchaseOrderState?.orderLinesForm?.items,
    defaultOrderItem: defaultPurchaseOrder?.purchaseOrderItems,
  });

  const user = useSelector((state: RootState) => state.user.user);
  const [orderDetailsForm] = Form.useForm<IOrderDetailsForm>();
  const [orderLinesForm] = Form.useForm<IOrderLinesForm>();
  const [contactPersonForm] = Form.useForm<IContactDetailsForm>();
  const checklistItem = useChecklistItem('announcement.po_screen');

  const [activeKey, setActiveKey] = useState<string>(
    POCreationFormTabEnum.ORDER_DETAILS
  );

  const elementRef = useRef<AdditionalInfoFormRef>(null); // create the ref
  const navigate = useNavigate();

  const {
    data: externalUserList,
    isError: isExternalUserListError,
    error: externalUserListError,
  } = useGetExternalUserConnectionsQuery();

  const hasExternalConnections = !!(
    externalUserList && externalUserList.length > 0
  );

  const validateUpdateForm: THandleConfirmMethod = async ({
    currentKey,
    nextKey,
  }) => {
    try {
      switch (currentKey) {
        case POCreationFormTabEnum.ORDER_DETAILS: {
          const orderDetailsFormValues =
            await orderDetailsForm.validateFields();

          dispatch(
            setOrderDetailsForm({
              ...orderDetailsFormValues,
              cargoReadyDate: orderDetailsFormValues.cargoReadyDate
                .format('YYYY-MM-DDThh:mm:ss.00Z')
                .toString(),
              orderIssueDate: orderDetailsFormValues.orderIssueDate
                .format('YYYY-MM-DDThh:mm:ss.00Z')
                .toString(),
            })
          );
          break;
        }
        case POCreationFormTabEnum.ORDER_ITEM: {
          const orderLinesFormValues = await orderLinesForm?.validateFields();

          dispatch(
            setOrderLinesForm({
              ...orderLinesFormValues,
              items: orderLinesFormValues.items.map((item) => ({
                ...item,
                cargoReadyDate: computeOrderLineCargoReadyDate(
                  item.cargoReadyDate
                ),
              })),
            })
          );
          break;
        }
        case POCreationFormTabEnum.ADDITIONAL_INFO: {
          break;
        }
      }

      if (nextKey) {
        setActiveKey(nextKey);
      }
    } catch (e: any) {
      formInputWarning();
    }
  };

  const onSubmitForm = async (isDraft: boolean) => {
    try {
      // DETAILS
      // 1. validate fields
      // validation
      if (!isDraft) {
        await orderDetailsForm.validateFields();
        await orderLinesForm.validateFields();
        await contactPersonForm.validateFields();

        if (createPurchaseOrderState) {
          const invalidFormKey = ['orderDetailsForm', 'orderLinesForm'].find(
            (form) => createPurchaseOrderState[form] === null
          );

          if (invalidFormKey) {
            setActiveKey(invalidFormKey);
            formInputWarning();
            return;
          }
        }
      }
      let orderDetails: IOrderDetailsForm;

      // 2. get data from fields
      if (isEditView) {
        // 2.1. if edited only get the modified data
        orderDetails = await orderDetailsForm.getFieldsValue(
          true,
          ({ touched }) => touched
        );

        if (orderDetails.origin) {
          orderDetails = {
            ...orderDetails,
            originFull: {
              ...(isEditView && { id: defaultPurchaseOrder?.origin.id }),
              ...(await orderDetailsForm.getFieldValue('originFull')),
            },
          };
        }

        if (orderDetails.destination) {
          orderDetails = {
            ...orderDetails,
            destinationFull: {
              ...(isEditView && { id: defaultPurchaseOrder?.destination.id }),
              ...(await orderDetailsForm.getFieldValue('destinationFull')),
            },
          };
        }
      } else {
        // 2.2. if new order , get all fields
        orderDetails = await orderDetailsForm.getFieldsValue(true);
      }

      // ORDER ITEMS
      let orderItems: IOrderLinesForm | null = null;

      const fieldsEdited =
        orderLinesForm.isFieldsTouched() || orderDetails.cargoReadyDate;

      if (!isEditView || fieldsEdited) {
        // 2. get items lines
        orderItems = await orderLinesForm.getFieldsValue(true);
      }

      const ownerId = await contactPersonForm.getFieldValue('createdBy');

      // CREATE THE REQ BODY
      const purchaseOrderUpsertBody = {
        ...mapOrderDetails(orderDetails, isDraft, user?.id),
        purchaseOrderItems: mapOrderItems(
          orderItems,
          orderDetails.cargoReadyDate ?? getDefaultCRD(defaultPurchaseOrder),
          isDraft
        ),
        urls: elementRef.current?.getUrls() ?? [],
        isDraft,
        ownerId,
      };

      checklistItem.complete();
      await onFinish(purchaseOrderUpsertBody);
    } catch (error: unknown) {
      const lines = await orderLinesForm.getFieldsValue(true);
      if (!isDraft && !lines.items.length) {
        notification.error({
          message: 'Validation error',
          description: 'Make sure to insert at least one puchase order item !',
        });
      } else {
        notification.warning({
          message: 'Wrong inputs',
          description: 'Make sure to fill all mandotary fields correctly !',
        });
      }
    }
  };

  const resetForms = useCallback(() => {
    orderDetailsForm.resetFields();
    orderLinesForm.resetFields();
  }, [orderLinesForm, orderDetailsForm]);

  useEffect(() => {
    resetForms();
  }, [defaultPurchaseOrder, resetForms]);

  useEffect(() => {
    return () => {
      dispatch(resetPurchaseOrder());
    };
  }, [defaultPurchaseOrder, resetForms]);

  useEffect(() => {
    if (createPurchaseOrderState.orderDetailsForm) {
      orderDetailsForm.setFieldsValue({
        ...createPurchaseOrderState.orderDetailsForm,
        cargoReadyDate: dayjs(
          createPurchaseOrderState.orderDetailsForm.cargoReadyDate
        ),
        orderIssueDate: dayjs(
          createPurchaseOrderState.orderDetailsForm.orderIssueDate
        ),
      });
    }

    if (createPurchaseOrderState.orderLinesForm) {
      orderLinesForm.setFieldsValue(createPurchaseOrderState.orderLinesForm);
    }
  }, [
    createPurchaseOrderState.orderDetailsForm,
    createPurchaseOrderState.orderLinesForm,
  ]);

  useEffect(() => {
    if (isExternalUserListError) {
      displayErrors(externalUserListError, {
        title: 'Loading Connections Error',
      });
    }
  }, [isExternalUserListError, externalUserListError]);

  const handleKeyChange = async (nextKey: string, currentKey: string) => {
    validateUpdateForm({ currentKey, nextKey });
  };

  const accordionItems: IAccordionItemDef[] = [
    {
      key: POCreationFormTabEnum.ORDER_DETAILS,
      label: 'Order Details',
      children: (
        <OrderDetailsForm
          form={orderDetailsForm}
          createBookingFlow={createBookingFlow}
          defaultValues={
            defaultPurchaseOrder
              ? {
                  ...defaultPurchaseOrder,
                  orderIssueDate: defaultPurchaseOrder?.orderIssueDate
                    ? dayjs(defaultPurchaseOrder?.orderIssueDate)
                    : dayjs(),
                  cargoReadyDate: getDefaultCRD(defaultPurchaseOrder),
                  status: defaultPurchaseOrder?.status ?? null,
                  destinationFull: defaultPurchaseOrder.destination,
                  originFull: defaultPurchaseOrder.origin,
                  destination: defaultPurchaseOrder.destination?.city,
                  origin: defaultPurchaseOrder.origin?.city,
                  supplier: defaultPurchaseOrder?.supplier?.id,
                }
              : null
          }
        />
      ),
    },
    {
      key: POCreationFormTabEnum.ORDER_ITEM,
      label: (
        <div className="order-item-title">
          Order Items
          {showItemTag && reviewTagCount > 0 && (
            <div className="custom-tag">{reviewTagCount} to Review</div>
          )}
        </div>
      ),
      children: (
        <OrderLinesForm
          form={orderLinesForm}
          footer={
            isEditView ? (
              <Space>
                <Button disabled={isProcessing} onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => onSubmitForm(false)}
                  disabled={isProcessing}
                >
                  Update Order
                </Button>
              </Space>
            ) : (
              <></>
            )
          }
          isEditView={isEditView}
          defaultValues={
            defaultPurchaseOrder?.purchaseOrderItems
              ? {
                  purchaseOrderId: isEditView
                    ? defaultPurchaseOrder.id
                    : undefined,
                  items: defaultPurchaseOrder?.purchaseOrderItems ?? [],
                }
              : {
                  items: [ORDER_ITEM_INITIAL_STATE],
                }
          }
        />
      ),
    },
    ...(!isEditView
      ? [
          {
            key: POCreationFormTabEnum.ADDITIONAL_INFO,
            label: 'Additional Information',
            children: (
              <AdditionalInfoForm
                ref={elementRef}
                uploadedPurchaseOrderfile={file}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {!isEditView && hasExternalConnections && (
        <DashboardCard
          large
          flat
          headerTitle="Contact Details"
          subTitle="Select the contact person to be notified about this purchase order."
        >
          <ContactDetailsForm
            form={contactPersonForm}
            externalUserList={externalUserList}
          />
        </DashboardCard>
      )}
      <div className="mx-2">
        <Accordion
          items={accordionItems}
          activeKey={activeKey}
          onChange={handleKeyChange}
          type="filled"
          footer={(index, currentKey) =>
            accordionItems.length - 1 === index ? (
              !isEditView ? (
                <Space>
                  <Button
                    onClick={() => navigate('/purchase-orders')}
                    disabled={isProcessing}
                  >
                    Cancel
                  </Button>
                  {!createBookingFlow && (
                    <Button
                      onClick={() => onSubmitForm(true)}
                      disabled={isProcessing}
                    >
                      Save & Close
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={() => onSubmitForm(false)}
                    disabled={isProcessing}
                  >
                    {createBookingFlow ? 'Create Booking' : 'Create Order'}
                  </Button>
                </Space>
              ) : (
                <></>
              )
            ) : (
              <Button
                onClick={() =>
                  validateUpdateForm({
                    nextKey: `${accordionItems[index + 1]?.key}`,
                    currentKey,
                  })
                }
                icon={<EnterIcon />}
              >
                Confirm Details
              </Button>
            )
          }
        />
      </div>
    </>
  );
};

export default OrderCreationForm;
