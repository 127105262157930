import { Card, Empty, Spin, Typography } from 'antd';
import { useGetRecentCollaboratorByShipmentQuery } from 'api/collaborators';
import {
  messagesApi,
  useGetMessagesQuery,
  useSendMessageMutation,
} from 'api/messages';
import { useGetProfileQuery } from 'api/user';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { useAppDispatch } from 'state/store';
import { IMentionInput } from 'types/feature/messages.type';
import { IShipmentMessagesProps } from 'types/feature/shipments.types';

import { messageSendEvent, messagesSendEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import Messages from './Messages';
import './shipment-messages.scss';

dayjs.extend(relativeTime);

const { Title } = Typography;

const ShipmentMessages: FC<IShipmentMessagesProps> = ({
  shipment: { id: shipmentId, status: shipmentStatus, slug: shipmentSlug },
  fitContent,
  firstUnreadMessageId,
}) => {
  const dispatch = useAppDispatch();
  const { data: userData } = useGetProfileQuery();
  const user = useSelector((state: RootState) => state.user.user);

  const {
    data: messagesData,
    isLoading: isMessagesLoading,
    isFetching: isMessageFetching,
    isError: isMessagesError,
    error: messagesError,
  } = useGetMessagesQuery(shipmentId, {
    refetchOnMountOrArgChange: true,
    skip: !shipmentId,
  });

  const [
    sendMessage,
    {
      data: respData,
      isLoading: isSending,
      isSuccess: isSendingSuccess,
      isError: isSendingError,
      error: sendingError,
    },
  ] = useSendMessageMutation();

  const { data: collaboratorsData } = useGetRecentCollaboratorByShipmentQuery({
    shipmentId,
    search: '',
    isCommunication: true,
  });

  const onAddMessage = (mentionInput: IMentionInput, files: string[]) => {
    sendMessage({
      shipmentId,
      ...mentionInput,
      attachments: files,
    });
    messagesSendEvent({
      user_id: user?.id || '',
      email: user?.email || '',
      company: user?.company?.name || '',
      company_id: user?.companyId || '',
    });
  };

  useEffect(() => {
    if (isSendingSuccess && respData) {
      dispatch(
        messagesApi.util.updateQueryData(
          'getMessages',
          shipmentId,
          (draftPosts) => {
            draftPosts.push(respData);
          }
        )
      );

      messageSendEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        shipment_id: shipmentId,
        company_id: user?.companyId,
      });
    }
    // eslint-disable-next-line
  }, [respData, isSendingSuccess]);

  useEffect(() => {
    if (isSendingError)
      displayErrors(sendingError, { title: 'Send Message Error' });
  }, [isSendingError, sendingError]);

  useEffect(() => {
    if (isMessagesError)
      displayErrors(messagesError, { title: 'Get Message Error' });
  }, [isMessagesError, messagesError]);
  const renderWithoutCard = () => {
    return isMessagesLoading || isMessageFetching ? (
      <div className="loading">
        <Spin />
      </div>
    ) : messagesData && userData ? (
      <Messages
        firstUnreadMessageId={firstUnreadMessageId}
        fitContent={true}
        messages={messagesData}
        shipmentId={shipmentId}
        shipmentSlug={shipmentSlug}
        onAddMessage={onAddMessage}
        isLoading={isMessagesLoading || isSending}
        currentUser={{
          id: userData.data.id,
          fullname: userData.data.fullname,
          email: userData.data.email,
          photo: userData.data.photo,
        }}
        userList={collaboratorsData || []}
      />
    ) : (
      <Empty />
    );
  };
  const renderWithCard = () => {
    return (
      <Card loading={isMessagesLoading || isMessageFetching}>
        <Title level={3} className="m-0">
          Messages
        </Title>

        {messagesData && userData && (
          <Messages
            messages={messagesData}
            shipmentId={shipmentId}
            shipmentSlug={shipmentSlug}
            onAddMessage={onAddMessage}
            isLoading={isMessagesLoading || isSending}
            currentUser={{
              id: userData.data.id,
              fullname: userData.data.fullname,
              email: userData.data.email,
              photo: userData.data.photo,
            }}
            userList={collaboratorsData || []}
          />
        )}
      </Card>
    );
  };
  return fitContent ? renderWithoutCard() : renderWithCard();
};

export const ShipmentMessagesRouteWrapper: FC = () => {
  const props = useOutletContext<IShipmentMessagesProps>();
  return <ShipmentMessages {...props} />;
};

export default ShipmentMessages;
