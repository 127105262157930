import { Tooltip, Avatar, Typography } from 'antd';
import ProfileImage from 'app/components/ProfilePicture';
import React, { FunctionComponent } from 'react';
import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { IPurchaseOrderCollaborator } from 'types/feature/purchase-orders.types';

const { Text } = Typography;
export const disaplayForwarders: FunctionComponent<{
  teamMemebers: ICollaboratorsEntity[] | IPurchaseOrderCollaborator[];
  maxCount?: number;
}> = ({ teamMemebers, maxCount = 5 }) => {
  return (
    <>
      {teamMemebers.length > maxCount
        ? teamMemebers.slice(0, maxCount + 1).map((team, index) =>
            index < maxCount ? (
              <Tooltip key={team.id} title={`${team.collaborator.fullname} `}>
                <div>
                  <ProfileImage
                    key={team.id}
                    name={`${team.collaborator.fullname} `}
                    photoUrl={team.collaborator.photo}
                    className="user-gravatar"
                  />
                </div>
              </Tooltip>
            ) : (
              <Avatar
                className="user-gravatar"
                key={team.id}
                alt="collaboratoes"
                style={{ backgroundColor: '#FFD8BF' }}
              >
                <Text strong style={{ color: '#FA541C' }}>
                  {`+${teamMemebers.length - maxCount}`}
                </Text>
              </Avatar>
            )
          )
        : teamMemebers.map((team) => (
            <Tooltip key={team.id} title={`${team.collaborator.fullname} `}>
              <div>
                <ProfileImage
                  key={team.id}
                  name={`${team.collaborator.fullname} `}
                  photoUrl={team.collaborator.photo}
                  className="user-gravatar"
                />
              </div>
            </Tooltip>
          ))}
    </>
  );
};
