import { Badge, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IBookmarkedShipment } from 'types/entities/shipment.entity';

import { formatOriginToDest, formatShipmentState } from 'utils/format-fields';

export const shipmentColumnsConst: ColumnsType<IBookmarkedShipment> = [
  {
    key: 'status',
    dataIndex: 'status',
    render: (value, record, index) => {
      const text = record.shipment.shipmentReference
        ? `${record.shipment.slug} ${record.shipment.shipmentReference}`
        : `${record.shipment.slug}`;

      return (
        <Space direction="vertical" align="center" size={2}>
          {formatShipmentState(record.shipment.status)}
          <Tooltip placement="top" title={text}>
            <Typography.Paragraph className="shipment-text--sub" strong>
              {text}
            </Typography.Paragraph>
          </Tooltip>
        </Space>
      );
    },
    align: 'center',
  },
  {
    key: 'fromTo',
    render: (value, record, index) => {
      const text = record.shipment.shipmentItems;
      return (
        <Space direction="vertical" align="center">
          {formatOriginToDest(
            record.shipment.destination,
            record.shipment.origin
          )}
          <Tooltip placement="top" title={text}>
            <Typography.Paragraph className="shipment-text--sub">
              {text}
            </Typography.Paragraph>
          </Tooltip>
        </Space>
      );
    },
    align: 'center',
  },
  {
    key: 'date',
    render: (value, record, index) => {
      return (
        <Space direction="horizontal">
          {record.arrivalSoon && <Badge status="processing" />}
          <Typography.Text type="secondary">
            {record.shipment.eta
              ? dayjs(record.shipment.eta).format('MMM DD')
              : 'N/A'}
          </Typography.Text>
        </Space>
      );
    },
  },
];
