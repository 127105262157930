import { useRef, useState } from 'react';
import { SSE } from 'sse.js';
import { ChatRequest } from 'types/entities/chat.entity';

import { displayErrors } from 'utils/error-notification';

const CHAT_API = process.env.REACT_APP_CONVERSATION_API_URL;

export const useChatRequest = ({ onSaveResponse, onErrorResponse }) => {
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sourceRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResponse = (e: any, chatRequest: ChatRequest) => {
    // Pass chatRequest as a parameter
    if (e.data === '[DONE]') {
      setIsStreaming(false);
    } else {
      const payload = JSON.parse(e.data);
      onSaveResponse(payload.data);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    const errorData = JSON.parse(error.data);
    displayErrors(
      { data: errorData, status: errorData.status },
      {
        title: 'Opps! Unable to send question. Please try again.',
      }
    );
    onErrorResponse();
    setIsStreaming(false);
  };

  const sendQuestion = ({ chatRequest }: { chatRequest: ChatRequest }) => {
    // If there is an active stream, close it
    if (sourceRef.current) {
      sourceRef.current.close();
      sourceRef.current.removeEventListener('message', handleResponse);
      sourceRef.current.removeEventListener('error', handleError);
    }

    setIsStreaming(true);
    const source = new SSE(`${CHAT_API}/chatbot/universal-chat`, {
      headers: { 'Content-Type': 'application/json' },
      payload: JSON.stringify(chatRequest),
      method: 'POST',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source.addEventListener('message', (e: any) =>
      handleResponse(e, chatRequest)
    ); // Pass chatRequest to handleResponse
    source.addEventListener('error', handleError);

    source.stream();
    sourceRef.current = source;

    return () => {
      source.removeEventListener('message', handleResponse);
      source.removeEventListener('error', handleError);
      source.close();
    };
  };

  const onStreamReset = () => {
    setIsStreaming(false);
    sourceRef.current.close();
    sourceRef.current.removeEventListener('message', handleResponse);
    sourceRef.current.removeEventListener('error', handleError);
  };

  return { sendQuestion, onStreamReset, isStreaming };
};
