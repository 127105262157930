import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ProfileImage from 'app/components/ProfilePicture';
import React from 'react';
import { IBookingCollaborators } from 'types/feature/bookings.types';

export const bookingColumnsConst: ColumnsType<IBookingCollaborators> = [
  {
    key: 'avatar',
    render: (value, record, index) => {
      return <ProfileImage name={record.fullname} photoUrl={record.photo} />;
    },
  },
  {
    key: 'name',
    render: (value, record, index) => {
      return <Typography.Text>{record.fullname}</Typography.Text>;
    },
  },
];
