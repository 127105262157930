import { Col, DatePicker, Form, Row, TimePicker } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import {
  IBiddingDetailsForm,
  IBiddingDetailsFormProps,
} from 'types/feature/create-shipment.types';

import { biddingDateHelper } from 'utils/bidding-date-helper';

const { dateFormat, timeFormat, disabledDate } = biddingDateHelper;

const BiddingDetailsForm: FC<IBiddingDetailsFormProps> = ({
  form,
  defaultValues,
  isDisabled = false,
  isCompact = false,
}) => {
  const [isDateRequired, setIsDateRequired] = useState<boolean>(false);
  const [isTimeRequired, setIsTimeRequired] = useState<boolean>(false);

  const disableTime = (currentDate) => {
    const now = dayjs();
    const oneHoursLater = now.add(1, 'hour');
    const disabledHours: number[] = [];

    if (currentDate && currentDate.isSame(oneHoursLater, 'day')) {
      // Disable hours for dates until four hours from now
      for (let i = 0; i < oneHoursLater.hour(); i++) {
        disabledHours.push(i);
      }
    }

    return {
      disabledHours: () => disabledHours,
    };
  };

  const onBiddingFormValueChange = (
    changedValues: Partial<IBiddingDetailsForm>,
    values: IBiddingDetailsForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];

    switch (fieldName) {
      case 'dueDate': {
        if (values.dueDate) {
          const disabledHours = disableTime(values.dueDate).disabledHours();
          const currentSelectedTime = form.getFieldValue('dueTime');
          const currentSelectedHours = dayjs(currentSelectedTime).hour();

          if (disabledHours.includes(currentSelectedHours)) {
            const allowedHours = dayjs(currentSelectedTime).hour(
              disabledHours.length
            );
            form.setFieldValue('dueTime', allowedHours);
          }
        }
        break;
      }
    }

    if (values.dueDate) {
      setIsTimeRequired(true);
    } else if (values.dueTime) {
      setIsDateRequired(true);
    } else {
      form.validateFields();
      form.setFieldsValue({
        dueDate: null,
        dueTime: null,
      });
      setIsDateRequired(false);
      setIsTimeRequired(false);
    }
  };

  return (
    <Form
      initialValues={defaultValues}
      layout="vertical"
      form={form}
      scrollToFirstError
      onValuesChange={onBiddingFormValueChange}
    >
      <Row gutter={[12, 12]}>
        <Col span={isCompact ? 12 : 6}>
          <FormItem
            label="Date"
            name="dueDate"
            rules={[
              {
                required: isDateRequired,
                message: 'Please Select Date',
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disabledDate}
              disabled={isDisabled}
            />
          </FormItem>
        </Col>

        <Col span={isCompact ? 12 : 6}>
          <FormItem
            label="Hour"
            name="dueTime"
            rules={[
              {
                required: isTimeRequired,
                message: 'Please Select Time',
              },
            ]}
          >
            <TimePicker
              disabledTime={() => disableTime(form.getFieldValue('dueDate'))}
              showNow={false}
              format={timeFormat}
              disabled={isDisabled}
              popupClassName="bidding-time-picker"
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default BiddingDetailsForm;
