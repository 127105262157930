import { Button, Modal, Space, Typography } from 'antd';
import { useUpdateInvoiceByIdMutation } from 'api/invoice';
import React, { useEffect, useState } from 'react';
import {
  IInvoiceAcceptModalProps,
  InvoiceStatusEnum,
} from 'types/feature/invoice.types';

import InvoiceAssigneeModal from '../InvoiceAssigneeModal';

const InvoiceAcceptModal: React.FC<IInvoiceAcceptModalProps> = ({
  invoiceId,
  shipmentId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [updateInvoice, { isLoading: isAcceptLoading, isSuccess }] =
    useUpdateInvoiceByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const handleAcceptInvoice = () => {
    updateInvoice({
      id: invoiceId,
      invoice: {
        status: InvoiceStatusEnum.ACCEPTED,
      },
    });
  };

  // methods

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Request Assignee
      </Button>

      <Modal
        title="Accept Invoice"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Space>
            <InvoiceAssigneeModal
              handleCloseAcceptInvoiceModal={handleCancel}
              buttonType="default"
              buttonText="Assign Invoice"
              invoiceId={invoiceId}
              shipmentId={shipmentId}
            />
            <Button
              loading={isAcceptLoading}
              onClick={handleAcceptInvoice}
              type="primary"
            >
              Accept Invoice
            </Button>
          </Space>
        }
      >
        <Typography.Text>
          The amount you quoted for shipment matches 100% with the invoiced
          amount. You can accept the invoice on behalf of Cargo Owner.
        </Typography.Text>
      </Modal>
    </div>
  );
};

export default InvoiceAcceptModal;
