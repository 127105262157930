import { faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { MarkerF } from '@react-google-maps/api';
import React, { FunctionComponent } from 'react';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ITrackingEntity } from 'types/entities/tracking.entity';

import {
  displayInfo,
  displayMultipleInfo,
  displayTrackingInfo,
} from '../InfoWindow';

const DotsColors = {
  origin: '#2296F2',
  destination: '#2296F2',
  tracking: '#2296F2',
};

export const displayVisselMarker: FunctionComponent<{
  shipments: IShipmentEntity | IShipmentEntity[];
  onMarkerClicked: (key: string) => void;
  activatedMarkerId: string | undefined;
  onCloseInfoWindow: () => void;
  showRedirct?: (shipmentId: string, slug: string) => void;
}> = ({
  shipments,
  onCloseInfoWindow,
  onMarkerClicked,
  activatedMarkerId,
  showRedirct,
}) => {
  let shipment: IShipmentEntity;
  if (Array.isArray(shipments)) {
    shipment = shipments[0];
  } else {
    shipment = shipments;
  }
  return shipment.currentLocation ? (
    <MarkerF
      key={`${shipment.id}-current`}
      position={
        new google.maps.LatLng(
          shipment.currentLocation?.latitude,
          shipment.currentLocation?.longitude
        )
      }
      icon={{
        path: faDotCircle.icon[4] as string,
        fillColor: '#fff',
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faDotCircle.icon[0] / 3, // width
          faDotCircle.icon[1] / 2 // height
        ),
        strokeWeight: 3,
        strokeColor: '#F94F5D',
        scale: 0.03,
      }}
      onClick={(e) => onMarkerClicked(`${shipment.id}-current`)}
    >
      {activatedMarkerId === `${shipment.id}-current`
        ? Array.isArray(shipments)
          ? displayMultipleInfo({
              shipments,
              onCloseInfoWindow,
              type: 'current location',
              showRedirct,
              shipment,
            })
          : displayInfo({
              shipment,
              onCloseInfoWindow,
              type: 'current location',
              showRedirct,
            })
        : null}
    </MarkerF>
  ) : (
    <></>
  );
};

export const displayShipmentOriginDest: FunctionComponent<{
  shipment: IShipmentEntity;
  onMarkerClicked: (key: string) => void;
  activatedMarkerId: string | undefined;
  onCloseInfoWindow: () => void;
  type: 'origin' | 'destination';
  current?: boolean;
  showRedirct?: (shipmentId: string, slug: string) => void;
}> = ({
  shipment,
  onCloseInfoWindow,
  onMarkerClicked,
  activatedMarkerId,
  type,
  current,
  showRedirct,
}) => {
  return (
    <MarkerF
      key={`${shipment.id}-${type}`}
      position={
        new google.maps.LatLng(
          shipment[type].latitude,
          shipment[type].longitude
        )
      }
      icon={
        current
          ? {
              path: faDotCircle.icon[4] as string,
              fillColor: '#fff',
              fillOpacity: 1,
              anchor: new google.maps.Point(
                faDotCircle.icon[0] / 2, // width
                faDotCircle.icon[1] // height
              ),
              strokeWeight: 3,
              strokeColor: '#F94F5D',
              scale: 0.03,
            }
          : {
              path: faCircle.icon[4] as string,
              fillColor: DotsColors[type],
              fillOpacity: 1,
              anchor: new google.maps.Point(
                faCircle.icon[0] / 2, // width
                faCircle.icon[1] // height
              ),
              strokeWeight: 2,
              strokeColor: '#fff',
              scale: 0.02,
            }
      }
      onClick={(e) => onMarkerClicked(`${shipment.id}-${type}`)}
    >
      {activatedMarkerId === `${shipment.id}-${type}`
        ? displayInfo({
            shipment,
            onCloseInfoWindow,
            type,
            showRedirct,
          })
        : null}
    </MarkerF>
  );
};

export const displayTrackingEvent: FunctionComponent<{
  shipment: IShipmentEntity;
  tracking: ITrackingEntity;
  onMarkerClicked: (key: string) => void;
  activatedMarkerId: string | undefined;
  onCloseInfoWindow: () => void;
}> = ({
  shipment,
  tracking,
  onCloseInfoWindow,
  onMarkerClicked,
  activatedMarkerId,
}) => {
  return (
    <MarkerF
      key={`${tracking.id}-tracking`}
      position={new google.maps.LatLng(tracking.latitude, tracking.longitude)}
      icon={{
        path: faCircle.icon[4] as string,
        fillColor: DotsColors.tracking,
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faCircle.icon[0] / 2, // width
          faCircle.icon[1] // height
        ),
        strokeWeight: 1,
        strokeColor: '#fff',
        scale: 0.015,
      }}
      onClick={(e) => onMarkerClicked(`${tracking.id}-tracking`)}
    >
      {activatedMarkerId === `${tracking.id}-tracking`
        ? displayTrackingInfo({
            shipment,
            onCloseInfoWindow,
            type: 'tracking',
            tracking,
          })
        : null}
    </MarkerF>
  );
};
