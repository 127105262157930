import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from 'react';

type TChatContext = {
  isChatOpen: boolean;
  openChat: () => void;
  closeChat: () => void;
};

const ChatContext = createContext<TChatContext | undefined>(undefined);

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}

type IChatProviderProps = {
  children: ReactNode;
};

export const ChatProvider: FC<IChatProviderProps> = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const openChat = () => setIsChatOpen(true);
  const closeChat = () => setIsChatOpen(false);

  return (
    <ChatContext.Provider value={{ isChatOpen, openChat, closeChat }}>
      {children}
    </ChatContext.Provider>
  );
};
