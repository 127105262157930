import { ICompanyEntity } from 'types/entities/company.entity';

export enum AcceptedProposalStoryScreenEnum {
  FORWARDER_SCREEN = 'forwarder_screen',
  BOOKING_PARTY_SCREEN = 'booking_party_screen',
}

export interface AcceptedProposalStoryModalProps {
  shipmentId: string;
  company: ICompanyEntity;
}

export interface ShareBookingPartyOnboardingScreenProps {
  onShareBookingPartyClick: () => void;
  onScreenChange: (screen: AcceptedProposalStoryScreenEnum) => void;
  onScreenReset: () => void;
}

export interface AddForwarderColleageOnboardingScreenProps
  extends AcceptedProposalStoryModalProps {
  onScreenChange: (screen: AcceptedProposalStoryScreenEnum) => void;
  onScreenReset: () => void;
}

// Signup Onboarding

export enum SignupOnboardingScreenEnum {
  DEFAULT = 'default',
  PO_SCREEN = 'po_screen',
  CREATE_SHIPMENT_SCREEN = 'create_shipment_screen',
  IMPORT_SHIPMENT_SCREEN = 'import_shipment_screen',
}

export interface ISignupOnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultScreen?: SignupOnboardingScreenEnum;
}

export interface IOverviewScreenProps {
  onScreenChange: (
    newScreen: SignupOnboardingScreenEnum,
    screenData?: Record<string, any>
  ) => void;
  onClose: () => void;
}
