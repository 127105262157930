import { Form, Select, Row, Col, DatePicker, Checkbox, Input } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import LocationFields from 'app/design-system/LocationFields';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IncotermsEnum } from 'types/entities/product.entity';
import { CargoTypeEnum } from 'types/entities/shipment.entity';
import { ICargoDetailsFormProps } from 'types/feature/create-shipment.types';

import { capitalize, createServiceModeOptions } from 'utils/format-fields';

import { useCargoDetailsForm } from './UseCargoDetailsForm';

const incoterms = Object.values(IncotermsEnum);
const cargoType = Object.values(CargoTypeEnum);

const AIR_FORMAT = 'YYYY-MM-DD HH:mm';
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const CargoDetailsForm: FC<ICargoDetailsFormProps> = ({
  form,
  defaultValues,
  isAirShipment,
  isEditView,
  previewTabCta,
}) => {
  const {
    isNewProduct,
    commodities,
    isFetchingProducts,
    disabledETDDate,
    disabledETADate,
    onValuesChange,
  } = useCargoDetailsForm(form, defaultValues);

  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError
      onValuesChange={onValuesChange}
      {...(defaultValues && {
        initialValues: defaultValues,
      })}
    >
      <DashboardCard
        large
        headerTitle="Cargo Details"
        rightAccessory={previewTabCta}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item
              label="Commodity Type"
              name="productId"
              rules={[
                {
                  required: true,
                  message: 'Please select a comodity type.',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Commodity Type"
                options={[
                  ...commodities,
                  {
                    label: 'Other',
                    value: 'other',
                  },
                ]}
                loading={isFetchingProducts}
                filterOption={(input, option) =>
                  ((option?.label as string) ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Service Mode"
              name="serviceMode"
              rules={[
                {
                  required: true,
                  message: 'Please select a service mode.',
                },
              ]}
            >
              <Select
                data-testid="service-mode-select"
                placeholder="Service Mode"
                options={createServiceModeOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Commodity Name"
              name="productCategoryName"
              rules={[
                {
                  required: isNewProduct,
                  message: 'Please enter a commodity name.',
                },
              ]}
              hidden={!isNewProduct}
            >
              <Input placeholder="Please enter a comodity name." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <LocationFields
            form={form}
            isEditView={isEditView}
            defaultLocations={
              defaultValues
                ? {
                    origin: defaultValues.origin,
                    destination: defaultValues.destination,
                  }
                : null
            }
          />
          <Col span={8}>
            <Form.Item
              label="Incoterm"
              name="incoterms"
              rules={[
                {
                  required: true,
                  message: 'Please select the incoterms.',
                },
              ]}
            >
              <Select
                placeholder="Choose Incoterm"
                options={incoterms.map((value: IncotermsEnum) => {
                  return { value: value, label: value.toUpperCase() };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} align={'bottom'}>
          <Col span={8}>
            <Form.Item
              label="Cargo Ready Date"
              name="cargoReadyDate"
              rules={[
                {
                  required: true,
                  message: 'Please select the cargo ready date.',
                },
                {
                  type: 'date',
                  message: 'Please select a valid date',
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Cargo Type"
              name="cargoType"
              rules={[
                {
                  required: true,
                  message: 'Please select the cargo type.',
                },
              ]}
            >
              <Select
                placeholder="Choose Cargo Type"
                options={cargoType.map((value: CargoTypeEnum) => {
                  return {
                    value: value,
                    label: capitalize(value),
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="shipment-type-checkbox"
              name="nonStackable"
              valuePropName="checked"
            >
              <Checkbox>Non-Stackable</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {isEditView && (
          <Row gutter={[12, 12]} align={'bottom'}>
            <Col span={6}>
              <Form.Item
                label="Estimated Departure"
                name="etd"
                rules={[
                  () => ({
                    validator(rule, value) {
                      const isDateAccepted =
                        value && value > form.getFieldValue('cargoReadyDate');
                      if (!value || (value && isDateAccepted)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'the ETD should not be earlier than Cargo ready date ! '
                      );
                    },
                  }),
                ]}
              >
                <DatePicker
                  format={isAirShipment ? AIR_FORMAT : DEFAULT_FORMAT}
                  showTime={
                    isAirShipment
                      ? { defaultValue: dayjs('00:00:00', 'HH:mm') }
                      : false
                  }
                  disabledDate={disabledETDDate}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="autoUpdateEtd" valuePropName="checked">
                <Checkbox>Auto Update</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Estimated Arrival"
                name="eta"
                rules={[
                  () => ({
                    validator(rule, value) {
                      const isDateAccepted =
                        value && value > form.getFieldValue('cargoReadyDate');
                      if (!value || (value && isDateAccepted)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'the ETA should not be earlier than Cargo ready date ! '
                      );
                    },
                  }),

                  () => ({
                    validator(rule, value) {
                      const isDateAccepted =
                        value && value > form.getFieldValue('etd');
                      if (!value || (value && isDateAccepted)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'the ETA should not be earlier than ETD ! '
                      );
                    },
                  }),
                ]}
              >
                <DatePicker
                  format={isAirShipment ? AIR_FORMAT : DEFAULT_FORMAT}
                  showTime={
                    isAirShipment
                      ? { defaultValue: dayjs('00:00:00', 'HH:mm') }
                      : false
                  }
                  disabledDate={disabledETADate}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="autoUpdateEta" valuePropName="checked">
                <Checkbox>Auto Update</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}
      </DashboardCard>
    </Form>
  );
};

export default CargoDetailsForm;
