import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';

import { capitalize } from './format-fields';

export const isManager = (accessLevel: AccessLevelEnum) => {
  return accessLevel === AccessLevelEnum.MANAGE;
};
export const isEditor = (accessLevel: AccessLevelEnum) => {
  return accessLevel === AccessLevelEnum.EDIT;
};
export const isViewer = (accessLevel: AccessLevelEnum) => {
  return accessLevel === AccessLevelEnum.VIEW;
};

export const getAccessLevels = (
  currentAccessLevel: AccessLevelEnum = AccessLevelEnum.VIEW,
  isShipmentAccess = false
) => {
  switch (currentAccessLevel) {
    case AccessLevelEnum.MANAGE:
      return isShipmentAccess
        ? Object.values(AccessLevelEnum).filter(
            (accessLevel) => accessLevel !== AccessLevelEnum.MANAGE
          )
        : Object.values(AccessLevelEnum);
    case AccessLevelEnum.EDIT:
      return Object.values(AccessLevelEnum).filter(
        (accessLevel) => accessLevel !== AccessLevelEnum.MANAGE
      );
    case AccessLevelEnum.VIEW:
      return Object.values(AccessLevelEnum).filter(
        (accessLevel) =>
          accessLevel !== AccessLevelEnum.MANAGE &&
          accessLevel !== AccessLevelEnum.EDIT
      );
    default:
      return [];
  }
};

export const canRemoveCollab = (
  currentUser: ICollaboratorsEntity,
  collaborator: IRecentCollaboratorDef
) => {
  // either a manager or he is the one who added the collaborator
  return (
    isManager(currentUser.accessLevel) ||
    currentUser.collaborator.id === collaborator.addedBy
  );
};

export const renderAccessLevelOptions = (
  currentUserAccessLevel: AccessLevelEnum
) =>
  getAccessLevels(currentUserAccessLevel, true).map((accessLevel) => ({
    label: `Invite to ${capitalize(accessLevel)}`,
    value: accessLevel,
  }));

export const findCurrentUser = (shipment: IShipmentEntity, userId?: string) => {
  let user = shipment?.forwarders?.find(
    (forwarder) => forwarder.collaborator.id === userId
  );
  if (!user)
    user = shipment?.teams?.find((team) => team.collaborator.id === userId);
  return user;
};
