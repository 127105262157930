import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import {
  IPOParsedDocumentEntity,
  IPOParsingReq,
} from 'types/entities/purchase-order.entity';

import { CONVERSATION_API_URL } from './common/config';

const staggeredBaseQuery = retry(
  fetchBaseQuery({ baseUrl: CONVERSATION_API_URL }),
  { maxRetries: 3 }
);

export const freightAIConvertionApi = createApi({
  reducerPath: 'freight-ai',
  baseQuery: staggeredBaseQuery,
  endpoints: (build) => ({
    createOrderFromFile: build.mutation<IPOParsedDocumentEntity, IPOParsingReq>(
      {
        query: (body) => ({
          url: `${CONVERSATION_API_URL}/documents/parse-purchaseOrder`,
          method: 'POST',
          body,
        }),
      }
    ),

    createShipmentFromText: build.mutation<
      IPOParsedDocumentEntity,
      { text: string; companyName: string | undefined }
    >({
      query: (payload) => ({
        url: `${CONVERSATION_API_URL}/documents/extract-shipment-info`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useCreateOrderFromFileMutation,
  useCreateShipmentFromTextMutation,
} = freightAIConvertionApi;
