import React, { createContext, useState, useContext, FC } from 'react';
import { IProposalEntity } from 'types/entities/proposal.entity';
import {
  IProposalContextType,
  IProposalProviderProps,
} from 'types/feature/proposal.types';

export const ProposalContext = createContext<IProposalContextType | undefined>(
  undefined
);

export const ProposalContextProvider: FC<IProposalProviderProps> = (props) => {
  const [selectedProposal, setSelectedProposal] =
    useState<IProposalEntity | null>(null);
  const [showProposalDetailsModal, setShowProposalDetailsModal] =
    useState(false);

  return (
    <ProposalContext.Provider
      value={{
        selectedProposal,
        setSelectedProposal,
        showProposalDetailsModal,
        setShowProposalDetailsModal,
      }}
    >
      {props.children}
    </ProposalContext.Provider>
  );
};

export const useProposalState = () => {
  const proposal = useContext(ProposalContext);

  if (!proposal) {
    throw new Error(
      'useProposalState has to be used within <ProposalContext.Provider>'
    );
  }

  return proposal;
};
