import { InfoWindow } from '@react-google-maps/api';
import { Button, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { IShipmentEntity } from 'types/entities/shipment.entity';
import { ITrackingEntity } from 'types/entities/tracking.entity';

import { formatOriginToDest } from 'utils/format-fields';

interface InfoWindowProps {
  type: 'origin' | 'destination' | 'current location' | 'tracking';
  shipment: IShipmentEntity;
  tracking?: ITrackingEntity;
  onCloseInfoWindow: () => void;
  showRedirct?: (shipmentId: string, slug: string) => void;
}

interface InfoWindowMultipleProps extends InfoWindowProps {
  shipments: IShipmentEntity[];
}

export const displayInfo: FunctionComponent<InfoWindowProps> = ({
  onCloseInfoWindow,
  shipment,
  type,
  showRedirct,
}) => {
  return (
    <InfoWindow onCloseClick={onCloseInfoWindow}>
      <div style={{ margin: 0, padding: 0 }}>
        <strong> {type.toUpperCase()} </strong>
        {shipment.eta && (
          <p>
            Arrives within
            <strong>{dayjs(shipment.eta).diff(dayjs(), 'days')} days</strong>
          </p>
        )}
        <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
        <p>
          {shipment.shipmentReference ? (
            <>
              <strong>{shipment.shipmentReference} </strong>({shipment.slug})
            </>
          ) : (
            <strong>{shipment.slug} </strong>
          )}
        </p>
        {formatOriginToDest(shipment.destination, shipment.origin)}
        {showRedirct && (
          <>
            <Divider style={{ marginTop: '10px', marginBottom: '5px' }} />
            <Row justify={'end'}>
              <Button
                type="link"
                onClick={() => showRedirct(shipment.id, shipment.slug)}
                size="small"
              >
                View Shipment
              </Button>
            </Row>
          </>
        )}
      </div>
    </InfoWindow>
  );
};

export const displayMultipleInfo: FunctionComponent<
  InfoWindowMultipleProps
> = ({ onCloseInfoWindow, shipments, type, showRedirct }) => {
  return (
    <InfoWindow onCloseClick={onCloseInfoWindow}>
      <>
        <strong> {type.toUpperCase()} </strong>

        {shipments.map((shipment, index) => {
          return (
            <div style={{ margin: 0, padding: 0 }} key={shipment.id}>
              <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

              {shipment.eta && (
                <p>
                  Arrives within
                  <strong>
                    {dayjs(shipment.eta).diff(dayjs(), 'days')} days
                  </strong>
                </p>
              )}
              <p>
                {shipment.shipmentReference ? (
                  <>
                    <strong>{shipment.shipmentReference} </strong>(
                    {shipment.slug})
                  </>
                ) : (
                  <strong>{shipment.slug} </strong>
                )}
              </p>
              {formatOriginToDest(shipment.destination, shipment.origin)}
              {showRedirct && (
                <>
                  <Row justify={'end'} style={{ marginTop: 10 }}>
                    <Link
                      to={{
                        pathname: `/shipments/${shipment.slug}`,
                      }}
                      state={{ shipmentId: shipment.id }}
                    >
                      {'View '}
                      {shipment.shipmentReference
                        ? shipment.shipmentReference
                        : shipment.slug}
                    </Link>
                  </Row>
                </>
              )}
            </div>
          );
        })}
      </>
    </InfoWindow>
  );
};

export const displayTrackingInfo: FunctionComponent<InfoWindowProps> = ({
  onCloseInfoWindow,
  shipment,
  tracking,
  type,
}) => {
  return (
    <InfoWindow onCloseClick={onCloseInfoWindow}>
      <div>
        <strong> {type.toUpperCase()} </strong>
        {shipment.eta && (
          <p>
            Arrives within
            <strong>{dayjs(shipment.eta).diff(dayjs(), 'days')} days</strong>
          </p>
        )}
        <Divider style={{ margin: '0px' }} />
        <p>
          {shipment.shipmentReference ? (
            <>
              <strong>{shipment.shipmentReference} </strong>({shipment.slug})
            </>
          ) : (
            <strong>{shipment.slug} </strong>
          )}
        </p>
        {formatOriginToDest(shipment.destination, shipment.origin)}
        <Divider style={{ marginBottom: '0px', marginTop: '5px' }} />
        <p>
          {dayjs(tracking?.date).format('DD.MM.YY')} -
          <strong>
            {tracking?.portName} , {tracking?.countryCode}
          </strong>
        </p>
      </div>
    </InfoWindow>
  );
};
