import { Typography } from 'antd';
import { computeDimensionValue } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import DescriptionList from 'app/design-system/DescriptionList';
import React, { FC } from 'react';
import { TProposalShipmentItemsPropos } from 'types/feature/proposal.types';

const ProposalShipmentItems: FC<TProposalShipmentItemsPropos> = ({
  proposal,
  diffKeys,
}) => {
  const proposalDimensionFields =
    proposal &&
    computeDimensionValue(
      proposal.shipmentType,
      proposal.proposalShipmentItems
    );

  return (
    <div className="proposal-shipment-items-wrapper slim-card-wrapper">
      <DescriptionList
        label="Freight Method"
        description={proposal.shipmentType.modality}
        className={
          diffKeys && `${diffKeys['shipmentType']?.modality} text-value`
        }
      />
      <DescriptionList
        label="Shipment Type"
        description={
          <Typography.Text
            className={`shipment-type-title ${proposal.shipmentType.title.replace(
              ' ',
              '-'
            )}`}
          >
            {proposal.shipmentType.title.toUpperCase()}
          </Typography.Text>
        }
        className={diffKeys && `${diffKeys['shipmentType']?.title} text-value`}
      />

      <DescriptionList
        label="Quantity"
        description={proposal.proposalShipmentItemsFormatted}
        className={
          diffKeys && `${diffKeys['proposalShipmentItemsFormatted']} text-value`
        }
      />
      {proposalDimensionFields && (
        <>
          <DescriptionList
            label="Total Gross Weight"
            description={`${proposalDimensionFields.weight} kg`}
          />
          <DescriptionList
            label="Total Volume"
            description={`${proposalDimensionFields.volume} cbm`}
          />
          <DescriptionList
            label="Chargeable Weight"
            description={`${proposalDimensionFields.chargeableWeight} kg`}
          />
        </>
      )}
    </div>
  );
};

export default ProposalShipmentItems;
