import dayjs from 'dayjs';
import {
  IBookingEntity,
  IBookingItemEntity,
  IBookingPurchaseItemEntity,
} from 'types/entities/booking.entity';

export const mapPOItemsToBookingItems = (booking: IBookingEntity) => {
  const items: IBookingPurchaseItemEntity[] = [];
  booking.bookingPurchaseOrders.forEach(
    (bookingPurchaseOrder: IBookingItemEntity) => {
      const purchaseOrderItems = bookingPurchaseOrder.purchaseOrderItems.map(
        (item: IBookingPurchaseItemEntity) => ({
          ...item,
          purchaseOrderItem: {
            ...item.purchaseOrderItem,
            cargoReadyDate: dayjs(booking.cargoReadyDate),
            order: bookingPurchaseOrder.purchaseOrder.reference,
          },
        })
      );

      items.push(...purchaseOrderItems);
    }
  );
  return items;
};
