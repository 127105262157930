import { Alert, Button, Space, Typography } from 'antd';
import BiddingCounter from 'app/components/Bidding/BiddingCounter';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { ICreateNewProposalCardProps } from 'types/feature/multiple-proposal';

import {
  isProposalRejected,
  isProposalRequested,
  isProposalRevoked,
  isProposalSubmitted,
} from 'utils/proposal-helper';
import { formatRankNumber } from 'utils/rank.utils';

import ProposalNotificationAction from '../NotificationAction';
import './create-new-quote-card.scss';

const { Text, Paragraph } = Typography;

const CreateNewQuoteCard: FC<ICreateNewProposalCardProps> = ({
  proposal: { bigDeadline, bidRank, shipmentReference, showBiddingRank },
  onCreateNewProposalClick,
  onProposalNotifChange,
  status,
  isProposalNotifEnabled,
  proposalParams,
  onEditProposal,
}) => {
  const isSubmitted = isProposalSubmitted(status) || isProposalRevoked(status);
  const isRequested = isProposalRequested(status);

  return (
    <DashboardCard
      large
      fitContent
      headerTitle="Bidding Details"
      rightAccessory={
        bigDeadline && (
          <BiddingCounter
            deadline={bigDeadline}
            fitContent
            showDropDownActions={false}
          />
        )
      }
    >
      <div className="new-quote-modal-content-wrapper">
        {isSubmitted ? (
          <Paragraph>
            Your quotation for shipment {shipmentReference ?? ''} has
            successfully submitted. Submit another quotation.
          </Paragraph>
        ) : (
          <Paragraph>
            Please submit you best quotation for shipment{' '}
            {shipmentReference ?? ''} .
          </Paragraph>
        )}

        <div className="modal-footer">
          {!isRequested && (
            <Text strong className="bidding-text-wrapper" color="#0062FF">
              {showBiddingRank && bidRank > 0 && (
                <>
                  Your Rank Level:{' '}
                  <Text className="bidding-text" color="#0062FF">
                    {formatRankNumber(bidRank)}
                  </Text>
                </>
              )}
            </Text>
          )}

          <Space className="modal-actions">
            <ProposalNotificationAction
              onProposalNotifChange={onProposalNotifChange}
              proposalParams={proposalParams}
              isProposalNotifEnabled={isProposalNotifEnabled}
            />
            <Button onClick={onCreateNewProposalClick} disabled={!isSubmitted}>
              Create New Quotation
            </Button>
            {!isProposalRejected(status) && (
              <Button onClick={onEditProposal} type="primary">
                Edit Proposal
              </Button>
            )}
          </Space>
        </div>

        {showBiddingRank && (
          <Alert
            description="Please note that the rank group is only based on the total amount. Cargo Owner will also take into account other parameters such as transit time, carrier, and sailing schedule to decide and award the shipment."
            type="info"
            banner
          />
        )}
      </div>
    </DashboardCard>
  );
};

export default CreateNewQuoteCard;
