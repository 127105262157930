import { Button, Col, Form, Input, Row, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useUpdatePasswordMutation } from 'api/user';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC, useCallback, useEffect } from 'react';
import { UpdatePasswordRequest } from 'types/entities/user.entity';
import { PasswordProps } from 'types/feature/profile.types';

import { displayErrors } from 'utils/error-notification';

const Password: FC<PasswordProps> = () => {
  const [updatePassword, { isSuccess, isError, error }] =
    useUpdatePasswordMutation();
  const [form] = useForm();

  const onSave = async () => {
    try {
      const values: UpdatePasswordRequest = await form.validateFields();
      await updatePassword(values);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.warning({
        message: 'Error',
        description: 'make sure to fill all fields correctly !',
      });
    }
  };

  const onSuccess = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Password updated successfully',
      });
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  useEffect(() => {
    if (isError) {
      displayErrors(error);
    }
  }, [isError, error]);
  return (
    <DashboardCard headerTitle="Password">
      <Form layout="horizontal" labelCol={{ span: 24 }} form={form}>
        <Row gutter={[12, 12]} align={'middle'}>
          <Col span={8}>
            <Form.Item
              label="Current Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter your old password!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter your new password!',
                },
                {
                  min: 8,
                  message: 'Password needs to be at least 8 characters long !',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Button type="primary" onClick={onSave} className="save-btn">
            Save
          </Button>
        </Row>
      </Form>
    </DashboardCard>
  );
};

export default Password;
