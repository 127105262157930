import { Button, Form, notification } from 'antd';
import DashboardCard from 'app/design-system/DashboardCard';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect } from 'react';
import {
  IContractCreationFormProps,
  IContractDetailsForm,
  IContractFeesForm,
} from 'types/feature/contracts.type';

import { formatServiceModeString } from 'utils/format-fields';

import '../contract-creation.scss';
import ContractDetailsForm from './ContractDetailsForm';
import ContractFeesForm from './ContractFeesForm';
import { mapContractFeesFormValues } from './helper';

const CreationForm: FC<IContractCreationFormProps> = ({
  defaultContract,
  isEditView,
  onFinish,
  isProcessing,
}) => {
  const [contractDetailsForm] = Form.useForm<IContractDetailsForm>();
  const [contractLinesForm] = Form.useForm<IContractFeesForm>();

  const onSubmitForm = async () => {
    try {
      // DETAILS
      // 1. validate fields
      // validation

      await contractDetailsForm.validateFields();
      await contractLinesForm.validateFields();

      let contractDetails: IContractDetailsForm;
      let contractFees: IContractFeesForm;

      // 2. get data from fields
      if (isEditView) {
        // 2.1. if edited only get the modified data
        contractDetails = await contractDetailsForm.getFieldsValue(
          true,
          ({ touched }) => touched
        );

        contractFees = await contractLinesForm.getFieldsValue(true);
      } else {
        // 2.2. if new order , get all fields
        contractDetails = await contractDetailsForm.getFieldsValue(true);
        contractFees = await contractLinesForm.getFieldsValue(true);
      }

      // CREATE THE REQ BODY
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const contractUpsertBody: any = {
        id: defaultContract?.id,
        ...contractDetails,
        costHeads: mapContractFeesFormValues(
          contractFees,
          defaultContract?.costHeads
        ),
      };

      await onFinish(contractUpsertBody);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const lines = await contractLinesForm.getFieldsValue(true);
      if (!lines.costHeads.length) {
        notification.error({
          message: 'Validation error',
          description: 'Make sure to insert at least one cost head !',
        });
      } else {
        notification.warning({
          message: 'Wrong inputs',
          description:
            error.message ??
            'Make sure to fill all mandotary fields correctly !',
        });
      }
    }
  };

  const resetForms = useCallback(() => {
    contractDetailsForm.resetFields();
    contractLinesForm.resetFields();
  }, [contractLinesForm, contractDetailsForm]);

  useEffect(() => {
    resetForms();
  }, [defaultContract, resetForms]);

  return (
    <>
      <DashboardCard
        large
        headerTitle="Contract Details"
        noMargin
        rightAccessory={
          <Button
            type="primary"
            onClick={onSubmitForm}
            disabled={isProcessing}
            loading={isProcessing}
          >
            {!isEditView ? 'Create New Contract' : 'Update Contract'}
          </Button>
        }
      >
        <ContractDetailsForm
          form={contractDetailsForm}
          defaultValues={
            defaultContract
              ? {
                  carrierId: defaultContract.carrierId,
                  reference: defaultContract.reference,
                  forwarder: defaultContract.forwarder?.id,
                  forwarderName: defaultContract.forwarder?.name,
                  shipmentTypeId: defaultContract.shipmentType?.id,
                  paymentTerms: defaultContract.paymentTerms,
                  contactId: defaultContract.contactId,
                  startDate: defaultContract.startDate
                    ? dayjs(defaultContract.startDate)
                    : null,
                  endDate: defaultContract.endDate
                    ? dayjs(defaultContract.endDate)
                    : null,
                }
              : null
          }
          footer={
            <Button
              type="primary"
              onClick={onSubmitForm}
              disabled={isProcessing}
              loading={isProcessing}
            >
              Create New Contract
            </Button>
          }
        />

        <ContractFeesForm
          isEditView={isEditView}
          form={contractLinesForm}
          contractId={defaultContract?.id}
          defaultValues={
            defaultContract?.costHeads
              ? {
                  costHeads: defaultContract?.costHeads.map((costHead) => ({
                    ...costHead,
                    serviceMode: formatServiceModeString(
                      costHead.origin.serviceMode,
                      costHead.destination.serviceMode
                    ),
                    originFull: costHead.origin,
                    destinationFull: costHead.destination,
                    origin: costHead.origin.city,
                    destination: costHead.destination.city,
                  })),
                }
              : {
                  costHeads: [],
                }
          }
        />
      </DashboardCard>
    </>
  );
};

export default CreationForm;
