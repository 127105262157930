import { notification } from 'antd';
import { useCreateContractMutation } from 'api/contract';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-reflex/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { IContractEntity } from 'types/entities/contract.entity';

import { createContractClickEvent } from 'utils/analytics-events';
import { displayErrors } from 'utils/error-notification';

import CreationForm from './CreationForm';

const ContractCreation: FC = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const navigate = useNavigate();
  const location = useLocation();
  const [createContractRate, { error, isError, isSuccess, isLoading }] =
    useCreateContractMutation();

  const onFinish = async (contract: Partial<IContractEntity>) => {
    try {
      const originalProposalId = location?.state?.proposalId;
      await createContractRate({
        contract,
        proposalId: originalProposalId,
      }).unwrap();
      createContractClickEvent({
        user_id: user?.id || '',
        email: user?.email || '',
        company: user?.company?.name || '',
        company_id: user?.companyId || '',
      });
      //eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Contract creation Error' });
    }
  };

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'Contract creation Error' });
    }

    return;
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Contract created !',
        description: 'You can now view in main page !',
      });
      navigate('/contracts');
    }
  }, [isSuccess, navigate]);

  return (
    <CreationForm
      defaultContract={location?.state?.contract ?? null}
      isEditView={false}
      onFinish={onFinish}
      isProcessing={isLoading}
    />
  );
};

export default ContractCreation;
