import { createSlice } from '@reduxjs/toolkit';
import { TCreateShipmentForm } from 'types/feature/create-shipment.types';

export type TInitialState = {
  createdShipmentResp: {
    slug: string;
    id: string;
  } | null;
} & TCreateShipmentForm;

const initialState: TInitialState = {
  createdShipmentResp: null,
  cargoDetailsForm: null,
  shipmentDetailsForm: null,
  additionalInfoForm: null,
};

export const createShipmentSlice = createSlice({
  name: 'createShipment',
  initialState,
  reducers: {
    setCreatedShipmentResp: (state, action) => {
      state.createdShipmentResp = action.payload;
    },
    setCargoDetailsForm: (state, action) => {
      state.cargoDetailsForm = action.payload;
    },
    setShipmentDetailsForm: (state, action) => {
      state.shipmentDetailsForm = action.payload;
    },
    setAdditionalInfoForm: (state, action) => {
      state.additionalInfoForm = action.payload;
    },
    resetCreateShipment: (state) => {
      state.createdShipmentResp = initialState.createdShipmentResp;
      state.cargoDetailsForm = initialState.cargoDetailsForm;
      state.shipmentDetailsForm = initialState.shipmentDetailsForm;
      state.additionalInfoForm = initialState.additionalInfoForm;
    },
  },
});

export const {
  setCargoDetailsForm,
  setShipmentDetailsForm,
  setAdditionalInfoForm,
  setCreatedShipmentResp,
  resetCreateShipment,
} = createShipmentSlice.actions;

export default createShipmentSlice.reducer;
