import { Select } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { IAddConnectionSelectProps } from 'types/feature/connections.type';

import { selectOptionFilter } from 'utils/common';

import AddConnectionModal from '../AddConnectionModal';

const AddConnectionSelect: FunctionComponent<IAddConnectionSelectProps> = ({
  selectOptions,
  isRoleHidden,
  btnProps,
  onSelectChange,
  showAddNewUser,
  isLoading = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onAddBtnClick = () => setIsOpen(false);

  return (
    <Select
      showSearch
      placeholder={btnProps?.text ?? 'Add Supplier'}
      loading={isLoading}
      open={isOpen}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      filterOption={selectOptionFilter}
      options={selectOptions}
      dropdownRender={(menu) => {
        return (
          <>
            {showAddNewUser && (
              <AddConnectionModal
                isRoleHidden={isRoleHidden}
                btnProps={btnProps}
                onBtnClicked={onAddBtnClick}
              />
            )}

            {menu}
          </>
        );
      }}
      {...(onSelectChange && { onChange: onSelectChange })}
    />
  );
};

export default AddConnectionSelect;
