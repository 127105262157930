import Icon from '@ant-design/icons/lib/components/Icon';
import { Button, Col, Modal, Row, Space, Tag, Typography } from 'antd';
import { useGetInvoiceCollaboratorByIdQuery } from 'api/invoice-collaborator';
import DescriptionList from 'app/design-system/DescriptionList';
import { disaplayForwarders } from 'app/design-system/DisplayConnections';
import React, { FC, useState } from 'react';
import {
  IInvoiceHeaderProps,
  InvoiceStatusEnum,
} from 'types/feature/invoice.types';

import { splitState } from 'utils/format-fields';

import { ReactComponent as AddUserIcon } from '../../../../assets/icons/add-user-icon.svg';
import InvoiceCollaborators from '../InvoiceCollaborators';
import './invoice-header.scss';

const { Title } = Typography;

const InvoiceHeader: FC<IInvoiceHeaderProps> = ({ invoice }) => {
  const [isCollaboratorOpen, setIsCollaboratorOpen] = useState(false);

  const { data: invoiceCollabs } = useGetInvoiceCollaboratorByIdQuery(
    invoice.id
  );

  const showCollaboratorModal = () => {
    setIsCollaboratorOpen(true);
  };

  const hideCollaboratorModal = () => {
    setIsCollaboratorOpen(false);
  };

  const renderCollaboratorModal = () => (
    <Modal
      title="Invoice Collaborators"
      open={isCollaboratorOpen}
      onCancel={hideCollaboratorModal}
      footer={null}
    >
      <InvoiceCollaborators
        invoiceId={invoice.id}
        shipmentId={invoice.shipmentId}
      />
    </Modal>
  );
  const computeTagColor = () => {
    switch (invoice.status) {
      case InvoiceStatusEnum.DRAFT:
        return 'default';

      case InvoiceStatusEnum.IN_REVIEW:
        return 'orange';

      case InvoiceStatusEnum.ACCEPTED:
        return 'green';

      case InvoiceStatusEnum.DECLINED:
        return 'red';

      case InvoiceStatusEnum.PAID:
        return 'blue';

      default:
        return 'default';
    }
  };

  return (
    <Row
      gutter={[20, 20]}
      justify="space-between"
      align="middle"
      className="invoice-header-wrapper"
    >
      <Col className="title-col">
        <Space>
          <Title level={4} className="m-0 title">
            Invoice No {invoice.slug}
          </Title>
          <Tag color={computeTagColor()}>{splitState(invoice.status)}</Tag>
        </Space>
      </Col>
      <Col>
        <Space>
          {invoiceCollabs && (
            <DescriptionList
              label="Collaborators:"
              description={
                <div className="user-gravatar-wrapper">
                  {disaplayForwarders({
                    teamMemebers: invoiceCollabs.data.collaborators,
                    maxCount: 3,
                  })}
                </div>
              }
            />
          )}
          <Button
            icon={<Icon component={AddUserIcon} />}
            onClick={showCollaboratorModal}
          >
            Collaborators
          </Button>
          {invoice && renderCollaboratorModal()}
        </Space>
      </Col>
    </Row>
  );
};

export default InvoiceHeader;
