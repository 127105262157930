import { UserAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  useInviteCollaboratorMutation,
  useGetRecentCollaboratorByShipmentQuery,
} from 'api/collaborators';
import { useGetProfileQuery } from 'api/user';
import { roleOptionConst } from 'app/components/Collaborators/collaborators.const';
import { formItemLayoutWithOutLabel } from 'app/components/Proposal/CargoDetails/carg-details.const';
import CompanyField from 'app/design-system/CompanyField';
import React, { useEffect, useState } from 'react';
import {
  ICollaboratorsEntity,
  IInviteCollaboratorForm,
} from 'types/entities/collaborator.entity';
import { UserRoleEnum } from 'types/entities/user.entity';

import { addCollaboratorEvent } from 'utils/analytics-events';
import { renderAccessLevelOptions } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';

import './style.scss';

interface IAddNewShipmentCollaboratorProps {
  shipmentId: string;
  isCollaborator: boolean;
  currentUser: ICollaboratorsEntity;
}
const AddNewShipmentCollaborator: React.FC<
  IAddNewShipmentCollaboratorProps
> = ({ shipmentId, isCollaborator, currentUser }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = useForm<IInviteCollaboratorForm>();
  const [isCargoOwnerSelected, setIsCargoOwnerSelected] =
    useState<boolean>(false);

  const [selectedRole, setSelectedRole] = useState<UserRoleEnum>();

  const { data: userData } = useGetProfileQuery();

  useEffect(() => {
    if (isCollaborator) {
      form.setFieldsValue({
        role: currentUser.role,
        company: userData?.data?.company?.name || '',
        companyId: userData?.data.companyId,
      });
      setSelectedRole(currentUser.role);
    }
  }, [isCollaborator, currentUser.role, userData, form]);

  // queries
  const [inviteCollaborator, { isLoading, isError, error, isSuccess }] =
    useInviteCollaboratorMutation();

  const { refetch: refetchGetCollaboratorByShipment } =
    useGetRecentCollaboratorByShipmentQuery({
      shipmentId,
      search: '',
    });

  // methods
  const handleSelectAcompany =
    (name: string | number) => (value: string, id: string | undefined) => {
      if (value && id) {
        form.setFieldValue(name, value);
        form.setFieldValue('companyId', id);
      }
    };

  const handleOnRoleChange = (value: UserRoleEnum) => {
    if (value === UserRoleEnum.CARGO_OWNER) {
      form.setFieldsValue({
        company: userData?.data?.company?.name || '',
        companyId: userData?.data.companyId,
      });
      setIsCargoOwnerSelected(true);
    } else {
      setIsCargoOwnerSelected(false);
    }
  };

  const onInviteCollaborator = async () => {
    try {
      const { fullname, email, companyId, role, accessLevel } =
        await form.validateFields();

      if (shipmentId) {
        await inviteCollaborator({
          fullname,
          email,
          companyId,
          role,
          shipmentId,
          accessLevel,
        });
        refetchGetCollaboratorByShipment();
        addCollaboratorEvent({
          user_id: userData?.data.id,
          email: userData?.data.email,
          company: userData?.data.company.name || '',
          collaborator_user_id: [],
          company_id: userData?.data.companyId,
        });
      }

      if (isCollaborator) {
        form.setFieldsValue({
          fullname: '',
          email: '',
          companyId: userData?.data.companyId,
          company: userData?.data.company.name || '',
          role: currentUser.role,
        });
      } else {
        form.resetFields();
        setIsCargoOwnerSelected(false);
      }
    } catch (error) {
      notification.error({
        message: 'Failed to invite collaborator, Try again!',
      });
    }
  };

  useEffect(() => {
    if (isError && error) displayErrors(error);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Invitation sent to collaborator!' });
    }
  }, [isSuccess]);

  const onValuesChange = (
    changedValues: Partial<IInviteCollaboratorForm>,
    values: IInviteCollaboratorForm
  ) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === 'role') {
      setSelectedRole(values.role);
    }
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="add-sc-wrapper">
      <Button
        type="text"
        onClick={onOpenModal}
        icon={<UserAddOutlined />}
        className="add-sc-btn"
      >
        Add New Collaborator
      </Button>
      <Modal
        title="Add New Collaborator"
        open={isModalOpen}
        onCancel={onCloseModal}
        footer={[
          <Button key="submit" type="default" onClick={onCloseModal}>
            Back
          </Button>,
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            htmlType="submit"
            onClick={onInviteCollaborator}
          >
            Add Collaborator
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            collaborators: [
              {
                fullname: '',
                email: '',
                company: '',
                role: '',
              },
            ],
          }}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label="Full Name"
            name="fullname"
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Field is required',
              },
            ]}
          >
            <Input placeholder="Full name" />
          </Form.Item>

          <Form.Item
            label="Email"
            {...formItemLayoutWithOutLabel}
            name="email"
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
              {
                type: 'email',
                message: 'Enter correct email.',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Role"
            {...formItemLayoutWithOutLabel}
            name="role"
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Field is required',
              },
            ]}
          >
            <Select
              placeholder="Select Role"
              options={roleOptionConst}
              onChange={handleOnRoleChange}
              allowClear
              disabled={isCollaborator}
            />
          </Form.Item>

          <Form.Item
            label="Company"
            name="companyId"
            hidden
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {isCargoOwnerSelected || isCollaborator ? (
            <Form.Item
              label="Company"
              name="company"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          ) : (
            <Form.Item
              label="Company"
              {...formItemLayoutWithOutLabel}
              name="company"
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'The company is required, if it is new make sure to add it first !'
                    );
                  },
                }),
              ]}
            >
              <CompanyField
                userRole={selectedRole}
                handleSelectAcompany={handleSelectAcompany('company')}
              />
            </Form.Item>
          )}

          <Form.Item name="accessLevel" label="Access Level" key="access_level">
            <Select
              placeholder="Select Role"
              options={renderAccessLevelOptions(currentUser.accessLevel)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewShipmentCollaborator;
