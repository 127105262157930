import { Table } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { ICommentEntity } from 'types/entities/comment.entity';
import { CurrencyEnum } from 'types/entities/fee.entity';
import {
  IInvoiceEntity,
  IInvoiceItemEntity,
  IUpdateInvoicesRequestBody,
} from 'types/entities/invoice.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { ICreatedByDef, IShipmentEntity } from 'types/entities/shipment.entity';
import { IUserEntity } from 'types/entities/user.entity';

export enum InvoiceStatusEnum {
  DRAFT = 'draft',
  ON_HOLD = 'on_hold',
  IN_REVIEW = 'in_review',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  PROCESSED = 'processed',
  PAID = 'paid',
}

export enum InvoiceTableViewEnum {
  INVOICE_MATCHING = 'invoice-matching-view',
  PROPOSAL_MATCHING = 'proposal-matching-view',
  SAVE_SAHRE = 'save-share-view',
}

export interface IInvoiceHeaderProps {
  invoice: IInvoiceEntity;
}

export interface IInvoiceMatchingProps {
  invoice: IInvoiceEntity;
  onStepChange: (step: number) => void;
}

export interface IProposalMatchingProps extends IInvoiceMatchingProps {
  // TODO: Hot fix to keep proposal currency consisten for everyone
  proposalCurrency: CurrencyEnum | null;
}

export interface IShareInvoiceProps extends IInvoiceMatchingProps {
  shipment: IShipmentEntity;
}

export interface IInvoiceMatchingTableProps {
  invoice: IInvoiceEntity;
  tableView: InvoiceTableViewEnum;
  onBackClick: () => void;
  onContinueClick: () => void;
  isItemDetailEditabale: boolean;
}

export interface EditableRowProps {
  index: number;
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IInvoiceItemEntity;
  record: IInvoiceItemEntity;
  handleSave: (record: IInvoiceItemEntity, dataIndex: string) => void;
}

export interface IUploadInvoiceModalProps {
  shipmentId: string;
  shipmentSlug: string;
}

export interface IProposalPreviewProps {
  proposal: IProposalEntity;
  // TODO: Hot fix to keep proposal currency consisten for everyone
  proposalCurrency: CurrencyEnum | null;
}

export interface IInvoiceCommentProps {
  invoiceId: string;
  shipmentId: string;
}

export interface IInvoiceItemCommentProps extends IInvoiceCommentProps {
  invoiceItemId: string;
  onUpdate: (newComments: ICommentEntity, invoiceItemId: string) => void;
}

export type EditableTableProps = Parameters<
  typeof Table<IInvoiceItemEntity>
>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export interface InvoiceStatusSwitchProps {
  invoice: IInvoiceEntity;
  updateInvoicesArgs?: IUpdateInvoicesRequestBody;
  getUpdatedInvoices?: (
    IUpdateInvoicesRequestBody: IUpdateInvoicesRequestBody
  ) => void;
}

export interface IInvoiceAssigneeModalProps {
  invoiceId: string;
  shipmentId: string;
  handleCloseAcceptInvoiceModal?: () => void;
  buttonType?: 'default' | 'ghost' | 'primary' | 'dashed' | undefined;
  buttonText?: string;
}
export interface IInvoiceAcceptModalProps {
  invoiceId: string;
  shipmentId: string;
}

export interface IInvoiceCollaboratorsProps {
  invoiceId: string;
  shipmentId: string;
}

export interface IInvoiceCollaboratorTableDef {
  id: string;
  fullname: string;

  email: string;
  isAlreadyInvited: boolean;
  photo: string | null;
}

export interface IInvoiceDetails {
  mbl: string;
  issueDate: string;
  dueDate: string;
  currency: CurrencyEnum | null;
  proposalTotalCost?: number | null;
  invoiceNo?: string | null;
  supplierVatNo: string | null;
  buyerVatNo: string | null;
}

export interface IInvoiceDetailsProps {
  invoiceDetails: IInvoiceDetails;
  isEditabale: boolean;
  isLoading: boolean;
  onChange?: (newInvoiceDetails: Partial<IInvoiceDetails>) => void;
}

export enum EditableDescriptiontInputEnum {
  TEXT_INPUT = 'input-text',
  DATE_INPUT = 'date-input',
  CURRENCY_INPUT = 'currency-input',
}
export interface IEditableDescriptiontProps {
  value: string;
  inputType: EditableDescriptiontInputEnum;
  onChange: (value: string) => void;
}

export type IComputeMoneyDef = (
  cost: number | null,
  convertedCost: number | null,
  currency: CurrencyEnum | null,
  companyCurrency: CurrencyEnum | null
) => {
  money: string;
  convertedMoney: string;
};

export type IRenderInvoiceMatchingTableColPropos = (props: {
  isItemDetailEditabale: boolean;
  tableView: InvoiceTableViewEnum;
  isTaxEnabled: boolean;
  onCopyActualCost: ((index: number) => void) | null;
}) => (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[];

export interface IDeclineInvoiceProps {
  isModalOpen: boolean;
  invoice: IInvoiceEntity;
  setIsDeclineOpen: Dispatch<SetStateAction<boolean>>;
  updateInvoicesArgs?: IUpdateInvoicesRequestBody;
  getUpdatedInvoices?: (
    IUpdateInvoicesRequestBody: IUpdateInvoicesRequestBody
  ) => void;
}

export interface IInvoiceActionButton {
  canBypass: boolean;
  invoiceId: string;
  shipmentId: string;
  status: InvoiceStatusEnum;
  isLoading: boolean;
  isAdminCO: boolean;
  canAssignInvoice: boolean;
  onStatusActionClick: (status: InvoiceStatusEnum) => void;
  onCloseClick: () => void;
}

export type TCanAssignInvoiceMethod = (props: {
  shipmentCreator: ICreatedByDef;
  invoiceCreator: IUserEntity;
  user: IUserEntity | undefined;
}) => boolean;
