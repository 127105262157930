import { Button, Space } from 'antd';
import { useGetProfileQuery } from 'api/user';
import ProfileImage from 'app/components/ProfilePicture';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Mention, MentionItem, MentionsInput } from 'react-mentions';
import { ICommentProps } from 'types/feature/comment.types';
import {
  CustomSuggestionDataItem,
  IMentionInput,
} from 'types/feature/messages.type';

import CommentItem from './CommentItem';
import './comment-box.scss';

dayjs.extend(relativeTime);
const messageInitialStateConst = {
  mentions: [],
  originalText: '',
  markupText: '',
};

const CommentBox: FC<ICommentProps> = ({
  comments,
  isLoading,
  onAddComment,
  hideActions = false,
  isExternal = false,
  userList,
  topSuggestions,
  fitContent,
}) => {
  const [messageWithMention, setMessageWithMention] = useState<IMentionInput>(
    messageInitialStateConst
  );
  const commentContainerRef = useRef<HTMLDivElement>(null);
  const { data: userData } = useGetProfileQuery(null, {
    skip: isExternal,
  });

  const scrollToBottom = useCallback(() => {
    const lastItem = commentContainerRef.current?.getElementsByClassName(
      `comment-item-${comments.length - 1}`
    );
    if (lastItem && lastItem.length > 0) {
      setTimeout(() => {
        lastItem[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [comments]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, commentContainerRef]);

  const onAddCommentClick = () => {
    onAddComment(messageWithMention);
    setMessageWithMention(messageInitialStateConst);
  };
  const handleChange = (
    _: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setMessageWithMention({
      mentions: mentions.map((m) => m.id),
      originalText: newPlainTextValue,
      markupText: newValue,
    });
  };
  //add comment by pressing enter (to go a new line you need to press shift + enter)
  const handleOnKeyPress = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onAddCommentClick();
    }
  };
  return (
    <div className={`comments-wrapper${fitContent ? '__fit-content' : ''}`}>
      <div ref={commentContainerRef} className="comments-list-wrapper">
        {comments.map((comment, index) => (
          <div
            key={`comment-item-${index}`}
            className={`comment-item-${index}`}
          >
            <CommentItem
              comment={{
                userName: comment.createdBy.fullname,
                messageDate: dayjs(comment.createdAt).fromNow(),
                message: comment.originalComment || comment.comment,
                userPhoto: comment.createdBy.photo,
              }}
            />
          </div>
        ))}
      </div>
      {!hideActions && (
        <div
          className={`send-comment-wrapper ${
            userData?.data ? '' : 'padding-fix'
          }`}
        >
          {userData?.data && (
            <ProfileImage
              wrapperClassName="user-gravatar"
              name={userData?.data.fullname}
              photoUrl={userData?.data.photo}
              size={30}
            />
          )}
          <div className="comment-inputs">
            <MentionsInput
              value={messageWithMention?.markupText}
              placeholder="Comment or add other with @"
              onChange={handleChange}
              className={
                topSuggestions
                  ? 'comment-mentions-input-top'
                  : 'comment-mentions-input'
              }
              onKeyDown={(event) => handleOnKeyPress(event)}
            >
              <Mention
                markup="@{{__id__||__display__}}"
                displayTransform={(id, username) => `@${username}`}
                className="mentions__mention"
                trigger={'@'}
                data={userList || [{ id: '', display: '' }]}
                renderSuggestion={(suggestion) => {
                  const customSuggestion =
                    suggestion as CustomSuggestionDataItem;

                  return (
                    <Space className="suggestion-item">
                      <ProfileImage
                        key={customSuggestion.id}
                        name={customSuggestion.display || ''}
                        photoUrl={customSuggestion.photo}
                        className="user-gravatar"
                      />
                      {customSuggestion.display}
                    </Space>
                  );
                }}
              />
            </MentionsInput>
            <Button
              className="add-comment-btn"
              type="primary"
              onClick={onAddCommentClick}
              loading={isLoading}
              disabled={!messageWithMention.originalText}
            >
              Comment
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentBox;
