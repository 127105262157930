import { Col, Form, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import DashboardCard from 'app/design-system/DashboardCard';
import React, { FC } from 'react';
import { IContactDetailsFormProps } from 'types/feature/create-shipment.types';

import {
  computeUserSelectOptions,
  userSelectOptionFilterByLabel,
} from 'utils/user-utils';

const ContactDetailsForm: FC<IContactDetailsFormProps> = ({
  form,
  externalUserList,
}) => {
  return (
    <Form layout="vertical" form={form} scrollToFirstError>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <FormItem label="Contact Person" name="createdBy">
            <Select
              allowClear
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              filterOption={userSelectOptionFilterByLabel}
              options={computeUserSelectOptions(externalUserList)}
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactDetailsForm;
