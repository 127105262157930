import { ILocation } from 'types/entities/shipment.entity';

export const mapValuesToLocation = (
  defaultLocation: ILocation | null,
  location: ILocation,
  serviceMode?: string
) => {
  return {
    ...(defaultLocation && { id: defaultLocation.id }),
    ...location,
    serviceMode: serviceMode ?? defaultLocation?.serviceMode,
  } as ILocation;
};
