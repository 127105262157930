import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { IndicatorProps } from 'types/feature/dashboard.types';

import './indicator.scss';
import { matchIndicatorToLogo } from './utils';

const { Title, Paragraph } = Typography;
const IndicatorWrapper: FC<IndicatorProps> = ({ data, onClick }) => {
  return (
    <Row className="indicator-holder" key={data.id} onClick={onClick}>
      <Col className="indicator-holder__icon">
        {matchIndicatorToLogo(data.status)}
      </Col>
      <Col>
        <Title level={4} className="value-text">
          {data.count}
        </Title>
        <Paragraph className="title-text">{data.status}</Paragraph>
      </Col>
    </Row>
  );
};

export default IndicatorWrapper;
