import { Typography } from 'antd';
import React, { FC } from 'react';

import { ReactComponent as ShipmentMessagesIllustration } from '../../../../assets/shipment-messages-icon.svg';

const EmptyMessages: FC = () => {
  return (
    <div className="illustration-wrapper">
      <ShipmentMessagesIllustration />
      <Typography.Title level={5}>Shipment Messages</Typography.Title>
      <Typography.Text>
        Send and receive messages against shipment quotes and purchase orders.
      </Typography.Text>
    </div>
  );
};

export default EmptyMessages;
