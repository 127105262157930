import { ConfigProvider, Modal, Typography } from 'antd';
import React, { FC, useState } from 'react';
import { APP_THEME_CONST } from 'style-config';

import ImportShipmentsByTracking from '..';
import '../create-shipment-from-tracking-styles.scss';

const ImportShipmentsByTrackingModal: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalCloseable, setIsModalCloseable] = useState<boolean>(true);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => setIsModalOpen(true);

  const handleModalCloseable = (canClose: boolean) =>
    setIsModalCloseable(canClose);

  return (
    <>
      <Typography.Text className="create-dropdown-item" onClick={onOpenModal}>
        Import Shipments
      </Typography.Text>

      <Modal
        open={isModalOpen}
        width="710px"
        footer={null}
        onCancel={onCloseModal}
        centered
        destroyOnClose={true}
        maskClosable={isModalCloseable} //modal is not closable on step 2
        closable={isModalCloseable}
      >
        <ConfigProvider theme={APP_THEME_CONST}>
          <div className="app-theme-wrapper">
            <ImportShipmentsByTracking
              onClose={onCloseModal}
              handleModalCloseable={handleModalCloseable}
            />
          </div>
        </ConfigProvider>
      </Modal>
    </>
  );
};

export default ImportShipmentsByTrackingModal;
