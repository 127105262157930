import { ICompanyEntity, IUserPartnerEntity } from './company.entity';
import { CurrencyEnum } from './fee.entity';

export enum UserConnectionTypes {
  SUPPLIER = 'supplier',
  FORWARDER = 'forwarder',
  OTHER = 'other',
}

export enum UserRoleEnum {
  CARGO_OWNER = 'cargo_owner',
  FORWARDER = 'forwarder',
  SUPPLIER = 'supplier',
  LOCAL_TRANSPORTER = 'local_transporter',
  CUSTOMS_AGENCY = 'customs_agency',
  OTHER = 'other',
}

export interface IUserNameEntity {
  fullname: string;
}

export interface IUserBaseEntity extends IUserNameEntity {
  id: string;
  email: string;
}
export interface IUserWithCompany extends IUserBaseEntity {
  company: {
    name: string;
  };
}

export enum userProfileRoleEnum {
  ADMIN = 'admin',
  SIMPLE_USER = 'simple_user',
}

export interface IUserEntity extends IUserBaseEntity {
  userId?: string;
  companyId: string;
  company: ICompanyEntity;
  userRole: userProfileRoleEnum;
  invited?: boolean;
  isProfileCompleted: boolean;
  photo: string | null;
  mobileNumber: string | null;
  showOnboardingOnLogin?: boolean;
  createdAt: string;
}

export interface ICreateUserEntity extends IUserEntity {
  userInviteFor: string;
  userConnectionType: UserConnectionTypes;
  companyName?: string;
  country?: string | null;
}

export interface UpdatePasswordRequest {
  newPassword: string;
  oldPassword: string;
}

export interface UpdateCompanyRequest {
  name: string;
  website: string;
  country: string;
  city: string;
  address: string;
  registrationNumber: string;
  currency: CurrencyEnum;
}

export interface IGetConnectionsParams {
  type?: UserConnectionTypes;
  keyword?: string;
}

export type CombinedUserType = IUserEntity & IUserPartnerEntity;
