import {
  ICreateTaskRequestBody,
  ITaskEntity,
  IUpdateTaskRequestBody,
} from 'types/entities/task.entity';

import { api } from './base-api';

export const tasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTaskById: build.query<ITaskEntity[], string>({
      query: (shipmentId) => `/shipment-tasks/${shipmentId}`,
      providesTags: ['ShipmentTasks'],
    }),

    createTask: build.mutation<ITaskEntity, Partial<ICreateTaskRequestBody>>({
      query: (body) => ({
        url: '/shipment-tasks',
        method: 'POST',
        body: {
          shipmentId: body.shipmentId,
          ...body.data,
        },
      }),
      invalidatesTags: ['ShipmentTasks'],
    }),

    updateTask: build.mutation<ITaskEntity, Partial<IUpdateTaskRequestBody>>({
      query: (body) => ({
        url: `/shipment-tasks/${body.taskId}`,
        method: 'PATCH',
        body: { ...body.data },
      }),
      invalidatesTags: ['ShipmentTasks'],
    }),

    createDefaultTask: build.mutation<
      ITaskEntity,
      Partial<ICreateTaskRequestBody>
    >({
      query: (body) => ({
        url: `/shipment-tasks/default`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useCreateDefaultTaskMutation,
} = tasksApi;
