import { RangePickerProps } from 'antd/es/date-picker';
import { DefaultOptionType } from 'antd/es/select';
import { useGetProductsQuery } from 'api/product';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { IProductEntity } from 'types/entities/product.entity';
import { ICargoDetailsForm } from 'types/feature/create-shipment.types';

export const useCargoDetailsForm = (form: any, defaultValues: any) => {
  const [isNewProduct, setIsNewProduct] = useState<boolean>(false);
  const [commodities, setCommodities] = useState<DefaultOptionType[]>([]);
  const {
    data: products,
    isLoading: isFetchingProducts,
    isSuccess: isProductsLoaded,
  } = useGetProductsQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isProductsLoaded) {
      const options = products.map((product: IProductEntity) => ({
        label: product.category,
        value: product.id,
      }));
      setCommodities(options);
    }
  }, [isProductsLoaded, products]);

  const disabledETDDate: RangePickerProps['disabledDate'] = (current) => {
    const eta = form.getFieldValue('eta');
    if (eta) return current && current > dayjs(eta).endOf('day');
    return false;
  };

  const disabledETADate: RangePickerProps['disabledDate'] = (current) => {
    const etd = form.getFieldValue('etd');
    if (etd) return current && current < dayjs(etd).endOf('day');
    else return false;
  };

  const onValuesChange = (changedValues: any, values: ICargoDetailsForm) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === 'productId') {
      setIsNewProduct(changedValues['productId'] === 'other');
    }
  };

  return {
    isNewProduct,
    commodities,
    isFetchingProducts,
    disabledETDDate,
    disabledETADate,
    onValuesChange,
  };
};
