import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Tag, notification } from 'antd';
import { useUpdatePurchaseOrdersMutation } from 'api/purchase-order';
import React, { FC } from 'react';
import { PurchaseOrderStatusEnum } from 'types/entities/purchase-order.entity';

import { splitState } from 'utils/format-fields';

import './task-switch.scss';

interface PurchaseOrderStatusSwitchProps {
  disabled: boolean;
  id: string;
  status: PurchaseOrderStatusEnum;
  toUpdate: boolean;
}
const items = Object.values(PurchaseOrderStatusEnum).map(
  (type: PurchaseOrderStatusEnum) => ({
    label: splitState(type as unknown as string),
    value: type,
    key: type,
  })
);
const PurchaseOrderStatusSwitch: FC<PurchaseOrderStatusSwitchProps> = ({
  id,
  status,
  toUpdate,
  disabled,
}) => {
  const [updatePurchaseOrder] = useUpdatePurchaseOrdersMutation();

  const updateStatus = async (status: PurchaseOrderStatusEnum) => {
    try {
      if (id) await updatePurchaseOrder({ id: id, body: { status } });
    } catch (error) {
      notification.warning({
        message: 'Unknown error while updating purchase order status!',
      });
    }
  };

  // eslint-disable-next-line
  const handleStatusUpdated = (status: any) => {
    updateStatus(status?.key);
  };

  let color = 'orange';
  switch (status) {
    case PurchaseOrderStatusEnum.CONFIRMED:
      color = 'green';
      break;
    case PurchaseOrderStatusEnum.IN_PRODUCTION:
      color = 'orange';
      break;
    case PurchaseOrderStatusEnum.READY_PICKUP:
      color = 'purple';
      break;
    default:
      color = 'blue';
  }

  return (
    <Dropdown
      disabled={disabled}
      menu={{
        items: items,
        onClick: handleStatusUpdated,
      }}
    >
      <Tag
        className="task-tag"
        color={color}
        closable={toUpdate && !disabled}
        closeIcon={!disabled && <DownOutlined />}
      >
        {splitState(status)}
      </Tag>
    </Dropdown>
  );
};

export default PurchaseOrderStatusSwitch;
