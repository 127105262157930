import { Col, Row, Spin } from 'antd';
import { useGetInvoiceCountsQuery } from 'api/invoice-analytics';
import React from 'react';

import { formatAmount } from 'utils/monetary-utils';

import InvoiceAnalyticCard from './InvoiceCountCard/InvoiceAnalyticCard';
import OnHoldTable from './OnHoldTable';
import PendingApprovalTable from './PendingApprovalTable';
import PendingReviewTable from './PendingReviewTable';
import SubmittedInvoicesChart from './SubmittedInvoicesChart';

const InvoiceAnalytics = () => {
  // queries

  const { data: invoiceCountData, isLoading: isInvoiceCountDataLoading } =
    useGetInvoiceCountsQuery();
  return isInvoiceCountDataLoading ? (
    <Row className="py-3" justify="center">
      <Spin />
    </Row>
  ) : (
    <>
      {invoiceCountData && (
        <Row gutter={16}>
          <Col md={8} lg={4}>
            <InvoiceAnalyticCard
              title={invoiceCountData.counts.pendingApproval.toString()}
              subtitle="Pending Approval"
            />
          </Col>
          <Col md={8} lg={4}>
            <InvoiceAnalyticCard
              title={invoiceCountData.counts.pendingReview.toString()}
              subtitle="Pending Review"
            />
          </Col>
          <Col md={8} lg={5}>
            <InvoiceAnalyticCard
              title={invoiceCountData.counts.onHold.toString()}
              subtitle="On Hold - more Info required"
            />
          </Col>
          <Col md={12} lg={6}>
            <InvoiceAnalyticCard
              isToBeCollected
              title={formatAmount(
                parseFloat(invoiceCountData.counts.totalAmount),
                invoiceCountData.currency
              )}
              subtitle="To be Scheduled"
            />
          </Col>
          <Col md={12} lg={5}>
            <InvoiceAnalyticCard
              isReceived
              title={formatAmount(
                parseFloat(invoiceCountData.counts.receivedAmount),
                invoiceCountData.currency
              )}
              subtitle="Processed"
            />
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col sm={24} md={12} xxl={6}>
          <PendingReviewTable />
        </Col>
        <Col sm={24} md={12} xxl={6}>
          <OnHoldTable />
        </Col>
        <Col sm={24} md={12} xxl={6}>
          <PendingApprovalTable />
        </Col>
        <Col sm={24} md={12} xxl={6}>
          <SubmittedInvoicesChart />
        </Col>
      </Row>
    </>
  );
};

export default InvoiceAnalytics;
