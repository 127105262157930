import { PaginatorParams } from 'types/feature/pagination.types';
import {
  IShipmentsResponseBody,
  ISingleShipmentResponse,
  ShipmentsParams,
} from 'types/feature/shipments.types';

import { api } from './base-api';

export const searchApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchShipment: build.query<
      IShipmentsResponseBody | ISingleShipmentResponse,
      PaginatorParams & ShipmentsParams
    >({
      query: (params: PaginatorParams & ShipmentsParams) => ({
        url: `/shipment/search`,
        params,
      }),
      providesTags: (result, error, arg) => [
        { type: 'Search', id: arg.keyword },
      ],
    }),
  }),
});

export const { useSearchShipmentQuery } = searchApi;
