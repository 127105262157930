import { ColumnsType } from 'antd/es/table';
import { IContainerEntity } from 'types/entities/container.entity';

export const defaultSelectedColumns = [
  'number',
  'type',
  'status',
  'carrier',
  'action',
];

export const containersColumnConst: ColumnsType<IContainerEntity> = [
  {
    title: 'Container Number',
    dataIndex: 'number',
    key: 'number',
    render: (_, record: IContainerEntity) => record.number,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, record: IContainerEntity) => record.sizeType,
  },

  {
    title: 'Carrier',
    dataIndex: 'carrier',
    key: 'carrier',
    render: (_, record: IContainerEntity) => record.carrier?.name,
  },
];
