/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { DoptUsersProvider } from '@dopt/react-users';
import * as Sentry from '@sentry/react';
import { DOPT_API_KEY } from 'api/common/config';
import { initRemoteConfig } from 'api/common/remoteConfig';
import { FeatureFlagsProvider } from 'providers/feature-flag-provider';
import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';

import { store } from '../state/store';
import { ErrorBoundary } from './layout/ErrorBoundary';
import FallBack from './layout/ErrorBoundary/FallBack';
import AppRoutes from './routes';

const App: FC = () => {
  useEffect(() => {
    initRemoteConfig();
  }, []);

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={FallBack()}>
        <FeatureFlagsProvider>
          <DoptUsersProvider apiKey={DOPT_API_KEY}>
            <AppRoutes />
          </DoptUsersProvider>
        </FeatureFlagsProvider>
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default App;
// export default Sentry.withProfiler(App);
