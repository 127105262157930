import { ColumnsType } from 'antd/es/table';
import { IShipmentEntity } from 'types/entities/shipment.entity';

export const filterColumns = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allcolumns: ColumnsType<any>,
  selectedColumns: string[],
  defaultColumns?: string[]
) => {
  // if no columns selected and no default options, return all columns
  if (!selectedColumns.length && !defaultColumns) {
    return allcolumns;
  }

  // if no columns selected and default options, return default selected columns (required columns)
  if (!selectedColumns.length && defaultColumns) {
    return allcolumns.filter((col) =>
      defaultColumns.includes(col.key as unknown as string)
    );
  }

  // else return columns selected by user preferences
  return allcolumns.filter((col) =>
    selectedColumns.includes(col.key as unknown as string)
  );
};

export const excludeEmptyKeyColumns = (columns: ColumnsType<IShipmentEntity>) =>
  columns.filter((col) => col?.key);
