import { ShipmentStatusEnum } from 'types/feature/shipments.types';

import { IBookingPartyEntity } from './booking-party';
import { ICollaboratorsEntity } from './collaborator.entity';
import { ICompanyEntity } from './company.entity';
import { ServiceModeEnum } from './contract.entity';
import { CurrencyEnum } from './fee.entity';
import { IFileEntity } from './files.entity';
import { IShipementTags } from './metadata.entity';
import { IProductEntity, IncotermsEnum } from './product.entity';
import { ContainerTypeEnum, IProposalEntity } from './proposal.entity';
import { UserRoleEnum } from './user.entity';

export enum ModalityEnum {
  SEA = 'sea',
  AIR = 'air',
  ROAD = 'road',
}

export enum ShipmentTypeEnum {
  FCL = 'fcl',
  LCL = 'lcl',
  FTL = 'ftl',
  LTL = 'ltl',
  AIR_CARGO = 'air cargo',
  COURIER = 'courier',
}

export enum CargoTypeEnum {
  GENERAL_CARGO = 'general cargo',
  DANGEROUS_CARGO = 'dangerous cargo',
  PERISHABLE_CARGO = 'perishable cargo',
  LIVE_ANIMALS = 'live animals',
  WET_CARGO = 'wet cargo',
}

export enum PackageTypeEnum {
  PALLETS = 'pallets',
  SKIDS = 'skids',
  CRATES = 'crates',
  BOXES = 'boxes',
  CARTONS = 'cartons',
  CASES = 'cases',
  PACKAGES = 'packages',
  DRUMS = 'drums',
  BALES = 'bales',
  BAGS = 'bags',
  ROLLS = 'rolls',
  COLLI = 'colli',
  JUMBO_BAGS = 'jumbo_bags',
}

export interface ISingleShipmentTypeEntity {
  id: string;
  title: ShipmentTypeEnum;
  modality: ModalityEnum;
}
export interface IShipmentTypeEntity {
  [key: string]: ISingleShipmentTypeEntity[];
}

export enum LengthMeasurementUnitEnum {
  CM = 'cm',
  IN = 'in',
  // M = 'm',
}

export interface ICargoUnit {
  containerType?: ContainerTypeEnum | null;
  packageType?: PackageTypeEnum | null;
  quantity?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  weight?: number | null;
  volume?: number | null;
  charges?: number;
  id?: string;
  product?: IProductEntity;
  proposalId?: string;
  totalWeight?: number;
  lengthMeasurement?: LengthMeasurementUnitEnum;
}

export interface ILocation {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  country: string;
  code: string;
  city: string;
  latitude: number;
  longitude: number;
  serviceMode: ServiceModeEnum | null;
}

export interface IProductDef {
  id: string;
  category: string;
}

export interface IShipmentTypeDef {
  id: string;
  title: ShipmentTypeEnum;
  modality: ModalityEnum;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export interface IShipmentHistoryDef {
  key: ShipmentStatusEnum;
  label: string;
  date?: string;
}

export interface ICreatedByDef {
  id: string;
  fullname: string;
  userRole?: string;
  company: ICompanyEntity;
}

export interface IBaseShipmentEntity {
  id: string;
  modality?: ModalityEnum;
  createdDate: number;
  createdBy: ICreatedByDef;
  initiatedBy: ICreatedByDef; // same as UserEntity !!
  createdAt: string;
  modifiedDate: number;
  status: ShipmentStatusEnum;
  slug: string;

  cargoReadyDate: string;
  shipmentType: IShipmentTypeDef;

  cargoUnits: ICargoUnit[];
  eta: string | null;
  etd: string | null;
  autoUpdateEta: boolean;
  autoUpdateEtd: boolean;
  cargoType: CargoTypeEnum;
  nonStackable: boolean;
  destination: ILocation;
  origin: ILocation;
  currentLocation: ILocation | null;
  incoterms: IncotermsEnum;
  pod: string | null;
  pol: string | null;
  shipmentReference: string;
  proposalReference: string | null;
  isBookmarked: boolean;
  teams: ICollaboratorsEntity[];
  forwarders: ICollaboratorsEntity[];
  product: IProductEntity;
  attachments: string[];
  containers: string[];
  mbl: string | null;
  awb: string | null;
  trackingNumber: string | null;

  shipmentHistory: IShipmentHistoryDef[];
  currentUserRole: UserRoleEnum;
  shipmentAccess: {
    currentUser: string;
    currentUserEmail: string;
    hasAccessToShipment: boolean;
    redirect: string;
    targetId: string;
  };

  acceptedCargoUnits: ICargoUnit[];
  acceptedShipmentItems: string;
  acceptedShipmentType: IShipmentTypeDef | null;

  currentCollaborator: ICollaboratorsEntity;

  // not available
  containerType?: string;
  additionalInfo: string | null;
  files?: IFileEntity[];
  dueDate: string | null;
  trackingName: string | null;
  shipmentTypeId: string;
  createdById: string;
  carrier: string | null;
  autoUpdateStatus: boolean;
  archived: boolean;
}

export interface IShipmentEntity extends IBaseShipmentEntity {
  shipmentItems: string;
  carrierId?: string | null;
  serviceMode: string | null;
  shipmentMetadata?: IShipementTags[];
  bookingParties?: IBookingPartyEntity[];
  currency?: CurrencyEnum;
  totalQuotes?: number;
  submittedQuotes?: 0;
  highestCost?: number | null;
  lowestCost?: number | null;

  proposalId?: string | null;
  proposals?: IProposalEntity[];
}

export interface IProposalShipmentEntity extends IBaseShipmentEntity {
  shipmentItems: ICargoUnit[];
}

export interface IBookmarkedShipment {
  id: string;
  shipment: IShipmentEntity;
  arrivalSoon: boolean;
  isBookmarked: boolean;
}

export interface IDuplicateShipment {
  id: string;
  shipment: Partial<IShipmentEntity>;
}

export interface IDeleteShipmentItem {
  id: string;
  shipmentId: string;
}

export interface IAllShipmentsLocation {
  origins: string[];
  destinations: string[];
}
