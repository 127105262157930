import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Typography } from 'antd';
import { useGetCollaboratorByShipmentQuery } from 'api/collaborators';
import ProfileImage from 'app/components/ProfilePicture';
import React, { FC, useEffect } from 'react';
import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { IMembersListProps } from 'types/entities/message.entity';

import { displayErrors } from 'utils/error-notification';

const MembersList: FC<IMembersListProps> = ({
  shipmentId,
  handleHideMembers,
}) => {
  const { data, isLoading, isError, error } =
    useGetCollaboratorByShipmentQuery(shipmentId);

  useEffect(() => {
    if (isError && error) {
      displayErrors(error, { title: 'error fetching collaborators' });
    }
  }, [isError, error]);

  return (
    <>
      <Card className="header-card" bordered={false}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>Members</Typography.Title>
            <Typography.Text>Total Members: {data?.length}</Typography.Text>
          </Col>
          <Button
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={handleHideMembers}
          ></Button>
        </Row>
      </Card>
      <Card bordered={false} className="list-card">
        <List
          loading={isLoading}
          dataSource={data}
          renderItem={(member: ICollaboratorsEntity) => (
            <div className="shipment-message-wrapper">
              <List.Item>
                <List.Item.Meta
                  className="message-item"
                  avatar={
                    <ProfileImage
                      wrapperClassName="user-gravatar"
                      name={member.collaborator.fullname}
                      photoUrl={member.collaborator.photo}
                      size={30}
                    />
                  }
                  title={member.collaborator.fullname}
                  description={member.role}
                />
              </List.Item>
            </div>
          )}
        ></List>
      </Card>
    </>
  );
};

export default MembersList;
