import { ArrowRightOutlined, CaretDownFilled } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { renderServiceMode } from 'app/components/ContractRates/CostHeadCollapse/cost-head.helper';
import React from 'react';
import { ServiceModeEnum } from 'types/entities/contract.entity';
import { IDocumentTypeEnum } from 'types/entities/document.entity';
import { ContainerTypeEnum } from 'types/entities/proposal.entity';
import { PurchaseOrderStatusEnum } from 'types/entities/purchase-order.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import { ILocation, IShipmentEntity } from 'types/entities/shipment.entity';
import { TaskStatusEnum } from 'types/entities/task.entity';
import { AccessLevelEnum } from 'types/feature/purchase-orders.types';
import { ShipmentStatusEnum } from 'types/feature/shipments.types';

import { formatAmount } from './monetary-utils';

export const displayCountry = (location: ILocation) =>
  location && location.city
    ? `${location.city} ${location.code ? `,${location.code}` : ''}`
    : `---`;
export const displayCity = (location: ILocation) =>
  location && location.city ? `${location.city}` : `---`;
export const formatOriginToDestDashBoard = (shipment: IShipmentEntity) => {
  return (
    <div className="from-to">
      <Space size={8}>
        {displayCountry(shipment.origin)}
        <ArrowRightOutlined />
        {displayCountry(shipment.destination)}
      </Space>
      <Typography.Paragraph className="shipment-text--items">
        {shipment.shipmentItems}
      </Typography.Paragraph>
    </div>
  );
};
export const formatOriginToDest = (
  destination: ILocation,
  origin: ILocation
) => {
  return (
    <Space size={8}>
      {displayCountry(origin)}
      <ArrowRightOutlined />
      {displayCountry(destination)}
    </Space>
  );
};
export const formatBids = (shipment: IShipmentEntity) => {
  return (
    <div className="shipment-bidding">
      <Typography.Paragraph className="shipment-text--sub labels">
        <span className="lowest-label">Lowest: </span>
        <span className="highest-label">Highest: </span>
      </Typography.Paragraph>
      <Typography.Paragraph className="shipment-text--sub values">
        <span className="lowest-value">
          {shipment.lowestCost && shipment.currency
            ? formatAmount(shipment.lowestCost, shipment.currency)
            : '-'}
        </span>
        <span className="highest-value">
          {shipment.highestCost && shipment.currency
            ? formatAmount(shipment.highestCost, shipment.currency)
            : '-'}
        </span>
      </Typography.Paragraph>
    </div>
  );
};

export const capitalize = (state: string) => {
  return state ? state.replace(/\b\w/g, (l) => l.toUpperCase()) : state;
};

export const splitState = (state: string) =>
  capitalize(state.replace('rfq', 'RFQ').replace(/_/gi, ' '));

export const formatDocumentType = (state: IDocumentTypeEnum) => {
  switch (state) {
    case IDocumentTypeEnum.MSDS:
      return 'MSDS';
    default:
      return splitState(state);
  }
};

export const formatServiceMode = (mode: ServiceModeEnum) => {
  switch (mode) {
    case ServiceModeEnum.CY:
      return 'Port';
    case ServiceModeEnum.DOOR:
      return 'Door';
  }
};

export const formatContainerName = (container: ContainerTypeEnum) =>
  container.replace('_', "' ").replace(/_/gi, ' ').toUpperCase();

export const formatShipmentState = (
  state: ShipmentStatusEnum | string,
  isEditable = false
) => {
  switch (state) {
    case ShipmentStatusEnum.DELIVERED:
    case ShipmentStatusEnum.RFQ_SUBMITTED:
      return (
        <Tag color={'green'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );

    case ShipmentStatusEnum.RFQ_REQUESTED:
      return (
        <Tag color={'blue'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );

    case ShipmentStatusEnum.IN_ORIGIN:
      return (
        <Tag color={'gold'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );
    case ShipmentStatusEnum.IN_TRANSIT:
      return (
        <Tag color={'orange'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );
    case ShipmentStatusEnum.IN_CUSTOMS:
      return (
        <Tag color={'volcano'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );
    case ShipmentStatusEnum.IN_DESTINATION:
      return (
        <Tag color={'pink'}>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );
    default:
      return (
        <Tag>
          <Space>
            {splitState(state)}
            {isEditable && <CaretDownFilled />}
          </Space>
        </Tag>
      );
  }
};

const TASK_STATE_MAP = {
  [TaskStatusEnum.DONE]: { color: 'green' },
  [TaskStatusEnum.IN_PROGRESS]: { color: 'orange' },
  [TaskStatusEnum.TODO]: { color: 'blue' },
};

type ProposalStateMap = { [key in IQuoteStatus]?: { color: string } };

const PROPOSAL_STATE_MAP: ProposalStateMap = {
  [IQuoteStatus.ACCEPTED]: { color: 'green' },
  [IQuoteStatus.SUBMITTED]: { color: 'blue' },
  [IQuoteStatus.REQUESTED]: { color: 'orange' },
  [IQuoteStatus.DECLINED]: { color: 'red' },
};

export const formatTaskState = (state: TaskStatusEnum | string) => {
  const tagProps = TASK_STATE_MAP[state as TaskStatusEnum] ?? {};

  return (
    <Tag {...tagProps} bordered={false}>
      {splitState(state)}
    </Tag>
  );
};
export const formatProposalState = (state: IQuoteStatus | string) => {
  const tagProps = PROPOSAL_STATE_MAP[state as IQuoteStatus] ?? {};

  return (
    <Tag {...tagProps} style={{ margin: 0, marginBottom: '10px' }}>
      {splitState(state)}
    </Tag>
  );
};

export const computeTagColors = (status: string) => {
  switch (status) {
    case IQuoteStatus.ACCEPTED:
      return 'green';
    case IQuoteStatus.DECLINED:
      return 'red';
    case IQuoteStatus.REQUESTED:
      return 'gold';
    case IQuoteStatus.REVOKED:
      return 'lime';
    case IQuoteStatus.SUBMITTED:
    default:
      return 'blue';
  }
};

//
const PO_STATE_MAP = {
  [PurchaseOrderStatusEnum.CONFIRMED]: { color: 'green' },
  [PurchaseOrderStatusEnum.IN_PRODUCTION]: { color: 'orange' },
  [PurchaseOrderStatusEnum.PICKED]: { color: 'blue' },
  [PurchaseOrderStatusEnum.READY_PICKUP]: { color: 'purple' },
};

export const formaPOState = (state: PurchaseOrderStatusEnum | string) => {
  const tagProps = PO_STATE_MAP[state as PurchaseOrderStatusEnum] ?? {};

  return (
    <Tag {...tagProps} bordered={false}>
      {splitState(state)}
    </Tag>
  );
};

export const formatServiceModeString = (
  originServiceMode: ServiceModeEnum | null,
  destinationServiceMode: ServiceModeEnum | null
) =>
  originServiceMode && destinationServiceMode
    ? `${originServiceMode}/${destinationServiceMode}`
    : '--/--';

const generateCombinations: () => string[] = () => {
  const combinations: string[] = [];

  for (const mode1 in ServiceModeEnum) {
    for (const mode2 in ServiceModeEnum) {
      combinations.push(
        formatServiceModeString(ServiceModeEnum[mode1], ServiceModeEnum[mode2])
      );
    }
  }

  return combinations;
};
export const createServiceModeOptions: () => DefaultOptionType[] = () => {
  const modes = generateCombinations();

  return modes.map((mode) => {
    return { label: renderServiceMode(mode), value: mode };
  });
};
export const isEditAllowed = (access: AccessLevelEnum) =>
  [AccessLevelEnum.EDIT, AccessLevelEnum.MANAGE].includes(access);

export const formatAccessLevel = (accessLevel: AccessLevelEnum | undefined) => {
  return accessLevel ? `Can ${accessLevel}` : 'Invite';
};

export const formatAccessLevelOptions = (
  isCollaboratorInvited: boolean,
  accessLevel: AccessLevelEnum
) => {
  return isCollaboratorInvited
    ? `Can ${accessLevel}`
    : `Invite to ${accessLevel}`;
};
