import { ICommentEntity } from 'types/entities/comment.entity';
import {
  IAddInvoiceCommentByIdPayload,
  IAddInvoiceItemCommentByIdPayload,
  IGetInvoiceItemCommentByIdPayload,
} from 'types/entities/invoice.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const invoiceCommentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceCommentById: build.query<
      ApiResponse<{ comments: ICommentEntity[] }>,
      string
    >({
      query: (invoiceId) => `/invoice-comment/${invoiceId}`,
      providesTags: ['InvoiceComment'],
    }),

    addInvoiceCommentById: build.mutation<
      ApiResponse<ICommentEntity>,
      IAddInvoiceCommentByIdPayload
    >({
      query: (body) => ({
        url: `/invoice-Comment`,
        method: 'POST',
        body: body,
        formData: true,
      }),
      invalidatesTags: ['InvoiceComment'],
    }),

    getInvoiceItemCommentById: build.query<
      ApiResponse<{ comments: ICommentEntity[] }>,
      IGetInvoiceItemCommentByIdPayload
    >({
      query: ({ invoiceItemId, invoiceId }) =>
        `/invoice-comment/item/${invoiceId}/${invoiceItemId}`,
      providesTags: ['InvoiceItemComment'],
    }),

    addInvoiceItemCommentById: build.mutation<
      ApiResponse<ICommentEntity>,
      IAddInvoiceItemCommentByIdPayload
    >({
      query: (body) => ({
        url: `/invoice-Comment/item`,
        method: 'POST',
        body: body,
        formData: true,
      }),
      invalidatesTags: ['InvoiceItemComment'],
    }),
  }),
});

export const {
  useGetInvoiceCommentByIdQuery,
  useAddInvoiceCommentByIdMutation,
  useGetInvoiceItemCommentByIdQuery,
  useAddInvoiceItemCommentByIdMutation,
} = invoiceCommentApi;
