import { ArrowRightOutlined } from '@ant-design/icons';
import { Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IContractEntity } from 'types/entities/contract.entity';

import { displayCountry } from 'utils/format-fields';
import { dateFormat2Const } from 'utils/shipment-type';

import { renderServiceMode } from '../CostHeadCollapse/cost-head.helper';

export const mainColumnConst: ColumnsType<IContractEntity> = [
  {
    key: 'reference',
    title: '# Ref',
    dataIndex: 'reference',
  },

  {
    key: 'forwarder',
    title: 'Forwarder',
    render: (
      _: string,
      { forwarder, contact }: IContractEntity,
      index: number
    ) => {
      return (
        <Space direction="vertical">
          <Typography.Text
            ellipsis={{ tooltip: forwarder?.name }}
            className="company-text"
            strong
          >
            {forwarder?.name}
          </Typography.Text>
          <Typography.Text type="secondary">
            {contact?.fullname}
          </Typography.Text>
        </Space>
      );
    },
  },

  {
    key: 'startDate',
    title: 'Start Date',
    dataIndex: 'startDate',
    render: (_: string, { startDate }: IContractEntity, index: number) => {
      return dayjs(startDate).format(dateFormat2Const);
    },
  },
  {
    key: 'endDate',
    title: 'End Date',
    render: (_: string, { endDate }: IContractEntity, index: number) => {
      return dayjs(endDate).format(dateFormat2Const);
    },
  },
  {
    key: 'service',
    title: 'Service Mode',
    render: (_: string, record: IContractEntity, index: number) => {
      return record.serviceMode ? renderServiceMode(record.serviceMode) : '--';
    },
    align: 'center',
  },
  {
    key: 'company',
    title: 'Company',
    render: (_: string, record: IContractEntity, index: number) => {
      return (
        <Typography.Text
          ellipsis={{ tooltip: record?.buyer?.name }}
          className="company-text"
        >
          {record?.buyer?.name}
        </Typography.Text>
      );
    },
  },
  {
    key: 'transitDays',
    title: 'Transit Time',
    dataIndex: 'transitDays',
    render: (_: string, { costHeads }: IContractEntity, index: number) => {
      return costHeads.length ? `${costHeads[0].transitDays} Days` : '--';
    },
  },
  {
    key: 'paymentTerms',
    title: 'Payment Terms',
    render: (_: string, { paymentTerms }: IContractEntity, index: number) => {
      return paymentTerms ? `${paymentTerms} Days` : '--';
    },
  },
  {
    key: 'carrier',
    title: 'Carrier',
    render: (_: string, record: IContractEntity, index: number) => {
      return record?.carrier?.name;
    },
  },
];

export const options = mainColumnConst.map((column) => ({
  value: column.key as string,
  label: column.title as string,
}));
