import {
  GetContractListParams,
  IContractEntity,
  IUpdateContractReq,
  IMatchContractRatesReq,
  ICreateContractReq,
} from 'types/entities/contract.entity';

import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const contractsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContracts: build.query<
      ApiResponse<IEntityList<IContractEntity>>,
      GetContractListParams
    >({
      query: ({ params }) => ({ url: '/contract/list', params: params }),
      providesTags: ['Contracts'],
    }),
    matchContracts: build.mutation<
      ApiResponse<IEntityList<IContractEntity>>,
      IMatchContractRatesReq
    >({
      query: (body) => ({ url: '/contract/match', body, method: 'POST' }),
    }),
    uploadContractFile: build.mutation<ApiResponse<IContractEntity>, FormData>({
      query: (formData) => ({
        url: '/contract/extract-contract-rows',
        body: formData,
        method: 'POST',
        formData: true,
      }),
    }),
    createContract: build.mutation<
      ApiResponse<IContractEntity>,
      ICreateContractReq
    >({
      query: ({ contract, proposalId }) => ({
        url: '/contract',
        body: contract,
        method: 'POST',
        params: proposalId ? { proposalId } : {},
      }),
      invalidatesTags: ['Contracts'],
    }),
    updateContract: build.mutation<
      ApiResponse<IContractEntity>,
      IUpdateContractReq
    >({
      query: (payload) => {
        const { params, body } = payload;
        return {
          url: `/contract/${params.contractId}`,
          body: body,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Contracts'],
    }),
    deleteContractById: build.mutation<
      ApiResponse<string>,
      { contractId: string }
    >({
      query: ({ contractId }) => ({
        url: `/contract/${contractId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contracts'],
    }),
    deleteCostheadById: build.mutation<
      ApiResponse<string>,
      { contractId: string; id: string }
    >({
      query: ({ contractId, id }) => ({
        url: `/contract/${contractId}/cost/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteChargeHeadById: build.mutation<
      ApiResponse<string>,
      { contractId: string; id: string }
    >({
      query: ({ contractId, id }) => ({
        url: `/contract/${contractId}/charge/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetContractsQuery,
  useDeleteContractByIdMutation,
  useUploadContractFileMutation,
  useCreateContractMutation,
  useUpdateContractMutation,
  useDeleteChargeHeadByIdMutation,
  useDeleteCostheadByIdMutation,
  useMatchContractsMutation,
} = contractsApi;
