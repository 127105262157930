import errorsLogger from 'app/layout/ErrorBoundary/useErrorReport';
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  isSupported,
} from 'firebase/remote-config';

import { isTesting } from './config';
import { firebaseApp } from './firebase';

// Remote Config
export const firebaseRemoteConfig = async () =>
  (await isSupported()) && getRemoteConfig(firebaseApp);

export async function initRemoteConfig() {
  const rc = await firebaseRemoteConfig();
  let featureFlags;
  let simplifiedFlags;

  try {
    if (rc) {
      if (isTesting) {
        rc.settings.minimumFetchIntervalMillis = 1800000;
      }

      rc.defaultConfig = {
        FEATURE_SIGNUP_ONBOARDING_ENABLED: 'false',
        FEATURE_TEST_ONBOARDING_BTN_ENABLED: 'false',
        FEATURE_ONBOARDING_CHECKLIST_ENABLED: 'false',
      };

      await fetchAndActivate(rc);
      featureFlags = getAll(rc);
      simplifiedFlags = Object.entries(featureFlags).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, current: any) => {
          const [key, entry] = current;
          return {
            ...acc,
            [key]: entry.asBoolean(),
          };
        },
        {}
      );
    }
  } catch (e) {
    const location = window.location.pathname;
    errorsLogger.report(`FE Remote Config Error at ${location} : ${e} `);
  }

  return simplifiedFlags;
}
