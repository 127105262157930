import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ProfileImage from 'app/components/ProfilePicture';
import React from 'react';
import { IRecentCollaboratorDef } from 'types/feature/collaborator.types';

import { capitalize } from 'utils/format-fields';

export const shipmentsCollabColumnsConst: ColumnsType<IRecentCollaboratorDef> =
  [
    {
      key: 'name',
      render: (value, record, index) => {
        return (
          <Space size={[18, 0]}>
            <ProfileImage name={record.fullname} photoUrl={record.photo} />
            <Space direction="vertical" size={[4, 6]}>
              <Typography.Text>{capitalize(record.fullname)}</Typography.Text>
              <Typography.Text type="secondary">{record.email}</Typography.Text>
            </Space>
          </Space>
        );
      },
    },
  ];
