import { IDocumentsResponseBody } from 'types/entities/document.entity';
import { DocsFilters, PaginatorParams } from 'types/feature/pagination.types';

import { api } from './base-api';

const transformParam = (types: string[]) =>
  types.length ? types.map((type) => `documentType=${type}`).join('&') : '';

export const documentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocuments: build.query<
      IDocumentsResponseBody,
      PaginatorParams & DocsFilters
    >({
      query: (params: PaginatorParams & DocsFilters) => ({
        url: `/file/files-paginate?${transformParam(
          params.documentType ?? []
        )}`,
        params: {
          page: params.page,
          limit: params.limit,
        },
      }),
      providesTags: ['Documents'],
    }),
  }),
});

export const { useGetDocumentsQuery } = documentsApi;
