import Table from 'antd/es/table';
import { convertToCBM } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import dayjs from 'dayjs';

import { roundToPrecision } from 'utils/common';
import { dateFormat2Const } from 'utils/shipment-type';

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const bookingItemColumns = [
  {
    title: 'SN #',
    dataIndex: 'id',
    render: (value, record, index) => record.purchaseOrderItem?.reference,
  },
  {
    title: 'Product Items',
    dataIndex: 'description',
    render: (value, record, index) => record.purchaseOrderItem?.description,
  },
  {
    title: 'CRD',
    dataIndex: 'cargoReadyDate',
    render: (value, record, index) =>
      dayjs(record.purchaseOrderItem?.cargoReadyDate).format(dateFormat2Const),
  },
  {
    title: 'Volume (CBM)',
    dataIndex: 'volume',
    render: (value, record, index) => convertToCBM(record.volume?.toString()),
    editable: true,
  },
  {
    title: 'Weight (KG)',
    dataIndex: 'weight',
    render: (value, record, index) => roundToPrecision(record.weight),
    editable: true,
  },
  {
    title: 'Boxes',
    dataIndex: 'boxes',
    render: (value, record, index) => record.purchaseOrderItem?.boxes,
    editable: true,
  },
  {
    title: 'Items',
    dataIndex: 'quantity',
    editable: true,
    width: '10%',
  },
];
